import _ from 'lodash';
import {
  BOOK_DEMO_CLICKED,
  CHARGEBEE_PAYMENT_SUCCESS,
  CURRENT_SUBSCRIPTION_SUCCESS,
  PLAN_DETAILS_SUCCESS,
  PLAN_PAYMENT_SUCCESS,
  RESET_PAYMENT_URL,
  SET_ACTIVE_LOCK_NAVIGATION,
  SET_ACTIVE_LOCK_NAVIGATION_IDENTIFIER,
  SET_ACTIVE_PRODUCT_PLAN,
  SET_COMPONENT_LOCK_STATUS,
  SET_CURRENT_ACTIVE_FEATURE_IDENTIFIER,
  SET_CURRENT_ACTIVE_NAVIGATION,
  SET_MODAL_CLOSE,
  SET_MODAL_OPEN,
} from '../actions/actionTypes';
import { chargebee_payment_restart } from '../../constants';
import { FEATURES_FLAG_LIST } from '../../constants/subscriptionConstants';

const initialState = {
  productList: [],
  productPriceList: [],
  paymentURL: null,
  fetchCurrentPlans: null,
  showTimer: null,
  productPriceIdList: [],
  showModal: false,
  currentActiveProduct: {
    planName: "",
    price: "",
    planPriceId: null,
    currentPlanStartAt: null,
    currentPlanEndAt: null,
    currentPlanStatus: null,
    cummuntyStartAt:  null,
    planPeriod: null,
    productId: null,
    currencyCode: null,
    periodUnit: null,
    productSubscription: {},
    productSubscriptionPlans: {},
    communityMembersCounts: null,
    communityStorageUsed: null,
    maxMembers: null,
    maxStorage: null,
  },
  featureLock: {},
  currentActiveNavigation: null,
  currentActiveFeatureIdentifier: null,
  activeLockNavigationIdentifier: null,
  activeLockNavigation: null,
  bookDemoFromModalClicked: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PLAN_DETAILS_SUCCESS:
      const {data: productPriceList, included: productList} = action.payload.data
      const productPriceIdList = productPriceList?.map(item => item?.attributes?.plan_price_id)
      return { ...state, productPriceIdList: productPriceIdList, productList: productList, productPriceList: productPriceList?.sort((a, b) => a?.attributes?.price - b?.attributes?.price)};
    
    case CHARGEBEE_PAYMENT_SUCCESS:
      return {...state, paymentURL: action?.payload?.data?.url}

    case RESET_PAYMENT_URL:
      return { ...state, paymentURL: null };

    case SET_ACTIVE_PRODUCT_PLAN:
      return {
        ...state,
        currentActiveProduct: { planName: action?.payload?.planName, productPriceId: action?.payload?.priceId },
      };
      
    case PLAN_PAYMENT_SUCCESS:
      const isSuccess = action?.payload
      if(isSuccess){
        localStorage.setItem(chargebee_payment_restart, true)
      }
      return {...state, showTimer: !isSuccess, fetchCurrentPlans: isSuccess}
    case CURRENT_SUBSCRIPTION_SUCCESS:
      const {product_subscription, product_subscription_plans, community_members_counts, community_storage_used} = action?.payload
      const {data: {attributes: {current_plan_end_at, current_plan_start_at, start_at, status, next_billing_at, features: {max_members, storage_in_gb: maxStorage}}} } = product_subscription
      const {included: productPlanData} = product_subscription_plans
      const productData = productPlanData?.find(product => (product?.type === "product_price"))
      const {currency_code, period_unit, period, external_name, plan_price_id, product_id, price} = productData?.attributes
      return {
        ...state, 
        currentActiveProduct: {
        ...state.currentActiveProduct,
        planName: external_name,
        price: price,
        planPriceId: plan_price_id,
        currentPlanStartAt: current_plan_start_at,
        currentPlanEndAt: current_plan_end_at,
        currentPlanStatus: status,
        cummuntyStartAt:  start_at,
        planPeriod: period_unit,
        productId: product_id,
        currencyCode: currency_code,
        periodUnit: period,
        nextBillingAt: next_billing_at,
        communityMembersCounts: community_members_counts,
        communityStorageUsed: community_storage_used,
        maxMembers: max_members,
        maxStorage: maxStorage
      }}

    case SET_MODAL_OPEN:
      return {...state, showModal: true}
    case SET_MODAL_CLOSE:
      return {...state, showModal: false}
    case SET_COMPONENT_LOCK_STATUS:
      return { ...state, featureLock: { ...state.featureLock, ...action.payload } };
    case SET_CURRENT_ACTIVE_NAVIGATION:
      return {...state, currentActiveNavigation: action?.payload}
    case SET_CURRENT_ACTIVE_FEATURE_IDENTIFIER:
      return { ...state, currentActiveFeatureIdentifier: action?.payload };
    case SET_ACTIVE_LOCK_NAVIGATION_IDENTIFIER:
      return { ...state, activeLockNavigationIdentifier: action?.payload };
    case SET_ACTIVE_LOCK_NAVIGATION:
      return { ...state, activeLockNavigation: action?.payload };
    case BOOK_DEMO_CLICKED:
      return { ...state, bookDemoFromModalClicked: action.payload }
    default:
      return state;
  }
};

export default reducer;
