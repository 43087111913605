import server from '../api/server';

export const fetchLeaderBoardData = async ({ page, groupId, communityId }) => new Promise((resolve, reject) => {
  server.get(`communities/${communityId}/groups/${groupId}/leaderboard?page=${page}`)
    .then((response) => resolve(response?.data))
    .catch(reject);
});

export const fetchWeeklyLeaderBoardData = async ({ page, groupId, communityId, startAt, endAt, offset }) => new Promise((resolve, reject) => {
  server.get(`communities/${communityId}/groups/${groupId}/weekly_leaderboard?page=${page}${offset !== 0 ? `&start_at=${startAt}&end_at=${endAt}` : ""}}`)
    .then((response) => resolve(response?.data))
    .catch(reject);
});
