import React, { useState, useEffect, useCallback, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Dialog, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import Cropper from 'react-easy-crop';
import {
  ArrowsExpandIcon,
  ChevronLeftIcon,
  PencilIcon,
  XIcon,
  ZoomInIcon,
  ZoomOutIcon,
} from '@heroicons/react/outline';
import Slider from 'rc-slider';
import { ReactComponent as OneByOne } from '../../../assets/images/icons/OneByOne.svg';
import { ReactComponent as ThreeByTwo } from '../../../assets/images/icons/ThreeByTwo.svg';
import { ReactComponent as ThreeByFour } from '../../../assets/images/icons/ThreeByFour.svg';
import { ReactComponent as SixteenByNine } from '../../../assets/images/icons/SixteenByNine.svg';
import { getPresignedPostData, uploadFileToS3, imageFetch } from '../../../services/preSignedAws';
import NotificationService from '../../../services/notificationService';
import { getActiveCommunityAccentColor } from '../../../selectors/CommunitySelectors';
import 'rc-slider/assets/index.css';
import LightBoxWrapper from '../../../components/shared/light-box/LightBoxWrapper';
import { ASPECT_RATIO } from '../../../constants';
import heic2any from 'heic2any';

const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation);

  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

async function getCroppedImg(
  imageSrc,
  fileInfo,
  editWithCropper,
  pixelCrop,
  rotation = 0,
  flip = { horizontal: false, vertical: false },
) {
  if (editWithCropper === false) {
    return fileInfo;
  }
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0);

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (file) => {
        resolve(file);
      },
      fileInfo && fileInfo?.type && (fileInfo?.name.includes('.heic') || fileInfo.name.includes('.HEIC'))
        ? 'image/jpeg'
        : fileInfo?.type,
    );
  });
}

function ImagePicker({
  onImageCropped,
  onImageLoading,
  returnRelativeImage = false,
  fileData,
  handleResetPicker,
  setRawImage,
  isLoading,
  isCta,
  presignedParams,
  cropOptions,
  imageDimensions,
}) {
  const [originalImage, setOriginalImage] = useState();
  const [openImageCropModal, setOpenImageCropModal] = useState(isCta ? true : false);

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [aspectRatio, setAspectRatio] = useState({ label: ASPECT_RATIO.ORIGINAL, value: 1 / 1 });
  const [editWithCropper, setEditWithCropper] = useState(isCta ? true : false);
  const [imageViewer, setImageViewer] = useState(null);
  const [fullImage, setFullImage] = useState(true);
  const [cropSize, setCropSize] = useState({ width: 100, height: 100 });

  const onCropCompleteOriginal = useCallback((croppedArea, croppedAreaPixels) => {
    if (fullImage) {
      setCrop({ x: 0, y: 0 });
    }
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const accentColor = useSelector(getActiveCommunityAccentColor);
  const calculateInitialCropSize = () => {
    return { width: imageDimensions?.width, height: imageDimensions?.height };
  };
  useEffect(() => {
    if (fullImage && imageDimensions?.width > 0 && imageDimensions?.height > 0) {
      setCropSize(calculateInitialCropSize());
      setCrop({ x: 0, y: 0 });
    }
  }, [imageDimensions]);

  useEffect(() => {
    const checkFileData = async () => {
      if (fileData) {
        onImageLoading(true);

        if (fileData.type === 'image/heic') {
          const jpegArrayBuffer = await heic2any({
            blob: fileData,
            toType: 'image/jpeg',
          });
          const jpegBlob = new Blob([jpegArrayBuffer], { type: 'image/jpeg' });
          const url = URL.createObjectURL(jpegBlob);
          setOpenImageCropModal(true);
          setOriginalImage(url);
          onImageLoading(false);
        } else {
          const url = URL.createObjectURL(fileData);
          setOpenImageCropModal(true);
          setOriginalImage(url);
          onImageLoading(false);
        }
      }
    };
    checkFileData();
  }, [fileData]);

  const getFileSizeString = (size) => {
    let sizeString = '';
    if (size < 1000) {
      sizeString = `${size} byte`;
    } else if (size < 1000000) {
      sizeString = `${(size / 1000).toFixed(2)} KB`;
    } else {
      sizeString = `${(size / 1000000).toFixed(2)} MB`;
    }
    return sizeString;
  };

  const getFooterClassName = () => {
    let footerClassName = 'flex flex-row items-center justify-between cursor-pointer h-16';
    if (cropOptions) {
      footerClassName = 'flex flex-row items-center justify-center cursor-pointer h-16';
    }
    return footerClassName;
  };

  const handleZoomChange = (newZoom) => {
    setZoom(newZoom);
    setCrop({ x: 0, y: 0 });
  };

  const handleSubmit = async () => {
    try {
      onImageLoading(true); // Start loading once at the beginning
      const croppedFile = await getCroppedImg(originalImage, fileData, editWithCropper, croppedAreaPixels);

      if (!croppedFile) {
        throw new Error('No cropped file generated.');
      }

      if (croppedFile.size > 10000000) {
        // 10 MB limit check
        throw new Error(
          `Image size should be less than 10 MB (current size is ${getFileSizeString(croppedFile.size)})`,
        );
      }

      setOpenImageCropModal(false);
      const url = 'onboarding/request_presigned_url';
      const fileObj = {
        uri: croppedFile,
        name: `IMG_${Date.now()}`,
        type: fileData.type,
      };
      const data = await getPresignedPostData(url, fileObj.name, presignedParams);
      const response = await uploadFileToS3(data, fileObj);

      handleImageUploadResponse(response, returnRelativeImage);
    } catch (error) {
      NotificationService.error(error.message || 'Failed to process the image.');
    } finally {
      // Reset state and loading indicator no matter the outcome
      resetEditorState();
      onImageLoading(false);
    }
  };

  const handleImageUploadResponse = async (response, returnRelativeImage) => {
    try {
      const parser = new DOMParser();
      const responseDoc = parser.parseFromString(response.data, 'application/xml');

      // Safely accessing the 'Key' node value
      const keyNode = responseDoc.getElementsByTagName('Key')[0];
      if (!keyNode || !keyNode.childNodes[0]) {
        throw new Error('Key node is missing or has no child nodes');
      }
      const imageSrc = keyNode.childNodes[0].nodeValue;

      // Determine the final image URL based on condition
      const finalImageUrl = returnRelativeImage ? imageSrc : await imageFetch(imageSrc, {}, false);

      // Callback with the final image URL
      onImageCropped(finalImageUrl);
    } catch (error) {
      console.error('Failed to process image upload response:', error);
      // Additional error handling or fallback logic can be added here
    }
  };

  const resetEditorState = () => {
    setEditWithCropper(false);
    setRawImage(null);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setCroppedAreaPixels(null);
    setAspectRatio({ label: '1:1', value: 1 });
    setOriginalImage();
  };

  const handleClose = () => {
    setOriginalImage();
    setOpenImageCropModal(false);
    setEditWithCropper(false);
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setCroppedAreaPixels(null);
    setAspectRatio({ label: '1:1', value: 1 / 1 });
    if (handleResetPicker) {
      handleResetPicker();
    }
  };

  const handleEdit = () => {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setCroppedAreaPixels(null);
    setAspectRatio({ label: '1:1', value: 1 / 1 });
    setEditWithCropper(true);
  };

  const getCropperView = () => {
    if (fullImage && imageDimensions?.width > 0) {
      return (
        <Cropper
          image={originalImage}
          crop={crop}
          zoom={zoom}
          style={{
            containerStyle: {
              height: '100%',
              width: '100%',
              background: 'rgba(0, 0, 0, 0.9)',
            },
          }}
          aspect={cropOptions ? cropOptions?.aspect : aspectRatio?.value}
          onCropChange={setCrop}
          onCropComplete={onCropCompleteOriginal}
          onZoomChange={handleZoomChange}
          cropSize={cropSize}
        />
      );
    } else {
      return (
        <Cropper
          image={originalImage}
          crop={crop}
          zoom={zoom}
          style={{
            containerStyle: {
              height: '100%',
              width: '100%',
              background: 'rgba(0, 0, 0, 0.9)',
            },
          }}
          aspect={cropOptions ? cropOptions?.aspect : aspectRatio?.value}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      );
    }
  };

  const handleAspectRatio = (name) => {
    switch (name) {
      case ASPECT_RATIO.ONE_BY_ONE:
        setAspectRatio({ label: '1:1', value: 1 / 1 });
        setFullImage(false);
        break;
      case ASPECT_RATIO.THREE_BY_TWO:
        setAspectRatio({ label: '3:2', value: 3 / 2 });
        setFullImage(false);
        break;
      case ASPECT_RATIO.THREE_BY_FOUR:
        setAspectRatio({ label: '3:4', value: 3 / 4 });
        setFullImage(false);
        break;
      case ASPECT_RATIO.SISXTEEN_BY_NINE:
        setAspectRatio({ label: '16:9', value: 16 / 9 });
        setFullImage(false);
        break;
      case ASPECT_RATIO.ORIGINAL:
        setAspectRatio({ label: ASPECT_RATIO.ORIGINAL, value: 1 / 1 });
        setFullImage(true);
        break;
      default:
        setAspectRatio({ label: '1:1', value: 1 / 1 });
        setFullImage(false);
        break;
    }
  };

  if (!openImageCropModal) return null;
  return (
    <>
      {imageViewer && <LightBoxWrapper mainSrc={imageViewer} onCloseRequest={() => setImageViewer(false)} />}
      {!isCta ? (
        <div
          style={{
            height: '100%',
            width: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            position: 'relative',
            borderRadius: '2px',
          }}
        >
          <div
            style={{
              backgroundImage: `url(${originalImage})`,
              height: '100%',
              width: '100%',
              backgroundPosition: 'center',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
            }}
          />
          {isLoading ? (
            <div className="flex justify-center items-center m-auto absolute h-full w-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 opacity-60 bg-gray-300">
              <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-black" />
            </div>
          ) : (
            <div className="flex justify-center items-center h-full w-full m-auto absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10">
              <ArrowsExpandIcon
                className="h-6 w-6 text-white bg-gray-900 flex-none rounded-full absolute top-2 right-16 p-1 ltr:mr-1 rtl:ml-1  cursor-pointer"
                onClick={() => {
                  const reader = new FileReader();
                  reader.onloadend = function () {
                    setImageViewer(reader.result);
                  };
                  reader.readAsDataURL(fileData);
                }}
              />
              <PencilIcon
                className="h-6 w-6 text-white bg-gray-900 flex-none rounded-full absolute top-2 right-9 p-1 ltr:mr-1 rtl:ml-1  cursor-pointer"
                onClick={handleEdit}
              />
              <XIcon
                className="h-6 w-6 text-white bg-gray-900 flex-none rounded-full absolute top-2 right-2 p-1 ltr:mr-1 rtl:ml-1  cursor-pointer"
                onClick={handleClose}
              />
            </div>
          )}
        </div>
      ) : null}

      <Transition show={openImageCropModal && editWithCropper === true} as="div">
        <Dialog as="div" className="fixed z-[101] inset-0 overflow-y-auto" onClose={handleClose}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center md:block md:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </TransitionChild>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden md:inline-block md:align-middle md:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <TransitionChild
              as="div"
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 align-bottom card justify-center rounded-lg text-left overflow-hidden shadow-xl transition-all md:align-middle w-11/12 lg:w-1/2">
                <div className="h-full flex flex-col shadow-xl overflow-y-scroll">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-center justify-between">
                      <DialogTitle className="text-lg font-medium text__title">
                        <div
                          role="none"
                          className="flex flex-row items-center cursor-pointer h-16"
                          onClick={
                            isCta
                              ? handleClose
                              : () => {
                                  setEditWithCropper(false);
                                }
                          }
                        >
                          <ChevronLeftIcon className="h-5 w-5 main__icon ltr:mr-2 rtl:ml-2" aria-hidden="true" />
                          <span className="mt-1">Crop media</span>
                        </div>
                      </DialogTitle>
                      <div>
                        <button
                          type="button"
                          className="w-full ltr:mr-2 rtl:ml-2  inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-0 focus:ring-offset-0 md:text-sm btn__primary"
                          onClick={handleSubmit}
                          style={{ backgroundColor: accentColor }}
                        >
                          {$translatei18n('Save')}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative h-380 w-full">{getCropperView()}</div>
                  <div role="none" className={getFooterClassName()}>
                    {cropOptions ? null : (
                      <div className="w-1/2 flex justify-start gap-x-2 sm-640:gap-x-4 ltr:ml-2 rtl:mr-2 sm-640:ltr:ml-4 sm-640:rtl:mr-4 items-center">
                        <span onClick={() => handleAspectRatio(ASPECT_RATIO.ONE_BY_ONE)}>
                          <OneByOne
                            className={`icon h-5 ${aspectRatio.label === '1:1' ? '' : 'main__icon'}`}
                            style={aspectRatio.label === '1:1' ? { color: accentColor } : {}}
                          />
                        </span>
                        <span onClick={() => handleAspectRatio(ASPECT_RATIO.THREE_BY_TWO)}>
                          <ThreeByTwo
                            className={`icon h-5 ${aspectRatio.label === '3:2' ? '' : 'main__icon'}`}
                            style={aspectRatio.label === '3:2' ? { color: accentColor } : {}}
                          />
                        </span>
                        <span onClick={() => handleAspectRatio(ASPECT_RATIO.THREE_BY_FOUR)}>
                          <ThreeByFour
                            className={`icon h-5 ${aspectRatio.label === '3:4' ? '' : 'main__icon'}`}
                            style={aspectRatio.label === '3:4' ? { color: accentColor } : {}}
                          />
                        </span>
                        <span onClick={() => handleAspectRatio(ASPECT_RATIO.SISXTEEN_BY_NINE)}>
                          <SixteenByNine
                            className={`icon h-5 ${aspectRatio.label === '16:9' ? '' : 'main__icon'}`}
                            style={aspectRatio.label === '16:9' ? { color: accentColor } : {}}
                          />
                        </span>
                        <span onClick={() => handleAspectRatio(ASPECT_RATIO.ORIGINAL)}>
                          <span
                            className="icon h-5 w-auto text-sm"
                            style={aspectRatio.label === ASPECT_RATIO.ORIGINAL ? { color: accentColor } : {}}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-6 h-6"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
                              />
                            </svg>
                          </span>
                        </span>
                      </div>
                    )}
                    <div className="w-1/2 flex justify-center ltr:mr-2 rtl:ml-2 items-center">
                      <ZoomOutIcon
                        className="h-6 w-6 flex-none rounded-full p-0.5 main__icon ltr:mr-2 rtl:ml-2"
                        style={{ color: accentColor }}
                        onClick={() => {
                          if (zoom <= 3 && zoom >= 1.1) {
                            setZoom((prevZoom) => prevZoom - 0.1);
                          }
                        }}
                      />
                      <Slider
                        value={zoom}
                        min={1}
                        max={3}
                        step={0.1}
                        onChange={(zoom) => {
                          setZoom(zoom);
                        }}
                        // railStyle={{ backgroundColor: accentColor }}
                        trackStyle={{ backgroundColor: accentColor }}
                        handleStyle={{
                          borderColor: accentColor,
                          backgroundColor: accentColor,
                        }}
                      />
                      <ZoomInIcon
                        className="h-6 w-6 flex-none rounded-full p-0.5 main__icon ltr:ml-2 rtl:mr-2"
                        style={{ color: accentColor }}
                        onClick={() => {
                          if (zoom <= 2.9 && zoom >= 1) {
                            setZoom((prevZoom) => prevZoom + 0.1);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default ImagePicker;
