import { TRIAL_PERIOD } from './pricingConstants';
import { FEATURES_FLAG_LIST, SUBSCRIPTION_EXHAUSTED_KEY } from './subscriptionConstants';
import { MONTHS_LIST_SHORT } from './timeConstants';

export const getTrialEndDate = (subscriptionDays) => {
  const todaysDate = Date.now();
  const endDateInMilliSec = todaysDate + subscriptionDays * 24 * 60 * 60 * 1000;
  const endDateObject = new Date(endDateInMilliSec);
  const endDate = `${
    MONTHS_LIST_SHORT[endDateObject.getMonth()]
  } ${endDateObject.getDate()}, ${endDateObject.getFullYear()}`;
  return endDate;
};

export const getTrialPeriod = (subscriptionDays) => {
  const todaysDate = Date.now();
  const endDateInMilliSec = todaysDate + subscriptionDays * 24 * 60 * 60 * 1000;
  const endDateObject = new Date(endDateInMilliSec);
  const startDateInMilliSec = todaysDate - (TRIAL_PERIOD - subscriptionDays) * 24 * 60 * 60 * 1000;
  const startDateObject = new Date(startDateInMilliSec);
  const trialDuration = `${
    MONTHS_LIST_SHORT[startDateObject.getMonth()]
  } ${startDateObject.getDate()}, ${startDateObject.getFullYear()} - 
      ${MONTHS_LIST_SHORT[endDateObject.getMonth()]} ${endDateObject.getDate()}, ${endDateObject.getFullYear()}`;
  return trialDuration;
};

// Process Components which are not recieving boolean feature flags instead number feature flags
export const getNumberData = (componentSubscriptionId, subscriptionData) => {
  if (componentSubscriptionId === FEATURES_FLAG_LIST.MAX_CHANNELS) {
    return {
      text: `${
        subscriptionData?.featureLimits[componentSubscriptionId] === 'unlimited'
          ? ''
          : `${subscriptionData?.exhausted?.[SUBSCRIPTION_EXHAUSTED_KEY.COMMUNITY_CHANNEL_COUNT]}/`
      }${subscriptionData?.featureLimits[componentSubscriptionId]}`,
      available:
        subscriptionData?.exhausted?.[SUBSCRIPTION_EXHAUSTED_KEY.COMMUNITY_CHANNEL_COUNT] >=
        subscriptionData?.featureLimits[componentSubscriptionId]
          ? false
          : true,
    };
  }
  if (componentSubscriptionId === FEATURES_FLAG_LIST.MAX_GROUPS) {
    return {
      text: `${
        subscriptionData?.featureLimits[componentSubscriptionId] === 'unlimited'
          ? ''
          : `${subscriptionData?.exhausted?.[SUBSCRIPTION_EXHAUSTED_KEY.COMMUNITY_GROUP_COUNT]}/`
      }${subscriptionData?.featureLimits[componentSubscriptionId]}`,
      available:
        subscriptionData?.exhausted?.[SUBSCRIPTION_EXHAUSTED_KEY.COMMUNITY_GROUP_COUNT] >=
        subscriptionData?.featureLimits[componentSubscriptionId]
          ? false
          : true,
    };
  }
  if (componentSubscriptionId === FEATURES_FLAG_LIST.SHOP_TRANSACTION_FEES) {
    return {
      available: subscriptionData?.featureLimits[FEATURES_FLAG_LIST.SHOP_TRANSACTION_FEES] === 0 ? false : true,
    };
  }
  if (componentSubscriptionId === FEATURES_FLAG_LIST.LIVE_STREAM_ZOOM_LIMIT) {
    return {
      available: subscriptionData?.featureLimits[FEATURES_FLAG_LIST.LIVE_STREAM_ZOOM_LIMIT] === 0 ? false : true,
    };
  }
  return {
    notNumber: true,
  };
};

export const checkComponentSubscriptionStatus = (
  toRenderSubscriptionUI,
  subscriptionData,
  componentSubscriptionId,
  isAfterSubscriptionCommunity,
  loackStatus = null,
) => {
  // Check for Old Communities
  if (!isAfterSubscriptionCommunity && typeof !isAfterSubscriptionCommunity === 'boolean') {
    return false;
  }
  // Check for Components Wrapped in subscription but subscription Id is not passed
  if (componentSubscriptionId === '') {
    return false;
  }
  if (
    subscriptionData?.featureLimits[componentSubscriptionId] === false ||
    typeof subscriptionData?.featureLimits[componentSubscriptionId] === 'string' ||
    typeof subscriptionData?.featureLimits[componentSubscriptionId] === 'number' ||
    !toRenderSubscriptionUI?.notNumber
  ) {
    if (
      toRenderSubscriptionUI.available &&
      componentSubscriptionId === FEATURES_FLAG_LIST.MAX_GROUPS &&
      location.pathname === '/'
    ) {
      return false;
    } else if (!toRenderSubscriptionUI.available) return true;
    if (loackStatus) {
      if (toRenderSubscriptionUI.available) {
        return false;
      } else return true;
    } else {
      if (location?.pathname?.includes('/community-settings') && toRenderSubscriptionUI.available) {
        if (toRenderSubscriptionUI.text) {
          return true;
        }
        return false;
      } else return false;
    }

    return true;
  }
  return false;
};
