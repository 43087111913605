import React from 'react';
import ReactPaginate from 'react-paginate';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline';

function Pagination({ pageCount, accentColor, handlePageClick, className = '' }) {
  const btnClass =
    'inline-flex items-center justify-center w-9 h-9 border border-gray-300 bg-white text-md font-medium text-gray-700';

  return (
    <div>
      <ReactPaginate
        nextLabel={<ChevronRightIcon className="w-5" />}
        previousLabel={<ChevronLeftIcon className="w-5" />}
        onPageChange={handlePageClick}
        pageRangeDisplayed={2}
        marginPagesDisplayed={1}
        pageCount={pageCount}
        pageLinkClassName={btnClass}
        previousLinkClassName={`${btnClass} rounded-l-md`}
        nextLinkClassName={`${btnClass} rounded-r-md`}
        breakLabel="..."
        breakLinkClassName={btnClass}
        containerClassName={`flex gap-2 ${className}`}
        activeLinkClassName="bg-gray-200 border-gray-400"
        renderOnZeroPageCount={null}
        disabledLinkClassName="cursor-not-allowed"
      />
    </div>
  );
}

export default Pagination;
