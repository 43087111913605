import { all, fork, put, takeEvery, takeLatest } from '@redux-saga/core/effects';
import { parseAxiosErrorMessage } from '../constants/utils';
import NotificationService from '../services/notificationService';
import {
  userBookmarkForumDownVoteService,
  userBookmarkForumUpVoteService,
  userForumBookmarks,
  userResourceBookmarks,
} from '../services/bookmarkServices';
import {
  DOWNVOTE_BOOKMARKED_POST_REQUEST,
  DOWNVOTE_BOOKMARKED_POST_SUCCESS,
  FETCH_USER_FORUM_BOOKMARKS_REQUEST,
  FETCH_USER_RESOURCE_BOOKMARKS_REQUEST,
  RESET_BOOKMARKS_SUCCESS,
  SET_USER_FORUM_BOOKMARKS,
  SET_USER_FORUM_BOOKMARKS_PAGE_MANAGERS,
  SET_USER_RESOURCE_BOOKMARKS,
  SET_USER_RESOURCE_BOOKMARKS_LOADING,
  SET_USER_RESOURCE_BOOKMARKS_PAGE_MANAGERS,
  UPVOTE_BOOKMARKED_POST_REQUEST,
  UPVOTE_BOOKMARKED_POST_SUCCESS,
} from '../store/actions/actionTypes';

const getResourcesForData = (posts) => {
  const postIds = {};
  if (!posts) {
    return postIds;
  }
  posts.forEach((item) => {
    postIds[item?.id] = item;
  });
  return postIds;
};

export const getPostsIdsForData = (posts) => {
  const postIds = {};
  if (!posts) {
    return postIds;
  }
  posts.forEach((item) => {
    postIds[item?.attributes?.forum_post_id] = item?.attributes;
  });
  return postIds;
};

export const bookmarkForumModel = (data, forceValues) => {
  const { forum_posts, forum_post_votes } = data;

  const forumPosts = [];
  const postVotes = forum_post_votes?.data;

  const votedPostsIds = getPostsIdsForData(postVotes);

  forum_posts.forEach((post) => {
    let postData = post;

    const postVoted = votedPostsIds[post.id];

    if (postVoted) {
      postData.voted = true;
      postData.vote = postVoted?.vote_flag;
    }

    if (forceValues) {
      postData = { ...postData, ...forceValues };
    }
    forumPosts.push(postData);
  });

  return forumPosts;
};

const bookmarkResourceModel = (resourceData, forceValues) => {
  const { resource_posts, resource_post_likes } = resourceData;
  const resources = [];
  const resourceVotes = resource_post_likes?.data;
  const votedResourceIds = getResourcesForData(resourceVotes);

  resource_posts.forEach((post) => {
    let postData = post;

    const postVoted = votedResourceIds[post.id];

    if (postVoted) {
      postData.voted = true;
      postData.vote = postVoted?.vote_flag;
    }

    if (forceValues) {
      postData = { ...postData, ...forceValues };
    }
    resources.push(postData);
  });

  return resources;
};

function* fetchUserForumBookmarks() {
  yield takeLatest(FETCH_USER_FORUM_BOOKMARKS_REQUEST, function* (queryParamsAction) {
    try {
      const forumBookmarks = yield userForumBookmarks(
        queryParamsAction?.payload ? { params: queryParamsAction.payload } : {},
      );
      const forumPosts = bookmarkForumModel(forumBookmarks?.data?.data, {
        hasBookmarked: true,
      });

      yield put({
        type: SET_USER_FORUM_BOOKMARKS,
        payload: { forumPosts, channelPermissions: forumBookmarks?.data?.data?.channel_permissions },
        loading: false,
      });

      const forumBookmarksLinks = forumBookmarks?.data?.data?.links || {};

      yield put({
        type: SET_USER_FORUM_BOOKMARKS_PAGE_MANAGERS,
        payload:
          {
            page: forumBookmarksLinks.page,
            pages: forumBookmarksLinks.pages,
            count: forumBookmarksLinks.count,
            last: forumBookmarksLinks.last,
            items: forumBookmarksLinks.items,
          } || {},
      });

      yield put({
        type: SET_USER_RESOURCE_BOOKMARKS_LOADING,
        payload: false,
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);

      yield put({
        type: SET_USER_RESOURCE_BOOKMARKS_LOADING,
        payload: false,
      });
    }
  });
}

function* fetchUserResourceBookmarks() {
  yield takeLatest(FETCH_USER_RESOURCE_BOOKMARKS_REQUEST, function* (queryParamsAction) {
    try {
      const resourceBookmarks = yield userResourceBookmarks(
        queryParamsAction?.payload ? { params: queryParamsAction.payload } : {},
      );
      const resources = bookmarkResourceModel(resourceBookmarks.data.data, {
        hasBookmarked: true,
      });

      yield put({
        type: SET_USER_RESOURCE_BOOKMARKS,
        payload: { resources, channelPermissions: resourceBookmarks?.data?.data?.channel_permissions },
        loading: false,
      });

      const resourceBookmarksLinks = resourceBookmarks?.data?.data?.links || {};

      yield put({
        type: SET_USER_RESOURCE_BOOKMARKS_PAGE_MANAGERS,
        payload:
          {
            page: resourceBookmarksLinks.page,
            pages: resourceBookmarksLinks.pages,
            count: resourceBookmarksLinks.count,
            last: resourceBookmarksLinks.last,
            items: resourceBookmarksLinks.items,
          } || {},
      });

      yield put({
        type: SET_USER_RESOURCE_BOOKMARKS_LOADING,
        payload: false,
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);

      yield put({
        type: SET_USER_RESOURCE_BOOKMARKS_LOADING,
        payload: false,
      });
    }
  });
}

function* upVoteBookmarkedForumPost() {
  yield takeEvery(UPVOTE_BOOKMARKED_POST_REQUEST, function* ({ payload }) {
    try {
      const upvoteRes = yield userBookmarkForumUpVoteService(payload);
      yield put({ type: UPVOTE_BOOKMARKED_POST_SUCCESS, payload });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* downVoteBookmarkedForumPost() {
  yield takeEvery(DOWNVOTE_BOOKMARKED_POST_REQUEST, function* ({ payload }) {
    try {
      const downvoteRes = yield userBookmarkForumDownVoteService(payload);
      yield put({ type: DOWNVOTE_BOOKMARKED_POST_SUCCESS, payload });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* resetBookmarks() {
  yield put({ type: RESET_BOOKMARKS_SUCCESS });
}

export default function* rootSaga() {
  yield all([
    fork(fetchUserForumBookmarks),
    fork(fetchUserResourceBookmarks),
    fork(upVoteBookmarkedForumPost),
    fork(downVoteBookmarkedForumPost),
    fork(resetBookmarks),
  ]);
}
