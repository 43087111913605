import server from '../api/server';

export function fetchAllSearchResults(query, activeCommunityId, activeGroupId) {
  return server.get(`/communities/${activeCommunityId}/groups/${activeGroupId}/global_search`, {
    params: {
      search: query,
      page: 1,
    },
  });
}

export function fetchSearchResults(query, queryType, page, activeCommunityId, activeGroupId) {
  return server.get(`/communities/${activeCommunityId}/groups/${activeGroupId}/global_search`, {
    params: {
      search: query || '',
      page: page,
      query_type: queryType,
    },
  });
}

export function fetchGloabalSearchUsers(activeCommunityId, activeGroupId, query, page = 1, queryType = 'user') {
  return server.get(`/communities/${activeCommunityId}/groups/${activeGroupId}/global_search`, {
    params: {
      search: query,
      page,
      query_type: queryType,
      per_page: 50,
    },
  });
}

export function fetchGlobalSearchHashTags(query, queryType, page, activeCommunityId, activeGroupId) {
  return server.get(`/communities/${activeCommunityId}/groups/${activeGroupId}/global_search`, {
    params: {
      search: query,
      page,
      query_type: queryType,
      per_page: 50,
    },
  });
}
