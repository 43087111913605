import { all, fork, put, takeLatest } from 'redux-saga/effects';
import { SUCCESS_MESSAGES } from '../constants/notificationMessages';
import { analyticsDefaults, parseAxiosErrorMessage } from '../constants/utils';
import {
  createForumPostService,
  fetchAllCommunityChannels,
  createCommunityChannel,
  createChannelCategory,
  updateCommunityChannel,
  updateChannelCategory,
  updateCategoryPositionService,
  updateChannelPositionService,
  fetchForumMediaChannelsWithPermisson,
} from '../services/channelService';
import NotificationService from '../services/notificationService';
import {
  CREATE_CHANNEL_REQUEST,
  CREATE_CHANNEL_SUCCESS,
  UPDATE_CHANNEL_REQUEST,
  UPDATE_CHANNEL_SUCCESS,
  CREATE_CHANNEL_CATEGORY_REQUEST,
  CREATE_CHANNEL_CATEGORY_SUCCESS,
  FETCH_ALL_COMMUNITY_CHANNELS_REQUEST,
  FETCH_ALL_COMMUNITY_CHANNELS_SUCCESS,
  UPDATE_CHANNEL_CATEGORY_REQUEST,
  UPDATE_CHANNEL_CATEGORY_SUCCESS,
  FETCH_GROUPS_REQUEST,
  UPDATE_CATEGORY_POSITION,
  UPDATE_CHANNEL_POSITION,
  FETCH_CHANNELS_REQUEST,
  FETCH_CHANNELS_SUCCESS,
} from '../store/actions/actionTypes';
import {
  fetchAllCommunityChannels as fetchAllCommunityChannelsSagaCall,
  setCurrentChannels,
} from '../store/actions/channelActions';
import { changeSubscriptionUseData } from '../components/subscription/subscriptionUtils';
import { SUBSCRIPTION_EXHAUSTED_KEY } from '../constants/subscriptionConstants';
import AnalyticsService from '../services/AnalyticsService';
import { COMMUNITY_PUBLISH_EVENT_TITLE } from '../constants';

let prevCommunityId;

function* fetchAllCommunityChannelsSaga() {
  yield takeLatest(FETCH_ALL_COMMUNITY_CHANNELS_REQUEST, function* ({ payload }) {
    try {
      const { activeCommunityId , groupId: activeGroupId } = payload
      const response = yield fetchAllCommunityChannels(payload);
      const { community } = response.data;

      prevCommunityId = community?.id;

      yield put({
        type: FETCH_ALL_COMMUNITY_CHANNELS_SUCCESS,
        payload: response,
      });

      yield put({
        type: FETCH_CHANNELS_REQUEST,
        payload: {activeCommunityId, activeGroupId}
      })
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* createCommunityChannelSaga() {
  yield takeLatest(CREATE_CHANNEL_REQUEST, function* ({ payload }) {
    try {
      const response = yield createCommunityChannel(payload);

      const { data } = response;

      AnalyticsService.trackAmplitudeEvent(COMMUNITY_PUBLISH_EVENT_TITLE.COMMUNITY_NEW_CHANNEL_CREATED, {
        ...analyticsDefaults(true),
        channel_type: data?.channel_type,
        channel_name: data?.name,
        channel_id: data?.id,
        category_id: data?.community_category_id,
        groud_id: data?.group_id,
      });

      yield put({
        type: CREATE_CHANNEL_SUCCESS,
        payload: response,
      });
      changeSubscriptionUseData(SUBSCRIPTION_EXHAUSTED_KEY.COMMUNITY_CHANNEL_COUNT, 1);
      NotificationService.success(SUCCESS_MESSAGES.CHANNEL_CREATE);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* updateCommunityChannelSaga() {
  yield takeLatest(UPDATE_CHANNEL_REQUEST, function* ({ payload }) {
    try {
      const response = yield updateCommunityChannel(payload);

      yield put({
        type: UPDATE_CHANNEL_SUCCESS,
        payload: response,
      });

      NotificationService.success(SUCCESS_MESSAGES.CHANNEL_UPDATE);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* createChannelCategorySaga() {
  yield takeLatest(CREATE_CHANNEL_CATEGORY_REQUEST, function* ({ payload }) {
    try {
      const response = yield createChannelCategory(payload);

      const { data } = response;

      AnalyticsService.trackAmplitudeEvent(COMMUNITY_PUBLISH_EVENT_TITLE.COMMUNITY_NEW_CATEGORY_CREATED, {
        ...analyticsDefaults(true),
        category_name: data?.name,
        category_id: data?.id,
        groud_id: data?.group_id,
      });

      yield put({
        type: CREATE_CHANNEL_CATEGORY_SUCCESS,
        payload: response?.data,
      });

      NotificationService.success(SUCCESS_MESSAGES.CHANNEL_CATEGORY_CREATE);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* updateChannelCategorySaga() {
  yield takeLatest(UPDATE_CHANNEL_CATEGORY_REQUEST, function* ({ payload }) {
    try {
      const response = yield updateChannelCategory(payload);

      yield put({
        type: UPDATE_CHANNEL_CATEGORY_SUCCESS,
        payload: response,
      });

      NotificationService.success(SUCCESS_MESSAGES.CHANNEL_CATEGORY_UPDATE);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* updateCategoryPositionSaga() {
  yield takeLatest(UPDATE_CATEGORY_POSITION, function* ({ payload }) {
    try {
      yield put(setCurrentChannels(payload?.orderedChannel));

      yield updateCategoryPositionService(payload?.groupId, payload?.categoryId, payload?.position);
      yield put(fetchAllCommunityChannelsSagaCall({ groupId: payload?.groupId, activeCommunityId: payload?.activeCommunityId }));
      // NotificationService.success(SUCCESS_MESSAGES.CHANNEL_CATEGORY_UPDATE);
    } catch (error) {
      yield put(setCurrentChannels(payload?.currentChannel));
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* updateChannelPositionSaga() {
  yield takeLatest(UPDATE_CHANNEL_POSITION, function* ({ payload }) {
    try {
      yield put(setCurrentChannels(payload?.orderedChannel));

      yield updateChannelPositionService(payload?.groupId, payload?.channelId, payload?.position);
      yield put(fetchAllCommunityChannelsSagaCall({ groupId: payload?.groupId, activeCommunityId: payload?.activeCommunityId }));
      // NotificationService.success(SUCCESS_MESSAGES.CHANNEL_UPDATE);
    } catch (error) {
      yield put(setCurrentChannels(payload?.currentChannel));
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* getForumMediaChannelsWithPermisson() {
  yield takeLatest(FETCH_CHANNELS_REQUEST, function* ({ payload }) {
    try {
      const { activeCommunityId, activeGroupId } = payload
      if (activeCommunityId && activeGroupId) {
        const permissionsResponse = yield fetchForumMediaChannelsWithPermisson(activeCommunityId, activeGroupId);

        const { data } = permissionsResponse;
        yield put({
          type: FETCH_CHANNELS_SUCCESS,
          payload: data
        })
      }

    } catch (error) {
      if (error.response?.data.code === 401) {
        NotificationService.error('You are not authorized to perform this action');
      } else {
        NotificationService.error('Something went wrong');
      }
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(fetchAllCommunityChannelsSaga),
    fork(createCommunityChannelSaga),
    fork(createChannelCategorySaga),
    fork(updateCommunityChannelSaga),
    fork(updateChannelCategorySaga),
    fork(updateCategoryPositionSaga),
    fork(updateChannelPositionSaga),
    fork(getForumMediaChannelsWithPermisson)
  ]);
}
