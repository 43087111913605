/* eslint-disable global-require */
/* eslint-disable no-async-promise-executor */
import { getPresignedPostData, uploadFileToS3, imageFetch } from '../../services/preSignedAws';
import Canvas from './canvasHelpers';
import GradientsArr from './gradients';

export const getGradientJPG = (direction, colors) => {
  const canvas = document.createElement('canvas');

  canvas.id = 'canva';
  canvas.width = screen.width; // eslint-disable-line
  canvas.height = screen.height; // eslint-disable-line
  canvas.style.zIndex = 1;
  canvas.style.position = 'absolute';
  document.body.appendChild(canvas);

  const canva = document.getElementById('canva');
  const ctx = canva.getContext('2d');

  const dir = Canvas.generateCoordinates(direction, canvas.height, canvas.width);
  const grd = ctx.createLinearGradient(...dir);

  ctx.fillStyle = Canvas.generateFillStyle(grd, ...colors);
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  ctx.fillStyle = Canvas.generateFillStyle(grd, ...colors);

  const jpegData = canvas.toDataURL('image/jpeg');

  document.getElementById('canva').remove();
  return jpegData;
};

export const getRandomGradientColorsArr = () => {
  const randomIndex = Math.floor(Math.random() * GradientsArr.length);

  return GradientsArr[randomIndex].colors;
};

// For uploading To S3
function base64StringtoFile(base64String, filename) {
  const arr = base64String.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1], 'base64');
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export const uploadJpegBase64ToS3 = (
  jpegBase64,
  returnRelativePath = false,
  params = {},
) => new Promise(async (resolve, reject) => {
  const file = base64StringtoFile(jpegBase64, 'cover');

  const fileObj = {
    uri: file,
    name: `IMG_${Date.now()}`,
    type: 'image/jpeg',
  };

  const url = 'onboarding/request_presigned_url';
  const data = await getPresignedPostData(url, fileObj.name, params);

  uploadFileToS3(data, fileObj)
    .then(async (response) => {
      window.DOMParser = require('xmldom').DOMParser;
      const responseDoc = new DOMParser().parseFromString(response.data, 'application/xml');
      const imageSrc = responseDoc.getElementsByTagName('Key')[0].childNodes[0].nodeValue;
      if (returnRelativePath) resolve(imageSrc);
      const img = await imageFetch(imageSrc, {}, false);
      resolve(img);
    })
    .catch((err) => {
      reject(err);
    });
});
