import React from 'react';
import HideContentWrapper from '../hideContent/HideContentWrapper';

export default function SidebarHideContent({ width, height }) {
  return (
    <div className="sidebar__main_w">
      <HideContentWrapper
        circle={{ width: '1.25rem', height: '1.25rem' }}
        bar={{ width: '7.4375rem', height: '1.25rem' }}
      />

      {Array(3)
        .fill(0)
        .map((_, index) => (
          <div className="hide_block" key={`sidebar_hide_block_${index}`}>
            <HideContentWrapper bar={{ width: '6.8125rem', height: '1.25rem' }} />
            <HideContentWrapper
              circle={{ width: '1.25rem', height: '1.25rem' }}
              bar={{ width: '7.3125rem', height: '1.25rem' }}
            />
            <HideContentWrapper
              circle={{ width: '1.25rem', height: '1.25rem' }}
              bar={{ width: '8.375rem', height: '1.25rem' }}
            />
            <HideContentWrapper
              circle={{ width: '1.25rem', height: '1.25rem' }}
              bar={{ width: '5.6875rem', height: '1.25rem' }}
            />
            <HideContentWrapper
              circle={{ width: '1.25rem', height: '1.25rem' }}
              bar={{ width: '7.9375rem', height: '1.25rem' }}
            />
          </div>
        ))}
    </div>
  );
}
