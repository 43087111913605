import { ADD_RESOURCE_PERMISSIONS, REFETCH_RESORUCES } from '../actions/actionTypes';
import { getResourcePermissionConfig } from '../models/community';

const initialState = {
  channelPermissionsConfig: {},
  refetchResoruces: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_RESOURCE_PERMISSIONS:
      return {
        ...state,
        channelPermissionsConfig: getResourcePermissionConfig(action.channelPermissions),
      };

    case REFETCH_RESORUCES:
      return {
        ...state,
        refetchResoruces: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
