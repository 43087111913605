import { ChevronLeftIcon, DotsVerticalIcon, MailIcon, PencilAltIcon, SearchIcon } from '@heroicons/react/outline';
import { useEffect, useState, Fragment } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader, { ThreeDots } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { INBOX_TYPES } from '../../constants';
import { classNames, prepareUserImageName } from '../../constants/utils';
import {
  getActiveCommunityAccentColor,
  getActiveCommunityFontsClass,
  getActiveCommunityId,
  getUnreadDmCount,
} from '../../selectors/CommunitySelectors';
import { imageFetch } from '../../services/preSignedAws';
import {
  deleteUserChat,
  listPrivateRoomsForInbox,
  resetPrivateChatTextRoomState,
  setActivePrivateRoom,
  setActivePrivateRoomFromUserId,
  setListInboxUserLoading,
  setPrivateInboxType,
} from '../../store/actions/privateChatActions';
import EllipseIcon from '../../assets/images/icons/ellipse.svg';
import LoadingSpinner from '../../components/shared/tailwind/LoadingSpinner';
import CommonNameAvatar, {
  COMMON_NAME_AVATAR_SIZE,
  COMMON_NAME_AVATAR_TYPE,
} from '../../components/shared/tailwind/NameAvatar';
import { setUnreadDmCount } from '../../store/actions/communityActions';
import { setNewChatRequest } from '../../store/actions/chatActions';
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { getFullName } from '../../utils/Utils';

function SidebarEmptyState() {
  return (
    <div className="h-full flex justify-center items-center text-center text-gray-800 text-18 px-2 font-bold text__body">
      <p>Your conversations will show up here</p>
    </div>
  );
}

function UserImage({ image, first_name, last_name, imgClasses, imgSize, online }) {
  const [isImageLoadingError, setIsImageLoadingError] = useState(false);
  return (
    <>
      {image && !isImageLoadingError ? (
        <div className="relative">
          <img
            className={imgClasses}
            src={imageFetch(image)}
            onError={() => setIsImageLoadingError(true)}
            alt="profile"
          />
          {online && (
            <div className="dot h-2.5 w-2.5 bg-green-500 absolute rounded-full right-1 bottom-0" />
          )}
        </div>
      ) : (
        <div className="relative">
          <CommonNameAvatar
            name={prepareUserImageName(first_name, last_name)}
            type={COMMON_NAME_AVATAR_TYPE.CIRCULAR}
            size={imgSize}
          />
          {online && (
            <div className="dot h-2.5 w-2.5 bg-green-500 absolute rounded-full right-1 bottom-0" />
          )}
        </div>
      )}
    </>
  );
}

export default function Sidebar({ openCreateNewConversationModal, userId, accept }) {
  const activeCommunityAccentColor = useSelector(getActiveCommunityAccentColor);
  const activeCommunityId = useSelector(getActiveCommunityId);
  const privateTextRooms = useSelector((state) => state?.privateChat?.privateTextRooms);
  const privateTextRoomNextId = useSelector((state) => state?.privateChat?.privateTextRoomNextId);
  const activePrivateRoom = useSelector((state) => state?.privateChat?.activePrivateRoom);
  const inboxType = useSelector((state) => state?.privateChat?.inboxType);
  const requestsCount = useSelector((state) => state?.privateChat?.requestsCount);
  const privateTextRoomLoading = useSelector((state) => state?.privateChat?.privateTextRoomLoading);
  const unreadDm = useSelector(getUnreadDmCount);
  const history = useHistory();
  const { primaryFont } = useSelector(getActiveCommunityFontsClass);

  const dispatch = useDispatch();

  const loadMorePrivateRooms = () => {
    dispatch(listPrivateRoomsForInbox({ reset: false, nextPageId: privateTextRoomNextId }));
  };

  useEffect(() => {
    if (inboxType) {
      dispatch(
        listPrivateRoomsForInbox({
          reset: true,
          nextPageId: privateTextRoomNextId,
          inboxType,
        }),
      );
    }
  }, [inboxType]);

  useEffect(() => {
    if (userId && privateTextRoomLoading === false) {
      dispatch(
        setActivePrivateRoomFromUserId({
          userId,
          privateTextRooms,
          communityId: activeCommunityId,
          history,
          inboxType,
          privateTextRoomId: history?.location?.state?.privateTextRoomId,
          accept,
          requestsCount,
        }),
      );
    }
  }, [userId, privateTextRoomLoading]);

  const switchActivePrivateRoom = (data) => {
    history.push(
      {
        pathname: '/inbox',
        search:
          inboxType === INBOX_TYPES.INBOX ? `?user_id=${data?.user?.id}` : `?user_id=${data?.user?.id}&type=request`,
      },
      { privateTextRoomId: data?.id },
    );
    if (activeCommunityId == 3864) {
      dispatch(setActivePrivateRoom(data?.id));
    }
  };

  const handleUserChatDelete = (data) => {
    dispatch(deleteUserChat({private_text_room_id: data?.id, community_id: data?.community_id, inboxType:INBOX_TYPES.INBOX}))
  }

  const switchInboxType = (value) => {
    if (value === inboxType) {
      dispatch(setActivePrivateRoom(null));
      history.replace('/inbox');
      return;
    }

    dispatch(resetPrivateChatTextRoomState());
    dispatch(setNewChatRequest(false))
    dispatch(setPrivateInboxType(value));
    history.replace('/inbox');
  };

  if (privateTextRoomLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="h-full">
      {/* Header */}
      <div className="border-b flex py-4 items-center justify-between inbox__sidebar__header">
        {inboxType === INBOX_TYPES.INBOX ? (
          <>
            <button
              className="flex align-center ltr:pl-5 rtl:pr-5 cursor-pointer text-gray-800 text-16 font-semibold text__title"
              onClick={() => {switchInboxType(INBOX_TYPES.INBOX); dispatch(setNewChatRequest(false)) }}
              type="button"
            >
              <MailIcon className="h-5 w-5" />
              <span className="ltr:ml-1 rtl:mr-1 pt-0.5">{$translatei18n('Inbox')}</span>
            </button>
            <button
              className="pt-1 text-16 cursor-pointer background-transparent font-bold px-3 outline-none focus:outline-none ltr:mr-1 rtl:ml-1 mb-1 ease-linear transition-all disabled:opacity-50 text__link"
              type="button"
              style={{ color: activeCommunityAccentColor }}
              onClick={() => switchInboxType(INBOX_TYPES.REQUESTS)}
              disabled={!requestsCount || requestsCount <= 0}
            >
              {$translatei18n('Requests')} ({requestsCount})
            </button>
          </>
        ) : (
          <>
            <button
              className="flex align-center ltr:pl-5 rtl:pr-5 cursor-pointer text-gray-800 text-16 text__link"
              type="button"
              style={{ color: activeCommunityAccentColor }}
              onClick={() => switchInboxType(INBOX_TYPES.INBOX)}
            >
              <ChevronLeftIcon className="h-5 w-5" />
              <span className="ltr:ml-1 rtl:mr-1 pt-0.5">{$translatei18n('MessageRequests')} ({`${requestsCount}`})</span>
            </button>
            {/* <button
                  className="hidden md:block text-red-500 pt-1 text-16 cursor-pointer background-transparent font-bold px-3 outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all text__danger"
                  type="button"
                >
                  Delete All
                </button> */}
          </>
        )}
      </div>
      <section className="h-full my-1">
        {privateTextRooms?.length > 0 ? (
          <InfiniteScroll
            dataLength={privateTextRooms?.length}
            next={loadMorePrivateRooms}
            hasMore={!!privateTextRoomNextId}
            style={{paddingBottom: '60px'}}
            loader={
              <div
                style={{
                  display: 'flex',
                  flex: 1,
                  marginTop: 10,
                  marginBottom: 10,
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ThreeDots color="#00BFFF" height={30} width={30} />
              </div>
            }
            scrollableTarget="inbox-sidebar"
          >
            {privateTextRooms?.map((data, index) => (
              <div className={`relative group`}>
                <div
                  onClick={() => {
                    if (unreadDm > 0) {
                      dispatch(setUnreadDmCount(unreadDm - 1));
                    }
                    switchActivePrivateRoom(data);
                  }}
                  className={classNames(
                    'flex-shrink-0 flex  py-2 px-4 cursor-pointer rounded',
                    activePrivateRoom === data?.id ? `btn__primary ${primaryFont}` : ' text__body',
                  )}
                  style={{
                    backgroundColor: activePrivateRoom === data?.id ? activeCommunityAccentColor : '',
                  }}
                  role="button"
                  onKeyPress={() => switchActivePrivateRoom(data)}
                  tabIndex="-1"
                  key={data?.id}
                >
                  <a className="flex-shrink-0 w-full block">
                    <div className="flex items-center">
                      <div>
                        <UserImage
                          imgClasses="inline-block h-12 w-12 rounded-full"
                          image={data?.user?.image}
                          first_name={data?.user?.first_name}
                          last_name={data?.user?.last_name}
                          online={data?.user?.online}
                          imgSize={COMMON_NAME_AVATAR_SIZE.LG}
                        />
                      </div>
                      <div className="ml-3">
                        <p className={classNames('text-sm font-bold')}>
                          {getFullName(data?.user?.first_name, data?.user?.last_name)}
                        </p>
                        {/* <p
                            className={classNames(
                              'text-xs font-medium text-gray-500',
                              activePrivateRoom === data?.id ? 'text-gray-700' : 'group-hover:text-gray-700',
                            )}>
                            Can you update me on this? &bull; 7h
                          </p> */}
                      </div>
                      {data?.unread && <img src={EllipseIcon} className="icon ltr:mr-2 rtl:ml-2 ltr:ml-auto rtl:mr-auto h-3 w-3" alt="Online" />}
                    </div>
                  </a>
                </div>
                {inboxType === INBOX_TYPES.INBOX ? 
                  <div className="flex h-full right-4 top-1/2 -translate-y-1/2 absolute items-center z-50">
                    <Menu as="div" className="relative inline-block text-left">
                        <MenuButton
                            className="rounded-md items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-0 focus:ring-offset-0 hidden xs:block group-hover:block"
                          >
                          <span className="sr-only">Open options</span>
                          <DotsVerticalIcon className="h-3 w-3 chat__sender__name" aria-hidden="true" />
                      </MenuButton>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <MenuItems className="z-50 origin-top-left absolute right-0 top-2 mt-2 w-56 rounded-md shadow-lg border menu__options__container focus:outline-none">
                          <div className="py-1 z-50">
                            <MenuItem onClick={() => handleUserChatDelete(data)}>
                                {({ active }) => (
                                  <a
                                    href="#"
                                    className={classNames(
                                      active && 'menu__option--active',
                                      'block px-4 py-2 text-sm text-red-500',
                                    )}
                                  >
                                    {$translatei18n('deleteText')}
                                  </a>
                                )}
                              </MenuItem>
                          </div>
                        </MenuItems>
                      </Transition>
                    </Menu>
                  </div>
                : null}
              </div>
            ))}
          </InfiniteScroll>
        ) : (
          <SidebarEmptyState />
        )}
      </section>
    </div>
  );
}
