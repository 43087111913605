import { toast } from 'react-toastify';
import { CheckCircleIcon, XIcon, InformationCircleIcon, ExclamationIcon, XCircleIcon } from '@heroicons/react/solid';
import { useSelector } from 'react-redux';
import { classNames } from '../constants/utils';
import { getActiveCommunityFontsClass } from '../selectors/CommunitySelectors';

const ToastCustomCloseButton = ({ closeToast, type }) => {
  let colorName = '';

  switch (type) {
    case 'success':
      colorName = 'green';
      break;
    case 'info':
      colorName = 'blue';
      break;
    case 'error':
      colorName = 'red';
      break;
    case 'warning':
      colorName = 'yellow';
      break;
    default:
      colorName = 'white';
      break;
  }

  return (
    <button
      type="button"
      className={`inline-flex bg-${colorName}-50 rounded-md p-1.5 text-${colorName}-500 hover:bg-${colorName}-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-${colorName}-50 focus:ring-${colorName}-600`}
      onClick={closeToast}
    >
      <span className="sr-only">Dismiss</span>
      <XIcon className="h-5 w-5" aria-hidden="true" />
    </button>
  );
};

const ToastBodyText = ({ heading, description = '', colorClass }) => {
  const { primaryFont } = useSelector(getActiveCommunityFontsClass);
  return (
    <div>
      <div className={classNames('text-sm', colorClass + 800, primaryFont)}>{heading}</div>
      <div className={classNames('text-sm', colorClass + 700)}>{description}</div>
    </div>
  );
};

class NotificationService {
  static success(message, description) {
    toast.success(<ToastBodyText heading={message} description={description} colorClass={'text-green-'} />, {
      className: 'bg-green-50 w-full min-h-0 mb-4 rounded-md shadow-sm',
      bodyClassName: 'items-start',
      icon: <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />,
      closeButton: ToastCustomCloseButton,
    });
  }

  static error(message, description) {
    toast.error(<ToastBodyText heading={message} description={description} colorClass={'text-red-'} />, {
      className: 'bg-red-50 w-full min-h-0 mb-4 rounded-md shadow-sm',
      bodyClassName: 'items-start',
      icon: <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />,
      closeButton: ToastCustomCloseButton,
    });
  }

  static warning(message, description) {
    toast.warning(<ToastBodyText heading={message} description={description} colorClass={'text-yellow-'} />, {
      className: 'bg-yellow-50 w-full min-h-0 mb-4 rounded-md shadow-sm',
      bodyClassName: 'items-start',
      icon: <ExclamationIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />,
      closeButton: ToastCustomCloseButton,
    });
  }

  static info(message, description) {
    toast.info(<ToastBodyText heading={message} description={description} colorClass={'text-blue-'} />, {
      className: 'bg-blue-50 w-full min-h-0 mb-4 rounded-md shadow-sm',
      bodyClassName: 'items-start',
      icon: <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />,
      closeButton: ToastCustomCloseButton,
    });
  }
}

export default NotificationService;
