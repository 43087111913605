import { CopyIcon } from '@100mslive/react-icons';
import { MailIcon } from '@heroicons/react/solid';
import LinkedInIcon from '../../../assets/images/share-icons/linkedin.png';
import TwitterIcon from '../../../assets/images/share-icons/twitter.png';
import FacebookIcon from '../../../assets/images/share-icons/facebook.png';
import WhatsappIcon from '../../../assets/images/share-icons/whatsapp.png';
import RedditIcon from '../../../assets/images/share-icons/reddit.png';
import TelegramIcon from '../../../assets/images/share-icons/telegram.png';
import { useDispatch, useSelector } from 'react-redux';
import { Fragment, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { getActiveCommunity } from '../../../selectors/CommunitySelectors';
import { fetchRoles } from '../../../store/actions/rolesAction';
import { handleInviteCopy } from '../../../utils/Utils';
import NotificationService from '../../../services/notificationService';
import { analyticsDefaults, renderRolesAsString } from '../../../constants/utils';
import AnalyticsService from '../../../services/AnalyticsService';
import { INVITE_USERS_AMPLITUDE_EVENT_TITLE, SEND_INVITE_TRIGGERED_FROM } from '../../../constants';

const SendInviteContent = ({ createdInviteDetails }) => {
  const { name, invite_code, link_limit, link_uses, roles_ids, expires_at, nonEditable, message } =
    createdInviteDetails || {};

  const dispatch = useDispatch();
  const activeCommunity = useSelector(getActiveCommunity);
  const communityRoles = useSelector((state) => state?.roles?.communityRoles);
  const communityConfig = useSelector((state) => state?.community?.communityConfig);
  const communityUserRoles = useSelector((state) => state?.community?.communityUserRoles);

  const [link, setLink] = useState(null);
  const [shortenedLink, setShortenedLink] = useState(null);

  const [inviteMessage, setInviteMessage] = useState(
    message
      ? message
      : sessionStorage.getItem('invite_message') ||
          (roles_ids
            ? `You’re invited for ${renderRolesAsString(
                roles_ids,
                communityRoles,
              )} role. Join us to be part of engaging discussions, access exclusive resources, and connect with like-minded individuals.`
            : "We're thrilled to invite you to join our community! Get ready to be part of engaging discussions, access exclusive resources, and connect with like-minded individuals."),
  );
  const [inviteMessageLimitError, setInviteMessageLimitError] = useState(false);

  useEffect(() => {
    if (communityConfig?.canManageRoles) {
      if (!activeCommunity?.id) return;
      dispatch(fetchRoles({ activeCommunityId: activeCommunity.id }));
    }
  }, [activeCommunity]);

  useEffect(() => {
    if (inviteMessage.length > 250) {
      setInviteMessageLimitError(true);
    } else if (inviteMessage.length <= 250 && inviteMessageLimitError === true) {
      setInviteMessageLimitError(false);
    }
  }, [inviteMessage]);

  useEffect(() => {
    const webpage = new URL(window.location.origin);
    if (invite_code) {
      webpage.searchParams.set('invite_code', invite_code);
    } else {
      webpage.searchParams.set('referral_code', localStorage.getItem('MyReferralCode'));
    }
    setLink(webpage);
    setShortenedLink(webpage.href);
  }, [invite_code]);

  const handleCopyShareLink = (shareLink) => {
    const eventTitle = INVITE_USERS_AMPLITUDE_EVENT_TITLE.INVITE_LINK_COPY;
    AnalyticsService.trackAmplitudeEvent(eventTitle, {
      ...analyticsDefaults(true),
      source: sessionStorage.getItem(SEND_INVITE_TRIGGERED_FROM),
      role: communityUserRoles
        .reduce((arr, role) => {
          arr.push(role?.role);
          return arr;
        }, [])
        .join(', '),
    });
    handleInviteCopy(shareLink);
  };

  const handleInviteMessageChange = (e) => {
    sessionStorage.setItem('invite_message', e.target.value);
    setInviteMessage(e.target.value);
  };

  const trackAmplitudeEvent = (value) => {
    AnalyticsService.trackAmplitudeEvent(INVITE_USERS_AMPLITUDE_EVENT_TITLE.SHARE_SOCIAL_MEDIA_ICON_CLICKED, {
      ...analyticsDefaults(true),
      value: value,
      source: sessionStorage.getItem(SEND_INVITE_TRIGGERED_FROM),
      role: communityUserRoles
        .reduce((arr, role) => {
          arr.push(role?.role);
          return arr;
        }, [])
        .join(', '),
    });
  };

  const shareOnFacebook = () => {
    trackAmplitudeEvent('fb');
    const url = `https://www.facebook.com/sharer/sharer.php?u=${shortenedLink}`;
    window.open(url, '');
  };

  const shareOnTwitter = () => {
    if (inviteMessage.length > 250) {
      NotificationService.error($translatei18n('YouHaveExceeded250'));
    } else {
      trackAmplitudeEvent('twitter');
      const shareableText = `${inviteMessage} ${$translatei18n('ClickHereToJoin')} : ${shortenedLink}`;
      const url = `https://twitter.com/intent/tweet?url=${shareableText}`;
      window.open(url, '');
    }
  };

  const shareOnWhatsapp = () => {
    if (inviteMessage.length > 250) {
      NotificationService.error($translatei18n('YouHaveExceeded250'));
    } else {
      trackAmplitudeEvent('whatsapp');
      const shareableText = `${inviteMessage} ${$translatei18n('ClickHereToJoin')} : ${shortenedLink}`;
      const url = `https://api.whatsapp.com/send/?phone&text=${shareableText}`;
      window.open(url, '');
    }
  };

  const shareOnReddit = () => {
    if (inviteMessage.length > 250) {
      NotificationService.error($translatei18n('YouHaveExceeded250'));
    } else {
      trackAmplitudeEvent('reddit');
      const shareableText = `${inviteMessage} ${$translatei18n('ClickHereToJoin')} : ${shortenedLink}`;
      const url = `https://www.reddit.com/submit?url=${shareableText}`;
      window.open(url, '');
    }
  };

  const shareOnLinkedIn = () => {
    trackAmplitudeEvent('linkedin');
    const url = `https://www.linkedin.com/shareArticle?url=${shortenedLink}`;
    window.open(url, '');
  };

  const shareOnMail = () => {
    if (inviteMessage.length > 250) {
      NotificationService.error($translatei18n('YouHaveExceeded250'));
    } else {
      trackAmplitudeEvent('email');
      const shareableText = `${inviteMessage} ${$translatei18n('ClickHereToJoin')} : ${shortenedLink}`;
      const url = `mailto:?body=${shareableText}`;
      window.open(url, '');
    }
  };

  const shareOnTelegran = () => {
    if (inviteMessage.length > 250) {
      NotificationService.error($translatei18n('YouHaveExceeded250'));
    } else {
      trackAmplitudeEvent('telegram');
      const shareableText = `${inviteMessage} ${$translatei18n('ClickHereToJoin')} : ${shortenedLink}`;
      const url = `https://t.me/share/url?url=${shareableText}`;
      window.open(url, '');
    }
  };

  const textAreaRef = useRef();

  useEffect(() => {
    textAreaRef?.current?.blur();
  }, [])

  return (
    <div className="flex flex-col h-[calc(100%-80px)]">
      <div className="h-full overflow-y-auto">
        {/* manager link : dynamic */}
        {link_limit && roles_ids && expires_at && (
          <div className="p-6 bg-gray-100">
            <h3 className="text-20 font-medium pb-3">{$translatei18n('ManageLink')}</h3>
            <div className="grid grid-cols-2 gap-3 font-semibold text-sm">
              <div>
                <p className="text-gray-500">{$translatei18n('ExpiresOn')}:</p>
                <p className="text-gray-800">{moment(new Date(expires_at)).format('DD MMM y, h:mma')}</p>
              </div>
              <div>
                <p className="text-gray-500">{$translatei18n('Uses')}:</p>
                <p className="text-gray-800">
                  {link_uses || 0}/{link_limit}
                </p>
              </div>
              <div className="col-span-2">
                <p className="text-gray-500">{$translatei18n('RolesAssigned')}:</p>
                <div className="flex flex-wrap gap-2 mt-1">
                  {roles_ids?.map((role_id) => (
                    <Fragment key={role_id}>
                      <span
                        className="inline-flex items-center text-sm py-[6px] ltr:pl-3  rtl:pr-3 ltr:pr-2 rtl:pl-2 rounded-3xl text-gray-800"
                        style={{
                          backgroundColor: communityRoles[role_id]?.color,
                        }}
                      >
                        {communityRoles[role_id]?.role}
                      </span>
                    </Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
        {/* invite message */}
        <div className="p-6 flex flex-col gap-6 flex-1">
          <div>
            <p className="text-sm font-semibold pb-2">{$translatei18n('InvitationMessage')}</p>
            {nonEditable ? (
              <span className="text-sm">{message}</span>
            ) : (
              <>
                <textarea
                  value={inviteMessage}
                  onChange={handleInviteMessageChange}
                  onBlur={() => {
                    if (!inviteMessageLimitError) {
                      AnalyticsService.trackAmplitudeEvent(
                        INVITE_USERS_AMPLITUDE_EVENT_TITLE.INVITATION_MESSAGE_TEXTBOX,
                        {
                          ...analyticsDefaults(true),
                          source: sessionStorage.getItem(SEND_INVITE_TRIGGERED_FROM),
                          value: inviteMessage,
                        },
                      );
                    }
                  }}
                  className="border bg-transparent border-gray-300 rounded-md px-3 py-2 w-full resize-none h-32 text-sm usetiful-textbox"
                  ref={textAreaRef}
                />
                <div
                  className={`flex justify-between text-xs ${
                    inviteMessageLimitError ? 'text-red-600' : 'text-gray-800'
                  }`}
                >
                  <span>{inviteMessageLimitError ? 'You have exceeded the maximum number of characters' : ''}</span>
                  <span>{inviteMessage.length}/250</span>
                </div>
              </>
            )}
          </div>
          <div>
            <p className="text-sm font-semibold pb-2">{$translatei18n('InviteLink')}</p>
            <div className="px-2 py-[6px] border border-gray-300 rounded flex text-xs justify-between items-center gap-2">
              <span className="text-indigo-600 truncate">{link?.href}</span>
              <button
                className="border border-gray-300 flex px-2 py-[6px] rounded w-32 lg:w-28 usetiful-copy-icon"
                type="button"
                onClick={() => {
                  handleCopyShareLink(shortenedLink);
                }}
              >
                <CopyIcon className="w-3 h-3" />
                &nbsp; {$translatei18n('CopyLink')}
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* footer */}
      <div className="px-6 py-4 border-t border-gray-300 sticky w-full bottom-0 bg-white usetiful-share-social-media">
        <p className="text-sm font-semibold pb-4">{$translatei18n('InviteBy')}</p>
        <div className="flex justify-between">
          <button className="flex justify-center items-center h-10 w-10 rounded-md bg-indigo-600" onClick={shareOnMail}>
            <MailIcon className="w-4 h-4 text-white" />
          </button>
          <button onClick={shareOnTwitter}>
            <img src={TwitterIcon} alt="twitter" />
          </button>
          <button onClick={shareOnReddit}>
            <img src={RedditIcon} alt="reddit" />
          </button>
          <button onClick={shareOnTelegran}>
            <img src={TelegramIcon} alt="telegram" />
          </button>
          <button onClick={shareOnWhatsapp}>
            <img src={WhatsappIcon} alt="whatsapp" />
          </button>
          <button onClick={shareOnLinkedIn}>
            <img src={LinkedInIcon} alt="linkedin" />
          </button>
          <button onClick={shareOnFacebook}>
            <img src={FacebookIcon} alt="facebook" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SendInviteContent;
