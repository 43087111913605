/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogTitle, Menu, MenuItem, MenuItems, Transition, TransitionChild } from '@headlessui/react';
import { ChevronLeftIcon, XIcon, ExclamationIcon, MinusCircleIcon } from '@heroicons/react/outline';
import _ from 'lodash';
import { useHistory } from 'react-router';
import serverV from '../../../api/serverVersionless';
import usePrevious from '../../../hooks/usePrevious';
import {
  getActiveCommunity,
  getActiveCommunityAccentColor,
  getActiveCommunityFontsClass,
  getActiveGroupId,
  getCommunityState,
} from '../../../selectors/CommunitySelectors';
import serverLess from '../../../api/serverVersionless';
import { capitalizeFirstLetter } from '../../../helpers';
import { imageFetch } from '../../../services/preSignedAws';
import { addBlockedUserIds, closeChannelConnection, removeBlockedUserIds } from '../../../store/actions/textActions';
import ManageRoles from './ManageRoles';
import CommonNameAvatar, {
  COMMON_NAME_AVATAR_SIZE,
  COMMON_NAME_AVATAR_TYPE,
} from '../../../components/shared/tailwind/NameAvatar';
import MyWallet from '../../coinSystem/MyWallet';
import CoinbgIcon from '../../../assets/images/coin-system/coinbg.png';
import TipCoin from '../../coinSystem/TipCoin';
import { classNames, parseAxiosErrorMessage } from '../../../constants/utils';
import ChannelIcon from '../../../components/layout/ChannelIcon';
import EditProfile from './EditProfile';
import { fetchUserPosts, followUser, unfollowUser } from '../../../services/UserService';
import NotificationService from '../../../services/notificationService';
import { ERROR_MESSAGES } from '../../../constants/notificationMessages';
import FollowListSidebar from '../../profile/followSystem/FollowListSidebar';
import RenderSocialLinks from '../../profile/RenderSocialLinks';
import { createPrivateTextRoomService } from '../../../services/privateChatService';
import { setActivePrivateRoom } from '../../../store/actions/privateChatActions';
import { addLeaderboardUserId } from '../../../store/actions/leaderboardActions';
import LoadingSpinner from '../../../components/shared/tailwind/LoadingSpinner';
import { resetUserProfileData } from '../../../store/actions/userProfileActions';
import ConfirmationModal from '../../../components/shared/tailwind/ConfirmationModal';
import { authLogout } from '../../../store/actions/authActions';
import { closeChatChannelConnection } from '../../../store/actions/chatActions';
import ForumPosts from './drawerChannelList/Forum';
import { SearchLoadingSpinner } from '../../../components/search/GlobalSearch';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import ReportModal from '../../../components/moderation/ReportModal';
import { REPORT_TYPE } from '../../../constants';
import { getCommonAvtarName, getFullName } from '../../../utils/Utils';

export default function UserProfile({
  openUserProfile,
  onClose,
  self,
  canManageSpeakers,
  activeUserId,
  onKickUser,
  onMuteUser,
  channelId,
  openReportModal,
  hideSendCoin,
  isLeaderboardList,
}) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const activeCommunity = useSelector(getActiveCommunity);
  const community = useSelector(getCommunityState);
  const coinsCount = useSelector((state) => state?.channel?.coinsCount);
  const communityOwner = useSelector((state) => state?.channel?.communityOwner);
  const { primaryFont } = useSelector(getActiveCommunityFontsClass);
  const accentColor = useSelector(getActiveCommunityAccentColor);
  const isUpdatingUser = useSelector((state) => state?.user?.isUpdating);
  const isCommunityOwner = useSelector((state) => state?.channel?.communityOwner);
  const activeGroupId = useSelector(getActiveGroupId);

  const [userData, setUserData] = useState({});
  const [openMyWallet, setOpenMyWallet] = useState(false);
  const [openTipCoin, setOpenTipCoin] = useState(false);
  const [openEditProfile, setOpenEditProfile] = useState(false);
  const [openFollowerSidebar, setOpenFollowerSidebar] = useState(false);
  const [disableFollow, setDisableFollow] = useState(false);
  const prev = usePrevious({ userData, open });
  const prevIsUpdatingUser = usePrevious(isUpdatingUser);
  const [isImageLoadingError, setIsImageLoadingError] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [showActivity, setShowActivity] = useState(false);
  const [reportOpen, setReportOpen] = useState(false)
  const history = useHistory();

  useEffect(() => {
    if (!self) {
      setShowActivity(false);
    }
    setOpen(openUserProfile);
    if (openUserProfile === true) {
      setLoading(true);

      if (activeUserId) {
        fetchUserDetails();
        setOpenFollowerSidebar(false);
      }
    }
  }, [openUserProfile, activeUserId]);

  useEffect(() => {
    if (open === false && onClose !== undefined && onClose !== null) {
      onClose();
    }
  }, [open]);

  useEffect(() => {
    const userChanged = userData?.data?.user?.id !== prev?.userData?.data?.user?.id;
  }, [userData, open]);

  useEffect(() => {
    if (!isUpdatingUser && prevIsUpdatingUser) {
      fetchUserDetails();
    }
  }, [isUpdatingUser]);

  const closeUserProfileDrawer = () => {
    setOpen(false);
    dispatch(addLeaderboardUserId({ communityUserId: null }));
  };

  const fetchUserDetails = () => {
    serverLess
      .get(`/profile/${activeUserId}?community_id=${activeCommunity.id}`, {
        params: {
          channel_id: channelId || null,
        },
      })
      .then((res) => {
        const { community_roles, channel_lists } = res.data.data;
        const arrCommunities = [];
        const arrChannels = [];
        if (community_roles) {
          community_roles.forEach((item) => {
            const obj = { id: 1, label: capitalizeFirstLetter(item.role), color: item.color };
            arrCommunities.push(obj);
          });
        }
        if (channel_lists) {
          channel_lists.forEach((item) => {
            const obj = {
              access: capitalizeFirstLetter(item.base_permission),
              type: item.channel_type,
              name: item.channel_name,
            };
            arrChannels.push(obj);
          });
        }
        setUserData({
          isBlocked: res.data?.data?.blocked,
          data: res.data.data,
          followed: res.data.data.followed,
          loader: false,
          arrCommunity: arrCommunities,
          arrChannels,
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleBlockUser = () => {
    if (userData.isBlocked) unblockUser();
    else blockUser();
  };

  const blockUser = () => {
    serverLess
      .post('/blocked_users', {
        blocked_user: { blocked_user_id: activeUserId },
      })
      .then(() => {
        setUserData({ ...userData, isBlocked: true, loader: false });
        dispatch(addBlockedUserIds([activeUserId]));
      })
      .catch(() => {
        setUserData({ ...userData, loader: false });
      });
  };

  const unblockUser = () => {
    serverLess
      .delete(`/blocked_users/${activeUserId}`)
      .then(() => {
        setUserData({ ...userData, isBlocked: false, loader: false });
        dispatch(removeBlockedUserIds(activeUserId));
      })
      .catch(() => {
        setUserData({ ...userData, loader: false });
      });
  };

  const handleFollow = async () => {
    const requestData = {
      followee_id: userData?.data?.id,
    };
    const newUserData = _.cloneDeep(userData);
    setDisableFollow(true);
    if (userData?.followed) {
      try {
        await unfollowUser(requestData, activeUserId);
        if (newUserData?.data) {
          newUserData.data.followers_count = parseInt(newUserData.data.followers_count, 10) - 1;
        }
        newUserData.followed = false;
        setUserData(newUserData);
      } catch (error) {
        NotificationService.error(ERROR_MESSAGES.SOMETHING_WENT_WRONG);
      }
    } else {
      try {
        await followUser(requestData);
        if (newUserData?.data) {
          newUserData.data.followers_count = parseInt(newUserData.data.followers_count, 10) + 1;
        }
        newUserData.followed = true;
        setUserData(newUserData);
      } catch (error) {
        NotificationService.error(ERROR_MESSAGES.SOMETHING_WENT_WRONG);
      }
    }
    setDisableFollow(false);
  };

  const updateFolloweesCount = (newFolloweeCount) => {
    const newUserData = _.cloneDeep(userData);
    newUserData.data.followees_count = newFolloweeCount;
    setUserData(newUserData);
  };

  const handleOnMessageClick = async () => {
    setOpen(false);
    try {
      const currentUser = userData?.data?.user;
      history.push(`/inbox?user_id=${currentUser?.id}`);
    } catch (error) {
      NotificationService.error(parseAxiosErrorMessage(error));
    }
  };

  const renderUserProfileView = () => {
    const { data } = userData;
    if (!openUserProfile) return null;

    const isCoolHuman = data?.user['is_cool_human?'];
    const coolCoverGif = data?.user.cover_gif || '';
    const coverImage = data?.user?.cover_image ? imageFetch(data?.user?.cover_image, {}, false) : '';

    const name = getFullName(data.user.first_name, data.user.last_name);
    const username = `${data.user.username}`;

    const checkWalletPermission = () =>
      activeCommunity?.coin_enable &&
      activeCommunity?.coin_transfer &&
      coinsCount !== null &&
      coinsCount !== undefined &&
      (hideSendCoin === undefined || hideSendCoin === null || hideSendCoin === false);

    const handleOpenFollowerSidebar = () => {
      if (self === true) {
        setOpenFollowerSidebar(true);
      }
    };

    return (
      <div className="mt-6 relative flex-1">
        <div>
          <div
            className="relative aspect-w-448 aspect-h-211 bg-gray-200 bg-cover bg-no-repeat"
            style={{
              backgroundImage: `url(${isCoolHuman && coolCoverGif ? coolCoverGif : coverImage || ''})`,
            }}
          >
            <div className="">
              <div className="flex justify-center absolute -bottom-10 sm:-bottom-14 md:-bottom-14 left-0 right-0 ">
                {data.user.image && !isImageLoadingError ? (
                  <div className="relative">
                    <img
                      className="inline-block h-20 w-20 sm:h-28 sm:w-28 md:h-28 md:w-28 rounded-full ring-4 ring-white"
                      src={imageFetch(data.user.image)}
                      onError={() => setIsImageLoadingError(true)}
                      alt="profile"
                    />
                    {data?.online && (
                      <div className="dot h-4 w-4 bg-green-500 absolute rounded-full right-4 bottom-0" />
                    )}
                  </div>
                ) : (
                  <div className="relative">
                    <CommonNameAvatar
                      type={COMMON_NAME_AVATAR_TYPE.CIRCULAR}
                      size={COMMON_NAME_AVATAR_SIZE.XXL}
                      name={getCommonAvtarName(data?.user?.first_name, data?.user?.last_name)}
                    />
                    {data?.online && (
                      <div className="dot h-4 w-4 bg-green-500 absolute rounded-full right-4 bottom-0" />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="px-6 xs:px-4">
          <div className="text-center mt-12 sm:mt-16 md:mt-16">
            <p className="text-lg text__title font-bold leading-5 mt-1">{name}</p>
            <p className="text-sm text__description mb-2">{`@${username}`}</p>
            <div className="flex items-start justify-center gap-5 xs:gap-2 my-1 text__body profile__sidepanel--follow">
              <button
                type="button"
                className={classNames('text-sm font-bold ', self === false && 'cursor-text')}
                onClick={handleOpenFollowerSidebar}
              >
                {`${data.followers_count} ${$translatei18n('Followers')}`}
              </button>
              <button
                type="button"
                className={classNames('text-sm font-bold ', self === false && 'cursor-text')}
                onClick={handleOpenFollowerSidebar}
              >
                {`${data.followees_count} ${$translatei18n('Following')}`}
              </button>
            </div>
            {self === true && (
              <div>
                <div className="flex items-start justify-center my-4 gap-4 xs:gap-2">
                  <button
                    type="button"
                    className={classNames(
                      'w-6/12 px-3 py-3 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm focus:outline-none focus:ring-0 focus:ring-offset-0 btn__primary',
                      primaryFont,
                    )}
                    style={{ backgroundColor: accentColor, minWidth: '50px' }}
                    onClick={() => setOpenEditProfile(true)}
                  >
                    {$translatei18n('EditProfile')}
                  </button>
                  {activeCommunity?.coin_enable && (
                    <button
                      type="button"
                      className={classNames(
                        'w-6/12 px-3 py-3 border btn__light shadow-sm text-sm leading-4 font-medium rounded-md focus:outline-none focus:ring-0 focus:ring-offset-0',
                        primaryFont,
                      )}
                      style={{ minWidth: '50px' }}
                      onClick={() => setOpenMyWallet(true)}
                    >
                      {$translatei18n('Wallet')}
                    </button>
                  )}
                </div>
              </div>
            )}
            <div className='relative'>
              {self !== true && (
                <div className="flex items-start justify-center my-4 gap-4 xs:gap-2">
                  <button
                    type="button"
                    className={classNames(
                      'profile__follow__btn w-40 inline-flex items-center justify-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm focus:outline-none focus:ring-0 focus:ring-offset-0',
                      primaryFont,
                      userData?.followed ? 'btn__light' : 'btn__primary',
                    )}
                    style={{ backgroundColor: userData?.followed ? '' : accentColor }}
                    onClick={handleFollow}
                    disabled={disableFollow}
                  >
                    {userData?.followed ? $translatei18n('Unfollow') : $translatei18n('Follow')}
                  </button>
                  <button
                    type="button"
                    id="direct__message"
                    className={classNames(
                      'profile__inbox__btn w-40 inline-flex items-center justify-center px-3 py-2 border btn__light shadow-sm text-sm leading-4 font-medium rounded-md focus:outline-none focus:ring-0 focus:ring-offset-0',
                      primaryFont,
                    )}
                    onClick={handleOnMessageClick}
                  >
                    Message
                  </button>
                </div>
              )}
              {!self &&
                <div className="absolute right-0 top-1 ">
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <MenuButton className="rounded flex items-center text-gray-800 hover:text-gray-500 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-offset-gray-100 focus:ring-indigo-500">
                        <span className="sr-only">Open options</span>
                        <DotsVerticalIcon className='main__icon w-6 h-6' />
                      </MenuButton>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <MenuItems className="ltr:origin-top-right rtl:origin-top-left z-10 absolute ltr:right-0 rtl:left-0 mt-2 w-56 rounded-md shadow-lg menu__options__container border focus:outline-none">
                        <div className="py-1">
                          <MenuItem>
                            {({ active }) => (
                              <a
                                onClick={() => setReportOpen(true)}
                                className={classNames(
                                  active && 'menu__option--active',
                                  'block px-4 py-2 text-sm menu__option text__danger hover:cursor-pointer',
                                )}
                              >
                                {$translatei18n("ReportUser")}
                              </a>
                            )}
                          </MenuItem>
                        </div>
                      </MenuItems>
                    </Transition>
                  </Menu>
                </div>
              }
            </div>
            {self && (
              <div className="flex items-start justify-start my-6 gap-4 xs:gap-2">
                <button
                  type="button"
                  className={classNames(
                    'inline-flex items-center justify-center px-3 py-1.5 border text-14 rounded-full',
                    primaryFont,
                    !showActivity ? 'btn__primary' : 'btn__light',
                  )}
                  style={{ backgroundColor: !showActivity && accentColor }}
                  onClick={() => setShowActivity(false)}
                >
                  {$translatei18n('About')}
                </button>
                <button
                  type="button"
                  className={classNames(
                    'inline-flex items-center justify-center px-3 py-1.5 border text-14 rounded-full activity__btn',
                    primaryFont,
                    showActivity ? 'btn__primary' : 'btn__light',
                  )}
                  style={{ background: showActivity && accentColor }}
                  onClick={() => setShowActivity(true)}
                >
                  {$translatei18n('Activity')}
                </button>
              </div>
            )}
            {!showActivity && (
              <>
                {data?.user?.about && (
                  <div className="mt-7 mb-3 text-sm uppercase text__title text-left font-bold">
                    {$translatei18n('About')}
                  </div>
                )}
                <p className="text-sm text__body my-1 text-left">{data?.user?.about || ''}</p>

                <div id="social__icons__box">
                  {/* Social Links Render Logic */}
                  {data?.facebook_url ||
                    data?.twitter_url ||
                    data?.instagram_url ||
                    data?.linkedin_url ||
                    data?.website_url ? (
                    <>
                      <div className="mt-7 mb-3 uppercase text-sm text__title text-left font-bold">
                        {$translatei18n('Links')}
                      </div>
                      <RenderSocialLinks userData={data} />
                    </>
                  ) : (
                    self && (
                      <>
                        <div className="mt-7 mb-3 uppercase text-sm text__title text-left font-bold">
                          {$translatei18n('Links')}
                        </div>
                        <button
                          type="button"
                          className={classNames('text__link mb-4', primaryFont)}
                          onClick={() => setOpenEditProfile(true)}
                        >
                          {$translatei18n('AddSocialHandles')}
                        </button>
                      </>
                    )
                  )}
                </div>
              </>
            )}
          </div>
          {!showActivity && (
            <>
              {community?.activeCommunity && activeUserId && self === false && (
                <ManageRoles activeCommunityId={community.activeCommunity} activeUserId={activeUserId} />
              )}
              {community?.activeCommunity && activeUserId && self === true && (
                <div className="block mt-3 profile__sidepanel--roles">
                  {userData.arrCommunity?.length > 0 && (
                    <div className="mt-7 mb-3 text-sm text__title font-bold uppercase">{$translatei18n('Roles')}</div>
                  )}
                  <div className="flex items-center flex-wrap">
                    {userData.arrCommunity.map((item) => (
                      <span
                        key={item?.label}
                        className="inline-flex rounded-full items-center py-1 px-2 my-1 ltr:mr-2 rtl:ml-2 text-12 font-medium text-gray-800 capitalize"
                        style={{ backgroundColor: item?.color }}
                      >
                        {item?.label}
                      </span>
                    ))}
                  </div>
                </div>
              )}

              {activeCommunity?.id == 1
                ? null
                : self === true &&
                checkWalletPermission() === true && (
                  <>
                    <div className="mt-7 mb-3 font-bold uppercase text-sm">{$translatei18n('CommunityCoin')}</div>
                    <div className="inline-flex justify-between items-center px-3 py-2 rounded-md bg-yellow-100 w-full">
                      <div className="flex items-center">
                        <div className="relative">
                          <img src={CoinbgIcon} className="h-8 w-8" alt="coin bg" />
                          <img
                            src={imageFetch(activeCommunity?.coin_icon, {}, false)}
                            className="absolute h-5 w-5 inset-0 m-auto rounded-full opacity-50"
                            alt="coin"
                          />
                        </div>
                        <p className="text-sm text-gray-800 ml-3">
                          {$translatei18n('Balance')}: {coinsCount} {activeCommunity.coin_abbreviation}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              {community?.activeCommunity && activeUserId && self === true && (
                <div className="block mt-3">
                  {userData?.arrChannels?.length > 0 && (
                    <>
                      <div className="mt-7 mb-3 text-sm text__title capitalize font-bold">Your Access Level</div>
                    </>
                  )}
                  <div className="flex items-center flex-wrap">
                    {userData?.arrChannels.map((item, index) => (
                      <div className="flex w-full py-1 text__description" key={index}>
                        <ChannelIcon
                          accent=""
                          channelType={item?.type}
                          iconClassName="h-5 w-5 ltr:mr-2 rtl:ml-2  flex-none text__description"
                        />
                        <span className="text-14 leading-6">{item?.name}</span>
                        <span className="text-14 ltr:ml-auto rtl:mr-auto leading-6 text__title">{item?.access}</span>
                      </div>
                    ))}
                  </div>
                </div>
              )}
              {activeCommunity?.id == 1
                ? null
                : self === false && (
                  <div className="w-full mt-7">
                    {checkWalletPermission() === true && (
                      <>
                        <p className="text-sm font-bold text__title capitalize font-bold">
                          {$translatei18n('InCommunityActions')}
                        </p>
                        <button
                          type="button"
                          className="inline-flex justify-between items-center px-3 py-2 rounded-md bg-yellow-100 w-full mt-4"
                          onClick={() => setOpenTipCoin(true)}
                        >
                          <div className="flex items-center">
                            <div className="relative">
                              <img src={CoinbgIcon} className="h-8 w-8" alt="coin bg" />
                              <img
                                src={imageFetch(activeCommunity?.coin_icon, {}, false)}
                                className="absolute h-5 w-5 inset-0 m-auto rounded-full opacity-50"
                                alt="coin"
                              />
                            </div>
                            <p className="text-sm text-gray-800 ml-3">{$translatei18n('SendCoins')}</p>
                          </div>
                          <div className="text-sm text-gray-400">
                            {$translatei18n('Balance')}: {coinsCount} {activeCommunity.coin_abbreviation}
                          </div>
                        </button>
                      </>
                    )}
                  </div>
                )}
              {canManageSpeakers === true && self === false && (
                <div>
                  <button
                    type="button"
                    className="inline-flex items-center px-3 py-2 rounded-md bg-gray-50 w-full mt-4"
                    onClick={() => onMuteUser(activeUserId)}
                  >
                    <p className="text-red-500">Mute user from room</p>
                  </button>
                  <button
                    type="button"
                    className="inline-flex items-center px-3 py-2 rounded-md bg-gray-50 w-full mt-4"
                    onClick={() => onKickUser(activeUserId)}
                  >
                    <p className="text-red-500">Kick user from room</p>
                  </button>
                </div>
              )}

              {self === false && (
                <div>
                  {/* <button
                type="button"
                className="inline-flex items-center px-3 py-2 rounded-md btn__light w-full mt-4"
                onClick={openReportModal}
              >
                <p className="text__danger">Report user</p>
              </button> */}
                  {/* <button
                type="button"
                className="inline-flex items-center px-3 py-2 rounded-md btn__light w-full mt-4 user__profile__block--user--button"
                onClick={handleBlockUser}
              >
                <p className="text__danger">{userData?.isBlocked ? 'Unblock user' : 'Block user'}</p>
              </button> */}
                </div>
              )}
              {community?.activeCommunity && activeUserId && self === true && !isCommunityOwner && (
                <div className="block mt-7 profile__sidepanel--roles" id="profile__actions">
                  {userData.arrCommunity?.length > 0 && (
                    <div className="mt-7 mb-3 text-s font-bold m text__title">Profile Actions</div>
                  )}
                  <button
                    type="button"
                    className="inline-flex justify-between items-center px-3 py-2 rounded-lg w-full border-2 border-gray-200"
                    onClick={() => setDeleteConfirmation(true)}
                  >
                    <div className="flex items-center">
                      <div className="relative">
                        <MinusCircleIcon className="text__danger w-6 h-6 " />
                      </div>
                      <p className="text-sm text__danger ml-3">Delete Account</p>
                    </div>
                  </button>
                  <DeleteUserModal open={deleteConfirmation} close={setDeleteConfirmation} />
                </div>
              )}
            </>
          )}
          {showActivity && self && (
            <Activity
              activeCommunity={activeCommunity}
              closeUserProfileDrawer={closeUserProfileDrawer}
              userData={data}
            />
          )}
        </div>
        {/* /End replace */}
      </div>
    );
  };

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-hidden"
        onClose={() => {
          setOpen(false);
          dispatch(addLeaderboardUserId({ communityUserId: null }));
        }}
      >
        <div className="absolute inset-0  overflow-hidden">
          <TransitionChild
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md xs:max-w-xs">
                <div className="h-full flex flex-col py-6 sidepanel__container shadow-xl overflow-y-scroll">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <DialogTitle className={classNames('text-lg font-medium text__title', primaryFont)}>
                        <div
                          role="none"
                          className={`flex flex-row items-center
                          ${isLeaderboardList ? 'cursor-pointer' : ''}`}
                          onClick={() => {
                            if (isLeaderboardList) {
                              setOpen(false);
                              dispatch(addLeaderboardUserId({ communityUserId: null }));
                            }
                          }}
                        >
                          {isLeaderboardList && (
                            <ChevronLeftIcon className="h-6 w-6 main__icon ltr:mr-2 rtl:ml-2" aria-hidden="true" />
                          )}
                          {isLeaderboardList
                            ? `${$translatei18n('BackTo')} ${$translatei18n('Leaderboard')}`
                            : getFullName(userData?.data?.user?.first_name, userData?.data?.user?.last_name)}
                        </div>
                      </DialogTitle>
                      {!isLeaderboardList && (
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="rounded-md focus:outline-none focus:ring-0 focus:ring-offset-0"
                            onClick={closeUserProfileDrawer}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6 main__icon" aria-hidden="true" />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  {isLoading ? (
                    <div className="h-full w-full flex justify-center items-center z-10 bg-gray-200">
                      <div
                        className="
                          animate-spin
                          rounded-full
                          h-12
                          w-12
                          border-t-2 border-b-2 border-blue-700
                        "
                      />
                    </div>
                  ) : (
                    !_.isEmpty(userData) && renderUserProfileView()
                  )}
                  {self && activeCommunity?.coin_enable && (
                    <MyWallet openMyWallet={openMyWallet} setOpenMyWallet={setOpenMyWallet} userData={userData} />
                  )}
                  {!self && (
                    <TipCoin
                      openTipCoin={openTipCoin}
                      setOpenTipCoin={setOpenTipCoin}
                      userData={userData}
                      activeCommunity={activeCommunity}
                      coinsCount={coinsCount}
                      communityOwner={communityOwner}
                    />
                  )}
                  {self && (
                    <EditProfile
                      openEditProfile={openEditProfile}
                      setOpenEditProfile={setOpenEditProfile}
                      userData={userData}
                      activeCommunity={activeCommunity}
                    />
                  )}
                  {self && (
                    <FollowListSidebar
                      open={openFollowerSidebar}
                      setOpen={setOpenFollowerSidebar}
                      followeesCount={userData?.data?.followees_count}
                      followersCount={userData?.data?.followers_count}
                      updateFolloweesCount={updateFolloweesCount}
                    />
                  )}
                  {!self &&
                    <ReportModal
                      heading="Report User"
                      subheading="Are you sure you want to report this User?"
                      onClose={() => setReportOpen(false)}
                      open={reportOpen}
                      userName={getFullName(userData?.data?.first_name, userData?.data?.last_name)}
                      reportType={REPORT_TYPE.USER_REPORT}
                      userId={userData?.data?.id}
                    />

                  }
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

function DeleteUserModal({ open, close }) {
  const [confirmMessage, setConfirmMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const appearanceData = useSelector((state) => state?.community?.publicCommunityDetails);
  const handleDeleteUser = () => {
    if (confirmMessage === 'Delete') {
      setLoading(true);
      serverV
        .post('/join', { join: false })
        .then((response) => {
          NotificationService.success('Account deleted successfully');
          try {
            if (appearanceData?.settings?.logout_url) {
              window.location.href = appearanceData?.settings?.logout_url;
            } else {
              dispatch(authLogout());
            }
            dispatch(closeChannelConnection(() => { }, true));
            dispatch(closeChatChannelConnection());
          } catch (error) {
            console.error('Logout Error =>', error);
          }
        })
        .catch((err) => NotificationService.error(parseAxiosErrorMessage(err)))
        .finally(() => setLoading(false));
    }
  };

  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" onClose={() => close(false)}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center md:block md:p-0">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden md:inline-block md:align-middle md:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            enterTo="opacity-100 translate-y-0 md:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 md:scale-100"
            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
          >
            <div className="inline-block align-bottom card justify-center rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all md:my-8 md:align-middle md:max-w-lg md:w-full md:p-6">
              <div className="md:flex md:items-start">
                <div className="mt-3 text-center md:mt-0 md:ml-4 md:text-left">
                  <div className="mt-2">
                    <div className="flex flex-col md:flex-row justify-between items-center md:items-start">
                      <div className=" md:mr-4 flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationIcon className="h-6 w-6 text-red-500" aria-hidden="true" />
                      </div>
                      <div>
                        <div className="font-semibold">Are you sure ?</div>
                        <div className="text-14 text__body">
                          This action will permanently delete your account from the community. Deleting your account
                          will delete all your profile data and reset all your community rewards.
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 w-full">
                      <h1 className="text__body font-semibold">Enter "Delete" to confirm</h1>
                      <input
                        placeholder="Delete"
                        className="px-2 py-1.5 mt-2 shadow-sm block w-full sm:text-sm rounded-md card input ring-1 ring-gray-400 focus:ring-gray-600"
                        onChange={(e) => setConfirmMessage(e.target.value)}
                        value={confirmMessage}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-5 md:mt-4 flex justify-end flex-col-reverse md:flex-row">
                <button
                  type="button"
                  className="mt-2 md:mt-0 w-full inline-flex justify-center rounded-md border btn__light shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-0 focus:ring-offset-0 md:w-auto md:text-sm"
                  onClick={(e) => {
                    setConfirmMessage('');
                    close(false);
                  }}
                  disabled={loading}
                >
                  {$translatei18n('Cancel')}
                </button>
                <button
                  type="button"
                  className={`ml-0 md:ml-4 w-full inline-flex justify-center rounded-md border btn__danger shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-0 focus:ring-offset-0 md:w-auto md:text-sm ${confirmMessage !== 'Delete' ? 'opacity-50 cursor-default' : 'bg-opacity-100 cursor-pointer'
                    }`}
                  onClick={() => {
                    handleDeleteUser();
                    setConfirmMessage('');
                    close(false);
                  }}
                  disabled={confirmMessage !== 'Delete' || loading}
                >
                  Delete
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
}

const Activity = ({ activeCommunity, page, closeUserProfileDrawer, userData }) => {
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [pagination, setPagination] = useState(1);
  const activeCommunityAccentColor = useSelector(getActiveCommunityAccentColor);

  useEffect(() => {
    getPosts();
  }, []);

  const getPosts = async () => {
    setLoading(true);
    try {
      const userPosts = await fetchUserPosts(activeCommunity?.id, activeUserId, pagination);
      setPagination(userPosts?.links?.next);
      setPosts([...posts, ...userPosts?.data]);
    } catch (err) {
      NotificationService.error(parseAxiosErrorMessage(err));
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-col items-center gap-6 pb-2">
      <div className="mt-2 mb-3 text-sm uppercase text__title text-left font-bold self-start">
        {$translatei18n('Activity')}
      </div>
      {posts?.length !== 0 &&
        posts?.map((val) => {
          return val?.type === 'forum_post' ? (
            <ForumPosts
              key={val?.attributes?.id}
              forumPostData={val?.attributes}
              userData={userData}
              closeDrawer={closeUserProfileDrawer}
            />
          ) : null;
        })}
      {posts?.length === 0 && (
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-14 text__title font-bold">{$translatei18n('ThereIsNothingHereYet')}</h1>
          <h1 className="text-12 text__description">{$translatei18n('AllYourPostsWillAppearHere')}</h1>
        </div>
      )}
      {loading && (
        <div className="w-full h-32 flex items-center justify-center">
          <SearchLoadingSpinner />
        </div>
      )}
      {pagination && (
        <button
          className="text__link cursor-pointer background-transparent font-bold px-3 py-1 text-sm outline-none focus:outline-none ltr:mr-1 rtl:ml-1  mb-1 ease-linear transition-all duration-150"
          type="button"
          style={{ color: activeCommunityAccentColor }}
          onClick={getPosts}
        >
          {$translatei18n('SeeMore')}
        </button>
      )}
    </div>
  );
};
