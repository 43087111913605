import SideDrawer from './SideDrawer';
import CreateInviteContent from './drawer-contents/CreateInviteContent';
import SendInviteContent from './drawer-contents/SendInviteContent';

const Drawer = ({
  createInviteOpen,
  handleCreateInviteDrawer,
  sendInviteOpen,
  createdInviteDetails,
  handleSendInviteDrawer,
  refreshList,
}) => {
  return (
    <>
      <SideDrawer
        open={createInviteOpen}
        handleClose={() => handleCreateInviteDrawer(false)}
        drawerTitle="Create Invite Link qweqwe"
      >
        <CreateInviteContent handleSendInviteDrawer={handleSendInviteDrawer} refreshList={refreshList} />
      </SideDrawer>
      <SideDrawer
        open={sendInviteOpen}
        handleClose={() => {
          handleCreateInviteDrawer(false);
          handleSendInviteDrawer(false);
        }}
        drawerTitle="Send Invite"
      >
        <SendInviteContent createdInviteDetails={createdInviteDetails} />
      </SideDrawer>
    </>
  );
};

export default Drawer;
