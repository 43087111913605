import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import auth from './auth';
import userReducer from './userReducer';
import onboadingReducer from './onboadingReducer';
import communityReducer from './communityReducer';
import channelReducer from './channelReducer';
import forumReducer from './forumReducer';
import socketReducer from './socketReducer';
import textReducer from './textReducer';
import audioReducer from './audioReducer';
import chatReducer from './chatReducer';
import rolesReducer from './rolesReducer';
import resourceReducer from './resourceReducer';
import calendarReducer from './calendarReducer';
import userProfileReducer from './userProfileReducer';
import bookmarkReducer from './bookmarkReducer';
import ssoReducer from './ssoReducer';
import privateChatReducer from './privateChatReducer';
import offlineChatReducer from './offlineChatReducer';
import { authPersistConfig, communityPersistConfig } from '../configureStore';
import leaderboardReducer from './leaderboardReducer';
import shopReducer from './shopReducer';
import eventReducer from './eventReducer';
import publicCommunityReducer from './publicCommunityReducer';
import appInAppReducers from './appInApp';
import plansReducer from './plansReducer';
import mixpanelReducer from './mixpanelReducer';
import scheduledAndDraftsReducer from "./scheduledAndDrafts";

const createReducer = (asyncReducer) =>
  combineReducers({
    auth: persistReducer(authPersistConfig, auth),
    user: userReducer,
    onboarding: onboadingReducer,
    community: persistReducer(communityPersistConfig, communityReducer),
    channel: channelReducer,
    leaderboard: leaderboardReducer,
    forum: forumReducer,
    socket: socketReducer,
    text: textReducer,
    audio: audioReducer,
    chat: chatReducer,
    roles: rolesReducer,
    resource: resourceReducer,
    calendar: calendarReducer,
    event: eventReducer,
    userProfile: userProfileReducer,
    userBookmarks: bookmarkReducer,
    sso: ssoReducer,
    privateChat: privateChatReducer,
    offlineChat: offlineChatReducer,
    shop: shopReducer,
    publicCommunity: publicCommunityReducer,
    appInApp: appInAppReducers,
    plans: plansReducer,
    mixpanel: mixpanelReducer,
    scheduledAndDrafts: scheduledAndDraftsReducer,
    ...asyncReducer,
  });

export default createReducer;