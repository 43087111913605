import { TagIcon } from "@heroicons/react/solid"
import { useSelector } from "react-redux"


const DisplayTags = ({ postTags }) => {
  const tags = useSelector(state => state?.community?.tags)
  return (
    postTags?.length !== 0 &&
    <div className='my-3 flex  items-center justify-start gap-2'>
      <TagIcon className='w-5 h-5 text__title' />
      <div className='flex flex-wrap items-center gap-3 justify-start w-full'>
        {tags.filter(val => postTags?.includes(val?.attributes?.id))?.map(val => {
          return (
            <div className='flex items-center justify-between gap-1 text__title border-2 py-0.5 px-2.5 rounded-full card' key={val?.attributes?.id}>
              <p className='text-12'>
                {val?.attributes?.name}
              </p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default DisplayTags;