import * as Yup from 'yup';

export const createForumPostSchema = Yup.object().shape({
  description: Yup.string().required('Please enter description'),
  // .max(1500, 'Must be less than 1500 characters'),
  image_url: Yup.mixed(),
});

export const addCommentSchema = Yup.object().shape({
  description: Yup.string().trim().required('Please enter comment').max(5000, 'Must be less than 5000 characters'),
});

export const addCommentWithGifSchema = Yup.object().shape({
  description: Yup.string().optional(),
});

export const addReplySchema = Yup.object().shape({
  description: Yup.string().trim().required('Please enter reply').max(5000, 'Must be less than 5000 characters'),
});

export const addReplyWithGifSchema = Yup.object().shape({
  description: Yup.string().optional(),
});

export const reportPostSchema = Yup.object().shape({
  report_type: Yup.string().required('Please select report type'),
  reason: Yup.string().trim().required('Please enter reason'),
});
