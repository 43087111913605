import React, { useState } from 'react';
import classNames from 'classnames';

import { Initial } from 'react-initial';
import { imageFetch } from '../../services/preSignedAws';
import CommonNameAvatar, { COMMON_NAME_AVATAR_SIZE, COMMON_NAME_AVATAR_TYPE } from './tailwind/NameAvatar';
import { prepareUserImageName } from '../../constants/utils';

function UserImage({
  image, name, imgClasses, imgSize,
}) {
  const [isImageLoadingError, setIsImageLoadingError] = useState(false);
  return (
    <>
      {image && !isImageLoadingError ? (
        <img
          className={imgClasses || ''}
          src={imageFetch(image)}
          onError={() => setIsImageLoadingError(true)}
          alt="profile"
        />
      ) : (
        <Initial
          radius={500}
          name={name}
          height={16}
          width={16}
          fontSize={6}
          charCount={2}
          useWords
          className="rounded-full cursor-pointer font-Geomanist"
          color="rgb(107 114 128)"
        />
      )}
    </>
  );
}

export function OnlineUserIndicator({ onlineCount, onlineUsers, defaultStyle = true }) {
  const [isImageLoadingError, setIsImageLoadingError] = useState(false);
  if (onlineCount > 4) {
    onlineUsers = onlineUsers?.slice(0, 4);
  }

  if (!onlineCount || onlineCount == 0) {
    return <></>;
  }

  return (
    <div className={classNames('ml-9 mb-2 text-sm flex items-center')}>
      <div className="flex -space-x-1 overflow-hidden mx-1 sm:mx-2 md:mx-2 mx-3.5">
        {onlineUsers?.map((user) => (
          <UserImage
            name={user?.name}
            imgClasses="inline-block h-4 w-4 rounded-full ring-2 ring-white"
            image={user.image}
            size={COMMON_NAME_AVATAR_SIZE.SM}
            key={user?.id}
          />
        ))}
      </div>
      {onlineCount - 4 > 0 && <div className="text-xs">+{onlineCount - 4} attending</div>}
    </div>
  );
}
