import { applyMiddleware, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import { createReduxEnhancer } from '@sentry/react';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import rootSagas from '../saga';
import reducers from './reducers';
// const saveSubsetFilter = createFilter('text', ['text.canSendMessage']);
// export const transformCircular = createTransform(
//   (inboundState, key) => stringify(inboundState),
//   (outboundState, key) => parse(outboundState),
// );
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'channel', 'offlineChat'],
  // transforms: [saveSubsetFilter, transformCircular],
};

const communityPersistConfig = {
  key: 'community',
  storage,
  blacklist: [
    'posts',
    'calendarEvents',
    'totalPage',
    'page',
    'publicCommunityDetails',
    'isCommunityMemberVerified',
    'verifyCommunityMemberLoading',
    'setActiveGroupLoading',
    'communityUser',
  ],
};

const authPersistConfig = {
  key: 'auth',
  storage,
  blacklist: ['isManualLogout'],
};

const sentryReduxEnhancer = createReduxEnhancer({
  // Optionally pass options listed below
});

const sagaMiddleware = createSagaMiddleware();
const middlewares = [thunk, sagaMiddleware];
const composeEnhancer = (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const persistedReducer = persistReducer(persistConfig, reducers());

const store = createStore(persistedReducer, composeEnhancer(applyMiddleware(...middlewares), sentryReduxEnhancer));

sagaMiddleware.run(rootSagas);

const persistor = persistStore(store);

export {
  store, persistor, communityPersistConfig, authPersistConfig,
};
