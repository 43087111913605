import React, {useEffect} from 'react';
import TopHeader, { TOP_HEADER_TYPE } from '../layout/Header';
import { useSelector } from 'react-redux';
import { CustomHeaderTitle } from '../inbox';
import GlobalSearch from '../../components/search/GlobalSearch';

const SearchPage = () => {
  const activeCommunity = useSelector((state) => state?.community?.activeCommunity);

  return (
    <div>
      <TopHeader
        headerType={TOP_HEADER_TYPE.INBOX}
        headerTitle={<CustomHeaderTitle communityId={activeCommunity} />}
      />
      <GlobalSearch page={true} />
    </div>
  );
}

export default SearchPage;
