import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import Truncate from 'react-truncate';
import { useSelector } from 'react-redux';
import { getUtcTime } from '../../helpers';
import { imageFetch } from '../../services/preSignedAws';
import { getActiveCommunityFontsClass } from '../../selectors/CommunitySelectors';
import { CalendarIcon, ClockIcon } from '@heroicons/react/outline';
import { history } from '../../constants/utils';
import { fetchSearchResults } from '../../services/SearchService';
import Pagination from '../shared/Pagination';
import { SearchLoadingSpinner } from './GlobalSearch';

function EventsList({
  searchQuery,
  eventData,
  activeCommunity,
  activeGroupId,
  closeDrawer,
  onCommonPage,
  handleViewDetails,
  handleScrollTop,
}) {
  const [intitalData, setIntitalData] = useState();
  const [eventPostsData, setEventPostsData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  function redirectToDetailsPage(event) {
    const { calendar_channel_id, id } = event;
    history.push(`/communities/${activeCommunity?.id}/channels/${calendar_channel_id}/calendarposts/${id}`,
      { isFromOverview: window.location.pathname === "/" ? true : false });
    closeDrawer();
  }

  async function fetchEventPosts(query, page) {
    setIsLoading(true);
    const { data } = await fetchSearchResults(query, 'calendar_event', page, activeCommunity?.id, activeGroupId);
    setIsLoading(false);
    if (!data?.entries) return;
    setEventPostsData(data);
    handleScrollTop();
  }

  useEffect(() => {
    if (eventData?.entries) setIntitalData(eventData);
    if (!eventPostsData?.total_count) setEventPostsData(eventData);
  }, [eventData]);

  useEffect(() => {
    if (onCommonPage) return;
    fetchEventPosts(searchQuery, 1);
  }, [onCommonPage]);

  return (
    <div className={`mb-8 ${!onCommonPage ? 'flex-1' : ''}`}>
      <div className="flex flex-row align-center justify-between">
        <p className="font-bold text__title">
          {$translatei18n('Events')} <span className="font-normal text__description">{`(${eventData?.total_count})`}</span>
        </p>
        {onCommonPage && handleViewDetails && (
          <span
            className="underline text-xs cursor-pointer text__link"
            onClick={handleViewDetails}
            style={{ color: activeCommunity?.accent_color }}
          >
            See all
          </span>
        )}
      </div>
      <EventList
        listData={onCommonPage ? intitalData?.entries : eventPostsData?.entries}
        redirectToDetailsPage={redirectToDetailsPage}
        activeCommunity={activeCommunity}
      />
      {isLoading && <SearchLoadingSpinner />}
      {eventPostsData?.links?.pages && eventPostsData.links.pages > 1 && !onCommonPage && (
        <Pagination
          handlePageClick={(page) => fetchEventPosts(searchQuery, page?.selected + 1)}
          pageCount={eventPostsData?.links?.pages}
          className="mt-6"
        />
      )}
    </div>
  );
}

function EventList({ listData, redirectToDetailsPage, activeCommunity }) {
  const { primaryFont } = useSelector(getActiveCommunityFontsClass);

  function getCoverImage(coverUri) {
    if (coverUri && coverUri !== '' && coverUri.substring(0, 7) === 'uploads') return imageFetch(coverUri, {}, false);
    return coverUri;
  }

  return (
    <>
      {listData?.data?.map((event) => {
        const { id, calendar_channel_id, thumbnail, name, description, start_time } = event?.attributes;
        const postedStage = listData?.included?.find(
          (item) => item.type === 'channel' && event?.attributes?.stage_channel_id === item?.attributes?.id,
        );

        return (
          <div
            key={id}
            className="p-4 shadow rounded-lg mt-2 card cursor-pointer"
            onClick={() => redirectToDetailsPage(event?.attributes)}
          >
            <div className="text-xs text__body mb-3 pb-2 border-b border-gray-100">
              <p className="text__body">
                {$translatei18n('PostedIn')} <strong>{postedStage?.attributes?.name}</strong>
              </p>
            </div>
            <div className="my-auto rounded-lg overflow-hidden xl:flex-1">
              <div className="aspect-w-343 aspect-h-182 bg-gray-100">
                {thumbnail && <img className="block h-full w-full" src={getCoverImage(thumbnail)} alt="cover" />}
              </div>
            </div>
            <div className="event__card__text mt-4 text__body">
              <p className={classNames('text-md font-normal font-Geomanist-book leading-6', primaryFont)}>{name}</p>
              <div className="text-sm mt-1">
                <Truncate
                  lines={2}
                  ellipsis={
                    <span className="text-xs" style={{ color: activeCommunity?.accent_color }}>
                      ... Read more
                    </span>
                  }
                >
                  {description}
                </Truncate>
              </div>
              <div className="flex mt-2">
                <div className="text-sm flex items-center">
                  <CalendarIcon className="w-5 h-5" />
                  <div className="text-sm ltr:ml-2 rtl:mr-2 ltr:mr-3.5 rtl:ml-3.5">{moment(start_time).format('DD-MMM-YYYY')}</div>
                </div>
                <div className="text-sm flex event__card__text">
                  <ClockIcon className="w-5 h-5" />
                  <div className="text-sm ltr:ml-2 rtl:mr-2">{moment(getUtcTime(start_time)).format('LT')}</div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default EventsList;
