import server from '../api/server';
import serverV4 from '../api/v4Server';

export const verifyEmailRequest = async (data) => {
  return await new Promise((resolve, reject) => {
    server
      .post('/onboarding/confirm_email', {
        confirmation_token: data.confirmation_token,
        email: data.email,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
};

export const addPhoneNumber = async (data) => {
  return await new Promise((resolve, reject) => {
    server
      .post('/onboarding/add_phone_number', {
        user: {
          phone_country_code: data.phone_country_code,
          phone_number: data.phone_number,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
};

export const verifyPhoneRequest = async (data) => {
  return await new Promise((resolve, reject) => {
    server
      .post('/onboarding/verify_otp', {
        otp: data.otp,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
};

export const resendPhoneOtp = async () => {
  return await new Promise((resolve, reject) => {
    server
      .post('/onboarding/resend_otp')
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
};

export const resendEmailOtp = async () => {
  return await new Promise((resolve, reject) => {
    server
      .post('/users/confirmations/resend')
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
};

// export const completeOnboardingRequest = async () => {
//   return await new Promise((resolve, reject) => {

//     server
//       .post('/onboarding/complete_onboarding')
//       .then((response) => {
//         resolve(response.data);
//       })
//       .catch(reject);
//   });
// };

export const completeOnboardingRequest = async (data) => {
  return await new Promise((resolve, reject) => {
    serverV4
      .post('/onboarding/complete_onboarding', {
        user: {
          first_name: data.first_name,
          last_name: data.last_name,
          username: data.username,
          image: data.image,
          gender: data.gender,
          cover_image: data.cover_image,
          about: data.about,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
};

export const updateVoiceOnboardingStatus = async (data) => {
  return await new Promise((resolve, reject) => {
    server
      .post('/onboarding/update_voice_onboarding_status', null, {
        params: {
          status: data.status,
          skipped: data.skipped,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch(reject);
  });
};
