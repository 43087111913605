/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */
import _ from 'lodash';

export const pluralize = (val, word, plural = `${word}s`) => {
  const _pluralize = (num, word, plural = `${word}s`) => ([1, -1].includes(Number(num)) ? word : plural);
  if (typeof val === 'object') return (num, word) => _pluralize(num, word, val[word]);
  return _pluralize(val, word, plural);
};

export const numFormatter = (num) => {
  if (num >= 1000000000) {
    return `${(num / 1000000000).toFixed(1).replace(/\.0$/, '')}g`;
  }
  if (num >= 1000000) {
    return `${(num / 1000000).toFixed(1).replace(/\.0$/, '')}m`;
  }
  if (num >= 1000) {
    return `${(num / 1000).toFixed(1).replace(/\.0$/, '')}k`;
  }
  return num;
};

export const getUtcTime = (dateTime) => {
  let time = new Date();

  if (!dateTime) {
    return time;
  }

  if (typeof dateTime === 'string' && !dateTime.includes('Z')) {
    let utcTIme = '';
    utcTIme = `${dateTime}Z`;

    time = new Date(utcTIme);
  } else {
    time = new Date(dateTime);
  }

  return time;
};

export const getFirstName = (name) => {
  if (!name) {
    return '';
  }

  const firstName = name.trim().split(' ')[0];
  return firstName;
};

export const getDaysLeft = (expiryDate) => {
  if (!expiryDate) return 0;

  const today = getUtcTime(new Date());
  const expire = getUtcTime(expiryDate);

  return Math.round((expire - today) / (1000 * 60 * 60 * 24));
};

export const isGiphyUrl = (uri = '') => {
  uri = uri.split('?')[0];
  const parts = uri.split('.');
  const extension = parts[parts.length - 1];
  const imageTypes = ['jpg', 'jpeg', 'tiff', 'png', 'gif', 'bmp', 'webp'];

  if (imageTypes.indexOf(extension) !== -1) {
    return true;
  }

  return false;
};

export const userAvtarName = (name = '') => {
  if (name && _.isString(name)) {
    const names = name.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }
  return '';
};

export const capitalizeFirstLetter = (string) => {
  if (!string) {
    return '';
  }
  return string?.charAt(0)?.toUpperCase() + string?.slice(1)?.toLowerCase();
};

export const arrayToBooleanObject = (dataArray = []) => {
  const dataObj = {};
  dataArray.forEach((data) => {
    dataObj[data] = true;
  });
  return dataObj;
};

export function hexToRgbA(hex, opaque = 1) {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = `0x${c.join('')}`;
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${opaque})`;
  }
  throw new Error('Bad Hex');
}
