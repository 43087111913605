export const generateQueryFromList = (list) => {
  let query = '?';
  list?.map((i, index) => {
    if (list.length - 1 === index) {
      query += 'filter=' + i;
    } else {
      query += 'filter=' + i + '&';
    }
  });
  if (query === '?') return window.location.pathname;
  else return query;
};

export const getFiltersFromQuery = () => {
  const params = new URLSearchParams(location.search);
  let objList = [];
  for (const [key, value] of params.entries()) {
    let obj = {};
    obj[key] = value;
    objList.push(obj);
  }
  return objList;
};

export const generateQueryUrl = (allFilterData, reset = false) => {
  const selectedFilterNameList = reset
    ? []
    : allFilterData?.filter((item) => item?.checked)?.map((tag) => `${tag?.label}_${tag?.id}`?.toLowerCase());
  const query = generateQueryFromList(selectedFilterNameList);
  return query;
};

export const uniqueMergeObjectsWithOrder = (arr1, arr2) => {
  const map = new Map();

  // Add elements from the first list to the map using their IDs
  arr1?.forEach((obj, index) => {
    if (!map.has(obj.id)) {
      map.set(obj.id, { obj, index });
    }
  });

  // Add elements from the second list to the map while maintaining order
  arr2?.forEach((obj, index) => {
    if (!map.has(obj.id)) {
      map.set(obj.id, { obj, index: map.size }); // Use current size as index to maintain order
    }
  });

  // Sort the map by index and extract the unique objects
  const uniqueObjects = Array.from(map.values())
    .sort((a, b) => a.index - b.index)
    .map(({ obj }) => obj);

  return uniqueObjects;
};

export const uniqueMergeWithOrder = (arr1, arr2) => {
  const map = new Map();

  // Add elements from the first list to the map along with their indices
  arr1.forEach((elem, index) => {
    if (!map.has(elem)) {
      map.set(elem, index);
    }
  });

  // Add elements from the second list to the map while maintaining order
  arr2.forEach((elem) => {
    if (!map.has(elem)) {
      map.set(elem, map.size); // Use current size as index to maintain order
    }
  });

  // Sort the map by index and extract the unique elements
  const uniqueArray = Array.from(map)
    .sort((a, b) => a[1] - b[1])
    .map(([key]) => key);

  return uniqueArray;
};

export const removeJwtToken = (queryString) => {
  const params = new URLSearchParams(queryString);
  if (params.has('jwt_token')) {
    params.delete('jwt_token');
  }
  return params.toString();
};
