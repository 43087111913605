import { CategorySection, GroupSection, RoleSection } from './VectorView';

const ITEMABLE_TYPE = {
  GROUP: 'Group',
  COMMUNITY_CATEGORY: 'CommunityCategory',
  COMMUNITY_ROLE: 'CommunityRole',
};

const productData = {
  [ITEMABLE_TYPE.GROUP]: {
    title: 'paid group',
    descrition: `Great for creating premium Cohorts, courses or Clubs within your community.
  Groups have their own page, channels and content.`,
    buttonText: 'create a group',
    VectorComponent: GroupSection,
  },
  [ITEMABLE_TYPE.COMMUNITY_CATEGORY]: {
    id: 1,
    title: 'paid category',
    descrition: `Easiest way to paywall content in your community. Helpful for 
  communities with a simple role structure.`,
    buttonText: 'Create a Category',
    VectorComponent: CategorySection,
  },
  [ITEMABLE_TYPE.COMMUNITY_ROLE]: {
    id: 2,
    title: 'paid role',
    descrition: `Great as a status symbol. A standalone role whose access to 
  existing groups and content can be set manually. `,
    buttonText: 'create a role',
    VectorComponent: RoleSection,
  },
};

export {
  productData,
  ITEMABLE_TYPE,
};
