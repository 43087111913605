import axios from 'axios';
import { ERROR_CODE } from '../constants';

const BASE_URL = `${process.env.REACT_APP_RAILS_HOST}/api`;
const server = axios.create({
  baseURL: `${BASE_URL}`,
  timeout: 600000,
  headers: {
    'Access-Control-Allow-Origin': process.env.REACT_APP_RAILS_HOST,
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  },
});

const BASE_URL_SERVERLESS = `https://${process.env.REACT_APP_PHOENIX_HOST}/api`;
const serverV = axios.create({
  baseURL: `${BASE_URL_SERVERLESS}`,
  timeout: 600000,
  headers: {
    'Access-Control-Allow-Origin': process.env.REACT_APP_RAILS_HOST,
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  },
});

export const fetchPublicCommunityFeed = async (data) => await new Promise((resolve, reject) => {
  server
    .get(`/guest/feed?page=${data?.page || 1}&group_id=${data?.activeGroupId}&sort=${data?.sort || ''}`, {
      params: {
        request_host: data?.host,
      },
    })
    .then((response) => {
      resolve(response?.data);
    })
    .catch(reject);
});

export const fetchPublicCommunityGroups = async () => await new Promise((resolve, reject) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  serverV
    .get(`/guest/groups?timezone=${timezone}`)
    .then((response) => {
      resolve(response?.data);
    })
    .catch(reject);
});

export const fetchPublicCommunityChannels = async (data) => await new Promise((resolve, reject) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  serverV
    .get(`/guest/groups/${data?.activeGroupId}/channels?timezone=${timezone}`)
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch(reject);
});

// FORUM
export const fetchPublicForumPostService = async (data) => await new Promise((resolve, reject) => {
  server
    .get(`/guest/groups/${data.group_id}/channels/${data.channel_id}/forum_posts`, {
      params: {
        page: data.page,
      },
    })
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch(reject);
});

// RESOURCES
export const getPublicResources = (communityId, channelId, groupId, page = 1, resourceId) => {
  if (resourceId) {
    return server.get(`/guest/groups/${groupId}/channels/${channelId}/resource_posts/${resourceId}`);
  }

  return server.get(`/guest/groups/${groupId}/channels/${channelId}/resource_posts?page=${page}`);
};

// Calander
export const getCalendarEventsNew = (
  communityId,
  channelId,
  futureEvents, // boolean
  timezone,
  groupId,
  nextPage,
  perPage = 1,
) => {
  if (nextPage) {
    return serverV.get(
      `/guest/groups/${groupId}/calendar_events?per_page=${perPage}&next_page_id=&calendar_channel_id=${channelId}&active=${futureEvents}&timezone=${timezone}`,
    );
  }

  return serverV.get(
    `/guest/groups/${groupId}/calendar_events?per_page=${perPage}&next_page_id=&calendar_channel_id=${channelId}&active=${futureEvents}&timezone=${timezone}`,
  );
};

export const endCalendarEvent = (communityId, eventId, groupId) => server.put(`/guest/groups/${groupId}/calendar_events/${eventId}/end`, {});

// Gloabl search
export function fetchAllPublicSearchResults(query, activeCommunityId, activeGroupId) {
  return server.get(`/guest/groups/${activeGroupId}/global_search`, {
    params: {
      search: query,
      page: 1,
    },
  });
}

export function fetchSearchPublicResults(query, queryType, page, activeCommunityId, activeGroupId) {
  return server.get(`/guest/groups/${activeGroupId}/global_search`, {
    params: {
      search: query,
      page,
      query_type: queryType,
    },
  });
}

export function fetchGloabalPublicSearchUsers(activeCommunityId, activeGroupId, query, page = 1, queryType = 'user') {
  return server.get(`/guest/groups/${activeGroupId}/global_search`, {
    params: {
      search: query,
      page,
      query_type: queryType,
      per_page: 50,
    },
  });
}

// Zoom
export const getPublicZoomEvents = (communityId, groupId, channelId) => serverV.get(`/guest/groups/${groupId}/channels/${channelId}/zoom`);

// Comments
export const fetchPublicForumPostCommentRepliesService = async (data) => await new Promise((resolve, reject) => {
  server
    .get(
      `/guest/groups/${data.group_id}/channels/${data.channel_id}/forum_posts/${data.post_id}/forum_comments/${data.comment_id}/forum_replies`,
      {
        params: {
          page: data.page,
        },
      },
    )
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch(reject);
});

export const fetchPublicForumPostCommentService = async (data) => await new Promise((resolve, reject) => {
  server
    .get(
      `/guest/groups/${data.group_id}/channels/${data.channel_id}/forum_posts/${data.post_id}/forum_comments`,
      {
        params: {
          page: data.page,
        },
      },
    )
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch(reject);
});

export const getResourcesPublic = (communityId, channelId, groupId, page = 1, resourceId) => {
  if (resourceId) {
    return server.get(
      `/guest/groups/${groupId}/channels/${channelId}/resource_posts/${resourceId}`,
    );
  }

  return server.get(`/guest/groups/${groupId}/channels/${channelId}/resource_posts?page=${page}`);
};

export const getResourceCommentsPublic = (communityId, channelId, resourceId, page = 1, groupId) => server.get(
  `/guest/groups/${groupId}/channels/${channelId}/resource_posts/${resourceId}/resource_comments?page=${page}`,
);

export const getCalendarEventDetails = (communityId, channelId, eventId, groupId) => {
  return server.get(
    `/guest/groups/${groupId}/calendar_events/${eventId}?calendar_channel_id=${channelId}`,
  );
}

server.interceptors.response.use(
  async response => response, error => {
    if(error.response && error.response.status && error.response.status){
      if(error.response?.data?.error_code === ERROR_CODE.POST_NOT_FOUND_ERROR_CODE) {
        return Promise.reject(error)
      }
    }
  }
)
