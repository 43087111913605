import { all, fork, put, takeLatest } from 'redux-saga/effects';
import { SUCCESS_MESSAGES } from '../constants/notificationMessages';
import { history, parseAxiosErrorMessage } from '../constants/utils';
import NotificationService from '../services/notificationService';
import {
  addPhoneNumber as addPhoneNumberService,
  completeOnboardingRequest,
  resendEmailOtp as resendEmailOtpService,
  resendPhoneOtp as resendPhoneOtpService,
  updateVoiceOnboardingStatus,
  verifyEmailRequest,
  verifyPhoneRequest,
} from '../services/onboardingServices';
import AnalyticsService from '../services/AnalyticsService';
import {
  EMAIL_RESEND_OTP_FAILED,
  EMAIL_RESEND_OTP_REQUEST,
  EMAIL_RESEND_OTP_SUCCESS,
  ONBOARDING_ADD_PHONE_NUMBER_FAILED,
  ONBOARDING_ADD_PHONE_NUMBER_REQUEST,
  ONBOARDING_ADD_PHONE_NUMBER_SUCCESS,
  ONBOARDING_COMPLETE_PROCESS_FAILED,
  ONBOARDING_COMPLETE_PROCESS_REQUEST,
  ONBOARDING_COMPLETE_PROCESS_SUCCESS,
  ONBOARDING_RESEND_PHONE_OTP_FAILED,
  ONBOARDING_RESEND_PHONE_OTP_REQUEST,
  ONBOARDING_RESEND_PHONE_OTP_SUCCESS,
  ONBOARDING_VERIFY_COMMUNITY_STEP_UPDATE,
  ONBOARDING_VERIFY_EMAIL_FAILED,
  ONBOARDING_VERIFY_EMAIL_REQUEST,
  ONBOARDING_VERIFY_EMAIL_SUCCESS,
  ONBOARDING_VERIFY_PHONE_NUMBER_FAILED,
  ONBOARDING_VERIFY_PHONE_NUMBER_REQUEST,
  ONBOARDING_VERIFY_PHONE_NUMBER_SUCCESS,
  UPDATE_VOICE_ONBOARDING_STATUS_FAILED,
  UPDATE_VOICE_ONBOARDING_STATUS_REQUEST,
  UPDATE_VOICE_ONBOARDING_STATUS_SUCCESS,
} from '../store/actions/actionTypes';
import { setUser } from '../store/actions/userActions';
import { store } from '../store/configureStore';
import { onboardingEntriesPost } from '../services/communityService';

export function* verifyEmail() {
  yield takeLatest(ONBOARDING_VERIFY_EMAIL_REQUEST, function* ({ payload }) {
    const {data, setter} = payload;
    try {
      setter && setter(true);
      const response = yield verifyEmailRequest(data);
      const { user, onboarding_status } = response;
      if (user && user?.data?.attributes) {
        user.data.attributes.onboarding_status = onboarding_status;
        yield put(setUser(user?.data?.attributes));
      }

      yield put({
        type: ONBOARDING_VERIFY_EMAIL_SUCCESS,
        payload,
      });

      if (onboarding_status === 'voice_onboard_start') {
        history.replace('/register/email/verify-otp');
      } else if (onboarding_status === 'voice_email_confirmed' || onboarding_status === 'voice_otp_sent') {
        history.replace('/onboarding/mobile');
      } else if (onboarding_status === 'voice_otp_verified') {
        history.replace('/onboarding/create-account');
      } else {
        history.replace('/');
      }

      NotificationService.success(SUCCESS_MESSAGES.EMAIL_VERIFIED);
    } catch (error) {
      setter && setter(false);
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);

      yield put({
        type: ONBOARDING_VERIFY_EMAIL_FAILED,
        payload,
      });
    }
  });
}

export function* addPhoneNumber() {
  yield takeLatest(ONBOARDING_ADD_PHONE_NUMBER_REQUEST, function* ({ payload }) {
    try {
      const response = yield addPhoneNumberService(payload);
      const { user, onboarding_status } = response;
      if (user && user?.data?.attributes) {
        user.data.attributes.onboarding_status = onboarding_status;
        yield put(setUser(user?.data?.attributes));
      }
      AnalyticsService.setUserAttributes({
        phone_number: payload.phone_number,
      });

      yield put({ type: ONBOARDING_ADD_PHONE_NUMBER_SUCCESS, payload });

      NotificationService.success(SUCCESS_MESSAGES.OTP_SENT_MOBILE);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);

      yield put({ type: ONBOARDING_ADD_PHONE_NUMBER_FAILED });
    }
  });
}

export function* verifyPhoneNumber() {
  yield takeLatest(ONBOARDING_VERIFY_PHONE_NUMBER_REQUEST, function* ({ payload }) {
    try {
      const response = yield verifyPhoneRequest(payload);
      const { user, onboarding_status } = response;
      if (user && user?.data?.attributes) {
        user.data.attributes.onboarding_status = onboarding_status;
        yield put(setUser(user?.data?.attributes));
      }

      yield put({ type: ONBOARDING_VERIFY_PHONE_NUMBER_SUCCESS, payload });

      if (onboarding_status === 'voice_onboard_start') {
        history.replace('/register/email/verify-otp');
      } else if (onboarding_status === 'voice_email_confirmed' || onboarding_status === 'voice_otp_sent') {
        history.replace('/onboarding/mobile');
      } else if (onboarding_status === 'voice_otp_verified') {
        history.replace('/onboarding/create-account');
      } else {
        history.replace('/');
      }

      NotificationService.success(SUCCESS_MESSAGES.MOBILE_VERIFY);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);

      yield put({ type: ONBOARDING_VERIFY_PHONE_NUMBER_FAILED });
    }
  });
}

export function* resendPhoneOtp() {
  yield takeLatest(ONBOARDING_RESEND_PHONE_OTP_REQUEST, function* () {
    try {
      yield resendPhoneOtpService();

      yield put({ type: ONBOARDING_RESEND_PHONE_OTP_SUCCESS });

      NotificationService.success(SUCCESS_MESSAGES.OTP_SENT_MOBILE);
    } catch (error) {
      const status = error?.response?.status
      let message = ""
      if(status === 429) {
        message = $translatei18n('error_message_otp_limit_exceed')
      } else {
        message = parseAxiosErrorMessage(error);
      }
      if(message) NotificationService.error(message);

      yield put({ type: ONBOARDING_RESEND_PHONE_OTP_FAILED });
    }
  });
}

export function* completeOnboarding() {
  yield takeLatest(ONBOARDING_COMPLETE_PROCESS_REQUEST, function* ({ payload }) {
    const { data, setter } = payload;
    try {
      setter(true);
      const response = yield completeOnboardingRequest(data);
      const { user, onboarding_status } = response;

      if (user && user?.data?.attributes) {
        user.data.attributes.onboarding_status = onboarding_status;
        yield put(setUser(user?.data?.attributes));
      }

      // API
      const referralCode = localStorage.getItem('ReferredBy');
      const inviteCode = localStorage.getItem('InviteCode');
      const onboardingPayload = {
        metadata: {},
      };

      if (referralCode) onboardingPayload.metadata.referral_code = referralCode;
      if (inviteCode) onboardingPayload.metadata.invite_code = inviteCode;

      const responseOnboard = yield onboardingEntriesPost(onboardingPayload);
      store.dispatch({
        type: ONBOARDING_VERIFY_COMMUNITY_STEP_UPDATE,
        payload: responseOnboard?.data?.status,
      });

      AnalyticsService.setUserAttributes({
        personal_referral_code: user?.data?.attributes?.referral_code || 'na',
        invited_via_referral_code: user?.data?.attributes?.invited_via_referral_code || 'na',
      });

      yield put({ type: ONBOARDING_COMPLETE_PROCESS_SUCCESS });
      NotificationService.success(SUCCESS_MESSAGES.ACCOUNT_CREATED);
      window.location.href = window.location.origin;
    } catch (error) {
      setter(false);
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);

      yield put({ type: ONBOARDING_COMPLETE_PROCESS_FAILED });
    }
  });
}

export function* resendEmailOtp() {
  yield takeLatest(EMAIL_RESEND_OTP_REQUEST, function* () {
    try {
      yield resendEmailOtpService();

      yield put({ type: EMAIL_RESEND_OTP_SUCCESS });
      NotificationService.success(SUCCESS_MESSAGES.OTP_SENT_EMAIL);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);

      yield put({ type: EMAIL_RESEND_OTP_FAILED });
    }
  });
}

export function* updateVoiceOnboarding() {
  yield takeLatest(UPDATE_VOICE_ONBOARDING_STATUS_REQUEST, function* ({ payload }) {
    try {
      const response = yield updateVoiceOnboardingStatus(payload);
      const { user, onboarding_status } = response;

      if (user && user?.data?.attributes) {
        user.data.attributes.onboarding_status = onboarding_status;
        yield put(setUser(user?.data?.attributes));
      }

      yield put({ type: UPDATE_VOICE_ONBOARDING_STATUS_SUCCESS });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);

      yield put({ type: UPDATE_VOICE_ONBOARDING_STATUS_FAILED });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(verifyEmail),
    fork(addPhoneNumber),
    fork(verifyPhoneNumber),
    fork(resendPhoneOtp),
    fork(completeOnboarding),
    fork(resendEmailOtp),
    fork(updateVoiceOnboarding),
  ]);
}
