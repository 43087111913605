import React from 'react';
import { useSelector } from 'react-redux';
import { LockClosedIcon } from '@heroicons/react/solid';
import { CubeIcon, DocumentIcon, GiftIcon, HomeIcon, VolumeUpIcon } from '@heroicons/react/outline';
import { imageFetch } from '../../services/preSignedAws';
import { getActiveCommunity } from '../../selectors/CommunitySelectors';
import personIcon from '../../assets/images/icons/person.svg';
import PaidGroup from '../../assets/images/empty-state/paid-group-left-rounded.svg';
import PaidCategory from '../../assets/images/empty-state/paid-category-left-rounded.svg';
import PaidRole from '../../assets/images/empty-state/paid-role-left-rounded.svg';
import person1 from '../../assets/images/icons/person1.svg';
import person2 from '../../assets/images/icons/person2.svg';
import person3 from '../../assets/images/icons/person3.svg';
import person4 from '../../assets/images/icons/person4.svg';
import person5 from '../../assets/images/icons/person5.svg';

export function GroupSection() {
  const currentActiveCommunity = useSelector(getActiveCommunity);
  return (
    <div className="absolute top-1/2 -translate-y-1/2 -right-2 h-3/4" style={{ width: '35%' }}>
      <img src={PaidGroup} alt="paid-group" className="w-full h-full" />
    </div>
  );
}

export function CategorySection() {
  return (
    <div className="absolute top-1/2 -translate-y-1/2 -right-2 h-3/4" style={{ width: '35%' }}>
      <img src={PaidCategory} alt="paid-category" className="w-full h-full" />
    </div>
  );
}

export function RoleSection() {
  return (
    <div className="absolute top-1/2 -translate-y-1/2 -right-2 h-3/4" style={{ width: '35%' }}>
      <img src={PaidRole} alt="paid-role" className="w-full h-full" />
    </div>
  );
}
