import { SET_MIXPANEL_ATTRIBUTES, FETCH_MIXPANEL_ATTRIBUTES } from './actionTypes';

export const fetchMixpanelAttributes = (id) => {
  return {
    type: FETCH_MIXPANEL_ATTRIBUTES,
    payload: id,
  };
};

export const setMixpanelAPIAttributes = (token, identifier) => {
  return {
    type: SET_MIXPANEL_ATTRIBUTES,
    payload: { mixpanelToken: token, internalIdentifier: identifier },
  };
};

export const setMixpanelAttributes = (payload) => ({
  type: SET_MIXPANEL_ATTRIBUTES,
  payload,
});
