export const rolesModel = (res) => {
  const {
    data: {
      channel_types,
      general_permissions,
      community_roles,
      category_permissions,
      group_permissions,
      community_categories,
    },
  } = res;
  const communityRolesObj = {};
  community_roles.forEach((item) => {
    communityRolesObj[item.id] = item;
  });

  const roleGeneralPermission = {};
  community_roles.forEach((item) => {
    const finalObj = {};
    Object.keys(general_permissions).forEach((value) => {
      finalObj[value] = {
        checked: communityRolesObj[item.id].general_permissions.includes(value),
        ...general_permissions[value],
      };
    });
    roleGeneralPermission[item.role] = finalObj;
  });

  const rolesArray = community_roles
    .map((ele) => ({
      id: ele.id,
      role: ele.role,
      community_id: ele.community_id,
    }))
    .sort((a, b) => (a?.role?.toLowerCase() < b?.role?.toLowerCase() ? -1 : 1));

  return {
    channelTypes: channel_types,
    generalPermissions: general_permissions,
    communityRoles: communityRolesObj,
    rolesArray,
    roleGeneralPermission,
    categoryPermissionOptions: category_permissions,
    groupPermissionOptions: group_permissions,
    communityGroupwiseCategories: community_categories,
  };
};

export const communityRolesModel = (res) => {
  const {
    data: { community_roles },
  } = res;

  const communityRolesObj = {};
  community_roles.forEach((item) => {
    communityRolesObj[item.id] = item;
  });

  const rolesArray = community_roles.map((ele) => ({
    id: ele.id,
    role: ele.role,
    community_id: ele.community_id,
  }));

  return { communityRoles: communityRolesObj, rolesArray };
};
