import { Fragment, useRef, useState } from 'react';
import { Dialog, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import { useSelector } from 'react-redux';
import { ErrorMessage, Formik } from 'formik';
import CommonInput from '../shared/tailwind/CommonInput';
import { getActiveGroupId, getActiveCommunity } from '../../selectors/CommunitySelectors';
import CommonSelect from '../shared/tailwind/CommonSelect';
import CommonTextArea from '../shared/tailwind/TextArea';
import { REPORT_TYPE, REPORT_REASON_TITLE, REPORT_REASONS } from '../../constants';
import {
  reportForumComment,
  reportForumPost,
  reportForumReply,
  reportResourceComment,
  reportUser,
} from '../../services/moderationService';
import NotificationService from '../../services/notificationService';
import AnalyticsService from '../../services/AnalyticsService';
import { parseAxiosErrorMessage } from '../../constants/utils';
import { reportPostSchema } from '../../schema/forumSchema';
import { CustomErrorMessage } from '../shared/ErrorMessage';
import AttachVideo from '../VideoUpload/Index';
import { AttachImage } from '../../views/channel/forum/forumCreatePost';
import { getPresignedPostData, uploadFileToS3 } from '../../services/preSignedAws';
import { MODAL_TYPE } from '../../constants/pricingConstants';

export default function ReportModal({
  open,
  onClose,
  heading,
  subheading,
  reportType,
  communityId,
  channelId,
  postId,
  commentId,
  replyId,
  userId,
  userName,
}) {
  const cancelButtonRef = useRef(null);
  const activeGroupId = useSelector(getActiveGroupId);
  const { name: activeCommunityName } = useSelector(getActiveCommunity);

  const [rawImage, setRawImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [formSubmitLoading, setFormSubmitLoading] = useState(false);

  const logReporting = () => {
    AnalyticsService.logMixpanelEvent('Report Created', {
      'Community ID': communityId,
      'Community Name': activeCommunityName,
    });
  };

  const handleOnSubmit = async (values) => {
    setFormSubmitLoading(true);
    try {
      let response;

      switch (reportType) {
        case REPORT_TYPE.FORUM_POST:
          response = await reportForumPost(communityId, channelId, postId, values, activeGroupId);
          logReporting();
          break;
        case REPORT_TYPE.FORUM_COMMENT:
          response = await reportForumComment(communityId, channelId, postId, commentId, values, activeGroupId);
          logReporting();
          break;
        case REPORT_TYPE.FORUM_REPLY:
          response = await reportForumReply(communityId, channelId, postId, commentId, replyId, values, activeGroupId);
          logReporting();
          break;
        case REPORT_TYPE.RESOURCE_COMMENT:
          response = await reportResourceComment(communityId, channelId, postId, commentId, values, activeGroupId);
          logReporting();
          break;

        case REPORT_TYPE.USER_REPORT:
          if (values.image_url.length === 0 && rawImage) {
            setIsLoading(true);
            let imgSize = 0;
            imgSize = rawImage.size;
            if (imgSize <= 10000000) {
              const fileObj = {
                uri: rawImage,
                name: `IMG_${Date.now()}`,
                type: rawImage.type,
              };
              const url = 'onboarding/request_presigned_url';
              const data = await getPresignedPostData(url, fileObj.name, {
                type: 'user',
              });
              const fileUploadResponse = await uploadFileToS3(data, fileObj);
              window.DOMParser = require('xmldom').DOMParser;
              const responseDoc = new DOMParser().parseFromString(fileUploadResponse.data, 'application/xml');
              values.image_url = responseDoc.getElementsByTagName('Key')[0].childNodes[0].nodeValue;
              setIsLoading(false);
            }
          }
          const data = {
            user_report: {
              description: values.reason,
              report_type: values.report_type,
              user_id: userId,
              image_url: values.image_url,
            },
            video_upload_id: values?.video_upload_ids[0],
          };
          !values.image_url || values?.image_url?.length === 0
            ? delete data.user_report.image_url
            : delete data.video_upload_id;
          !data.video_upload_id && delete data.video_upload_id;
          response = await reportUser(data);
          logReporting();
          break;

        default:
          break;
      }
      if (response && response.status >= 200 && response.status < 300) {
        NotificationService.success('Your report has been submitted for review.');
      }
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    } finally {
      setFormSubmitLoading(false);
      setIsLoading(false);
      setRawImage('');
      onClose();
    }
  };

  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={onClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center md:block md:p-0">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"  onClick={onClose} />
          </TransitionChild>

          <span className="hidden md:inline-block md:align-middle md:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            enterTo="opacity-100 translate-y-0 md:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 md:scale-100"
            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
          >
            <div className="inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all md:my-8 md:align-middle md:max-w-lg md:w-full">
              <Formik
                initialValues={{
                  report_type: REPORT_REASONS[0],
                  reason: '',
                  image_url: '',
                  media_types: [],
                  video_upload_ids: [],
                }}
                validationSchema={reportPostSchema}
                onSubmit={handleOnSubmit}
                enableReinitialize
              >
                {({ values, handleSubmit, setFieldValue, handleChange }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="card px-4 pt-5 pb-4 md:p-6 md:pb-4">
                      <div className="md:flex md:items-start">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 md:mx-0 md:h-10 md:w-10">
                          <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                        </div>
                        <div className="mt-3 text-center md:mt-0 md:ml-4 md:text-left">
                          <DialogTitle as="h3" className="text-lg leading-6 font-medium text__title">
                            {heading}
                          </DialogTitle>
                          <div className="mt-2">
                            <p className="text-sm text__description">{subheading}</p>
                          </div>
                        </div>
                      </div>

                      <div className="mt-6">
                        <CommonInput label="Reported User" name="reported-user" value={userName} disabled />
                      </div>
                      <div className="mt-6">
                        <CommonInput
                          label={$translatei18n('ReportedItem')}
                          name="reported-item"
                          value={reportType}
                          disabled={true}
                        />
                      </div>
                      <div className="mt-6">
                        <CommonSelect
                          label={REPORT_REASON_TITLE}
                          list={REPORT_REASONS.map((d) => ({ label: d, value: d }))}
                          name="report_type"
                          selected={values.report_type}
                          onChange={(value) => setFieldValue('report_type', value)}
                        />
                      </div>
                      <div className="mt-6 mb-4">
                        <CommonTextArea
                          label={$translatei18n('Details')}
                          placeholder="Add anything you would like us to know"
                          value={values.reason}
                          name="reason"
                          onChange={handleChange}
                        />
                        <ErrorMessage name="reason" render={CustomErrorMessage} />
                      </div>
                      {reportType === REPORT_TYPE.USER_REPORT && (
                        <div className="text__description text-sm flex flex-wrap gap-2 items-center">
                          {values.video_upload_ids.length === 0 && (
                            <AttachImage
                              setFieldValue={setFieldValue}
                              currentImageUrl={values.image_url}
                              isLoading={isLoading}
                              setIsLoading={setIsLoading}
                              setRawImage={(rawFile) => setRawImage(rawFile)}
                              rawImage={rawImage}
                              type={MODAL_TYPE.REPORT_MODAL}
                            />
                          )}
                          {!rawImage && (!values.image_url || values?.image_url?.length === 0) && (
                            <AttachVideo
                              muxPassthrough={'user_report'}
                              setFieldValue={setFieldValue}
                              currentVideoUrl={values.video_upload_ids}
                              media_types={values.media_types}
                              isLoading={isLoading}
                              setIsLoading={setIsLoading}
                            />
                          )}
                        </div>
                      )}
                    </div>
                    <div className="card__footer px-4 py-3 md:px-6 md:flex md:flex-row-reverse">
                      <button
                        disabled={isLoading || isLoading}
                        className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-0 focus:ring-offset-0 md:ml-3 md:w-auto md:text-sm btn__danger ${
                          formSubmitLoading || isLoading ? 'opacity-50' : 'opacity-100'
                        }`}
                        type="submit"
                      >
                        {$translatei18n('Send')} Report
                      </button>
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border btn__light shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-0 focus:ring-offset-0 md:mt-0 md:ml-3 md:w-auto md:text-sm"
                        onClick={() => {
                          setFormSubmitLoading(false);
                          setIsLoading(false);
                          setRawImage('');
                          onClose();
                        }}
                        ref={cancelButtonRef}
                      >
                        {$translatei18n('Cancel')}
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
}
