import { CHANNEL_TYPES } from '../../../constants';
import { getForumPermissionsConfig, getPostsIdsForData } from '../forum/forumModel';

const retrievePostsIdsForData = (posts) => {
  const postIds = {};
  if (!posts) {
    return postIds;
  }
  posts.forEach((item) => {
    postIds[item?.forum_post_id || item?.resource_post_id] = item;
  });
  return postIds;
};

export const postFeedDataModel = (data, forceValues) => {
  const {
    posts,
    links: { page, pages: totalPages },
    channel_permissions,
    post_votes,
    bookmarks,
  } = data;
  const forum_poll_votes = data?.forum_poll_votes?.data?.map((el) => el?.attributes);
  const forum_poll_options = data?.forum_poll_options;
  const feedPosts = [];

  const votedPostsIds = retrievePostsIdsForData(post_votes);
  const bookmarkedPostsIds = retrievePostsIdsForData(bookmarks);

  posts?.forEach((post) => {
    let postData = post;
    // let postData = post?.attributes;
    // postData.channel_type = post.type;
    // postData.id = post.id;
    // postData.id = post?.relationships?.user?.id;

    const pollOptions = [];
    if (!forum_poll_options.hasOwnProperty('data')) {
      const pollOptionsIds = forum_poll_options[post.id]?.map((opt) => opt?.id);
      if (pollOptionsIds?.length > 0) {
        pollOptionsIds?.forEach((optId) => {
          if (forum_poll_options[post.id]) {
            const option = forum_poll_options[post.id]?.find((opt) => opt?.id == optId);
            if (optId == option?.id) {
              const pollVotedOption = forum_poll_votes?.find(
                (opx) =>
                  opx?.forum_post_id == option?.forum_post_id &&
                  opx?.poll_option_id == option?.id &&
                  option?.vote_count > 0,
              );
              if (pollVotedOption) {
                option.pollVoted = true;
              }
              pollOptions.push(option);
            }
          }
        });
      }
    }
    if (forum_poll_options.hasOwnProperty('data')) {
      const pollOptionsIds = forum_poll_options[post.id]?.map((opt) => opt?.id);
      if (pollOptionsIds?.length > 0) {
        pollOptionsIds?.forEach((optId) => {
          if (forum_poll_options?.data) {
            const option = forum_poll_options?.data?.map((el) => el?.attributes)?.find((opt) => opt?.id == optId);
            if (optId == option?.id) {
              const pollVotedOption = forum_poll_votes?.find(
                (opx) =>
                  opx?.forum_post_id == option?.forum_post_id &&
                  opx?.poll_option_id == option?.id &&
                  option?.vote_count > 0,
              );
              if (pollVotedOption) {
                option.pollVoted = true;
              }
              pollOptions.push(option);
            }
          }
        });
      }
    }
    if (pollOptions?.length > 0) {
      postData.pollOptions = pollOptions;
      postData.pollTotalCount = pollOptions?.reduce((a, b) => a + (b?.vote_count || 0), 0);
    }

    const postVoted = votedPostsIds[postData.id];
    if (postData.channel_type === CHANNEL_TYPES.FORUM) {
      postData.forumPermissions = getForumPermissionsConfig(channel_permissions[postData.channel_id]);
    }

    if (postVoted) {
      postData.voted = true;
      postData.vote = postVoted?.vote_flag;
    }

    if (forceValues) {
      postData = { ...postData, ...forceValues };
    }

    if (bookmarkedPostsIds[postData.id]?.id) {
      postData.hasBookmarked = true;
    }

    feedPosts.push(postData);
  });

  return {
    posts: feedPosts,
    permissions: channel_permissions,
    page,
    totalPages,
  };
};
