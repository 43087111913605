import {
  SET_DRAFT_POSTS,
  SET_SELECTED_SCHEDULED_AND_DRAFTS_DATA,
  SET_SCHEDULED_DRAFTS_COUNT,
  SET_SCHEDULED_POSTS,
  SET_POLL_OPTIONS,
  SET_VIEW_ALL,
  SET_DRAFTS_PAGE_MANAGER,
  SET_SCHEDULE_PAGE_MANAGER,
} from './actionTypes';

export const setScheduledPosts = (payload) => ({
  type: SET_SCHEDULED_POSTS,
  payload,
});

export const setDraftPosts = (payload) => ({
  type: SET_DRAFT_POSTS,
  payload,
});

export const setPollOptions = (payload) => ({
  type: SET_POLL_OPTIONS,
  payload,
});

export const setSelectedScheduledAndDraftsData = (payload) => ({
  type: SET_SELECTED_SCHEDULED_AND_DRAFTS_DATA,
  payload,
});

export const setScheduledNDraftsCount = (payload) => ({
  type: SET_SCHEDULED_DRAFTS_COUNT,
  payload,
});

export const setViewAll = (payload) => ({
  type: SET_VIEW_ALL,
  payload,
});

export const setDraftsPageManager = (payload) => ({
  type: SET_DRAFTS_PAGE_MANAGER,
  payload
})

export const setSchedulePageManager = (payload) => ({
  type: SET_SCHEDULE_PAGE_MANAGER,
  payload
})