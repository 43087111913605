/* eslint-disable no-case-declarations */
import _ from 'lodash';
import {
  AUTH_LOG_OUT_SUCCESS,
  CREATE_ROLES_REQUEST,
  FETCH_COMMUNITY_ROLES_SUCCESS,
  FETCH_COMMUNITY_USER_ROLE_SUCCESS,
  FETCH_ROLES_REQUEST,
  FETCH_ROLES_SUCCESS,
  RESET_SEARCH_COMMUNITY_MEMBER,
  SEARCH_COMMUNITY_MEMBER_REQUEST,
  SEARCH_COMMUNITY_MEMBER_SUCCESS,
  SET_FETCH_NEXT_PAGE,
  UPDATE_ROLES_REQUEST,
  UPDATE_ROLES_SUCCESS,
} from '../actions/actionTypes';

const initialState = {
  loading: false,
  error: false,
  channelTypes: {},
  generalPermissions: {},
  communityRoles: {},
  roleGeneralPermission: {},
  rolesArray: [],
  communityUserRolesList: [],
  communityUserRoleNextPageId: null,
  communityUserRolesListDataLength: 0,
  categoryPermissionOptions: [],
  groupPermissionOptions: [],
  communityGroupwiseCategories: {},
  fetchNextPage: false,
  currentPage: 1,
  searchCommunityUserRolesList: [],
};

// eslint-disable-next-line default-param-last
function RolesReducer(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_ROLES_REQUEST:
      return {
        ...state,
        fetchedRoles: true,
      };
    case FETCH_ROLES_SUCCESS:
      const {
        channelTypes,
        generalPermissions,
        communityRoles,
        roleGeneralPermission,
        rolesArray,
        categoryPermissionOptions,
        groupPermissionOptions,
        communityGroupwiseCategories,
      } = payload;
      return {
        ...state,
        loading: false,
        error: false,
        channelTypes,
        generalPermissions,
        communityRoles,
        roleGeneralPermission,
        rolesArray,
        categoryPermissionOptions,
        groupPermissionOptions,
        communityGroupwiseCategories,
      };

    case FETCH_COMMUNITY_ROLES_SUCCESS:
      return {
        ...state,
        communityRoles: payload.communityRoles,
        rolesArray: payload.rolesArray.sort((a, b) => (a?.role?.toLowerCase() < b?.role?.toLowerCase() ? -1 : 1)),
      };

    case CREATE_ROLES_REQUEST:
      return {
        ...state,
        createRole: true,
      };

    case UPDATE_ROLES_REQUEST:
      return {
        ...state,
        updatedRoles: true,
      };
    case UPDATE_ROLES_SUCCESS:
      const roleData = payload.data.community_role;
      if (state?.communityRoles?.[roleData.id]?.role !== roleData.role) {
        const newRolesArray = _.cloneDeep(state.rolesArray);
        const newCommunityRoles = _.cloneDeep(state.communityRoles);

        const idx = newRolesArray.findIndex((ele) => ele.id === roleData.id);
        newRolesArray[idx].role = roleData.role;

        newCommunityRoles[roleData.id].role = roleData.role;

        return {
          ...state,
          updatedRoles: false,
          rolesArray: newRolesArray,
          communityRoles: newCommunityRoles,
        };
      }
      return {
        ...state,
        updatedRoles: false,
      };

    case FETCH_COMMUNITY_USER_ROLE_SUCCESS:
      const { community_roles, user_community_roles, owner_community_roles } = payload.response.data;

      const reorderCommunityRoles = (roles) => {
        if (roles[0].role !== 'owner') {
          const ownerIndex = roles.findIndex((role) => role.role === 'owner');
          if (ownerIndex !== -1) {
            const [owner] = roles.splice(ownerIndex, 1);
            roles.unshift(owner);
          }
        }
        return roles;
      };

      const combinedData =  owner_community_roles ? [...owner_community_roles, ...user_community_roles.data] : [...user_community_roles.data];

      const arrSection = _.cloneDeep(payload?.is_reset ? [] : state.communityUserRolesList);
      reorderCommunityRoles(community_roles).map((communityRole) => {
        const arrUsers = combinedData.filter(
          (element) => element.community_role_id == communityRole.id,
        );

        const alreadyCommunity = arrSection.find((item) => item.id === communityRole.id);
        if (alreadyCommunity !== undefined && alreadyCommunity !== null && !_.isEmpty(alreadyCommunity)) {
          const index = arrSection.findIndex((element) => element.id == alreadyCommunity.id);
          if (index !== -1 && arrUsers.length > 0) {
            const arrDeepData = _.cloneDeep(alreadyCommunity.data);
            const children = arrDeepData.concat(arrUsers);
            alreadyCommunity.data = children;
            alreadyCommunity.memberLength = communityRole.users_count;
            arrSection[index] = alreadyCommunity;
          }
        } else if (arrUsers && arrUsers.length > 0) {
          arrSection.push({
            title: communityRole.role,
            data: arrUsers,
            memberLength: communityRole.users_count,
            id: communityRole.id,
            color: communityRole.color,
          });
        }
      });

      return {
        ...state,
        communityUserRolesList: arrSection,
        communityUserRoleNextPageId: user_community_roles?.metadata?.after,
        communityUserRolesListDataLength: payload?.is_reset
          ? user_community_roles?.data?.length
          : state.communityUserRolesListDataLength + user_community_roles?.data?.length,
        isSearch: false,
      };

    case SEARCH_COMMUNITY_MEMBER_REQUEST:
      return { ...state, isSearching: true };

    case SEARCH_COMMUNITY_MEMBER_SUCCESS:
      const userCommunityRoles = payload?.response?.data?.results;
      const sectionArr = _.cloneDeep(payload?.is_reset ? [] : state.searchCommunityUserRolesList);

      userCommunityRoles.forEach((userData) => {
        if (sectionArr.length > 0) {
          sectionArr[0]?.data?.push(userData);
        } else {
          sectionArr?.push({
            data: [userData],
          });
        }
      });

      let fetch = false;
      let page = state.currentPage;
      if (userCommunityRoles?.length >= 10) {
        fetch = true;
        page = payload?.is_reset ? 2 : state.currentPage + 1;
      } else {
        fetch = false;
      }

      return {
        ...state,
        searchCommunityUserRolesList: userCommunityRoles?.length ? sectionArr : [],
        communityUserRoleNextPageId: null,
        fetchNextPage: fetch,
        currentPage: page,
        searchCommunityUserRolesListDataLength: userCommunityRoles?.length,
        isSearch: true,
        isSearching: false,
      };

    case RESET_SEARCH_COMMUNITY_MEMBER:
      return {
        ...state,
        searchCommunityUserRolesList: [],
        searchCommunityUserRolesListDataLength: 0,
        isSearch: false,
        isSearching: false,
        fetchNextPage: false,
        currentPage: 1,
      };

    case SET_FETCH_NEXT_PAGE:
      return {
        ...state,
        fetchNextPage: payload,
        isSearch: true,
      };

    case AUTH_LOG_OUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
}

export default RolesReducer;
