import {
  setCommunitySubscriptionData,
  setCommunitySubscriptionDays,
  setCommunitySubscriptionStatus,
} from '../../store/actions/communityActions';
import { store } from '../../store/configureStore';

export const setSubscriptionDataInStore = (data) => {
  const subscriptionData = {
    exhausted: {
      community_channel_count: data?.community_channel_count,
      community_group_count: data?.community_group_count,
    },
    featureLimits: {
      ...data?.features,
    },
  };

  store.dispatch(setCommunitySubscriptionData(subscriptionData));
  if (data?.subscription_days) {
    store.dispatch(setCommunitySubscriptionDays(data?.subscription_days));
  }
  if (typeof data?.subscription_active !== "undefined" ) {
    store.dispatch(setCommunitySubscriptionStatus(data?.subscription_active));
  }
};


export const changeSubscriptionUseData = (key , change) => {
  const subscriptionFeature = store.getState()?.community?.subscriptionData?.featureLimits
  const subscriptionExhausted = store.getState()?.community?.subscriptionData?.exhausted
  subscriptionExhausted[key] += change
  const updatedData = {features : {...subscriptionFeature}, ...subscriptionExhausted}
  setSubscriptionDataInStore(updatedData)
}