import React from 'react';
import { useSelector } from 'react-redux';
import { SUBSCRIPTION_LOCKED_UI_ID } from '../../constants/subscriptionConstants';
import LockedUIHandler from './LockUIHandler';
import { getNumberData, checkComponentSubscriptionStatus } from '../../constants/subscriptionUtils';
import LockIcon from '../icons/LockIcon';

const index = ({ componentSubscriptionId = '', children = <></>, replacementId, route, handleClose, item, featureIdentifier, routeName }) => {
  const subscriptionData = useSelector((state) => state?.community?.subscriptionData);
  const isAfterSubscriptionCommunity = useSelector((state) => state?.community?.subscriptionActive);
  const toRenderSubscriptionUI = getNumberData(componentSubscriptionId, subscriptionData);
  const componentStatus = checkComponentSubscriptionStatus(
    toRenderSubscriptionUI,
    subscriptionData,
    componentSubscriptionId,
    isAfterSubscriptionCommunity,
  );

  if (componentStatus) {
    switch (replacementId) {
      case SUBSCRIPTION_LOCKED_UI_ID.CHANNEL_LOCK:
        return (
          <LockedUIHandler toRenderSubscriptionUI={toRenderSubscriptionUI} featureIdentifier={featureIdentifier} handleClose={handleClose} routeName={routeName} item={item}          >
            {children}
            <div className="flex flex-1 items-start justify-end absolute right-0 bottom-0 left-0 top-0 px-2">
              <div className="flex items-center gap-1 p-1.5 bg-gray-200 rounded-md m-2">
                <LockIcon id="lockIcon" strokeStyle="group-hover:stroke-[#CBD5E1]"  classNames="group w-5 h-5 rounded-full text-black bg-[#CBD5E1] hover:bg-[#475569]" />
                <p className="text-xs mt-0.5">Locked</p>
              </div>
            </div>
          </LockedUIHandler>
        );

      case SUBSCRIPTION_LOCKED_UI_ID.GROUP_LOCK:
        return (
          <LockedUIHandler
            toRenderSubscriptionUI={toRenderSubscriptionUI} 
            featureIdentifier={featureIdentifier}
            replacementId={replacementId}
            handleClose={handleClose} 
            routeName={routeName}
            place="right"
            offset={20}
            item={item}
          >
            {children}
            <div
              className="flex flex-1 items-end justify-center absolute right-0 bottom-0 left-0 top-0 px-2 border-2 rounded-lg border-gray-400"
              style={{ zIndex: 99999 }}
            >
              <LockIcon id="lockIcon" strokeStyle="group-hover:stroke-[#CBD5E1]"  classNames="group w-5 h-5 p-0.5 -mb-2 rounded-full text-black bg-[#CBD5E1] hover:bg-[#475569]" />
            </div>
          </LockedUIHandler>
        );

      case SUBSCRIPTION_LOCKED_UI_ID.SETTINGS_LOCK:
        return (
          <LockedUIHandler
            toRenderSubscriptionUI={toRenderSubscriptionUI} 
            featureIdentifier={featureIdentifier}
            route={route}
            handleClose={handleClose} 
            routeName={routeName}
            place="right"
            offset={10}
            item={item}
          >
            {children}
            {toRenderSubscriptionUI.text ? (
              toRenderSubscriptionUI.available ? (
                <div className="flex flex-1 items-center justify-end absolute right-0 bottom-0 left-0 top-0 px-2">
                  <div className="p-1 flex items-center justify-center bg-gray-200 text-xs rounded-full">
                    <h3 className="">{toRenderSubscriptionUI.text}</h3>
                  </div>
                </div>
              ) : (
                <div className="flex flex-1 items-center justify-end absolute right-0 bottom-0 left-0 top-0 px-2">
                  <LockIcon id="lockIcon" strokeStyle="group-hover:stroke-[#CBD5E1]"  classNames="group w-5 h-5 p-1 rounded-full text-black bg-[#CBD5E1] hover:bg-[#475569]" />
                </div>
              )
            ) : (
              <div className="flex flex-1 items-center justify-end absolute right-0 bottom-0 left-0 top-0 px-2">
                <LockIcon id="lockIcon" strokeStyle="group-hover:stroke-[#CBD5E1]"  classNames="group w-5 h-5 p-1 rounded-full text-black bg-[#CBD5E1] hover:bg-[#475569]" />
              </div>
            )}
          </LockedUIHandler>
        );
    }
  }
  // If the Component is available in the plan or free to use
  return <>{children}</>;
};

export default index;
