/* eslint-disable import/prefer-default-export */
export function Product(productData) {
  this.id = productData?.id;
  this.displayName = productData?.name;
  this.description = productData?.description;
  this.icon = productData?.icon;
  this.roleName = productData?.community_role_name;
  this.groupName = productData?.itemable_name;
  this.productType = productData?.itemable_type;
  this.currencyAmount = productData?.amount?.currency_count;
  this.coinsAmount = productData?.amount?.coins_count;
  this.condition = productData?.amount?.condition;
  this.paymentType = productData?.amount?.payment_type;
  this.roleId = productData?.community_role_id;
}

export const getProductData = (productData, prevProductArray) => {
  if (productData?.shop_item) {
    const data = [
      { ...new Product(productData?.shop_item) },
      ...prevProductArray,
    ];
    return data;
  }
  return [...prevProductArray];
};

export const getShopItemsArray = (
  response,
  prevProductArray,
  isPurchasedProducts,
  isFirstPage,
  latest,
) => {
  const products = [];
  response?.shop_items?.data?.forEach((item) => {
    const shopItem = isPurchasedProducts ? item?.id : new Product(item);
    products.push(shopItem);
  });
  if (isFirstPage || latest) return products;
  return prevProductArray ? [...prevProductArray, ...products] : products;
};

export const getPurchasedShopItems = (purchasedItems, prevProducts) => {
  const products = { ...prevProducts };
  purchasedItems?.data.forEach((item) => {
    const shopItem = {
      ...new Product({ ...item?.metadata, id: item?.id }),
      category_permissions: item?.metadata?.category_permissions,
      group_permissions: item?.metadata?.group_permissions,
    };
    products[item?.id] = shopItem;
  });
  return products;
};

export const getCreateProductData = (payload) => ({
  shop_item: {
    name: payload?.values?.displayName,
    description: payload?.values?.description,
    icon: payload?.values?.icon,
    community_role_name: payload?.values?.roleName,
    itemable_name: payload?.values?.groupName,
    itemable_type: payload?.productType,
    group_id: payload?.values?.group?.id,
    amount: {
      payment_type: 'one_time',
      // coins_count: parseFloat(payload?.values?.coinsAmount, 10),
      coins_count: 11,
      currency_count: parseFloat(payload?.values?.currencyAmount, 10),
      // condition: payload?.values?.condition?.toLowerCase(),
      condition: 'or',
    },
  },
});

export const getEditProductData = (payload) => ({
  shop_item: {
    name: payload?.values?.displayName,
    description: payload?.values?.description,
    icon: payload?.values?.icon,
    amount: {
      payment_type: 'one_time',
      coins_count: parseFloat(payload?.values?.coinsAmount, 10),
      currency_count: parseFloat(payload?.values?.currencyAmount, 10),
      condition: payload?.values?.condition?.toLowerCase(),
    },
  },
});

export const paymentStatus = {
  IDLE: 'idle',
  INITIALIZED: 'initialized',
  PROCESSING: 'processing',
  SUCCESS: 'completed',
  FAILED: 'failed',
};
