import { createSelector } from 'reselect';
import { CHANNEL_TYPES } from '../constants';

const getChannel = (state) => state.channel;

export const getActiveChannelId = createSelector([getChannel], (channel) => channel?.activeChannelData?.id);

export const getActiveChannelType = createSelector([getChannel], (channel) => channel?.activeChannelData?.channel_type);

export const getActiveChannelName = createSelector([getChannel], (channel) => channel?.activeChannelData?.name);

export const getActiveChannelData = createSelector([getChannel], (channel) => channel?.activeChannelData);

export const getCommunityOwner = createSelector([getChannel], (channel) => channel?.communityOwner);

export const getCommunityMember = createSelector([getChannel], (channel) => channel?.communityMember);

export const isForumChannelType = createSelector(
  [getChannel],
  (channel) => channel?.activeChannelData?.channel_type === CHANNEL_TYPES.FORUM,
);

// export const getCommunityFromChannel = createSelector([getChannel], (channel) => channel?.configs?.community);
