import React from 'react'
import HideContent from './HideContent'

export default function HideContentWrapper({circle, bar, styles, classNames}) {
  const {width: circleWidth, height: circleHeight, bgColor: circleBgColor, classNames: circleClassNames} = circle || {}
  const {width: barWidth, height: barHeight, bgColor: barBgColor} = bar || {}

  return (
    <div style={styles} className='hide_content_wrapper'>
      {circleWidth && circleHeight ? <HideContent width={circleWidth} height={circleHeight} bgColor={circleBgColor} classNames={circleClassNames} /> : null }
      {barWidth && barHeight ? <HideContent width={barWidth} height={barHeight} bgColor={barBgColor} /> : null }
    </div>
  )   
}
