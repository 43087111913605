import React, { Component } from 'react';
import { SearchIcon } from '@heroicons/react/outline';
import { ThreeDots } from 'react-loader-spinner';
import { debounce } from 'lodash';

import InfiniteScroll from 'react-infinite-scroll-component';

const GIPHY_API_KEY = process.env.REACT_APP_GIPHY_API_KEY;

class GifPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      GIFS: [],
      fetching: false,
      searchOffset: 0,
      initialSearch: 'Galaxy',
      visibleItems: [],
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.debounceSearch = debounce(() => this.searchGifs(), 700);
    this.searchGifs();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onChangeText = (text) => {
    this.setState({ searchText: text }, () => {
      if (text.length === 0) this.searchGifs();
      else this.debounceSearch();
    });
  };

  searchGifs = (loadMore = false) => {
    const { GIFS, searchText, searchOffset, initialSearch } = this.state;
    this.setState({ fetching: true });

    if (!loadMore) this.setState({ GIFS: [], visibleItems: [], searchOffset: 0 });

    const query = searchText.trim().length > 0 ? searchText.trim() : initialSearch;
    const url = `https://api.giphy.com/v1/gifs/search?api_key=${GIPHY_API_KEY}&q=${query}&limit=14&offset=${searchOffset}&rating=g&lang=en`;

    fetch(url)
      .then((resp) => resp.json())
      .then((result) => {
        if (result.data) {
          const gifs = result.data.map((item) => {
            return {
              id: item.id,
              url: item.images.fixed_height_downsampled.url,
              width: item.images.fixed_height_downsampled.width,
              height: item.images.fixed_height_downsampled.height,
            };
          });

          if (!this._isMounted) return;

          if (loadMore)
            this.setState({
              GIFS: [...GIFS, ...gifs],
              fetching: false,
            });
          else this.setState({ GIFS: gifs, fetching: false, searchOffset: 0 });
        }
      })
      .catch(() => {
        this.setState({ fetching: false });
      });
  };

  loadMoreGifData = () => {
    this.setState(
      (prevState) => ({
        searchOffset: prevState.searchOffset + 14,
        fetching: true,
      }),
      () => {
        this.searchGifs(true);
      },
    );
  };

  closeSearchModal = () => {
    this.props.closeGifPicker();
  };
  render() {
    const { openGifPicker, handleSelectedGif } = this.props;
    const { GIFS, searchText, fetching } = this.state;
    return (
      <div
        className="w-full inline-block align-bottom rounded-lg text-left overflow-hidden transform transition-all h-96 px-3"
      >
        <div className="flex items-center shadow-sm w-full border input rounded-md px-2 py-2">
          <SearchIcon className="w-5 h-5 main__icon" />
          <input
            type="text"
            name="searchGIF"
            id="searchGID"
            value={searchText}
            placeholder={$translatei18n('Search')}
            className="block px-2 py-0 w-full sm:text-sm focus:outline-none border-none focus:ring-0 input card"
            onChange={(e) => {
              this.onChangeText(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                this.handleSubmit();
              }
            }}
          />
        </div>

        <div
          id="gifID"
          style={{
            maxHeight: '480px',
            height: '480px',
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
            marginTop: '15px',
          }}
        >
          {/*Put the scroll bar always on the bottom*/}
          {GIFS?.length === 0 && fetching === true && (
            <div
              style={{
                display: 'flex',
                flex: 1,
                marginTop: 10,
                marginBottom: 10,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <ThreeDots color="#00BFFF" height={30} width={30} />
            </div>
          )}
          {GIFS?.length > 0 && (
            <InfiniteScroll
              dataLength={GIFS.length}
              next={() => this.loadMoreGifData()}
              className="grid grid-cols-1 gap-4 md:grid-cols-2"
              hasMore={true}
              inverse={false}
              loader={
                <div
                  style={{
                    display: 'flex',
                    flex: 1,
                    marginTop: 10,
                    marginBottom: 10,
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <ThreeDots color="#00BFFF" height={30} width={30} />
                </div>
              }
              scrollableTarget="gifID"
            >
              {GIFS?.map((item, i) => {
                return (
                  <div
                    onClick={() => {
                      handleSelectedGif(item.url), this.closeSearchModal();
                    }}
                    key={`main-${i}`}
                    className="exp-container"
                  >
                    <div className="description">
                      <img key={`key-${item.id}`} className="h-40 w-full rounded-md" src={item.url} alt="" loading='lazy' />
                    </div>
                  </div>
                );
              })}
            </InfiniteScroll>
          )}
        </div>
      </div>
    );
  }
}

export default GifPicker;
