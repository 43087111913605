import {
  UPDATE_STRIPE_CONNECTION_STATE,
  UPDATE_SELECT_PRODUCT_MODAL,
  CREATE_PRODUCT_SUCCESS,
  SET_UPDATE_PRODUCT_ID,
  DELETE_PRODUCT_SUCCESS,
  SET_COMMUNITY_SHOP_SUCCESS,
  SET_CREATE_SHOP_LOADING,
  SET_TEMP_PRODUCT_DATA,
  GET_SHOP_ITEMS,
  SET_COMMUNITY_SHOP_FAILED,
  GET_SHOP_ITEMS_SUCCESS,
  UPDATE_PRODUCT_SUCCESS,
  SET_PURCHASE_SUCCESS_DATA,
  INITIALIZE_STRIPE_PURCHASE,
  UPDATE_CREATE_PRODUCT_MODAL,
  GET_PURCHASED_SHOP_ITEMS_SUCCESS,
  SET_FORM_SUBMITTINIG,
  SET_PRODUCT_LIST_VIEW,
  GET_PURCHASED_SHOP_ITEMS,
  GET_SHOP_ITEMS_FAILED,
  GET_PURCHASED_SHOP_ITEMS_FAILED,
  UPDATE_RAZORPAY_STATE,
  UPDATE_COMMUNITY_SHOP_SUCCESS,
  UPDATE_CURRENCY,
  DISCONNECT_ACCOUNT_STRIPE,
} from '../actions/actionTypes';
import { getProductData, getPurchasedShopItems, getShopItemsArray, paymentStatus } from '../models/ShopModel';

export const shopInitialState = {
  isShopEnabled: false,
  shopName: '',
  stripe: { isStripeConnected: false, stripeAccountStatus: '', stripeAccountId: null, stripeCurrency: '' },
  payment_provider: '',
  go_live: false,
  displaySelectProductModal: false,
  shopItem: {
    products: [],
    metadata: null,
    isLoading: false,
  },
  editProductId: null,
  isCreateShopLoading: false,
  tempProductData: null,
  purchaseSuccessData: null,
  purchaseInitData: null,
  paymentStatus: paymentStatus.IDLE,
  showCreateProductModal: false,
  purchasedProducts: {
    products: {},
    productIds: [],
    metadata: null,
    isLoading: false,
  },
  isFormSubmitting: false,
  productListView: 'SHOP',
};

const reducer = (state = shopInitialState, action) => {
  switch (action.type) {
    case SET_COMMUNITY_SHOP_SUCCESS:
      return {
        ...state,
        shopId: action?.payload?.id,
        shopName: action?.payload?.name,
        isShopEnabled: action?.payload?.enabled,
        isCreateShopLoading: false,
        stripe: {
          stripeAccountStatus: action?.payload?.stripe?.stripe_account_status || state?.stripe?.stripeAccountStatus,
          stripeAccountId: action?.payload?.stripe?.stripe_account_id || state?.stripe.stripeAccountId,
          isStripeConnected: action?.payload?.stripe?.isStripeConnected || state?.stripe?.isStripeConnected,
          stripeCurrency: action?.payload?.stripe?.stripeCurrency || state?.stripe?.stripeCurrency,
        },
        razorpay: action?.payload?.razorpay,
        payment_provider: action?.payload?.payment_provider || '',
        go_live: action?.payload?.go_live || false,
      };

    case UPDATE_COMMUNITY_SHOP_SUCCESS:
      return {
        ...state,
        shopId: action?.payload?.id,
        shopName: action?.payload?.name,
        isShopEnabled: action?.payload?.enabled,
        go_live: action?.payload?.go_live,
        payment_provider: action?.payload?.payment_provider || '',
      };

    case SET_COMMUNITY_SHOP_FAILED:
      return {
        ...state,
        isCreateShopLoading: false,
      };

    case SET_CREATE_SHOP_LOADING:
      return {
        ...state,
        isCreateShopLoading: action?.payload?.isLoading,
      };

    case GET_SHOP_ITEMS:
      return {
        ...state,
        shopItem: {
          ...state.shopItem,
          isLoading: true,
        },
      };

    case GET_SHOP_ITEMS_SUCCESS:
      return {
        ...state,
        shopItem: {
          ...state.shopItem,
          products: getShopItemsArray(
            action?.payload?.response,
            state.shopItem.products,
            false,
            action?.payload?.isFirstPage,
            action?.payload?.latest,
          ),
          metadata: action?.payload?.shop_items?.metadata,
          isLoading: false,
        },
        stripe: {
          ...state.stripe,
          stripeCurrency: action?.payload?.response?.stripe_currency
        }
      };

    case GET_SHOP_ITEMS_FAILED:
      return {
        ...state,
        shopItem: {
          ...state.shopItem,
          isLoading: false,
        },
      };

    case GET_PURCHASED_SHOP_ITEMS:
      return {
        ...state,
        purchasedProducts: {
          ...state.purchasedProducts,
          isLoading: true,
        },
      };

    case GET_PURCHASED_SHOP_ITEMS_SUCCESS:
      return {
        ...state,
        purchasedProducts: {
          ...state.purchasedProducts,
          productIds: getShopItemsArray(
            { shop_items: action?.payload?.shop_item_purchase_logs },
            state.purchasedProducts.productIds,
            true,
          ),
          products: getPurchasedShopItems(action?.payload?.shop_item_purchase_logs, state?.purchasedProducts?.products),
          metadata: action?.payload?.shop_item_purchase_logs?.metadata,
          isLoading: false,
        },
      };

    case GET_PURCHASED_SHOP_ITEMS_FAILED:
      return {
        ...state,
        purchasedProducts: {
          ...state.purchasedProducts,
          isLoading: false,
        },
      };

    case UPDATE_STRIPE_CONNECTION_STATE:
      return {
        ...state,
        stripe: {
          ...state?.stripe,
          isStripeConnected: action?.payload?.isStripeConnected,
        },
      };

    case UPDATE_RAZORPAY_STATE:
      if (!action?.payload?.razorpay || action?.payload?.razorpay == null) {
        return {
          ...state,
          razorpay: null,
        };
      }
      return {
        ...state,
        razorpay: {
          ...state?.razorpay,
          ...(action?.payload?.razorpay || {}),
        },
      };

    case UPDATE_SELECT_PRODUCT_MODAL:
      return {
        ...state,
        displaySelectProductModal: action?.payload?.displaySelectProductModal,
      };

    case UPDATE_CREATE_PRODUCT_MODAL:
      return {
        ...state,
        showCreateProductModal: action?.payload?.showCreateProductModal,
      };

    case CREATE_PRODUCT_SUCCESS:
      return {
        ...state,
        shopItem: {
          ...state.shopItem,
          products: getProductData(action?.payload, state.shopItem.products),
        },
        displaySelectProductModal: false,
        showCreateProductModal: false,
        isFormSubmitting: false,
      };

    case SET_UPDATE_PRODUCT_ID:
      return {
        ...state,
        editProductId: action?.payload?.editProductId,
      };

    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        shopItem: {
          ...state.shopItem,
          products: action.payload,
        },
        displaySelectProductModal: false,
        showCreateProductModal: false,
        tempProductData: null,
        isFormSubmitting: false,
      };

    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        shopItem: {
          ...state.shopItem,
          products: action?.payload,
        },
      };

    case SET_TEMP_PRODUCT_DATA:
      return {
        ...state,
        tempProductData: action?.payload,
      };

    case SET_PURCHASE_SUCCESS_DATA:
      return {
        ...state,
        purchaseSuccessData: action?.payload,
        paymentStatus: action?.payload?.paymentStatus,
      };

    case INITIALIZE_STRIPE_PURCHASE:
      return {
        ...state,
        purchaseInitData: action?.payload,
        paymentStatus: paymentStatus.INITIALIZED,
      };

    case SET_FORM_SUBMITTINIG:
      return {
        ...state,
        isFormSubmitting: action?.payload?.isFormSubmitting,
      };

    case SET_PRODUCT_LIST_VIEW:
      return {
        ...state,
        productListView: action?.payload,
      };

    case UPDATE_CURRENCY:
      return {
        ...state,
        stripe: {
          ...state?.stripe,
          stripeCurrency: action?.payload,
        },
      };

    case DISCONNECT_ACCOUNT_STRIPE:
      return {
        ...state,
        stripe: { isStripeConnected: false, stripeAccountStatus: '', stripeAccountId: null, stripeCurrency: '' },
      };

    default:
      return state;
  }
};

export default reducer;
