import { MIXPANEL_INTERNAL_IDENTIFIER, MIXPANEL_TOKEN, SET_MIXPANEL_ATTRIBUTES } from '../actions/actionTypes';

const initialState = {
  mixpanelToken: null,
  internalIdentifier: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case MIXPANEL_TOKEN:
      return { ...state, mixpanelToken: action.payload };
    case MIXPANEL_INTERNAL_IDENTIFIER:
      return { ...state, internalIdentifier: action.payload };
    case SET_MIXPANEL_ATTRIBUTES:
      return { ...state, ...action.payload};
    default:
      return { ...state };
  }
};

export default reducer;
