export const communityPermissionModal = (permissions) => {
  const permissionsConfig = {};
  permissions.forEach((permission) => {
    permissionsConfig[permission] = true;
  });

  const communityConfigs = {
    canManageRoles: !!permissionsConfig.MANAGE_ROLES,
    canManageCommunity: !!permissionsConfig.MANAGE_COMMUNITY,
    canInviteUsers: !!permissionsConfig.INVITE_USERS,
    canManageMembers: !!permissionsConfig.MANAGE_MEMBERS,
  };

  return communityConfigs;
};
