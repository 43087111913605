import { createSelector } from 'reselect';

const getPrivateChat = (state) => state?.privateChat;

export const getActivePrivateRoom = createSelector(
  [getPrivateChat],
  (privateChat) => privateChat?.privateTextRooms
    ?.find((data) => data?.id === privateChat?.activePrivateRoom),
);

export const getActivePrivateRoomId = createSelector(
  [getPrivateChat],
  (privateChat) => privateChat?.activePrivateRoom,
);
