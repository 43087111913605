import { forEach } from 'lodash';

export const areUsersEqual = (users, nextUsers) => {
  let isEqual = true;
  if (users.length !== nextUsers.length) {
    isEqual = false;
  }
  forEach(users, (user, index) => {
    if (
      user?.host !== nextUsers[index]?.host ||
      user?.speaker !== nextUsers[index]?.speaker ||
      user.uid !== nextUsers[index].uid ||
      user.ss_uid !== nextUsers[index].ss_uid ||
      user.mute !== nextUsers[index].mute ||
      user.video !== nextUsers[index].video
    ) {
      isEqual = false;
    }
  });
  return isEqual;
};
export const areRemoteUsersEqual = (users, nextUsers) => {
  let isEqual = true;
  if (users.length !== nextUsers.length) {
    isEqual = false;
  }
  forEach(users, (user, index) => {
    if (user?.videoTrack !== nextUsers[index]?.videoTrack) {
      isEqual = false;
    }
  });
  return isEqual;
};

export const userUpdateDebounce = (callback, count, modVar, delay) => {
  let timeOutVar;
  return () => {
    if (count === 1) {
      callback();
      return;
    }
    if (timeOutVar && count % modVar !== 0) {
      clearTimeout(timeOutVar);
    }
    timeOutVar = setTimeout(() => {
      callback();
    }, delay);
  };
};

export const isMuteBtnEnabled = ({ granted, isHost, channel }) => {
  return (channel && channel.room_type === 'general') || isHost || granted;
};
