// /* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable prefer-destructuring */
// /* eslint-disable import/no-cycle */
/* eslint-disable no-prototype-builtins */

import _ from 'lodash';
import server from '../../api/server';
import { captureException, LOGGER_JAVASCRIPT } from '../../services/SentryService';
import NotificationService from '../../services/notificationService';
import { Presence } from '../../sockets/phoenix';
import { ROOM_TYPES } from '../../constants';
import { USERTYPES } from '../../constants/users';

import AudioChannelService from '../../services/AudioChannelService';
import AnalyticsService from '../../services/AnalyticsService';
import { areUsersEqual } from '../../constants/userUtils';

import {
  AUDIO_CONNECTION_SUCCESS,
  DEMOTED_TO_LISTENER,
  RESET_AUDIO_STATE,
  SET_IS_ACCEPT_MODAL_OPEN,
  SET_IS_GRANTED_STATUS,
  SET_IS_LEAVE_MODAL_OPEN,
  SET_IS_USER_HOST,
  SET_SHOW_SHARE_TOOLTIP,
  UPDATE_AUDIO_STATE,
  UPDATE_MUTED_IDS,
  UPDATE_ONLINE_USER,
  UPDATE_SPEAKER_REQUESTS,
  RESET_AUDIO_STATE_WITHOUT_MUTE,
} from './actionTypes';
import { setVoiceRoomToken } from './authActions';
import {
  addBlockedUserIds, closeChannelConnection, removeBlockedUserIds, updateTextState,
} from './textActions';
import { getUserIdFromAuthCookie } from '../../constants/authUtils';

let AgoraRTC = null;
(async () => {
  AgoraRTC = (await import('agora-rtc-sdk-ng')).default;
})();

const timeoutVar = {};

let count = 0;
let presences = {};

export const updateMutedIds = (mutedIdMap) => ({
  type: UPDATE_MUTED_IDS,
  payload: { mutedIdMap },
});

export const audioConnectionSuccess = (data) => ({
  type: AUDIO_CONNECTION_SUCCESS,
  payload: data,
});

export const setIsLeaveModalOpen = (value) => ({
  type: SET_IS_LEAVE_MODAL_OPEN,
  payload: { value },
});

export const setIsAcceptModalOpen = (value) => ({
  type: SET_IS_ACCEPT_MODAL_OPEN,
  payload: { value },
});

export const setIsUserHost = (value) => ({
  type: SET_IS_USER_HOST,
  payload: { value },
});

export const setShowShareTooltip = (value) => ({
  type: SET_SHOW_SHARE_TOOLTIP,
  payload: { value },
});

export const setIsGrantedStatus = (value) => ({
  type: SET_IS_GRANTED_STATUS,
  payload: { value },
});

export const onDemotedToListener = () => ({
  type: DEMOTED_TO_LISTENER,
});

export const resetAudioState = (persistAudioState) => ({
  type: persistAudioState === false ? RESET_AUDIO_STATE : RESET_AUDIO_STATE_WITHOUT_MUTE,
});

export const updateAudioState = (data) => ({
  type: UPDATE_AUDIO_STATE,
  payload: data,
});
const checkIfUsersTypeUnequal = (prevUserList, nextUserList) => prevUserList.length !== nextUserList.length || !areUsersEqual(prevUserList, nextUserList);

const updateOnlineUsers = (userType, userList) => ({
  type: UPDATE_ONLINE_USER,
  payload: {
    [userType]: userList,
  },
});

export const toggleMuteStatus = (options) => async (dispatch, getState) => {
  const {
    audio,
    text: { channelData },
  } = getState();

  const { audioManager, mute } = audio;

  if (options?.hasOwnProperty('muteState')) {
    dispatch(updateAudioState({ mute: options?.muteState }));
    await audioManager.muteAudio(!options?.muteState);
    return;
  }

  if (mute) {
    dispatch(updateAudioState({ mute: false }));
    await audioManager.muteAudio(true);
  } else {
    dispatch(updateAudioState({ mute: true }));
    await audioManager.muteAudio(false);
  }
};

const updateUserState = (userType, userList, updateCount, modVar = 10, delay = 500) => (dispatch) => {
  try {
    if (updateCount === 1) {
      dispatch(updateOnlineUsers(userType, userList));
      return;
    }
    if (timeoutVar[userType] && updateCount % modVar !== 0) {
      clearTimeout(timeoutVar[userType]);
    }
    timeoutVar[userType] = setTimeout(() => {
      dispatch(updateOnlineUsers(userType, userList));
    }, delay);
  } catch (error) {
    console.error('UPDATE USERS ERROR', error);
    captureException(`AudioAction UPDATE USERS Error: ${error}`, LOGGER_JAVASCRIPT);
  }
};

const onPresenceSync = (data, currentUser) => (dispatch, getState) => {
  try {
    const { audio, text, user } = getState();
    const typingList = [];
    const { audioManager } = audio;

    const hosts = [];
    const videoUsers = [];
    let hasVideoUsers = false;
    const listeners = [];
    const speakers = [];

    const userUUID = Number(currentUser);

    let hasUsers = false;
    // Presence.list(data, (id, { metas: [first] }) => {
    Presence.list(data, (id, userList) => {
      const { metas } = userList;
      if (metas.length === 1) {
        const {
          metas: [first],
        } = userList;

        const isCurrentUser = metas.find((element) => element.id === user.id);

        if (isCurrentUser !== null && isCurrentUser !== undefined && !_.isEmpty(isCurrentUser)) {
          dispatch(updateAudioState({ hasMultiUserError: false }));
        }

        if (first.typing && first.id !== userUUID) {
          typingList.push(first.name);
        }

        if (first.video) {
          hasVideoUsers = true;
        }
        if (first.video && !(first.id === userUUID)) {
          videoUsers.push(first);
        }
        if (first.host === true) {
          hosts.push(first);
          if (first.id === userUUID && !audio.isUserHost) {
            dispatch(setIsUserHost(true));
          }
        } else if (first.speaker === true) {
          speakers.push(first);
        } else {
          listeners.push(first);
        }
        hasUsers = true;
      } else if (metas.length > 1) {
        const isCurrentUser = metas.find((element) => element.id === user.id);

        if (isCurrentUser !== null && isCurrentUser !== undefined && !_.isEmpty(isCurrentUser)) {
          dispatch(updateAudioState({ hasMultiUserError: true }));
        }
        const arrFirst = metas.filter(
          (element) => element.uid !== null
            && element.uid !== undefined
            && element.ss_uid !== null
            && element.ss_uid !== undefined,
        );
        if (arrFirst.length > 0) {
          const firstObj = arrFirst[0];
          if (firstObj.typing && firstObj.id !== userUUID) {
            typingList.push(firstObj.name);
          }

          if (firstObj.video) {
            hasVideoUsers = true;
          }
          if (firstObj.video && !(firstObj.id === userUUID)) {
            videoUsers.push(firstObj);
          }
          if (firstObj.host === true) {
            hosts.push(firstObj);
            if (firstObj.id === userUUID && !audio.isUserHost) {
              dispatch(setIsUserHost(true));
            }
          } else if (firstObj.speaker === true) {
            speakers.push(firstObj);
          } else {
            listeners.push(firstObj);
          }
          hasUsers = true;
          return;
        }

        const arrSecond = metas.filter((element) => element.uid !== null);

        if (arrSecond.length > 0) {
          const firstObj = arrSecond[0];
          if (firstObj.typing && firstObj.id !== userUUID) {
            typingList.push(firstObj.name);
          }

          if (firstObj.video) {
            hasVideoUsers = true;
          }
          if (firstObj.video && !(firstObj.id === userUUID)) {
            videoUsers.push(firstObj);
          }
          if (firstObj.host === true) {
            hosts.push(firstObj);
            if (firstObj.id === userUUID && !audio.isUserHost) {
              dispatch(setIsUserHost(true));
            }
          } else if (firstObj.speaker === true) {
            speakers.push(firstObj);
          } else {
            listeners.push(firstObj);
          }
          hasUsers = true;
          return;
        }

        const arrThird = metas.filter((element) => element.uid === null || element.uid === undefined);
        if (arrThird.length > 0) {
          const firstObj = arrThird[0];
          if (firstObj.typing && firstObj.id !== userUUID) {
            typingList.push(firstObj.name);
          }

          if (firstObj.video) {
            hasVideoUsers = true;
          }
          if (firstObj.video && !(firstObj.id === userUUID)) {
            videoUsers.push(firstObj);
          }
          if (firstObj.host === true) {
            hosts.push(firstObj);
            if (firstObj.id === userUUID && !audio.isUserHost) {
              dispatch(setIsUserHost(true));
            }
          } else if (firstObj.speaker === true) {
            speakers.push(firstObj);
          } else {
            listeners.push(firstObj);
          }
          hasUsers = true;
        }
      }
    });

    count += 1;

    if (checkIfUsersTypeUnequal(audio.hosts, hosts)) {
      dispatch(updateUserState(USERTYPES.HOST, hosts, count));
    }

    if (checkIfUsersTypeUnequal(audio.speakers, speakers)) {
      dispatch(updateUserState(USERTYPES.SPEAKER, speakers, count));
    }

    if (checkIfUsersTypeUnequal(audio.listeners, listeners)) {
      dispatch(
        updateUserState(
          USERTYPES.LISTENER,
          listeners,
          count,
          text.roomType === ROOM_TYPES.OVERFLOW ? 30 : 20,
          text.roomType === ROOM_TYPES.OVERFLOW ? 2000 : listeners.length > 600 ? 1500 : 500,
        ),
      );
    }
    if (checkIfUsersTypeUnequal(audio.videoUsers, videoUsers)) {
      dispatch(updateAudioState({ [USERTYPES.VIDEO]: videoUsers }));
    }

    if (audio.hasUsers !== hasUsers) dispatch(updateAudioState({ hasUsers }));
    if (audioManager) {
      if (hasVideoUsers) {
        audioManager.enableVideo();
      } else {
        audioManager.disableVideo();
      }
    }
  } catch (error) {
    console.error('onPresenceSync', { error });
    captureException(`AudioAction onPresenceSync Error: ${error}`, LOGGER_JAVASCRIPT);
  }
};

export const updateSpeakerRequests = (speakerRequests) => ({
  type: UPDATE_SPEAKER_REQUESTS,
  payload: { speakerRequests },
});

export const deleteSpeakerRequest = (id) => (dispatch, getState) => {
  const {
    audio: { speakerRequests },
  } = getState();
  const updatedRequests = speakerRequests.filter((item) => item.id !== id);
  dispatch(updateSpeakerRequests(updatedRequests));
};

export const checkIfHost = (channel, userId) => {
  if (channel && channel.room_type === 'general') {
    return true;
  }
  if (channel.hosts && channel.hosts.length > 0) {
    const indexOfhost = channel.hosts.findIndex((host) => host.id === userId);
    if (indexOfhost > -1) return true;
  }
  return false;
};

const onVoiceRequest = (data) => (dispatch, getState) => {
  const {
    text: { channelData },
    audio: { speakerRequests },
  } = getState();

  if (channelData?.channel?.id === data.speaker_request.channel_id) {
    const index = speakerRequests.findIndex((item) => item.user_id === data.speaker_request.user_id);

    if (index > 0) return;
    const updatedArray = [...speakerRequests, data.speaker_request].reduce((finalArray, item) => {
      if (finalArray.findIndex((arr) => arr.user_id === item.user_id) === -1) {
        finalArray.push(item);
      }
      return finalArray;
    }, []);

    dispatch(updateSpeakerRequests(updatedArray));
  }
};

const userKickedOutOfTheRoom = (data) => (dispatch, getState) => {
  const { auth, text } = getState();
  const channelId = text?.channelData?.channel?.id;

  const {
    channel_id,
    channel_kicked_user: { user_id },
  } = data;

  const userId = getUserIdFromAuthCookie();
  if (parseInt(userId) === user_id && channelId === channel_id) {
    NotificationService.error("You've been kicked out of this room!");
    // TODO: ADD ON CONN CLOSE
    dispatch(
      closeChannelConnection(() => {
        dispatch(updateTextState({ redirect: true }));
      }, true),
    );
    // dispatch(closeChannelConnection(null, true));
    // dispatch(updateTextState({ redirect: true }));
  }
};

const voiceRequestUpdated = (data) => (dispatch, getState) => {
  const {
    text: { channelData },
  } = getState();

  const {
    speaker_request: { id, accepted, channel_id },
  } = data;
  if (accepted && channelData?.channel?.id === channel_id) {
    dispatch(deleteSpeakerRequest(id));
  }
};

const demoteToListener = (data) => async (dispatch, getState) => {
  const { audioManager } = getState().audio;
  const { speaker, agora_token, speaker_request } = data;

  await audioManager.renewAgoraToken(agora_token, speaker_request?.channel_id);
  NotificationService.success('You are demoted to listener');

  dispatch(setVoiceRoomToken(null));
  dispatch(onDemotedToListener());

  if (speaker) {
    await audioManager.switchClientRole('broadcaster');
  } else {
    await audioManager.switchClientRole('audience');
    audioManager.unPublishAudio();
    audioManager.unPublishVideo();
    audioManager.leaveShareChannel();
  }
};

const updateVoiceStats = (userType, users, data) => (dispatch) => {
  const userList = [...users];
  const index = userList.findIndex((item) => item.id === data.user.id);
  if (index === -1) return;
  userList[index].speaker = false;
  if (userType === USERTYPES.LISTENER) {
    dispatch(updateOnlineUsers(userType, userList));
  }
};

const voiceDemoted = (data) => (dispatch, getState) => {
  const { channel, audio } = getState();
  const channelId = channel?.channel?.id;

  if (channelId === data.channel_id) {
    const { hosts, speakers, listeners } = audio;

    updateVoiceStats(USERTYPES.HOST, hosts, data);
    updateVoiceStats(USERTYPES.LISTENER, listeners, data);
    updateVoiceStats(USERTYPES.SPEAKER, speakers, data);
  }
};

const updateMuteStats = (data) => (dispatch, getState) => {
  const { mutedId, mutedState } = data;
  const {
    audio: { mutedIdMap },
  } = getState();
  const updatedMutedIds = { ...mutedIdMap };

  if (mutedIdMap[mutedId] !== mutedState) {
    updatedMutedIds[mutedId] = mutedState;
    dispatch(updateAudioState({ mutedIdMap: updatedMutedIds }));
  }
};

const audioServiceCallBack = (data) => (dispatch) => {
  const { speakers } = data;
  if (speakers && speakers.length === 0) {
    return;
  }

  if (data.mutedId) {
    dispatch(updateMuteStats(data));
  }
};

export const setUserMutedInRoom = () => (dispatch, getState) => {
  const {
    text: { channelConfigs },
  } = getState();

  dispatch(toggleMuteStatus({ muteState: true }));
  const updatedChannelConfig = { ...channelConfigs, canSendMessage: false };
  dispatch(updateTextState({ channelConfigs: updatedChannelConfig }));
};

const userMutedInTheRoom = (data) => (dispatch, getState) => {
  const {
    auth,
    text: {
      channelData: {
        channel: { id: activeChannelId },
      },
    },
  } = getState();

  const {
    channel_id,
    channel_muted_user: { user_id },
  } = data;
  const userId = getUserIdFromAuthCookie();

  if (parseInt(userId) === user_id && activeChannelId === channel_id) {
    NotificationService.error("You've been disabled from sending messages in this room!");

    dispatch(setUserMutedInRoom());
  }
};

const handleBlockUser = (response) => (dispatch) => {
  // TODO ADD TEXTACTIONTYPE
  if (response.user_id) {
    dispatch(addBlockedUserIds([response.user_id]));
  }
};

export const handleUnblockUser = (response) => (dispatch) => {
  if (response.user_id) dispatch(removeBlockedUserIds(response.user_id));
};

export const openAcceptModal = (data) => async (dispatch, getState) => {
  const {
    auth,
    text: { channelData },
    audio: { audioManager },
  } = getState();
  const userId = getUserIdFromAuthCookie();

  const {
    speaker,
    speaker_request: { id, user_id, channel_id },
  } = data;

  if (parseInt(userId) === user_id && channelData?.channel?.id === channel_id) {
    if (speaker) {
      dispatch(
        updateAudioState({
          speakerAcceptModal: true,
          speaker_request_id: id,
        }),
      );
    } else {
      audioManager.switchClientRole('audience');
    }
  }
};

export const foregroundAudioListener = (event) => (dispatch, getState) => {
  const {
    text: { channelData, isMinimised },
  } = getState();
  const channelId = channelData?.channel?.id;
  switch (event.actionLabel) {
    case `${channelId}-Leave`:
      if (isMinimised) {
        dispatch(closeChannelConnection(null, true));
      } else {
        dispatch(updateAudioState({ leaveModal: true }));
      }
      break;
    case `${channelId}-Mute`:
      dispatch(toggleMuteStatus());
      break;
    default:
      break;
  }
};

// VIDEO EVENTS

export const toggleUserVideo = () => async (dispatch, getState) => {
  try {
    const {
      text: { textChannel },
      audio: { audioManager, videoUsers },
    } = getState();

    textChannel
      .push('video:toggle')
      .receive('ok', async (data) => {
        if (data.video === true) {
          await audioManager.toggleVideo(data.video);
        } else {
          audioManager.unPublishVideo();
        }
        dispatch(updateAudioState({ isUserVideoActive: data.video }));
      })
      .receive('error', (reasons) => {
        if (videoUsers.length === 4) {
          NotificationService.error('Max users video limit is 4');
        } else {
          NotificationService.error('Cannot turn on Video');
        }
        console.log({ reasons });
      });
  } catch (error) {
    console.error({ error });
    captureException(`AudioAction toggleUserVideo Error: ${error}`, LOGGER_JAVASCRIPT);
  }
};

export const toggleUserShareScreen = (screenTrack) => async (dispatch, getState) => {
  try {
    const {
      text: { textChannel },
      audio: { audioManager, videoUsers, screenVideoTrack },
    } = getState();

    //   textChannel
    //     .push(`video:toggle`)
    //     .receive('ok', async (data) => {
    // dispatch(updateAudioState({ isUserVideoActive: data.video }));
    await audioManager.toggleScreenShare(screenTrack, !(screenVideoTrack !== undefined && screenVideoTrack !== null));
    // })
    // .receive('error', (reasons) => {
    //   if (videoUsers.length === 2) {
    //     NotificationService.error('Max users video limit is 2');
    //   } else {
    //     NotificationService.error('Cannot turn on Video');
    //   }
    //   console.log({ reasons });
    // });
  } catch (error) {
    captureException(`AudioAction toggleUserShareScreen Error: ${error}`, LOGGER_JAVASCRIPT);
    console.error({ error });
  }
};

export const enableVideoMode = (enable) => async (dispatch, getState) => {
  try {
    const {
      text: { textChannel },
      audio: { isUserVideoActive, audioManager },
    } = getState();

    // if (!enable && isUserVideoActive) {
    //   dispatch(toggleUserVideo());
    // }

    textChannel
      .push('video:enable', { enable })
      .receive('ok', async (data) => {
        console.error('here call');

        if (data.video === false) {
          audioManager.unPublishVideo();
        }
        // if (data.video) {
        //   await audioManager.enableVideo();
        // } else {
        //   await audioManager.disableVideo();
        // }
        dispatch(
          updateAudioState({
            isVideoActive: data.video,
            isUserVideoActive: false,
          }),
        );
      })
      .receive('error', (reasons) => console.error('ERROR', { reasons }));
  } catch (error) {
    captureException(`AudioAction enableVideoMode Error: ${error}`, LOGGER_JAVASCRIPT);
    console.error({ error });
  }
};

export const enableSlowMode = (enable) => async (dispatch, getState) => {
  try {
    const {
      text: { textChannel },
    } = getState();

    textChannel
      .push('slow_mode:enable', { enable })
      .receive('ok', async (data) => {
        dispatch(
          updateAudioState({
            isSlowMode: data.slow_mode,
          }),
        );
      })
      .receive('error', (reasons) => console.error('ERROR', { reasons }));
  } catch (error) {
    captureException(`AudioAction enableSlowMode Error: ${error}`, LOGGER_JAVASCRIPT);
    console.error({ error });
  }
};

export const enableText = (enable) => async (dispatch, getState) => {
  try {
    const {
      text: { textChannel },
    } = getState();

    textChannel
      .push('text:enable', { enable })
      .receive('ok', async (data) => {
        dispatch(
          updateAudioState({
            isTextEnabled: data.text,
          }),
        );
      })
      .receive('error', (reasons) => console.error('ERROR', { reasons }));
  } catch (error) {
    captureException(`AudioAction enableText Error: ${error}`, LOGGER_JAVASCRIPT);
    console.error({ error });
  }
};

export const initialiseVoice = (textChannel, { channelId = null, channelData }) => async (dispatch, getState) => {
  try {
    if (!channelId || !channelData) {
      return;
    }
    const {
      socket,
      meta,
      event,
      audio,
      community: { currentCommunity },
    } = getState();
    presences = {};
    if (Object.prototype.hasOwnProperty.call(channelData?.channel, 'settings')) {
      const isChannelVideoActive = channelData?.channel?.settings.video;
      const isChannelSlowModeActive = channelData?.channel?.settings.slow_mode;
      const isChannelTextActive = channelData?.channel?.settings.text;
      dispatch(
        updateAudioState({
          isSlowMode: isChannelSlowModeActive,
          isVideoActive: isChannelVideoActive,
          isTextEnabled: isChannelTextActive,
        }),
      );
    }

    const isHost = channelData.host;
    let isEventPresent;
    const currentChannelEvent = {};
    if (channelData.speaker_requests && channelData.speaker_requests.length > 0) {
      dispatch(
        updateSpeakerRequests(
          channelData.speaker_requests.reduce((a, c) => {
            if (a.findIndex((item) => item.user_id === c.user_id) === -1) {
              a.push(c);
            }
            return a;
          }, []),
        ),
      );
    }
    // TODO CHANNEL   not able to find event
    // if (isHost) {
    //   isEventPresent = !isEmpty(event.hostEvent, true);
    //   if (isEventPresent) {
    //     currentChannelEvent = event.hostEvent;
    //   }
    // } else {
    //   isEventPresent = channelData?.events?.length > 0;
    //   if (isEventPresent) {
    //     currentChannelEvent = channelData.events[0];
    //   }
    // }

    // let currentUser = {};
    // if (meta && meta[`/sessionUser`]) {
    //   if (meta['/sessionUser'].data[0]) {
    //     currentUser = build(getState(), 'user', meta['/sessionUser'].data[0].id);
    //   }
    // }

    const currentUser = getState().user;

    const { userChannel } = socket;

    const { channel, room_type } = channelData;
    const channelName = channel?.name;

    count = 0;

    const presence = new Presence(textChannel);

    textChannel.on('presence_state', (state) => {
      presences = Presence.syncState(presences, state);
      dispatch(onPresenceSync(presences, currentUser.id));
    });

    textChannel.on('presence_diff', (diff) => {
      presences = Presence.syncDiff(presences, diff);
      dispatch(onPresenceSync(presences, currentUser.id));
    });

    if (userChannel) {
      if (room_type === ROOM_TYPES.MAIN) {
        userChannel.on('voice:requested', (data) => dispatch(onVoiceRequest(data)));
        userChannel.on('voice:request:updated', (data) => dispatch(voiceRequestUpdated(data)));
        userChannel.on('voice:demoted', (data) => dispatch(demoteToListener(data)));
        userChannel.on('voice:shifted:listener', (data) => dispatch(voiceDemoted(data)));
        userChannel.on('voice:user:demoted', (data) => dispatch(voiceDemoted(data)));
      }
      userChannel.on('user:kicked', (data) => dispatch(userKickedOutOfTheRoom(data)));
      userChannel.on('user:muted', (data) => dispatch(userMutedInTheRoom(data)));
      userChannel.on('user:blocked', (data) => dispatch(handleBlockUser(data)));
      userChannel.on('user:unblocked', (data) => dispatch(handleUnblockUser(data)));
    }

    const engine = await AgoraRTC.createClient({
      mode: newFunction(),
      codec: 'vp8',
    });

    // let audioTrack = null;

    // try {
    //   audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
    //   dispatch(updateAudioState({ audioTrack: audioTrack, microphoneAllowed: true }));
    // } catch (error) {
    //   if (error.code === 'PERMISSION_DENIED') {
    //     dispatch(updateAudioState({ microphoneAllowed: false }));
    //   }
    // }

    const data = await server.get(`/channels/${channelId}/join`, {
      params: {
        room_token: channelData?.room_token,
      },
    });
    const audioManager = new AudioChannelService({
      // audioTrack: audioTrack,
      engine,
      token: data.data.token,
      user: data.data.user,
      channelId,
      host: isHost,
      textChannel,
    });

    dispatch(
      audioConnectionSuccess({
        audioManager,
        presence,
      }),
    );

    audioManager.initialization((audioData) => dispatch(audioServiceCallBack(audioData)));
    audioManager.registerUser();
    audioManager.joinChannel(channelId);

    textChannel.on('stage:setting', (stageSetting) => {
      const { audio } = getState();
      if (Object.prototype.hasOwnProperty.call(stageSetting, 'video')) {
        if (!stageSetting.video && audio.isUserVideoActive) {
          dispatch(toggleUserVideo());
        }
        audioManager.unPublishVideo();
        // dispatch(updateAudioState({ isVideoActive: stageSetting.video, isUserVideoActive: false }));

        dispatch(updateAudioState({ isVideoActive: stageSetting.video }));
        // if (stageSetting.video) {
        //   audioManager.enableVideo();
        // } else {
        //   audioManager.disableVideo();
        // }
      }
      if (Object.prototype.hasOwnProperty.call(stageSetting, 'slow_mode')) {
        dispatch(updateAudioState({ isSlowMode: stageSetting.slow_mode }));
      }
      if (Object.prototype.hasOwnProperty.call(stageSetting, 'text')) {
        dispatch(updateAudioState({ isTextEnabled: stageSetting.text }));
      }
    });

    if (userChannel) {
      userChannel.on('voice:request:accepted', (aceeptedData) => {
        dispatch(openAcceptModal(aceeptedData));
      });
    }
  } catch (error) {
    captureException(`AudioAction initialiseVoice Error: ${error}`, LOGGER_JAVASCRIPT);
    console.error(error);
  }

  function newFunction() {
    return 'live';
  }
};
