import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Dialog, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { XIcon, InformationCircleIcon } from '@heroicons/react/outline';
// import moment from 'moment';
import dayjs from 'dayjs';
import { getActiveCommunity, getActiveCommunityFontsClass } from '../../../selectors/CommunitySelectors';
import { imageFetch } from '../../../services/preSignedAws';
import CoinbgIcon from '../../../assets/images/coin-system/coinbg.png';
import { getUtcTime } from '../../../helpers';
import { getWalletWithID } from '../../../services/CoinSystemServices';
import SectionHeader from './SectionHeader';
import SectionRow from './SectionRow';
import { classNames, parseAxiosErrorMessage } from '../../../constants/utils';
import NotificationService from '../../../services/notificationService';

export default function MyWallet({ openMyWallet = false, setOpenMyWallet }) {
  const coinCount = useSelector((state) => state?.channel?.coinsCount);
  const activeCommunity = useSelector(getActiveCommunity);
  const { primaryFont } = useSelector(getActiveCommunityFontsClass);

  const [isLoadingMore, setLoadingMore] = useState(false);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [linkData, setLinkData] = useState(undefined);
  const [sectionData, setSectionData] = useState([]);
  //   const [dataLength, setDataLength] = useState(0);

  useEffect(() => {
    if (activeCommunity?.coin_enable) {
      fetchWalletLog();
    }
    return () => {
      setPage(1);
    };
  }, []);

  const getDate = (time) => {
    const dt = getUtcTime(time);
    return dayjs(dt, 'YYYY-MM-DD').format('YYYY-MM-DD');
  };

  const fetchWalletLog = async (pageIndex) => {
    try {
      const response = await getWalletWithID(activeCommunity.id, pageIndex);
      const { status, data } = response;
      if (status >= 200 && status < 300) {
        const { coin_logs } = data;
        setLinkData(coin_logs?.links);
        const arrSection = [];
        const coinList = coin_logs?.data;
        if (coinList && coinList.length > 0) {
          coinList.map((coin) => {
            const dateTime = getDate(coin?.attributes?.created_at);
            const alreadyHas = arrSection.find((item) => item.title === dateTime);
            if (alreadyHas !== undefined && alreadyHas !== null && !_.isEmpty(alreadyHas)) {
              const index = arrSection.findIndex((element) => element.title === alreadyHas.title);
              if (index !== -1) {
                alreadyHas.data.push(coin);
                arrSection[index] = alreadyHas;
              }
            } else {
              const obj = { title: dateTime, data: [coin] };
              arrSection.push(obj);
            }
          });
          //   setDataLength((state) => parseInt(state) + parseInt(coinList.length));
          setSectionData([...sectionData, ...arrSection]);
        }
      }
      setLoadingMore(false);
      setIsLoading(false);
    } catch (error) {
      const err = error?.response?.data?.error;
      setIsLoading(false);
      setLoadingMore(false);
      NotificationService.error(parseAxiosErrorMessage(error));
    }
  };

  const loadMore = () => {
    setLoadingMore(true);
    const pageIndex = page + 1;
    setPage(pageIndex);
    fetchWalletLog(pageIndex);
  };

  const renderFooter = () => {
    if (!linkData) return null;
    const { pages } = linkData;
    if (!isLoading && page === pages) return null;

    return (
      <div className="flex justify-center mb-4">
        {isLoadingMore ? (
          <div
            className="
                      animate-spin
                      rounded-full
                      h-4
                      w-4
                      ltr:mr-2 rtl:ml-2
                      border-t-2 border-b-2 border-white
                  "
          />
        ) : (
          <button
            type="button"
            className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-0 focus:ring-offset-0"
            onClick={loadMore}
          >
            load more
          </button>
        )}
      </div>
    );
  };
  return (
    <div className=" ">
      <Transition show={openMyWallet} as={Fragment}>
        <Dialog
          as="div"
          className="bg-gray-500 bg-opacity-70 flex flex-1 fixed inset-0 z-50 overflow-hidden"
          onClose={setOpenMyWallet}
        >
          <div className="absolute inset-0 overflow-hidden">
            <div className="absolute inset-0" onClick={setOpenMyWallet} />

            <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
              <TransitionChild
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="w-screen max-w-md">
                  <div
                    id="scroll-content"
                    className="h-full flex flex-col py-6 sidepanel__container shadow-xl overflow-y-scroll"
                  >
                    <div className="px-4 sm:px-6">
                      <div className="flex items-start justify-between">
                        <DialogTitle className={classNames('text-lg font-medium text__title', primaryFont)}>
                          {$translatei18n('MyInCommunityWallet')}
                        </DialogTitle>
                        <div className="ml-3 h-7 flex items-center">
                          <button
                            type="button"
                            className="rounded-md focus:outline-none focus:ring-0 focus:ring-offset-0"
                            onClick={() => setOpenMyWallet(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6 main__icon" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="mt-6 relative flex-1 px-4 sm:px-6">
                      <div className="absolute inset-0 px-4 sm:px-6">
                        <div className="flex flex-col">
                          <div
                            className="h-24 w-full flex rounded-lg relative overflow-hidden btn__primary"
                            style={{ backgroundColor: activeCommunity?.accent_color }}
                          >
                            <div className="relative h-28 w-28 -top-2 -left-7">
                              <img src={CoinbgIcon} className="h-28 w-28" />
                              <img
                                src={imageFetch(activeCommunity?.coin_icon, {}, false)}
                                className="absolute h-16 w-16 inset-0 m-auto rounded-full opacity-50"
                              />
                            </div>
                            <div className="flex flex-col justify-center">
                              <div className="text-xs flex my-2 items-center">
                                <span>{$translatei18n('MyBalance')}</span>
                                <InformationCircleIcon className="h-3 w-3 ltr:ml-2 rtl:mr-2" />
                              </div>
                              <div>
                                <span className="text-5xl">{coinCount}</span>
                                <span className="text-2xl ltr:ml-2 rtl:mr-2">{activeCommunity?.coin_abbreviation}</span>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className={classNames('text-base mt-7 mb-5 text__title', primaryFont)}>
                              {$translatei18n('TransactionHistory')}
                            </div>
                            <div>
                              {sectionData.map((section) => (
                                <div key={section?.title}>
                                  <SectionHeader section={section} />
                                  {section?.data?.map((item) => (
                                    <SectionRow
                                      key={item?.id}
                                      coinAbbreviation={activeCommunity?.coin_abbreviation || ''}
                                      section={section}
                                      user={item?.attributes}
                                    />
                                  ))}
                                </div>
                              ))}
                              {renderFooter()}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
