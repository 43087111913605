import { useState } from 'react';
import { imageFetch } from '../../../services/preSignedAws';
import { userAvtarName } from '../../../helpers';
import CommonNameAvatar, {
  COMMON_NAME_AVATAR_SIZE,
  COMMON_NAME_AVATAR_TYPE,
} from '../../../components/shared/tailwind/NameAvatar';
import { useDispatch } from 'react-redux';
import { history } from '../../../constants/utils';
import { ACTOR_TYPE } from '../../../constants';

function SectionRow({ user, coinAbbreviation }) {
  const dispatch = useDispatch()
  const [isImageLoadingError, setIsImageLoadingError] = useState(false);
  const newName = userAvtarName(`${user?.metadata?.user_full_name}`);
  const getImage = (image) => {
    const images = imageFetch(image, {}, false);
    return images;
  };

  const showUserProfile = () => {
    if(user?.metadata?.actor_type === ACTOR_TYPE.USER) {
      history.push(`/profile?user_id=${user?.metadata?.user_id}`)
    }
  };

  return (
    <div className="flex text-sm space-x-4 items-center justify-between my-4">
      <div className="flex items-center">
        <div className="inline-block relative flex-none cursor-pointer" onClick={showUserProfile}>
          {user?.metadata?.user_image?.length > 0 && !isImageLoadingError ? (
            <img
              src={imageFetch(user?.metadata?.user_image, {}, false)}
              className="h-10 w-10 rounded-lg"
              onError={() => setIsImageLoadingError(true)}
              alt="profile"
            />
          ) : (
            <CommonNameAvatar type={COMMON_NAME_AVATAR_TYPE.ROUND} size={COMMON_NAME_AVATAR_SIZE.MD} name={newName} />
          )}
        </div>
        <div className="text-14 text__body ml-4">{user?.metadata?.user_full_name}</div>
      </div>
      <div className={`${user?.status === 'credit' ? 'text__success' : 'text__danger'}`}>
        {`${user?.status === 'credit' ? '+' : '-'}${user?.metadata?.coins} ${coinAbbreviation || ''}`}
      </div>
    </div>
  );
}

export default SectionRow;
