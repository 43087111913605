const AutomateRolesIcon = ({fill, classNames}) => {
  return (
    <svg width={21} height={21} viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" className={classNames}>
      <circle cx="10.4174" cy="6.3532" r="3.54314" stroke={fill} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M3.74805 16.149V16.149C3.74805 14.0771 5.42768 12.3975 7.49961 12.3975H9.65301"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4582 14.7651L16.8884 14.335C17.2479 15.1782 17.1172 16.1505 16.548 16.8689C15.9787 17.5874 15.062 17.9369 14.1589 17.7797"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6968 15.864L12.2666 16.2942C11.8958 15.4268 12.0445 14.4246 12.6512 13.7023C13.2579 12.98 14.2194 12.6605 15.1378 12.8761"
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AutomateRolesIcon;
