import server from '../api/server';
import serverLess from '../api/serverVersionless';

export const listInbox = (data) => new Promise((resolve, reject) => {
  serverLess
    .get('/inbox', {
      params: {
        next_page_id: data?.nextPageId || '',
      },
    })
    .then((response) => {
      resolve(response.data);
    })
    .catch(reject);
});

export const listRequests = (data) => new Promise((resolve, reject) => {
  serverLess
    .get('/requests', {
      params: {
        next_page_id: data?.nextPageId || '',
      },
    })
    .then((response) => {
      resolve(response.data);
    })
    .catch(reject);
});

export const createPrivateTextRoomService = (data) => new Promise((resolve, reject) => {
  serverLess
    .post('/private_text_rooms', {
      user_id: data.user_id,
    })
    .then((response) => {
      resolve(response.data);
    })
    .catch(reject);
});

export const getFollowedUsers = ({ name, page, per_page }) => new Promise((resolve, reject) => {
  serverLess
    .post('/follows/search', {
      name: name || '',
      page: page || 1,
      per_page: per_page || 10,
      type: 'followees',
    })
    .then((response) => {
      resolve(response.data);
    })
    .catch(reject);
});

export const requestJoinTextRoom = ({ accept, textRoomId }) => new Promise((resolve, reject) => {
  serverLess
    .post(`/private_text_rooms/${textRoomId}/join`, {
      accept,
    })
    .then((response) => {
      resolve(response.data);
    })
    .catch(reject);
});

export const deleteDmChatService = async ({private_text_room_id, community_id}) =>
  await new Promise((resolve, reject) => {
    serverLess
      .get(`/communities/${community_id}/delete_chat?private_text_room_id=${private_text_room_id}`)
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch(reject);
  });