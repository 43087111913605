import { history } from '../constants/utils';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
const REACT_APP_ENV = process.env.REACT_APP_ENV;
const REACT_APP_SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN;
const REACT_APP_WEB_VERSION = process.env.REACT_APP_WEB_VERSION;
export const LOGGER_JAVASCRIPT = 'javascript';

export function setupSentry() {
  
  if (REACT_APP_ENV === 'production' || REACT_APP_ENV === 'ggnation') {
    Sentry.init({
      dsn: REACT_APP_SENTRY_DSN,
      integrations: [new BrowserTracing()],
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 1.0,
    });
    addBuildContext();
  }
}

const addBuildContext = async () => {
  if (REACT_APP_ENV === 'production' || REACT_APP_ENV === 'ggnation') {
    Sentry.setTag('REACT_APP_WEB_VERSION', REACT_APP_WEB_VERSION);
    Sentry.setTag('WEB_DOMAIN', window.location.host);
  }
};

export function addSentryTag(tag, value) {
  if (REACT_APP_ENV === 'production' || REACT_APP_ENV === 'ggnation') {
    Sentry.setTag(tag, value);
  }
}

export function addSentryUser(email) {
  if (REACT_APP_ENV === 'production' || REACT_APP_ENV === 'ggnation') {
    Sentry.setUser({ email: email });
  }
}

export function captureException(error, logger) {
  if (!error || !logger) {
    return;
  }
  if (REACT_APP_ENV === 'production' || REACT_APP_ENV === 'ggnation') {
    Sentry.captureException(error, { logger });
  }
}
