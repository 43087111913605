import React, { useState } from 'react';
import { Initial } from 'react-initial';
import { useDispatch } from 'react-redux';
import { history, prepareUserImageName } from '../../constants/utils';
import { imageFetch } from '../../services/preSignedAws';
import { setUserProfileData } from '../../store/actions/userProfileActions';
import CommonNameAvatar, { COMMON_NAME_AVATAR_SIZE, COMMON_NAME_AVATAR_TYPE } from '../shared/tailwind/NameAvatar';

function UserInfo({ user, closeDrawer }) {
  const dispatch = useDispatch();
  const [isImageLoadingError, setIsImageLoadingError] = useState(false);
  const {
    id, name, first_name, last_name, image,
  } = user;

  function openUserProfileDrawer() {
    history.push(`/profile?user_id=${id}`, { isFromOverview: window.location.pathname === "/" ? true : false });
    closeDrawer();
  }

  return (
    <div key={id} className="flex flex-row items-center w-full cursor-pointer mb-3" onClick={openUserProfileDrawer}>
      {image && !isImageLoadingError ? (
        <img
          src={imageFetch(image)}
          className="h-9 w-9 rounded-full"
          onError={() => setIsImageLoadingError(true)}
          alt="profile"
        />
      ) : (
        <CommonNameAvatar
          name={prepareUserImageName(first_name, last_name)}
          alt="profile"
          type={COMMON_NAME_AVATAR_TYPE.CIRCULAR}
          size={COMMON_NAME_AVATAR_SIZE.MD}
        />
      )}
      <span className="text-sm font-bold ltr:ml-2 rtl:mr-2 text__body">{`${name}`}</span>
    </div>
  );
}

export default UserInfo;
