import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import LoadingSpinner from '../components/shared/tailwind/LoadingSpinner';
import { verifyCommunityMember } from '../store/actions/communityActions';

function CommunityMemberVerification({ children }) {
  const history = useHistory();
  const location = useLocation();

  const isCommunityMemberVerified = useSelector((state) => state?.community?.isCommunityMemberVerified);
  const verifyCommunityMemberLoading = useSelector((state) => state?.community?.verifyCommunityMemberLoading);

  const dispatch = useDispatch();
  useEffect(() => {
    if (isCommunityMemberVerified) {
      return;
    }
    dispatch(verifyCommunityMember());
  }, []);

  if (verifyCommunityMemberLoading) {
    return <LoadingSpinner />;
  }

  if (isCommunityMemberVerified) {
    return children;
  }

  history.replace('/enter-community');
  return null;
}

export default CommunityMemberVerification;
