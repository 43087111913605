import {
  all, fork, put, takeLatest,
} from 'redux-saga/effects';

import {
  CHARGEBEE_PAYMENT_REQUEST,
  CHARGEBEE_PAYMENT_SUCCESS,
  CLEAR_LOCAL_STORAGE,
  CURRENT_SUBSCRIPTION_REQUEST,
  CURRENT_SUBSCRIPTION_SUCCESS,
  PLAN_DETAILS_REQUEST, PLAN_DETAILS_SUCCESS, PLAN_PAYMENT_STATUS_REQUEST, PLAN_PAYMENT_SUCCESS
} from '../store/actions/actionTypes';
import { getCurrentPlanSubscriptionData, getPlanPaymentStatus, getProductData, startChargebeeFlow } from '../services/ProductPlanServices';
import { setSubscriptionDataInStore } from '../components/subscription/subscriptionUtils';
import { chargebee_payment, chargebee_payment_restart, new_price, plan_extended } from '../constants';

function* fetchPlans() {
  yield takeLatest(PLAN_DETAILS_REQUEST, function* ({ payload }) {
    try {
      const response = yield getProductData(payload);

      yield put({
        type: PLAN_DETAILS_SUCCESS,
        payload: {
          data: response || {},
          reset: payload?.reset,
        },
      });
    } catch (error) {
      console.log(error);
    }
  });
}

function* makePayment() {
  yield takeLatest(CHARGEBEE_PAYMENT_REQUEST, function* ({ payload }) {
    try {
      const {response: {hosted_page}} = yield startChargebeeFlow(payload);

      yield put({
        type: CHARGEBEE_PAYMENT_SUCCESS,
        payload: {
          data: hosted_page || {},
          reset: payload?.reset,
        },
      });
    } catch (error) {
      console.log(error);
    }
  });
}

function* fetchPaymentStatus() {
  yield takeLatest(PLAN_PAYMENT_STATUS_REQUEST, function* ({payload}){
    try {
      const {payment_success} = yield getPlanPaymentStatus(payload);
      yield put({
        type: PLAN_PAYMENT_SUCCESS,
        payload: payment_success
      })
    } catch (error) {
      console.log(error)
    }
  })
}

function* getCurrentSubscriptionData() {
  yield takeLatest(CURRENT_SUBSCRIPTION_REQUEST, function* ({payload}){
    try {
      const {community_channel_counts,  community_group_counts, product_subscription, product_subscription_plans, community_members_counts, community_storage_used} = yield getCurrentPlanSubscriptionData(payload);
      yield put({
        type: CURRENT_SUBSCRIPTION_SUCCESS,
        payload: { product_subscription, product_subscription_plans, community_members_counts, community_storage_used}
      })
      const featuresData = {
        community_channel_count : community_channel_counts,
        community_group_count : community_group_counts,
        features : product_subscription?.data?.attributes?.features,
      }
      setSubscriptionDataInStore(featuresData)
    } catch (error) {
      console.log(error)
    }
  })
}

function* resetLocalStorage() {
  yield takeLatest(CLEAR_LOCAL_STORAGE, function* () {
    localStorage.removeItem(plan_extended)
    localStorage.removeItem('priceId')
    localStorage.removeItem(chargebee_payment)
    localStorage.removeItem(chargebee_payment_restart)
    localStorage.removeItem(new_price)
  })
}

export default function* plansSaga() {
  yield all([fork(fetchPlans), fork(makePayment), fork(fetchPaymentStatus), fork(getCurrentSubscriptionData), fork(resetLocalStorage)]);
}
