import { useFormik } from 'formik';
import { classNames } from '../../../constants/utils';
import { getActiveCommunity, getActiveCommunityFontsClass } from '../../../selectors/CommunitySelectors';
import communityInviteSchema from '../../../schema/communityInviteSchema';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { addCommunityInvite } from '../../../services/communityService';
import NotificationService from '../../../services/notificationService';
import { useSelector, useDispatch } from 'react-redux';
import { COMMUNITY_ROLE_COLOR, CREATE_INVITE_EXPIRY, SEND_INVITE_TRIGGERED_FROM } from '../../../constants';
import { InformationCircleIcon, PlusIcon, XIcon } from '@heroicons/react/outline';
import { Dialog, Popover, PopoverButton, PopoverPanel, Transition, TransitionChild } from '@headlessui/react';
import { fetchRoles } from '../../../store/actions/rolesAction';
import moment from 'moment';
import { isEmpty } from 'lodash';
import styles from './styles.module.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const RolesList = ({
  rolesList,
  accentColor,
  selectedRoles,
  handleRoleClick,
  handleAssignSelectedRoles,
  rolesListOpen,
  handleRoleListClose,
}) => {
  const [query, setQuery] = useState('');
  const [roles, setRoles] = useState([]);

  const activeCommunity = useSelector(getActiveCommunity);
  const isMobile = window.innerWidth < 1024;

  useEffect(() => {
    if (isEmpty(rolesList)) return;
    if (query.length) {
      filterRolesArr(query);
      return;
    }
    setRoles(rolesList);
  }, [rolesList, query]);

  const filterRolesArr = (str) => {
    setRoles(rolesList.filter((ele) => ele.role.toLowerCase().includes(str.toLowerCase())));
  };

  return (
    <>
      {isMobile ? (
        <Transition show={rolesListOpen} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 overflow-hidden z-50" onClose={() => {}}>
            <div className="absolute inset-0 overflow-hidden">
              <TransitionChild
                as={Fragment}
                enter="ease-in-out duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div
                  className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                  onClick={handleRoleListClose}
                />
              </TransitionChild>

              <div className="fixed bottom-0 max-w-full flex items-end overflow-y-auto">
                <TransitionChild
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <div className="w-screen main--background relative">
                    <div className="min-h-12 flex justify-center items-center cursor-pointer absolute w-full top-0">
                      <div className="w-6 h-[2px] rounded-sm bg-[#AAB1B7]"></div>
                    </div>
                    <div className="flex items-start justify-between pt-8">
                      <div className="h-full overflow-y-scroll relative w-full">
                        {rolesList.length ? (
                          <>
                            <div className="pt-2 mb-2 mx-3">
                              <input
                                type="text"
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                placeholder={'Select role'}
                                className="shadow-sm block w-full text-sm rounded-md input card"
                              />
                            </div>
                            <div className="min-h-60 max-h-[400px] mb-12">
                              {roles.length ? (
                                roles.map((item) => {
                                  const selected = selectedRoles.filter((role) => role.id === item.id)?.length;
                                  if (item?.role?.toLowerCase() === 'guest') {
                                    return null;
                                  }
                                  return (
                                    <div key={item.id}>
                                      <label
                                        htmlFor={`role_${item.id}`}
                                        className="flex flex-row items-center px-3 py-2 w-full"
                                      >
                                        <input
                                          id={`role_${item.id}`}
                                          checked={selected}
                                          type="checkbox"
                                          className="h-4 w-4 text__link border-gray-300 rounded focus:outline-none focus:ring-0 focus:ring-offset-0"
                                          onChange={() => handleRoleClick(item)}
                                          style={{
                                            color: accentColor,
                                          }}
                                        />
                                        <span
                                          className="ml-2 px-2 py-1 text-sm text-left max-w-[90%] inline-block break-words rounded-[32px]"
                                          style={{
                                            backgroundColor:
                                              item.color ?? COMMUNITY_ROLE_COLOR[index % rolesList.length],
                                          }}
                                        >
                                          {item.role}
                                        </span>
                                      </label>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="text-sm text-gray-400 mx-3 mb-2">{$translatei18n('0ResultsFound')}</div>
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="min-h-60 flex items-center justify-center text-sm text-gray-400 mb-12">
                              {$translatei18n('NoRolesCreated')}
                            </div>
                          </>
                        )}
                        <div className="fixed left-0 right-0 bottom-0 bg-white px-3 py-2 flex justify-between items-center border-t border-gray-300 w-full">
                          <span>{selectedRoles?.length || 0} selected</span>
                          <button
                            className="py-1 px-3 rounded btn__primary"
                            style={{ backgroundColor: activeCommunity?.accent_color }}
                            onClick={handleAssignSelectedRoles}
                            type="button"
                          >
                            {$translatei18n('Add')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </Transition>
      ) : (
        <div className={`max-h-64 overflow-y-scroll ${styles.scrollbar}`}>
          {rolesList.length ? (
            <>
              <div className="pt-2 mb-2 mx-3">
                <input
                  type="text"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder={'Select role'}
                  className="shadow-sm block w-full text-sm rounded-md input card"
                />
              </div>
              <div className="min-h-60">
                {roles.length ? (
                  roles.map((item) => {
                    const selected = selectedRoles.filter((role) => role.id === item.id)?.length;
                    if (item?.role?.toLowerCase() === 'guest') {
                      return null;
                    }
                    return (
                      <div key={item.id} className="flex flex-row items-center px-3 py-2">
                        <input
                          id={`role_${item.id}`}
                          checked={selected}
                          type="checkbox"
                          className="h-4 w-4 text__link border-gray-300 rounded focus:outline-none focus:ring-0 focus:ring-offset-0 cursor-pointer"
                          onChange={() => handleRoleClick(item)}
                          style={{
                            color: accentColor,
                          }}
                        />
                        <label
                          className="w-40 inline-block"
                          htmlFor={`role_${item.id}`}
                          onClick={(e) => e.preventDefault()}
                        >
                          <span
                            className="ml-2 px-2 py-1 text-sm text-left rounded-[32px] pointer-events-none max-w-[100%] inline-block break-words"
                            style={{ backgroundColor: item.color ?? COMMUNITY_ROLE_COLOR[index % rolesList.length] }}
                          >
                            {item.role}
                          </span>
                        </label>
                      </div>
                    );
                  })
                ) : (
                  <div className="text-sm text-gray-400 mx-3 mb-2">{$translatei18n('0ResultsFound')}</div>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="min-h-60 flex items-center justify-center text-sm text-gray-400 -mt-4">
                {$translatei18n('NoRolesCreated')}
              </div>
            </>
          )}
          <div className="sticky bottom-0 bg-white px-3 py-2 flex justify-between items-center border-t border-gray-300">
            <span>
              {selectedRoles?.length || 0} {$translatei18n('selected')}
            </span>
            <button
              className="py-1 px-3 rounded btn__primary"
              style={{ backgroundColor: activeCommunity?.accent_color }}
              onClick={handleAssignSelectedRoles}
              type="button"
            >
              {$translatei18n('Add')}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const CreateInviteContent = ({ handleSendInviteDrawer, refreshList }) => {
  const dispatch = useDispatch();
  const { primaryFont } = useSelector(getActiveCommunityFontsClass);
  const activeCommunity = useSelector(getActiveCommunity);
  const communityRoles = useSelector((state) => state?.roles?.communityRoles);
  const [rolesArr, setRolesArr] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [isSelectedRolesEmpty, setIsSelectedRolesEmpty] = useState(true);
  const [assignedRoles, setAssignedRoles] = useState([]);
  const [rolesListOpen, setRolesListOpen] = useState(false);

  function handleGenerateClick(payload, setSubmitting) {
    const communityRoleIdArr = selectedRoles.map((item) => item.id);
    if (formik?.isValid && communityRoleIdArr.length) {
      payload.community_role_ids = communityRoleIdArr;
      sessionStorage.setItem(SEND_INVITE_TRIGGERED_FROM, 'role_based_invite');
      generateCommunityInvite(payload, setSubmitting);
    } else {
      setIsSelectedRolesEmpty(true);
      setSubmitting(false);
    }
  }

  function handleRoleClick(role) {
    setIsSelectedRolesEmpty(false);
    const roleExist = selectedRoles.find((item) => item.id === role.id);
    if (roleExist) {
      const updatedRolesArr = selectedRoles.filter((item) => item.id !== roleExist.id);
      if (!updatedRolesArr.length) setIsSelectedRolesEmpty(true);
      setSelectedRoles(updatedRolesArr);
      return;
    }
    setSelectedRoles((roles) => [...roles, role]);
  }

  function handleAssignSelectedRoles(closePopover) {
    if (selectedRoles.length > 0) {
      setAssignedRoles([...selectedRoles]);
      closePopover ? closePopover() : setRolesListOpen(false);
    } else {
      NotificationService.error('No roles selected');
    }
  }

  async function generateCommunityInvite(payload, setSubmitting) {
    try {
      await addCommunityInvite(payload).then((response) => {
        const { name, invite_code, link_limit, link_uses, expires_at } = response.data?.data;
        NotificationService.success(name + ' Created!');
        handleSendInviteDrawer(true, {
          name,
          invite_code,
          link_limit,
          expires_at,
          link_uses,
          roles_ids: payload?.community_role_ids,
        });
        if (typeof refreshList === 'function') {
          refreshList();
        }
      });
    } catch (error) {
      if (error.response.status === 422) {
        const { data } = error.response;
        const { errors } = data;
        const message = errors
          ? errors.link_limit
            ? errors.link_limit[0]
              ? errors.link_limit[0]
              : 'Something went wrong'
            : 'Something went wrong'
          : 'Something went wrong';
        NotificationService.error(message);
      } else {
        NotificationService.error('Something went wrong');
      }
    } finally {
      setSubmitting(false);
    }
  }

  const getExpiryTime = useCallback((expiryKey) => {
    let timeNow = moment(new Date());
    switch (expiryKey) {
      case '1_hour':
        timeNow.add(1, 'hour');
        break;
      case '12_hour':
        timeNow.add(12, 'hours');
        break;
      case '1_day':
        timeNow.add(1, 'day');
        break;
      case '1_week':
        timeNow.add(1, 'week');
        break;
      case '1_month':
        timeNow.add(1, 'month');
        break;
      default:
        break;
    }
    return timeNow.format('DD MMM y, h:mma');
  }, []);

  const formik = useFormik({
    initialValues: {
      expires_at: '1_hour',
      name: '',
      link_limit: '',
      allow_self_assign: false,
    },
    validationSchema: communityInviteSchema,
    onSubmit: (payload, { setSubmitting }) => {
      handleGenerateClick(payload, setSubmitting);
    },
  });

  useEffect(() => {
    if (!activeCommunity?.id) return;
    dispatch(fetchRoles({ activeCommunityId: activeCommunity.id }));
  }, [activeCommunity]);

  useEffect(() => {
    if (isEmpty(communityRoles)) return;
    const memberDefault = Object.values(communityRoles).filter((el) => el?.role === 'member');
    if (memberDefault) {
      setSelectedRoles(memberDefault);
      setAssignedRoles(memberDefault);
    }
    setRolesArr(Object.values(communityRoles));
  }, [communityRoles]);

  const handleRoleListClose = () => {
    setSelectedRoles([...assignedRoles]);
    setRolesListOpen(false);
  };

  const linkInputRef = useRef();
  const limitInputRef = useRef();

  useEffect(() => {
    linkInputRef?.current?.blur();
    limitInputRef?.current?.blur();
  }, [])

  return (
    <form className="h-[calc(100%-80px)] flex flex-col shadow-xl overflow-y-scroll" onSubmit={formik.handleSubmit}>
      <div className="flex-1 p-6">
        <div className="mb-4 md:mb-6">
          <label htmlFor="name" className={classNames('block text-sm font-medium text__title', primaryFont)}>
            {$translatei18n('THeadLinkName')}
          </label>
          <div className="mt-2">
            <input
              id="name"
              type="text"
              value={formik.values.name}
              onChange={formik.handleChange}
              className="shadow-s block w-full sm:text-sm input border card rounded-md"
              placeholder="Enter title of the link"
            />
          </div>
          {formik.touched?.name && formik.errors?.name && (
            <div className="float-right text-xs mt-1 text-red-500">{formik.errors.name}</div>
          )}
        </div>
        <div className="mb-4 md:mb-6">
          <label htmlFor="link_limit" className={classNames('block text-sm font-medium text__title', primaryFont)}>
            {$translatei18n('MaxNumberOf')} {$translatei18n('THeadRolesAssigned')}
          </label>
          <div className="mt-2">
            <input
              id="link_limit"
              type="number"
              value={formik.values.link_limit}
              onChange={formik.handleChange}
              className="shadow-s block w-full sm:text-sm input border card rounded-md"
              placeholder="Enter number between 1 - 10,00,000"
            />
          </div>
          {formik.touched?.link_limit && formik.errors?.link_limit && (
            <div className="float-right text-xs mt-1 text-red-500">{formik.errors.link_limit}</div>
          )}
        </div>
        <div className="mb-2 md:mb-4">
          <label htmlFor="expires_at" className={classNames('block text-sm font-medium text__title', primaryFont)}>
            {$translatei18n('ExpiresAfter')}
          </label>
          <div className="mt-2">
            <select
              id="expires_at"
              value={formik.values.expires_at}
              onChange={formik.handleChange}
              autoComplete="country-name"
              className="shadow-sm block w-full sm:text-sm input border card rounded-md"
            >
              {CREATE_INVITE_EXPIRY.map((expiry) => (
                <option key={expiry.key} value={expiry.key}>
                  {expiry.label}
                </option>
              ))}
            </select>
          </div>
          {formik.values.expires_at && (
            <div className="text-sm mt-2 text__info">Will expire on {getExpiryTime(formik.values.expires_at)}</div>
          )}
          {formik.touched?.expires_at && formik.errors?.expires_at && (
            <div className="float-right text-xs mt-1 text-red-500">{formik.errors.expires_at}</div>
          )}
        </div>
        <div className="relative flex items-start mb-4 md:mb-6">
          <div className="flex items-center h-5">
            <input
              id={'assignSelf'}
              name={'assignSelf'}
              type="checkbox"
              checked={formik.values.allow_self_assign}
              onChange={() => formik.setFieldValue('allow_self_assign', !formik.values.allow_self_assign)}
              className="h-4 w-4 rounded focus:outline-none focus:ring-0 focus:ring-offset-0 border-gray-300 text__link"
              style={{ color: activeCommunity?.accent_color }}
            />
          </div>
          <div className="ml-3 text-sm">
            <label htmlFor={'assignSelf'} className={classNames('font-medium text__title', primaryFont)}>
              {$translatei18n('AllowExistingToUseLink')}
            </label>
          </div>
        </div>
        <div className="mb-4 md:mb-6">
          <div className="flex justify-between items-center border-b border-gray-300 pb-3 mb-4">
            <span className={classNames('text-sm font-medium text__title', primaryFont)}>
              {$translatei18n('AssignRoles')}
            </span>
            <div>
              {/* mobile */}
              <div className="lg:hidden">
                <button
                  className="inline-flex justify-center text-xs items-center rounded px-3 py-[6px] btn__light focus:outline-none focus:ring-0 focus:ring-offset-0"
                  type="button"
                  onClick={() => setRolesListOpen(true)}
                >
                  {$translatei18n('AddRoles')}
                </button>
                <RolesList
                  rolesList={rolesArr}
                  selectedRoles={selectedRoles}
                  accentColor={activeCommunity?.accent_color}
                  handleRoleClick={handleRoleClick}
                  handleAssignSelectedRoles={() => handleAssignSelectedRoles()}
                  rolesListOpen={rolesListOpen}
                  handleRoleListClose={handleRoleListClose}
                />
              </div>
              {/* web */}
              <div className="hidden lg:block">
                <Popover>
                  {({ close, open }) => {
                    useEffect(() => {
                      if (!open) {
                        setSelectedRoles([...assignedRoles]);
                      }
                    }, [open]);

                    return (
                      <>
                        <PopoverButton>
                          <span className="inline-flex justify-center text-xs items-center rounded px-3 py-[6px] btn__light focus:outline-none focus:ring-0 focus:ring-offset-0 cursor-pointer">
                            {$translatei18n('AddRoles')}
                          </span>
                        </PopoverButton>
                        <PopoverPanel>
                          <div className="absolute z-10 right-4 mt-2 w-56 rounded-md shadow-lg menu__options__container border focus:outline-none">
                            <RolesList
                              rolesList={rolesArr}
                              selectedRoles={selectedRoles}
                              accentColor={activeCommunity?.accent_color}
                              handleRoleClick={handleRoleClick}
                              handleAssignSelectedRoles={() => handleAssignSelectedRoles(close)}
                              rolesListOpen={rolesListOpen}
                              handleRoleListClose={handleRoleListClose}
                            />
                          </div>
                        </PopoverPanel>
                      </>
                    );
                  }}
                </Popover>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap gap-2">
            {assignedRoles.map((role, index) => {
              return (
                <span
                  key={role.id}
                  className="inline-flex items-center text-sm py-[6px] ltr:pl-3  rtl:pr-3 ltr:pr-2 rtl:pl-2 rounded-3xl text-gray-800"
                  style={{
                    backgroundColor: role.color ?? COMMUNITY_ROLE_COLOR[index % rolesArr.length],
                  }}
                >
                  <span
                    className={`inline-block ${role?.role?.length > 10 ? 'truncate max-w-[60px]' : ''}`}
                    data-tooltip-content={role?.role}
                    data-tooltip-id={role?.role?.split(`'`).join()}
                  >
                    {role?.role}
                    {role?.role?.length > 10 && (
                      <ReactTooltip
                        id={role?.role?.split(`'`).join()}
                        effect="solid"
                        style={{
                          whiteSpace: 'break-spaces',
                          maxWidth: '200px',
                          overflowWrap: 'break-word',
                        }}
                      />
                    )}
                  </span>
                  {/* {role?.role?.toLowerCase() !== 'owner' && (
                    <XIcon
                      className="ml-1 inline h-4 w-4 rounded-full cursor-pointer hover:bg-gray-300"
                      onClick={() => handleRoleClick(role)}
                    />
                  )} */}
                </span>
              );
            })}
          </div>
          {formik.submitCount > 0 && isSelectedRolesEmpty && selectedRoles.length === 0 && (
            <div className="float-right text-xs mt-1 text-red-500">{$translatei18n('PleaseSelectCommunityRole')}</div>
          )}
        </div>
      </div>
      <div className="card__footer p-4 border-t border-gray-300 sticky bottom-0 w-full bg-white">
        <button
          type="submit"
          className="w-full rounded-md shadow-sm py-2 px-4 inline-flex justify-center font-bold focus:outline-none focus:ring-0 focus:ring-offset-0 btn__primary usetiful-create-invite-drawer btn__primary"
          style={{ backgroundColor: activeCommunity?.accent_color }}
          disabled={formik.isSubmitting}
        >
          {formik.isSubmitting ? 'Generating New Invite. Please wait...' : 'Create Invite'}
        </button>
      </div>
    </form>
  );
};

export default CreateInviteContent;
