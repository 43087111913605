import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/fonts/Geomanist-Regular.otf';
import './main.scss';
import './index.css';

import { setupSentry } from './services/SentryService';
import InternalMaintenance from './components/shared/tailwind/InternalMaintenance';
import CommunityNotFound from './components/shared/tailwind/CommunityNotFound';
import { checkForMaintenance } from './services/communityService';

import translate, { setLocale, getCurrentLocale } from './i18n';
import ErrorBoundary from './components/errors';

window.$translatei18n = translate;
window.$getCurrentLocale = getCurrentLocale;
window.$setLocale = setLocale;

setupSentry();

function RootComponent() {
  const [page, setPage] = useState(null);
  const init = async () => {
    try {
      const res = await checkForMaintenance();
      if (res) {
        setPage('maintenance');
      }
    } catch (error) {
      setPage(null);
    }
  };
  useEffect(() => {
    init();
  }, []);

  if (page === 'maintenance') {
    return <InternalMaintenance />;
  }
  if (!page) {
    return <App setPage={setPage} />;
  }
  return <CommunityNotFound />;
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ErrorBoundary >
    <React.StrictMode>
      <RootComponent />
    </React.StrictMode>
  </ErrorBoundary>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(process.env.REACT_APP_ENV === "production" ?  "" : console.log );
