import {
  all, fork, put, takeEvery, takeLatest,
} from 'redux-saga/effects';
import { CHANNEL_TYPES } from '../constants';
import { SUCCESS_MESSAGES } from '../constants/notificationMessages';
import { history, parseAxiosErrorMessage } from '../constants/utils';
import NotificationService from '../services/notificationService';

import {
  fetchPublicCommunityFeed,
  fetchPublicCommunityGroups,
  fetchPublicCommunityChannels,
  fetchPublicForumPostService,
  fetchPublicForumPostCommentService,
  fetchPublicForumPostCommentRepliesService,
  getPublicZoomEvents,
} from '../services/publicCommunity';
import {
  FETCH_FORUM_POST_COMMENT_REPLIES_SUCCESS,
  FETCH_FORUM_POST_COMMENT_SUCCESS,
  FETCH_PUBLIC_COMMUNITY_CHANNELS_REQUEST,
  FETCH_PUBLIC_COMMUNITY_CHANNELS_SUCCESS,
  FETCH_PUBLIC_COMMUNITY_OVERVIEW_REQUEST,
  FETCH_PUBLIC_COMMUNITY_OVERVIEW_SUCCESS,
  // FORUM
  FETCH_PUBLIC_FORUM_POST_COMMENT_REPLIES_REQUEST,
  FETCH_PUBLIC_FORUM_POST_COMMENT_REPLIES_SUCCESS,
  FETCH_PUBLIC_FORUM_POST_COMMENT_REQUEST,
  FETCH_PUBLIC_FORUM_POST_COMMENT_SUCCESS,
  FETCH_PUBLIC_FORUM_POST_REQUEST,
  FETCH_PUBLIC_FORUM_POST_SUCCESS,
  FETCH_PUBLIC_GROUPS_REQUEST,
  FETCH_PUBLIC_GROUPS_SUCCESS,
  GET_PUBLIC_ZOOM_EVENT,
  GET_PUBLIC_ZOOM_EVENT_SUCCESS,
  SET_QUERY_STRING,
  UPDATE_PUBLIC_ACTIVE_CHANNEL,
} from '../store/actions/actionTypes';
import { getFullName } from '../utils/Utils';
import { getAuthToken } from '../constants/authUtils';

const getPostsIdsForData = (posts) => {
  const postIds = {};
  if (!posts) {
    return postIds;
  }
  posts.forEach((item) => {
    postIds[item?.attributes?.forum_post_id] = item?.attributes;
  });
  return postIds;
};

const postDataModel = (resData, forceValues) => {
  const posts = resData?.data;
  const included = resData?.included;
  const page = resData?.links?.page;
  const totalPages = resData?.links?.pages;
  const forum_post_votes = resData?.forum_post_votes;
  const forum_bookmarks = resData?.forum_bookmarks;

  const forumPosts = [];
  const includedData = {};
  const postVotes = forum_post_votes?.data;
  const postsBookmarked = forum_bookmarks?.data;

  const votedPostsIds = getPostsIdsForData(postVotes);
  const bookmarkedPostsIds = getPostsIdsForData(postsBookmarked);
  let permissions;

  included.forEach((item) => {
    const {
      attributes: {
        first_name, last_name, image, id, cool_human_badge_url, is_cool_human,
      },
    } = item;
    includedData[`${item.id}-${item.type}`] = {
      name: getFullName(first_name, last_name),
      image,
      id,
      coolBadgeUrl: cool_human_badge_url,
      isCoolHuman: is_cool_human,
      first_name,
      last_name,
    };
  });

  posts.forEach((post) => {
    let postData = post.attributes;
    const {
      relationships: { user },
    } = post;

    const postVoted = votedPostsIds[post.id];

    postData.user = includedData[`${user.data.id}-${user.data.type}`];
    if (postVoted) {
      postData.voted = true;
      postData.vote = postVoted?.vote_flag;
    }
    postData.hasBookmarked = !!bookmarkedPostsIds[post.id];
    if (forceValues) {
      postData = { ...postData, ...forceValues };
    }
    forumPosts.push(postData);
  });

  return {
    posts: forumPosts,
    permissions,
    page,
    totalPages,
  };
};

const postCommentDataModel = (data) => {
  const comments = data?.forum_post_comments?.data;
  const included = data?.forum_post_comments?.included;
  const page = data?.forum_post_comments?.links?.page;
  const totalPages = data?.forum_post_comments?.links?.pages;
  const forumPost = data?.forum_post;
  // forum_comment_votes: { data: commentVotes },
  const postComments = [];
  const includedData = {};
  let permissions;

  included.forEach((item) => {
    const {
      attributes: {
        first_name, last_name, image, id, cool_human_badge_url, is_cool_human,
      },
    } = item;
    includedData[`${item.id}-${item.type}`] = {
      name: getFullName(first_name, last_name),
      image,
      id,
      coolBadgeUrl: cool_human_badge_url,
      isCoolHuman: is_cool_human,
    };
  });

  comments.forEach((comment) => {
    const commentData = comment.attributes;
    // const commentVoted = commentVotes.find((item) => item.attributes.forum_post_comment_id.toString() === comment.id);

    const {
      relationships: { user },
    } = comment;
    // if (commentVoted) {
    //   commentData.voted = true;
    //   commentData.vote = commentVoted?.attributes?.vote_flag;
    // }

    commentData.user = includedData[`${user.data.id}-${user.data.type}`];
    postComments.push(commentData);
  });

  const { posts } = postDataModel({
    data: [forumPost.data],
    included: forumPost.included,
    links: {},
    forum_bookmarks: data?.forum_bookmark,
    forum_post_votes: data?.forum_post_vote,
  });

  return {
    comments: postComments,
    permissions,
    page,
    totalPages,
    forumPost: posts[0],
  };
};

const postCommentReplyDataModel = (data) => {
  const replies = data?.forum_comment_replies?.data;
  const included = data?.forum_comment_replies?.included;
  const page = data?.forum_comment_replies?.links?.page;
  const totalPages = data?.forum_comment_replies?.links?.pages;
  const commentId = data?.forum_post_comment?.data?.id;
  // forum_comment_reply_votes: { data: replyVotes },

  const commentReplies = [];
  const includedData = {};

  included.forEach((item) => {
    const {
      attributes: {
        first_name, last_name, image, id, cool_human_badge_url, is_cool_human,
      },
    } = item;
    includedData[`${item.id}-${item.type}`] = {
      name: getFullName(first_name, last_name),
      image,
      id,
      coolBadgeUrl: cool_human_badge_url,
      isCoolHuman: is_cool_human,
    };
  });

  replies.forEach((reply) => {
    const replyData = reply.attributes;
    const {
      relationships: { user },
    } = reply;
    // const replyVoted = replyVotes.find((item) => item.attributes.forum_comment_reply_id.toString() === reply.id);

    // if (replyVoted) {
    //   replyData.voted = true;
    //   replyData.vote = replyVoted?.attributes?.vote_flag;
    // }

    replyData.user = includedData[`${user.data.id}-${user.data.type}`];
    commentReplies.push(replyData);
  });

  return {
    replies: commentReplies,
    commentId,
    page,
    totalPages,
  };
};

const retrievePostsIdsForData = (posts) => {
  const postIds = {};
  if (!posts) {
    return postIds;
  }
  posts.forEach((item) => {
    postIds[item?.forum_post_id || item?.resource_post_id] = item;
  });
  return postIds;
};

const postFeedDataModel = (data, forceValues) => {
  const posts = data?.posts;
  const included = data?.included;
  const page = data?.links?.page;
  const totalPages = data?.links?.pages;
  const post_votes = data?.post_votes;
  const bookmarks = data?.bookmarks;

  const feedPosts = [];

  const votedPostsIds = retrievePostsIdsForData(post_votes);
  const bookmarkedPostsIds = retrievePostsIdsForData(bookmarks);

  posts.forEach((post) => {
    let postData = post;

    const postVoted = votedPostsIds[postData.id];
    // if (postData.channel_type === CHANNEL_TYPES.FORUM) {
    //   postData.forumPermissions = getForumPermissionsConfig(channel_permissions[postData.channel_id]);
    // }

    if (postVoted) {
      postData.voted = true;
      postData.vote = postVoted?.vote_flag;
    }

    if (forceValues) {
      postData = { ...postData, ...forceValues };
    }

    if (bookmarkedPostsIds[postData.id]?.id) {
      postData.hasBookmarked = true;
    }

    feedPosts.push(postData);
  });

  return {
    posts: feedPosts,
    page,
    totalPages,
  };
};

function* fetchPublicCommunityFeedSaga() {
  yield takeLatest(FETCH_PUBLIC_COMMUNITY_OVERVIEW_REQUEST, function* ({ payload }) {
    try {
      const response = yield fetchPublicCommunityFeed(payload);
      const calendarEvents = response?.calendar_events || [];

      yield put({
        type: FETCH_PUBLIC_COMMUNITY_OVERVIEW_SUCCESS,
        payload: { ...postFeedDataModel(response?.data), calendarEvents, isReset: payload.isReset },
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* fetchAllPublicCommunityChannelsSaga() {
  yield takeLatest(FETCH_PUBLIC_COMMUNITY_CHANNELS_REQUEST, function* ({ payload }) {
    try {
      const response = yield fetchPublicCommunityChannels(payload);

      yield put({
        type: FETCH_PUBLIC_COMMUNITY_CHANNELS_SUCCESS,
        payload: response,
      });
      if (payload?.params?.channel_id) {
        let channelData = null;
        response?.data?.group_categories?.every((el) => {
          channelData = el?.channels.find((ch) => ch?.id == payload?.params?.channel_id);
          if (channelData) {
            return false;
          }
          return true;
        });
        if (channelData) {
          yield put({
            type: UPDATE_PUBLIC_ACTIVE_CHANNEL,
            payload: channelData,
          });
        }
      }
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* fetchAllPublicCommunityGroupsSaga() {
  yield takeLatest(FETCH_PUBLIC_GROUPS_REQUEST, function* ({ payload }) {
    try {
      const response = yield fetchPublicCommunityGroups(payload);

      yield put({
        type: FETCH_PUBLIC_GROUPS_SUCCESS,
        payload: response,
      });
    } catch (error) {

      const authToken = getAuthToken();
      if(authToken) {
        history.replace('/');
      } else {
        history.replace('/signin');
      }
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* fetchPublicForumPostsSaga() {
  yield takeLatest(FETCH_PUBLIC_FORUM_POST_REQUEST, function* ({ payload }) {
    try {
      const response = yield fetchPublicForumPostService(payload);
      yield put({
        type: FETCH_PUBLIC_FORUM_POST_SUCCESS,
        payload: { ...postDataModel(response), isResetPost: payload.isResetPost },
      });
    } catch (error) {
      // yield put({
      //   type: FETCH_FORUM_POST_ERROR,
      // });
      yield put ({
        type: SET_QUERY_STRING,
        payload: ""
      })
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      history.push('/')
    }
  });
}

function* fetchPublicForumPostCommentSaga() {
  yield takeLatest(FETCH_PUBLIC_FORUM_POST_COMMENT_REQUEST, function* ({ payload }) {
    try {
      const response = yield fetchPublicForumPostCommentService(payload);
      yield put({
        type: FETCH_FORUM_POST_COMMENT_SUCCESS,
        payload: { ...postCommentDataModel(response), isResetPost: payload.isResetPost },
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      yield put ({
        type: SET_QUERY_STRING,
        payload: ""
      })
      history.push('/')
    }
  });
}

function* fetchPublicForumPostCommentRepliesSaga() {
  yield takeEvery(FETCH_PUBLIC_FORUM_POST_COMMENT_REPLIES_REQUEST, function* ({ payload }) {
    try {
      const response = yield fetchPublicForumPostCommentRepliesService(payload);
      yield put({
        type: FETCH_FORUM_POST_COMMENT_REPLIES_SUCCESS,
        payload: {
          ...postCommentReplyDataModel(response),
          isResetPost: payload.isResetPost,
          commentId: payload.comment_id,
        },
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* getPublicZoomEventsSaga() {
  try {
    yield takeEvery(GET_PUBLIC_ZOOM_EVENT, function* ({ payload: { community_id, activeGroupId, channel_id } }) {
      const response = yield getPublicZoomEvents(community_id, activeGroupId, channel_id);
      yield put({
        type: GET_PUBLIC_ZOOM_EVENT_SUCCESS,
        payload: response?.data?.data,
      });
    });
  } catch (error) {
    const message = parseAxiosErrorMessage(error);
    NotificationService.error(message);
  }
}

export default function* rootSaga() {
  yield all([
    fork(fetchAllPublicCommunityChannelsSaga),
    fork(fetchPublicCommunityFeedSaga),
    fork(fetchAllPublicCommunityGroupsSaga),
    fork(fetchPublicForumPostsSaga),
    fork(fetchPublicForumPostCommentSaga),
    fork(fetchPublicForumPostCommentRepliesSaga),
    fork(getPublicZoomEventsSaga),
  ]);
}
