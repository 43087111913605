/* eslint-disable no-case-declarations */
import {
  ADD_BLOCKED_USER_IDS_CHAT,
  AUTH_LOG_OUT_SUCCESS,
  CHAT_CHANNEL_CONNECTION_SUCCESS,
  NEW_CHAT_REQUEST,
  REMOVE_BLOCKED_USER_IDS_CHAT,
  RESET_CHAT_STATE,
  SET_CHAT_LOADING,
  SET_EARLIER_MESSAGES_CHAT,
  SET_LAST_MESSAGE_CHAT,
  SET_LOAD_MORE_CHAT,
  SET_NOTIFICATION_STATUS,
  SET_PINNED_MESSAGE,
  SET_TEXT_MESSAGES_CHAT,
  UPDATE_CHAT_STATE,
} from '../actions/actionTypes';

const initialState = {
  channelLoading: true,
  isConnected: false,
  channelData: {},
  chatChannel: null,
  channelDetails: {},
  messages: [],
  lastMessageId: null,
  canLoadMore: false,
  isTypingText: '',
  pinnedMessage: null,
  isNotificationMuted: false,
  channelConfigs: {
    canSendMessage: true,
    canStartVoice: true,
    canManageSpeakers: true,
    canManageMessages: true,
    canManageUsers: true,
    canManageSettings: true,
    canViewStage: true,
  },
  redirect: false,
  newChatRequest: false,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case CHAT_CHANNEL_CONNECTION_SUCCESS:
      return {
        ...state,
        channelData: payload.channelData,
        chatChannel: payload.chatChannel,
        channelConfigs: payload.channelConfigs,
        channelLoading: false,
        pinnedMessage: payload.pinnedMessage,
        isNotificationMuted: payload.isNotificationMuted,
        isConnected: true,
      };
    case UPDATE_CHAT_STATE:
      return { ...state, ...payload };
    case SET_LOAD_MORE_CHAT:
      return { ...state, canLoadMore: payload.value };
    case SET_EARLIER_MESSAGES_CHAT:
      return {
        ...state,
        messages: [...state.messages, ...payload.messages],
        hasEarlierMessages: true,
      };
    case SET_LAST_MESSAGE_CHAT:
      return { ...state, lastMessageId: payload.id };
    case SET_TEXT_MESSAGES_CHAT:
      return { ...state, messages: payload.messages };
    case ADD_BLOCKED_USER_IDS_CHAT:
      return { ...state, blockedUserIds: [...state.blockedUserIds, ...payload.value] };
    case REMOVE_BLOCKED_USER_IDS_CHAT:
      const updatedBlockedUserIds = state.blockedUserIds.filter((id) => id !== payload.value);
      return { ...state, blockedUserIds: updatedBlockedUserIds };
    case RESET_CHAT_STATE:
      return initialState;
    case SET_PINNED_MESSAGE:
      return { ...state, pinnedMessage: payload.pinnedMessage };
    case SET_NOTIFICATION_STATUS:
      return { ...state, isNotificationMuted: payload.isNotificationMuted };
    case SET_CHAT_LOADING:
      return { ...state, channelLoading: payload };
    case AUTH_LOG_OUT_SUCCESS:
      return initialState;
	case NEW_CHAT_REQUEST:
		return { ...state, newChatRequest: payload };
    default:
      return state;
  }
};

export default reducer;
