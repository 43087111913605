import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MicrophoneIcon, PhoneMissedCallIcon } from '@heroicons/react/outline';
import { FiMicOff } from 'react-icons/fi';
import { useHistory } from 'react-router';
import moment from 'moment';
import isAfter from 'date-fns/isAfter';
import disconnectIcon, { ReactComponent as DisconnectIcon } from '../../assets/images/icons/disconnect.svg';
import micIcon from '../../assets/images/icons/mic.svg';
import unmuteIcon, { ReactComponent as UnmuteIcon } from '../../assets/images/icons/Unmute.svg';
import { toggleMuteStatus, updateAudioState } from '../../store/actions/audioActions';
import { closeChannelConnection, updateTextState } from '../../store/actions/textActions';
import { isMuteBtnEnabled } from '../../constants/userUtils';
import { getUtcTime } from '../../helpers';
import ConfirmationModal, { CONFIRMATION_TYPE } from '../shared/tailwind/ConfirmationModal';
import ExitPromptLiveEvent from '../shared/tailwind/ExitPromptLiveEvent';
import { endCalendarEvent } from '../../services/CalendarService';
import { getActiveGroupId } from '../../selectors/CommunitySelectors';

function MinimizedTray({ audioReducer, textReducer, closeMenu }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [disableMic, setDisableMic] = useState(false);
  const activeGroupId = useSelector(getActiveGroupId);

  useEffect(() => {
    if (disableMic === true) {
      setTimeout(() => {
        setDisableMic(false);
      }, 500);
    }
  }, [disableMic]);

  const muteUnmute = () => {
    setDisableMic(!disableMic);
    try {
      const { mute } = audioReducer;
      dispatch(toggleMuteStatus());
    } catch (error) {
      // console.log('Err: ',error);
    }
  };
  const disconnect = () => {
    if (audioReducer.connected) {
      dispatch(
        closeChannelConnection(() => {
          dispatch(updateAudioState({ isPressDisconnect: false }));
          //  history.push(`/communities/${activeCommunity?.id}`);
        }, true),
      );
    }
  };

  const navigateToStage = () => {
    const { channelData } = textReducer;
    closeMenu && closeMenu();
    dispatch(updateTextState({ redirect: false }));
    history.push(
      `/communities/${channelData?.community?.id}/group/${activeGroupId}/channels/${channelData?.channel?.id}/stage`,
      {
        breadcrumb_data: `${channelData?.community?.name} > ${channelData?.channel?.name}`,
      },
    );
  };

  const isDisabled = () => {
    const channel = textReducer?.channelData;
    const isHost = textReducer?.channelData?.host;
    const granted = audioReducer?.granted;
    return isMuteBtnEnabled({
      channel,
      granted,
      isHost,
    });
  };

  const getDayStart = (time) => {
    const dt = getUtcTime(time);
    return moment(dt, 'YYYY-MM-DD HH:mm:ss');
  };

  const isEventRunning = (start, end) => {
    const nowTime = moment(Date.now());
    const isBwt = nowTime.isBetween(getDayStart(start), getDayStart(end));
    return isBwt;
  };

  const endEvent = async (isCurrentEvent, liveEventId) => {
    if (isCurrentEvent === true) {
      try {
        const { community } = textReducer?.channelData;
        const calendarResponse = await endCalendarEvent(community.id, liveEventId, activeGroupId);
        const { status } = calendarResponse;
        if (status >= 200 && status < 300) {
          disconnect();
        }
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  const isEventLive = () => {
    const { channelData } = textReducer;

    let liveEventName = '';
    let liveEventId = '';
    let isCurrentEvent = false;
    let selectedEvent = {};

    const { events } = channelData;

    if (events !== undefined && events !== null) {
      if (events.length > 0) {
        selectedEvent = events[0];
        liveEventName = events[0].name;
        liveEventId = events[0].id;
        isCurrentEvent = isAfter(new Date(), new Date(getUtcTime(events[0].start_time)));
      }
    }
    return { isCurrentEvent, liveEventName };
  };

  const renderExitPromptModal = () => {
    const {
      granted, isVideoActive, isUserVideoActive, isPressDisconnect,
    } = audioReducer;

    const { channelData } = textReducer;

    const isHost = channelData.host;

    let liveEventName = '';
    let liveEventId = '';
    let isCurrentEvent;
    let selectedEvent = {};

    const { events } = channelData;

    if (events !== undefined && events !== null) {
      if (events.length > 0) {
        selectedEvent = events[0];
        liveEventName = events[0].name;
        liveEventId = events[0].id;
        isCurrentEvent = isAfter(new Date(), new Date(getUtcTime(events[0].start_time)));
      }
    }

    return (
      <div>
        {isPressDisconnect === true ? (
          isHost === true && isEventRunning(selectedEvent.start_time, selectedEvent.stop_time) ? (
            <ExitPromptLiveEvent
              infoText={'Event will automatically end if\nno speaker is detected on stage for 30mins'}
              open={isPressDisconnect}
              onClose={() => dispatch(updateAudioState({ isPressDisconnect: false }))}
              disConnectStage={() => disconnect()}
              endEvent={() => endEvent(isCurrentEvent, liveEventId)}
            />
          ) : (
            <ConfirmationModal
              infoText={$translatei18n('areYouSureYouWantToLeaveThisConversation')}
              open={isPressDisconnect}
              onClose={() => dispatch(updateAudioState({ isPressDisconnect: false }))}
              onConfirm={() => disconnect()}
              confirmType={CONFIRMATION_TYPE.LEAVE}
            />
          )
        ) : null}
      </div>
    );
  };

  return (
    <div className="flex flex-row items-center w-full overflow-hidden card rounded-md border border-gray-200 p-2 mt-3">
      <div
        className="relative cursor-pointer"
        style={{
          flex: isDisabled() ? 0.7 : 0.85,
        }}
        onClick={() => navigateToStage()}
        id="stage__minimized--navigateToStage"
      >
        <div className="flex flex-row items-center">
          <div className="flex h-2 w-2 rounded-md bg-emerald-500" />
          {/* <span className="pl-1 text-xs text__body">Stage Connected</span> */}
          <span className="pl-1 text-xs font-semibold text__body">{textReducer?.channelData?.channel?.name}</span>
        </div>
        {/* <span className="text-sm text__body text-normal leading-5">{textReducer?.channelData?.channel?.name}</span> */}
        {isEventLive().isCurrentEvent === true && (
          <span className="text-xs text__body text-normal leading-5">{isEventLive().liveEventName}</span>
        )}
      </div>
      <div
        className="flex flex-row items-center justify-evenly"
        style={{
          flex: isDisabled() ? 0.3 : 0.15,
        }}
      >
        {isDisabled() && (
          <button
            className={`${audioReducer?.mute ? 'bg-red-500 border border-red-500' : 'bg-white border border-gray-300 hover:border-gray-400'} flex items-center justify-center rounded-md h-9 w-9`}
            disabled={disableMic}
            onClick={() => muteUnmute()}
            id={`stage__minimized--${audioReducer?.mute ? 'unmute' : 'mute'}`}
          >
            {audioReducer?.mute ? (
              <UnmuteIcon className="h-5 w-5 text-white" />
            ) : (
              <MicrophoneIcon className="h-5 w-5 text-gray-800" />
            )}
          </button>
        )}
        <button
          className="flex items-center justify-center rounded-md h-9 w-9 bg-white border border-gray-300 hover:border-gray-400 ltr:ml-2 rtl:mr-2"
          onClick={() => dispatch(updateAudioState({ isPressDisconnect: true }))}
          id="stage__minimized--disconnect"
        >
          <DisconnectIcon className="text__danger h-4 w-4" />
        </button>
      </div>
      {renderExitPromptModal()}
    </div>
  );
}

export default MinimizedTray;
