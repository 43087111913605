import axios from 'axios';
import {
  getAuthToken, getRefreshToken, updateAuthToken, updateRefreshToken
} from '../constants/authUtils';
import NotificationService from '../services/notificationService';
import { AUTH_LOG_OUT_BACKGROUD } from '../store/actions/actionTypes';
import { store } from '../store/configureStore';
import RefreshTokenRequestService from './RefreshTokenRequestService';
import { history } from '../constants/utils';
import { SUBSCRIPTION_EXPIRED_CODE } from './server';
import { setSubscriptionError } from '../store/actions/communityActions';
import { ERROR_CODE } from '../constants';


const BASE_URL = `https://${process.env.REACT_APP_PHOENIX_HOST}/api/v4`;

const server = axios.create({
  baseURL: BASE_URL,
  timeout: 600000,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  },
});

server.interceptors.request.use(
  (config) => {
    const token = getAuthToken();
    let isError = store.getState()?.community?.isSubscribedError
    const urlList = ["/verify_community", '/verify_community_member', "/onboarding_entries/verify_entry", "/logout", "/self_serve/products", "/payment", "/self_serve/subscription_payment_status", "/self_serve/subscription", "/maintenance"]
    const controller = new AbortController();
    if(isError && !urlList.includes(config.url)){
      controller.abort()
      return {
        ...config,
        signal: controller.signal
      };
    } else if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => Promise.reject(error),
);

server.interceptors.response.use(
  async (response) => response,
  (error) => {
    if(error?.message === "canceled"){
      const isOwner = store.getState()?.community?.isOwner
      const planName = store.getState()?.plans?.currentActiveProduct?.planName
      const chargebePayment = localStorage.getItem("chargebee_payment")
      if(isOwner){
        error.message = chargebePayment ? "" : `${planName ? planName : "Free trial"} expired`
      } else {
        error.message = "The community is on hold."
      }
    }

    if (error.response && error.response.status && error.response.status == 403) {
      store.dispatch({
        type: AUTH_LOG_OUT_BACKGROUD,
      });
      return Promise.reject(error);
    }

    if (error.response && error.response.status && error.response.status == 503) {
      /* TODO: Redirect to the maintenance page */
      return Promise.reject(error);
    }

    if (error.response && error.response.status && error.response.status == 417) {
      /* TODO: Redirect to the ForceUpdate page */
      return Promise.reject(error);
    }

    if (error.response && error.response.status && error.response.status == 400) {
      if(error.response?.data?.error_code === SUBSCRIPTION_EXPIRED_CODE){
          store.dispatch(setSubscriptionError(true))
          window.location.replace('/')
          return Promise.reject(error);
      }  else if(error.response?.data?.error_code === ERROR_CODE.LIMIT_EXHAUSTED_CODE){
        return Promise.reject(error);
      } 
    } 

    if (
      error.response
      && error.response.data
      && error.response.data.errors
      && error.response.data.errors === 'Refresh Token is invalid'
    ) {
      NotificationService.error('Your session is expired. Please log in again.');
      store.dispatch({
        type: AUTH_LOG_OUT_BACKGROUD,
      });
      return Promise.reject(error);
    }

    if (isTokenExpiredError(error)) {
      return resetTokenAndReattemptRequest(error);
    }

    return Promise.reject(error);
  },
);

function isTokenExpiredError(error) {
  if (error.response && error.response.status && error.response.status == 401) return true;
  return false;
}

// how resetToken is working
// step 1 - we explicitly check for 401 error only to make sure error is due to bad/expired authToken (Also checking for old authToken and  resetToken exist)
// step 2 - we then store the faild request via "addSubscriber"
// step 3 - we are attempting to get new resetToken and authToken using old resetToken
// step 4 - If we get new resetToken & authToken sucessfully, we update auth-cookie (which is async), else we logout
// step 5 - Intead of attempting failed API call directly, we have to make sure we make failed API call again with new authToken
// (We are using cookie to store authToken which is an async storage and we have wait until storing cookie succeed,
// so, we are using explicitly 100ms timeout for trying failed API call again as cookies had no provision for promised based waiting)

const resetTokenAndReattemptRequest = async (error) => {
  try {
    // step 1
    const { response: errorResponse } = error;
    const resetToken = getRefreshToken();
    const access_token = getAuthToken();
    if (!access_token || !resetToken) {
      NotificationService.error('Your session is expired. Please log in again.');
      store.dispatch({
        type: AUTH_LOG_OUT_BACKGROUD,
      });
      return Promise.reject(error);
    }
    // step 2
    const retryOriginalRequest = new Promise((resolve) => {
      RefreshTokenRequestService.addSubscriber((access_token) => {
        errorResponse.config.headers.Authorization = `Bearer ${access_token}`;
        resolve(axios(errorResponse.config));
      });
    });
    // step 3
    if (RefreshTokenRequestService.getRefreshTokenRequestStatus() === false) {
      try {
        RefreshTokenRequestService.refreshTokenRequestStarted();
        const response = await axios.post(
          `${process.env.REACT_APP_RAILS_HOST}/api/v4/users/refresh_tokens`,
          {},
          {
            headers: {
              'Refresh-Token': resetToken,
              Authorization: `Bearer ${access_token}`,
            },
          },
        );
        if (!response.data) {
          NotificationService.error('Your session is expired. Please log in again.');

          store.dispatch({
            type: AUTH_LOG_OUT_BACKGROUD,
          });
          return Promise.reject(error);
        }
        // step 4
        const newToken = response.data.token;
        const newRefreshToken = response.data.refresh_token ? response.data.refresh_token : null;
        if (newRefreshToken) {
          updateRefreshToken(newRefreshToken?.crypted_token);
        }
        if (newToken) {
          updateAuthToken(newToken);
        }

        RefreshTokenRequestService.refreshTokenRequestCompleted();
        RefreshTokenRequestService.onAccessTokenFetched(newToken);
      } catch (error) {
        store.dispatch({
          type: AUTH_LOG_OUT_BACKGROUD,
        });
        return Promise.reject(error);
      }
    }
    // step 5
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((r) => setTimeout(r, 100));
    return retryOriginalRequest;
  } catch (err) {
    NotificationService.error('Your session is expired. Please log in again.');
    store.dispatch({
      type: AUTH_LOG_OUT_BACKGROUD,
    });
    return Promise.reject(err);
  }
};

export default server;
