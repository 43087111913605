import axios from 'axios';
import { getAuthToken, getRefreshToken, updateAuthToken, updateRefreshToken } from '../constants/authUtils';
import { history } from '../constants/utils';
import NotificationService from '../services/notificationService';
import { authLogout, authSetRefreshToken, authSetToken } from '../store/actions/authActions';
import { store } from '../store/configureStore';
import RefreshTokenRequestService from './RefreshTokenRequestService';

const BASE_URL = `${process.env.REACT_APP_RAILS_HOST}/api/v4`;

const server = axios.create({
  baseURL: `${BASE_URL}`,
  timeout: 600000,
  headers: {
    'Access-Control-Allow-Origin': process.env.REACT_APP_RAILS_HOST,
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  },
});

server.interceptors.request.use(
  (config) => {
    let token = getAuthToken();
    if (token) config.headers.Authorization = `Bearer ${token}`;
    if (process.env.REACT_APP_API_WITH_CREDENTIALS === 'true') {
      config.withCredentials = true;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

server.interceptors.response.use(
  async (response) => {
    return response;
  },
  function (error) {
    if (error.response && error.response.status && error.response.status == 503) {
      /* TODO: Redirect to the maintenance page */
      return Promise.reject(error);
    }

    if (error.response && error.response.status && error.response.status == 417) {
      /*TODO: Redirect to the ForceUpdate page */
      return Promise.reject(error);
    }

    if (
      error.response &&
      error.response.data &&
      error.response.data.errors &&
      error.response.data.errors === 'Refresh Token is invalid'
    ) {
      NotificationService.error('Your session is expired. Please log in again.');
      store.dispatch(authLogout());
      return Promise.reject(error);
    }

    if (isTokenExpiredError(error)) {
      return resetTokenAndReattemptRequest(error);
    }

    return Promise.reject(error);
  },
);

function isTokenExpiredError(error) {
  if (error.response && error.response.status && error.response.status == 401) return true;
  return false;
}

const resetTokenAndReattemptRequest = async (error) => {
  try {
    const { response: errorResponse } = error;
    const resetToken = getRefreshToken();
    const access_token = getAuthToken();
    if (!access_token || !resetToken) {
      NotificationService.error('Your session is expired. Please log in again.');
      store.dispatch(authLogout());
      return Promise.reject(error);
    }
    const retryOriginalRequest = new Promise((resolve) => {
      RefreshTokenRequestService.addSubscriber((access_token) => {
        errorResponse.config.headers.Authorization = 'Bearer ' + access_token;
        resolve(axios(errorResponse.config));
      });
    });

    if (RefreshTokenRequestService.getRefreshTokenRequestStatus() === false) {
      RefreshTokenRequestService.refreshTokenRequestStarted();

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_RAILS_HOST}/api/v4/users/refresh_tokens`,
          {},
          {
            headers: {
              'Refresh-Token': resetToken,
              Authorization: `Bearer ${access_token}`,
            },
          },
        );
        if (!response.data) {
          NotificationService.error('Your session is expired. Please log in again.');

          store.dispatch(authLogout());
          return Promise.reject(error);
        }

        const newToken = response.data.token;
        const newRefreshToken = response.data.refresh_token ? response.data.refresh_token : null;
        if (newRefreshToken) {
          updateRefreshToken(newRefreshToken?.crypted_token);
        }
        if (newToken) {
          updateAuthToken(newToken);
        }

        RefreshTokenRequestService.refreshTokenRequestCompleted();
        RefreshTokenRequestService.onAccessTokenFetched(newToken);
      } catch (err) {
        store.dispatch(authLogout());
        return Promise.reject(err);
      }
    }
    return retryOriginalRequest;
  } catch (err) {
    NotificationService.error('Your session is expired. Please log in again.');
    store.dispatch(authLogout());
    return Promise.reject(err);
  }
};

export default server;
