import { Dialog, Transition } from '@headlessui/react';
import { BellIcon, ExclamationIcon, PencilIcon, TrashIcon, UserIcon, XIcon } from '@heroicons/react/outline';
import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import { classNames } from '../../constants/utils';
import {
  getActiveCommunityFontsClass,
  getActiveCommunityId,
  getActiveCommunityAccentColor,
  getActiveGroupId,
} from '../../selectors/CommunitySelectors';
import { deleteCommunitychannel } from '../../services/channelService';
import NotificationService from '../../services/notificationService';
import { changeSubscriptionUseData } from '../../components/subscription/subscriptionUtils';
import { SUBSCRIPTION_EXHAUSTED_KEY } from '../../constants/subscriptionConstants';

export default function Sidebar({ open, closeMenu, setOpenDeleteConfirmation }) {
  const history = useHistory();
  const location = useLocation();
  const activeCommunityId = useSelector(getActiveCommunityId);
  const categories = useSelector((state) => state?.channel?.channels);
  const communityConfig = useSelector((state) => state?.community?.communityConfig);
  const groupConfig = useSelector((state) => state?.channel?.configs?.groupConfigs);
  const { primaryFont } = useSelector(getActiveCommunityFontsClass);
  const accentColor = useSelector(getActiveCommunityAccentColor);
  const [channelData, setChannelData] = useState({});
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const activeGroupId = useSelector(getActiveGroupId);

  let channelSettingsPath = matchPath(location.pathname, { path: '/channel-settings/:channel_id' });
  // let channelSettingsEditPath = matchPath(location.pathname, { path: '/channel-settings/:channel_id/edit' });

  let channel_id = channelSettingsPath?.params?.channel_id;

  useEffect(() => {
    if (!groupConfig?.canManageGroup) {
      handleBackNavigation();
    }
  }, [groupConfig]);

  useEffect(() => {
    if (channel_id && categories?.length) {
      let found = false;
      let channelDataObj = {};
      for (let i = 0; i < categories.length; i++) {
        let j = 0;
        while (j < categories[i].channels?.length) {
          if (categories[i].channels[j].id == channel_id) {
            found = true;
            channelDataObj = categories[i].channels[j];
            break;
          }
          j++;
        }
        if (found) {
          break;
        }
      }
      if (!found) {
        NotificationService.error('Invalid Channel');
        handleBackNavigation();
      } else {
        setChannelData(channelDataObj);
      }
    } else {
      handleBackNavigation();
    }
  }, [channel_id, categories]);

  return (
    <div
      className={`${open ? 'fixed top-0 right-0 left-0 bottom-0 main--background overflow-scroll' : ''}`}
      style={{ zIndex: 99999 }}
    >
      {/* <div
        className="flex items-center text-sm flex-shrink-0 ltr:pl-2 rtl:pr-2 cursor-pointer text-gray-600"
        onClick={handleBackNavigation}
      >
        <ChevronLeftIcon className={classNames('text-gray-600', 'mr-2 flex-shrink-0 h-4')} aria-hidden="true" /> Back to
        community
      </div> */}
      <div className="flex-1 px-2 space-y-1">
        <div className="mb-5 pb-5 border-b divider">
          <div
            className={classNames(
              'flex items-start justify-between flex-shrink-0 px-2 pt-5 pb-2 text-base font-medium text__title break-all',
              primaryFont,
            )}
          >
            <h2>
              {/* {channelData?.name} {$translatei18n('Settings')} */}
              Channel Settings
            </h2>
            {open && (
              <button onClick={() => closeMenu()}>
                <XIcon className="w-6 h-6" />
              </button>
            )}
          </div>
          {/* <Link
            to={`/channel-settings/${channel_id}`}
            className={classNames(
              `/channel-settings/${channel_id}` === location?.pathname ? `btn__primary ${primaryFont}` : 'text__body',
              'group flex items-center px-2 py-2 text-sm rounded-md mb-2',
            )}
            style={{ backgroundColor: `/channel-settings/${channel_id}` === location?.pathname ? accentColor : '' }}
            onClick={closeMenu}
          >
            <PencilIcon className={classNames('mr-3 flex-shrink-0 h-5')} aria-hidden="true" />
            Edit Permissions
          </Link> */}
          <Link
            to={`/channel-settings/${channel_id}`}
            className={classNames(
              `/channel-settings/${channel_id}` === location?.pathname ? `btn__primary ${primaryFont}` : 'text__body',
              'group flex items-center px-2 py-2 text-sm rounded-md mb-2',
            )}
            style={{
              backgroundColor: `/channel-settings/${channel_id}` === location?.pathname ? accentColor : '',
            }}
            onClick={closeMenu}
          >
            <PencilIcon className={classNames('mr-3 flex-shrink-0 h-5')} aria-hidden="true" />
            Edit Channel
          </Link>
          {/* <button className="group flex items-center px-2 py-2 text-sm rounded-md text__body w-full">
            <BellIcon className="mr-3 flex-shrink-0 h-5" aria-hidden="true" />
            Mute this channel
          </button> */}
        </div>
        <button
          type="button"
          className="group flex items-center px-2 py-2 text-sm rounded-md text__danger w-full"
          onClick={() => setOpenDeleteConfirmation(true)}
        >
          <TrashIcon className="mr-3 flex-shrink-0 h-5" aria-hidden="true" />
          Delete Channel
        </button>
      </div>
    </div>
  );
}
