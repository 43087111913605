import {
  all, fork, put, takeLatest,
} from 'redux-saga/effects';
import { parseAxiosErrorMessage } from '../constants/utils';
import NotificationService from '../services/notificationService';
import {
  FETCH_FOLLOWEE_REQUEST,
  FETCH_FOLLOWEE_SUCCESS,
  FETCH_FOLLOWERS_REQUEST,
  FETCH_FOLLOWERS_SUCCESS,
  UPDATE_USER_FAILED,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
} from '../store/actions/actionTypes';
import { updateUser, fetchFollowers, fetchFollowees } from '../services/UserService';
import { SUCCESS_MESSAGES } from '../constants/notificationMessages';

function* updateUserSaga() {
  yield takeLatest(UPDATE_USER_REQUEST, function* ({ payload }) {
    try {
      const newUserData = yield updateUser(payload?.userData);
      yield put({
        type: UPDATE_USER_SUCCESS,
        payload: { userData: newUserData },
      });
      NotificationService.success(SUCCESS_MESSAGES.UPDATE_SUCCESS);
    } catch (error) {
      yield put({
        type: UPDATE_USER_FAILED,
      });
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    } finally {
      if (payload?.setSubmitting) {
        payload.setSubmitting(false);
      }
    }
  });
}

function* fetchFollowersSaga() {
  yield takeLatest(FETCH_FOLLOWERS_REQUEST, function* ({ payload }) {
    try {
      const response = yield fetchFollowers(payload?.nextPageId);
      yield put({
        type: FETCH_FOLLOWERS_SUCCESS,
        payload: {
          data: response?.data?.data?.followers?.data || [],
          nextPageId: response?.data?.data?.followers?.metadata?.after || null,
          reset: payload?.reset,
        },
      });
    } catch (error) {
      console.log(error);
    }
  });
}

function* fetchFolloweeSaga() {
  yield takeLatest(FETCH_FOLLOWEE_REQUEST, function* ({ payload }) {
    try {
      const response = yield fetchFollowees(payload?.nextPageId);

      yield put({
        type: FETCH_FOLLOWEE_SUCCESS,
        payload: {
          data: response?.data?.data?.data || [],
          nextPageId: response?.data?.data?.metadata?.after || null,
          reset: payload?.reset,
        },
      });
    } catch (error) {
      console.log(error);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(updateUserSaga), fork(fetchFollowersSaga), fork(fetchFolloweeSaga)]);
}
