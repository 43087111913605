import { createSelector } from 'reselect';

const getShopStore = (state) => state.shop;

export const getShopData = createSelector([getShopStore], (shopData) => shopData);

export const getIsShopEnabled = createSelector(
  [getShopStore],
  (shopData) => shopData?.isShopEnabled,
);

export const getShopProducts = createSelector(
  [getShopData],
  (shopData) => shopData?.shopItem?.products,
);

export const getPurchasedProducts = createSelector(
  [getShopData],
  (shopData) => shopData?.purchasedProducts?.products,
);

export const getPurchasedProductIds = createSelector(
  [getShopData],
  (shopData) => shopData?.purchasedProducts?.productIds,
);


export const getShopItemsMetadata = createSelector(
  [getShopData],
  (shopData) => shopData?.shopItem?.metadata,
);

export const getPurchasedItemsMetadata = createSelector(
  [getShopData],
  (shopData) => shopData?.purchasedProducts?.metadata,
);

export const getShopName = createSelector([getShopStore], (shopData) => shopData?.shopName);

export const getIsStripeConnected = createSelector(
  [getShopData],
  (state) => state?.stripe?.isStripeConnected,
);

export const getTempProductData = createSelector(
  [getShopData],
  (shopData) => shopData?.tempProductData,
);

export const getCreateProductModalStatus = createSelector(
  [getShopData],
  (shopData) => shopData?.showCreateProductModal,
);
