import React, { useEffect, useState } from 'react';
import { fetchSearchResults } from '../../services/SearchService';
import Pagination from '../shared/Pagination';
import { SearchLoadingSpinner } from './GlobalSearch';
import UserInfo from './UserInfo';

function PeopleList({
  searchQuery,
  peopleData,
  activeCommunity,
  activeGroupId,
  closeDrawer,
  onCommonPage,
  handleViewDetails,
  handleScrollTop,
}) {
  const [intitalData, setIntitalData] = useState();
  const [allUsersData, setAllUsersData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  async function fetchUsersData(query, page) {
    setIsLoading(true);
    const { data } = await fetchSearchResults(query, 'user', page, activeCommunity?.id, activeGroupId);
    setIsLoading(false);
    if (!data?.entries) return;
    setAllUsersData(data);
    handleScrollTop();
  }

  useEffect(() => {
    if (peopleData?.entries) setIntitalData(peopleData);
    if (!allUsersData?.total_count) setAllUsersData(peopleData);
  }, [peopleData]);

  useEffect(() => {
    if (onCommonPage) return;
    fetchUsersData(searchQuery, 1);
  }, [onCommonPage]);

  return (
    <div className={`mb-8 ${!onCommonPage ? 'flex-1' : ''}`}>
      <div className="flex flex-row align-center justify-between">
        <p className="font-bold text__title">
          People <span className="font-normal text__description" id="people__count">{`(${peopleData?.total_count})`}</span>
        </p>
        {onCommonPage && handleViewDetails && (
          <span
            className="underline text-xs cursor-pointer text__link"
            onClick={handleViewDetails}
            style={{ color: activeCommunity?.accent_color }}
          >
            See all
          </span>
        )}
      </div>
      <UserList listData={onCommonPage ? intitalData?.entries : allUsersData?.entries} closeDrawer={closeDrawer} />
      {isLoading && <SearchLoadingSpinner />}
      {allUsersData?.links?.pages && allUsersData.links.pages > 1 && !onCommonPage && (
        <Pagination
          handlePageClick={(page) => fetchUsersData(searchQuery, page?.selected + 1)}
          pageCount={allUsersData?.links?.pages}
          className="mt-6"
        />
      )}
    </div>
  );
}

function UserList({ listData, closeDrawer }) {
  return (
    <div className="p-4 pb-1 shadow rounded-lg mt-2 card">
      {listData?.included?.map((data) => {
        const { id } = data?.attributes;
        return <UserInfo key={id} user={data?.attributes} closeDrawer={closeDrawer} />;
      })}
    </div>
  );
}

export default PeopleList;
