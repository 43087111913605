import { RefreshIcon } from '@heroicons/react/outline';
import React from 'react';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error: error };
  }

  componentDidCatch(error, errorInfo) {
    console.log('Error : Oops something not right.......');
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <section class="bg-white dark:bg-gray-900 ">
          <div class="container flex flex-col items-center justify-center min-h-screen px-6 py-12 mx-auto">
            <div>
              <p class="text-xl font-medium text-blue-500 dark:text-blue-400"> Error Code : 41300</p>
              <h1 class="mt-3 text-4xl font-semibold text-gray-800 dark:text-white md:text-3xl">
                Sorry, This was not supposed to happen.
              </h1>
              <p class="mt-4 text-gray-500 dark:text-gray-200 text-xl">
                There has been an error on the client side app please refer the log for more information. Try refreshing
                the App if that doesn't work try again after sometime.
              </p>
              <button
                onClick={() => location.reload()}
                class="my-4 text-lg flex items-center justify-center px-5 py-2 text-gray-700 transition-colors duration-200 bg-white border rounded-lg gap-x-2 sm:w-auto dark:hover:bg-gray-800 dark:bg-gray-900 hover:bg-gray-100 dark:text-gray-200 dark:border-gray-400"
              >
                <RefreshIcon className="w-5 h-5" />

                <span>Refresh the App</span>
              </button>
            </div>
            {process.env.REACT_APP_ENV !== 'production' && (
              <>
                <div className="text-white text-left my-4" style={{ whiteSpace: 'pre-wrap' }}>
                  <h1 className="text-2xl">
                    Error : {this?.state?.error?.message && this?.state?.error?.message?.toString()}
                  </h1>
                  <p>{this?.state?.error?.stack?.toString()}</p>
                </div>
              </>
            )}
          </div>
        </section>
      );
    }

    return this.props.children;
  }
}
