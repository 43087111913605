import { downVoteItem, upVoteItem } from '../../services/models/forum/forumModel';
import {
  DOWNVOTE_BOOKMARKED_POST_SUCCESS,
  SET_USER_BOOKMARKS_COUNT,
  RESET_BOOKMARKS_SUCCESS,
  SET_USER_FORUM_BOOKMARKS,
  SET_USER_FORUM_BOOKMARKS_PAGE_MANAGERS,
  SET_USER_RESOURCE_BOOKMARKS,
  SET_USER_RESOURCE_BOOKMARKS_LOADING,
  SET_USER_RESOURCE_BOOKMARKS_PAGE_MANAGERS,
  UPVOTE_BOOKMARKED_POST_SUCCESS,
} from '../actions/actionTypes';

const initialState = {
  bookmarks: {
    forumPosts: [],
    resources: [],
  },
  forumPageManager: {},
  forumChannelPermissions: {},
  resourcePageManager: {},
  resourceChannelPermissions: {},
  loading: true,
  myBookmarksCount: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_FORUM_BOOKMARKS:
      return {
        ...state,
        bookmarks: {
          ...state.bookmarks,
          forumPosts: [...state.bookmarks.forumPosts, ...action.payload.forumPosts],
        },
        forumChannelPermissions: action.payload.channelPermissions,
      };
    case SET_USER_RESOURCE_BOOKMARKS:
      return {
        ...state,
        bookmarks: {
          ...state.bookmarks,
          resources: [...state.bookmarks.resources, ...action.payload.resources],
        },
        resourceChannelPermissions: action.payload.channelPermissions,
      };
    case SET_USER_FORUM_BOOKMARKS_PAGE_MANAGERS:
      return { ...state, forumPageManager: action.payload };
    case SET_USER_RESOURCE_BOOKMARKS_PAGE_MANAGERS:
      return { ...state, resourcePageManager: action.payload };
    case SET_USER_RESOURCE_BOOKMARKS_LOADING:
      return { ...state, loading: action.payload };
    case UPVOTE_BOOKMARKED_POST_SUCCESS:
      let currentUpvoteIdx = state.bookmarks.forumPosts.findIndex((d) => d?.id === action?.payload.post_id);
      if (currentUpvoteIdx >= 0) {
        const downVotedItem = upVoteItem(state.bookmarks.forumPosts[currentUpvoteIdx]);
        state.bookmarks.forumPosts.splice(currentUpvoteIdx, 1, downVotedItem);
      }
      return {
        ...state,
        bookmarks: {
          ...state.bookmarks,
          forumPosts: [...state.bookmarks.forumPosts],
        },
      };
    case DOWNVOTE_BOOKMARKED_POST_SUCCESS:
      const currentDownvoteIdx = state.bookmarks.forumPosts.findIndex((d) => d?.id === action?.payload.post_id);
      if (currentDownvoteIdx >= 0) {
        const downVotedItem = downVoteItem(state.bookmarks.forumPosts[currentDownvoteIdx]);
        state.bookmarks.forumPosts.splice(currentDownvoteIdx, 1, downVotedItem);
      }
      return {
        ...state,
        bookmarks: {
          ...state.bookmarks,
          forumPosts: [...state.bookmarks.forumPosts],
        },
      };
    case RESET_BOOKMARKS_SUCCESS:
      return {
        ...state,
        bookmarks: {
          forumPosts: [],
          resources: [],
        },
      };
    case SET_USER_BOOKMARKS_COUNT:
      return {
        ...state,
        myBookmarksCount: action.payload.count,
      };
    default:
      return state;
  }
};

export default reducer;
