import {
  FETCH_ROLES_REQUEST,
  UPDATE_ROLES_REQUEST,
  CREATE_ROLES_REQUEST,
  FETCH_COMMUNITY_USER_ROLE_REQUEST,
  DELETE_ROLES_REQUEST,
  SEARCH_COMMUNITY_MEMBER_REQUEST,
  RESET_SEARCH_COMMUNITY_MEMBER,
  FETCH_COMMUNITY_ROLES_REQUEST,
  SET_FETCH_NEXT_PAGE,
} from './actionTypes';

export const fetchRoles = (payload) => ({
  type: FETCH_ROLES_REQUEST,
  payload,
});

export const createRole = (payload) => ({
  type: CREATE_ROLES_REQUEST,
  payload,
});

export const updateRoles = (payload) => ({
  type: UPDATE_ROLES_REQUEST,
  payload,
});

export const deleteRole = (payload) => ({
  type: DELETE_ROLES_REQUEST,
  payload,
});

export const getCommunityUserRoleList = (id, next_page_id, user_id, is_reset) => ({
  type: FETCH_COMMUNITY_USER_ROLE_REQUEST,
  payload: {
    id,
    next_page_id,
    user_id,
    is_reset,
  },
});

export const searchCommunityMember = (payload) => ({
  type: SEARCH_COMMUNITY_MEMBER_REQUEST,
  payload,
});

export const resetSearchCommunityMember = () => ({
  type: RESET_SEARCH_COMMUNITY_MEMBER,
});

export const setFetchNextPage = (value) => ({
  type: SET_FETCH_NEXT_PAGE,
  payload: value,
});

export const fetchCommunityRoles = () => ({
  type: FETCH_COMMUNITY_ROLES_REQUEST,
});
