import { useDispatch } from 'react-redux';
import { getAuthToken } from '../constants/authUtils';
import { history, redirectToGuestView, redirectToLoginApp, redirectToLoginDomain } from '../constants/utils';
import { setQueryString, setRedirectUrl } from '../store/actions/authActions';
import { store } from '../store/configureStore';
import { useSelector } from 'react-redux';
import { getIsAllowedPath } from '../utils/Utils';

function ProtectedRoute({ children }) {
  const dispatch = useDispatch();
  const appearanceData = useSelector((state) => state?.community?.publicCommunityDetails);
  const custom_sso = appearanceData?.custom_sso || [];
  const guest_view = useSelector(state => state?.community?.publicCommunityDetails?.settings?.guest_view)

  try {
    const authToken = getAuthToken();

    // Ye first routing
    // Verification
    if (authToken) {
      return <>{children}</>;
    }
    // Redirect to login domain

    const pathName = history.location.pathname;
    const blackListPath = ['/signin', '/'];
    const filterPathList = ['forumposts', 'forum', 'resourceposts']
    const isAllowedPath = getIsAllowedPath(filterPathList, pathName) 

    if (blackListPath.indexOf(pathName) < 0 && !store?.getState()?.auth?.isManualLogout) {
      if(!isAllowedPath) {
        dispatch(setRedirectUrl(pathName.concat(history.location.search ? history.location.search : '')));
      }
      if(guest_view) dispatch(setQueryString(""))
    }
    if(guest_view){
      redirectToGuestView()
    } else if (custom_sso && custom_sso.length > 0) {
      const isManualLogout = store?.getState()?.auth?.isManualLogout
      if(!isManualLogout){
        redirectToLoginDomain();
      }
    } else redirectToLoginApp()
    return null;
  } catch (error) {
    console.log('🚀 ~ file: ProtectedRoute.js ~ line 31 ~ ProtectedRoute ~ error', error);
    // Redirect to login domain
    if(guest_view){
      redirectToGuestView()
    } if (custom_sso && custom_sso.length > 0) {
      redirectToLoginDomain();
    } else (redirectToLoginApp())


    return null;
  }
}

export default ProtectedRoute;
