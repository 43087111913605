/* eslint-disable no-case-declarations */
import _ from 'lodash';
import { downVoteItem, upVoteItem } from '../../services/models/forum/forumModel';
import {
  ADD_CUSTOM_CSS_FAILED,
  ADD_CUSTOM_CSS_REQUEST,
  ADD_CUSTOM_CSS_SUCCESS,
  ADD_CUSTOM_DOMAIN_FAILED,
  ADD_CUSTOM_DOMAIN_REQUEST,
  ADD_CUSTOM_DOMAIN_SUCCESS,
  AUTH_LOG_OUT_SUCCESS,
  CHANGE_COMMUNITY_APPEARANCE_FAILED,
  CHANGE_COMMUNITY_APPEARANCE_REQUEST,
  CHANGE_COMMUNITY_APPEARANCE_SUCCESS,
  COMMUNITY_FEED_RESET,
  COMMUNITY_RESOURCE_FEED_SET_LIKE,
  CREATE_COMMUNITY_REQUEST,
  CREATE_COMMUNITY_SUCCESS,
  CREATE_GROUP_FAILED,
  CREATE_GROUP_REQUEST,
  CREATE_GROUP_SUCCESS,
  DELETE_GROUP_FAILED,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SUCCESS,
  FETCH_ALL_COMMUNITY_SUCCESS,
  FETCH_COMMUNITY_FEED_SET_PAGE,
  FETCH_COMMUNITY_FEED_SUCCESS,
  FETCH_GROUPS_FAILED,
  FETCH_GROUPS_REQUEST,
  FETCH_GROUPS_SUCCESS,
  FETCH_PUBLIC_COMMUNITY_DETAILS_SUCCESS,
  FORUM_POST_DOWN_VOTE_FROM_LIST_SUCCESS,
  FORUM_POST_UP_VOTE_FROM_LIST_SUCCESS,
  REMOVE_CUSTOM_CSS_FAILED,
  REMOVE_CUSTOM_CSS_REQUEST,
  REMOVE_CUSTOM_CSS_SUCCESS,
  REMOVE_CUSTOM_DOMAIN_FAILED,
  REMOVE_CUSTOM_DOMAIN_REQUEST,
  REMOVE_CUSTOM_DOMAIN_SUCCESS,
  REMOVE_CUSTOM_SSO_PUBLIC_COMMUNITY_DETAILS,
  SET_ACTIVE_COMMUNITY,
  SET_ACTIVE_GROUP,
  SET_COMMUNITY_LOADING,
  SET_COMMUNITY_MEMBER_FLAG,
  SET_COMMUNITY_PERMISSIONS,
  SET_GROUP_LOADING,
  SET_HAS_CHANGED_CSS,
  SET_INITIAL_COMMUNITY_DATA,
  SET_VERIFY_COMMUNITY_MEMBER_LOADING,
  TOGGLE_GROUPS_SUCCESS,
  UPDATE_COMMUNITY_DATA,
  UPDATE_COMMUNITY_FAILED,
  UPDATE_COMMUNITY_REQUEST,
  UPDATE_COMMUNITY_SUCCESS,
  UPDATE_CUSTOM_SSO_PUBLIC_COMMUNITY_DETAILS,
  UPDATE_GROUP_FAILED,
  UPDATE_GROUP_REQUEST,
  UPDATE_GROUP_SUCCESS,
  VERIFY_COMMUNITY_MEMBER_FAILED,
  VERIFY_COMMUNITY_MEMBER_SUCCESS,
  VERIFY_CUSTOM_DOMAIN_FAILED,
  VERIFY_CUSTOM_DOMAIN_REQUEST,
  VERIFY_CUSTOM_DOMAIN_SUCCESS,
  UPDATE_PUBLIC_COMMUNITY_SETTINGS,
  SET_BRANCH_SETUP_FAILED,
  SET_UNREAD_DM_COUNT,
  SET_IS_PREMIUM,
  GET_GROUP_TAG,
  FORUM_POST_POLL_VOTE_FROM_LIST_SUCCESS,
  UPDATE_SUBSCRIPTION_DAYS,
  UPDATE_SUBSCRIPTION_ACTIVE,
  SET_IS_COMMUNITY_OWNER,
  SET_IS_SUBSCRIPTION_ERROR,
  UPDATE_COMMUNITY_SUBSCRIPTION_DATA,
  // UPDATE_GRAPHY_SSO_EXTERNAL_NAME,
  SET_PENDING_MEMBERS_COUNT,
  SET_MEMBERS_COUNT,
  VERIFY_COMMUNITY_MEMBER_ROLES_SUCCESS,
  FETCH_TAG_SUCCESS,
  RESET_TAG,
  UPDATE_SCENES_LOGIN_PUBLIC,
  SET_LOGIN_TYPE,
  SET_SELECTED_CHANNEL,
} from '../actions/actionTypes';

const initialState = {
  categories: [],
  currentCommunity: {},
  communities: [],
  hostedCommunities: [],
  joinedCommunities: [],
  publicCommunityDetails: {},
  communityCreated: false,
  activeCommunity: null,
  activeGroup: null,
  posts: [],
  calendarEvents: [],
  paginationData: {},
  totalPage: 1,
  page: 1,
  verifyCommunityMemberLoading: true,
  setActiveGroupLoading: true,
  communityConfig: {},
  hasChangedCssBefore: false,
  communityUser: {},
  communityUserRoles: [],
  communityShop: {},
  branchSetupFailed: false,
  unreadDm: 0,
  isPremium: false,
  tags: [],
  subscriptionData: null,
  subscriptionDays: null,
  subscriptionActive: null,
  isOwner: false,
  isSubscribedError: null,
  tagData: [],
  tagLinks: {},
  loginType: null,
  selectedChannel: null,
  // community,
};

const reducer = (state = initialState, action) => {
  let idx;
  let clonedSsoList;
  let newPublicCommunityDetails;
  switch (action.type) {
    // case FETCH_ALL_COMMUNITY_CHANNELS_SUCCESS:
    //   return {
    //     ...state,
    //     communities: [action?.payload?.data?.community],
    //     activeCommunity: action?.payload?.data?.community?.id,
    //   };

    case FETCH_PUBLIC_COMMUNITY_DETAILS_SUCCESS:
      return { ...state, publicCommunityDetails: action?.payload?.data };

    case UPDATE_PUBLIC_COMMUNITY_SETTINGS:
      const publicCommunityDetail = { ...state.publicCommunityDetails, settings: action?.payload?.data?.settings };
      return { ...state, publicCommunityDetails: publicCommunityDetail };

    case SET_MEMBERS_COUNT:
      const membersCount = action.payload;
      return {
        ...state,
        publicCommunityDetails: { ...state.publicCommunityDetails, members_count: membersCount },
      };

    case SET_PENDING_MEMBERS_COUNT:
      const pendingMembersCount = action.payload;
      return {
        ...state,
        publicCommunityDetails: {
          ...state.publicCommunityDetails,
          pending_onboarding_entries_count: pendingMembersCount,
        },
      };

    case CREATE_COMMUNITY_REQUEST:
      return { ...state, communityCreated: false };
    case CREATE_COMMUNITY_SUCCESS:
      return { ...state, communityCreated: true };

    case UPDATE_COMMUNITY_REQUEST:
      return { ...state, updatingCommunity: { status: true, isError: false } };
    case UPDATE_COMMUNITY_SUCCESS:
      return { ...state, updatingCommunity: { status: false } };
    case UPDATE_COMMUNITY_FAILED:
      return { ...state, updatingCommunity: { status: false, isError: true } };

    case SET_ACTIVE_COMMUNITY:
      return { ...state, activeCommunity: action.payload };
    case SET_COMMUNITY_LOADING:
      return { ...state, isLoading: action.payload };

    case FETCH_ALL_COMMUNITY_SUCCESS:
      return {
        ...state,
        communities: action?.payload?.communities,
        hostedCommunities: action?.payload?.hostedCommunities,
        joinedCommunities: action?.payload?.joinedCommunities,
      };

    case FETCH_COMMUNITY_FEED_SUCCESS:
      return {
        ...state,
        calendarEvents:
          action?.payload?.calendarEvents?.length > 0
            ? _.uniqBy([...state?.calendarEvents, ...action?.payload?.calendarEvents], 'id')
            : state?.calendarEvents,
        posts: action?.payload?.isReset ? action?.payload?.posts : [...state.posts, ...action?.payload?.posts],
        feedPermissions: action?.payload?.permissions,
        totalPage: action?.payload?.totalPages,
        page: action?.payload?.page,
      };

    case COMMUNITY_FEED_RESET:
      return {
        ...state,
        calendarEvents: [],
        posts: [],
        feedPermissions: {},
        totalPage: 1,
        page: 1,
      };

    case FORUM_POST_UP_VOTE_FROM_LIST_SUCCESS:
      const currentUpPostIndex = state.posts.findIndex((d) => d?.id === action?.payload);
      if (currentUpPostIndex >= 0) {
        const upVotedItem = upVoteItem(state.posts[currentUpPostIndex]);
        state.posts.splice(currentUpPostIndex, 1, upVotedItem);
      }

      return {
        ...state,
        posts: [...state.posts],
      };

    case FORUM_POST_POLL_VOTE_FROM_LIST_SUCCESS:
      const currentPollPostIndex = state.posts.findIndex((d) => d?.id == action?.payload?.forum_post_id);
      if (currentPollPostIndex >= 0) {
        const updatedPollOptions = state.posts[currentPollPostIndex]?.pollOptions?.map((itm) => {
          if (itm?.id == action?.payload?.option_id) {
            itm.pollVoted = true;
            itm.vote_count = (itm.vote_count || 0) + 1;
          }

          return itm;
        });
        state.posts[currentPollPostIndex].pollOptions = updatedPollOptions;
        state.posts[currentPollPostIndex].pollTotalCount = (state.posts[currentPollPostIndex].pollTotalCount || 0) + 1;
      }

      return {
        ...state,
        posts: [...state.posts],
      };

    case FORUM_POST_DOWN_VOTE_FROM_LIST_SUCCESS:
      const currentDownPostIndex = state.posts.findIndex((d) => d?.id === action?.payload);
      if (currentDownPostIndex >= 0) {
        const downVotedItem = downVoteItem(state.posts[currentDownPostIndex]);
        state.posts.splice(currentDownPostIndex, 1, downVotedItem);
      }

      return {
        ...state,
        posts: [...state.posts],
      };

    case FETCH_COMMUNITY_FEED_SET_PAGE:
      return {
        ...state,
        page: action?.payload?.page,
      };

    case COMMUNITY_RESOURCE_FEED_SET_LIKE:
      const currentResourcePostIndex = state.posts.findIndex((d) => d?.id === action?.payload?.post_id);
      if (currentResourcePostIndex >= 0) {
        state.posts[currentResourcePostIndex].likes_count = action?.payload?.like_count;
        state.posts[currentResourcePostIndex].voted = !state.posts[currentResourcePostIndex].voted;
      }
      return {
        ...state,
        posts: [...state.posts],
      };

    case AUTH_LOG_OUT_SUCCESS:
      return {
        ...initialState,
        isCommunityMemberVerified: state.isCommunityMemberVerified,
        publicCommunityDetails: state.publicCommunityDetails,
        verifyCommunityMemberLoading: false,
      };

    case UPDATE_COMMUNITY_DATA:
      return {
        ...state,
        currentCommunity: { ...state.currentCommunity, ...action?.payload },
        publicCommunityDetails: { ...state.publicCommunityDetails, custom_css: action?.payload?.custom_css },
      };

    case UPDATE_SCENES_LOGIN_PUBLIC:
      return {
        ...state,
        publicCommunityDetails: { ...state.publicCommunityDetails, scenes_login_enable: action?.payload },
      };

    case CHANGE_COMMUNITY_APPEARANCE_REQUEST:
      return { ...state, changingAppearance: { status: true, isError: false } };

    case CHANGE_COMMUNITY_APPEARANCE_SUCCESS:
      return {
        ...state,
        changingAppearance: { status: false },
      };

    case CHANGE_COMMUNITY_APPEARANCE_FAILED:
      return {
        ...state,
        changingAppearance: { status: false, isError: true },
      };

    case ADD_CUSTOM_DOMAIN_REQUEST:
      return {
        ...state,
        addingCustomDomain: { status: true, isError: false },
      };

    case ADD_CUSTOM_DOMAIN_SUCCESS:
      return {
        ...state,
        addingCustomDomain: { status: false },
      };

    case ADD_CUSTOM_DOMAIN_FAILED:
      return {
        ...state,
        addingCustomDomain: { status: false, isError: true },
      };

    case VERIFY_CUSTOM_DOMAIN_REQUEST:
      return {
        ...state,
        verifyingCustomDomain: { status: true, isError: false },
      };

    case VERIFY_CUSTOM_DOMAIN_SUCCESS:
      return {
        ...state,
        verifyingCustomDomain: { status: false },
      };

    case VERIFY_CUSTOM_DOMAIN_FAILED:
      return {
        ...state,
        verifyingCustomDomain: { status: false, isError: true },
      };

    case REMOVE_CUSTOM_DOMAIN_REQUEST:
      return { ...state, removingCustomDomain: true };

    case REMOVE_CUSTOM_DOMAIN_SUCCESS:
      return { ...state, removingCustomDomain: false };

    case REMOVE_CUSTOM_DOMAIN_FAILED:
      return { ...state, removingCustomDomain: false };

    case ADD_CUSTOM_CSS_REQUEST:
      return { ...state, addingCustomCss: true };
    case ADD_CUSTOM_CSS_SUCCESS:
      return { ...state, addingCustomCss: false };
    case ADD_CUSTOM_CSS_FAILED:
      return { ...state, addingCustomCss: false };

    case REMOVE_CUSTOM_CSS_REQUEST:
      return { ...state, addingCustomCss: true };
    case REMOVE_CUSTOM_CSS_SUCCESS:
      return { ...state, addingCustomCss: false };
    case REMOVE_CUSTOM_CSS_FAILED:
      return { ...state, addingCustomCss: false };

    case VERIFY_COMMUNITY_MEMBER_SUCCESS:
      return { ...state, isCommunityMemberVerified: true, communityUser: action?.payload };

    case VERIFY_COMMUNITY_MEMBER_ROLES_SUCCESS:
      return { ...state, isCommunityMemberVerified: true, communityUserRoles: action?.payload };

    case SET_HAS_CHANGED_CSS:
      return { ...state, hasChangedCssBefore: true };

    case VERIFY_COMMUNITY_MEMBER_FAILED:
      return { ...state, isCommunityMemberVerified: false };
    case SET_COMMUNITY_MEMBER_FLAG:
      return { ...state, isCommunityMemberVerified: action?.payload };

    case SET_VERIFY_COMMUNITY_MEMBER_LOADING:
      return { ...state, verifyCommunityMemberLoading: action?.payload };

    case FETCH_GROUPS_REQUEST:
      return { ...state, fetchingGroups: true };
    case FETCH_GROUPS_SUCCESS:
      return { ...state, fetchingGroups: false };
    case FETCH_GROUPS_FAILED:
      return { ...state, fetchingGroups: false };

    case CREATE_GROUP_REQUEST:
      return { ...state, creatingGroup: true };
    case CREATE_GROUP_SUCCESS:
      return {
        ...state,
        currentCommunity: {
          ...state.currentCommunity,
          groups: [...(state.currentCommunity.groups || []), action?.payload],
        },
        creatingGroup: false,
      };

    case CREATE_GROUP_FAILED:
      return { ...state, creatingGroup: false };

    case UPDATE_GROUP_REQUEST:
      return { ...state, updatingGroup: true };
    case UPDATE_GROUP_SUCCESS:
      const groupIndex = state.currentCommunity.groups?.findIndex((ele) => ele.id === action.payload.id);
      if (groupIndex >= 0) {
        const groupsClone = _.cloneDeep(state.currentCommunity.groups);
        groupsClone[groupIndex] = action.payload;
        return {
          ...state,
          currentCommunity: { ...state.currentCommunity, groups: groupsClone },
          updatingGroup: false,
        };
      }

      return { ...state, updatingGroup: false };
    case UPDATE_GROUP_FAILED:
      return { ...state, updatingGroup: false };

    case DELETE_GROUP_REQUEST:
      return { ...state, deletingGroup: true };
    case DELETE_GROUP_SUCCESS:
      idx = state.currentCommunity.groups?.findIndex((ele) => ele.id === action.payload);
      if (idx >= 0) {
        const groupsClone = _.cloneDeep(state.currentCommunity.groups);
        const mainGroup = groupsClone.find((group) => group?.main_group);
        groupsClone.splice(idx, 1);
        return {
          ...state,
          activeGroup: mainGroup.id,
          currentCommunity: { ...state.currentCommunity, groups: groupsClone },
          deletingGroup: false,
        };
      }
      return { ...state, deletingGroup: false };
    case DELETE_GROUP_FAILED:
      return { ...state, deletingGroup: false };

    case TOGGLE_GROUPS_SUCCESS:
      return {
        ...state,
        currentCommunity: { ...state.currentCommunity, enable_groups: action.payload },
      };

    case SET_INITIAL_COMMUNITY_DATA:
      const permissionsConfig = {};
      action?.payload?.community_permissions?.forEach((permission) => {
        permissionsConfig[permission] = true;
      });

      const communityConfig = {
        canManageRoles: !!permissionsConfig.MANAGE_ROLES,
        canManageCommunity: !!permissionsConfig.MANAGE_COMMUNITY,
        canInviteUsers: !!permissionsConfig.INVITE_USERS,
        canManageMembers: !!permissionsConfig.MANAGE_MEMBERS,
      };
      return {
        ...state,
        currentCommunity: action?.payload?.community,
        activeCommunity: action?.payload?.community.id,
        communityConfig,
      };

    case SET_GROUP_LOADING:
      return { ...state, setActiveGroupLoading: action.payload };
    case SET_ACTIVE_GROUP:
      return { ...state, activeGroup: Number(action.payload) };

    case SET_COMMUNITY_PERMISSIONS:
      return { ...state, communityConfig: action.payload };

    case UPDATE_CUSTOM_SSO_PUBLIC_COMMUNITY_DETAILS:
      newPublicCommunityDetails = state?.publicCommunityDetails || {};
      newPublicCommunityDetails.custom_sso = [action.payload];
      return { ...state, publicCommunityDetails: newPublicCommunityDetails };

    case REMOVE_CUSTOM_SSO_PUBLIC_COMMUNITY_DETAILS:
      newPublicCommunityDetails = state?.publicCommunityDetails || {};
      newPublicCommunityDetails.custom_sso = [];
      return { ...state, publicCommunityDetails: newPublicCommunityDetails };
    case SET_BRANCH_SETUP_FAILED:
      return { ...state, branchSetupFailed: action?.payload };
    case SET_UNREAD_DM_COUNT:
      return { ...state, unreadDm: action?.payload };
    case SET_IS_PREMIUM:
      return { ...state, isPremium: action?.payload };
    case GET_GROUP_TAG:
      const tags = action?.payload;
      const newTags = tags.map((tag) => ({
        ...tag,
        label: tag?.attributes?.name,
      }));
      return { ...state, tags: newTags };

    case FETCH_TAG_SUCCESS:
      const {
        data: { data, links },
      } = action.payload;
      const arr = [];
      const existingIdList = state.tagData.map((item) => item?.attributes?.id);
      data.map((tag) => {
        if (!existingIdList.includes(tag?.attributes?.id)) {
          arr.push({
            ...tag,
            label: tag?.attributes?.name,
          });
        }
      });

      return { ...state, tagData: [...state.tagData, ...arr], tagLinks: links };

    case RESET_TAG:
      return { ...state, tagData: [], tagLinks: {} };

    case UPDATE_SUBSCRIPTION_DAYS:
      return {
        ...state,
        subscriptionDays: action.payload,
      };
    case UPDATE_SUBSCRIPTION_ACTIVE:
      return {
        ...state,
        subscriptionActive: action.payload,
      };
    case UPDATE_COMMUNITY_SUBSCRIPTION_DATA:
      return {
        ...state,
        subscriptionData: action.payload,
      };
    case SET_IS_COMMUNITY_OWNER:
      return {
        ...state,
        isOwner: action.payload,
      };
    case SET_IS_SUBSCRIPTION_ERROR:
      return {
        ...state,
        isSubscribedError: action.payload,
      };
    case SET_LOGIN_TYPE:
      return {...state, loginType: action.payload}
    case SET_SELECTED_CHANNEL:
      return {...state, selectedChannel: action.payload}

    // case UPDATE_GRAPHY_SSO_EXTERNAL_NAME:
    //   return {
    //     ...state,
    //     graphyExternalName: action.payload,
    //   };

    default:
      return state;
  }
};

export default reducer;
