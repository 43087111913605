import * as Yup from 'yup';

const communityInviteSchema = Yup.object().shape({
  expires_at: Yup.string().trim().required('Please enter expiry time'),
  name: Yup.string().trim().max(40).required('Please enter name'),
  link_limit: Yup.number()
    .required('Please enter maximum number of use')
    .moreThan(0, 'Please enter a number more than 0')
    .max(10_00_000, 'Please enter a number less than or equal to 1000000'),
});

export default communityInviteSchema;
