import React from 'react';

const WhatsapIcon = () => {
  return (
    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.625 11.5009V11.5C2.625 6.05471 7.05414 1.625 12.4969 1.625H12.5031C17.9462 1.625 22.375 6.05511 22.375 11.5C22.375 16.9448 17.9458 21.375 12.5031 21.375L12.5018 21.375C10.5707 21.3789 8.68163 20.8117 7.07206 19.7448L6.82211 19.5791L6.53648 19.6704L3.676 20.5846L4.59195 17.853L4.69454 17.547L4.50448 17.2862C3.28008 15.606 2.62185 13.5799 2.625 11.5009Z"
        stroke="#1F2937"
        strokeWidth="1.25"
        className="header__whatsapp_icon"
      />
      <path
        d="M9.09896 6.26071C9.38112 6.27222 9.522 6.28889 9.7085 6.73096V6.73136C9.93983 7.28534 10.5045 8.65164 10.5718 8.79133C10.6398 8.93181 10.7079 9.12229 10.6118 9.30721C10.5213 9.4973 10.4421 9.58103 10.3012 9.74373C10.1599 9.90604 10.0247 10.0291 9.8834 10.2029C9.75372 10.3541 9.60644 10.5164 9.77053 10.7961C9.93422 11.0707 10.4985 11.9831 11.3286 12.7168C12.4012 13.6629 13.2705 13.9653 13.581 14.0938C13.8124 14.1895 14.0893 14.1669 14.2586 13.9875C14.4731 13.7581 14.7385 13.3775 15.0094 13.0021C15.2015 12.7335 15.4441 12.6998 15.6978 12.795C15.9576 12.8847 17.3295 13.5565 17.6121 13.6966C17.8938 13.8367 18.0803 13.9038 18.1484 14.0212C18.216 14.1387 18.216 14.6931 17.9843 15.3427C17.7529 15.9919 16.8328 16.5296 16.0988 16.6864C15.5966 16.7927 14.9414 16.8768 12.7342 15.9697C9.91181 14.8105 8.09401 11.9664 7.95273 11.7819C7.81705 11.597 6.8125 10.2755 6.8125 8.90919C6.8125 7.54289 7.51249 6.877 7.79464 6.59128C8.02597 6.35635 8.40978 6.25 8.77718 6.25C8.88453 6.25041 8.99183 6.25399 9.09896 6.26071Z"
        stroke="#1F2937"
        strokeWidth="1.25"
        className="header__whatsapp_icon"
      />
      <circle cx={20} cy={4} r="3.5" fill="#10B981" stroke="white" />
    </svg>
  );
};

export default WhatsapIcon;
