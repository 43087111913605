import server from '../api/server';

export const createForumPostService = async (data) =>
  await new Promise((resolve, reject) => {
    const requestData = {
      description: data.description,
      image_url: data.image_url,
      post_tags: data?.post_tags,
    };

    if (data?.status) requestData['status'] = data.status;
    requestData['scheduled_at'] = data.scheduledAt ?? '';

    server
      .post(`/communities/${data.community_id}/groups/${data.group_id}/channels/${data.channel_id}/forum_posts`, {
        forum_post: requestData,
        video_upload_ids: data.video_upload_ids,
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch(reject);
  });

export const createForumPollPostService = async (data) =>
  await new Promise((resolve, reject) => {
    const requestData = {
      description: data.description,
      image_url: data.image_url,
      post_tags: data?.post_tags,
      hide_poll: data?.hide_poll || false,
      poll_expiry_date: data?.poll_expiry_date,
    };

    if (data?.status) requestData['status'] = data.status;
    requestData['scheduled_at'] = data.scheduledAt ?? '';

    server
      .post(`/communities/${data.community_id}/groups/${data.group_id}/channels/${data.channel_id}/create_poll`, {
        forum_post: requestData,
        poll_options: data?.poll_options,
        video_upload_ids: data.video_upload_ids,
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch(reject);
  });

// export const deleteForumPollPostService = async (data) =>
//   await new Promise((resolve, reject) => {
//     server
//       .delete(`/communities/${data.community_id}/groups/${data.group_id}/channels/${data.channel_id}/create_poll/${data.post_id}`)
//       .then((response) => {
//         const { data } = response;
//         resolve(data);
//       })
//       .catch(reject);
//   });

export const voteForumPollService = async (data) =>
  await new Promise((resolve, reject) => {
    server
      .put(
        `/communities/${data.community_id}/groups/${data.group_id}/channels/${data.channel_id}/forum_posts/${data?.forum_post_id}/poll_vote?option_id=${data?.option_id}`,
      )
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch(reject);
  });

export const updateForumPostService = async (routeData, data) =>
  await new Promise((resolve, reject) => {
    server
      .put(
        `/communities/${routeData.community_id}/groups/${routeData.group_id}/channels/${routeData.channel_id}/forum_posts/${routeData.post_id}`,
        data,
      )
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch(reject);
  });

export const deleteForumPostService = async (data) =>
  await new Promise((resolve, reject) => {
    server
      .delete(
        `/communities/${data.community_id}/groups/${data.group_id}/channels/${data.channel_id}/forum_posts/${data.post_id}`,
      )
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch(reject);
  });

export const fetchForumPostService = async (data) =>
  await new Promise((resolve, reject) => {
    server
      .get(`/communities/${data.community_id}/groups/${data.group_id}/channels/${data.channel_id}/forum_posts`, {
        params: {
          page: data.page,
          post_tags: data.post_tag,
          post_tags: JSON.stringify(data.filters),
        },
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch(reject);
  });

export const fetchForumPostCommentService = async (data) =>
  await new Promise((resolve, reject) => {
    server
      .get(
        `/communities/${data.community_id}/groups/${data.group_id}/channels/${data.channel_id}/forum_posts/${data.post_id}/forum_post_comments`,
        {
          params: {
            page: data.page,
          },
        },
      )
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch(reject);
  });

export const addForumPostCommentService = async (data) =>
  await new Promise((resolve, reject) => {
    server
      .post(
        `/communities/${data.community_id}/groups/${data.group_id}/channels/${data.channel_id}/forum_posts/${data.post_id}/forum_post_comments`,
        {
          forum_post_comment: {
            description: data?.description,
            mentioned_user_ids: (data?.mentionUserIds || []).map((el) => parseInt(el)),
            image_url: data?.image_url,
          },
        },
      )
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch(reject);
  });

export const updateForumPostCommentService = async (data) =>
  await new Promise((resolve, reject) => {
    server
      .put(
        `/communities/${data.community_id}/groups/${data.group_id}/channels/${data.channel_id}/forum_posts/${data.post_id}/forum_post_comments/${data.comment_id}`,
        {
          forum_post_comment: {
            description: data.description,
            mentioned_user_ids: (data?.mentionUserIds || []).map((el) => parseInt(el)),
            image_url: data.image_url,
          },
        },
      )
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch(reject);
  });

export const deleteForumPostCommentService = async (data) =>
  await new Promise((resolve, reject) => {
    server
      .delete(
        `/communities/${data.community_id}/groups/${data.group_id}/channels/${data.channel_id}/forum_posts/${data.post_id}/forum_post_comments/${data.comment_id}`,
      )
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch(reject);
  });

export const forumUpVoteService = async (data) =>
  await new Promise((resolve, reject) => {
    server
      .put(
        `/communities/${data.community_id}/groups/${data.group_id}/channels/${data.channel_id}/forum_posts/${data.post_id}/upvote`,
        {
          forum_post_comment: {
            vote_flag: true,
          },
        },
      )
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch(reject);
  });

export const forumDownVoteService = async (data) =>
  await new Promise((resolve, reject) => {
    server
      .put(
        `/communities/${data.community_id}/groups/${data.group_id}/channels/${data.channel_id}/forum_posts/${data.post_id}/downvote`,
        {
          forum_post_comment: {
            vote_flag: true,
          },
        },
      )
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch(reject);
  });

export const fetchForumPostCommentRepliesService = async (data) =>
  await new Promise((resolve, reject) => {
    server
      .get(
        `/communities/${data.community_id}/groups/${data.group_id}/channels/${data.channel_id}/forum_posts/${data.post_id}/forum_post_comments/${data.comment_id}/forum_comment_replies`,
        {
          params: {
            page: data.page,
          },
        },
      )
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch(reject);
  });

export const addForumPostCommentReplyService = async (data) =>
  await new Promise((resolve, reject) => {
    server
      .post(
        `/communities/${data.community_id}/groups/${data.group_id}/channels/${data.channel_id}/forum_posts/${data.post_id}/forum_post_comments/${data.comment_id}/forum_comment_replies`,
        {
          forum_comment_reply: {
            description: data.description,
            mentioned_user_ids: (data?.mentionUserIds || []).map((el) => parseInt(el)),
            image_url: data.image_url,
          },
        },
      )
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch(reject);
  });

export const updateForumPostCommentReplyService = async (data) =>
  await new Promise((resolve, reject) => {
    server
      .put(
        `/communities/${data.community_id}/groups/${data.group_id}/channels/${data.channel_id}/forum_posts/${data.post_id}/forum_post_comments/${data.comment_id}/forum_comment_replies/${data.reply_id}`,
        {
          forum_comment_reply: {
            description: data.description,
            mentioned_user_ids: (data?.mentionUserIds || []).map((el) => parseInt(el)),
            image_url: data.image_url,
          },
        },
      )
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch(reject);
  });

export const deleteForumPostCommentReplyService = async (data) =>
  await new Promise((resolve, reject) => {
    server
      .delete(
        `/communities/${data.community_id}/groups/${data.group_id}/channels/${data.channel_id}/forum_posts/${data.post_id}/forum_post_comments/${data.comment_id}/forum_comment_replies/${data.reply_id}`,
      )
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch(reject);
  });

export const forumCommentUpVoteService = async (data) =>
  await new Promise((resolve, reject) => {
    server
      .put(
        `/communities/${data.community_id}/groups/${data.group_id}/channels/${data.channel_id}/forum_posts/${data.post_id}/forum_post_comments/${data.comment_id}/upvote`,
        {
          forum_post_comment: {
            vote_flag: true,
          },
        },
      )
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch(reject);
  });

export const forumCommentDownVoteService = async (data) =>
  await new Promise((resolve, reject) => {
    server
      .put(
        `/communities/${data.community_id}/groups/${data.group_id}/channels/${data.channel_id}/forum_posts/${data.post_id}/forum_post_comments/${data.comment_id}/downvote`,
        {
          forum_post_comment: {
            vote_flag: true,
          },
        },
      )
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch(reject);
  });

export const forumReplyUpVoteService = async (data) =>
  await new Promise((resolve, reject) => {
    server
      .put(
        `/communities/${data.community_id}/groups/${data.group_id}/channels/${data.channel_id}/forum_posts/${data.post_id}/forum_post_comments/${data.comment_id}/forum_comment_replies/${data.reply_id}/upvote`,
        {
          forum_post_comment: {
            vote_flag: true,
          },
        },
      )
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch(reject);
  });

export const forumReplyDownVoteService = async (data) =>
  await new Promise((resolve, reject) => {
    server
      .put(
        `/communities/${data.community_id}/groups/${data.group_id}/channels/${data.channel_id}/forum_posts/${data.post_id}/forum_post_comments/${data.comment_id}/forum_comment_replies/${data.reply_id}/downvote`,
        {
          forum_post_comment: {
            vote_flag: true,
          },
        },
      )
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch(reject);
  });

export const forumBookMarkPostService = async (data) =>
  await new Promise((resolve, reject) => {
    server
      .patch(
        `/communities/${data.community_id}/groups/${data.group_id}/channels/${data.channel_id}/forum_posts/${data.post_id}/bookmark`,
      )
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch(reject);
  });

export const pinForumPostService = (data) =>
  new Promise((resolve, reject) => {
    server
      .patch(`/groups/${data.group_id}/channels/${data.channel_id}/forum_posts/${data.post_id}/toggle_pin`, {
        pinned: data?.pinned,
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch(reject);
  });

export const getForumUpvotes = (communityId, groupId, channelId, { postId, page = 1 }) =>
  server.get(
    `/communities/${communityId}/groups/${groupId}/channels/${channelId}/forum_posts/${postId}/upvotes?page=${page}`,
  );

export const getForumCommentUpvotes = (communityId, groupId, channelId, { postId, commentId, page = 1 }) =>
  server.get(
    `/communities/${communityId}/groups/${groupId}/channels/${channelId}/forum_posts/${postId}/forum_post_comments/${commentId}/upvotes?page=${page}`,
  );

export const getForumCommentReplyUpvotes = (
  communityId,
  groupId,
  channelId,
  { postId, commentId, replyId, page = 1 },
) =>
  server.get(
    `/communities/${communityId}/groups/${groupId}/channels/${channelId}/forum_posts/${postId}/forum_post_comments/${commentId}/forum_comment_replies/${replyId}/upvotes?page=${page}`,
  );

export const markTopAnswer = (community_id, group_id, channel_id, post_id, comment_id, flag) =>
  new Promise((resolve, reject) => {
    server
      .put(
        `/communities/${community_id}/groups/${group_id}/channels/${channel_id}/forum_posts/${post_id}/forum_post_comments/${comment_id}/top_answer`,
        { flag: flag },
      )
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch(reject);
  });

export const getScheduledDraftPosts = (communityId, status, channelId = '', groupId = '', page = 1) =>
  new Promise((resolve, reject) => {
    server
      .get(
        `users/profiles/posts?page=${page}&community_id=${communityId}&status=${status}&group_id=${groupId}&channel_id=${channelId}`,
      )
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch(reject);
  });
