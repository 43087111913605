import React, { useEffect, useState } from 'react';
import { formatDistanceToNow, parseISO } from 'date-fns';
import clip from "text-clipper";
import { parseHtml } from '../../views/channel/forum/forumUtils';
import { history } from '../../constants/utils';
import UserInfo from './UserInfo';
import Pagination from '../shared/Pagination';
import { fetchSearchResults } from '../../services/SearchService';
import { SearchLoadingSpinner } from './GlobalSearch';

function ForumPosts({
  searchQuery,
  forumData,
  activeCommunity,
  activeGroupId,
  closeDrawer,
  onCommonPage,
  handleViewDetails,
  handleScrollTop,
}) {
  const [intitalData, setIntitalData] = useState();
  const [forumPostsData, setForumPostsData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleForumClick = (channelId, postId) => {
    history.push(
      `/communities/${activeCommunity?.id}/group/${activeGroupId}/channels/${channelId}/forumposts/${postId}`,
      { isFromOverview: window.location.pathname === "/" ? true : false }
    );
    closeDrawer();
  };

  async function fetchForumPosts(query, page) {
    setIsLoading(true);
    const { data } = await fetchSearchResults(query, 'forum', page, activeCommunity?.id, activeGroupId);
    setIsLoading(false);
    if (!data?.entries) return;
    setForumPostsData(data);
    handleScrollTop();
  }

  useEffect(() => {
    if (forumData?.entries) setIntitalData(forumData);
    if (!forumPostsData?.total_count) setForumPostsData(forumData);
  }, [forumData]);

  useEffect(() => {
    if (onCommonPage) return;
    fetchForumPosts(searchQuery, 1);
  }, [onCommonPage]);

  return (
    <div className={`mb-8 ${!onCommonPage ? 'flex-1' : ''}`}>
      <div className="flex flex-row align-center justify-between">
        <p className="font-bold text__title">
          {$translatei18n('Forums')}{' '}
          <span className="font-normal text__description">{`(${forumData?.total_count})`}</span>
        </p>
        {onCommonPage && handleViewDetails && (
          <span
            className="underline text-xs cursor-pointer text__link"
            onClick={handleViewDetails}
            style={{ color: activeCommunity?.accent_color }}
          >
            See all
          </span>
        )}
      </div>
      <ForumList
        listData={onCommonPage ? intitalData?.entries : forumPostsData?.entries}
        handleForumClick={handleForumClick}
        activeCommunity={activeCommunity}
        closeDrawer={closeDrawer}
      />
      {isLoading && <SearchLoadingSpinner />}
      {forumPostsData?.links?.pages && forumPostsData.links.pages > 1 && !onCommonPage && (
        <Pagination
          handlePageClick={(page) => fetchForumPosts(searchQuery, page?.selected + 1)}
          pageCount={forumPostsData?.links?.pages}
          className="mt-6"
        />
      )}
    </div>
  );
}

function ForumList({
  listData, handleForumClick, activeCommunity, closeDrawer,
}) {
  return (
    <>
      {listData?.data?.map((forum) => {
        const {
          id, created_at, description, user_id, channel_id,
        } = forum?.attributes;
        const userData = listData?.included.find((item) => item.type === 'user' && item?.attributes?.id === user_id);
        const postChannel = listData?.included.find(
          (item) => item.type === 'channel' && item?.attributes?.id === channel_id,
        );

        return (
          <div
            key={id}
            className="p-4 shadow rounded-lg mt-2 card cursor-pointer"
            onClick={() => handleForumClick(channel_id, id)}
          >
            <div className="flex flex-row align-center justify-between text-xs text__body mb-3 pb-2 border-b border-gray-100">
              <p>
                {$translatei18n('PostedIn')} <strong>{postChannel?.attributes?.name}</strong>
              </p>
              <span className="text__info">{formatDistanceToNow(parseISO(created_at), { addSuffix: true })}</span>
            </div>
            <UserInfo user={userData?.attributes} closeDrawer={closeDrawer} />
            <div className="text__body">
              {parseHtml(
                clip(description, 140, { html: true, maxLines: 4 }), closeDrawer
              )}
            </div>
          </div>
        );
      })}
    </>
  );
}

export default ForumPosts;
