import { SETTINGS_ROUTES_CONSTANTS } from '../../../constants/routesConstants';

const manageCommunity = [
  SETTINGS_ROUTES_CONSTANTS.COMMUNITY_SETTINGS,
  SETTINGS_ROUTES_CONSTANTS.APPEARANACE,
  SETTINGS_ROUTES_CONSTANTS.PEOPLE_MANAGEMENT,
  SETTINGS_ROUTES_CONSTANTS.NOTIFICATION_PREFERENCE,
  SETTINGS_ROUTES_CONSTANTS.PLAN_DETAILS,
  SETTINGS_ROUTES_CONSTANTS.NEW_GROUP,
  SETTINGS_ROUTES_CONSTANTS.MANAGE_TAGS,
  SETTINGS_ROUTES_CONSTANTS.NEW_APPLICATIONS,
  SETTINGS_ROUTES_CONSTANTS.THEMING,
  SETTINGS_ROUTES_CONSTANTS.SETUP_COIN,
  SETTINGS_ROUTES_CONSTANTS.ALLOCATE_COIN,
];

const manageRoles = [
  SETTINGS_ROUTES_CONSTANTS.PEOPLE_MANAGEMENT,
  SETTINGS_ROUTES_CONSTANTS.MANAGE_ROLES,
  SETTINGS_ROUTES_CONSTANTS.CREATE_ROLE,
];

const manageMembers = [SETTINGS_ROUTES_CONSTANTS.MODERATION];

const groupManager = [
  SETTINGS_ROUTES_CONSTANTS.NEW_CHANNEL,
  SETTINGS_ROUTES_CONSTANTS.NEW_CHANNEL_CHOOSE_CATEGORY,
  SETTINGS_ROUTES_CONSTANTS.NEW_CATEGORY,
  SETTINGS_ROUTES_CONSTANTS.MANAGE_TAGS,
];

export const renderChildList = (settingsRoute, childrenRoute) => {
  const newArrayData = [];
  for (const checkPath of childrenRoute) {
    for (const parentData of settingsRoute) {
      if (checkPath === parentData.path) {
        newArrayData.push(parentData);
      }
    }
  }
  return newArrayData;
};

export const toRenderSettingsOption = (
  item,
  isOwner,
  subscriptionDays,
  communityConfig,
  activeCommunity,
  groupConfig,
) => {
  if (item.path === SETTINGS_ROUTES_CONSTANTS.PLAN_DETAILS) {
    if (subscriptionDays) {
      return true;
    }
    return false;
  } else if (isOwner) {
    if (item.path === SETTINGS_ROUTES_CONSTANTS.NEW_GROUP) {
      if (activeCommunity?.enable_groups) {
        return true;
      }
      return false;
    }
    return true;
  } else if (communityConfig?.canManageCommunity && pathChecker(manageCommunity, item.path, activeCommunity)) {
    return pathChecker(manageCommunity, item.path, activeCommunity);
  } else if (communityConfig?.canManageRoles && pathChecker(manageRoles, item.path, activeCommunity)) {
    return pathChecker(manageRoles, item.path, activeCommunity);
  } else if (communityConfig?.canManageMembers && pathChecker(manageMembers, item.path, activeCommunity)) {
    return pathChecker(manageMembers, item.path, activeCommunity);
  } else if (groupConfig?.canManageGroup && pathChecker(groupManager, item.path, activeCommunity)) {
    return pathChecker(groupManager, item.path, activeCommunity);
  } else {
    return false;
  }
};

const pathChecker = (pathArray, path, activeCommunity) => {
  if (pathArray?.includes(path)) {
    if (path === SETTINGS_ROUTES_CONSTANTS.NEW_GROUP) {
      return activeCommunity?.enable_groups || false;
    }
    return true;
  }
  return false;
};

export const toRenderSettingsOptionTitle = (
  childItem,
  isOwner,
  subscriptionDays,
  communityConfig,
  activeCommunity,
  groupConfig,
) => {
  const booleanArray = childItem.map((val) => {
    const dataPath = { path: val };
    return toRenderSettingsOption(dataPath, isOwner, subscriptionDays, communityConfig, activeCommunity, groupConfig);
  });
  return booleanArray?.includes(true);
};
