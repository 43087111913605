export default function CommonInput({
  label,
  name,
  id,
  placeholder,
  onChange,
  onBlur,
  value,
  disabled,
  type,
}) {
  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text__body">
        {label}
      </label>
      <div className="mt-1">
        <input
          type={type || 'text'}
          name={name}
          id={id}
          className="shadow-sm block w-full sm:text-sm border input card rounded-md"
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          disabled={disabled}
        />
      </div>
    </div>
  );
}
