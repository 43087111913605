import {
  FETCH_PUBLIC_COMMUNITY_CHANNELS_SUCCESS,
  FETCH_PUBLIC_COMMUNITY_OVERVIEW_RESET,
  FETCH_PUBLIC_COMMUNITY_OVERVIEW_SET_PAGE,
  FETCH_PUBLIC_COMMUNITY_OVERVIEW_SUCCESS,
  FETCH_PUBLIC_FORUM_POST_RESET,
  FETCH_PUBLIC_FORUM_POST_SET_PAGE,
  FETCH_PUBLIC_FORUM_POST_SUCCESS,
  FETCH_PUBLIC_GROUPS_SUCCESS,
  SET_PREMIUM_MODAL,
  GET_PUBLIC_ZOOM_EVENT_SUCCESS,
  UPDATE_PUBLIC_ACTIVE_CHANNEL,
  UPDATE_PUBLIC_COMMUNITY_CHANNELS,
  SET_USER_MODAL,
} from '../actions/actionTypes';

const initialState = {
  feed: {
    calendarEvents: [],
    posts: [],
    totalPages: 1,
    page: 1,
  },
  channels: [], // By catogery
  community: null,
  groups: [], // including main
  activeGroup: null, // default main
  activeChannel: null, // default main,
  forum: {
    page: null,
    posts: [],
    totalPages: 1,
    comments: [],
    currentForumPost: {},
    commentTotalPages: 1,
    commentPage: null,
    allCommentReplies: {},
    permissions: {},
    postPermissions: {},
    onForumPostLoading: false,
    forumPostCreateOrUpdateLoading: false,
  },
  premiumModal: false,
  userModal: false,
  zoomEvent: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PUBLIC_COMMUNITY_OVERVIEW_SUCCESS:
      return {
        ...state,
        feed: {
          ...state.feed,
          calendarEvents:
            action?.payload?.calendarEvents?.length > 0
              ? [...state?.feed?.calendarEvents, ...action?.payload?.calendarEvents]
              : state?.feed?.calendarEvents,
          posts: action?.payload?.isReset ? action?.payload?.posts : [...state?.feed?.posts, ...action?.payload?.posts],
          totalPages: action?.payload?.totalPages,
          page: action?.payload?.page,
        },
      };

    case FETCH_PUBLIC_COMMUNITY_OVERVIEW_SET_PAGE:
      return {
        ...state,
        feed: {
          ...state.feed,
          page: action?.payload?.page,
        },
      };

    case FETCH_PUBLIC_COMMUNITY_OVERVIEW_RESET:
      return {
        ...state,
        feed: {
          calendarEvents: [],
          posts: [],
          totalPages: 1,
          page: 1,
        },
      };

    case FETCH_PUBLIC_COMMUNITY_CHANNELS_SUCCESS:
      return {
        ...state,
        channels: action?.payload?.data?.group_categories.map((el) => {
          if (el) {
            el.isCollapsed = true;
          }
          return el;
        }),
        // configs: channelCategoryModel(action?.payload?.data),
        // communityOwner: action?.payload?.data?.community_owner,
        // // communityMember: action?.payload?.data?.community_member,
        // coinsCount: action?.payload?.data?.coins_count || 0,
      };
    case UPDATE_PUBLIC_COMMUNITY_CHANNELS:
      return {
        ...state,
        channels: action?.payload,
      };

    case UPDATE_PUBLIC_ACTIVE_CHANNEL:
      return {
        ...state,
        activeChannel: action?.payload,
      };

    case FETCH_PUBLIC_GROUPS_SUCCESS:
      const mainGrp = action?.payload?.data?.groups?.find((grp) => grp.main_group === true);
      return {
        ...state,
        community: action?.payload?.data?.community,
        groups: action?.payload?.data?.groups,
        activeGroup: mainGrp,
        mainGroup: mainGrp,
      };

    case FETCH_PUBLIC_FORUM_POST_SUCCESS:
      return {
        ...state,
        forum: {
          ...state.forum,
          posts: action?.payload?.isResetPost
            ? action?.payload?.posts
            : [...state.forum.posts, ...action?.payload?.posts],
          page: action?.payload?.page,
          totalPages: action?.payload?.totalPages,
          permissions: action?.payload?.permissions,
          onForumPostLoading: false,
        },
      };

    case FETCH_PUBLIC_FORUM_POST_SET_PAGE:
      return {
        ...state,
        forum: {
          ...state.forum,
          page: action?.payload?.page,
        },
      };

    case FETCH_PUBLIC_FORUM_POST_RESET:
      return {
        ...state,
        forum: {
          page: 1,
          posts: [],
          totalPages: 1,
          comments: [],
          currentForumPost: {},
          commentTotalPages: 1,
          commentPage: null,
          allCommentReplies: {},
          permissions: {},
          postPermissions: {},
          onForumPostLoading: false,
          forumPostCreateOrUpdateLoading: false,
        },
      };

    case SET_PREMIUM_MODAL:
      return {
        ...state,
        premiumModal: action?.payload,
      };
    case SET_USER_MODAL:
      return {
        ...state,
        userModal: action?.payload,
      };
    case GET_PUBLIC_ZOOM_EVENT_SUCCESS:
      return {
        ...state,
        zoomEvent: action.payload,
      };
    // case FETCH_FORUM_POST_COMMENT_SUCCESS:
    //   return {
    //     ...state,
    //     comments: action?.payload?.isResetPost
    //       ? action?.payload?.comments
    //       : [...state.comments, ...action?.payload?.comments],
    //     commentPage: action?.payload?.page,
    //     commentTotalPages: action?.payload?.totalPages,
    //     currentForumPost: action?.payload?.forumPost,
    //     postPermissions: action?.payload?.permissions,
    //   };
    default:
      return state;
  }
};

export default reducer;
