/* eslint-disable camelcase */
import server from '../api/serverVersionless';

export const getCalendarEvents = (communityId, channelId, monthTimestamp, timezone, nextPage, perPage = 1) => {
  if (nextPage) {
    return server.get(
      `/communities/${communityId}/calendar_events?per_page=${perPage}&next_page_id=${nextPage}&calendar_month_ts=${monthTimestamp}&timezone=${timezone}&calendar_channel_id=${channelId}`,
    );
  }

  return server.get(
    `/communities/${communityId}/calendar_events?per_page=${perPage}&next_page_id=&calendar_month_ts=${monthTimestamp}&timezone=${timezone}&calendar_channel_id=${channelId}`,
  );
};

export const getCalendarEventDetails = (communityId, channelId, eventId, groupId) => {
  return server.get(
    `/communities/${communityId}/groups/${groupId}/calendar_events/${eventId}?calendar_channel_id=${channelId}`,
  );
};

export const createCalendarEvent = (communityId, post, groupId) =>
  server.post(`/communities/${communityId}/groups/${groupId}/calendar_events`, {
    calendar_event: post,
  });

export const updateCalendarEvent = (communityId, eventId, post, groupId) =>
  server.put(`/communities/${communityId}/groups/${groupId}/calendar_events/${eventId}`, {
    calendar_event: post,
  });

export const endCalendarEvent = (communityId, eventId, groupId) =>
  server.put(`/communities/${communityId}/groups/${groupId}/calendar_events/${eventId}/end`, {});

export const endCalendarEventAll = (communityId, eventId, groupId) =>
  server.put(`/communities/${communityId}/groups/${groupId}/calendar_events/${eventId}/end?end_event`, {});

export const goLive = (communityId, eventId, groupId, rtmpUrl) =>
  server.post(`/communities/${communityId}/groups/${groupId}/calendar_events/${eventId}/restream_dyte_event?rtmp_url=${rtmpUrl}`);

export const stopLive = (communityId, eventId, groupId) =>
  server.put(`/communities/${communityId}/groups/${groupId}/calendar_events/${eventId}/stop_dyte_restream`);

// http://localhost:4000/api/v4/communities/98/groups/103/calendar_events/1161/restream_dyte_event?rtmp_url=rtmp%3A%2F%2Flive.restream.io%2Flive%2Fre_7877192_973e2a12dc49ad65a49d

export const setRemindCalendarEvent = (communityId, eventId, groupId) =>
  server.post(`/communities/${communityId}/groups/${groupId}/calendar_events/${eventId}/remind`, {
    remind: true,
  });

export const getCalendarEventsNew = (
  communityId,
  channelId,
  futureEvents, // boolean
  timezone,
  groupId,
  nextPage,
  perPage = 1,
) => {
  if (nextPage) {
    return server.get(
      `/communities/${communityId}/groups/${groupId}/channel_events?per_page=${perPage}&next_page_id=${nextPage}&timezone=${timezone}&calendar_channel_id=${channelId}&active=${futureEvents}`,
    );
  }

  return server.get(
    `/communities/${communityId}/groups/${groupId}/channel_events?per_page=${perPage}&next_page_id=&timezone=${timezone}&calendar_channel_id=${channelId}&active=${futureEvents}`,
  );
};

export const deleteCalendarEvent = (communityId, eventId, groupId, data) =>
  server.delete(`/communities/${communityId}/groups/${groupId}/calendar_events/${eventId}`, {
    data: { calendar_event: data },
  });

export const getZoomEventSignature = (communityId, eventId, groupId) => {
  return server.get(`/communities/${communityId}/groups/${groupId}/calendar_events/${eventId}/join_zoom_meeting`);
};

export const getZoomEvents = (communityId, groupId, channelId) => {
  return server.get(`communities/${communityId}/groups/${groupId}/channels/${channelId}/zoom`);
};
export const getStreamingEvents = (communityId, groupId, channelId) => {
  return server.get(`communities/${communityId}/groups/${groupId}/channels/${channelId}/streaming`);
};
export const getDyteStreamingEvent = (communityId, groupId, eventId) => {
  return server.get(`communities/${communityId}/groups/${groupId}/calendar_events/${eventId}/join_stream_meeting`);
};
export const setEventReminder = (communityId, groupId, eventId, reminder) => {
  const data = { remind: reminder };
  return server.post(`/communities/${communityId}/groups/${groupId}/calendar_events/${eventId}/remind`, data);
};

export const kickAndBanUser = (data) => {
  const { communityId, groupId, calendarEventId, calendarChannelId, userId } = data;
  return server.post(
    `/communities/${communityId}/groups/${groupId}/calendar_events/${calendarEventId}/event_ban_user/?calendar_channel_id=${calendarChannelId}&user_id=${userId}`,
  );
};
