import { ArchiveIcon, InboxIcon, PlusIcon } from '@heroicons/react/outline';
import classNames from 'classnames';
import { sortBy } from 'lodash';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import ConfirmationModal, { CONFIRMATION_TYPE } from '../../components/shared/tailwind/ConfirmationModal';
import { getActiveCommunity, getActiveGroupId, getGroups } from '../../selectors/CommunitySelectors';
import { getAudioReducer } from '../../selectors/StageTextSelectors';
import { updateGroupPositionService } from '../../services/channelService';
import NotificationService from '../../services/notificationService';
import { imageFetch } from '../../services/preSignedAws';
import { fetchGroups, setActiveGroup } from '../../store/actions/communityActions';
import { closeChannelConnection } from '../../store/actions/textActions';
import { USER_IMAGE_TYPE_3 } from '../../constants';
import { FEATURES_FLAG_LIST, SUBSCRIPTION_LOCKED_UI_ID } from '../../constants/subscriptionConstants';
import Subscription from '../../components/subscription/index';

export const sidebarNavigation = [
  {
    name: 'Open',
    href: '#',
    icon: InboxIcon,
    current: true,
  },
  {
    name: 'Archive',
    href: '#',
    icon: ArchiveIcon,
    current: false,
  },
];

function StageConfirm({ open, onClose, audioReducer, groupId, activeCommunity }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const disconnect = () => {
    if (audioReducer.connected) {
      dispatch(setActiveGroup(groupId, true));
      // history.push(`/communities/${activeCommunity?.id}/group/${groupId}/overview`);
      history.push('/');
      onClose();
      dispatch(closeChannelConnection(() => {}, true));
    }
  };

  return (
    <ConfirmationModal
      infoText={$translatei18n('areYouSureYouWantToLeaveThisConversation')}
      open={open}
      onClose={onClose}
      onConfirm={() => disconnect()}
      confirmType={CONFIRMATION_TYPE.LEAVE}
    />
  );
}

export default function NarrowSidebar() {
  const dispatch = useDispatch();
  const history = useHistory();

  // state vars
  const initialOpenStageConfirm = {
    open: false,
    groupId: null,
  };
  const [mainGroup, setMainGroup] = useState({});
  const [subGroups, setSubGroups] = useState([]);
  const [openStageConfirm, setOpenStageConfirm] = useState(initialOpenStageConfirm);
  const [activeHoverGrp, setActiveHoverGrp] = useState(null);

  // store vars
  const groups = useSelector(getGroups);
  const activeGroupId = useSelector(getActiveGroupId);
  const activeCommunityDetails = useSelector(getActiveCommunity);
  const activeCommunity = useSelector((state) => state?.community?.activeCommunity);
  const communityConfig = useSelector((state) => state?.community?.communityConfig);
  const audioReducer = useSelector(getAudioReducer);
  const { connected } = audioReducer;

  // lifecycles
  useEffect(() => {
    if (Array.isArray(groups)) {
      setMainGroup(groups.find((d) => d.main_group));
      setSubGroups(sortByOrder(groups.filter((d) => !d.main_group)));
    }
  }, [groups]);

  // helpers
  const sortByOrder = (items) => {
    const sortedGroups = sortBy(items, 'position');
    return sortedGroups;
  };

  const handleSwitchGroup = (groupId, data) => {
    const isLocked = data?.locked;
    const isNotActiveGroupId = activeGroupId !== groupId;
    const isConnected = connected === true;

    if (isLocked) {
      NotificationService.error('You don’t have access to this Premium Group.');
      return;
    }

    if (isNotActiveGroupId) {
      if (isConnected) {
        setOpenStageConfirm({
          open: true,
          groupId,
        });
      } else {
        dispatch(setActiveGroup(activeCommunity, groupId, true));
        history.push('/');
      }
    }
  };

  const handleHoverGroup = (groupId) => {
    setActiveHoverGrp(groupId);
  };

  // drag helpers
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result?.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;

    const reorderedSubGroups = reorder(subGroups, sourceIndex, destIndex);
    const indexReorderedSubGroups = reorderedSubGroups.map((item, index) => ({ ...item, position: index + 1 }));

    if (destIndex === sourceIndex) {
      return;
    }

    let finalPosition = null;
    if (destIndex === 0) {
      finalPosition = 2;
    } else if (destIndex < sourceIndex) {
      // Drop above
      finalPosition = reorderedSubGroups[destIndex + 1].position;
    } else if (indexReorderedSubGroups.length - 1 === destIndex) {
      finalPosition = reorderedSubGroups[destIndex - 1].position;
    } else {
      finalPosition = reorderedSubGroups[destIndex - 1].position;
    }

    if (result.type === 'droppableSubGrp') {
      setSubGroups(indexReorderedSubGroups);
      updateGroupPositionService(activeCommunity, result?.draggableId, finalPosition).then(() => {
        // refetch grps latest positions
        if (activeCommunityDetails?.enable_groups) {
          dispatch(fetchGroups());
        } else {
          history.push('/community-settings');
        }
      });
    }
  };

  // constants
  const navClassNames = `bg-gray-800 overflow-y-auto h-screen fixed overflow-auto ${
    audioReducer?.isFullScreen === true ? 'hidden' : ''
  }`;

  // render helpers
  const renderMainGroup = () => {
    const imageUrl = imageFetch(mainGroup?.icon, {
      resize: {
        height: USER_IMAGE_TYPE_3.HEIGHT,
        width: USER_IMAGE_TYPE_3.WIDTH,
        fit: 'inside',
      },
    });

    return (
      <>
        <span
          key={mainGroup?.id}
          onClick={() => handleSwitchGroup(mainGroup?.id, mainGroup)}
          className={classNames(
            activeGroupId === mainGroup?.id && 'border border-gray-50 narrow__sidebar__active--group--hover',
            'flex-shrink-0 inline-flex items-center justify-center h-11 w-11 rounded-lg cursor-pointer',
          )}
        >
          <span className="sr-only">{mainGroup?.name}</span>
          <img src={imageUrl} className="h-10 w-10 rounded-lg" alt="community-icon" loading="lazy" />
        </span>
        <div className="relative w-full px-1">
          <div className="absolute inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t-2 border-gray-600" />
          </div>
        </div>
      </>
    );
  };

  const renderPlucIcon = () => {
    const handleClick = () => history.push('/community-settings/create-group');

    return (
      <Subscription
        componentSubscriptionId={FEATURES_FLAG_LIST.MAX_GROUPS}
        replacementId={SUBSCRIPTION_LOCKED_UI_ID.GROUP_LOCK}
      >
        <a
          onClick={handleClick}
          className="border border-gray-50 flex-shrink-0 inline-flex items-center justify-center h-11 w-11 rounded-lg cursor-pointer"
          aria-hidden="true"
        >
          <PlusIcon className="h-6 w-6 rounded-lg" color="#F9FAFB" />
        </a>
      </Subscription>
    );
  };

  const renderSubGroups = () => {
    const canManageCommunity = communityConfig?.canManageCommunity;

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppableGrp" type="droppableSubGrp">
          {(provided, parentSnapshot) => (
            <div
              ref={provided.innerRef}
              className={`${parentSnapshot.isDraggingOver ? 'text-gray-400 text__info' : 'text-gray-900 text__title'}`}
            >
              {subGroups.map((item, idx) => (
                <Draggable
                  key={item?.id}
                  draggableId={String(item?.id)}
                  index={idx}
                  isDragDisabled={!communityConfig?.canManageCommunity}
                >
                  {(provided, categoryChildSnapshot) => (
                    <span
                      onMouseEnter={() => handleHoverGroup(item.id)}
                      onMouseLeave={() => handleHoverGroup(null)}
                      key={item?.id}
                      onClick={() => handleSwitchGroup(item.id, item)}
                      className={classNames(
                        (activeGroupId === item?.id || activeHoverGrp === item.id) &&
                          'border border-gray-50 narrow__sidebar__active--group--hover',
                        categoryChildSnapshot.isDragging && 'text-gray-900 text__title',
                        'flex-shrink-0 inline-flex items-center justify-center h-11 w-11 rounded-lg cursor-pointer relative',
                      )}
                      aria-hidden="true"
                      data-tooltip-content={item?.name}
                      data-tooltip-position-strategy="fixed"
                      data-tooltip-offset={20}
                      data-tooltip-id={item?.name}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <span className="sr-only">{item?.name}</span>
                      <img src={imageFetch(item.icon)} className="h-10 w-10 rounded-lg" alt="grp-icon" loading="lazy" />
                      <ReactTooltip id={item?.name} place="right" effect="solid" />
                    </span>
                  )}
                </Draggable>
              ))}
            </div>
          )}
        </Droppable>
        {/* Add New Group Button */}
        {canManageCommunity ? renderPlucIcon() : null}
      </DragDropContext>
    );
  };

  return (
    <nav className={navClassNames}>
      <div className="relative w-16 flex flex-col p-2 space-y-3 items-center">
        {mainGroup ? renderMainGroup() : null}
        {renderSubGroups()}
      </div>
      <StageConfirm
        audioReducer={audioReducer}
        open={openStageConfirm.open}
        groupId={openStageConfirm.groupId}
        onClose={() => setOpenStageConfirm(initialOpenStageConfirm)}
        activeCommunity={activeCommunity}
      />
    </nav>
  );
}
