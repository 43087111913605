import {
  SET_EVENT_SOURCE,
  SET_EVENT_TEMP_DATA,
  UPDATE_CREATE_EVENT_MODAL,
  SET_EVENT_ID,
  RESET_CREATE_EVENT_STATE,
  UPDATE_CALENDAR_EVENT_LIST,
  SET_ZOOM_CALENDAR_EVENT,
  SET_CALENDAR_CHANNELS,
} from './actionTypes';

export const updateCreateCalenderModal = (payload) => ({
  type: UPDATE_CREATE_EVENT_MODAL,
  payload,
});

export const setEventTempData = (payload) => ({
  type: SET_EVENT_TEMP_DATA,
  payload,
});

export const setEventSource = (payload) => ({
  type: SET_EVENT_SOURCE,
  payload,
});

export const setZoomCalendarEvent = (payload) => ({
  type: SET_ZOOM_CALENDAR_EVENT,
  payload,
});

export const setCalendarChannels = (payload) => ({
  type: SET_CALENDAR_CHANNELS,
  payload,
});

export const setEventId = (payload) => ({
  type: SET_EVENT_ID,
  payload,
});

export const resetCreateEventState = () => ({
  type: RESET_CREATE_EVENT_STATE,
});

export const updateCalendarEventList = (payload) => ({
  type: UPDATE_CALENDAR_EVENT_LIST,
  payload,
});