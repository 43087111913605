import { ArrowsExpandIcon, XIcon } from '@heroicons/react/outline';
import { PhotographIcon, PlusIcon, VideoCameraIcon } from '@heroicons/react/solid';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import FileInputPicker from '../../../components/shared/FileInputPicker';
import { classNames, getButtonCSS } from '../../../constants/utils';
import { getActiveChannelId } from '../../../selectors/ChannelSelectors';
import {
  getActiveCommunity,
  getActiveCommunityAccentColor,
  getActiveCommunityFontsClass,
  getActiveCommunityViaPublicCommunity,
  getActiveGroupId,
} from '../../../selectors/CommunitySelectors';
import { imageFetch } from '../../../services/preSignedAws';
import ImagePickerForumPost from '../MediaResources/ImagePickerForumPost';
import NotificationService from '../../../services/notificationService';
import { MODAL_TYPE } from '../../../constants/pricingConstants';
import LightBoxWrapper from "../../../components/shared/light-box/LightBoxWrapper";

let heic2any = null;
(async () => {
  heic2any = (await import('heic2any')).default;
})();

export function AttachImage({
  setFieldValue, currentImageUrl, isLoading, setIsLoading, setRawImage, rawImage, type, imageDimensions, setImageDimensions, channelId=null, setAddedImage={file: null, url: ''}
}) {
  const [showImagePicker, setShowImagePicker] = useState(false);
  const [imageViewer, setImageViewer] = useState(null);
  const activeChannelId = useSelector(state => state.channel?.activeChannelData?.id) || channelId;

  const handleResetPicker = () => {
    setRawImage(null);
  };

  const handleUploadPhotoClick = () => {
    setShowImagePicker(true);
  };

  const onImageCropped = (imgURL) => {
    setFieldValue('image_url', imgURL);
    setAddedImage(file => ({...file, url: imgURL}))
  };

  const onImageLoading = (loading) => {
    setIsLoading(loading);
  };

  const handleRemoveImage = () => {
    setFieldValue('image_url', null);
    setRawImage(null);
    setAddedImage(file => ({file: null, url: ''}))
  };
  if (isLoading) {
    return (
      <div className="attach-image-wrapper">
        <div
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            position: 'relative',
          }}
          className="w-56 h-56"
        >
          <div className="flex justify-center items-center m-auto absolute h-full w-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10 opacity-60 bg-gray-300">
            <div className="animate-spin rounded-full h-6 w-6 border-t-2 border-b-2 border-black" />
          </div>
        </div>
      </div>
    );
  }


  const presignedParamsObj = type === MODAL_TYPE.REPORT_MODAL ? {type: "community" } : {
    type: 'channel',
    channel_id: activeChannelId,
  }

  const handleUploadImage = (file) => {
    setRawImage(file);
    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;

      img.onload = () => {
        const width = img.width;
        const height = img.height;
        setImageDimensions({ width, height });
      };
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="attach-image-wrapper">
      {imageViewer && (
      <LightBoxWrapper
        mainSrc={imageViewer}
        onCloseRequest={() => setImageViewer(false)} />
      )}
      {currentImageUrl ? (
        <div
          style={{
            backgroundColor: 'rgba(0, 0, 0, 0.9)',
            position: 'relative',
          }}
          className="w-56 h-56"
        >
          <div
            style={{
              backgroundImage: `url(${imageFetch(currentImageUrl)})`,
              height: '100%',
              width: '100%',
              backgroundPosition: 'center',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
            }}
          />
          <div className="flex justify-center items-center m-auto absolute top-2 right-4">
            <ArrowsExpandIcon
              className="h-6 w-6 text-white bg-gray-900 flex-none rounded-full p-1 ltr:mr-1 rtl:ml-1  cursor-pointer"
              onClick={() => {
                setImageViewer(imageFetch(currentImageUrl));
              }}
            />
            <XIcon
              className="h-6 w-6 text-white bg-gray-900 flex-none rounded-full p-1 cursor-pointer"
              onClick={handleRemoveImage}
            />
          </div>
        </div>
      ) : (
        !rawImage && (
          <button className="btn__light rounded-md" onClick={handleUploadPhotoClick} type="button">
            <FileInputPicker
              onChange={async (e) => {
                setIsLoading(true);
                const file = e.target.files[0];
                if (file.size <= 10000000) {
                  if (file && (file.name.includes('.heic') || file.name.includes('.HEIC'))) {
                    const newImage = await heic2any({ blob: file, toType: 'image/jpeg', quality: 1 });
                    setRawImage(new File([newImage], 'tempImage.jpeg'));
                    e.target.value = '';
                    setIsLoading(false);
                  } else {
                    handleUploadImage(file)
                    e.target.value = '';
                    setIsLoading(false);
                  }
                } else {
                  let sizeString = '';
                  if (file.size < 1000) {
                    sizeString = `${file.size} byte`;
                  } else if (file.size < 1000000) {
                    sizeString = `${(file.size / 1000).toFixed(2)} KB`;
                  } else {
                    sizeString = `${(file.size / 1000000).toFixed(2)} MB`;
                  }
                  setIsLoading(false);
                  NotificationService.error(
                    `Image size should be less than 10 mb(current size of cropped image is ${sizeString})`,
                  );
                }
              }}
              id="attach_img"
              disabled={isLoading}
            >
              <div className="flex justify-center px-4 py-2 cursor-pointer">
                <PhotographIcon className="h-5 w-5 ltr:mr-2 rtl:ml-2" />
                <div className="attach-image-text cursor-pointer">{$translatei18n('Add Image')}</div>
              </div>
            </FileInputPicker>
          </button>
        )
      )}

      {!currentImageUrl && rawImage && (
          <ImagePickerForumPost
            // cropOptions={{ unit: '%', width: 50, aspect: 724 / 385 }}
            onImageCropped={onImageCropped}
            onImageLoading={onImageLoading}
            showImagePicker={showImagePicker}
            setShowImagePicker={setShowImagePicker}
            returnRelativeImage
            fileData={rawImage}
            setRawImage={setRawImage}
            handleResetPicker={handleResetPicker}
            isLoading={isLoading}
            isCta={true}
            presignedParams={presignedParamsObj}
            imageDimensions={imageDimensions}
            setImageDimensions={setImageDimensions}
          />
      )}
    </div>
  );
}

function ForumCreatePost() {
  const activeChannelId = useSelector(getActiveChannelId);
  const activeCommunityAccentColor = useSelector(getActiveCommunityAccentColor);
  const currentActiveCommunity = useSelector(getActiveCommunity);
  const { primaryFont } = useSelector(getActiveCommunityFontsClass);
  const getActiveCommunityPublic = useSelector(getActiveCommunityViaPublicCommunity);
  const history = useHistory();
  const activeGroupId = useSelector(getActiveGroupId);

  if (!activeChannelId) {
    return null;
  }

  const redirectToCreate = () => {
    history.push(
      `/communities/${currentActiveCommunity?.id}/group/${activeGroupId}/channels/${activeChannelId}/forum/create`,
      {
        channel_id: activeChannelId,
        community_id: currentActiveCommunity?.id,
      },
    );
  };

  return null;

  // return (
  //   <div className="forum-create-post xs:mr-0">
  //     <button
  //       style={getButtonCSS(activeCommunityAccentColor)}
  //       className={classNames(
  //         'font-Geomanist border border-transparent p-2 md:py-2 md:px-4 md:w-24 rounded inline-flex justify-center items-center btn__primary',
  //         getActiveCommunityPublic == 3764 ? '' : 'mr-2 md:mr-8',
  //         primaryFont,
  //       )}
  //       onClick={redirectToCreate}
  //       type="button"
  //     >
  //       <PlusIcon className="w-4 h-4" />
  //       <span className="hidden ltr:ml-1 rtl:mr-1 text-sm md:block font-semibold"> {$translatei18n('Post')}</span>
  //     </button>
  //   </div>
  // );
}

export default ForumCreatePost;
