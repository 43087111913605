import { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AnalyticService from '../services/AnalyticsService';
import CustomRoute from '../routes/CustomRoute';
import { Switch } from 'react-router-dom';
import indexRoutes from '../routes';
import tinycolor from 'tinycolor2';

const CustomRoutesContainer = () => {
  const [trackingSession, setTrackingSession] = useState(false);
  const user = useSelector((state) => state.user);
  const publicCommunityDetails = useSelector((state) => state.community?.publicCommunityDetails);
  const userRoles = useSelector(state => state?.community?.communityUserRoles)
  const userRolesArr = userRoles?.map(item => item?.role) 
  const logSessionBeforeUnload = (eventProperties = {}) => {
    const logEvent = () => {
      AnalyticService.logMixpanelEvent('Community Session Duration', eventProperties);
      const StageIsConnected = JSON.parse(sessionStorage.getItem('StageIsConnected') || false);
      if (StageIsConnected) {
        AnalyticService.logMixpanelEvent('Stage Connected Duration', eventProperties);
      }
    };
    window.addEventListener('beforeunload', logEvent);
    return window.removeEventListener('beforeunload', logEvent);
  };

  useEffect(() => {
    const isLightIntervalId = setInterval(() => {
      const mainBGElement = document.querySelector('.main--background');
      if (mainBGElement) {
        let computedStyles = window.getComputedStyle(mainBGElement);
        let backgroundColor = computedStyles.backgroundColor;
        const isLight = tinycolor(backgroundColor).isLight();
        localStorage.setItem('isLightTheme', isLight);
        clearInterval(isLightIntervalId);
      }
    }, 1000);

    return () => {
      if (isLightIntervalId) {
        clearInterval(isLightIntervalId);
      }
    };
  }, []);

  useEffect(() => {
    if (user?.name && publicCommunityDetails?.name && !trackingSession) {
      setTrackingSession(true);
      const eventProperties = {
        'Community ID': publicCommunityDetails.id,
        'Community Name': publicCommunityDetails.name,
        Username: user.username,
      };
      sessionStorage.setItem('MixpanelSessionProperties', JSON.stringify(eventProperties));
      AnalyticService.setAmplitudeIdentity(user?.id);
      AnalyticService.setAmplitudeUserProperty(user);
      AnalyticService.setMixpanelIdentify(user.id);
      AnalyticService.setUserAttributes({...user,  roleList: userRolesArr});
      AnalyticService.logMixpanelEvent('Community Session', eventProperties);
      AnalyticService.logMixpanelTimingEvent('Community Session Duration');
      logSessionBeforeUnload(eventProperties);
    }
  }, [user, publicCommunityDetails]);

  return (
    <Switch>
      {indexRoutes.map((route) => (
        <CustomRoute
          key={route.path}
          path={route.path}
          component={route.component}
          skipCommunityMemberVerification={route.skipCommunityMemberVerification}
          exact={route.exact}
          isProtected={route.isProtected}
        />
      ))}
    </Switch>
  );
};

export default CustomRoutesContainer;
