import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import { Fragment } from 'react';
import { InformationCircleIcon } from '@heroicons/react/solid';
export default function ExitPromptLiveEvent({ open, infoText, disConnectStage, endEvent, onClose }) {
  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" onClose={onClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center md:block md:p-0">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={onClose} />
          </TransitionChild>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden md:inline-block md:align-middle md:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            enterTo="opacity-100 translate-y-0 md:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 md:scale-100"
            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
          >
            <div className="inline-block align-bottom bg-white justify-center rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all md:my-8 md:align-middle md:max-w-lg md:w-full md:p-6">
              <div className="justify-center align-item-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2  md:ml-3 md:w-auto md:text-sm">
                <InformationCircleIcon className="h-14 w-14 text-gray-700" aria-hidden="true" />
              </div>
              <div className="md:flex md:items-start">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-gray-600 text-base font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 md:ml-3 md:w-auto md:text-sm"
                  onClick={disConnectStage}
                >
                  Disconnect from Stage
                </button>
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-pink-600 text-base font-medium text-white hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500 md:ml-3 md:w-auto md:text-sm"
                  onClick={endEvent}
                >
                  End Event & Disconnect
                </button>
              </div>
              <div className="md:flex md:items-start">
                <div className="mt-3 text-center md:mt-0 md:ml-4 md:text-left">
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{infoText}</p>
                  </div>
                </div>
              </div>
              <div className="mt-5 md:mt-4 md:flex md:flex-row-reverse">
                {/* <ConfirmationButton onConfirm={onConfirm} /> */}
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 md:mt-0 md:w-auto md:text-sm"
                  onClick={onClose}
                >
                  {$translatei18n('Cancel')}
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
}
