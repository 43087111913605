import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import CoinbgIcon from '../../assets/images/coin-system/coinbg.png';
import { imageFetch } from '../../services/preSignedAws';
import CommonNameAvatar, {
  COMMON_NAME_AVATAR_SIZE,
  COMMON_NAME_AVATAR_TYPE,
} from '../../components/shared/tailwind/NameAvatar';
import NotificationService from '../../services/notificationService';
import { sendCoinTip } from '../../services/CoinSystemServices';
import { updateCoinCount } from '../../store/actions/channelActions';
import { getActiveCommunityFontsClass } from '../../selectors/CommunitySelectors';
import { classNames, parseAxiosErrorMessage } from '../../constants/utils';
import { ERROR_MESSAGES } from '../../constants/notificationMessages';
import { getCommonAvtarName, getFullName } from '../../utils/Utils';

function TipCoin({ openTipCoin, setOpenTipCoin, userData, coinsCount, activeCommunity, communityOwner }) {
  const [coinsToSend, setCoinsToSend] = useState(0);
  const [sendingCoinsStatus, setSendingCoinsStatus] = useState('');
  const dispatch = useDispatch();
  const { primaryFont } = useSelector(getActiveCommunityFontsClass);
  const [isImageLoadingError, setIsImageLoadingError] = useState(false);

  useEffect(() => {
    if (openTipCoin && sendingCoinsStatus !== '') {
      setSendingCoinsStatus('');
    }
  }, [openTipCoin]);

  const renderUserInfo = () => {
    if (!userData || !userData?.data) return <></>;

    const { data, followed } = userData;

    const isCoolHuman = data?.user['is_cool_human?'];
    const coolBadgeUrl = data?.user.cool_human_badge_url;
    const coolCoverGif = data?.user.cover_gif || '';

    const name = getFullName(data.user.first_name, data.user.last_name);
    const username = `${data.user.username}`;

    return (
      <>
        <div
          className="relative h-52 bg-gray-200 bg-cover bg-no-repeat"
          style={{
            borderRadius: '5px',
            backgroundImage: `url(${isCoolHuman && coolCoverGif ? coolCoverGif : ''})`,
          }}
        >
          <div className="absolute -bottom-14 left-0 right-0 flex justify-center">
            {data?.user?.image && !isImageLoadingError ? (
              <img
                className="inline-block h-28 w-28 rounded-full"
                src={imageFetch(data?.user?.image)}
                alt="profile"
                onError={() => setIsImageLoadingError(true)}
              />
            ) : (
              <CommonNameAvatar
                type={COMMON_NAME_AVATAR_TYPE.CIRCULAR}
                size={COMMON_NAME_AVATAR_SIZE.XXL}
                name={getCommonAvtarName(data?.user?.first_name, data?.user?.last_name)}
              />
            )}
          </div>
        </div>
        <div className="text-center mt-16">
          <p className="text-lg mb-2 text__title">{name}</p>
          <p className="text-sm text__description mb-4">{`@${username}`}</p>
          <p className="text-sm text__body">{data?.user?.about || ''}</p>
        </div>
      </>
    );
  };

  const validateCoins = () => {
    if (coinsToSend <= 0) {
      NotificationService.error('Please Enter coins to send');
      return false;
    } else if (coinsToSend > coinsCount && !communityOwner) {
      NotificationService.error(`You do not have sufficient balance to send coins`);
      return false;
    }
    return true;
  };

  const sendCoins = async () => {
    if (validateCoins() === true) {
      setSendingCoinsStatus('loading');
      try {
        const postValue = {
          user_id: userData?.data?.user?.id,
          coins: parseInt(coinsToSend),
        };
        const res = await sendCoinTip(activeCommunity.id, postValue);
        const { status } = res;
        if (status === 204) {
          setSendingCoinsStatus('success');
            const countRemaining = parseInt(coinsCount) - parseInt(coinsToSend);
            dispatch(updateCoinCount(countRemaining));
        }
      } catch (error) {
        NotificationService.error(parseAxiosErrorMessage(error));
        setSendingCoinsStatus('');
      }
    }
  };

  return (
    <Transition show={openTipCoin} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-hidden bg-gray-700 bg-opacity-70" onClose={() => setOpenTipCoin(false)}>
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute inset-0" onClick={() => setOpenTipCoin(false)} />

          <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div id="scroll-content" className="h-full flex flex-col py-6 sidepanel__container shadow-xl overflow-y-scroll">
                  <div className="px-4 sm:px-6">
                    <div className={classNames('flex items-start justify-between', primaryFont)}>
                      {sendingCoinsStatus === 'success' ? (
                        <DialogTitle className="text-lg text__title">Transaction Successfull</DialogTitle>
                      ) : (
                        <DialogTitle className="text-lg text__title">
                          {$translatei18n('Send')} {activeCommunity?.coin_abbreviation} to{' '}
                          {getFullName(userData?.data?.user?.first_name, userData?.data?.user?.last_name)} ?
                        </DialogTitle>
                      )}
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          className="rounded-md focus:outline-none focus:ring-0 focus:ring-offset-0"
                          onClick={() => setOpenTipCoin(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6 main__icon" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 relative flex-1 px-4 sm:px-6">
                    <div className={`absolute inset-0 px-4 sm:px-6 ${sendingCoinsStatus === '' ? 'block' : 'hidden'}`}>
                      {renderUserInfo()}
                      <div className="mt-10">
                        <div className="mb-10">
                          <label
                            htmlFor="coinsToSend"
                            className={classNames('block text-sm font-medium text__body', primaryFont)}
                          >
                            {$translatei18n('EnterTheNumberOf')} {activeCommunity.coin_abbreviation} {$translatei18n('ToSend')}
                          </label>
                          <div className="mt-2">
                            <input
                              id="coinsToSend"
                              name="coinsToSend"
                              type="number"
                              value={coinsToSend}
                              onChange={(e) => setCoinsToSend(e.target.value)}
                              className="shadow-sm block w-full sm:text-sm border input card rounded-md"
                            />
                          </div>
                        </div>
                        <button
                          type="button"
                          className="inline-flex w-full justify-center items-center px-6 py-3 border border-transparent text-base leading-4 font-medium rounded-md focus:outline-none focus:ring-0 focus:ring-offset-0 shadow btn__primary"
                          style={{ backgroundColor: activeCommunity.accent_color }}
                          onClick={sendCoins}
                        >
                          <div className="relative ltr:mr-2 rtl:ml-2">
                            <img src={CoinbgIcon} className="h-5 w-5" />
                            <img
                              src={imageFetch(activeCommunity?.coin_icon, {}, false)}
                              className="absolute h-3 w-3 inset-0 m-auto rounded-full opacity-50"
                            />
                          </div>
                          {$translatei18n('Send')} {coinsToSend} {activeCommunity.coin_abbreviation}
                        </button>
                        <button
                          type="button"
                          className="inline-flex w-full justify-center items-center mt-4 px-6 py-3 text-base leading-4 font-medium rounded-md shadow focus:outline-none focus:ring-0 focus:ring-offset-0 btn__light"
                          onClick={() => setOpenTipCoin(false)}
                        >
                          {$translatei18n('CancelTransaction')}
                        </button>
                      </div>
                    </div>

                    {/* For SuccessFull Transaction */}
                    <div
                      className={`absolute inset-0 px-4 sm:px-6 flex justify-center items-center ${
                        sendingCoinsStatus === 'success' ? 'block' : 'hidden'
                      }`}
                    >
                      <div className="flex flex-col items-center text__body">
                        <div className="p-5 highlighted rounded-2xl w-16 h-16 text-xl mb-6">👍</div>
                        <div className={primaryFont}>
                          {coinsToSend} {activeCommunity.coin_abbreviation} sent succesfully!
                        </div>
                      </div>
                    </div>

                    {/* Loading */}
                    <div
                      className={`absolute inset-0 px-4 sm:px-6 flex justify-center items-center ${
                        sendingCoinsStatus === 'loading' ? 'block' : 'hidden'
                      }`}
                    >
                      <div className="flex flex-col items-center">
                        <div
                          className="
                      animate-spin
                      rounded-full
                      h-10
                      w-10
                      border-t-2 border-b-2 border-gray-400 mb-8
                  "
                        ></div>
                        <div className={primaryFont}>Sending {activeCommunity?.coin_abbreviation}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default TipCoin;
