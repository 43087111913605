import { Fragment, useState } from 'react';
import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

/*
list: [{label: '', value: ''}]
*/
export default function CommonSelect({ onChange, selected, list, disabled, name, label }) {
  return (
    <Listbox value={selected} onChange={onChange} disabled={disabled} refName={name}>
      {({ open }) => (
        <>
          {label && <Label className="block text-sm font-medium text__body mb-1">{label}</Label>}
          <div className="relative">
            <ListboxButton className="select__button relative w-full border rounded-md shadow-sm ltr:pl-3  rtl:pr-3 ltr:pr-10 rtl:pl-10 py-2 text-left cursor-default focus:outline-none focus:ring-0 sm:text-sm">
              <span className="block truncate">{selected}</span>
              <span className="absolute inset-y-0 right-0 flex items-center ltr:pr-2 rtl:pl-2 pointer-events-none">
                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
              </span>
            </ListboxButton>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <ListboxOptions className="absolute z-20 mt-1 w-full shadow-lg max-h-60 rounded-md py-1 text-base select__options__container border overflow-auto focus:outline-none sm:text-sm">
                {list.map((data, index) => (
                  <ListboxOption
                    key={index}
                    className={({ active }) =>
                      classNames(
                        active && 'select__option--active',
                        'cursor-default select-none relative py-2 ltr:pl-3  rtl:pr-3 ltr:pr-9 rtl:pl-9 select__option',
                      )
                    }
                    value={data.value}
                  >
                    {({ selected, active }) => (
                      <>
                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                          {data.label}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              // active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center ltr:pr-4 rtl:pl-4',
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </ListboxOption>
                ))}
              </ListboxOptions>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}
