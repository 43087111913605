import React, {useEffect} from 'react';
import TopHeader, { TOP_HEADER_TYPE } from '../layout/Header';
import { useSelector } from 'react-redux';
import { CustomHeaderTitle } from '../inbox';
import NotificationList from '../../components/notification/NotificationList';
import {getActiveCommunity} from "../../selectors/CommunitySelectors"

const Notification = () => {
  const activeCommunity = useSelector((state) => state?.community?.activeCommunity);
  const currentActiveCommunity = useSelector(getActiveCommunity);

  return (
    <div>
      <TopHeader
        headerType={TOP_HEADER_TYPE.INBOX}
        headerTitle={<CustomHeaderTitle communityId={activeCommunity} />}
      />
      
       <NotificationList communityId={currentActiveCommunity?.id} page={true} />
    </div>
  );
}

export default Notification;
