import server from '../api/server';
import serverLess from '../api/serverVersionless';

export const updateUser = (data) => new Promise((resolve, reject) => {
  server
    .post('/user_steps/add_details', data)
    .then(() => {
      resolve(data?.account_name ? data : data?.user);
    })
    .catch(reject);
});

export const updatePassword = ({
  oldPassword,
  newPassword,
  confirmNewPassword,
}) => new Promise((resolve, reject) => {
  server
    .post('/users/passwords/reset', {
      old_password: oldPassword,
      new_password: newPassword,
      confirm_new_password: confirmNewPassword,
    })
    .then((res) => {
      resolve(res);
    })
    .catch(reject);
});

export const followUser = (follow) => new Promise((resolve, reject) => {
  serverLess.post('/follows', { follow })
    .then((response) => {
      resolve(response);
    })
    .catch(reject);
});

export const unfollowUser = (follow, userId) => new Promise((resolve, reject) => {
  serverLess.delete(`/follows/${userId}`, { follow })
    .then((response) => {
      resolve(response);
    })
    .catch(reject);
});

export const removeFollower = (userId, communityId) => new Promise((resolve, reject) => {
  serverLess.delete(`/remove_follower/${userId}?community_id=${communityId}`)
    .then((response) => {
      resolve(response);
    })
    .catch(reject);
});


export const fetchFollowers = (nextPageId = '') => new Promise((resolve, reject) => {
  serverLess.get(`follows?next_page_id=${nextPageId}&type=followers`)
    .then((response) => {
      resolve(response);
    })
    .catch(reject);
});

export const fetchFollowees = (nextPageId = '') => new Promise((resolve, reject) => {
  serverLess.get(`follows?next_page_id=${nextPageId}`)
    .then((response) => {
      resolve(response);
    })
    .catch(reject);
});

export const fetchUserProfile = (userId, communityId) => new Promise((resolve, reject) => {
  serverLess
    .get(`/profile/${userId}?community_id=${communityId}`, {
    })
    .then((response) => {
      resolve(response.data);
    })
    .catch(() => reject(new Error('User not exist')));
});


export const fetchUserPosts = (activeCommunityId, userId, page) => new Promise((resolve, reject) => {
  server
    .get(`/users/profiles/list_posts?community_id=${activeCommunityId}&user_id=${userId}&page=${page}&profile_version=1`, {
    })
    .then((response) => {
      resolve(response.data);
    })
    .catch(reject);
});

export const updateUserLanguagePreference = (communityId, language) => new Promise((resolve, reject) => {
  const user = { language: language?.value }
  server
    .put(`/users/profiles/language?community_id=${communityId}`, { user })
    .then((data) => {
      resolve(data);
    })
    .catch(reject);
});
