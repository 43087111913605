import {
  ADD_CALENDAR_PERMISSIONS,
  UPDATE_CREATE_EVENT_MODAL,
  GET_CALENDER_EVENT_SUCCESS,
  SET_CALENDER_EVENT_LOADING,
  SET_EVENT_TEMP_DATA,
  GET_ZOOM_SIGNATURE_SUCCESS,
  UPDATE_CALENDAR_EVENT_LIST,
  GET_ZOOM_EVENT_SUCCESS,
  GET_STREAMING_EVENT_SUCCESS,
  GET_CALENDER_EVENT_REMINDER,
} from '../actions/actionTypes';
import { getCalendarPermissionConfig, getChannelPermissionsConfig } from '../models/community';

const initialState = {
  channelPermissionsConfig: {},
  activeCalendarEvent: null,
  channelDetails: {},
  stageChannelDetails: {},
  loading: false,
  stagePremissionsConfig: {},
  enableCreateEventModal: false,
  eventTempData: null,
  zoomToken: null,
  zoom_ZAK_Token: null,
  updateList: false,
  zoomEvent: null,
  streamingEvent: null,
  reminderDetails: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CALENDAR_PERMISSIONS:
      return {
        ...state,
        channelPermissionsConfig: getCalendarPermissionConfig(action.channelPermissions),
        stagePremissionsConfig: action.stagePermissions ? getChannelPermissionsConfig(action.stagePermissions) : {},
      };
    case GET_CALENDER_EVENT_SUCCESS:
      return {
        ...state,
        activeCalendarEvent: action.payload,
        channelDetails: action.channelDetails,
        stageChannelDetails: action.stageChannelDetails,
      };
    case GET_ZOOM_SIGNATURE_SUCCESS:
      return {
        ...state,
        zoomToken: action.zoomToken,
        zoom_ZAK_Token: action.zoomZAK,
      };
    case GET_ZOOM_EVENT_SUCCESS:
      return {
        ...state,
        zoomEvent: action.payload,
      };
    case GET_STREAMING_EVENT_SUCCESS:
      return {
        ...state,
        streamingEvent: action.payload,
      };
    case SET_CALENDER_EVENT_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case UPDATE_CREATE_EVENT_MODAL:
      return {
        ...state,
        enableCreateEventModal: action?.payload,
      };
    case UPDATE_CALENDAR_EVENT_LIST:
      return {
        ...state,
        updateList: action?.payload,
      };

    case SET_EVENT_TEMP_DATA:
      return {
        ...state,
        eventTempData: action?.payload,
      };
    case GET_CALENDER_EVENT_REMINDER:
      return {
        ...state,
        reminderDetails: action?.payload,
      }
    default:
      return state;
  }
};

export default reducer;
