import {
  SET_DRAFT_POSTS,
  SET_SCHEDULED_POSTS,
  SET_SCHEDULED_DRAFTS_COUNT,
  SET_SELECTED_SCHEDULED_AND_DRAFTS_DATA,
  SET_POLL_OPTIONS,
  SET_VIEW_ALL,
  SET_DRAFTS_PAGE_MANAGER,
  SET_SCHEDULE_PAGE_MANAGER,
} from '../actions/actionTypes';

export const initScheduledAndDrafts = {
  scheduledPosts: null,
  draftPosts: null,
  selectedScheduledAndDraftData: null,
  scheduledAndDrafts: null,
  pollOptions: null,
  isViewAll: false,
  schedulePageManager: {
    page: 1,
    totalPages: null,
  },
  draftsPageManager: {
    page: 1,
    totalPages: null,
  },
};

const reducer = (state = initScheduledAndDrafts, action) => {
  switch (action.type) {
    case SET_SCHEDULED_POSTS:
      return {
        ...state,
        scheduledPosts: action.payload,
      };
    case SET_DRAFT_POSTS:
      return {
        ...state,
        draftPosts: action.payload,
      };
    case SET_SELECTED_SCHEDULED_AND_DRAFTS_DATA:
      return {
        ...state,
        selectedScheduledAndDraftData: action.payload,
      };
    case SET_SCHEDULED_DRAFTS_COUNT:
      return {
        ...state,
        scheduledAndDrafts: action.payload,
      };
    case SET_POLL_OPTIONS:
      return {
        ...state,
        pollOptions: action.payload,
      };
    case SET_VIEW_ALL:
      return {
        ...state,
        isViewAll: action.payload,
      };
    case SET_SCHEDULE_PAGE_MANAGER:
      return {
        ...state,
        schedulePageManager: { ...state.schedulePageManager, ...action.payload },
      };
    case SET_DRAFTS_PAGE_MANAGER:
      return {
        ...state,
        draftsPageManager: { ...state.draftsPageManager, ...action.payload },
      };
    default:
      return state;
  }
};

export default reducer;
