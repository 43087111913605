export function postCrossDomainMessage(frameId, msg, hostURL) {
  const win = document.getElementById(frameId)?.contentWindow;
  if (!win) {
    // Show appropriate error
    return;
  }

  win?.postMessage(msg, `https://${hostURL}`);
}

export function createIframe(id, iframeSrc) {
  const iframe = document.createElement('IFRAME');
  iframe.id = id;
  iframe.style.display = 'none';
  iframe.src = iframeSrc;
  document.body.appendChild(iframe);
}
