import { Route } from 'react-router-dom';
import CommunityMemberVerification from './CommunityMemberVerification';
import ProtectedRoute from './ProtectedRoute';
import ZohoChatbot from '../components/chatbot/ZohoChatbot';
import { useSelector } from 'react-redux';
import { HeadInfo } from '../components/HeadInfo';

const CustomRoute = ({ component: Component, isProtected, skipCommunityMemberVerification, ...rest }) => {
  const isOwner = useSelector((state) => state?.community?.isOwner);

  // Return simple route
  if (!isProtected) {
    if (skipCommunityMemberVerification) {
      return <Route render={(props) => (
        <HeadInfo>
          <Component {...props} />
        </HeadInfo>
      )}  {...rest} />;
    }
    return (
      <Route
        {...rest}
        render={(props) => (
          <HeadInfo>
            <CommunityMemberVerification>
            <Component {...props} />
          </CommunityMemberVerification>
          </HeadInfo>
          
        )}
      />
    );
  }

  if (skipCommunityMemberVerification) {
    return (
      <Route
        {...rest}
        render={(props) => (
          <HeadInfo>
            <ProtectedRoute>
              <Component {...props} />
            </ProtectedRoute>
          </HeadInfo>
        )}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <HeadInfo>
          <ProtectedRoute>
            <CommunityMemberVerification>
              <Component {...props} />
            </CommunityMemberVerification>
            {isOwner ? <ZohoChatbot /> : null}
          </ProtectedRoute>
        </HeadInfo>
      )}
    />
  );
};
export default CustomRoute;
