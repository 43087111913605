import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ErrorMessage, Formik } from 'formik';
import { Dialog, Transition, Listbox, TransitionChild, DialogTitle, ListboxButton, ListboxOptions, ListboxOption } from '@headlessui/react';
import { TrashIcon, CheckIcon, SelectorIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaLink } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { getActiveCommunityFontsClass, getActiveCommunityId } from '../../../selectors/CommunitySelectors';
import { classNames, prepareUserImageName } from '../../../constants/utils';
import { getCurrentLoggedInUser, getCurrentLoggedInUserId } from '../../../selectors/ProfileSelectors';
import { imageFetch } from '../../../services/preSignedAws';
import { CustomErrorMessage } from '../../../components/shared/ErrorMessage';
import { updateUser } from '../../../store/actions/userActions';
import editProfileSchema from '../../../schema/userSchema';
import FileInputPicker from '../../../components/shared/FileInputPicker';
import ImagePickerForumPost from '../MediaResources/ImagePickerForumPost';

import {
  getGradientJPG,
  getRandomGradientColorsArr,
  uploadJpegBase64ToS3,
} from '../../../components/gradient/gradientHelpers';
import CommonNameAvatar, {
  COMMON_NAME_AVATAR_SIZE,
  COMMON_NAME_AVATAR_TYPE,
} from '../../../components/shared/tailwind/NameAvatar';
import { getActiveChannelId } from '../../../selectors/ChannelSelectors';

const GENDER_LIST = ['not_specified', 'male', 'female', 'other'];
const GENDER_OBJ = {
  male: $translatei18n('Male'),
  female: $translatei18n('Female'),
  other: $translatei18n('Other'),
  not_specified: $translatei18n('NotSpecified'),
};

function ProfilePicture({ currentFile, setFieldValue, first_name, last_name }) {
  const { primaryFont } = useSelector(getActiveCommunityFontsClass);
  const [showImagePicker, setShowImagePicker] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [isImageLoadingError, setIsImageLoadingError] = useState(false);
  const defaultURL =
    'https://d2iyvt1bqjp5u8.cloudfront.net/eyJidWNrZXQiOiJtZXRhLXdlYiIsImtleSI6Im9uYm9hcmRpbmcvdXNlci1hdmF0YXIucG5nIiwiZWRpdHMiOnsicmVzaXplIjp7ImhlaWdodCI6MTA4MCwid2lkdGgiOjE5MjAsImZpdCI6Imluc2lkZSJ9fX0=';

  const handleResetPicker = () => {
    setFileData(null);
  };

  const onImageCropped = (imgURL) => {
    setIsImageLoadingError(false);
    setShowImagePicker(false);
    setFieldValue('image', imgURL);
  };

  const onImageLoading = (isLoading) => {
    setLoading(isLoading);
  };

  const handleImageRemove = () => {
    setFieldValue('image', defaultURL);
  };

  return (
    <>
      <label htmlFor="photo" className={classNames('block text-sm font-medium text__title', primaryFont)}>
        {$translatei18n('Profile Picture')}
      </label>
      <div className="mt-1 flex flex-col gap-y-4">
        <span className="h-12 w-12 rounded-full overflow-hidden">
          {currentFile && !isImageLoadingError ? (
            <img
              src={imageFetch(currentFile, {}, true)}
              className="w-full h-full"
              alt="profile pic"
              onError={() => setIsImageLoadingError(true)}
            />
          ) : (
            <CommonNameAvatar
              name={prepareUserImageName(first_name, last_name)}
              type={COMMON_NAME_AVATAR_TYPE.ROUND}
              size={COMMON_NAME_AVATAR_SIZE.LG}
            />
          )}
        </span>
        <div className="flex gap-x-4">
          <FileInputPicker
            onChange={(e) => {
              setFileData(e.target.files[0]);
              e.target.value = '';
            }}
            id="editprofile_pic"
            disabled={loading}
          >
            <div
              className={`py-2 px-3 border btn__light rounded-md shadow-sm text-sm leading-4 font-medium focus:outline-none ${
                loading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={loading}
            >
              {$translatei18n('Upload Photo')}
            </div>
          </FileInputPicker>
          {['onboarding/user-avatar.png', defaultURL].includes(currentFile) ? null : (
            <button
              onClick={handleImageRemove}
              className={`py-2 px-3 border btn__light rounded-md shadow-sm text-sm leading-4 font-medium focus:outline-none ${
                loading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
            >
              {$translatei18n('Remove')}
            </button>
          )}
        </div>
      </div>
      {fileData ? (
        <ImagePickerForumPost
          cropOptions={{ aspect: 1 / 1 }}
          onImageCropped={onImageCropped}
          onImageLoading={onImageLoading}
          showImagePicker={showImagePicker}
          setShowImagePicker={setShowImagePicker}
          returnRelativeImage
          fileData={fileData}
          setRawImage={setFileData}
          handleResetPicker={handleResetPicker}
          isLoading={false}
          isCta={true}
          presignedParams={{
            type: 'user',
          }}
        />
      ) : null}
    </>
  );
}

function ChangeProfileCover({ setFieldValue, currentFile, gradientImage, resetDefaultGradient, removeGradient }) {
  const { primaryFont } = useSelector(getActiveCommunityFontsClass);

  const [showImagePicker, setShowImagePicker] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileData, setFileData] = useState(null);

  const handleResetPicker = () => {
    setFileData(null);
  };

  const onImageCropped = (imgURL) => {
    setShowImagePicker(false);
    setFieldValue('cover_image', imgURL);
  };

  const onImageLoading = (isLoading) => {
    setLoading(isLoading);
  };

  const removeImage = () => {
    if (currentFile) {
      setFieldValue('cover_image', '');
    }
    if (gradientImage) {
      removeGradient();
    }
  };

  return (
    <div>
      <label htmlFor="cover-photo" className={classNames('block text-sm font-medium text__title', primaryFont)}>
        {$translatei18n('ProfileCoverPhoto')}
      </label>
      {loading ? (
        <div className="aspect-w-448 aspect-h-211 overflow-hidden rounded-md w-full mt-2 relative">
          <div className="h-full w-full flex justify-center items-center absolute top-0 left-0 z-10 opacity-50 bg-gray-200">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-700" />
          </div>
        </div>
      ) : currentFile || gradientImage ? (
        <div className="relative">
          <div className="aspect-w-448 aspect-h-211 overflow-hidden rounded-md w-full mt-2">
            {currentFile ? (
              <img src={imageFetch(currentFile, {}, true)} className="block w-full h-full object-cover" alt="cover" />
            ) : (
              <img src={gradientImage} className="block w-full h-full" alt="cover" />
            )}
          </div>
          <button
            type="button"
            className="inline-flex items-center bg-gray-800 rounded-lg p-2 absolute bottom-2 right-2"
            onClick={removeImage}
          >
            <TrashIcon className="w-4 h-4 lg:w-5 lg:h-5 text-red-500" />
            <span className="ml-1 text-sm lg:text-base text-white">{$translatei18n('Remove Profile Cover')}</span>
          </button>
        </div>
      ) : (
        <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md input">
          <div className="space-y-1 text-center">
            <svg
              className="mx-auto h-12 w-12 main__icon"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="flex text-sm">
              <FileInputPicker
                onChange={(e) => {
                  setFileData(e.target.files[0]);
                  e.target.value = '';
                }}
                id="cover_img"
                disabled={loading}
              >
                <div className="relative cursor-pointer rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none">
                  {$translatei18n('UploadAFile')}
                </div>
              </FileInputPicker>
              <p className="px-1 text__body">{$translatei18n('Or')} </p>

              <button
                type="button"
                className="relative cursor-pointer rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none"
                onClick={resetDefaultGradient}
              >
                {$translatei18n('ResetDefaultgradient')}
              </button>
            </div>
            <p className="text-xs text__body">PNG, JPG, GIF {$translatei18n('UpTo')} 10MB</p>
          </div>
        </div>
      )}
      {fileData ? (
        <ImagePickerForumPost
          cropOptions={{ aspect: 4 / 1 }}
          onImageCropped={onImageCropped}
          onImageLoading={onImageLoading}
          showImagePicker={showImagePicker}
          setShowImagePicker={setShowImagePicker}
          returnRelativeImage
          fileData={fileData}
          setRawImage={setFileData}
          handleResetPicker={handleResetPicker}
          isLoading={false}
          isCta={true}
          presignedParams={{
            type: 'user',
          }}
        />
      ) : null}
    </div>
  );
}

export function SelectGender({ setFieldValue, value }) {
  const handleChange = (value) => {
    setFieldValue('gender', value);
  };

  return (
    <Listbox value={value} onChange={handleChange}>
      {({ open }) => (
        <div className="mt-1 relative">
          <ListboxButton className="relative w-full border rounded-md shadow-sm ltr:pl-3  rtl:pr-3 ltr:pr-10 rtl:pl-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 sm:text-sm select__button">
            <span className="block truncate">{GENDER_OBJ[value]}</span>
            <span className="absolute inset-y-0 right-0 flex items-center ltr:pr-2 rtl:pl-2 pointer-events-none">
              <SelectorIcon className="h-5 w-5" aria-hidden="true" />
            </span>
          </ListboxButton>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <ListboxOptions className="absolute z-10 mt-1 w-full select__options__container shadow-lg max-h-60 rounded-md py-1 text-base border overflow-auto focus:outline-none sm:text-sm">
              {GENDER_LIST.map((gender) => (
                <ListboxOption
                  key={gender}
                  className={({ active }) =>
                    classNames(
                      active && 'select__option--active',
                      'cursor-default select-none relative py-2 ltr:pl-3  rtl:pr-3 ltr:pr-9 rtl:pl-9 select__option',
                    )
                  }
                  value={gender}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate capitalize')}
                      >
                        {GENDER_OBJ[gender]}
                      </span>

                      {selected ? (
                        <span className={classNames('absolute inset-y-0 right-0 flex items-center ltr:pr-4 rtl:pl-4')}>
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Transition>
        </div>
      )}
    </Listbox>
  );
}

function EditProfile({ openEditProfile, setOpenEditProfile, activeCommunity }) {
  const dispatch = useDispatch();
  const { primaryFont } = useSelector(getActiveCommunityFontsClass);
  const userData = useSelector(getCurrentLoggedInUser);
  const activeCommunityId = useSelector(getActiveCommunityId);
  const currentLoggedInUserId = useSelector(getCurrentLoggedInUserId);

  const [gradientColors, setGradientColors] = useState([]);
  const [gradientJpeg, setGradientJpeg] = useState('');

  useEffect(() => {
    if (gradientColors?.length) {
      const gradientJpegBase64 = getGradientJPG('to right', gradientColors);
      setGradientJpeg(gradientJpegBase64);
    }
  }, [gradientColors]);

  const resetDefaultGradient = () => {
    setGradientColors(getRandomGradientColorsArr());
  };

  const removeGradient = () => {
    setGradientColors([]);
    setGradientJpeg('');
  };

  const handleOnSubmit = async (values, actions) => {
    actions.setSubmitting(true);
    let { cover_image } = values;
    if (gradientJpeg?.length && !values.cover_image) {
      cover_image = await uploadJpegBase64ToS3(gradientJpeg, true, {
        type: 'user',
      });
    }
    let requestBody = {
      first_name: values.firstname.trim(),
      last_name: values.lastname.trim(),
      username: values.username.trim(),
      image: values.image,
      cover_image,
      gender: values.gender,
      about: values.about.trim(),
      facebook_url: values.facebook_url.trim(),
      twitter_url: values.twitter_url.trim(),
      instagram_url: values.instagram_url.trim(),
      linkedin_url: values.linkedin_url.trim(),
      website_url: values.website_url.trim(),
    };

    if (activeCommunityId == 1 && values?.account_name?.trim()) {
      requestBody = { user: requestBody, account_name: values?.account_name?.trim() };
    } else {
      requestBody = { user: requestBody };
    }

    dispatch(
      updateUser({
        userData: requestBody,
        activeCommunityId,
        currentLoggedInUserId,
        setSubmitting: actions.setSubmitting,
      }),
    );
  };

  return (
    <Transition show={openEditProfile} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-hidden bg-gray-500 bg-opacity-70"
        onClose={setOpenEditProfile}
      >
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute inset-0" onClick={setOpenEditProfile} />

          <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div
                  id="scroll-content"
                  className="h-full flex flex-col pt-6 sidepanel__container shadow-xl overflow-y-scroll"
                >
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <DialogTitle className={classNames('text-lg font-medium text__title', primaryFont)}>
                        {$translatei18n('EditProfile')}
                      </DialogTitle>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          className="rounded-md focus:outline-none focus:ring-0 focus:ring-offset-0"
                          onClick={() => setOpenEditProfile(false)}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6 main__icon" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <Formik
                    initialValues={{
                      firstname: userData?.first_name || '',
                      lastname: userData?.last_name || '',
                      username: userData?.username || '',
                      gender: userData?.gender || 'not_specified',
                      image: userData?.image || '',
                      cover_image: userData?.cover_image || '',
                      about: userData?.about || '',
                      facebook_url: userData?.facebook_url || '',
                      twitter_url: userData?.twitter_url || '',
                      instagram_url: userData?.instagram_url || '',
                      linkedin_url: userData?.linkedin_url || '',
                      website_url: userData?.website_url || '',
                      account_name: userData?.settings?.account_name || '',
                    }}
                    onSubmit={handleOnSubmit}
                    validationSchema={editProfileSchema}
                    enableReinitialize
                  >
                    {({ values, handleChange, handleSubmit, setFieldValue, isSubmitting }) => (
                      <>
                        <div className="mt-6 flex-1 px-4 sm:px-6">
                          <div>
                            <ProfilePicture
                              first_name={values?.firstname}
                              last_name={values?.lastname}
                              currentFile={values.image}
                              setFieldValue={setFieldValue}
                            />
                            <div className="mt-4">
                              <label
                                htmlFor="firstname"
                                className={classNames('block text-sm font-medium text__title', primaryFont)}
                              >
                                {$translatei18n('FirstName')}
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="firstname"
                                  id="firstname"
                                  value={values.firstname}
                                  className="shadow-sm block w-full sm:text-sm rounded-md card input"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="float-right">
                                <ErrorMessage name="firstname" render={CustomErrorMessage} />
                              </div>
                            </div>
                            <div className="mt-4">
                              <label
                                htmlFor="lastname"
                                className={classNames('block text-sm font-medium text__title', primaryFont)}
                              >
                                {$translatei18n('LastName')}
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="lastname"
                                  id="lastname"
                                  value={values.lastname}
                                  className="shadow-sm block w-full sm:text-sm rounded-md card input"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="float-right">
                                <ErrorMessage name="lastname" render={CustomErrorMessage} />
                              </div>
                            </div>
                            <div className="mt-4">
                              <label
                                htmlFor="username"
                                className={classNames('block text-sm font-medium text__title', primaryFont)}
                              >
                                {$translatei18n('Username')}
                              </label>
                              <div className="mt-1">
                                <input
                                  type="text"
                                  name="username"
                                  id="username"
                                  value={values.username}
                                  className="shadow-sm block w-full sm:text-sm rounded-md card input"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="float-right">
                                <ErrorMessage name="username" render={CustomErrorMessage} />
                              </div>
                            </div>
                            {activeCommunityId == 1 && (
                              <div className="mt-4">
                                <label
                                  htmlFor="account_name"
                                  className={classNames('block text-sm font-medium text__title', primaryFont)}
                                >
                                  Account Name
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="account_name"
                                    id="account_name"
                                    value={values?.account_name}
                                    className="shadow-sm block w-full sm:text-sm rounded-md card input"
                                    onChange={handleChange}
                                  />
                                </div>
                                <div className="float-right">
                                  <ErrorMessage name="username" render={CustomErrorMessage} />
                                </div>
                              </div>
                            )}
                            {activeCommunity == 1 && (
                              <div className="mt-4">
                                <label
                                  htmlFor="Gender"
                                  className={classNames('block text-sm font-medium text__title', primaryFont)}
                                >
                                  {$translatei18n('Gender')}
                                </label>
                                <div className="mt-1">
                                  <SelectGender value={values.gender} setFieldValue={setFieldValue} />
                                </div>
                              </div>
                            )}
                            <div className="mt-4">
                              <ChangeProfileCover
                                currentFile={values.cover_image}
                                setFieldValue={setFieldValue}
                                gradientImage={gradientJpeg}
                                resetDefaultGradient={resetDefaultGradient}
                                removeGradient={removeGradient}
                              />
                              <div className="float-right">
                                <ErrorMessage name="cover_image" render={CustomErrorMessage} />
                              </div>
                            </div>
                            <div className="mt-4" id="bio__section__container">
                              <label
                                htmlFor="about"
                                className={classNames('block text-sm font-medium text__title', primaryFont)}
                              >
                                {$translatei18n('Bio')}
                              </label>
                              <div className="mt-1">
                                <textarea
                                  id="about"
                                  name="about"
                                  value={values.about}
                                  rows={5}
                                  placeholder={$translatei18n('edit_profile_example_text')}
                                  className="shadow-sm block w-full sm:text-sm border rounded-md card input"
                                  onChange={handleChange}
                                />
                              </div>
                              <div className="float-right">
                                <ErrorMessage name="about" render={CustomErrorMessage} />
                              </div>
                            </div>
                            <div id="social__icons__box">
                              <div className="mt-4 flex items-center">
                                <label htmlFor="facebook_url">
                                  <FaFacebook className="h-8 w-8 main__icon" />
                                </label>
                                <div className="ml-2 w-full">
                                  <input
                                    type="text"
                                    name="facebook_url"
                                    id="facebook_url"
                                    placeholder={$translatei18n('PasteLinkHere')}
                                    value={values.facebook_url}
                                    className="shadow-sm block w-full sm:text-sm rounded-md card input h-10"
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <ErrorMessage name="facebook_url" render={CustomErrorMessage} />
                              <div className="mt-4 flex items-center">
                                <label htmlFor="twitter_url">
                                  <FaXTwitter className="h-8 w-8 main__icon" />
                                </label>
                                <div className="ml-2 w-full">
                                  <input
                                    type="text"
                                    name="twitter_url"
                                    id="twitter_url"
                                    placeholder={$translatei18n('PasteLinkHere')}
                                    value={values.twitter_url}
                                    className="shadow-sm block w-full sm:text-sm rounded-md card input h-10"
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <ErrorMessage name="twitter_url" render={CustomErrorMessage} />
                              <div className="mt-4 flex items-center">
                                <label htmlFor="instagram_url">
                                  <FaInstagram className="h-8 w-8 main__icon" />
                                </label>
                                <div className="ml-2 w-full">
                                  <input
                                    type="text"
                                    name="instagram_url"
                                    id="instagram_url"
                                    placeholder={$translatei18n('PasteLinkHere')}
                                    value={values.instagram_url}
                                    className="shadow-sm block w-full sm:text-sm rounded-md card input h-10"
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <ErrorMessage name="instagram_url" render={CustomErrorMessage} />
                              <div className="mt-4 flex items-center">
                                <label htmlFor="linkedin_url">
                                  <FaLinkedin className="h-8 w-8 main__icon" />
                                </label>
                                <div className="ml-2 w-full">
                                  <input
                                    type="text"
                                    name="linkedin_url"
                                    id="linkedin_url"
                                    placeholder={$translatei18n('PasteLinkHere')}
                                    value={values.linkedin_url}
                                    className="shadow-sm block w-full sm:text-sm rounded-md card input h-10"
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <ErrorMessage name="linkedin_url" render={CustomErrorMessage} />
                              <div className="mt-4 flex items-center">
                                <label htmlFor="linkedin_url">
                                  <FaLink className="h-8 w-8 main__icon" />
                                </label>
                                <div className="ml-2 w-full">
                                  <input
                                    type="text"
                                    name="website_url"
                                    id="website_url"
                                    placeholder={$translatei18n('PasteLinkHere')}
                                    value={values.website_url}
                                    className="shadow-sm block w-full sm:text-sm rounded-md card input h-10"
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                              <ErrorMessage name="website_url" render={CustomErrorMessage} />
                            </div>
                          </div>
                        </div>
                        <div className="w-full card__footer px-4 py-3 sm:px-6 mt-2">
                          <button
                            type="submit"
                            className={`px-3 py-2 font-medium text-base rounded-md w-full mb-4 btn__primary ${
                              userData?.isUpdating && 'opacity-50 cursor-not-allowed'
                            }`}
                            disabled={userData?.isUpdating}
                            style={{ backgroundColor: activeCommunity?.accent_color }}
                            onClick={handleSubmit}
                          >
                            {userData?.isUpdating ? 'Saving Changes' : $translatei18n('SaveChanges')}
                          </button>
                          <button
                            type="submit"
                            disabled={userData?.isUpdating}
                            className="px-3 py-2 font-medium text-sm rounded-md btn__light border w-full"
                            onClick={() => setOpenEditProfile(false)}
                          >
                            {$translatei18n('Discard')}
                          </button>
                        </div>
                      </>
                    )}
                  </Formik>
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default EditProfile;
