import * as Yup from 'yup';

const facebookUrlChecker =
	/(?:(?:http|https):\/\/)?(?:www\.)?facebook\.com\/.(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-\.]*)/
const twitterUrlChecker =
	/(?:(?:http|https):\/\/)?(?:www\.)?twitter\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)/
const instagramUrlChecker =
	/(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_\.]+)/im
const linkedinUrlChecker =
	/(?:(?:http|https):\/\/)?(?:www\.)??linkedin\.com\/(pub|in|profile)/gm
const websiteUrlChecker =
	/https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@%_\+.~#?&//=]*)|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,}/gi;

const editProfileSchema = Yup.object().shape({
	username: Yup.string().required('Please enter username').min('3', 'Username should be atleast 3 character'),
	gender: Yup.string().required('Please select gender'),
	about: Yup.string().trim().max('200', 'Bio must be under 200 characters'),
	firstname: Yup.string().trim().max('20', 'firstname must be under 20 characters').required('Please enter first name'),
	lastname: Yup.string().trim().max('20', 'lastname must be under 20 characters'),
	facebook_url: Yup.string().matches(facebookUrlChecker, 'Facebook URL is not valid'),
	twitter_url: Yup.string().matches(twitterUrlChecker, 'Twitter URL is not valid'),
	instagram_url: Yup.string().matches(instagramUrlChecker, 'Instagram URL is not valid'),
	linkedin_url: Yup.string().matches(linkedinUrlChecker, 'LinkedIn URL is not valid'),
	website_url: Yup.string().matches(websiteUrlChecker, 'Website URL is not valid'),
});

export default editProfileSchema;
