export const FEATURES_FLAG_LIST = {
  ANALYTICS_INTEGRATION: 'analytics_integration',
  APP_ENABLE: 'app_enable',
  BOOKMARK_POSTS_ENABLE: 'bookmark_posts_enable',
  CUSTOM_CSS: 'custom_css',
  CUSTOM_PUSH_NOTIFICATION: 'custom_push_notification',
  CUSTOMER_SUCCESS_MANAGER: 'customer_success_manager',
  CUSTOM_DOMAIN :"custom_domain",
  DM_ENABLE: 'dm_enable',
  EMAIL_WHITELABEL: 'email_whitelabel',
  EXTERNAL_API: 'external_api',
  FLAG_MEMBERS: 'flag_members',
  GAMIFICATION_ENGINE_ENABLE: 'gamification_engine_enable',
  GOOGLE_CALENDAR_INTEGRATION: 'google_calendar_integration',
  IFRAME_ENABLE: 'iframe_enable',
  LEADERBOARD_ENABLE: 'leaderboard_enable',
  MULTI_LIVE_STREAM_ENABLE :"multi_live_stream_enable",
  LIVE_STREAM_ZOOM_LIMIT: 'live_stream_zoom_limit',
  MAX_ADMINS: 'max_admins',
  MAX_CHANNELS: 'max_channels',
  MAX_GROUPS: 'max_groups',
  MAX_MEMBERS: 'max_members',
  NOTIFICATION_CONTROL: 'notification_control',
  P2P_COIN_TRANSFER_ENABLE: 'p2p_coin_transfer_enable',
  POLLS_ENABLE: 'polls_enable',
  POST_TAGS: 'post_tags',
  PRIORITY_SUPPORT: 'priority_support',
  SHARE_POSTS: 'share_posts',
  SHOP_TRANSACTION_FEES: 'shop_transaction_fees',
  SSO_ENABLE: 'sso_enable',
  STAGE_CHANNEL_ENABLED:"stage_channel_enabled",
  STORAGE_IN_GB: 'storage_in_gb',
  WEBHOOKS_ENABLED: 'webhooks_enabled',
  PRIVACY_POLICY: 'privacy_policy',
  GRAPHY_INTEGRATION_ENABLED: 'graphy_integration_enabled',
  AUTOMATE_ROLES_ENABLED: 'zapier_integration_enabled'
};


export const SUBSCRIPTION_LOCKED_UI_ID = {
  GROUP_LOCK : "group_lock",
  CHANNEL_LOCK : "channel_lock",
  SETTINGS_LOCK : "settings_lock"
}

export const SUBSCRIPTION_EXHAUSTED_KEY = {
  COMMUNITY_CHANNEL_COUNT : "community_channel_count",
  COMMUNITY_GROUP_COUNT : "community_group_count"
}