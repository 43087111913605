import {
  AUDIO_CONNECTION_SUCCESS,
  AUTH_LOG_OUT_SUCCESS,
  DEMOTED_TO_LISTENER,
  RESET_AUDIO_STATE,
  RESET_AUDIO_STATE_WITHOUT_MUTE,
  SET_IS_ACCEPT_MODAL_OPEN,
  SET_IS_GRANTED_STATUS,
  SET_IS_LEAVE_MODAL_OPEN,
  SET_IS_USER_HOST,
  UPDATE_AUDIO_STATE,
  UPDATE_MUTED_IDS,
  UPDATE_ONLINE_USER,
  UPDATE_SPEAKER_REQUESTS,
  VOICE_GRANTED_SUCCESSFULLY,
} from '../actions/actionTypes';

const initialState = {
  hasMultiUserError: false,
  isChangedStage: false,
  isPressDisconnect: false,
  stageData: null,
  isMinimized: false,
  audioManager: null,
  ActiveuserId: '',
  deafen: false,
  connected: false,
  granted: false,
  presenceVoice: null,
  mutedIdMap: {},
  isUserVideoActive: false,
  isVideoActive: false,
  isVoiceGranted: false,
  mute: true,
  isAskToSpeakBtnDisabled: false,
  speakerAcceptModal: false,
  speaker_request_id: '',
  enableSpeakerphone: true,
  muteOthers: false,
  speakerRequests: [],
  speakerRequestModal: false,
  hosts: [],
  speakers: [],
  listeners: [],
  videoUsers: [],
  isUserHost: false,
  reportModal: false,
  leaveModal: false,
  roomSettingModal: false,
  openReportModal: false,
  showDescription: false,
  settingModalOpen: false,
  isInviteModalVisible: false,
  hasUsers: false,
  shareTooltipVisible: false,
  isSlowMode: false,
  isTextEnabled: true,
  remoteUsers: [],
  audioTrack: null,
  videoTrack: null,
  screenVideoTrack: null,
  screenAudioTrack: null,
  microphoneAllowed: true,
  cameraAllowed: true,
  selectedCard: null,
  isCardPinned: false,
  isFullScreen: false,
  selectedFullScreenUID: null,
  selectedFullScreenCard: null,
  fullScreenOtherCard: null,
  isUpdateStageSettings: false,
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case AUDIO_CONNECTION_SUCCESS:
      return { ...state, audioManager: payload.audioManager };
    // return { ...state, audioManager: payload.audioManager, connected: true };
    case UPDATE_MUTED_IDS:
      return { ...state, mutedIdMap: payload.mutedIdMap };
    case SET_IS_LEAVE_MODAL_OPEN:
      return { ...state, leaveModal: payload.value };
    case SET_IS_ACCEPT_MODAL_OPEN:
      return { ...state, speakerAcceptModal: payload.value };
    case VOICE_GRANTED_SUCCESSFULLY:
      return { ...state, granted: true, speakerAcceptModal: false };
    case UPDATE_ONLINE_USER:
      return { ...state, ...payload };
    case SET_IS_USER_HOST:
      return { ...state, isUserHost: payload.value };
    case SET_IS_GRANTED_STATUS:
      return { ...state, granted: payload.value };
    case DEMOTED_TO_LISTENER:
      return { ...state, granted: false, mute: false };
    case UPDATE_AUDIO_STATE:
      return { ...state, ...payload };
    case RESET_AUDIO_STATE:
      return initialState;
    case RESET_AUDIO_STATE_WITHOUT_MUTE:
      return { ...initialState, mute: state.mute };
    case UPDATE_SPEAKER_REQUESTS:
      return { ...state, speakerRequests: payload.speakerRequests };
    case AUTH_LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
