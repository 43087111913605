import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import { Fragment, useRef } from 'react';
import './loader.css';

export default function CommonLoader({ isVisible = false, coverFullScreen = true }) {
  const loaderRef = useRef(null);

  const renderLoader = () => (
    <div className="flex flex-col items-center justify-center w-full text-center">
      <div className="loader__box" />
      <div className="dot-wave">
        <div className="dot-wave__dot loader__icon" />
        <div className="dot-wave__dot loader__icon" />
        <div className="dot-wave__dot loader__icon" />
        <div className="dot-wave__dot loader__icon" />
      </div>
    </div>
  );

  if (coverFullScreen === false) {
    return renderLoader();
  }

  return (
    <Transition show={isVisible} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" onClose={() => {}}>
        <div className="flex items-center justify-center h-full text-center">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            enterTo="opacity-100 translate-y-0 md:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 md:scale-100"
            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
          >
            <div className="inline-block justify-center  overflow-hidden ">{renderLoader()}</div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
}
