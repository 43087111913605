// import Config from 'react-native-config';
import {
  SET_ACTIVE_CHANNEL_DATA,
  SET_CHANNEL,
  // SET_MESSAGES,
  APPEND_MESSAGE,
  // SET_LOAD_MORE,
  SET_LAST_MESSAGE,
  SET_CHANNEL_FAILED,
  SET_SOCKET,
  SET_LOBBY,
  SET_LOBBY_CATEGORIES,
  MUTE_USER,
  UNMUTE_USER,
  SET_USER_CHANNEL,
  // ADD_BLOCKED_USER_IDS,
  // REMOVE_BLOCKED_USER_IDS,
  REFRESH_MESSAGES,
  SET_HAS_EARLIER_MESSAGES,
  SET_ACTIVE_CHANNELID,
} from './actionTypes';

const MESSAGE_LIMIT = 25;
const TIME_LIMIT = 10000;
let timeoutVar;

export const setSocket = (socket) => ({
  type: SET_SOCKET,
  payload: {
    socket,
  },
});

export const setLobby = (lobby) => ({
  type: SET_LOBBY,
  payload: {
    lobby,
  },
});

export const setLobbyCategories = (lobbyCategories) => ({
  type: SET_LOBBY_CATEGORIES,
  payload: {
    lobbyCategories,
  },
});

export const setChannel = (channel) => ({
  type: SET_CHANNEL,
  payload: {
    channel,
  },
});

export const setActiveChannelId = (channelId) => ({
  type: SET_ACTIVE_CHANNELID,
  payload: {
    channelId,
  },
});

export const setUserChannel = (userChannel) => ({
  type: SET_USER_CHANNEL,
  payload: {
    userChannel,
  },
});

export const setActiveChannelData = (activeChannel) => ({
  type: SET_ACTIVE_CHANNEL_DATA,
  payload: {
    activeChannel,
  },
});

export const refreshMessages = () => ({
  type: REFRESH_MESSAGES,
});

export const setLastMessage = (id) => ({
  type: SET_LAST_MESSAGE,
  payload: {
    id,
  },
});

export const setHasEarlierMessages = (value) => ({
  type: SET_HAS_EARLIER_MESSAGES,
  payload: { value },
});

export const appendMessage = (newMessages) => (dispatch, getState) => {
  const { messages, hasEarlierMessages } = getState().socket;
  let updatedMessages;
  if (hasEarlierMessages) {
    updatedMessages = [...newMessages, ...messages];
    if (!timeoutVar) {
      timeoutVar = setTimeout(() => {
        dispatch(setHasEarlierMessages(false));
        clearInterval(timeoutVar);
        timeoutVar = null;
      }, TIME_LIMIT);
    }
  } else {
    updatedMessages = [...newMessages, ...messages].slice(0, MESSAGE_LIMIT);
  }
  dispatch({
    type: APPEND_MESSAGE,
    payload: {
      messages: updatedMessages,
    },
  });
};

export const muteUser = () => ({
  type: MUTE_USER,
});

export const unmuteUser = () => ({
  type: UNMUTE_USER,
});

// export const connectChannel = (socket, roomToken) => {
//   return dispatch => {
//     const LOBBY = `team_room:${roomToken}`;
//     const chan = socket.channel(LOBBY, {});

//     dispatch(setChannel(chan));
//   };
// };

export const setChannelFailed = () => ({
  type: SET_CHANNEL_FAILED,
});

// export const connectSocket = (token, roomToken) => {
//   return dispatch => {
//     const URL = `ws://${PHOENIX_HOST}/socket`;

//     const socket = new Socket(URL, {
//       params: {
//         token: token
//       }
//     });

//     if (token && roomToken) {
//       socket.connect();
//     } else {
//       socket.close();
//     }

//     // configure the event handlers
//     socket.onOpen(event => {
//       dispatch(connectChannel(socket, roomToken));
//     });
//     socket.onError(event => dispatch(setChannelFailed()));
//     socket.onClose(event => dispatch(setChannelFailed()));

//     // open a connection to the server
//     // dispatch(connectChannel(socket, roomToken));
//   };
// };
