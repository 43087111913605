import { lazy } from 'react';
import { FEATURES_FLAG_LIST } from '../constants/subscriptionConstants';
import { SETTINGS_ROUTES_CONSTANTS } from '../constants/routesConstants';
import {
  ArrowCircleDownIcon,
  CurrencyDollarIcon,
  PencilIcon,
  PlusCircleIcon,
  PlusIcon,
  UserAddIcon,
  UserIcon,
  UsersIcon,
  AdjustmentsIcon,
  LinkIcon,
  KeyIcon,
  BellIcon,
  ChipIcon,
  UserGroupIcon,
  MailOpenIcon,
  TagIcon,
  LockClosedIcon,
  ShoppingBagIcon,
  PresentationChartLineIcon,
  VideoCameraIcon,
  CashIcon,
  ShieldExclamationIcon,
} from '@heroicons/react/outline';

import translate from '../i18n';

const $translatei18n = translate;
import { FEATURES_NAMES } from '../constants/pricingConstants';

const AllocateCoin = lazy(() => import(/* webpackChunkName: "AllocateCoin" */ '../views/coinSystem/AllocateCoin'));
const CreateCoinScene = lazy(() =>
  import(/* webpackChunkName: "CreateCoinScene" */ '../views/coinSystem/CreateCoinScene'),
);
const CreateCategory = lazy(() => import(/* webpackChunkName: "CreateCategory" */ '../views/settings/CreateCategory'));
const CreateChannel = lazy(() => import(/* webpackChunkName: "CreateChannel" */ '../views/settings/CreateChannel'));
const CreateChannel2 = lazy(() => import(/* webpackChunkName: "CreateChannel2" */ '../views/settings/CreateChannel2'));
const CreateRole = lazy(() => import(/* webpackChunkName: "CreateRole" */ '../views/settings/CreateRole'));
const EditCommunity = lazy(() => import(/* webpackChunkName: "EditCommunity" */ '../views/settings/EditCommunity'));
const ManageRoles = lazy(() => import(/* webpackChunkName: "ManageRoles" */ '../views/settings/ManageRoles'));
const Moderation = lazy(() => import(/* webpackChunkName: "Moderation" */ '../views/settings/Moderation'));
const Appearance = lazy(() => import(/* webpackChunkName: "Appearance" */ '../views/settings/Appearance'));
const CustomDomain = lazy(() => import(/* webpackChunkName: "CustomDomain" */ '../views/settings/CustomDomain'));
const SingleSignOn = lazy(() => import(/* webpackChunkName: "SingleSignOn" */ '../views/settings/SingleSignOn'));
const PricingPage = lazy(() => import(/* webpackChunkName: "PricingPage" */ '../views/settings/Pricing/PricingPage'));
const CommunityOnboardingSetting = lazy(() =>
  import(/* webpackChunkName: "CommunityOnboardingSetting" */ '../views/settings/CommunityOnboardingSetting'),
);
const CommunityOnboardingNewApplication = lazy(() =>
  import(
    /* webpackChunkName: "CommunityOnboardingNewApplication" */ '../views/settings/CommunityOnboardingNewApplication'
  ),
);
const CustomCss = lazy(() => import(/* webpackChunkName: "CustomCss" */ '../views/settings/CustomCss'));
const PeopleManagement = lazy(() =>
  import(/* webpackChunkName: "PeopleManagement" */ '../views/settings/peopleManagement/PeopleManagement'),
);
const CreateGroup = lazy(() => import(/* webpackChunkName: "CreateGroup" */ '../views/settings/CreateGroup'));
// const CommunityAnalytics = lazy(() => import(/* webpackChunkName: "CommunityAnalytics" */ '../views/analytics'));
const Theming = lazy(() => import(/* webpackChunkName: "Theming" */ '../views/settings/Theming'));
const CommunityInvites = lazy(() =>
  import(/* webpackChunkName: "CommunityInvites" */ '../views/settings/CommunityInvites'),
);
const WhiteLabelEmail = lazy(() =>
  import(/* webpackChunkName: "WhiteLabelEmail" */ '../views/settings/WhiteLabelEmail'),
);
const NotificationPreferences = lazy(() =>
  import(/* webpackChunkName: "NotificationPreferences" */ '../views/settings/NotificationPreferences'),
);
const ManageWebhook = lazy(() => import(/* webpackChunkName: "ManageWebhook" */ '../views/settings/ManageWebhook'));
const ZoomSetting = lazy(() => import(/* webpackChunkName: "ZoomSetting" */ '../views/settings/ZoomSetting'));
const PrivacyPolicy = lazy(() => import(/* webpackChunkName: "PrivacyPolicy" */ '../views/settings/PrivacyPolicy'));
const ShopSettings = lazy(() =>
  import(/* webpackChunkName: "ShopSettings" */ '../views/settings/ShopSettings/ShopSettings'),
);
const MuxSetting = lazy(() => import(/* webpackChunkName: "ZoomSetting" */ '../views/settings/MuxSetting'));
const ManageTags = lazy(() => import('../views/settings/ManageTags'));
const PushNotifSetting = lazy(() => import('../views/settings/PushNotification'));
const MixpanelSettings = lazy(() => import('../views/settings/MixpanelSettings'));
const AutomateRoles = lazy(() => import('../views/settings/AutomateRoles/AutomateRoles'));

// All the layout routes will be here
const settingsRoutes = [
  {
    name: $translatei18n('EditCommunity'),
    path: SETTINGS_ROUTES_CONSTANTS.COMMUNITY_SETTINGS,
    component: EditCommunity,
    icon: PencilIcon,
  },
  {
    name: $translatei18n('Appearance'),
    path: SETTINGS_ROUTES_CONSTANTS.APPEARANACE,
    component: Appearance,
    icon: AdjustmentsIcon,
  },
  {
    name: $translatei18n('NewRole'),
    path: SETTINGS_ROUTES_CONSTANTS.CREATE_ROLE,
    component: CreateRole,
    icon: UserAddIcon,
  },
  {
    name: $translatei18n('ManageRoles'),
    path: SETTINGS_ROUTES_CONSTANTS.MANAGE_ROLES,
    component: ManageRoles,
    icon: UsersIcon,
  },
  {
    name: $translatei18n('PeopleManagement'),
    path: SETTINGS_ROUTES_CONSTANTS.PEOPLE_MANAGEMENT,
    component: PeopleManagement,
    icon: UserIcon,
  },
  {
    name: $translatei18n('NewCategory'),
    path: SETTINGS_ROUTES_CONSTANTS.NEW_CATEGORY,
    icon: PlusCircleIcon,
    component: CreateCategory,
  },
  {
    name: $translatei18n('NewChannel'),
    path: SETTINGS_ROUTES_CONSTANTS.NEW_CHANNEL,
    component: CreateChannel,
    icon: PlusIcon,
    componentSubscriptionId: FEATURES_FLAG_LIST.MAX_CHANNELS,
    featureIdentifier: FEATURES_NAMES.CHANNELS
  },
  {
    name: $translatei18n('ManageTags'),
    path: SETTINGS_ROUTES_CONSTANTS.MANAGE_TAGS,
    component: ManageTags,
    icon: TagIcon,
  },
  {
    name: 'Setup Coin',
    path: SETTINGS_ROUTES_CONSTANTS.SETUP_COIN,
    component: CreateCoinScene,
    icon: CurrencyDollarIcon,
    componentSubscriptionId: FEATURES_FLAG_LIST.GAMIFICATION_ENGINE_ENABLE,
  },
  {
    name: $translatei18n('AllocateCoins'),
    path: SETTINGS_ROUTES_CONSTANTS.ALLOCATE_COIN,
    component: AllocateCoin,
    icon: ArrowCircleDownIcon,
  },
  {
    name: $translatei18n('NewChannel'),
    path: SETTINGS_ROUTES_CONSTANTS.NEW_CHANNEL_CHOOSE_CATEGORY,
    component: CreateChannel2,
  },
  {
    name: $translatei18n('Moderation'),
    path: SETTINGS_ROUTES_CONSTANTS.MODERATION,
    component: Moderation,
    icon: ShieldExclamationIcon,
    featureIdentifier: FEATURES_NAMES.MODERATORS
  },
  {
    name: $translatei18n('PlanDetails&Billing'),
    path: SETTINGS_ROUTES_CONSTANTS.PLAN_DETAILS,
    component: PricingPage,
    icon: CashIcon,
  },
  {
    name: $translatei18n('CustomDomain'),
    path: SETTINGS_ROUTES_CONSTANTS.CUSTOM_DOMAIN,
    component: CustomDomain,
    icon: LinkIcon,
    componentSubscriptionId: FEATURES_FLAG_LIST.CUSTOM_DOMAIN,
  },
  {
    name: $translatei18n('SingleSignOn'),
    path: SETTINGS_ROUTES_CONSTANTS.SINGLE_SIGN_ON,
    component: SingleSignOn,
    icon: KeyIcon,
    componentSubscriptionId: FEATURES_FLAG_LIST.SSO_ENABLE,
  },
  {
    name: $translatei18n('OnboardingSettings'),
    path: SETTINGS_ROUTES_CONSTANTS.ONBOARDING_SETTINGS,
    icon: UserIcon,
    component: CommunityOnboardingSetting,
  },
  {
    name: $translatei18n('NewApplications'),
    path: SETTINGS_ROUTES_CONSTANTS.NEW_APPLICATIONS,
    component: CommunityOnboardingNewApplication,
    icon: BellIcon,
  },
  {
    name: $translatei18n('CustomCSS'),
    path: SETTINGS_ROUTES_CONSTANTS.CUSTOM_CSS,
    component: CustomCss,
    icon: ChipIcon,
    componentSubscriptionId: FEATURES_FLAG_LIST.CUSTOM_CSS,
  },
  {
    name: $translatei18n('NewGroup'),
    path: SETTINGS_ROUTES_CONSTANTS.NEW_GROUP,
    component: CreateGroup,
    icon: UserGroupIcon,
    componentSubscriptionId: FEATURES_FLAG_LIST.MAX_GROUPS,
  },
  {
    name: $translatei18n('Theming'),
    path: SETTINGS_ROUTES_CONSTANTS.THEMING,
    component: Theming,
    icon: AdjustmentsIcon,
    componentSubscriptionId: FEATURES_FLAG_LIST.APP_ENABLE,
  },
  {
    name: $translatei18n('Invites'),
    path: SETTINGS_ROUTES_CONSTANTS.INVITES,
    component: CommunityInvites,
    icon: UserGroupIcon,
  },
  {
    name: $translatei18n('WhitelabelEmail'),
    path: SETTINGS_ROUTES_CONSTANTS.EMAIL_WHITELABEL,
    component: WhiteLabelEmail,
    icon: MailOpenIcon,
    componentSubscriptionId: FEATURES_FLAG_LIST.EMAIL_WHITELABEL,
    featureIdentifier: FEATURES_NAMES.WHITE_LABELED_PLATFORM
  },
  {
    name: $translatei18n('NotificationPreferences'),
    path: SETTINGS_ROUTES_CONSTANTS.NOTIFICATION_PREFERENCE,
    component: NotificationPreferences,
    icon: BellIcon,
    componentSubscriptionId: FEATURES_FLAG_LIST.NOTIFICATION_CONTROL,
  },
  {
    name: $translatei18n('Webhooks'),
    path: SETTINGS_ROUTES_CONSTANTS.WEBHOOK,
    component: ManageWebhook,
    icon: TagIcon,
    componentSubscriptionId: FEATURES_FLAG_LIST.WEBHOOKS_ENABLED,
  },
  {
    name: $translatei18n('ZoomSettings'),
    path: SETTINGS_ROUTES_CONSTANTS.ZOOM_SETTINGS,
    component: ZoomSetting,
    icon: PresentationChartLineIcon,
    componentSubscriptionId: FEATURES_FLAG_LIST.LIVE_STREAM_ZOOM_LIMIT,
    featureIdentifier: FEATURES_NAMES.MULTI_LIVE_STREAM
  },
  {
    name: $translatei18n('AppNotification'),
    path: SETTINGS_ROUTES_CONSTANTS.CUSTOM_PUSH_NOTIFICATION,
    component: PushNotifSetting,
    icon: BellIcon,
    componentSubscriptionId: FEATURES_FLAG_LIST.CUSTOM_PUSH_NOTIFICATION,
    featureIdentifier: FEATURES_NAMES.PUSH_NOTIFICATIONS
  },
  {
    name: $translatei18n('Privacy Policy'),
    path: SETTINGS_ROUTES_CONSTANTS.PRIVACY_POLICY,
    component: PrivacyPolicy,
    icon: LockClosedIcon,
    componentSubscriptionId: FEATURES_FLAG_LIST.PRIVACY_POLICY,
  },
  // {
  //   name: $translatei18n('GraphyIntegration'),
  //   path: SETTINGS_ROUTES_CONSTANTS.GRAPHY_INTEGRATION,
  //   component: GraphyIntegSettings,
  //   icon: <></>,
  //   componentSubscriptionId: FEATURES_FLAG_LIST.GRAPHY_INTEGRATION_ENABLED,
  // },
  {
    name: $translatei18n('Shop'),
    path: SETTINGS_ROUTES_CONSTANTS.SHOP_SETUP,
    component: ShopSettings,
    icon: ShoppingBagIcon,
    componentSubscriptionId: FEATURES_FLAG_LIST.SHOP_TRANSACTION_FEES,
    featureIdentifier: FEATURES_NAMES.SHOP
  },
  {
    name: $translatei18n('MuxSettings'),
    path: SETTINGS_ROUTES_CONSTANTS.MUX_SETUP,
    component: MuxSetting,
    icon: VideoCameraIcon,
  },
  {
    name: $translatei18n('MixpanelIntegration'),
    path: SETTINGS_ROUTES_CONSTANTS.MIXPANEL_SETUP,
    component: MixpanelSettings,
    icon: <></>,
    componentSubscriptionId: FEATURES_FLAG_LIST.ANALYTICS_INTEGRATION,
    featureIdentifier: FEATURES_NAMES.ANALYTICS_INTEGRATION
  },
  {
    name: $translatei18n('AutomateRoles'),
    path: SETTINGS_ROUTES_CONSTANTS.AUTOMATE_ROLE,
    component: AutomateRoles,
    icon: <></>,
    componentSubscriptionId: FEATURES_FLAG_LIST.AUTOMATE_ROLES_ENABLED,
  },
];

export default settingsRoutes;
