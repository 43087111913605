import server from '../api/server';

export const getProductData = (data) => new Promise((resolve, reject) => {
  server
    .get('/self_serve/products', {
      params: {
        community_id: data?.communityId,
        billing_cycle: data?.billingCycle,
        product_type: data?.productType
      },
    })
    .then((response) => {
      resolve(response.data);
    })
    .catch(reject);
});

export const startChargebeeFlow = (data) => new Promise((resolve, reject) => {
  server
    .post('/payment', {
        subscriptions: data?.subscriptions,
        chargebee_redirect_url: data?.chargebeeRedirectUrl
    })
    .then((response) => {
      resolve(response.data);
    })
    .catch(reject);
});

export const  getPlanPaymentStatus = (data) => new Promise((resolve, reject) => {
  server
    .get('/self_serve/subscription_payment_status', {
      params: {
        community_id: data?.communityId,
        subscriptions: data?.subscriptions,
      }
    })
    .then(response => {
      resolve(response.data)
    })
    .catch(reject)
})

export const  getCurrentPlanSubscriptionData = (data) => new Promise((resolve, reject) => {
  server
    .get('/self_serve/subscription', {
      params: {
        community_id: data?.communityId,
      }
    })
    .then(response => {
      resolve(response.data)
    })
    .catch(reject)
})