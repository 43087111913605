const GraphyIcon = ({ fill, classNames }) => {
  return (
    <svg
      width={20}
      height={16}
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames}
    >
      <path
        d="M9.41717 8C9.41717 7.57989 9.40986 7.18553 9.39524 6.82091H4.98089V9.36141H7.0419C6.95054 11.1092 6.66551 12.3101 6.19593 12.9304C5.79396 13.4595 5.34632 13.4595 5.07956 13.4595C4.48208 13.4595 3.08433 13.4595 3.08433 8C3.08433 2.5405 4.48208 2.5405 5.07956 2.5405C5.54365 2.5405 6.50472 2.5405 6.89938 5.15234H9.26552C9.14127 4.13971 8.94029 3.30542 8.64794 2.60193C7.75996 0.45182 6.21786 0 5.07956 0C3.94125 0 2.39733 0.45182 1.51117 2.60193C0.98678 3.87218 0.741943 5.58633 0.741943 8C0.741943 10.4137 0.98678 12.1278 1.51117 13.3981C2.39915 15.5482 3.94125 16 5.07956 16C6.21786 16 7.76179 15.5482 8.64794 13.3981C9.17233 12.1298 9.41717 10.4137 9.41717 8Z"
        fill={fill}
      />
    </svg>
  );
};

export default GraphyIcon;