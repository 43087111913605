import { all, fork, take, put, takeLatest, select, call } from 'redux-saga/effects';
import { channel, eventChannel } from 'redux-saga';
import { parseAxiosErrorMessage } from '../constants/utils';
import { useDispatch, useSelector } from 'react-redux';
import { fetchForumPostCommentService, fetchForumPostService } from '../services/forumService';
import { postCommentDataModel, postDataModel } from '../services/models/forum/forumModel';
import NotificationService from '../services/notificationService';
import {
  INITIALIZE_TEXT_REQUEST,
  INITIALIZE_TEXT_SUCCESS,
  UPDATE_TEXT_STATE,
  SET_ACTIVE_CHANNELID,
  INITIALIZE_SOCKET_REQUEST,
  SET_SOCKET,
  SET_USER_CHANNEL,
} from '../store/actions/actionTypes';

import { Socket } from '../sockets/phoenix';
const connectSocket = channel();
function* initialiseSocketSaga() {
  yield takeLatest(INITIALIZE_SOCKET_REQUEST, function* ({ payload }) {
    new Promise((resolve, reject) => {
      try {
        let unreadCount = 0;
        const PHOENIX_HOST = process.env.REACT_APP_PHOENIX_HOST;
        const URL = `wss://${process.env.REACT_APP_PHOENIX_HOST}/socket`;
        const userId = payload.id;
        const socket = new Socket(URL, {
          params: { token: payload.token },
        });

        socket.connect();

        socket.onOpen(() => {
          connectSocket.put({
            type: SET_SOCKET,
            payload: socket,
          });
          console.log('PHOENIX_HOST =>', PHOENIX_HOST);
          // dispatch(setSocket(socket));
          resolve(socket);
        });

        const userChannel = socket.channel(`user:${userId}`, {});

        connectSocket.put({
          type: SET_USER_CHANNEL,
          payload: userChannel,
        });
        // dispatch(setUserChannel(userChannel));
        userChannel
          .join()
          .receive('ok', (data) => {
            //  data.blocked_user_ids && this.props.addBlockedUserIds(data.blocked_user_ids);
            unreadCount = data?.unread_notifications_count;
          })
          // .receive('error', ({ reason }) => {})
          .receive('error', (error) => {
            console.error({ error });
          })
          .receive('timeout', () => {});

        socket.onError(() => {
          socket.params = () => ({ token: payload.token });
        });
        socket.onClose(() => {});
      } catch (error) {
        const message = parseAxiosErrorMessage(error);
        NotificationService.error(message);
        reject(error);
      }
    });
    // try {

    // } catch (error) {
    //   const message = parseAxiosErrorMessage(error);
    //   NotificationService.error(message);
    // }
  });
}

function socketConnection(payload) {
  return new Promise((resolve, reject) => {
    try {
      let unreadCount = 0;
      const PHOENIX_HOST = process.env.REACT_APP_PHOENIX_HOST;
      // const URL = `wss://${PHOENIX_HOST}/socket`;
      const URL = `wss://${PHOENIX_HOST}/socket`;
      const socket = new Socket(URL, {
        params: { token: payload.token },
      });

      socket.connect();

      socket.onOpen(() => {
        resolve({ connected: true, socket: socket });
      });

      // const userChannel = socket.channel(`user:${userId}`, {});

      // yield put({
      //   type: SET_USER_CHANNEL,
      //   payload: userChannel,
      // });
      // // dispatch(setUserChannel(userChannel));
      // userChannel
      //   .join()
      //   .receive('ok', (data) => {
      //     //  data.blocked_user_ids && this.props.addBlockedUserIds(data.blocked_user_ids);
      //     unreadCount = data?.unread_notifications_count;
      //   })
      //   // .receive('error', ({ reason }) => {})
      //   .receive('error', (error) => {
      //     console.error({ error });
      //   })
      //   .receive('timeout', () => {});

      socket.onError(() => {
        socket.params = () => ({ token: payload.token });
      });
      socket.onClose(() => {});
    } catch (error) {
      console.error(error);
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      reject({ connected: false, error: error });
    }
  });
}

function* textCheck(params) {
  console.error('updateTextStatus =>', params);
  // yield put({
  //   type: UPDATE_TEXT_STATE,
  //   payload: params,
  // });
}
function updateTextStatus(params) {
  textCheck(params);
  // yield put({
  //   type: UPDATE_TEXT_STATE,
  //   payload: params,
  // });
}

function* initialiseTextChannelSaga() {
  yield takeLatest(INITIALIZE_TEXT_REQUEST, function* ({ payload }) {
    const { channelId, type, shouldInitialiseVoice } = payload;
    let unreadCount = 0;
    try {
      const {
        auth,
        text: { isConnected, channelData },
      } = yield select();

      if (channelId === channelData?.channel?.id) {
        return;
      }

      yield put({
        type: UPDATE_TEXT_STATE,
        payload: { channelLoading: true },
      });

      // if (isConnected) {
      //   dispatch(
      //     closeChannelConnection(
      //       () => dispatch(initialiseTextChannelSaga(channelId, { type, shouldInitialiseVoice })),
      //       true,
      //     ),
      //   );
      //   return;
      // }
      if (!channelId) {
        throw new Error('ActiveChannelID not present');
      }

      yield put({
        type: SET_ACTIVE_CHANNELID,
        payload: channelId,
      });

      const { socket } = yield select();
      let socketConn = socket.socket;
      if (!socketConn) {
        const res = yield call(socketConnection, auth);
        if (res.connected === true) {
          yield put({
            type: SET_SOCKET,
            payload: res.socket,
          });
        }
        socketConn = res.socket;
      }
      const userId = auth.id;
      const userChannel = socketConn.channel(`user:${userId}`, {});

      yield put({
        type: SET_USER_CHANNEL,
        payload: userChannel,
      });
      userChannel
        .join()
        .receive('ok', (data) => {
          unreadCount = data?.unread_notifications_count;
        })
        .receive('error', (error) => {
          console.error({ error });
        })
        .receive('timeout', () => {});

      const textChannel = socketConn.channel(`${type}:${channelId}`, {});

      textChannel
        .join()
        .receive('ok', updateTextStatus.bind(this))

        // {
        //   console.error('textChannel ok', data);
        //   updateTextStatus({ roomType: data.room_type });
        //   // yield put({
        //   //   type: UPDATE_TEXT_STATE,
        //   //   payload: { roomType: data.room_type },
        //   // });
        //   // dispatch(updateTextState({ roomType: data.room_type }));
        //   // dispatch(renderChatMessage(data.messages.data, data.messages.metadata));
        //   // dispatch(
        //   //   textChannelConnectionSuccess(textChannel, data, getChannelPermissionsConfig(data.channel_permissions)),
        //   // );

        //   // if (shouldInitialiseVoice) {
        //   //   dispatch(initialiseVoice(textChannel, { channelId, channelData: data }));
        //   //   if (data.host || data.speaker) {
        //   //     // TODO AUDIO PERMISSION CHECK
        //   //     await checkCameraAndAudioPermission({
        //   //       onCancel: () => dispatch(onAudioPermissionCancel()),
        //   //     });
        //   //   }
        //   // }

        //   // if (auth.voiceRoomToken) {
        //   //   textChannel.push('presence:update', {
        //   //     token: auth.voiceRoomToken,
        //   //   });
        //   // }
        // })
        .receive('error', ({ reason }) => {
          console.error('textChannel error', reason);
          // if (reason === 'kicked from room') {
          //   Dialog.dialog.showError({
          //     text: "You're not permitted to enter this room. Please try again later!",
          //     time: 1500,
          //   });
          //   dispatch(closeChannelConnection(null, true));
          //   // TODO: NAVIGATE TO CHANNEL LANDING
          //   //   navigationServices.navigate('ChannelScreen');
          // }
          // dispatch(updateTextState({ redirect: true }));
          // console.log({ reason });
        })
        .receive('timeout', () => {
          console.error('textChannel timeout');
          // dispatch(updateTextState({ redirect: true }));
        });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

export default function* rootSaga() {
  yield all([fork(initialiseTextChannelSaga), fork(initialiseSocketSaga)]);
}
