/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import URLPattern from 'url-pattern';

export const TOOLBAR_FILTERS = [
  {
    label: 'Today',
    value: 'dStart',
    start_date: new Date(new Date().setHours(0, 0, 0, 0)),
    end_date: new Date(),
  },
  {
    label: 'Yesterday',
    value: '-24h',
    start_date: new Date(new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0, 0, 0, 0)),
    end_date: new Date(new Date().setHours(0, 0, 0, 0)),
  },
  {
    label: 'Last 7 Days',
    value: '-7d',
    start_date: new Date(new Date().setDate(new Date().getDate() - 7)),
    end_date: new Date(),
  },
  {
    label: 'Last 14 Days',
    value: '-14d',
    start_date: new Date(new Date().setDate(new Date().getDate() - 14)),
    end_date: new Date(),
  },
  {
    label: 'Last 30 Days',
    value: '-30d',
    start_date: new Date(new Date().setDate(new Date().getDate() - 30)),
    end_date: new Date(),
  },
  {
    label: 'Last 90 Days',
    value: '-90d',
    start_date: new Date(new Date().setDate(new Date().getDate() - 90)),
    end_date: new Date(),
  },
  {
    label: 'Last 180 Days',
    value: '-180d',
    start_date: new Date(new Date().setDate(new Date().getDate() - 180)),
    end_date: new Date(),
  },
  {
    label: 'Date Range',
    value: 'CUSTOM',
    start_date: new Date(new Date().setDate(new Date().getDate() - 1)),
    end_date: new Date(),
  },
];

export const getScreenNameForPath = (pathname) => {
  const communityOverview = new URLPattern('/');
  const communitySwitcher = new URLPattern('/community-switcher');
  const shopListing = new URLPattern('/shoplisting');
  const DMsInbox = new URLPattern('/inbox');
  const forumChannel = new URLPattern('/communities/:id/group/:id/channels/:id/forumposts');
  const forumPostDetails = new URLPattern('/communities/:id/group/:id/channels/:id/forumposts/:id');
  const createForumPost = new URLPattern('/communities/:id/group/:id/channels/:id/forum/create');
  const resourceChannel = new URLPattern('/communities/:id/group/:id/channels/:id/resourceposts');
  const resourcePostDetails = new URLPattern('/communities/:id/group/:id/channels/:id/resourceposts/:id');
  const createResourcePost = new URLPattern('/communities/:id/group/:id/channels/:id/resource/create');
  const calendarChannel = new URLPattern('/communities/:id/group/:id/channels/:id/calendarposts');
  const calendarPostDetails = new URLPattern('/communities/:id/group/:id/channels/:id/calendarposts/:id');
  const calendarPostPublicDetails = new URLPattern('/public/event');
  const createCalendarEvent = new URLPattern('/communities/:id/group/:id/channels/:id/calendar/create');
  const stageChannel = new URLPattern('/communities/:id/group/:id/channels/:id/stage');
  const chatChannel = new URLPattern('/communities/:id/group/:id/channels/:id/text');
  const pageNotFound = new URLPattern('/not-found');
  const serverError = new URLPattern('/public/internal-server-error');

  if (communityOverview.match(pathname)) return 'Community Home';
  if (communitySwitcher.match(pathname)) return 'Community Switcher';
  if (shopListing.match(pathname)) return 'Shop';
  if (DMsInbox.match(pathname)) return 'DM Inbox';
  if (forumChannel.match(pathname)) return 'Forum Channel';
  if (forumPostDetails.match(pathname)) return 'View Forum Post';
  if (createForumPost.match(pathname)) return 'Create Forum Post';
  if (resourceChannel.match(pathname)) return $translatei18n('ResourceChannel');
  if (resourcePostDetails.match(pathname)) return 'View Resource Post';
  if (createResourcePost.match(pathname)) return 'Create Resource Post';
  if (calendarChannel.match(pathname)) return $translatei18n('CalendarChannel');
  if (calendarPostDetails.match(pathname)) return 'View Calendar Event';
  if (calendarPostPublicDetails.match(pathname)) return 'View Public Calendar Event';
  if (createCalendarEvent.match(pathname)) return 'Create Calendar Event';
  if (stageChannel.match(pathname)) return $translatei18n('StageChannel');
  if (chatChannel.match(pathname)) return 'Text Channel';
  if (pageNotFound.match(pathname)) return 'Page Not Found';
  if (serverError.match(pathname)) return 'Internal Server Error';

  const communitySettings = new URLPattern('/community-settings');
  const peopleManagement = new URLPattern('/community-settings/manage-people');
  const manageRoles = new URLPattern('/community-settings/manage-roles');
  const communityAppearance = new URLPattern('/community-settings/appearance');
  const invitesDashboard = new URLPattern('/community-settings/invites');
  const customCss = new URLPattern('/community-settings/custom-css');
  const customDomainSettings = new URLPattern('/community-settings/custom-domain');
  const ssoSettings = new URLPattern('/community-settings/single-signon');
  const emailWhitlabel = new URLPattern('/community-settings/white-label-email');

  const mobileTheming = new URLPattern('/community-settings/theming');
  const notificationPreferences = new URLPattern('/community-settings/notification-preferences');

  const createNewChannel = new URLPattern('/community-settings/create-channel');
  const createNewCategory = new URLPattern('/community-settings/create-category');
  const createNewRole = new URLPattern('/community-settings/create-role');
  const createNewGroup = new URLPattern('/community-settings/create-group');
  const createNewChannelSelectCategory = new URLPattern('/community-settings/create-channel/select-category');
  const editChannel = new URLPattern('/channel-settings/:id');
  const channelSettings = new URLPattern('/channel-settings');
  const channelCategorySettings = new URLPattern('/channel-category-settings');

  const onboardingSettings = new URLPattern('/community-settings/onboarding-setting');
  const onboardingApplications = new URLPattern('/community-settings/new-application');
  const editCommunityCoin = new URLPattern('/community-settings/manage-coin');
  const allocateCoins = new URLPattern('/community-settings/allocate-coin');
  const communityAnalytics = new URLPattern('/community-settings/performance');
  const moderation = new URLPattern('/community-settings/moderation');

  if (communitySettings.match(pathname)) return $translatei18n('EditCommunity');
  if (peopleManagement.match(pathname)) return $translatei18n('PeopleManagement');
  if (manageRoles.match(pathname)) return $translatei18n('ManageRoles');
  if (communityAppearance.match(pathname)) return 'Community Appearance';
  if (invitesDashboard.match(pathname)) return 'Invite System';
  if (customCss.match(pathname)) return $translatei18n('CustomCSS');
  if (customDomainSettings.match(pathname)) return $translatei18n('CustomDomain');
  if (ssoSettings.match(pathname)) return $translatei18n('SingleSignOn');
  if (emailWhitlabel.match(pathname)) return $translatei18n('WhitelabelEmail');

  if (mobileTheming.match(pathname)) return $translatei18n('MobileTheming');
  if (notificationPreferences.match(pathname)) return $translatei18n('NotificationPreferences');

  if (createNewChannel.match(pathname)) return 'Create Channel';
  if (createNewCategory.match(pathname)) return 'Create Category';
  if (createNewRole.match(pathname)) return 'Create Role';
  if (createNewGroup.match(pathname)) return $translatei18n('CreateGroup');
  if (createNewChannelSelectCategory.match(pathname)) return 'Create Channel';
  if (editChannel.match(pathname)) return 'Edit Channel';
  if (channelSettings.match(pathname)) return 'Channel Settings';
  if (channelCategorySettings.match(pathname)) return 'Channel Category Settings';

  if (onboardingSettings.match(pathname)) return $translatei18n('OnboardingSettings');
  if (onboardingApplications.match(pathname)) return 'Onboarding Applications';
  if (editCommunityCoin.match(pathname)) return $translatei18n('EditCommunityCoin');
  if (allocateCoins.match(pathname)) return $translatei18n('AllocateCoins');
  if (communityAnalytics.match(pathname)) return 'Community Analytics';
  if (moderation.match(pathname)) return $translatei18n('Moderation');

  const groupSetting = new URLPattern('/group-settings');
  const groupSettings = new URLPattern('/group-settings/:id');
  const groupNewChannel = new URLPattern('/group-settings/:id/create-channel');
  const groupNewCategory = new URLPattern('/group-settings/:id/create-category');
  const groupNewChannelSelectCategory = new URLPattern('/group-settings/:id/create-channel/select-category');

  if (groupSetting.match(pathname)) return $translatei18n('GroupSettings');
  if (groupSettings.match(pathname)) return $translatei18n('EditGroup');
  if (groupNewChannel.match(pathname)) return 'Create Channel';
  if (groupNewCategory.match(pathname)) return 'Create Category';
  if (groupNewChannelSelectCategory.match(pathname)) return 'Create Channel';

  const signInPage = new URLPattern('/signin');
  const loginPage = new URLPattern('/login');
  const bubbleSignin = new URLPattern('/oauth2/callback');
  const enterCommunity = new URLPattern('/enter-community');
  const verifyEmailOTP = new URLPattern('/register/email/verify-otp');
  const mobileVerification = new URLPattern('/onboarding/mobile');
  const createAccount = new URLPattern('/onboarding/create-account');
  const applicationForm = new URLPattern('/onboarding/application-form');
  const applicationStatus = new URLPattern('/onboarding/application-status');

  if (signInPage.match(pathname)) return '';
  if (loginPage.match(pathname)) return 'Scenes Login';
  if (bubbleSignin.match(pathname)) return 'Custom SSO Login';
  if (enterCommunity.match(pathname)) return 'Enter Community';
  if (verifyEmailOTP.match(pathname)) return 'Verify Email Otp';
  if (mobileVerification.match(pathname)) return 'Verify Phone Otp';
  if (createAccount.match(pathname)) return 'Create Profile';
  if (applicationForm.match(pathname)) return $translatei18n('ApplicationForm');
  if (applicationStatus.match(pathname)) return 'Application Pending';

  return pathname;
};
