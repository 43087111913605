import server from '../api/server';
import { API_ERROR_MESSAGES } from '../constants';
import { getRefreshToken } from '../constants/authUtils';

export const login = async (data) => await new Promise((resolve, reject) => {
  server
    .post('/login', {
      user: {
        email: data.email,
        password: data.password,
      },
    })
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch(reject);
});

export const checkEmailExists = async (data) => await new Promise((resolve, reject) => {
  server
    .post('/login', {
      user: {
        email: data.email,
      },
    })
    .then(() => {
      resolve();
    })
    .catch((e) => {
      const errorData = e.response && e.response.data;
      // We need to check if it's email exists error or not, If it is present then we mark as success
      if (errorData && errorData.errors && errorData.errors === API_ERROR_MESSAGES.EMAIL_OR_PASSWORD_INVALID) {
        return resolve();
      }
      reject(e);
    });
});

export const registerWithEmail = async ({data}) => await new Promise((resolve, reject) => {
  server
    .post('/register', {
      user: {
        email: data.email,
        password: data.password,
        first_name: data.first_name,
        last_name: data.last_name,
      },
    })
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch(reject);
});

export const googleSignInRequest = async (data) => await new Promise((resolve, reject) => {
  server
    .post('/users/oauth/google', {
      access_token: data.access_token,
    })
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch(reject);
});

export const bubbleSignInRequest = async (data) => await new Promise((resolve, reject) => {
  server
    .post('/users/oauth/bubble', {
      access_code: data.access_code,
      action: 'bubble',
    })
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch(reject);
});

export const customLoginRequest = async (data) => await new Promise((resolve, reject) => {
  server
    .post('/users/oauth/custom_login', {
      access_code: data?.access_code,
    }, {
      params: {
        request_host: window?.location?.host,
      },
    })
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch(reject);
});

export const getResetPasswordCode = (data) => new Promise((resolve, reject) => {
  server
    .post('/users/passwords', {
      email: data.email,
    }, {
      params: {
        request_host: data?.requestHost,
      },
    })
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch(reject);
});

export const logoutUser = (data) => new Promise((resolve, reject) => {
  server
    .delete('/logout', {
      headers: {
        'Refresh-Token': getRefreshToken(),
      },
    })
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch(reject);
});

export const resetPassword = (data) => new Promise((resolve, reject) => {
  server
    .post('/users/passwords/verify', {
      email: data.email,
      reset_password_token: data.reset_password_token, // Code from email and entered via otp boxes
      password: data.password,
      confirm_password: data.confirm_password,
    }, {
      params: {
        request_host: data?.requestHost,
      },
    })
    .then(async (response) => {
      const res = response.data;
      await resetPasswordVerify(data, data?.requestHost);
      resolve(res);
    })
    .catch(reject);
});

export const resetPasswordVerify = (data, requestHost) => {
  console.log('data verify', data);
  return new Promise((resolve, reject) => {
    server
      .put('/users/passwords', {
        email: data.email,
        // Code from email and entered via otp boxes
        reset_password_token: data.reset_password_token,
        password: data.password,
        confirm_password: data.confirm_password,
      }, {
        params: {
          request_host: requestHost,
        },
      })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch(reject);
  });
};

export const verifyAuthCode = (code) => new Promise((resolve, reject) => {
  server
    .post(
      '/token',
      {
        code,
      },
      {
        params: {
          request_host: window.location.host,
        },
      },
    )
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch(reject);
});
