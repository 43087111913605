// /* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable prefer-destructuring */
// /* eslint-disable import/no-cycle */
/* eslint-disable no-prototype-builtins */

import { UPDATE_OFF_LINE_CHAT_STATE } from './actionTypes';

export const updateOfflineChatState = (data) => ({
  type: UPDATE_OFF_LINE_CHAT_STATE,
  payload: data,
});