import { changeSubscriptionUseData } from '../../components/subscription/subscriptionUtils';
import { SUBSCRIPTION_EXHAUSTED_KEY } from '../../constants/subscriptionConstants';
import { deleteCommunitychannel } from '../../services/channelService';
import NotificationService from '../../services/notificationService';

const handleBackNavigation = () => {
  window.location.href = '/';
};

export const handleDeleteChannel = async (
  setOpenDeleteConfirmation,
  activeCommunityId,
  channel_id,
  activeGroupId,
  setLoading,
) => {
  try {
    await deleteCommunitychannel(activeCommunityId, channel_id, activeGroupId);
    changeSubscriptionUseData(SUBSCRIPTION_EXHAUSTED_KEY.COMMUNITY_CHANNEL_COUNT, -1);
    NotificationService.success(`Successfully Deleted Channel`);
    setOpenDeleteConfirmation(false);
    handleBackNavigation();
    setLoading(false);
  } catch (err) {
    NotificationService.error('Unable to delete channel');
    setLoading(false);
  }
};
