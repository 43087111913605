import { Fragment, useState, useEffect, useMemo } from 'react';
import { Dialog, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { ChevronLeftIcon } from '@heroicons/react/outline';
import { useDispatch, useSelector } from 'react-redux';
import { classNames, history } from '../../../constants/utils';
import { getCurrentLoggedInUser } from '../../../selectors/ProfileSelectors';
import { fetchFollowee, fetchFollowers } from '../../../store/actions/userActions';
import { imageFetch } from '../../../services/preSignedAws';
import { getActiveCommunityAccentColor, getActiveCommunityFontsClass, getActiveCommunityId } from '../../../selectors/CommunitySelectors';
import { followUser, removeFollower, unfollowUser } from '../../../services/UserService';
import NotificationService from '../../../services/notificationService';
import { ERROR_MESSAGES } from '../../../constants/notificationMessages';
import CommonNameAvatar, {
  COMMON_NAME_AVATAR_SIZE,
  COMMON_NAME_AVATAR_TYPE,
} from '../../../components/shared/tailwind/NameAvatar';
import { getFullName } from '../../../utils/Utils';

function FollowListSidebar({ open, setOpen, followeesCount, followersCount, updateFolloweesCount }) {
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentLoggedInUser);
  const activeCommunityId = useSelector(getActiveCommunityId)
  const { primaryFont } = useSelector(getActiveCommunityFontsClass);
  const accentColor = useSelector(getActiveCommunityAccentColor);
  const [isFollowerTabActive, setIsFollowerTabActive] = useState(true);
  const [unfollowedUsers, setUnfollowedUsers] = useState({});
  const [disableFollowForUser, setDisableFollowForUser] = useState({});

  const { followers, followee } = currentUser;

  useEffect(() => {
    dispatch(fetchFollowers({ reset: true }));
    dispatch(fetchFollowee({ reset: true }));
  }, []);

  const handleFollow = async (userId) => {
    const requestData = {
      followee_id: userId,
    };

    let newDisableFollowForUser = { ...disableFollowForUser };
    newDisableFollowForUser[userId] = true;
    setDisableFollowForUser(newDisableFollowForUser);

    if (!unfollowedUsers[userId]) {
      try {
        await unfollowUser(requestData, userId);
        const temp = { ...unfollowedUsers };
        temp[userId] = true;
        setUnfollowedUsers(temp);
        updateFolloweesCount(parseInt(followeesCount, 10) - 1);
      } catch (error) {
        NotificationService.error(ERROR_MESSAGES.SOMETHING_WENT_WRONG);
      }
    } else {
      try {
        await followUser(requestData);
        const temp = { ...unfollowedUsers };
        temp[userId] = false;
        setUnfollowedUsers(temp);
        updateFolloweesCount(parseInt(followeesCount, 10) + 1);
      } catch (error) {
        NotificationService.error(ERROR_MESSAGES.SOMETHING_WENT_WRONG);
      }
    }

    newDisableFollowForUser = { ...newDisableFollowForUser };
    newDisableFollowForUser[userId] = false;
    setDisableFollowForUser(newDisableFollowForUser);
  };

  const handleRemoveFollower = async (userId) => {
    try {
      const response = await removeFollower(userId, activeCommunityId)
      const { status } = response
      if (status >= 200 && status <= 300) {
        dispatch(fetchFollowers({ reset: true }));
      }
      else {
        NotificationService.error(response)
      }
    }
    catch (err) {
      console.log(err);
      NotificationService.error("Something Went Wring.")
    }
  }

  const loadMoreData = () => {
    if (isFollowerTabActive) {
      dispatch(fetchFollowers({ reset: false, nextPageId: followers?.nextPageId }));
    } else {
      dispatch(fetchFollowee({ reset: false, nextPageId: followee?.nextPageId }));
    }
  };

  const showUserProfile = (user) => {
    history.push(`/profile?user_id=${user?.id}`);
  };

  const toRenderSeeMore = () => {
    if ((isFollowerTabActive && followers?.nextPageId) || (!isFollowerTabActive && followee?.nextPageId)) {
      return true;
    }
    return false;
  };

  const toRenderEmptyState = useMemo(() => {
    if (isFollowerTabActive && followers?.data?.length === 0) {
      return true;
    }
    if (!isFollowerTabActive && followee?.data?.length === 0) {
      return true;
    }

    return false;
  }, [isFollowerTabActive, followers, followee]);

  const emptyStateText = useMemo(() => {
    if (isFollowerTabActive) {
      return {
        title: `${currentUser?.username} doesn’t have any followers`,
        description: 'When someone follows them, they’ll be listed here.',
      };
    }

    return {
      title: `${currentUser?.username} isn’t following anyone`,
      description: 'When they do, they’ll be listed here.',
    };
  }, [isFollowerTabActive, followers, followee]);

  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" onClose={() => setOpen(false)}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center md:block md:p-0">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={() => setOpen(false)} />
          </TransitionChild>

          <span className="hidden md:inline-block md:align-middle md:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            enterTo="opacity-100 translate-y-0 md:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 md:scale-100"
            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
          >
            <div className="inline-block align-bottom rounded-lg text-left overflow-hidden shadow-xl transform transition-all md:my-8 md:align-middle md:max-w-lg md:w-full">
              <div className="flex h-full flex-col overflow-y-scroll card shadow-xl min-h-96">
                <div className="p-3">
                  <div className="flex items-start">
                    <button type="button" className="rounded-md" onClick={() => setOpen(false)}>
                      <span className="sr-only">Close panel</span>
                      <ChevronLeftIcon className="h-6 w-6 main__icon" aria-hidden="true" />
                    </button>
                    <DialogTitle
                      className={classNames('text-lg font-medium text__title text-center w-full', primaryFont)}
                    >
                      {currentUser?.username}
                    </DialogTitle>
                  </div>
                </div>
                <div className="border-b tab__container">
                  <div className="">
                    <nav className="flex" x-descriptions="Tab component">
                      <button
                        type="button"
                        className={classNames(
                          isFollowerTabActive ? 'border-b tab--active' : 'tab',
                          'w-full py-2 text-sm font-semibold',
                        )}
                        onClick={() => setIsFollowerTabActive(true)}
                      >
                        {followersCount} {$translatei18n('Followers')}
                      </button>
                      <button
                        type="button"
                        className={classNames(
                          !isFollowerTabActive ? 'border-b tab--active' : 'tab',
                          'w-full py-2 text-sm font-semibold',
                        )}
                        onClick={() => setIsFollowerTabActive(false)}
                      >
                        {followeesCount} {$translatei18n('Following')}
                      </button>
                    </nav>
                  </div>
                </div>
                <ul className="flex-1 overflow-y-auto">
                  {((isFollowerTabActive ? followers?.data : followee?.data) || []).map((user) => (
                    <li key={user.id}>
                      <div className="relative flex items-center py-3 px-5">
                        <div className="-m-1 block flex-1 p-1">
                          <div className="relative flex min-w-0 flex-1 items-center">
                            <span
                              className="relative inline-block flex-shrink-0 cursor-pointer"
                              onClick={() => showUserProfile(user)}
                            >
                              {user?.image && user?.image !== "onboarding/user-avatar.png" ? (
                                <img
                                  className="h-10 w-10 rounded-full"
                                  src={imageFetch(user?.image, {}, true)}
                                  alt=""
                                />
                              ) : (
                                <CommonNameAvatar
                                  radius={COMMON_NAME_AVATAR_TYPE.FULL}
                                  size={COMMON_NAME_AVATAR_SIZE.MD}
                                  name={user?.first_name}
                                />
                              )}
                            </span>
                            <div className="ml-4 truncate">
                              <p className="truncate text-sm font-medium text__body">{getFullName(user?.first_name, user?.last_name)}</p>
                              <p className="truncate text-sm text__info">{`@${user.username}`}</p>
                            </div>
                          </div>
                        </div>
                        {!isFollowerTabActive && (
                          <button
                            type="button"
                            className={classNames(
                              'w-24 ltr:mr-3 rtl:ml-3 inline-flex items-center justify-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm focus:outline-none focus:ring-0 focus:ring-offset-0',
                              primaryFont,
                              unfollowedUsers[user.id] ? 'btn__primary' : 'btn__light',
                            )}
                            style={{ backgroundColor: unfollowedUsers[user.id] ? accentColor : '' }}
                            onClick={() => handleFollow(user.id)}
                            disabled={disableFollowForUser[user.id]}
                          >
                            {unfollowedUsers[user.id] ? $translatei18n('Follow') : $translatei18n('Unfollow')}
                          </button>
                        )}
                        {isFollowerTabActive && (
                          <button
                            type="button"
                            className={classNames(
                              'w-24 ltr:mr-3 rtl:ml-3 inline-flex items-center justify-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm focus:outline-none focus:ring-0 focus:ring-offset-0 btn__light',
                              primaryFont,
                            )}
                            onClick={() => handleRemoveFollower(user.id)}
                            disabled={disableFollowForUser[user.id]}
                          >
                            {$translatei18n('Remove')}
                          </button>
                        )}
                      </div>
                    </li>
                  ))}
                  {toRenderSeeMore() && (
                    <div className="flex justify-center">
                      <button
                        className="text__link cursor-pointer background-transparent font-bold px-3 text-sm outline-none focus:outline-none ease-linear transition-all duration-150"
                        type="button"
                        onClick={loadMoreData}
                      >
                        {$translatei18n('SeeMore')}
                      </button>
                    </div>
                  )}
                </ul>
                {toRenderEmptyState && (
                  <div className="h-full flex flex-col justify-center text-base">
                    <div className="text__title text-center">{emptyStateText.title}</div>
                    <div className="text__info text-center">{emptyStateText.description}</div>
                  </div>
                )}
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
}

export default FollowListSidebar;
