/* eslint-disable no-useless-escape */
import translate from '../i18n';

const $translatei18n = translate;

export const API_ERROR_MESSAGES = {
  EMAIL_OR_PASSWORD_INVALID: 'Email or password is invalid',
};

export const CHANNEL_TYPES = {
  FORUM: 'forum',
  RESOURCE: 'resources',
  STAGE: 'stage',
  CALENDER: 'calendar',
  CHAT: 'text',
  TEXT: 'text',
  BOOKMARKS: 'bookmarks',
  ZOOM: 'zoom',
  EMBED: 'embed',
  STREAMING: 'streaming',
};

export const ADDITIONAL_CHANNEL_TYPES = {
  OVERVIEW: 'overview',
};

export const ROOM_TYPES = {
  MAIN: 'main',
  OVERFLOW: 'overflow',
};

export const REPORT_TYPE = {
  FORUM_POST: 'Forum Post',
  FORUM_COMMENT: 'Forum Comment',
  FORUM_REPLY: 'Forum Reply',
  RESOURCE_COMMENT: 'Resource Comment',
  USER_REPORT: 'User Report',
};

export const REPORT_REASON_TITLE = 'Why are you reporting this?';

export const REPORT_REASONS = [
  $translatei18n('Spam'),
  $translatei18n('Offensive'),
  $translatei18n('Bullying'),
  $translatei18n('HateSpeech'),
  $translatei18n('Inappropriate'),
  $translatei18n('Pornography'),
  $translatei18n('Others'),
];

export const ROUTE_PATH = {
  FORUM_POSTS: '/communities/:community_id/group/:group_id/channels/:channel_id/forumposts',
  FORUM_POST_CREATE: '/communities/:community_id/group/:group_id/channels/:channel_id/forum/create/:forum_post_id?',
  FORUM_POST_CREATE_POLL:
    '/communities/:community_id/group/:group_id/channels/:channel_id/forum/create/poll/:forum_post_id?',
  FORUM_POST_DETAILS: '/communities/:community_id/group/:group_id/channels/:channel_id/forumposts/:forum_post_id',
  RESOURCE_POSTS: '/communities/:community_id/group/:group_id/channels/:channel_id/resourceposts',
  RESOURCE_POST_DETAILS:
    '/communities/:community_id/group/:group_id/channels/:channel_id/resourceposts/:resource_post_id',
  RESOURCE_POST_CREATE:
    '/communities/:community_id/group/:group_id/channels/:channel_id/resource/create/:resource_post_id?',
  COMMUNITY_OVERVIEW_DETAILS: '/',
  CALENDAR_POSTS: '/communities/:community_id/group/:group_id/channels/:channel_id/calendarposts',
  ZOOM_POSTS: '/communities/:community_id/group/:group_id/channels/:channel_id/zoomposts',
  ZOOM_EVENT: '/communities/:community_id/group/:group_id/channels/:channel_id/zoomevent/:event_id',
  CALENDAR_POST_DETAILS: '/communities/:community_id/group/:group_id/channels/:channel_id/calendarposts/:event_id',
  CALENDAR_POST_CREATE: '/communities/:community_id/group/:group_id/channels/:channel_id/calendar/create/:event_id?',
  STAGE_CHANNEL: '/communities/:community_id/group/:group_id/channels/:channel_id/stage',
  STREAMING_CHANNEL: '/communities/:community_id/group/:group_id/channels/:channel_id/streaming',
  TEXT_CHANNEL: '/communities/:community_id/group/:group_id/channels/:channel_id/text',
  TEXT_CHANNEL_EMBEDDED: '/communities/:community_id/group/:group_id/channels/:channel_id/embedded/text',
  EVENT_CREATE: '/communities/:community_id/group/:group_id/event/create',
  BOOKMARKS: '/profile/bookmarks',
  HASHTAGS: '/hashtag/:post_type/:query',
  SHOPLISTING: '/shoplisting',
  EMBED: '/communities/:community_id/group/:group_id/channels/:channel_id/iframe',
  STREAMING_CHANNEL_VIEW: '/communities/:community_id/group/:group_id/channels/:channel_id/streaming/:event_id',
  NOTIFICATION: '/notification',
  SEARCH: '/search',
  COMMUNITY_SETTINGS: {
    PLAN_DETAILS: '/community-settings/plan-details',
  },
};

export const OLD_ROUTE_PATH = {
  FORUM_POSTS: '/communities/:community_id/channels/:channel_id/forumposts',
  FORUM_POST_CREATE: '/communities/:community_id/channels/:channel_id/forum/create/:forum_post_id?',
  FORUM_POST_DETAILS: '/communities/:community_id/channels/:channel_id/forumposts/:forum_post_id',
  RESOURCE_POSTS: '/communities/:community_id/channels/:channel_id/resourceposts',
  RESOURCE_POST_DETAILS: '/communities/:community_id/channels/:channel_id/resourceposts/:resource_post_id',
  RESOURCE_POST_CREATE: '/communities/:community_id/channels/:channel_id/resource/create/:resource_post_id?',
  CALENDAR_POSTS: '/communities/:community_id/channels/:channel_id/calendarposts',
  CALENDAR_POST_DETAILS: '/communities/:community_id/channels/:channel_id/calendarposts/:event_id',
  CALENDAR_POST_CREATE: '/communities/:community_id/channels/:channel_id/calendar/create/:event_id?',
  STAGE_CHANNEL: '/communities/:community_id/channels/:channel_id/stage',
  TEXT_CHANNEL: '/communities/:community_id/channels/:channel_id/text',
  EVENT_CREATE: '/communities/:community_id/event/create',
};

export const ROUTE_PATH_PUBLIC = {
  FORUM_POSTS: '/guest/communities/:community_id/group/:group_id/channels/:channel_id/forumposts',
  FORUM_POST_CREATE:
    '/guest/communities/:community_id/group/:group_id/channels/:channel_id/forum/create/:forum_post_id?',
  FORUM_POST_DETAILS: '/guest/communities/:community_id/group/:group_id/channels/:channel_id/forumposts/:forum_post_id',
  RESOURCE_POSTS: '/guest/communities/:community_id/group/:group_id/channels/:channel_id/resourceposts',
  RESOURCE_POST_DETAILS:
    '/guest/communities/:community_id/group/:group_id/channels/:channel_id/resourceposts/:resource_post_id',
  RESOURCE_POST_CREATE:
    '/guest/communities/:community_id/group/:group_id/channels/:channel_id/resource/create/:resource_post_id?',
  COMMUNITY_OVERVIEW_DETAILS: '/guest',
  CALENDAR_POSTS: '/guest/communities/:community_id/group/:group_id/channels/:channel_id/calendarposts',
  ZOOM_POSTS: '/guest/communities/:community_id/group/:group_id/channels/:channel_id/zoomposts',
  ZOOM_EVENT: '/guest/communities/:community_id/group/:group_id/channels/:channel_id/zoomevent/:event_id',
  CALENDAR_POST_DETAILS:
    '/guest/communities/:community_id/group/:group_id/channels/:channel_id/calendarposts/:event_id',
  CALENDAR_POST_CREATE:
    '/guest/communities/:community_id/group/:group_id/channels/:channel_id/calendar/create/:event_id?',
  STAGE_CHANNEL: '/guest/communities/:community_id/group/:group_id/channels/:channel_id/stage',
  TEXT_CHANNEL: '/guest/communities/:community_id/group/:group_id/channels/:channel_id/text',
  TEXT_CHANNEL_EMBEDDED: '/guest/communities/:community_id/group/:group_id/channels/:channel_id/embedded/text',
  EVENT_CREATE: '/guest/communities/:community_id/group/:group_id/event/create',
  BOOKMARKS: '/profile/bookmarks',
  HASHTAGS: '/hashtag/:post_type/:query',
  SHOPLISTING: '/shoplisting',
  EMBED: '/guest/communities/:community_id/group/:group_id/channels/:channel_id/iframe',
};

export const FORUM_REQUEST_ORIGINS = {
  FORUM_LIST_PAGE: 'FORUM_LIST_PAGE',
  FORUM_DETAILS_PAGE: 'FORUM_DETAILS_PAGE',
  COMMUNITY_FEED_PAGE: 'COMMUNITY_FEED_PAGE',
};

export const SEE_MORE_LINES = {
  FORUM_POST: 5,
  FORUM_COMMENT: 5,
  FORUM_REPLY: 5,
  RESOURCE_FEED: 5,
  REPORT_SUMMARY: 5,
  RESOURCE_CHANNEL_LIST_LINES: 3,
  FEED_POST_NUMBER_OF_LINES: 6,
  FEED_POST_NUMBER_OF_LINES_WITH_IMAGE: 3,
  FEED_POST_NUMBER_OF_LINES_WITH_VIDEO: 3,
  FEED_POST_NUMBER_OF_LINES_POLL: 3,
  FEED_POST_RESOURSE_NUMBER_OF_LINES: 3,
  RESOURCE_LIST_POST_RESOURSE_NUMBER_OF_LINES: 3,
};

export const REGEX = {
  IMAGE_TAG_REGEX: /<img[^>]*>/gm,
  IFRAME_TAG_REGEX: /\<iframe[^>]*>.*?\<\/iframe\>/gm,
};

export const POST_TYPE = {
  FORUM: {
    POLL: 'poll',
    NORMAL: 'normal',
  },
  RESOURCE_POST: 'resource_post',
};

export const CHANNEL_TYPE = {
  RESOURCES: 'resources',
  FORUM: 'forum',
};

export const LINE_HEIGHT = {
  POST_LIST: 24,
  FEED_POST_RESOURSE_NUMBER_OF_LINES: 24,
  RESOURCE_LIST_POST_RESOURSE_NUMBER_OF_LINES: 20,
};

export const LOGIN_IFRAME_ID = 'login-ifr';

export const ONBOARD_IFRAME_ID = 'onboard-ifr';

export const COMMUNITY_COLOR_ACCENT = [
  '#F87171',
  '#F59E0B',
  '#10B981',
  '#3B82F6',
  '#6366F1',
  '#8B5CF6',
  '#F472B6',
  '#0EA5E9',
  '#1F2937',
];

export const COMMUNITY_ROLE_COLOR = [
  '#FDE68A',
  '#FECACA',
  '#E5E7EB',
  '#A7F3D0',
  '#BFDBFE',
  '#C7D2FE',
  '#DDD6FE',
  '#FBCFE8',
  '#FED7AA',
  '#D9F99D',
  '#BBF7D0',
  '#A5F3FC',
  '#E9D5FF',
  '#FECDD3',
];

export const URL_REGEX =
  /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/;
export const LINK_REGEX =
  /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
export const VIDEO_URL_REGEX =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%.\+#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%\+.#?&//=]*)/;

export const INBOX_TYPES = {
  INBOX: 'INBOX',
  REQUESTS: 'REQUESTS',
};

export const GLOBAL_SEARCH_TYPES = [
  { key: 'all', identifier: 'all', label: $translatei18n('All') },
  { key: 'forum', identifier: 'forum_posts', label: $translatei18n('Forums') },
  { key: 'resource', identifier: 'resource_posts', label: $translatei18n('Resources') },
  { key: 'calendar_event', identifier: 'calendar_events', label: $translatei18n('Events') },
  { key: 'user', identifier: 'community_users', label: $translatei18n('People') },
  { key: 'group', identifier: 'groups', label: $translatei18n('Groups') },
  { key: 'channel', identifier: 'channels', label: $translatei18n('Channels') },
];

export const GLOBAL_PUBLIC_SEARCH_TYPES = [
  { key: 'all', identifier: 'all', label: $translatei18n('All') },
  { key: 'forum', identifier: 'forum_posts', label: $translatei18n('Forums') },
  { key: 'resource', identifier: 'resource_posts', label: $translatei18n('Resources') },
  { key: 'calendar_event', identifier: 'calendar_events', label: $translatei18n('Events') },
  { key: 'channel', identifier: 'channels', label: $translatei18n('Channels') },
];

export const CREATE_INVITE_EXPIRY = [
  { key: '1_hour', label: 'An Hour' },
  { key: '12_hour', label: '12 Hours' },
  { key: '1_day', label: 'A Day' },
  { key: '1_week', label: 'A Week' },
  { key: '1_month', label: 'A Month' },
  { key: '1_year', label: 'A Year' },
];

export const chatLayouts = {
  whatsapp_telegram_style: 'Whatsapp / Telegram Style',
  slack_discord_style: 'Slack / Discord Style',
};

export const chatLayoutForApi = {
  'Whatsapp / Telegram Style': 'whatsapp_telegram_style',
  'Slack / Discord Style': 'slack_discord_style',
};

export const ScreenShareError = {
  SAFARI_BROWSER:
    'The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.',
  OTHER_BROWSER: 'Permission denied by system',
};

export const USER_IMAGE_TYPE_1 = {
  WIDTH: 24,
  HEIGHT: 24,
};

export const USER_IMAGE_TYPE_2 = {
  WIDTH: 32,
  HEIGHT: 32,
};

export const USER_IMAGE_TYPE_3 = {
  WIDTH: 40,
  HEIGHT: 40,
};

export const USER_IMAGE_TYPE_4 = {
  WIDTH: 80,
  HEIGHT: 80,
};

export const USER_IMAGE_TYPE_5 = {
  WIDTH: 96,
  HEIGHT: 96,
};

export const USER_IMAGE_TYPE_6 = {
  WIDTH: 36,
  HEIGHT: 36,
};

export const USER_IMAGE_TYPE_7 = {
  WIDTH: 48,
  HEIGHT: 48,
};

export const LARGE_SCREEN_BREAKPOINT = 1024;

export const componentType = {
  CALENDER: 'CALENDER',
  PLAN_DETAILS: 'PLAN_DETAILS',
  CHARGEBEE: 'CHARGEBEE',
};

export const ERROR_CODE = {
  LIMIT_EXHAUSTED_CODE: 49124,
  POST_NOT_FOUND_ERROR_CODE: 49132
};

export const UPGRADE_TIMER_TIME = 5000;

export const chargebee_payment = 'chargebee_payment';
export const plan_extended = 'plan_extended';
export const chargebee_payment_restart = 'chargebee_payment_restart';
export const new_price = 'new_price';

// session storage vars
export const SEND_INVITE_TRIGGERED_FROM = 'send_invite_triggered_from';

export const SUBSCRIPTION_AMPLITUDE_EVENT_TITLE = {
  COMMUNITY_UPGRADE_TAPPED: 'community_upgrade_tapped',
  COMMUNITY_TALK_TO_US: 'community_talk_to_us',
  COMMUNITY_CHARGEBEE_MODAL_DISMISSED: 'community_chargebee_modal_dismissed',
  COMMUNITY_LEFT_NAVBAR_ITEM_TAPPED: 'community_left_navbar_item_tapped',
  COMMUNITY_PLAN_UPGRADED_SUCCESS: 'community_plan_upgraded_success',
  COMMUNTIY_PAYMENT_STATUS_REFRESH: 'communtiy_payment_status_refresh',
  COMMUNITY_BOOK_A_DEMO: 'community_book_a_demo',
  PLAN_NAME: {
    PLAN_HUSTLER: 'plan_hustler',
    PLAN_PRO: 'plan_pro',
  },
  SCREEN: {
    MODAL: 'modal',
    SETTINGS_PLANS: 'settings_plans',
  },
  SOURCE: {
    SETTINGS_PLANS: 'settings_plans',
  },
  STATUS: {
    PENDING: 'pending',
    SUCCESS: 'success',
  },
  CHATBOT_INSIDE_COMMUNITY_CLICKED: 'chatbot_inside_community_clicked',
};

export const ASPECT_RATIO = {
  ONE_BY_ONE: 'ONE_BY_ONE',
  THREE_BY_TWO: 'THREE_BY_TWO',
  THREE_BY_FOUR: 'THREE_BY_FOUR',
  SISXTEEN_BY_NINE: 'SISXTEEN_BY_NINE',
  ORIGINAL: 'ORIGINAL',
};

export const ACTOR_TYPE = {
  USER: 'user',
  COMMUNITY: 'community',
};

export const ZAPIER_AMPLITUDE_EVENT_TITLE = {
  AUTOMATION_VIDEO_BANNER_CLOSED: 'automation_video_banner_closed',
  AUTOMATION_VIDEO_WATCHED: 'automation_video_watched',
  AUTOMATION_URL_COPIED: 'automation_url_copied',
  AUTOMATION_ROLE_DELETED: 'automation_role_deleted',
  AUTOMATION_TEMPLATE_OPENED: 'automation_template_opened',
  AUTOMATION_ROLE_ADDED: 'automation_role_added',
  ADD_AUTOMATION_BUTTON_CLICKED: 'add_automation_button_clicked',
};

export const USETIFUL_AMPLITUDE_EVENT_TITLE = {
  GET_STARTED_CLICKED: 'getting_started_clicked',
};

export const INVITE_USERS_AMPLITUDE_EVENT_TITLE = {
  INVITE_LINK_COPY: 'invite_link_copy',
  INVITE_LINK_SHARE_BUTTON_CLICKED: 'invite_link_share_button_clicked',
  CREATE_ROLE_BASED_INVITE: 'create_role_based_invite',
  SHARE_SOCIAL_MEDIA_ICON_CLICKED: 'share_social_media_icon_clicked',
  SEND_INVITE_PROFILE_SECTION: 'send_invite_profile_section',
  INVITATION_MESSAGE_TEXTBOX: 'invitation_message_textbox',
  CREATE_INVITE_CLICKED: 'create_invite_clicked',
};

export const COMMUNITY_PUBLISH_EVENT_TITLE = {
  COMMUNITY_FORUM_POST_PUBLISHED: 'community_forum_post_published',
  COMMUNITY_CALENDAR_EVENT_PUBLISHED: 'community_calendar_event_published',
  COMMUNITY_RESOURCE_POST_CREATED: 'community_resource_post_created',
  COMMUNITY_NEW_CATEGORY_CREATED: 'community_new_category_created',
  COMMUNITY_NEW_CHANNEL_CREATED: 'community_new_channel_created',
  COMMUNITY_NEW_GROUP_CREATED: 'community_new_group_created',
};

export const SHOP_EVENT_TITLE = {
  CONNECT_PAYMENT_GATEWAY_CLICKED: 'connect_payment_gateway_clicked',
  CREATE_NEW_PRODUCT_CLICKED: 'create_new_product_clicked',
  SHOP_NAME_ENTERED: 'shop_name_entered',
  CONNECT_WITH_STRIPE_CLICKED: 'connect_with_stripe_clicked',
  RESUME_ONBOARDING_CLICKED: 'resume_onboarding_clicked',
  DISCONNECT_STRIPE_ACCOUNT_CLICKED: 'disconnect_stripe_account_clicked',
  CHOOSE_PAYMENT_GATEWAY: 'choose_payment_gateway',
  CONNECT_WITH_RAZORPAY_CLICKED: 'connect_with_razorpay_clicked',
  GO_LIVE_CLICKED: 'go_live_clicked',
  DISCONNECT_RAZORPAY_ACCOUNT: 'disconnect_razorpay_account',
  CREATE_PRODUCT_CLICKED: 'create_product_clicked',
};

export const DROP_DOWN = {
  VARIANT_ONE: 'VARIANT_ONE',
  VARIANT_TWO: 'VARIANT_TWO',
};

export const FEED_CONTENT_TYPE = {
  FEED: 'feed',
  RESOURCES: 'resources',
  FORUM: 'forum',
};

export const PAGE_TYPE = {
  FEED: 'feed',
  RESOURCES: 'resources',
  FORUM: 'forum',
};

export const SCHEDULE_AND_DRAFTS = {
  DRAFT: 'draft',
  SCHEDULED: 'scheduled',
  PUBLISHED: 'published'
};

export const ROOM_PROVIDER = {
  DYTE: "dyte"
}

export const COMMUNITY_POST = {
  TYPE: {
    FORUM: "forum",
    RESOURCE: "resource_post",
  },
  EVENT: {
    NAME: "Post liked"
  }
}

export const LOGIN = {
  NAME: "Login",
  LOGIN_TYPE_GCP: "GCP",
  LOGIN_TYPE_SSO: "SSO"
}

export const SIGNUP = {
  NAME: "Signups",
  SIGNUP_TYPE_GCP: "GCP",
  SIGNUP_TYPE_SSO: "SSO"
}

export const COMMENT_CREATED = {
  NAME: "Comment created"
}

export const PLACE_TYPE = {
  MANAGE_ROLE: "MANAGE_ROLE",
  MANAGE_ROLE_WIEW_ONLY: "MANAGE_ROLE_WIEW_ONLY"
}
