import { createElement } from 'react';

// const rule = /([@][^]+)/gi;
const rule = /([@]\[[-@.\/#&+\w\s ]+\]\([-@.\/#&+\w\s ]+\))|(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@%_\+.~#?&//=]*)|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;

const parse = (value, renderer, action) => value?.split(rule)?.map((chunk) => {
  if (chunk?.match(rule)) {
    return renderer(chunk, action);
  }

  return chunk;
});

const defaultMentionRenderer = (createElement) => (mention, onClick) => createElement(
  'span',
  {
    key: mention,
    onClick: onClick ? (e) => onClick(mention, e) : null,
  },
  mention,
);

export default function MentionsParser({ children, renderMention, onMentionClick }) {
  const contents = typeof children === 'object' && children.length
    ? !isNaN(children.length)
      ? children[0]
      : children
    : children;
  const mentionRenderer = renderMention || defaultMentionRenderer(createElement);
  const parsed = parse(contents, mentionRenderer, onMentionClick);

  return parsed;
}
