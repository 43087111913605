// import moment from 'moment';
import dayjs from 'dayjs';
import advancedFormat from "dayjs/plugin/advancedFormat"
dayjs.extend(advancedFormat)

function SectionHeader({ section }) {
  const REFERENCE = dayjs();
  const TODAY = REFERENCE.clone().startOf('day');
  const YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');

  const isToday = (momentDate) => momentDate.isSame(TODAY, 'd');
  const isYesterday = (momentDate) => momentDate.isSame(YESTERDAY, 'd');

  const getTitle = () => {
    const date = dayjs(section.title, 'YYYY-MM-DD');
    const startDate = date.startOf('day');
    if (isToday(startDate)) {
      return 'Today';
    }
    if (isYesterday(startDate)) {
      return 'Yesterday';
    }
    return startDate.format('Do MMM, YYYY');
  };

  return (
    <div className="relative">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="w-full border-t divider" />
      </div>
      <div className="relative flex justify-center">
        <span className="px-2 text-sm text__body">{getTitle()}</span>
      </div>
    </div>
  );
}

export default SectionHeader;
