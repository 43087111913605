import React, { useState, lazy, useEffect } from 'react';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { XIcon, PlusIcon } from '@heroicons/react/solid';

import './picker.style.css';
import { classNames } from '../../../../constants/utils';
import GifPicker from './GifPicker';
import EmojiPicker from 'emoji-picker-react';

let SKIN_TONE_MEDIUM_DARK = EmojiPicker;
(async () => {
  SKIN_TONE_MEDIUM_DARK = (await import('emoji-picker-react')).SKIN_TONE_MEDIUM_DARK;
})();

function Picker({
  inputRef,
  isChat = true,
  handleSelectedGif,
  onEmojiClickSubmit,
  onEmojiSelect,
  isGifDisabled = false,
}) {
  const [isEmojiTabActive, setIsEmojiTabActive] = useState(true);

  const onEmojiClick = (event, emojiObject) => {
    if (isChat) {
      // eslint-disable-next-line no-param-reassign
      onEmojiSelect(emojiObject);
    } else {
      onEmojiClickSubmit(emojiObject.emoji);
    }
  };

  useEffect(() => {
    if (isGifDisabled) {
      setIsEmojiTabActive(true);
    }
  }, [isGifDisabled]);

  return (
    <Popover as="div" className="inline-block text-left ltr:mr-2.5 rtl:ml-2.5">
      {({ open, close }) => (
        <>
          <PopoverButton className="h-8 w-8 p-0 inline-flex text-gray-800 items-center justify-center border border-transparent text-sm leading-4 font-medium rounded-full focus:outline-none focus:ring-0 focus:ring-offset-0 chat__footer__btn">
            {open ? <XIcon className="w-4 h-4" /> : <PlusIcon className="w-4 h-4" />}
          </PopoverButton>

          <PopoverPanel
            className={`absolute z-[1000] ltr:left-4 rtl:right-4 ${
              isChat ? 'bottom-12' : 'top-[80%]'
            } w-80 md:w-96 rounded-md shadow-lg menu__options__container border focus:outline-none`}
          >
            <div className="border-b tab__container mx-3 pt-2">
              <div className="flex">
                <nav className="flex" x-descriptions="Tab component">
                  <button
                    type="button"
                    className={classNames(
                      isEmojiTabActive ? 'border-b tab--active' : 'tab',
                      'py-2 text-sm font-semibold w-20',
                    )}
                    onClick={() => setIsEmojiTabActive(true)}
                  >
                    Emoji
                  </button>
                  {!isGifDisabled && (
                    <button
                      type="button"
                      className={classNames(
                        !isEmojiTabActive ? 'border-b tab--active' : 'tab',
                        'py-2 text-sm font-semibold w-20',
                      )}
                      onClick={() => setIsEmojiTabActive(false)}
                    >
                      GIF
                    </button>
                  )}
                </nav>
                <div className="flex-1 w-2 h-2" />
              </div>
            </div>
            <div className="p-2">
              {isEmojiTabActive ? (
                <EmojiPicker
                  onEmojiClick={onEmojiClick}
                  disableAutoFocus
                  skinTone={SKIN_TONE_MEDIUM_DARK}
                  groupNames={{ smileys_people: 'PEOPLE' }}
                  searchPlaceholder="Search Emoji"
                  native
                  pickerStyle={{
                    width: '100%',
                    background: 'transparent',
                    boxShadow: 'none',
                    border: 'none',
                    height: '24rem',
                  }}
                />
              ) : (
                <GifPicker
                  openGifPicker={!isEmojiTabActive}
                  closeGifPicker={close}
                  handleSelectedGif={handleSelectedGif}
                />
              )}
            </div>
          </PopoverPanel>
        </>
      )}
    </Popover>
  );
}

export default Picker;
