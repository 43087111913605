import parse, { domToReact } from 'html-react-parser';
import { imageFetch } from '../../../services/preSignedAws';
import { USER_IMAGE_TYPE_3 } from '../../../constants';

export const getImage = (image) => {
  const images = imageFetch(image, {
      resize: {
        height: USER_IMAGE_TYPE_3.HEIGHT,
        width: USER_IMAGE_TYPE_3.WIDTH,
        fit: 'inside',
      }
    });
  return images;
};

export const getImageURL = (
  imageURL,
  fallbackURL = 'uploads/user/image/5c0cc1e3-9acb-41d7-98cd-c598fceb6728/1617256955',
) => {
  if (imageURL !== undefined && imageURL !== null && imageURL.length > 0) {
    // general case for images uploaded directly as cover_image from web and have relative path
    if (imageURL && imageURL !== '' && imageURL.substring(0, 7) === 'uploads') return imageFetch(imageURL, {}, false);

    // case for images taken from post html on mobile or web
    if (imageURL.includes('\\"')) {
      const url = imageURL.slice(2, -2);
      if (url && url !== '' && url.substring(0, 7) === 'uploads') return imageFetch(url, {}, false);
      return url.toString();
    }

    // case for images uploaded from web as cover_image and does not have relative path
    return imageURL;
  }
  // If no image then use fallback image
  return getImage(fallbackURL);
};

export const extractTextFromHtml = (richText) => {
  let text = '';
  const renderNode = (node) => {
    if (
      // node.name === 'a' ||
      node.name === 'img' ||
      node.name === 'video' ||
      node.name === 'iframe' ||
      (node.name === 'div' && node.attribs?.class?.includes('document'))
    ) {
      return <></>;
    }
    if (node.type === 'text' && node?.data !== '&nbsp;' && node?.data?.trim() != '') {
      if (text[text.length - 1] && text[text.length - 1] !== ' ') {
        text += ' ';
      }
      text += node.data;
    }
    return domToReact(node);
  };

  try {
    parse(richText, {
      replace: (domNode) => {
        return renderNode(domNode);
      },
    });
    return text;
  } catch (error) {
    return text;
  }
};
