import {
  CREATE_PRODUCT,
  CREATE_PRODUCT_SUCCESS,
  DELETE_PRODUCT,
  DELETE_PRODUCT_SUCCESS,
  SET_UPDATE_PRODUCT_ID,
  SET_COMMUNITY_SHOP,
  UPDATE_SELECT_PRODUCT_MODAL,
  UPDATE_STRIPE_CONNECTION_STATE,
  UPDATE_COMMUNITY_SHOP,
  SET_CREATE_SHOP_LOADING,
  SET_TEMP_PRODUCT_DATA,
  GET_SHOP_ITEMS,
  GET_SHOP_ITEMS_SUCCESS,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_SUCCESS,
  PURCHSE_PRODUCT,
  SET_PURCHASE_SUCCESS_DATA,
  INITIALIZE_STRIPE_PURCHASE,
  VERIFY_STRIPE_PURCHASE,
  UPDATE_CREATE_PRODUCT_MODAL,
  GET_PURCHASED_SHOP_ITEMS_SUCCESS,
  GET_PURCHASED_SHOP_ITEMS,
  GET_COMMUNITY_SHOP,
  SET_FORM_SUBMITTINIG,
  GET_SHOP_ITEMS_FAILED,
  GET_PURCHASED_SHOP_ITEMS_FAILED,
  VERIFY_RAZORPAY_PURCHASE,
  UPDATE_CURRENCY,
  DISCONNECT_ACCOUNT_STRIPE
} from './actionTypes';

export const setShopData = (payload) => ({
  type: SET_COMMUNITY_SHOP,
  payload,
});

export const getCommunityShop = (payload) => ({
  type: GET_COMMUNITY_SHOP,
  payload,
});

export const setCommunityShopLoading = ({ isLoading }) => ({
  type: SET_CREATE_SHOP_LOADING,
  payload: { isLoading },
});

export const updateShopData = (payload) => ({
  type: UPDATE_COMMUNITY_SHOP,
  payload,
});

export const getShopItems = (payload) => ({
  type: GET_SHOP_ITEMS,
  payload,
});

export const getShopItemsSuccess = (payload) => ({
  type: GET_SHOP_ITEMS_SUCCESS,
  payload,
});

export const getShopItemsFailed = (payload) => ({
  type: GET_SHOP_ITEMS_FAILED,
  payload,
});

export const getPurchasedShopItems = (payload) => ({
  type: GET_PURCHASED_SHOP_ITEMS,
  payload,
});

export const getPurchasedShopItemsSuccess = (payload) => ({
  type: GET_PURCHASED_SHOP_ITEMS_SUCCESS,
  payload,
});

export const getPurchasedShopItemsFailed = (payload) => ({
  type: GET_PURCHASED_SHOP_ITEMS_FAILED,
  payload,
});

export const updateStripeConnectionState = ({ isStripeConnected }) => ({
  type: UPDATE_STRIPE_CONNECTION_STATE,
  payload: { isStripeConnected },
});

// modal to select product type (role, category, group)
export const updateSelectProductModal = ({ displaySelectProductModal }) => ({
  type: UPDATE_SELECT_PRODUCT_MODAL,
  payload: { displaySelectProductModal },
});

// modal to write/edit product info (form)
export const updateCreateProductModal = ({ showCreateProductModal }) => ({
  type: UPDATE_CREATE_PRODUCT_MODAL,
  payload: { showCreateProductModal },
});

export const createProduct = (payload) => ({
  type: CREATE_PRODUCT,
  payload,
});

export const createProductSuccess = (payload) => ({
  type: CREATE_PRODUCT_SUCCESS,
  payload,
});

export const setEditProductId = ({ editProductId }) => ({
  type: SET_UPDATE_PRODUCT_ID,
  payload: editProductId,
});

export const editProduct = (payload) => ({
  type: UPDATE_PRODUCT,
  payload,
});

export const editProductSuccessfull = (payload) => ({
  type: UPDATE_PRODUCT_SUCCESS,
  payload,
});

export const deleteProductListing = (payload) => ({
  type: DELETE_PRODUCT,
  payload,
});

export const deleteProductSuccess = (payload) => ({
  type: DELETE_PRODUCT_SUCCESS,
  payload,
});

export const setTempProductData = (payload) => ({
  type: SET_TEMP_PRODUCT_DATA,
  payload,
});

export const purchaseProduct = (payload) => ({
  type: PURCHSE_PRODUCT,
  payload,
});

export const setPurchaseSuccessData = (payload) => ({
  type: SET_PURCHASE_SUCCESS_DATA,
  payload,
});

export const initializePurchase = (payload) => ({
  type: INITIALIZE_STRIPE_PURCHASE,
  payload,
});

export const verifyStripePurchase = (payload) => ({
  type: VERIFY_STRIPE_PURCHASE,
  payload,
});

export const verifyRazorpayPurchase = (payload) => ({
  type: VERIFY_RAZORPAY_PURCHASE,
  payload,
});

export const setFormSubmitting = ({ isFormSubmitting }) => ({
  type: SET_FORM_SUBMITTINIG,
  payload: { isFormSubmitting },
});

export const updateCurrency = (currency) => (
  {
  type: UPDATE_CURRENCY,
  payload:  currency ,
});

export const disconnectStripeAccount = (currency) => (
  {
  type: DISCONNECT_ACCOUNT_STRIPE,
  payload:  currency ,
});
