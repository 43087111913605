export default function CommunityNotFound() {
  return (
    <div className="h-screen flex flex-col bg-white">
      <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto">
        <div className="py-16 flex justify-center">
          <div className="text-center max-w-lg">
            <p className="text-xl font-semibold text-indigo-500 uppercase tracking-wide">404 error</p>
            <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">Page not found.</h1>
            <p className="mt-2 text-base text-gray-500">
              Sorry, we couldn’t find the page you’re looking for. You either do not have access or the page does not
              exist. Please check the community URL or contact an admin.
            </p>
          </div>
        </div>
      </main>
    </div>
  );
}
