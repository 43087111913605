import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentLoggedInUser } from '../../../selectors/ProfileSelectors';
import { getCommonAvtarName } from '../../../utils/Utils';

export const COMMON_NAME_AVATAR_TYPE = {
  CIRCULAR: 'CIRCULAR',
  ROUND: 'ROUND',
};

export const COMMON_NAME_AVATAR_SIZE = {
  SM: 'SM',
  MD: 'MD',
  LG: 'LG',
  LG2: 'LG2',
  XS: 'XS',
  XL: 'XL',
  XXL: 'XXL',
  H32: 'H32',
};

export default function CommonNameAvatar({
  name,
  type = COMMON_NAME_AVATAR_TYPE.ROUND,
  className,
  size = COMMON_NAME_AVATAR_SIZE.MD,
  onClick,
}) {
  const user = useSelector(getCurrentLoggedInUser);
  const [nameInitial, setNameInitial] = useState(name);

  useEffect(() => {
    if (!name) {
      setNameInitial(getCommonAvtarName(user?.first_name, user?.last_name));
    } else {
      setNameInitial(name);
    }
  }, [name, user]);

  return (
    <span
      className={classNames({
        'inline-flex items-center justify-center bg-gray-500': true,
        'rounded-full': type === COMMON_NAME_AVATAR_TYPE.CIRCULAR,
        rounded: type === COMMON_NAME_AVATAR_TYPE.ROUND,
        'h-6 w-6': size === COMMON_NAME_AVATAR_SIZE.XS,
        'h-8 w-8': size === COMMON_NAME_AVATAR_SIZE.SM,
        'h-10 w-10': size === COMMON_NAME_AVATAR_SIZE.MD,
        'h-12 w-12': size === COMMON_NAME_AVATAR_SIZE.LG,
        'h-16 w-16': size === COMMON_NAME_AVATAR_SIZE.LG2,
        'h-24 w-24': size === COMMON_NAME_AVATAR_SIZE.XL,
        'h-28 w-28': size === COMMON_NAME_AVATAR_SIZE.XXL,
        'h-32 w-32': size === COMMON_NAME_AVATAR_SIZE.H32,
        [className]: true,
      })}
      onClick={onClick}
    >
      <span
        className={classNames({
          'font-Geomanist-book text-white': true,
          'text-lg': size === COMMON_NAME_AVATAR_SIZE.MD || size === COMMON_NAME_AVATAR_SIZE.LG,
          'text-md': (size === size) === COMMON_NAME_AVATAR_SIZE.SM,
          'text-sm': size === COMMON_NAME_AVATAR_SIZE.XS,
          'text-xl': size === COMMON_NAME_AVATAR_SIZE.XL || size === COMMON_NAME_AVATAR_SIZE.LG2,
          'text-3xl': size === COMMON_NAME_AVATAR_SIZE.XXL,
          'text-4xl': size === COMMON_NAME_AVATAR_SIZE.H32,
        })}
      >
        {nameInitial}
      </span>
    </span>
  );
}
