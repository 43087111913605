import React from 'react'
import ReactPlayer from 'react-player';
import { useSelector } from 'react-redux';
import TimeAgo from 'timeago-react';
import Truncate from 'react-truncate';
import CommonNameAvatar from '../../../../components/shared/tailwind/NameAvatar';
import { classNames, history, prepareUserImageName } from '../../../../constants/utils';
import { getActiveCommunityAccentColor, getActiveCommunityFontsClass, getActiveCommunityId, getActiveGroup } from '../../../../selectors/CommunitySelectors';
import { imageFetch } from '../../../../services/preSignedAws';
import { parseHtml } from '../../forum/forumUtils';
import DisplayTags from '../../../../components/Tags/DisplayTags';
import { getFullName } from '../../../../utils/Utils';

const ForumPosts = ({ forumPostData, userData, closeDrawer }) => {
  const { primaryFont } = useSelector(getActiveCommunityFontsClass);
  const { accentColor } = useSelector(getActiveCommunityAccentColor)
  const activeCommunityId = useSelector(getActiveCommunityId)

  const handleForumClick = () => {
    history.push(
      `/communities/${activeCommunityId}/group/${forumPostData?.group_id}/channels/${forumPostData?.channel_id}/forumposts/${forumPostData?.id}`,
    );
    closeDrawer();
  };

  return (
    <div className='card rounded shadow p-2 post-wrapper w-full cursor-pointer' onClick={handleForumClick}>
      <div className="flex items-center mb-4 post-header">
        <div className="flex items-center">
          {userData?.image ? (
            <img
              className="user-img flex-none"
              src={imageFetch(userData?.image)}
              onError={() => setIsImageLoadingError(true)}
              alt="profile"
            />
          ) : (
            <CommonNameAvatar
              className="rounded-lg"
              name={prepareUserImageName(userData?.first_name, userData?.last_name)}
            />
          )}
          <div>
            <p className={classNames('text-sm ltr:pl-2 rtl:pr-2 text__title', primaryFont)}>{getFullName(userData?.user?.first_name, userData?.user?.last_name)}</p>
            <p className={classNames('text-xs ltr:pl-2 rtl:pr-2 text__title', primaryFont)}>{`@${userData?.user?.username}`}</p>
          </div>
        </div>
        <div className="ltr:ml-auto rtl:mr-auto flex flex-col items-end justify-center">
          {(forumPostData?.last_edited_at && (forumPostData?.last_edited_at !== forumPostData?.created_at)) && (
            <span className={classNames('text-xs font-Geomanist-book text__info', primaryFont)}>Edited</span>
          )}
        </div>
        <TimeAgo
          className={classNames('text-xs font-Geomanist-book text__info', primaryFont)}
          datetime={forumPostData?.created_at}
        />
      </div>
      <div className="text-base text__body break-words">
        <Truncate
          lines={4}
          ellipsis={
            <span className="text-xs" style={{ color: accentColor }}>
              ... Read more
            </span>
          }
        >
          {parseHtml(forumPostData?.description)}
        </Truncate>
      </div>
      {forumPostData?.image_url && (
        <div className="mt-2">
          <div className="h-full w-full mb-2 relative">
            <img src={imageFetch(forumPostData?.image_url)}
              className="rounded-md bottom-2 max-h-96 mx-auto"
            />
          </div>
        </div>
      )}
      {Object.keys(forumPostData?.media_types)?.length !== 0 &&
        Object.entries(forumPostData?.media_types)?.map((playbackId) => (
          <div className="mt-2" key={playbackId[0]}>
            <ReactPlayer
              url={`https://stream.mux.com/${playbackId[0]}.m3u8`}
              controls
              width={'100%'}
              height={playbackId[1]?.toLowerCase() === "video" ? '400px' : "60px"}
              style={{}}
            />
          </div>
        ))}

      {/* For Displaying Tags */}
      <DisplayTags postTags={forumPostData?.post_tags} />

    </div>
  )
}

export default ForumPosts
