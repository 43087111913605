/* eslint-disable no-case-declarations */
import _ from 'lodash';
import {
  ADD_BLOCKED_USER_IDS,
  AUTH_LOG_OUT_SUCCESS,
  REMOVE_BLOCKED_USER_IDS,
  RESET_TEXT_STATE,
  SET_EARLIER_MESSAGES,
  SET_LAST_MESSAGE,
  SET_LOAD_MORE,
  SET_TEXT_MESSAGES,
  TEXT_CHANNEL_CONNECTION_SUCCESS,
  UPDATE_TEXT_STATE,
} from '../actions/actionTypes';

const initialState = {
  channelLoading: true,
  isConnected: false,
  channelData: {},
  textChannel: null,
  channelDetails: {},
  messages: [],
  // isMinimised: false,
  lastMessageId: null,
  canLoadMore: false,
  isTypingText: '',
  blockedUserIds: [],
  channelConfigs: {
    canSendMessage: false,
    canStartVoice: false,
    canManageSpeakers: false,
    canManageMessages: false,
    canManageUsers: false,
    canManageSettings: false,
    canViewStage: false,
  },
  roomType: 'main',
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case TEXT_CHANNEL_CONNECTION_SUCCESS:
      return {
        ...state,
        channelData: payload.channelData,
        textChannel: payload.textChannel,
        channelConfigs: payload.channelConfigs,
        channelLoading: false,
        isConnected: true,
      };
    case UPDATE_TEXT_STATE:
      return { ...state, ...payload };
    case SET_LOAD_MORE:
      return { ...state, canLoadMore: payload.value };
    case SET_EARLIER_MESSAGES:
      const filteredMessages = payload.messages.filter((item) => {
        if (state.blockedUserIds.includes(item?.user._id)) {
          return false;
        }
        return true;
      });
      return {
        ...state,
        messages: [...state.messages, ...filteredMessages],
        hasEarlierMessages: true,
      };
    case SET_LAST_MESSAGE:
      return { ...state, lastMessageId: payload.id };
    case SET_TEXT_MESSAGES:
      return { ...state, messages: payload.messages };
    case ADD_BLOCKED_USER_IDS:
      const newArray = _.concat(state.blockedUserIds, payload.users);
      return { ...state, blockedUserIds: newArray };
    case REMOVE_BLOCKED_USER_IDS:
      const updatedBlockedUserIds = state.blockedUserIds.filter((id) => id !== payload.value);
      return { ...state, blockedUserIds: updatedBlockedUserIds };
    case RESET_TEXT_STATE:
      return initialState;
    case AUTH_LOG_OUT_SUCCESS:
      return initialState;
    default:
      return state;
  }
};

export default reducer;
