import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { CHANNEL_TYPES } from '../../constants';
import { history } from '../../constants/utils';
import { fetchSearchResults } from '../../services/SearchService';
import { setActiveChannel } from '../../store/actions/channelActions';
import ChannelIcon from '../layout/ChannelIcon';
import Pagination from '../shared/Pagination';
import { SearchLoadingSpinner } from './GlobalSearch';

function ChannelList({
  searchQuery,
  channelData,
  activeCommunity,
  activeGroupId,
  closeDrawer,
  onCommonPage,
  handleViewDetails,
  handleScrollTop,
}) {
  const [intitalData, setIntitalData] = useState();
  const [allChannelsData, setAllChannelsData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  function handleChannelClick(channelData) {
    let channelRoute;

    switch (channelData?.channel_type) {
      case CHANNEL_TYPES.FORUM:
        channelRoute = 'forumposts';
        break;
      case CHANNEL_TYPES.RESOURCE:
        channelRoute = 'resourceposts';
        break;
      case CHANNEL_TYPES.STAGE:
        channelRoute = 'stage';
        break;
      case CHANNEL_TYPES.CALENDER:
        channelRoute = 'calendarposts';
        break;
      case CHANNEL_TYPES.TEXT:
        channelRoute = 'text';
        break;
      case CHANNEL_TYPES.BOOKMARKS:
        channelRoute = 'bookmarks';
        break;

      default:
        break;
    }

    dispatch(setActiveChannel(channelData));
    history.push(
      `/communities/${activeCommunity?.id}/group/${activeGroupId}/channels/${channelData?.id}/${channelRoute}`,
      {
        breadcrumb_data: `${activeCommunity?.name} > ${channelData?.name}`,
        isFromOverview: window.location.pathname === "/" ? true : false
      },
    );
    closeDrawer();
  }

  async function fetchChannelsData(query, page) {
    setIsLoading(true);
    const { data } = await fetchSearchResults(query, 'channel', page, activeCommunity?.id, activeGroupId);
    setIsLoading(false);
    if (!data?.entries) return;
    setAllChannelsData(data);
    handleScrollTop();
  }

  useEffect(() => {
    if (channelData?.entries) setIntitalData(channelData);
    if (!allChannelsData?.total_count) setAllChannelsData(channelData);
  }, [channelData]);

  useEffect(() => {
    if (onCommonPage) return;
    fetchChannelsData(searchQuery, 1);
  }, [onCommonPage]);

  return (
    <div className={`mb-8 ${!onCommonPage ? 'flex-1' : ''}`}>
      <div className="flex flex-row align-center justify-between">
        <p className="font-bold text__title">
          {$translatei18n('Channels')} <span className="font-normal text__description">{`(${channelData?.total_count})`}</span>
        </p>
        {onCommonPage && handleViewDetails && (
          <span
            className="underline text-xs cursor-pointer text__link"
            onClick={handleViewDetails}
            style={{ color: activeCommunity?.accent_color }}
          >
            See all
          </span>
        )}
      </div>
      <AllChannels
        listData={onCommonPage ? intitalData?.entries : allChannelsData?.entries}
        handleChannelClick={handleChannelClick}
      />
      {isLoading && <SearchLoadingSpinner />}
      {allChannelsData?.links?.pages && allChannelsData.links.pages > 1 && !onCommonPage && (
        <Pagination
          handlePageClick={(page) => fetchChannelsData(searchQuery, page?.selected + 1)}
          pageCount={allChannelsData?.links?.pages}
          className="mt-6"
        />
      )}
    </div>
  );
}

function AllChannels({ listData, handleChannelClick }) {
  return (
    <div className="p-4 pb-2 shadow rounded-lg mt-2 card">
      {listData?.data?.map((data) => {
        const { id, name, channel_type } = data?.attributes;
        const channelCategory = listData?.included?.find(
          (item) => item?.attributes?.id === data?.attributes?.community_category_id,
        );

        return (
          <div
            key={id}
            className="mb-2 py-1 flex items-start text-14 rounded-md cursor-pointer text__body"
            onClick={() => handleChannelClick(data?.attributes)}
          >
            <ChannelIcon channelType={channel_type} />
            <div className="break-all flex align-center">
              {name} <span className="ml-3 text__info text-xs">in {channelCategory?.attributes?.name}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ChannelList;
