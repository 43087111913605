import React from 'react';
import { GLOBAL_SEARCH_TYPES } from '../../constants';

function SearchTypePills({ activeType, accentColor, handleTypeClick, enabledTypes }) {
  return (
    <div className="flex flex-wrap flex-row align-center gap-x-3 gap-y-2.5 pt-4 pb-6">
      {GLOBAL_SEARCH_TYPES.map((type) => {
        const btnDisabled = type.identifier !== 'all' && !enabledTypes?.includes(type.identifier);

        return (
          <button
            key={type.key}
            type="button"
            className={`inline-flex card items-center px-3 py-0.5 rounded-full justify-center text-14 text__description border border-gray-500 cursor-pointer hover:bg-gray-50 ${
              activeType === type.key ? 'btn__primary' : ''
            }`}
            onClick={() => handleTypeClick(type.key)}
            style={
              activeType === type.key
                ? {
                    backgroundColor: accentColor,
                    color: 'white',
                    borderColor: 'transparent',
                  }
                : btnDisabled
                ? { cursor: 'default', opacity: 0.5 }
                : undefined
            }
            disabled={btnDisabled}
            title={btnDisabled ? `No results for ${type.label}` : type.label}
          >
            {type.label}
          </button>
        );
      })}
    </div>
  );
}

export default SearchTypePills;
