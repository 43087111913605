import { PlusIcon } from '@heroicons/react/outline';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { analyticsDefaults, classNames, getButtonCSS } from '../../constants/utils';
import { getActiveCommunityAccentColor, getActiveCommunityFontsClass } from '../../selectors/CommunitySelectors';
import { getShopData } from '../../selectors/ShopSelectors';
import { updateSelectProductModal } from '../../store/actions/shopActions';
import AnalyticsService from '../../services/AnalyticsService';
import { SHOP_EVENT_TITLE } from '../../constants';

export default function CreateShopButton() {
  const activeCommunityAccentColor = useSelector(getActiveCommunityAccentColor);
  const { primaryFont } = useSelector(getActiveCommunityFontsClass);
  const shopData = useSelector(getShopData);

  const dispatch = useDispatch();

  const displaySelectProductModal = () => {
    AnalyticsService.trackAmplitudeEvent(SHOP_EVENT_TITLE.CREATE_NEW_PRODUCT_CLICKED, {
      ...analyticsDefaults(true),
      source: 'topbar',
    });
    dispatch(updateSelectProductModal({ displaySelectProductModal: true }));
  };

  return (
    <div className="xs:mr-0 mr-3">
      <button
        style={getButtonCSS(activeCommunityAccentColor)}
        className={classNames(
          `font-Geomanist border border-transparent p-2 md:py-2 md:px-4
          rounded flex justify-center items-center btn__primary lg:min-w-[150px]`,
          primaryFont,
        )}
        onClick={displaySelectProductModal}
        type="button"
      >
        <PlusIcon className="w-4 h-4" />
        <span className="hidden ltr:ml-1 rtl:mr-1 text-sm md:block font-semibold capitalize">create product</span>
      </button>
    </div>
  );
}
