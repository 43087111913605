import React, { useEffect, useState } from 'react';
import { format, formatDistanceToNow, parseISO } from 'date-fns';
import { imageFetch } from '../../services/preSignedAws';
import { history } from '../../constants/utils';
import { CubeIcon } from '@heroicons/react/outline';
import { getImageURL } from '../../views/channel/MediaResources/resourceUtils';
import Pagination from '../shared/Pagination';
import { fetchSearchResults } from '../../services/SearchService';
import { SearchLoadingSpinner } from './GlobalSearch';

function ResourcePosts({
  searchQuery,
  resourceData,
  activeCommunity,
  activeGroupId,
  closeDrawer,
  onCommonPage,
  handleViewDetails,
  handleScrollTop,
}) {
  const [intitalData, setIntitalData] = useState();
  const [resourcePostsData, setResourcePostsData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const handleResourceClick = (channelId, resourceId) => {
    history.push(
      `/communities/${activeCommunity?.id}/group/${activeGroupId}/channels/${channelId}/resourceposts/${resourceId}`,
      { isFromOverview: window.location.pathname === "/" ? true : false }
    );
    closeDrawer();
  };

  async function fetchResourcePosts(query, page) {
    setIsLoading(true);
    const { data } = await fetchSearchResults(query, 'resource', page, activeCommunity?.id, activeGroupId);
    setIsLoading(false);
    if (!data?.entries) return;
    setResourcePostsData(data);
    handleScrollTop();
  }

  useEffect(() => {
    if (resourceData?.entries) setIntitalData(resourceData);
    if (!resourcePostsData?.total_count) setResourcePostsData(resourceData);
  }, [resourceData]);

  useEffect(() => {
    if (onCommonPage) return;
    fetchResourcePosts(searchQuery, 1);
  }, [onCommonPage]);

  return (
    <div className={`mb-8 ${!onCommonPage ? 'flex-1' : ''}`}>
      <div className="flex flex-row align-center justify-between">
        <p className="font-bold text__title">
          Resource <span className="font-normal text__description">{`(${resourceData?.total_count})`}</span>
        </p>
        {onCommonPage && handleViewDetails && (
          <span
            className="underline text-xs cursor-pointer text__link"
            onClick={handleViewDetails}
            style={{ color: activeCommunity?.accent_color }}
          >
            See all
          </span>
        )}
      </div>
      <ResourceList
        listData={onCommonPage ? intitalData?.entries : resourcePostsData?.entries}
        handleResourceClick={handleResourceClick}
        activeCommunity={activeCommunity}
      />
      {isLoading && <SearchLoadingSpinner />}
      {resourcePostsData?.links?.pages && resourcePostsData.links.pages > 1 && !onCommonPage && (
        <Pagination
          handlePageClick={(page) => fetchResourcePosts(searchQuery, page?.selected + 1)}
          pageCount={resourcePostsData?.links?.pages}
          className="mt-6"
        />
      )}
    </div>
  );
}

function ResourceList({ listData, handleResourceClick, activeCommunity }) {
  return (
    <>
      {listData?.data?.map((resource) => {
        const { id, created_at, title, user_id, channel_id, thumbnail_url } = resource?.attributes;
        const userData = listData?.included.find((item) => item.type === 'user' && item?.attributes?.id === user_id);
        const postChannel = listData?.included.find(
          (item) => item.type === 'channel' && item?.attributes?.id === channel_id,
        );

        return (
          <div
            key={id}
            className="p-4 shadow rounded-lg mt-2 card cursor-pointer"
            onClick={() => handleResourceClick(channel_id, id)}
          >
            <div className="flex flex-row align-center justify-between text-xs text__body mb-3 pb-2 border-b border-gray-100">
              <p className="text__body">
                {$translatei18n('PostedIn')} <strong>{postChannel?.attributes?.name}</strong>
              </p>
              <span className="text__info">{formatDistanceToNow(parseISO(created_at), { addSuffix: true })}</span>
            </div>
            <div className="flex flex-row">
              <div className="flex flex-col justify-between flex-1">
                <p className="text__body">{title}</p>
                <div className="text-xs text__description">
                  <span className="pr-1">{format(parseISO(created_at), 'dd/MM/yyyy')}</span> &bull;{' '}
                  <span className="pl-1">{userData?.attributes?.name}</span>
                </div>
              </div>
              <div className="w-36 h-20 ml-6">
                <div
                  className="aspect-w-724 aspect-h-385 bg-cover bg-center bg-no-repeat rounded overflow-hidden"
                  style={
                    !thumbnail_url
                      ? { backgroundImage: `url("${imageFetch(activeCommunity?.cover_image)}")` }
                      : undefined
                  }
                >
                  {thumbnail_url ? (
                    <img className="h-full w-full" src={getImageURL(thumbnail_url)} alt="cover" />
                  ) : (
                    <>
                      <div className="bg-black opacity-40" />
                      <div className="flex justify-center items-center">
                        <CubeIcon className="text-white h-2/5 w-2/5" />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default ResourcePosts;
