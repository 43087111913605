import React, { useState, useEffect, useRef, Fragment, useId, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import { Oval } from 'react-loader-spinner';
import { PhotographIcon, PaperAirplaneIcon, XIcon } from '@heroicons/react/outline';
import { useParams } from 'react-router-dom';
import { getPresignedPostData, uploadFileToS3, imageFetch } from '../../../../services/preSignedAws';
import {
  getActiveCommunityFontsClass,
  getActiveCommunityAccentColor,
  getActiveCommunityId,
  getActiveGroupId,
} from '../../../../selectors/CommunitySelectors';
import FileInputPicker from '../../../../components/shared/FileInputPicker';
import NotificationService from '../../../../services/notificationService';
import { Mention, MentionsInput } from 'react-mentions';
import MentionsclassNames from '../../../../helpers/chatmodalmentions.module.css';
import { debounce } from 'lodash';
import { fetchGloabalSearchUsers } from '../../../../services/SearchService';
import { getFullName } from '../../../../utils/Utils';
import './mentionsinput.style.css';

let heic2any = null;
(async () => {
  heic2any = (await import('heic2any')).default;
})();

function SendImage({
  setImageLoading,
  isModal,
  handleImageMessage,
  placeholder,
  isDisbaled = false,
  inputText,
  enableTag = false,
}) {
  //  isModal = false -> not show modal
  const params = useParams();
  const { primaryFont } = useSelector(getActiveCommunityFontsClass);
  const activeCommunityAccentColor = useSelector(getActiveCommunityAccentColor);
  const [showImagePicker, setShowImagePicker] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileData, setFileData] = useState(null);
  const [open, setOpen] = useState(false);
  const [img, setImg] = useState(null);
  const [value, setValue] = useState(null);
  const community_id = useSelector(getActiveCommunityId);
  const activeGroupId = useSelector(getActiveGroupId);

  const cancelButtonRef = useRef(null);
  const handleResetPicker = () => {
    setOpen(false);
    setImg(null);
    setValue(null);
  };

  const onImageLoading = (isLoading) => {
    setLoading(isLoading);
  };
  useEffect(() => {
    const uploadToS3 = async () => {
      let imgSize = 0;
      let imgFileData = null;

      if (fileData) {
        setOpen(true);
        setLoading(true);
        setValue(inputText);
        if (fileData && (fileData.name.includes('.heic') || fileData.name.includes('.HEIC'))) {
          imgFileData = await heic2any({ blob: fileData, toType: 'image/jpg', quality: 1 });
        }
        imgSize = fileData.size;
        if (imgSize <= 10000000) {
          const url = 'onboarding/request_presigned_url';
          const fileObj = {
            uri: imgFileData || fileData,
            name: `IMG_${Date.now()}`,
            type: fileData.type,
          };
          const extraParams = params.channel_id
            ? {
                type: 'channel',
                channel_id: params.channel_id,
              }
            : { type: 'user' };
          const data = await getPresignedPostData(url, fileObj.name, extraParams);
          if (isModal === false) {
            setImageLoading(true);
          }
          uploadFileToS3(data, fileObj)
            .then(async (response) => {
              window.DOMParser = require('xmldom').DOMParser;
              const responseDoc = new DOMParser().parseFromString(response.data, 'application/xml');
              const imageSrc = responseDoc.getElementsByTagName('Key')[0].childNodes[0].nodeValue;
              setImg(imageSrc);
              setLoading(false);
              if (isModal === false) {
                setImageLoading(false);
                handleImageMessage(imageSrc, value);
              }
            })
            .catch((e) => {
              setLoading(false);
            });
        } else {
          handleResetPicker();
          setLoading(false);
          NotificationService.error('Image size should be less than 10 mb');
        }
      }
    };

    uploadToS3();
  }, [fileData]);

  useEffect(() => {
    if (isDisbaled) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [isDisbaled]);

  const onImageCropped = (imgURL) => {
    setOpen(true);
    setShowImagePicker(false);
    setImg(imgURL);
  };

  const fetchUsers = useCallback(
    debounce((query, callback, reset) => {
      if (!query) return;

      fetchGloabalSearchUsers(community_id, activeGroupId, query)
        .then((res) => {
          const currentUsers =
            res?.data?.entries?.included?.map((usr) => ({
              display: getFullName(usr?.attributes?.first_name, usr?.attributes?.last_name),
              id: usr?.attributes?.id,
              image: usr?.attributes?.image,
            })) || [];
          return currentUsers;
        })
        .then(callback)
        .catch((error) => {
          NotificationService.error(parseAxiosErrorMessage(error));
        });
    }, 500),
    [],
  );

  return (
    <div>
      <div className="flex items-center ltr:mr-2.5 rtl:ml-2.5">
        <FileInputPicker
          onChange={(e) => {
            setFileData(e.target.files[0]);
            e.target.value = '';
          }}
          id={useId()}
          disabled={loading}
          parentClassName={`cursor-pointer h-8 w-8 p-0 inline-flex text-gray-800 items-center justify-center border border-transparent text-sm leading-4 font-medium rounded-full focus:outline-none focus:ring-0 focus:ring-offset-0 chat__footer__btn ${
            loading ? 'opacity-50' : ''
          }`}
        >
          <PhotographIcon className="w-4 h-4" />
        </FileInputPicker>
      </div>
      {isModal === false ? null : (
        <Transition show={open} as={Fragment}>
          <Dialog
            as="div"
            className="fixed z-[1000] inset-0 overflow-y-auto"
            // initialFocus={cancelButtonRef}
            onClose={handleResetPicker}
          >
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={handleResetPicker} />
              </TransitionChild>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>
              <TransitionChild
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="relative inline-block align-middle main--background rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle">
                  <div className="px-4 pt-8 pb-4 sm:p-6 sm:pb-4">
                    <button
                      type="button"
                      className="absolute right-0 top-0 justify-center p-2 text-base font-medium sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm rounded-md"
                      onClick={handleResetPicker}
                      ref={cancelButtonRef}
                    >
                      <XIcon className="w-5 h-5 main__icon" />
                    </button>
                    <div className="sm:flex sm:items-start mb-6 h-64 min-w-[12rem] sm:min-w-[18rem] max-w-[24rem] relative">
                      {loading ? (
                        <div className="absolute inset-0 flex items-center justify-center">
                          <Oval
                            height={40}
                            width={40}
                            color="#10b981"
                            ariaLabel="oval-loading"
                            secondaryColor="#4fa94d"
                            strokeWidth={2}
                            strokeWidthSecondary={2}
                            visible
                          />
                        </div>
                      ) : (
                        <img
                          src={imageFetch(img)}
                          className="w-auto h-full ltr:ml-auto rtl:mr-auto mr-auto rounded-md"
                          alt="community icon"
                        />
                      )}
                    </div>
                    <div className="mt-1 flex items-center">
                      {enableTag ? (
                        <MentionsInput
                          allowSuggestionsAboveCursor={true}
                          allowSpaceInQuery
                          id="chat"
                          rows={3}
                          // onBlur={handleBlur}
                          value={value || ''}
                          onChange={(val) => {
                            setValue(val?.target?.value);
                          }}
                          a11ySuggestionsListLabel="Suggested members for mention"
                          classNames={MentionsclassNames}
                          suggestionsPortalHost={document.getElementById('suggetionPortalTwo')}
                          className="mentions chat__footer__input--container"
                          style={{ backgroundColor: '#f3f4f6', color: '#111827' }}
                          placeholder={placeholder}
                          maxlength={500}
                          multiline
                          onKeyPress={(e) => {
                            if (e.shiftKey && e.charCode === 13) {
                              return true;
                            }
                            if (e.charCode === 13) {
                              // onSendMessage();
                              // e.preventDefault();
                              e.target.style.height = '32px';
                              return false;
                            }
                          }}
                        >
                          <Mention
                            displayTransform={(id, username) => `@${username}`}
                            trigger="@"
                            data={fetchUsers || []}
                            singleline={true}
                            className={MentionsclassNames.mentions__mention}
                            style={{
                              color: 'black',
                              fontWeight: 'bold',
                              backgroundColor: 'white',
                            }}
                            renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
                              <div className="flex text-sm text-gray-600 justify-start items-center px-2 py-1">
                                <div className="inline-block relative flex-none cursor-pointer">
                                  <div className="relative ltr:mr-2 rtl:ml-2">
                                    <img
                                      className="h-10 w-10 rounded-full"
                                      src={imageFetch(suggestion?.image)}
                                      alt="profile"
                                    />
                                  </div>
                                </div>
                                <div className="">
                                  <p className="text-14 text-gray-600 text__title cursor-pointer text-left">
                                    {highlightedDisplay}
                                  </p>
                                </div>
                              </div>
                            )}
                            appendSpaceOnAdd
                          />
                        </MentionsInput>
                      ) : (
                        <input
                          type="text"
                          name="last-name"
                          autoComplete="family-name"
                          className="mr-3 border h-[42px] py-[5px] text-14 border-[rgba(209, 213, 219, 1)]  w-full rounded-md"
                          value={value}
                          placeholder={placeholder}
                          onChange={(e) => setValue(e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                              handleResetPicker();
                              handleImageMessage(img, value);
                            }
                          }}
                        />
                      )}

                      <button
                        type="button"
                        className={`${
                          loading ? 'disabled:opacity-25 pointer-events-none' : ''
                        } ltr:ml-2 rtl:mr-2 inline-flex items-center justify-center h-8 w-10 text-sm leading-4 rounded shadow-sm focus:outline-none focus:ring-0 focus:ring-offset-0 chat__footer__btn chat__footer__send__btn font-Geomanist font-semibold tracking-normal`}
                        onClick={() => {
                          handleResetPicker();
                          handleImageMessage(img, value || '');
                        }}
                        style={{
                          backgroundColor: activeCommunityAccentColor,
                        }}
                        disabled={loading ? 'disabled' : ''}
                      >
                        <PaperAirplaneIcon className="w-5 h-5 transform rotate-90 text-white" alt="" />
                      </button>
                    </div>
                  </div>
                </div>
              </TransitionChild>
            </div>
          </Dialog>
        </Transition>
      )}
    </div>
  );
}

export default SendImage;
