import { CHANNEL_TYPE, POST_TYPE, SEE_MORE_LINES } from '../constants';
import { getUtcTime } from '../helpers';
import NotificationService from '../services/notificationService';

export const getFullName = (fname, lname) => {
  let name = '';
  if (fname) {
    name = fname;
  }
  if (lname) {
    name = name + ' ' + lname;
  }
  return name;
};

export const getCommonAvtarName = (fname, lname) => {
  let name = '';
  if (fname) {
    name = fname?.charAt(0).toUpperCase();
  }
  if (lname) {
    name = name + lname?.charAt(0).toUpperCase();
  }
  return name;
};

export const cleanText = (text) => {
  // let text2 = '';
  // let newLineFlag = true;
  // let spaceFilterArr = ['<p>&nbsp;</p>', '<div>&nbsp;</div>'];
  // let collectSpace = '';

  // text.split('\n').map((item, index) => {
  //   if (index === 0 && !spaceFilterArr.includes(item)) {
  //     text2 = text2 + ' ' + item;
  //     newLineFlag = false;
  //     collectSpace = '';
  //   } else {
  //     if (spaceFilterArr.includes(item) && !newLineFlag) {
  //       // text2  = text2 + " " + item
  //       collectSpace += item;
  //     } else if (!spaceFilterArr.includes(item)) {
  //       text2 += collectSpace;
  //       text2 = text2 + ' ' + item;
  //       newLineFlag = false;
  //       collectSpace = '';
  //     }
  //   }
  // });

  // return text2;
  let cleanedText = trimedCkEditorTextAt(text, true);
  cleanedText = trimedCkEditorTextAt(cleanedText, false);
  if (!cleanedText) return text;
  else return cleanedText
};

export const highlightText = (searchValue, text) => {
  if (!searchValue) {
    return text;
  }
  const regex = new RegExp(`(${searchValue})`, 'gi');
  return text.split(regex).map((part, index) => {
    return regex.test(part) ? (
      <span key={index} className="font-bold">
        {part}
      </span>
    ) : (
      part
    );
  });
};

export const isClamped = (element) => {
  if (element?.current) {
    let el = element.current;
    if (el?.scrollHeight > el?.clientHeight) return true;
    else return false;
  } else return false;
};

export const getLines = (data) => {
  if (data?.channel_type === CHANNEL_TYPE.FORUM || data?.post_type) {
    if (data?.post_type === POST_TYPE.FORUM.POLL) {
      return SEE_MORE_LINES.FEED_POST_NUMBER_OF_LINES_POLL;
    } else if (data?.post_type) {
      if (data?.image_url) {
        return SEE_MORE_LINES.FEED_POST_NUMBER_OF_LINES_WITH_IMAGE;
      } else if (Object.keys(data?.media_types)?.length !== 0) {
        return SEE_MORE_LINES.FEED_POST_NUMBER_OF_LINES_WITH_VIDEO;
      } else {
        return SEE_MORE_LINES.FEED_POST_NUMBER_OF_LINES;
      }
    }
  } else if (data?.channel_type === CHANNEL_TYPE.RESOURCES || data?.type === POST_TYPE.RESOURCE_POST) {
    return SEE_MORE_LINES.RESOURCE_CHANNEL_LIST_LINES;
  } else return SEE_MORE_LINES.FEED_POST_NUMBER_OF_LINES;
};

export const trimText = (text, length) => {
  if (text.length > length) {
    return text.slice(0, length) + '...';
  } else {
    return text;
  }
};

export const handleInviteCopy = (url) => {
  navigator.clipboard.writeText(url);
  NotificationService.success('Invite link copied');
};

export const getBackgroundColorFromCustomCss = (cssString) => {
  const regexPattern = /\.btn__primary\s*{([^}]+)}/gi;
  const matches = cssString.match(regexPattern);

  const regexPatternForBG = /\.btn__primary\s*{([^}]*(background(-color)?)[^}]*)}/i;

  let result = null;

  if (matches) {
    for (let i = matches.length - 1; i >= 0; i--) {
      const match = matches[i].match(regexPatternForBG);

      if (match && match[1]) {
        const declarations = match[1].split(';');

        for (let j = declarations.length - 1; j >= 0; j--) {
          const declaration = declarations[j].trim();
          const [property, value] = declaration.split(':').map((part) => part.trim());

          if ((property === 'background' || property === 'background-color') && value.includes('!important')) {
            result = value.split('!important')[0].trim();
            break;
          }
        }

        if (result) {
          break;
        }
      }
    }
  }

  return result || '';
};

export const getIsAllowedPath = (filterPathList, pathname) => {
  return filterPathList.some((k) => pathname.includes(k));
};

export const getDateAndTime = (dateString, timeString) => {
  const parsedDate = new Date(dateString);
  const parsedTime = new Date(timeString);

  // Extract date components
  const year = parsedDate.getFullYear();
  const month = parsedDate.getMonth();
  const day = parsedDate.getDate();

  // Extract time components
  const hours = parsedTime.getHours();
  const minutes = parsedTime.getMinutes();
  const seconds = parsedTime.getSeconds();

  // Create a new Date object with combined date and time
  const combinedDateTime = new Date(year, month, day, hours, minutes, seconds);
  const combinedDateTimeInSeconds = combinedDateTime.getTime() / 1000;
  return combinedDateTimeInSeconds;
};

export const convertTZtoHMK = (date) => {
  if (!Boolean(date)) {
    return 'invalid date';
  }
  const options = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  const formattedDate = new Intl.DateTimeFormat('en-US', options).format(new Date(date));
  return formattedDate;
};

export const isWithinToday = (date) => {
  const currentDate = new Date();
  const inputDate = new Date(date);

  // Set hours, minutes, and seconds to 23:59:00 of the current day
  const endOfDay = new Date(currentDate);
  endOfDay.setHours(23, 59, 0, 0);

  return inputDate <= endOfDay;
};

export const convertTZtoHMwithDate = (date) => {
  if (!Boolean(date)) {
    return 'invalid date';
  }
  const options = {
    hour: '2-digit',
    minute: '2-digit',
    day: '2-digit',
    month: 'short',
  };
  var dateFormatter = new Intl.DateTimeFormat('en-US', options);
  var formattedDate = dateFormatter.formatToParts(new Date(date));

  return `${formattedDate[4].value}:${formattedDate[6].value} ${formattedDate[8].value}, ${getDayWithOrdinalSuffix(
    formattedDate[2].value,
  )} ${formattedDate[0].value}`;
};

const getDayWithOrdinalSuffix = (day) => {
  if (day >= 11 && day <= 13) {
    return day + 'th';
  } else {
    switch (day % 10) {
      case 1:
        return day + 'st';
      case 2:
        return day + 'nd';
      case 3:
        return day + 'rd';
      default:
        return day + 'th';
    }
  }
}

export const checkShowStatus = (startTimeStr, endTimeStr, stopTimeStr, isPast) => {
  const result = {
    eventEnded: false,
    happenSoon: false,
    happenLater: false,
    eventStarted: false,
    eventCancelled: false,
  };
  if (isPast) return result;
  const startTime = getUtcTime(startTimeStr);
  const endTime = getUtcTime(endTimeStr);
  const stopTime = getUtcTime(stopTimeStr);
  const now = new Date();

  if (now >= endTime) result.eventEnded = true;
  else if (now >= startTime && now < endTime) result.eventStarted = true;
  else if (Math.abs(startTime - now) <= 36e5) result.happenSoon = true;
  else result.happenLater = true;

  if (stopTime < endTime) {
    result.eventStarted = false;
    result.happenSoon = false;
    result.eventCancelled = true;
  }

  return result;
};

// Function to insert HTML content into CKEditor 5=
// export function insertHtmlContent(editor, htmlContent) {
//   editor.model.change((writer) => {
//     const viewFragment = editor.data.processor.toView(htmlContent);
//     const modelFragment = editor.data.toModel(viewFragment);
//     const insertPosition = editor.model.document.selection.getFirstPosition();
//     editor.model.insertContent(modelFragment, insertPosition);

//     // // Move cursor to a new line after inserting content
//     // const newLine = writer.createElement('paragraph');
//     // writer.append(newLine, editor.model.document.getRoot());
//     // writer.setSelection(newLine, 'end');
//   });
// }

export function insertHtmlContent(editor, htmlContent) {
  if(editor?.model) {
    editor.model.change(writer => {
      const viewFragment = editor.data.processor.toView(htmlContent);
      const modelFragment = editor.data.toModel(viewFragment);
      const insertPosition = editor.model.document.selection.getLastPosition(); // get the last position in the document selection

      editor.model.insertContent(modelFragment, insertPosition);

      // Find the new end position based on the last block inserted
      const endPosition = writer.createPositionAt(editor.model.document.getRoot(), 'end');
      writer.setSelection(endPosition); // Set the selection to the end

      editor.editing.view.focus(); // Focus the editing view
  });
  }
}

export const getTextFromHtml = (htmlData) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlData;
  return tempDiv.textContent || tempDiv.innerText || "";
}

export const trimedCkEditorTextAt = (contentStr, startOfText) => {
  if (!contentStr) return;
  const parser = new DOMParser();
  const doc = parser.parseFromString(contentStr, "text/html");

  // Function to remove leading/trailing spaces and &nbsp;
  const removeLeadingTrailingSpaces = (element, startOfText) => {
    let textContent = element.innerHTML;
    const leadingTrailingSpaces = /^\s+|\s+$/g;
    const leadingTrailingNonBreakingSpaces = /^(?:&nbsp;)+|(?:&nbsp;)+$/g;

    textContent = textContent.replace(leadingTrailingSpaces, "");
    textContent = textContent.replace(leadingTrailingNonBreakingSpaces, "");

    element.innerHTML = textContent;
  };

  // Check first or last child
  while (doc.body?.children.length) {
    const index = startOfText ? 0 : doc.body.children.length - 1;
    const child = doc.body.children[index];

    // Skip <figure class="media"></figure> elements
    if (child.tagName.toLowerCase() === 'figure' && child.classList.contains('media')) {
      if (startOfText) {
        // Move to the next child if at the start
        startOfText = !startOfText; // Toggle to move to the next child in the loop
        continue;
      } else {
        // Break if at the end, since we don't want to remove this element
        break;
      }
    }

    if (child.textContent.replace(/\s/g, "").length) {
      // Remove leading/trailing <br> tags
      while (child.children.length) {
        const innerIndex = startOfText ? 0 : child.children.length - 1;
        const grandchild = child.children[innerIndex];
        if (grandchild.tagName.toLowerCase() === 'br') {
          grandchild.remove();
        } else {
          break;
        }
      }

      // Remove leading/trailing spaces and &nbsp;
      removeLeadingTrailingSpaces(child, startOfText);
      break;
    } else {
      child.remove();
    }
  }

  return doc.body?.innerHTML;
};
