import server from '../api/server';

export const setMuxToken = (communityId, postValue) =>
  new Promise((resolve, reject) => {
    server
      .put(`/communities/${communityId}/update_mux_tokens`, {
        mux_token: postValue.mux_token,
        mux_secret: postValue.mux_secret,
      })
      .then((response) => {
        resolve(response);
      })
      .catch(reject);
  });

export const getStorageUrlMux = (communityId, postType) =>
  new Promise((resolve, reject) => {
    server
      .get(`/communities/${communityId}/get_storage_url?post_type=${postType}`, {})
      .then((response) => {
        resolve(response);
      })
      .catch(reject);
  });
