import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { getActiveCommunityAccentColor } from '../../../selectors/CommunitySelectors';

export const CONFIRMATION_TYPE = {
  DELETE: 'delete',
  LEAVE: 'leave',
  INVITE_TO_SPEAK: 'invite',
  CANCEL: 'cancel',
  SUBMIT: 'submit',
  SAVE_SETTINGS: 'Save settings',
  DISCONNECT_ACCOUNT: 'disconnect account',
  RAZORPAY_CONNECT: 'razorpay connect',
  UNBLOCK_AUDIO: 'unblock',
};

function ConfirmationButton({ confirmType, onConfirm, accentColor, refetchingRazopay, loading }) {
  switch (confirmType) {
    case CONFIRMATION_TYPE.DELETE:
      return (
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 btn__danger text-base font-medium focus:outline-none focus:ring-0 focus:ring-offset-0 md:ml-3 md:w-auto md:text-sm"
          onClick={(e) => {
            e.stopPropagation();
            onConfirm();
          }}
          disabled={Boolean(loading)}
        >
          Delete
        </button>
      );
    case CONFIRMATION_TYPE.LEAVE:
      return (
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 btn__primary text-base font-medium focus:outline-none focus:ring-0 focus:ring-offset-0 md:ml-3 md:w-auto md:text-sm"
          style={{ backgroundColor: accentColor }}
          onClick={(e) => {
            e.stopPropagation();
            onConfirm();
          }}
        >
          {$translatei18n('Leave')}
        </button>
      );
    case CONFIRMATION_TYPE.INVITE_TO_SPEAK:
      return (
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-green-600 text-base font-medium text-white hover:bg-green-700 focus:outline-none focus:ring-0 focus:ring-offset-0 md:ml-3 md:w-auto md:text-sm"
          onClick={(e) => {
            e.stopPropagation();
            onConfirm();
          }}
        >
          Hop on stage
        </button>
      );
    case CONFIRMATION_TYPE.CANCEL:
      return (
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 btn__danger text-base font-medium focus:outline-none focus:ring-0 focus:ring-offset-0 md:ml-3 md:w-auto md:text-sm"
          onClick={(e) => {
            e.stopPropagation();
            onConfirm();
          }}
        >
          {$translatei18n('CancelEvent')}
        </button>
      );
    case CONFIRMATION_TYPE.SUBMIT:
      return (
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 btn__primary text-base font-bold focus:outline-none focus:ring-0 focus:ring-offset-0 md:ml-3 md:w-auto md:text-sm"
          style={{ backgroundColor: accentColor }}
          onClick={onConfirm}
        >
          Submit
        </button>
      );
    case CONFIRMATION_TYPE.SAVE_SETTINGS:
      return (
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 btn__primary text-base font-bold focus:outline-none focus:ring-0 focus:ring-offset-0 md:ml-3 md:w-auto md:text-sm"
          style={{ backgroundColor: accentColor }}
          onClick={onConfirm}
        >
          Save settings
        </button>
      );
    case CONFIRMATION_TYPE.DISCONNECT_ACCOUNT:
      return (
        <button
          type="button"
          className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 btn__danger text-base font-medium focus:outline-none focus:ring-0 focus:ring-offset-0 md:ml-3 md:w-auto md:text-sm ${
            refetchingRazopay ? 'opacity-50' : ''
          }`}
          onClick={(e) => {
            e.stopPropagation();
            onConfirm();
          }}
          disabled={refetchingRazopay}
        >
          Yes, Disconnect Account
        </button>
      );
    case CONFIRMATION_TYPE.RAZORPAY_CONNECT:
      return (
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 btn__primary text-base font-medium focus:outline-none focus:ring-0 focus:ring-offset-0 md:ml-3 md:w-auto md:text-sm"
          style={{ backgroundColor: accentColor }}
          onClick={(e) => {
            e.stopPropagation();
            onConfirm();
          }}
          disabled={refetchingRazopay}
        >
          Connect
        </button>
      );

    case CONFIRMATION_TYPE.UNBLOCK_AUDIO:
      return (
        <button
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 btn__primary text-base font-medium focus:outline-none focus:ring-0 focus:ring-offset-0 md:ml-3 md:w-auto md:text-sm"
          style={{ backgroundColor: accentColor }}
          onClick={(e) => {
            e.stopPropagation();
            onConfirm();
          }}
        >
          Unblock
        </button>
      );

    default:
      break;
  }
}

export default function ConfirmationModal({
  cancelTitle = $translatei18n('Cancel'),
  open,
  infoText,
  infoHtml,
  onConfirm,
  onClose,
  confirmType = CONFIRMATION_TYPE.DELETE,
  refetchingRazopay,
}) {
  const accentColor = useSelector(getActiveCommunityAccentColor);

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={
          confirmType === CONFIRMATION_TYPE.LEAVE ||
          confirmType === CONFIRMATION_TYPE.INVITE_TO_SPEAK ||
          confirmType === CONFIRMATION_TYPE.UNBLOCK_AUDIO
            ? () => {}
            : onClose
        }
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center md:block md:p-0">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={
                confirmType === CONFIRMATION_TYPE.LEAVE ||
                confirmType === CONFIRMATION_TYPE.INVITE_TO_SPEAK ||
                confirmType === CONFIRMATION_TYPE.UNBLOCK_AUDIO
                  ? () => {}
                  : onClose
              }
            />
          </TransitionChild>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden md:inline-block md:align-middle md:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            enterTo="opacity-100 translate-y-0 md:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 md:scale-100"
            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
          >
            <div className="inline-block align-bottom card justify-center rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all md:my-8 md:align-middle md:max-w-lg md:w-full md:p-6">
              <div className="md:flex md:items-start">
                <div className="mt-3 text-center md:mt-0 md:ml-4 md:text-left">
                  <div className="mt-2">
                    {infoText && <p className="text-base text__title">{infoText}</p>}
                    {infoHtml || null}
                  </div>
                </div>
              </div>
              <div className="mt-5 md:mt-4 md:flex md:flex-row-reverse">
                <ConfirmationButton
                  onConfirm={onConfirm}
                  confirmType={confirmType}
                  accentColor={accentColor}
                  refetchingRazopay={refetchingRazopay}
                />
                {onClose ? (
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border btn__light shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-0 focus:ring-offset-0 md:mt-0 md:w-auto md:text-sm"
                    onClick={(e) => {
                      e.stopPropagation();
                      onClose();
                    }}
                    disabled={refetchingRazopay || false}
                  >
                    {cancelTitle}
                  </button>
                ) : null}
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
}
