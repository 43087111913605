import { ChevronLeftIcon, PencilAltIcon, PlusIcon, SearchIcon } from '@heroicons/react/outline';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, useHistory } from 'react-router-dom';
import { isMobile, isIOS, isChrome } from 'react-device-detect';
import { useMediaQuery } from 'react-responsive';
import { XIcon } from '@heroicons/react/solid';
import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import { classNames, prepareUserImageName } from '../../constants/utils';
import ProtectedRoute from '../../routes/ProtectedRoute';
import {
  getActiveCommunityAccentColor,
  getActiveCommunityFontsClass,
  getActiveCommunityViaPublicCommunity,
  getActiveGroupId,
  isCurrentActiveGroupIsMainGroup,
} from '../../selectors/CommunitySelectors';
import { isUserLoggedIn } from '../../selectors/ProfileSelectors';
import { deleteActiveChannel, fetchAllCommunityChannels } from '../../store/actions/channelActions';
import TopHeader, { TOP_HEADER_TYPE } from '../layout/Header';
import Sidebar from './sidebar';
import PrivateChatSection from './PrivateChatSection';
import chatBg from '../../assets/images/chatbgg.png';
import { getActivePrivateRoom } from '../../selectors/PrivateChatSelectors';
import { resetPrivateChatTextRoomState, setPrivateInboxType } from '../../store/actions/privateChatActions';
import CommonNameAvatar, {
  COMMON_NAME_AVATAR_SIZE,
  COMMON_NAME_AVATAR_TYPE,
} from '../../components/shared/tailwind/NameAvatar';
import { imageFetch } from '../../services/preSignedAws';
import { INBOX_TYPES } from '../../constants';
import InboxUserProfileSidebar from '../../components/inbox/InboxUserProfileSidebar';
import { setActiveGroup } from '../../store/actions/communityActions';
import UserProfileSidebar from '../../components/community/UserProfileSidebar';
import { fetchSearchResults } from '../../services/SearchService';
import { setNewChatRequest } from '../../store/actions/chatActions';
import { listRequests } from '../../services/privateChatService';
import { getFullName } from '../../utils/Utils';

export function CustomHeaderTitle() {
  const history = useHistory();
  const getActiveCommunityPublic = useSelector(getActiveCommunityViaPublicCommunity);
  const { primaryFont } = useSelector(getActiveCommunityFontsClass);
  const currentActiveGroupIsMainGroup = useSelector(isCurrentActiveGroupIsMainGroup);
  const paragraph = useRef(null);

  // dispatch(setActivePrivateRoom(data?.id));

  useEffect(() => {
    if (getActiveCommunityPublic == 3764) {
      paragraph.current.style.color = '#fff';
    }
  }, [getActiveCommunityPublic]);

  const handleBackNavigation = () => {
    history.push('/');
  };

  return (
    <div className="flex cursor-pointer text__header" onClick={handleBackNavigation}>
      <ChevronLeftIcon className="h-6 w-6 xs:h-5 xs:w-5" />
      <p className={classNames('ml-2 text-16 xs:text-14 xs:ml-1 truncate', primaryFont)} ref={paragraph}>
        {$translatei18n('BackTo')}{' '}
        {currentActiveGroupIsMainGroup ? $translatei18n('Community') : $translatei18n('Group')}
      </p>
    </div>
  );
}

function UserImage({ image, first_name, last_name, imgClasses, imgSize, onClick, online }) {
  const [isImageLoadingError, setIsImageLoadingError] = useState(false);
  return (
    <>
      {image && !isImageLoadingError ? (
        <div className="relative">
          <img
            className={imgClasses}
            src={imageFetch(image)}
            onError={() => setIsImageLoadingError(true)}
            alt="profile"
            onClick={onClick}
          />
          {online && <div className="dot h-2.5 w-2.5 bg-green-500 absolute rounded-full right-1 bottom-0" />}
        </div>
      ) : (
        <div className="relative">
          <CommonNameAvatar
            name={prepareUserImageName(first_name, last_name)}
            type={COMMON_NAME_AVATAR_TYPE.ROUND}
            size={imgSize}
          />
          {online && <div className="dot h-2.5 w-2.5 bg-green-500 absolute rounded-full right-1 bottom-0" />}
        </div>
      )}
    </>
  );
}

export default function Inbox() {
  const dispatch = useDispatch();
  const activeCommunity = useSelector((state) => state?.community?.activeCommunity);
  const isLoggedIn = useSelector(isUserLoggedIn);
  const { primaryFont, secondaryFont } = useSelector(getActiveCommunityFontsClass);
  const scrollFixFlag = isMobile && isIOS && isChrome;
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const activeCommunityAccentColor = useSelector(getActiveCommunityAccentColor);
  const activePrivateRoom = useSelector(getActivePrivateRoom);
  const [searchModal, setSearchModal] = useState(false);
  const privateChatReducer = useSelector((state) => state?.privateChat);
  const history = useHistory();
  const currentUser = useSelector((state) => state?.user);
  const newChatRequest = useSelector((state) => state?.chat?.newChatRequest);
  const searchParams = new URLSearchParams(history.location.search);
  const userId = searchParams.get('user_id');
  const type = searchParams.get('type');
  const isAutoAccept = searchParams.get('accept');

  const [users, setUsers] = useState([]);
  const searchTermRef = useRef();
  const [pageManager, setPageManager] = useState({});
  const [activeUserId, setActiveUserId] = useState(null);
  const [isOpenUserProfile, setIsOpenUserProfile] = useState(false);
  const activeGroupId = useSelector(getActiveGroupId);

  const xhrGetRequestUserData = () => {
    let requestUserData = listRequests();
    requestUserData.then((res) => onSuccess(res?.data)).catch((err) => console.log(err));
  };

  const onSuccess = (data) => {
    let dataNew = data?.recipients;
    let requestUserList = [];
    Object.keys(dataNew).forEach((key, index) => {
      requestUserList.push(String(dataNew[key]?.id));
    });

    closeCreateNewConversationModal();

    if (requestUserList.includes(activeUserId)) {
      dispatch(resetPrivateChatTextRoomState());
      dispatch(setNewChatRequest(false));
      dispatch(setPrivateInboxType(INBOX_TYPES.REQUESTS));
      history.replace({ pathname: '/inbox', search: `?user_id=${activeUserId}&type=request` });
    } else {
      history.push({ pathname: '/inbox', search: `?user_id=${activeUserId}` });
    }
  };

  const fetchLatestFollowUsers = useCallback((data, isReset = false) => {
    fetchSearchResults(data.name, 'user', data.page, activeCommunity, activeGroupId).then((response) => {
      setPageManager({
        page: response?.data?.links?.page || 1,
        count: response?.data?.links?.count,
      });

      const searchedAllUsers = isReset
        ? response?.data?.entries?.included
        : [...users, ...(response?.data?.entries?.included || [])];

      const removeCurrentUser = searchedAllUsers?.filter((e) => e.id !== currentUser?.id.toString());
      setUsers(removeCurrentUser);
    });
  });

  useEffect(() => {
    dispatch(setNewChatRequest(false));
    if (!isLoggedIn) {
      // Skip if user is not loggejd in
      return;
    }

    // Because we are displaying the current active channel in UI
    dispatch(deleteActiveChannel());
    // fetchLatestFollowUsers({});
    dispatch(setActiveGroup(activeCommunity, activeGroupId, false, true));
    // history.push(`/communities/${activeCommunity?.id}/group/${activeGroupId}/overview`);

    if (type === 'request' && privateChatReducer.inboxType !== INBOX_TYPES.REQUESTS) {
      dispatch(setPrivateInboxType(INBOX_TYPES.REQUESTS));
    }

    // eslint-disable-next-line consistent-return
    return () => {
      dispatch(resetPrivateChatTextRoomState());
    };
  }, []);

  useEffect(() => {
    if (activeGroupId) {
      dispatch(fetchAllCommunityChannels({ groupId: activeGroupId }));
    }
  }, [activeGroupId]);

  const closeCreateNewConversationModal = () => {
    setSearchModal(false);
    setActiveUserId(null);
    searchTermRef.current.value = '';
    setUsers([]);
    setPageManager({});
  };

  const handleCreateTextRoom = () => {
    xhrGetRequestUserData();
  };

  const privateChatSectionView = () => {
    if (activePrivateRoom?.id || newChatRequest) {
      return <PrivateChatSection onInfoClicked={() => setIsOpenUserProfile(true)} />;
    } else if (privateChatReducer.inboxType === INBOX_TYPES.INBOX) {
      return (
        <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <div className="text-center">
            <p className="font-bold text__title">{$translatei18n('NoConversationSelected')}</p>
            <p className="text__body">{$translatei18n('NoConversationSelectedDescription')}</p>
          </div>
          <div
            onKeyPress={() => {
              setSearchModal(true);
            }}
            className="flex justify-center mt-2 cursor-pointer"
            onClick={() => {
              setSearchModal(true);
            }}
            role="button"
            tabIndex="-1"
          >
            <span
              className={classNames(
                `group flex items-center px-5 py-2 text-sm rounded-md btn__primary ${primaryFont} text__body`,
              )}
              style={{
                backgroundColor: activeCommunityAccentColor,
              }}
            >
              <PlusIcon className="mr-1 flex-shrink-0 h-5" aria-hidden="true" />
              <span className="pt-1">{$translatei18n('StartANewConversation')}</span>
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 text__title">
          <div className="text-center">
            <p className="font-bold">{$translatei18n('MessageRequests')}</p>
            <p>{$translatei18n('MessageRequestsSearchPlaceholder')}</p>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="main--background pt-14">
      <Switch>
        <ProtectedRoute>
          <div className={classNames('main--background px-4 md:px-6', secondaryFont)}>
            <TopHeader
              headerType={TOP_HEADER_TYPE.INBOX}
              headerTitle={<CustomHeaderTitle communityId={activeCommunity} />}
            />

            <div className="mx-auto max-w-7xl my-1 flex h-screen card" style={{ maxHeight: 'calc(100vh - 60px)' }}>
              <div
                className={classNames(
                  'w-full md:w-inboxSidebar',
                  isTabletOrMobile && (activePrivateRoom?.id || newChatRequest) && 'hidden',
                )}
              >
                <div
                  className="fixed rounded-lg z-20 right-7 bottom-10 hidden xs:block sm:block"
                  onKeyPress={() => {
                    setSearchModal(true);
                  }}
                  onClick={() => {
                    setSearchModal(true);
                  }}
                  role="button"
                  tabIndex="-1"
                  style={{
                    display: `${searchModal || privateChatReducer.inboxType !== INBOX_TYPES.INBOX ? 'none' : ''}`,
                  }}
                >
                  <div className="main--background p-2 rounded-full">
                    <PencilAltIcon className="w-8 h-8 rounded-xl main__icon" />
                  </div>
                </div>
                <nav
                  aria-label="Sidebar"
                  id="inbox-sidebar"
                  className="layout-height sticky pb-6 shadow top-6 overflow-y-auto border-r h-full"
                >
                  <Sidebar
                    openCreateNewConversationModal={() => setSearchModal(true)}
                    userId={userId}
                    accept={isAutoAccept}
                  />
                </nav>
              </div>
              <main
                className={classNames(
                  `relative layout-height md:block h-full shadow overflow-y-auto w-full flex-1 px-4 md:px-6 md:pt-6 ${
                    scrollFixFlag ? 'pb-32' : 'pb-6'
                  }`,
                  isTabletOrMobile ? (activePrivateRoom?.id || newChatRequest ? 'block' : 'hidden') : null,
                )}
                id="main-content"
                style={{
                  backgroundImage: `url(${chatBg})`,
                  backgroundSize: 'cover',
                }}
              >
                {privateChatSectionView()}
              </main>
            </div>

            <InboxUserProfileSidebar
              open={isOpenUserProfile}
              onClose={() => setIsOpenUserProfile(false)}
              selectedUserId={activePrivateRoom?.user?.id}
            />
          </div>
          <Transition show={searchModal} as={Fragment}>
            <Dialog
              as="div"
              className="fixed z-40 inset-0 overflow-y-auto"
              onClose={closeCreateNewConversationModal}
              id="scrollableDiv"
            >
              <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center md:block md:p-0">
                <TransitionChild
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                    onClick={closeCreateNewConversationModal}
                  />
                </TransitionChild>

                {/* This element is to trick the browser into centering the modal contents. */}
                <span className="hidden md:inline-block md:align-middle md:h-screen" aria-hidden="true">
                  &#8203;
                </span>
                <TransitionChild
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                  enterTo="opacity-100 translate-y-0 md:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 md:scale-100"
                  leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
                >
                  <div className="inline-block card rounded-lg px-4 py-2 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all my-8 align-middle max-w-lg w-full p-6 h-100">
                    <div
                      className={classNames(
                        'flex justify-between items-center mb-3',
                        'text-16 text__title',
                        primaryFont,
                      )}
                    >
                      <div className="flex justify-center items-center">
                        <span
                          className="ltr:mr-2 rtl:ml-2 cursor-pointer"
                          onClick={closeCreateNewConversationModal}
                          role="button"
                          onKeyPress={closeCreateNewConversationModal}
                          tabIndex="-1"
                        >
                          <XIcon className="h-6 w-6 main__icon" aria-hidden="true" />
                        </span>
                        <span>{$translatei18n('StartANewConversation')}</span>
                      </div>
                      <button
                        className={classNames(
                          `xs:ml-4 sm:ml-4 pt-3 group flex items-center px-5 pb-2 text-sm rounded-md btn__primary ${primaryFont} cursor-pointer`,
                        )}
                        style={{
                          backgroundColor: activeCommunityAccentColor,
                        }}
                        onClick={handleCreateTextRoom}
                        type="button"
                        disabled={!activeUserId}
                      >
                        Message
                      </button>
                    </div>
                    <label className="relative block">
                      <span className="absolute inset-y-0 left-0 flex items-center ltr:pl-2 rtl:pr-2">
                        <SearchIcon className="w-5 h-5 text-gray-500 main__icon" />
                      </span>
                      <input
                        type="text"
                        name="searchUser"
                        id="searchUser"
                        ref={searchTermRef}
                        placeholder={$translatei18n('StartANewConversationSearchPlaceholder')}
                        className="block ltr:pl-9 rtl:pr-9 ltr:pr-4 rtl:pl-4  py-2 w-full sm:text-sm rounded-md card text__body input"
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' && searchTermRef.current.value.trim() !== '') {
                            fetchLatestFollowUsers(
                              {
                                page: 1,
                                per_page: 10,
                                name: searchTermRef.current.value.trim(),
                              },
                              true,
                            );
                          }
                        }}
                      />
                    </label>
                    <div className="text-12 text-gray-400 text__body pt-2">{$translatei18n('PressEnterToSearch')}</div>

                    <section className="mt-4 md:mt-6 overflow-y-scroll h-80 md:h-97">
                      {users.map((user) => (
                        <div
                          key={user.id}
                          className="flex items-center pt-4 justify-between"
                          role="button"
                          onClick={() => setActiveUserId(user.id)}
                          onKeyPress={() => setActiveUserId(user.id)}
                          tabIndex="-1"
                        >
                          <div className="flex items-center">
                            <UserImage
                              imgClasses="inline-block h-12 w-12 rounded-full"
                              image={user?.attributes?.image}
                              first_name={user?.attributes?.first_name}
                              last_name={user?.attributes?.last_name}
                              // online={user?.attributes?.online}
                              imgSize={COMMON_NAME_AVATAR_SIZE.MD}
                            />

                            <div>
                              <p className="ml-2 text__title text-14 font-bold text-gray-700 group-hover:text-gray-900">
                                {getFullName(user?.attributes?.first_name, user?.attributes?.last_name)}
                              </p>
                              {/* <p className="ml-2 text__title text-12 text-gray-500">
                                @{user?.username}
                              </p> */}
                            </div>
                          </div>
                          <div className="flex items-center h-5 ltr:pr-2 rtl:pl-2">
                            <input
                              id={user?.id}
                              type="radio"
                              checked={user.id === activeUserId}
                              className="h-4 w-4 border-gray-300 text__link"
                              onChange={(e) => {
                                setActiveUserId(user.id);
                              }}
                            />
                          </div>
                        </div>
                      ))}
                      {users.length < pageManager.count && (
                        <div className="flex justify-center">
                          <button
                            className="text__link cursor-pointer background-transparent font-bold px-3 text-sm outline-none focus:outline-none ease-linear transition-all duration-150"
                            type="button"
                            // style={{ color: activeCommunity?.accent_color }}
                            onClick={() =>
                              fetchLatestFollowUsers(
                                {
                                  page: pageManager.page + 1,
                                  per_page: 10,
                                  name: searchTermRef.current.value.trim(),
                                },
                                false,
                              )
                            }
                          >
                            {$translatei18n('SeeMore')}
                          </button>
                        </div>
                      )}
                    </section>
                  </div>
                </TransitionChild>
              </div>
            </Dialog>
          </Transition>
        </ProtectedRoute>
      </Switch>
      <UserProfileSidebar />
    </div>
  );
}
