/* eslint-disable no-unused-vars */
// /* eslint-disable import/no-cycle */
import { getAuthToken, getUserIdFromAuthCookie } from '../../constants/authUtils';
import _ from 'lodash';
// import { setSocket, setUserChannel, setVoiceRoomToken } from '.';
import { isGiphyUrl, arrayToBooleanObject } from '../../helpers';
import { setSocket, setUserChannel } from './socketAction';
import { setVoiceRoomToken } from './authActions';
import { Socket } from '../../sockets/phoenix';
import { ROOM_TYPES } from '../../constants';
import { imageFetch } from '../../services/preSignedAws';
import { store } from '../../store/configureStore';
// import navigationServices from '../../services/navigationServices';
import { captureException, LOGGER_JAVASCRIPT } from '../../services/SentryService';
// import { checkCameraAndAudioPermission, stopForegroundService } from '../../utils';
import { getChannelPermissionsConfig } from '../models/community';
import {
  ADD_BLOCKED_USER_IDS,
  REMOVE_BLOCKED_USER_IDS,
  RESET_TEXT_STATE,
  SET_EARLIER_MESSAGES,
  SET_LAST_MESSAGE,
  SET_LOAD_MORE,
  SET_TEXT_MESSAGES,
  TEST_REQUEST,
  TEXT_CHANNEL_CONNECTION_SUCCESS,
  UPDATE_CONNECTION_STATE,
  UPDATE_TEXT_STATE,
} from './actionTypes';
import { initialiseVoice, resetAudioState, updateAudioState } from './audioActions';
import { setActiveChannelId } from './socketAction';
import NotificationService from '../../services/notificationService';
import { internetState } from '../models/constants';
import { getFullName } from '../../utils/Utils';

let timeoutVar;
let count = 0;
const MESSAGE_LIMIT = 25;
const TIME_LIMIT = 10000;
const _newMessages = [];

export const setLoadMore = (value) => {
  return {
    type: SET_LOAD_MORE,
    payload: {
      value,
    },
  };
};

export const setEarlierMessages = (messages) => ({
  type: SET_EARLIER_MESSAGES,
  payload: {
    messages,
  },
});

export const textChannelConnectionSuccess = (textChannel, channelData, channelConfigs) => ({
  type: TEXT_CHANNEL_CONNECTION_SUCCESS,
  payload: { textChannel, channelData, channelConfigs },
});

export const addBlockedUserIds = (users) => ({
  type: ADD_BLOCKED_USER_IDS,
  payload: {
    users,
  },
});

export const removeBlockedUserIds = (value) => {
  return {
    type: REMOVE_BLOCKED_USER_IDS,
    payload: {
      value,
    },
  };
};

export const resetTextState = () => ({
  type: RESET_TEXT_STATE,
});

export const updateTextState = (data) => ({
  type: UPDATE_TEXT_STATE,
  payload: data,
});


export const generateMessageFromResponse = (response) => {
  if (!response) return null;
  let name = '';
  if (response.user.first_name !== undefined && response.user.first_name !== null) {
    name = getFullName(response.user.first_name, response.user.last_name)
  } else if (response.title !== undefined && response.title !== null) {
    name = response.title || '';
  }
  const currentUserID = getUserIdFromAuthCookie();

  return {
    _id: response.id,
    text: response.body,
    message_id: response.message_id,
    position: response.user.id === parseInt(currentUserID) ? 'right' : '',
    isSent: response.isSent !== undefined && response.isSent !== null ? response.isSent : true,
    avatar: response.user.image ? imageFetch(response.user.image) : undefined,
    'is_cool_human?': response.user['is_cool_human?'],
    cool_human_badge_url: response.user.cool_human_badge_url,
    type: isGiphyUrl(response.body || '') ? 'photo' : 'text',
    image: isGiphyUrl(response.body || '') ? response.body : '',
    text: !isGiphyUrl(response.body || '') ? response.body : '',
    image_url: response.image_url,
    createdAt: response.inserted_at,
    date: response.inserted_at,
    user: {
      _id: response.user.id,
      name,
      avatar: response.user.image ? imageFetch(response.user.image) : undefined,
      'is_cool_human?': response.user['is_cool_human?'],
      cool_human_badge_url: response.user.cool_human_badge_url,
    },
  };
  };


export const renderChatMessage = (messages, metadata) => (dispatch) => {
  let lastMessageId;
  let canLoadMore;

  if (metadata && metadata.after) {
    lastMessageId = metadata.after;
  }

  const newMessages = _.map(messages, (value) => {
    const name = getFullName(value.user.first_name, value.user.last_name);
    const userState = store.getState().user;
    // const currentUser = userState?.attributes;
    const currentUserID = getUserIdFromAuthCookie();
    return {
      _id: value.id,
      text: value.body,
      message_id: value.message_id,
      isSent: value.isSent !== undefined && value.isSent !== null ? value.isSent : true,
      // position: value.user.id == currentUser.id ? 'right' : '',
      position: value.user.id === parseInt(currentUserID) ? 'right' : '',
      avatar: value.user.image ? imageFetch(value.user.image) : undefined,
      'is_cool_human?': value.user['is_cool_human?'],
      cool_human_badge_url: value.user.cool_human_badge_url,
      type: isGiphyUrl(value.body || '') ? 'photo' : 'text',
      image: isGiphyUrl(value.body || '') ? value.body : '',
      text: !isGiphyUrl(value.body || '') ? value.body : '',
      image_url: value.image_url,
      createdAt: value.inserted_at,
      date: value.inserted_at,
      user: {
        _id: value.user.id,
        name,
        avatar: value.user.image ? imageFetch(value.user.image) : undefined,
        'is_cool_human?': value.user['is_cool_human?'],
        cool_human_badge_url: value.user.cool_human_badge_url,
      },
    };
  });

  if (newMessages.length > 0) {
    if (newMessages.length < 15) {
      canLoadMore = false;
    } else {
      canLoadMore = true;
    }

    dispatch(
      updateTextState({
        messages: newMessages,
        lastMessageId,
        canLoadMore,
      }),
    );
  }
};

export const appendMessage = (newMessages) => (dispatch, getState) => {
  const { messages, hasEarlierMessages, blockedUserIds } = getState().text;

  let updatedMessages;
  if (hasEarlierMessages) {
    updatedMessages = [...newMessages, ...messages];
    if (!timeoutVar) {
      timeoutVar = setTimeout(() => {
        dispatch(updateTextState({ hasEarlierMessages: false }));
        clearInterval(timeoutVar);
        timeoutVar = null;
      }, TIME_LIMIT);
    }
  } else {
    updatedMessages = [...newMessages, ...messages]
      .filter((item) => !blockedUserIds.includes(item.user._id))
      .slice(0, MESSAGE_LIMIT);
  }
  dispatch(updateTextState({ messages: updatedMessages }));
};

export const updateChatMessages =
  (modVar = 10, delay = 500) =>
  (dispatch) => {
    count += 1;
    if (_newMessages.length === 0) return;
    if (count === 1) {
      dispatch(appendMessage([..._newMessages]));
      _newMessages.length = 0;
      return;
    }
    if (timeoutVar && count % modVar !== 0) {
      clearTimeout(timeoutVar);
    }
    timeoutVar = setTimeout(() => {
      dispatch(appendMessage([..._newMessages]));
      _newMessages.length = 0;
    }, delay);
  };

export const setMessages = (messages) => ({
  type: SET_TEXT_MESSAGES,
  payload: { messages },
});

const receiveChatMessage = (messages) => (dispatch, getState) => {
  try {
    const {
      auth,
      text: { roomType },
      user,
    } = getState();

    // const currentUser = user?.attributes;
    const currentUserID = getUserIdFromAuthCookie();

    if (roomType === ROOM_TYPES.OVERFLOW) return;
    const userUUID = Number(currentUserID);
    const reciveObj = _.map(messages, (value) => {
      const name = getFullName(value.user.first_name, value.user.last_name);
      return {
        _id: value.id,
        text: value.body,
        message_id: value.message_id,
        isSent: value.isSent !== undefined && value.isSent !== null ? value.isSent : true,
        // position: value.user.id == currentUser.id ? 'right' : '',
        position: value.user.id == parseInt(currentUserID) ? 'right' : '',

        avatar: value.user.image ? imageFetch(value.user.image) : undefined,
        'is_cool_human?': value.user['is_cool_human?'],
        cool_human_badge_url: value.user.cool_human_badge_url,
        type: isGiphyUrl(value.body || '') ? 'photo' : 'text',
        image: isGiphyUrl(value.body || '') ? value.body : '',
        text: !isGiphyUrl(value.body || '') ? value.body : '',
        image_url: value.image_url,
        createdAt: value.inserted_at,
        date: value.inserted_at,
        user: {
          _id: value.user.id,
          name,
          avatar: value.user.image ? imageFetch(value.user.image) : undefined,
          'is_cool_human?': value.user['is_cool_human?'],
          cool_human_badge_url: value.user.cool_human_badge_url,
        },
      };
    });
    const message = reciveObj[0];

    if (message.user._id !== userUUID) {
      _newMessages.unshift(message);
    }
    dispatch(updateChatMessages(30, 1000));
  } catch (error) {
    captureException(`TextAction receiveChatMessage Error: ${error}`, LOGGER_JAVASCRIPT);

    console.error('Received error =>', error);
  }
};

export const setLastMessage = (id) => ({
  type: SET_LAST_MESSAGE,
  payload: {
    id,
  },
});

// export const closeChannelConnection = (callback, forceClose, preCallback) => async (dispatch, getState) => {
export const closeChannelConnection =
  (callback, forceClose, persistAudioState = false) =>
  async (dispatch, getState) => {
    try {
      const { text, audio, socket } = getState();
      const { connected, audioManager } = audio;
      if (connected && forceClose) {
        const { userChannel } = socket;
        const { textChannel } = text;
        const { channelData } = text;
        const { channel } = channelData;

        dispatch(
          updateAudioState({
            connected: false,
            leaveModal: false,
          }),
        );

        // if (preCallback && callback) {
        //   callback();
        // }

        if (userChannel) {
          userChannel.off('voice:requested');
          userChannel.off('voice:request:accepted');
          userChannel.off('voice:request:updated');
          userChannel.off('voice:demoted');
          userChannel.off('voice:shifted:listener');
          userChannel.off('voice:user:demoted');
          userChannel.off('user:kicked');
          userChannel.off('user:muted');
          userChannel.off('user:blocked');
          userChannel.off('user:unblocked');
        }

        if (audioManager) {
          await audioManager.leaveChannel();
          await audioManager.destroyChannel();
        }

        if (textChannel) {
          textChannel.off(`message:new`);
          textChannel.off(`message:pinned`);
          textChannel.leave();
        }

        //  if (preCallback) {
        //
        // refresh channel Data here
        // refreshLobbyData();
        //
        //  }

        console.error('Closing connectino');
        dispatch(setVoiceRoomToken(null));
        dispatch(setVoiceRoomToken(null));
        dispatch(resetAudioState(persistAudioState));
        dispatch(resetTextState());

        // if (callback && !preCallback) callback();

        if (callback) callback();
      } else if (callback) callback();
    } catch (error) {
      captureException(`TextAction closeChannelConnection Error: ${error}`, LOGGER_JAVASCRIPT);
      console.error({ error });
    }
  };

export const onAudioPermissionCancel = () => (dispatch) => {
  dispatch(
    closeChannelConnection(() => {
      // navigationServices.navigate('ChannelScreen');
    }),
  );
};

export const pinMessage = (data) => async () => {
  // console.log({ data });
};

export const initialiseSocket = ({ auth, dispatch, channelId, getState }) =>
  new Promise((resolve, reject) => {
    try {
      let authToken = getAuthToken();
      let unreadCount = 0;
      const PHOENIX_HOST = process.env.REACT_APP_PHOENIX_HOST;
      // console.log('PHOENIX_HOST =>', PHOENIX_HOST);
      //  https://scenes-channels-staging.avalonmeta.com
      // const URL = `wss://${PHOENIX_HOST}/socket`;
      const URL = `wss://${PHOENIX_HOST}/socket`;
      // const userId = auth.id;
      const userId = getUserIdFromAuthCookie();

      // const socket = new Socket(URL, {
      //   params: { token: auth.token },
      // });

      const socket = new Socket(URL, {
        params: { token: authToken },
      });

      socket.connect();

      socket.onOpen(() => {
        dispatch(setSocket(socket));
        dispatch({ type: UPDATE_CONNECTION_STATE, payload: internetState.CONNECTED });
        resolve(socket);
      });

      const userChannel = socket.channel(`user:${userId}`, {});
      dispatch(setUserChannel(userChannel));
      userChannel
        .join()
        .receive('ok', (data) => {
          //  data.blocked_user_ids && this.props.addBlockedUserIds(data.blocked_user_ids);
          unreadCount = data?.unread_notifications_count;
        })
        // .receive('error', ({ reason }) => {})
        .receive('error', (error) => {
          console.error('error', { error });
        })
        .receive('timeout', (res) => {
          console.error('timeout', res);
        });

      socket.onError((error) => {
        // socket.params = () => ({ token: auth.token });
        socket.params = () => ({ token: authToken });
        console.error('onError =>', error);
        dispatch({ type: UPDATE_CONNECTION_STATE, payload: internetState.FAILED });
      });
      socket.onClose((err) => {
        console.error('onClose here', err);
        const { audio } = getState();

        if (channelId) {
          dispatch(
            closeChannelConnection(
              () => {
                if (audio.connected === true) {
                  dispatch(initialiseTextChannel(channelId, { type: 'stage', shouldInitialiseVoice: true }));
                }
              },
              true,
              audio.connected,
            ),
          );
        }
      });
    } catch (error) {
      console.error('catch error', error);
      captureException(`TextAction initialiseSocket Error: ${error}`, LOGGER_JAVASCRIPT);
      reject(error);
    }
  });

export const testFunction = () => ({
  type: TEST_REQUEST,
  payload: {},
});
export const initialiseTextChannel =
  (channelId, { type = 'stage', shouldInitialiseVoice = false }) =>
  async (dispatch, getState) => {
    try {
      const {
        auth,
        text: { isConnected, channelData },
      } = getState();

      if (channelId === channelData?.channel?.id) {
        return;
      }

      dispatch(updateTextState({ channelLoading: true }));

      if (isConnected) {
        dispatch(
          closeChannelConnection(() => {
            dispatch(initialiseTextChannel(channelId, { type, shouldInitialiseVoice }));
          }, true),
        );
        return;
      }

      if (!channelId) {
        throw new Error('ActiveChannelID not present');
      }

      dispatch(setActiveChannelId(channelId));

      let socketConn = getState().socket.socket;

      if (!socketConn) {
        await initialiseSocket({ auth, dispatch, channelId, getState });
        socketConn = getState().socket.socket;
      }

      const textChannel = socketConn.channel(`${type}:${channelId}`, {});
      textChannel
        .join()
        .receive('ok', async (data) => {
          dispatch(updateTextState({ roomType: data.room_type }));
          dispatch(renderChatMessage(data.messages.data, data.messages.metadata));
          dispatch(
            textChannelConnectionSuccess(textChannel, data, getChannelPermissionsConfig(data.channel_permissions)),
          );
          if (shouldInitialiseVoice) {
            dispatch(initialiseVoice(textChannel, { channelId, channelData: data }));
            if (data.host || data.speaker) {
              // TODO AUDIO PERMISSION CHECK
              // await checkCameraAndAudioPermission({
              //   onCancel: () => dispatch(onAudioPermissionCancel()),
              // });
            }
          }

          if (auth.voiceRoomToken) {
            textChannel.push('presence:update', {
              token: auth.voiceRoomToken,
            });
          }
        })
        .receive('error', ({ code, reason }) => {
          if (code === 401 || code === 404) {
            // NotificationService.error("You're not permitted to enter this room. Please try again later!");
            // dispatch(closeChannelConnection(null, true));
            textChannel.leave();
            // dispatch(updateTextState({ redirect: true, textChannel: null }));
            dispatch(
              closeChannelConnection(() => {
                dispatch(updateTextState({ redirect: true }));
              }, true),
            );
          }
          // dispatch(updateTextState({ redirect: true }));
          console.error(code);
          console.error({ reason });
        })
        .receive('timeout', () => {
          console.error('timeout =>');
          // dispatch(updateTextState({ redirect: true }));
        });

      // textChannel.on(`message:new`, (msg) => {
      //   console.error('New Chat');
      //   dispatch(receiveChatMessage(msg));
      // });
      textChannel.on(`message:new`, (msg) => dispatch(receiveChatMessage(msg)));
      textChannel.on(`message:pinned`, (data) => dispatch(pinMessage(data)));

      // TODO Event Actions handle
      // textChannel.on(`event:reminder`, (event) => {
      //   dispatch(setHostEvent(event));
      // });

      // textChannel.on(`event:live`, (event) => {
      //   dispatch(setHostEvent(event));
      // });

      // textChannel.on(`event:stop`, (event) => {
      //   dispatch(setHostEvent(event));
      //   dispatch(removeChannelEvent(channelId));
      // });
    } catch (error) {
      // console.log({ error });
      captureException(`TextAction initialiseTextChannel Error: ${error}`, LOGGER_JAVASCRIPT);
    }
  };
