/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogTitle, Menu, MenuButton, MenuItem, MenuItems, Transition, TransitionChild } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import _ from 'lodash';
import usePrevious from '../../hooks/usePrevious';
import {
  getActiveCommunity,
  getActiveCommunityAccentColor,
  getActiveCommunityFontsClass,
} from '../../selectors/CommunitySelectors';
import serverLess from '../../api/serverVersionless';
import { capitalizeFirstLetter } from '../../helpers';
import { imageFetch } from '../../services/preSignedAws';
import { addBlockedUserIds, removeBlockedUserIds } from '../../store/actions/textActions';
import CommonNameAvatar, {
  COMMON_NAME_AVATAR_SIZE,
  COMMON_NAME_AVATAR_TYPE,
} from '../shared/tailwind/NameAvatar';
import { classNames, prepareUserImageName, history } from '../../constants/utils';
import { followUser, unfollowUser } from '../../services/UserService';
import NotificationService from '../../services/notificationService';
import { ERROR_MESSAGES } from '../../constants/notificationMessages';
import ReportModal from '../moderation/ReportModal';
import { REPORT_TYPE } from '../../constants';
import { setUserProfileData } from '../../store/actions/userProfileActions';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import { getFullName } from '../../utils/Utils';

function UserImage({
  image, first_name, last_name, imgClasses, imgSize, onClick,
}) {
  const [isImageLoadingError, setIsImageLoadingError] = useState(false);
  return (
    <>
      {image && !isImageLoadingError ? (
        <img
          className={imgClasses}
          src={imageFetch(image)}
          onError={() => setIsImageLoadingError(true)}
          alt="profile"
          onClick={onClick}
        />
      ) : (
        <CommonNameAvatar
          className={imgClasses}
          onClick={onClick}
          name={prepareUserImageName(first_name, last_name)}
          type={COMMON_NAME_AVATAR_TYPE.CIRCULAR}
          size={imgSize}
        />
      )}
    </>
  );
}

function InboxUserProfile({
  open,
  onClose,
  activeUserId,
  channelId,
}) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const activeCommunity = useSelector(getActiveCommunity);
  const { primaryFont } = useSelector(getActiveCommunityFontsClass);
  const accentColor = useSelector(getActiveCommunityAccentColor);

  const [userData, setUserData] = useState({});
  const [disableFollow, setDisableFollow] = useState(false);
  const [reportOpen, setReportOpen] = useState(false)
  const prev = usePrevious({ userData, open });

  useEffect(() => {
    if (activeUserId) {
      fetchUserDetails();
    }
  }, [activeUserId]);

  const fetchUserDetails = () => {
    serverLess
      .get(`/profile/${activeUserId}?community_id=${activeCommunity.id}`, {
        params: {
          channel_id: channelId || null,
        },
      })
      .then((res) => {
        const { community_roles, channel_lists } = res.data.data;
        const arrCommunities = [];
        const arrChannels = [];
        if (community_roles) {
          community_roles.forEach((item) => {
            const obj = { id: 1, label: capitalizeFirstLetter(item.role), color: item.color };
            arrCommunities.push(obj);
          });
        }
        if (channel_lists) {
          channel_lists.forEach((item) => {
            const obj = {
              access: capitalizeFirstLetter(item.base_permission),
              type: item.channel_type,
              name: item.channel_name,
            };
            arrChannels.push(obj);
          });
        }
        setUserData({
          isBlocked: res.data?.data?.blocked,
          data: res.data.data,
          followed: res.data.data.followed,
          loader: false,
          arrCommunity: arrCommunities,
          arrChannels,
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleBlockUser = () => {
    if (userData.isBlocked) unblockUser();
    else blockUser();
  };

  const blockUser = () => {
    serverLess
      .post('/blocked_users', {
        blocked_user: { blocked_user_id: activeUserId },
      })
      .then(() => {
        setUserData({ ...userData, isBlocked: true, loader: false });
        dispatch(addBlockedUserIds([activeUserId]));
      })
      .catch(() => {
        setUserData({ ...userData, loader: false });
      });
  };

  const unblockUser = () => {
    serverLess
      .delete(`/blocked_users/${activeUserId}`)
      .then(() => {
        setUserData({ ...userData, isBlocked: false, loader: false });
        dispatch(removeBlockedUserIds(activeUserId));
      })
      .catch(() => {
        setUserData({ ...userData, loader: false });
      });
  };

  const handleFollow = async () => {
    const requestData = {
      followee_id: userData?.data?.id,
    };
    const newUserData = _.cloneDeep(userData);
    setDisableFollow(true);
    if (userData?.followed) {
      try {
        await unfollowUser(requestData, activeUserId);
        if (newUserData?.data) {
          newUserData.data.followers_count = parseInt(newUserData.data.followers_count, 10) - 1;
        }
        newUserData.followed = false;
        setUserData(newUserData);
      } catch (error) {
        NotificationService.error(ERROR_MESSAGES.SOMETHING_WENT_WRONG);
      }
    } else {
      try {
        await followUser(requestData);
        if (newUserData?.data) {
          newUserData.data.followers_count = parseInt(newUserData.data.followers_count, 10) + 1;
        }
        newUserData.followed = true;
        setUserData(newUserData);
      } catch (error) {
        NotificationService.error(ERROR_MESSAGES.SOMETHING_WENT_WRONG);
      }
    }
    setDisableFollow(false);
  };

  const renderUserProfileView = () => {
    const { data } = userData;
    if (!open) return null;

    const name = getFullName(data.user.first_name, data.user.last_name);
    const username = `${data.user.username}`;

    return (
      <div className="mt-6 relative flex-1">
        <div className="px-4 py-4 border-t-2 border-gray-200">
          <div className="flex px-3">
            <UserImage
              imgClasses="h-10 w-10 rounded-full cursor-pointer"
              image={data?.user?.image}
              first_name={data?.user?.first_name}
              last_name={data?.user?.last_name}
              imgSize={COMMON_NAME_AVATAR_SIZE.MD}
              onClick={(e) => {
                e.stopPropagation();
                history.push(`/profile?user_id=${data?.user?.id}`, { isFromInbox: true })
              }}
            />

            <div className="flex justify-center flex-col ml-3">
              <p
                className={classNames('text-14 text-gray-900 text__title', primaryFont)}>
                {name}
              </p>
              <p
                className={classNames('text-12 text-gray-800 text__body', primaryFont)}>
                {username}
              </p>
            </div>
            <div className="relative ltr:ml-auto rtl:mr-auto">
              <button
                type="button"
                className={classNames(
                  'mr-3 inline-flex items-center justify-center px-3 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm focus:outline-none focus:ring-0 focus:ring-offset-0',
                  primaryFont,
                  userData?.followed ? 'btn__light' : 'btn__primary',
                )}
                style={{ backgroundColor: userData?.followed ? '' : accentColor }}
                onClick={handleFollow}
                disabled={disableFollow}
              >
                {userData?.followed ? $translatei18n('Unfollow') : $translatei18n('Follow')}
              </button>
              <div className="absolute -right-4 top-0 ltr:ml-auto rtl:mr-auto pt-1.5">
                <Menu as="div" className="relative inline-block text-left">
                  <div>
                    <MenuButton className="rounded flex items-center text-gray-800 hover:text-gray-500 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-offset-gray-100 focus:ring-indigo-500">
                      <span className="sr-only">Open options</span>
                      <DotsVerticalIcon className='main__icon w-6 h-6' />
                    </MenuButton>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <MenuItems className="ltr:origin-top-right rtl:origin-top-left z-10 absolute ltr:right-0 rtl:left-0 mt-2 w-56 rounded-md shadow-lg menu__options__container border focus:outline-none">
                      <div className="py-1">
                        <MenuItem>
                          {({ active }) => (
                            <a
                              onClick={() => setReportOpen(true)}
                              className={classNames(
                                active && 'menu__option--active',
                                'block px-4 py-2 text-sm menu__option text__danger hover:cursor-pointer',
                              )}
                            >
                              {$translatei18n("ReportUser")}
                            </a>
                          )}
                        </MenuItem>
                      </div>
                    </MenuItems>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>
        </div>
        <div className="px-4">
          <div>
            {/* <button
              type="button"
              className="inline-flex items-center px-3 py-2 rounded-md btn__link w-full mt-2"
              >
              <p className="text__danger">Delete chat</p>
            </button> */}
            {/* <button
              type="button"
              className="inline-flex items-center px-3 py-2 rounded-md btn__link w-full mt-2"
              onClick={openReportModal}
              >
              <p className="text__danger">Report</p>
            </button> */}
            {/* <button
              type="button"
              className="inline-flex items-center px-3 py-2 rounded-md btn__link w-full mt-2"
              onClick={handleBlockUser}
              >
              <p className="text__danger">{userData?.isBlocked ? 'Unblock' : 'Block'}</p>
            </button> */}
          </div>
        </div>
        {/* /End replace */}
        <ReportModal
          heading="Report User"
          subheading="Are you sure you want to report this User?"
          onClose={() => setReportOpen(false)}
          open={reportOpen}
          userName={getFullName(data?.first_name, data?.last_name)}
          reportType={REPORT_TYPE.USER_REPORT}
          userId={data?.user?.id}
        />
      </div>
    );
  };

  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-50 overflow-hidden" onClose={() => { onClose() }}>
        <div className="absolute inset-0  overflow-hidden">
          <TransitionChild
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={onClose} />
          </TransitionChild>

          <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md xs:max-w-xs">
                <div className="h-full flex flex-col py-6 sidepanel__container shadow-xl overflow-y-scroll">
                  <div className="px-4 sm:px-6">
                    <div className="flex items-start justify-between">
                      <DialogTitle className={classNames('text-lg font-medium text__title', primaryFont)}>
                        Details
                      </DialogTitle>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          className="rounded-md focus:outline-none focus:ring-0 focus:ring-offset-0"
                          onClick={() => onClose()}
                        >
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-6 w-6 main__icon" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>

                  {isLoading === false && !_.isEmpty(userData) && renderUserProfileView()}
                </div>
              </div>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

export default function InboxUserProfileSidebar({ open, onClose, selectedUserId }) {
  const [openReportModal, setOpenReportModal] = useState(false);

  return (
    <div>
      <InboxUserProfile
        open={open}
        onClose={onClose}
        activeUserId={selectedUserId}
        openReportModal={() => {
          setOpenReportModal(true);
          onClose();
        }}
      />

      {openReportModal && (
        <ReportModal
          heading="Report User"
          subheading=""
          onClose={() => setOpenReportModal(false)}
          open={openReportModal}
          reportType={REPORT_TYPE.USER_REPORT}
          userId={selectedUserId}
        />
      )}
    </div>
  );
}
