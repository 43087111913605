import server from '../api/server';

export const fetchCustomSsoList = async () => {
  return await new Promise((resolve, reject) => {
    server
      .get(`/custom_sso_providers`)
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch(reject);
  });
};

export const createCustomSso = async (sso_param) => {
  return await new Promise((resolve, reject) => {
    server
      .post(`/custom_sso_providers`, { sso_param })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch(reject);
  });
};

export const updateCustomSso = async (sso_param, id) => {
  return await new Promise((resolve, reject) => {
    server
      .put(`/custom_sso_providers/${id}`, { sso_param })
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch(reject);
  });
};

export const deleteCustomSso = async (id) => {
  return await new Promise((resolve, reject) => {
    server
      .delete(`/custom_sso_providers/${id}`)
      .then((response) => {
        const { data } = response;
        resolve(data);
      })
      .catch(reject);
  });
};

export const updateScenesLogin = async (state, community_id) => {
  return await new Promise((res, rej) => {
    server
      .patch(`/communities/${community_id}/toggle_scenes_login`, { enable: state })
      .then((response) => {
        res(response);
      })
      .catch(rej);
  });
};
