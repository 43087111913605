import { all, fork, put, takeLatest } from 'redux-saga/effects';
import { parseAxiosErrorMessage } from '../constants/utils';
import NotificationService from '../services/notificationService';
import {
  GET_CUSTOM_SSO_LIST,
  CREATE_CUSTOM_SSO_REQUEST,
  UPDATE_CUSTOM_SSO_REQUEST,
  DELETE_CUSTOM_SSO_REQUEST,
  SET_CUSTOM_SSO_LIST,
  CREATE_CUSTOM_SSO_SUCCESS,
  CREATE_CUSTOM_SSO_FAILED,
  UPDATE_CUSTOM_SSO_SUCCESS,
  DELETE_CUSTOM_SSO_SUCCESS,
  UPDATE_CUSTOM_SSO_FAILED,
  DELETE_CUSTOM_SSO_FAILED,
  UPDATE_CUSTOM_SSO_PUBLIC_COMMUNITY_DETAILS,
  REMOVE_CUSTOM_SSO_PUBLIC_COMMUNITY_DETAILS,
  SET_SCENES_LOGIN_STATE,
  UPDATE_SCENES_LOGIN_PUBLIC,
} from '../store/actions/actionTypes';
import { SUCCESS_MESSAGES } from '../constants/notificationMessages';
import {
  createCustomSso,
  deleteCustomSso,
  fetchCustomSsoList,
  updateCustomSso,
  updateScenesLogin,
} from '../services/ssoService';

function* getCustomSsoListSaga() {
  yield takeLatest(GET_CUSTOM_SSO_LIST, function* () {
    try {
      const ssoList = yield fetchCustomSsoList();
      yield put({
        type: SET_CUSTOM_SSO_LIST,
        payload: ssoList?.data,
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* createCustomSsoSaga() {
  yield takeLatest(CREATE_CUSTOM_SSO_REQUEST, function* ({ payload }) {
    const { requestBody, setIsOpen, handlScrollIntoView } = payload;
    try {
      const newSsoData = yield createCustomSso(requestBody);
      yield put({
        type: CREATE_CUSTOM_SSO_SUCCESS,
        payload: newSsoData?.data,
      });
      if (newSsoData?.data?.enable) {
        yield put({
          type: UPDATE_CUSTOM_SSO_PUBLIC_COMMUNITY_DETAILS,
          payload: newSsoData?.data,
        });
      }
      if (newSsoData?.data?.enable) {
        NotificationService.success(SUCCESS_MESSAGES.UPDATE_CUSTOM_SSO_SUCCESS_WITH_DISABLE_LOGIN);
      } else {
        NotificationService.success(SUCCESS_MESSAGES.UPDATE_CUSTOM_SSO_SUCCESS);
      }
      if (requestBody?.provider === 'jwt')
        setTimeout(() => {
          setIsOpen(true);
        }, 1000);
      if (requestBody?.enable) {
        try {
          const response = yield updateScenesLogin(false, requestBody.activeCommunityId);
          yield put({ type: SET_SCENES_LOGIN_STATE, payload: response?.data?.scenes_login_enable });
          yield put({ type: UPDATE_SCENES_LOGIN_PUBLIC, payload: response?.data?.scenes_login_enable });
        } catch (error) {
          console.error(error);
        }
      }
      if (typeof handlScrollIntoView === 'function' && !requestBody?.enable) handlScrollIntoView();
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      yield put({
        type: CREATE_CUSTOM_SSO_FAILED,
      });
    }
  });
}

function* updateCustomSsoSaga() {
  yield takeLatest(UPDATE_CUSTOM_SSO_REQUEST, function* ({ payload }) {
    const { handlScrollIntoView, requestBody, id } = payload;
    try {
      const updatedSsoData = yield updateCustomSso(requestBody, id);
      yield put({
        type: UPDATE_CUSTOM_SSO_SUCCESS,
        payload: updatedSsoData?.data,
      });
      if (updatedSsoData?.data?.enable) {
        yield put({
          type: UPDATE_CUSTOM_SSO_PUBLIC_COMMUNITY_DETAILS,
          payload: updatedSsoData?.data,
        });
      } else {
        yield put({
          type: REMOVE_CUSTOM_SSO_PUBLIC_COMMUNITY_DETAILS,
        });
        if (typeof handlScrollIntoView === 'function') handlScrollIntoView();
      }
      NotificationService.success(SUCCESS_MESSAGES.UPDATE_CUSTOM_SSO_SUCCESS);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      yield put({
        type: UPDATE_CUSTOM_SSO_FAILED,
      });
    }
  });
}

function* deleteCustomSsoSaga() {
  yield takeLatest(DELETE_CUSTOM_SSO_REQUEST, function* ({ payload }) {
    try {
      const { id, setProvider, setResponseType } = payload;
      const response = yield deleteCustomSso(id);
      yield put({
        type: DELETE_CUSTOM_SSO_SUCCESS,
        id,
      });
      yield put({
        type: REMOVE_CUSTOM_SSO_PUBLIC_COMMUNITY_DETAILS,
      });
      setProvider(null);
      setResponseType(null);
      NotificationService.success(SUCCESS_MESSAGES.DELETE_CUSTOM_SSO_SUCCESS);
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      yield put({
        type: DELETE_CUSTOM_SSO_FAILED,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(getCustomSsoListSaga),
    fork(createCustomSsoSaga),
    fork(updateCustomSsoSaga),
    fork(deleteCustomSsoSaga),
  ]);
}
