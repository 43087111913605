import React, { Fragment, useEffect, useState } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { useDispatch, useSelector } from 'react-redux';
import { BellIcon } from '@heroicons/react/outline';
import { Initial } from 'react-initial';
import DOMPurify from 'dompurify';
import moment from 'moment';
import server from '../../api/server';
import { imageFetch } from '../../services/preSignedAws';
import { getActiveCommunity, getActiveCommunityAccentColor } from '../../selectors/CommunitySelectors';
import { history } from '../../constants/utils';
import { isMobile } from 'react-device-detect';

// format time
moment.updateLocale(localStorage.getItem('currentLocal') || 'en', {
  relativeTime: {
    future: 'in %s',
    past: '%sec',
    s: 'Just now',
    ss: 'Just now',
    m: '1min',
    mm: '%dmin',
    h: '1hr',
    hh: '%dhr',
    d: '1d',
    dd: '%dd',
    M: '1m',
    MM: '%dm',
    y: '1y',
    yy: '%dy',
  },
});

function RenderNotification({ notif, onPhotoClick, onClick }) {
  const sanitizedMessage = DOMPurify.sanitize(notif.message, { USE_PROFILES: { html: true } });
  const [isImageLoadingError, setIsImageLoadingError] = useState(false);
  switch (notif.type) {
    default:
      return (
        <>
          {notif.image && !isImageLoadingError ? (
            <img
              onClick={onPhotoClick}
              className="rounded-lg notification__image"
              width="40"
              height="40"
              src={imageFetch(notif.image)}
              onError={() => setIsImageLoadingError(true)}
              alt="profile"
            />
          ) : (
            <Initial
              onClick={onPhotoClick}
              name={`${sanitizedMessage}`
                .replace(/<\/?b>/g, '')
                .substring(0, 2)
                .toUpperCase()}
              charCount="2"
              height={40}
              width={40}
              fontSize={20}
              className="rounded-lg"
              color="rgb(107 114 128)"
            />
          )}
          <div className="ml-3 notification__message-container">
            <p onClick={onClick} dangerouslySetInnerHTML={{ __html: sanitizedMessage }} />
            {notif?.created_at ? (
              <p className="text-gray-400 text-xs text__info">{moment(notif.created_at).fromNow(true)}</p>
            ) : null}
          </div>
        </>
      );
  }
}

function NotificationList({ communityId, page }) {
  const [notifications, setNotifications] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isUnread, setIsUnread] = useState(false);
  const [currPage, setCurrPage] = useState(0);
  const [nextPage, setNextPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const activeCommunityAccentColor = useSelector(getActiveCommunityAccentColor);
  const publicCommunityDetails = useSelector((state) => state?.community?.publicCommunityDetails);
  const currentActiveCommunity = useSelector(getActiveCommunity);
  const communityConfig = useSelector((state) => state?.community?.communityConfig);

  useEffect(() => {
    getNotifications();
  }, [communityId]);

  const getNotifications = () => {
    setIsFetching(true);
    server
      .get(`/scenes_notifications?community_id=${communityId}`)
      .then((res) => {
        let notifications = [];

        if (isMobile && communityConfig?.canManageCommunity && publicCommunityDetails?.pending_onboarding_entries_count) {
          notifications.push({
            id: 'pending_onboarding_entries',
            type: 'scenes_notification',
            attributes: {
              action_type: 'PendingOnboardingEntriesCount',
              message: `You have ${publicCommunityDetails?.pending_onboarding_entries_count} pending member requests`,
              actor_image: currentActiveCommunity?.icon
            },
          });
        }

        notifications = [...notifications, ...res.data?.data];

        const { page, next, last } = res.data?.links;
        const isNotificationUnread = res.data?.unread_notification_community_ids?.includes(communityId);

        setNotifications(notifications);
        setIsFetching(false);
        setIsUnread(isNotificationUnread);
        setCurrPage(page);
        setNextPage(next);
        setLastPage(last);
      })
      .catch(() => {
        setIsFetching(false);
      });
  };

  const getNextNotifications = () => {
    if (currPage >= lastPage || isFetching || !nextPage) return;

    setIsFetching(true);
    server
      .get(`/scenes_notifications?community_id=${communityId}&page=${nextPage}`)
      .then((res) => {
        const { page, next, last } = res.data?.links;
        setNotifications([...notifications, ...res.data?.data]);
        setIsFetching(false);
        setCurrPage(page);
        setNextPage(next);
        setLastPage(last);
      })
      .catch(() => {
        setIsFetching(false);
      });
  };

  const menuItems = notifications.map((notif) => {
    const { actor_image, community } = notif.attributes;
    return {
      ...notif.attributes,
      type: notif.attributes.action_type,
      image: actor_image || community?.icon || community?.cover_image || '',
      message: notif.attributes.message,
      created_at: notif.attributes.created_at || '',
    };
  });

  const onPhotoClick = (notification) => {
    const { actor_id } = notification;
    if (actor_id) {
      history.push(`/profile?user_id=${actor_id}`);
    }
  };

  const onClick = (notification) => {
    const { type, community, channel, metadata } = notification;
    let redirectUrl;

    switch (type) {
      case 'PendingOnboardingEntriesCount':
        redirectUrl = '/community-settings/manage-people';
        break;

      case 'CommunityMilestone':
        redirectUrl = `/`;
        break;

      case 'CommunityJoin':
      case 'AssignRole':
        onPhotoClick(notification);
        break;

      case 'NewResourcePost':
      case 'ResourcePostLike':
      case 'ResourceComment':
      case 'ResourceCommentMention':
        redirectUrl = `/communities/${community?.id}/group/${channel?.group_id}/channels/${channel?.id}/resourceposts/${metadata?.resource_post_id}`;
        break;

      case 'ForumPostLike':
      case 'ForumPostComment':
      case 'ForumPostCommentLike':
      case 'ForumCommentReply':
      case 'ForumCommentReplyLike':
      case 'ForumReplyMention':
      case 'ForumCommentMention':
        redirectUrl = `/communities/${community?.id}/group/${channel?.group_id}/channels/${channel?.id}/forumposts/${metadata?.forum_post_id}`;
        break;

      case 'CalendarEvent':
        redirectUrl = `/communities/${community?.id}/group/${channel?.group_id}/channels/${channel?.id}/calendarposts/${notification?.source_id}`;
        break;

      default:
        console.log(notification);
        break;
    }

    if (redirectUrl && redirectUrl !== location.href) {
      history.push(redirectUrl, { isFromOverview: window.location.pathname === '/' ? true : false });
    }
  };

  const viewTypeOne = () => {
    return (
      <Menu as="div" className="relative inline-block text-left z-20">
        <div>
          <MenuButton className="rounded flex items-center text-gray-500 hover:text-gray-700 focus:outline-none">
            <a
              onClick={() => setIsUnread(false)}
              href="#"
              className="flex-shrink-0 rounded-full p-1 text-gray-500 hover:text-gray-700 focus:text-black"
              id="notificationsMain"
            >
              <span className="sr-only">{$translatei18n('ViewNotifications')}</span>
              <BellIcon className="h-6 w-6 text__header" aria-hidden="true" />
              {isUnread && (
                <span className="absolute top-1 right-1 xs:right-4 flex justify-center items-center p-0.5 header">
                  <span className="animate-pulse h-1.5 w-1.5 rounded-full bg-red-600" />
                </span>
              )}
            </a>
          </MenuButton>
        </div>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <MenuItems className="ltr:origin-top-right rtl:origin-top-left absolute ltr:right-0 rtl:left-0 ltr:xs:-right-10 rtl:xs:-left-10 mt-2 rounded-md shadow-lg menu__options__container border focus:outline-none w-96 xs:w-72 sm:w-72 max-h-72 overflow-scroll notification__menu--container">
            <div className="py-1">
              {menuItems?.length == 0 ? (
                <div className="flex flex-col justify-center items-center px-2 py-32 menu__option notification__menu--option">
                  <BellIcon className="h-6 w-6 -mt-4" aria-hidden="true" />
                  <p className="mt-1">There are no new notifications here yet.</p>
                </div>
              ) : null}
              {menuItems.map((item, key) => (
                <MenuItem key={key}>
                  <div className="menu__option group flex items-start cursor-pointer px-5 pt-4 text-sm notification__menu--option">
                    <RenderNotification
                      notif={item}
                      onPhotoClick={() => onPhotoClick(item)}
                      onClick={() => onClick(item)}
                    />
                  </div>
                </MenuItem>
              ))}
              {menuItems?.length > 0 && currPage < lastPage ? (
                <div className="flex justify-center">
                  <button
                    onClick={() => getNextNotifications()}
                    style={{ color: activeCommunityAccentColor }}
                    className="text-sm font-bold text__link"
                  >
                    {$translatei18n('SeeMore')}
                  </button>
                </div>
              ) : null}
            </div>
          </MenuItems>
        </Transition>
      </Menu>
    );
  };

  const viewTypeTwo = () => {
    return (
      <div className="mt-16 rounded-md shadow-lg menu__options__container border focus:outline-none w-full xs:w-72 sm:w-72 h-[calc(100vh-52px)] overflow-scroll notification__menu--container">
        <div className="py-1 w-7/12 m-auto xs:w-full">
          {menuItems?.length == 0 ? (
            <div className="flex flex-col justify-center items-center px-2 py-32 menu__option notification__menu--option">
              <BellIcon className="h-6 w-6 -mt-4" aria-hidden="true" />
              <p className="mt-1">There are no new notifications here yet.</p>
            </div>
          ) : null}
          {menuItems.map((item, key) => (
            <div key={key}>
              <div className="menu__option group flex items-start cursor-pointer px-5 pt-4 text-sm notification__menu--option">
                <RenderNotification
                  notif={item}
                  onPhotoClick={() => onPhotoClick(item)}
                  onClick={() => onClick(item)}
                />
              </div>
            </div>
          ))}
          {menuItems?.length > 0 && currPage < lastPage ? (
            <div className="flex justify-center">
              <button
                onClick={() => getNextNotifications()}
                style={{ color: activeCommunityAccentColor }}
                className="text-sm font-bold text__link"
              >
                {$translatei18n('SeeMore')}
              </button>
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  return <>{page ? viewTypeTwo() : viewTypeOne()}</>;
}

export default NotificationList;
