/* This example requires Tailwind CSS v2.0+ */
import { Dialog, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import translate, { setLocale, getCurrentLocale } from '../../i18n';

window.$translatei18n = translate;
window.$getCurrentLocale = getCurrentLocale;
window.$setLocale = setLocale;
import { TrashIcon, XIcon } from '@heroicons/react/outline';
import _ from 'lodash';
import { Fragment, useState, useEffect, useCallback, useId } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { SUCCESS_MESSAGES } from '../../constants/notificationMessages';
import { analyticsDefaults, classNames, parseAxiosErrorMessage } from '../../constants/utils';
import {
  getActiveCommunity,
  getActiveCommunityFontsClass,
  getActiveCommunityId,
  getActiveGroup,
} from '../../selectors/CommunitySelectors';
import { getCurrentLoggedInUserId } from '../../selectors/ProfileSelectors';
import { deleteCoinSystem } from '../../services/CoinSystemServices';
import NotificationService from '../../services/notificationService';
import { updateCommunityData } from '../../store/actions/communityActions';
import Subscription from '../../components/subscription/index';
import { SUBSCRIPTION_LOCKED_UI_ID } from '../../constants/subscriptionConstants';
import AnalyticsService from '../../services/AnalyticsService';
import { SUBSCRIPTION_AMPLITUDE_EVENT_TITLE } from '../../constants';
import { saveComponentLockStatus } from '../../store/actions/planAction';
import { checkComponentSubscriptionStatus, getNumberData } from '../../constants/subscriptionUtils';
import AutomateRolesIcon from '../../components/icons/AutomateRolesIcon';
import { SETTINGS_ROUTES_CONSTANTS } from '../../constants/routesConstants';
import settingsRoutes from '../../routes/settingsRoutes';
import { renderChildList, toRenderSettingsOption, toRenderSettingsOptionTitle } from './utils/dataProcessor';
import MixpanelIcon from '../../components/icons/MixpanelIcon';
import GraphyIcon from '../../components/icons/GraphyIcon';

const settingsNavigation = [
  {
    name: $translatei18n('Settings'),
    children: [
      SETTINGS_ROUTES_CONSTANTS.COMMUNITY_SETTINGS,
      SETTINGS_ROUTES_CONSTANTS.PEOPLE_MANAGEMENT,
      SETTINGS_ROUTES_CONSTANTS.MANAGE_ROLES,
      SETTINGS_ROUTES_CONSTANTS.APPEARANACE,
      SETTINGS_ROUTES_CONSTANTS.CUSTOM_CSS,
      SETTINGS_ROUTES_CONSTANTS.NOTIFICATION_PREFERENCE,
      SETTINGS_ROUTES_CONSTANTS.PRIVACY_POLICY,
      SETTINGS_ROUTES_CONSTANTS.PLAN_DETAILS,
      SETTINGS_ROUTES_CONSTANTS.MODERATION,
    ],
  },
  {
    name: $translatei18n('Integrations'),
    children: [
      SETTINGS_ROUTES_CONSTANTS.MUX_SETUP,
      SETTINGS_ROUTES_CONSTANTS.MIXPANEL_SETUP,
      SETTINGS_ROUTES_CONSTANTS.CUSTOM_DOMAIN,
      SETTINGS_ROUTES_CONSTANTS.SINGLE_SIGN_ON,
      SETTINGS_ROUTES_CONSTANTS.EMAIL_WHITELABEL,
      SETTINGS_ROUTES_CONSTANTS.WEBHOOK,
      SETTINGS_ROUTES_CONSTANTS.GRAPHY_INTEGRATION,
      SETTINGS_ROUTES_CONSTANTS.AUTOMATE_ROLE,
      SETTINGS_ROUTES_CONSTANTS.ZOOM_SETTINGS,
    ],
  },
  {
    name: $translatei18n('AddNew'),
    children: [
      SETTINGS_ROUTES_CONSTANTS.NEW_CHANNEL,
      SETTINGS_ROUTES_CONSTANTS.NEW_CATEGORY,
      SETTINGS_ROUTES_CONSTANTS.CREATE_ROLE,
      SETTINGS_ROUTES_CONSTANTS.NEW_GROUP,
      SETTINGS_ROUTES_CONSTANTS.MANAGE_TAGS,
    ],
  },
  {
    name: $translatei18n('UserOnboarding'),
    children: [
      SETTINGS_ROUTES_CONSTANTS.INVITES,
      SETTINGS_ROUTES_CONSTANTS.ONBOARDING_SETTINGS,
      // SETTINGS_ROUTES_CONSTANTS.NEW_APPLICATIONS,
    ],
  },
  {
    name: $translatei18n('Mobile'),
    children: [SETTINGS_ROUTES_CONSTANTS.CUSTOM_PUSH_NOTIFICATION, SETTINGS_ROUTES_CONSTANTS.THEMING],
  },
  {
    name: $translatei18n('Monetisation'),
    children: [
      SETTINGS_ROUTES_CONSTANTS.SHOP_SETUP,
      SETTINGS_ROUTES_CONSTANTS.SETUP_COIN,
      SETTINGS_ROUTES_CONSTANTS.ALLOCATE_COIN,
    ],
  },
];

export default function SidebarNav({ open, closeMenu }) {
  const dispatch = useDispatch();
  const activeCommunityId = useSelector(getActiveCommunityId);
  const activeCommunity = useSelector(getActiveCommunity);
  const { primaryFont } = useSelector(getActiveCommunityFontsClass);
  const currentUserId = useSelector(getCurrentLoggedInUserId);
  const communityConfig = useSelector((state) => state?.community?.communityConfig);
  const groupConfig = useSelector((state) => state?.channel?.configs?.groupConfigs);
  const isOwner = useSelector((state) => state?.community?.isOwner);
  const subscriptionDays = useSelector((state) => state?.community?.subscriptionDays);
  const subscriptionData = useSelector((state) => state?.community?.subscriptionData);
  const isAfterSubscriptionCommunity = useSelector((state) => state?.community?.subscriptionActive);
  const isPlanChange = useSelector((state) => state?.plans?.currentActiveProduct?.planPriceId);
  const activeGroup = useSelector(getActiveGroup);

  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);

  useEffect(() => {
    const featureObj = {};
    const navigationArr = settingsNavigation.map((val) => renderChildList(settingsRoutes, val?.children));
    navigationArr.map((nav) => {
      nav.map((item) => {
        const componentSubscriptionId = item.componentSubscriptionId;
        if (componentSubscriptionId) {
          const toRenderSubscriptionUI = getNumberData(componentSubscriptionId, subscriptionData);
          const componentStatus = checkComponentSubscriptionStatus(
            toRenderSubscriptionUI,
            subscriptionData,
            componentSubscriptionId,
            isAfterSubscriptionCommunity,
            true,
          );
          if (componentSubscriptionId) {
            featureObj[componentSubscriptionId] = componentStatus;
          }
        }
      });
    });
    dispatch(saveComponentLockStatus(featureObj));
  }, [isPlanChange]);

  const isCurrentUserHost = useCallback(
    (function getCurrentHostBoolean() {
      let isCurrentUserHost = false;
      activeCommunity?.hosts?.forEach((host) => {
        if (host?.id?.toString() === currentUserId.toString()) {
          isCurrentUserHost = true;
        }
      });
      return isCurrentUserHost;
    })(),
    [],
  );

  const handleDeleteCoin = async () => {
    setOpenDeleteConfirmationModal(false);
    try {
      const res = await deleteCoinSystem(activeCommunityId);
      const { status, data } = res;
      if (status >= 200 && status < 300) {
        NotificationService.success(SUCCESS_MESSAGES.COIN_DELETE);
        const { attributes } = data.data;
        const activeCommunityCopy = _.cloneDeep(activeCommunity);
        activeCommunityCopy.coin_enable = attributes?.coin_enable;
        activeCommunityCopy.coin_icon = attributes?.coin_icon;
        activeCommunityCopy.coin_name = attributes?.coin_name;
        activeCommunityCopy.coin_abbreviation = attributes?.coin_abbreviation;
        activeCommunityCopy.coin_distribution = attributes?.coin_distribution;

        dispatch(updateCommunityData(activeCommunityCopy));
      }
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  };

  const handleNavigation = (item) => {
    const { name: itemName, path } = item;
    if (typeof closeMenu === 'function') {
      closeMenu();
    }
    let itemNew = '';
    if (itemName) {
      itemNew = itemName?.toLowerCase();
      itemNew = itemNew.replaceAll(' ', '_');
    }
    AnalyticsService.trackAmplitudeEvent(SUBSCRIPTION_AMPLITUDE_EVENT_TITLE.COMMUNITY_LEFT_NAVBAR_ITEM_TAPPED, {
      ...analyticsDefaults(path),
      item: itemNew,
    });
  };

  return (
    <div
      className={`${open ? 'fixed top-0 right-0 left-0 bottom-0 main--background overflow-scroll' : ''}`}
      style={{ zIndex: 99999 }}
    >
      <nav className="flex-1 px-2 space-y-1 relative">
        {open && (
          <button className="absolute right-4 top-4" onClick={() => closeMenu()}>
            <XIcon className="w-6 h-6" />
          </button>
        )}
        {settingsNavigation.map((parentItem) => {
          if (
            toRenderSettingsOptionTitle(
              parentItem?.children,
              isOwner,
              subscriptionDays,
              communityConfig,
              activeCommunity,
              groupConfig,
              isCurrentUserHost,
            )
          )
            return (
              <div
                key={parentItem.name}
                className={classNames(
                  'items-start justify-between flex-shrink-0 px-2 pt-5 pb-2 main--background',
                  primaryFont,
                )}
                style={{ zIndex: 99 }}
              >
                <h2 className="text-base font-medium text__title mb-2">{parentItem?.name}</h2>
                {/* {open && (
                  <button onClick={() => closeMenu()}>
                    <XIcon className="w-6 h-6" />
                  </button>
                )} */}
                <div>
                  {renderChildList(settingsRoutes, parentItem?.children)?.map(
                    (item) =>
                      toRenderSettingsOption(
                        item,
                        isOwner,
                        subscriptionDays,
                        communityConfig,
                        activeCommunity,
                        groupConfig,
                        isCurrentUserHost,
                        activeGroup,
                      ) && (
                        <Subscription
                          componentSubscriptionId={item?.componentSubscriptionId}
                          replacementId={SUBSCRIPTION_LOCKED_UI_ID.SETTINGS_LOCK}
                          route={item?.path}
                          handleClose={closeMenu}
                          item={item}
                        >
                          <LinkHandler
                            item={item}
                            activeCommunity={activeCommunity}
                            handleNavigation={handleNavigation}
                            primaryFont={primaryFont}
                          />
                        </Subscription>
                      ),
                  )}
                  {activeCommunity?.coin_enable &&
                    communityConfig?.canManageCommunity &&
                    parentItem?.name === $translatei18n('Monetisation') && (
                      <button
                        type="button"
                        id="deleteCoin"
                        className="group flex items-center px-2 py-2 text-sm rounded-md text__danger w-full"
                        onClick={() => {
                          setOpenDeleteConfirmationModal(true);
                        }}
                      >
                        <TrashIcon className="mr-3 flex-shrink-0 h-5" aria-hidden="true" />
                        {$translatei18n('DeleteCoin')}
                      </button>
                    )}
                </div>
              </div>
            );
        })}
      </nav>

      <DeleteModal
        title="Delete Community Coin"
        infoText="Deleting this coin will remove this coin from all user wallets forever. Are you sure you want to delete your community coin?"
        open={openDeleteConfirmationModal}
        onClose={() => setOpenDeleteConfirmationModal(false)}
        onConfirm={handleDeleteCoin}
        deleteButtonText={$translatei18n('DeleteCoin')}
        primaryFont={primaryFont}
      />
    </div>
  );
}

export function DeleteModal({
  title,
  infoText,
  open,
  onClose,
  onConfirm,
  deleteButtonText,
  primaryFont,
  deleteLoading,
}) {
  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" onClose={onClose}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center md:block md:p-0">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={onClose} />
          </TransitionChild>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden md:inline-block md:align-middle md:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            enterTo="opacity-100 translate-y-0 md:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 md:scale-100"
            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
          >
            <div className="inline-block align-bottom card justify-center rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all md:my-8 md:align-middle md:max-w-lg md:w-full md:p-6">
              <div className="md:flex md:items-start">
                <div className="mt-3 text-center md:mt-0 md:ml-4 md:text-left">
                  <DialogTitle
                    as="h3"
                    className={classNames('text-lg leading-6 font-medium text__title', primaryFont)}
                  >
                    {title}
                  </DialogTitle>
                  <div className="mt-2">
                    <p className="text-sm text__description">{infoText}</p>
                  </div>
                </div>
              </div>
              <div className="mt-5 md:mt-4 md:flex md:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-0 focus:ring-offset-0 md:ml-3 md:w-auto md:text-sm btn__danger disabled:opacity-50 disabled:cursor-not-allowed"
                  onClick={onConfirm}
                  disabled={deleteLoading}
                >
                  {deleteButtonText}
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-0 focus:ring-offset-0 md:mt-0 md:w-auto md:text-sm btn__light"
                  onClick={onClose}
                >
                  {$translatei18n('Cancel')}
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
}

const IconHandler = (props) => {
  switch (props.id) {
    case SETTINGS_ROUTES_CONSTANTS.GRAPHY_INTEGRATION:
      return <GraphyIcon fill={props.id === location?.pathname ? 'white' : 'black'} classNames="mr-3" />;
    case SETTINGS_ROUTES_CONSTANTS.MIXPANEL_SETUP:
      return <MixpanelIcon fill={props.id === location?.pathname ? 'white' : 'black'} classNames="mr-3 ml-1" />;
    case SETTINGS_ROUTES_CONSTANTS.AUTOMATE_ROLE:
      return <AutomateRolesIcon fill={props.id === location?.pathname ? 'white' : 'black'} classNames="mr-3" />;
    default:
      return <props.icon className={classNames('mr-3 flex-shrink-0 h-5')} aria-hidden="true" />;
  }
};

const LinkHandler = ({ item, activeCommunity, handleNavigation, primaryFont }) => {
  if (item.path === SETTINGS_ROUTES_CONSTANTS.SETUP_COIN || item.path === SETTINGS_ROUTES_CONSTANTS.ALLOCATE_COIN) {
    if (item.path === SETTINGS_ROUTES_CONSTANTS.ALLOCATE_COIN && !activeCommunity?.coin_enable) {
      return;
    }
    if (item.path === SETTINGS_ROUTES_CONSTANTS.SETUP_COIN) {
      return (
        <Link
          id={item?.name}
          to={item?.path}
          className={classNames(
            item?.path === location?.pathname ? `btn__primary ${primaryFont}` : ' text__body',
            'group flex items-center px-2 py-2 text-sm rounded-md relative font-medium',
          )}
          style={{
            backgroundColor: item?.path === location?.pathname ? activeCommunity?.accent_color : '',
          }}
          onClick={() => handleNavigation(item)}
        >
          <IconHandler icon={item?.icon} id={item?.path} />
          {activeCommunity?.coin_enable ? $translatei18n('EditCoin&Rewards') : item?.name}
        </Link>
      );
    }
  }
  return (
    <Link
      id={item?.name}
      to={item?.path}
      className={classNames(
        item?.path === location?.pathname
          ? `btn__primary ${primaryFont}`
          : item.name === $translatei18n('Moderation')
          ? 'text-red-500'
          : ' text__body',
        'group flex items-center px-2 py-2 text-sm rounded-md relative font-medium',
      )}
      style={{
        backgroundColor: item?.path === location?.pathname ? activeCommunity?.accent_color : '',
      }}
      onClick={() => handleNavigation(item)}
    >
      <IconHandler icon={item?.icon} id={item?.path} />
      {item?.name}
    </Link>
  );
};
