import React, { memo, useId } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { analyticsDefaults, history } from '../../constants/utils';
import { setBookADemoClicked, setModalOpen } from '../../store/actions/planAction';
import { SUBSCRIPTION_LOCKED_UI_ID } from '../../constants/subscriptionConstants';
import { SUBSCRIPTION_AMPLITUDE_EVENT_TITLE } from '../../constants';
import AnalyticsService from '../../services/AnalyticsService';

const LockedUIHandler = ({ children, toRenderSubscriptionUI, route, replacementId, handleClose, featureIdentifier, routeName, place, item, offset = 0 }) => {
  const dispatch = useDispatch();
  const toolTipId = useId();
  const isOwner = useSelector((state) => state?.community?.isOwner);
  const communityConfig = useSelector((state) => state?.community?.communityConfig);

  const handleAnalytics = () => {
    const {name: itemName, path} = item
    let itemNew = '';
    if (itemName) {
      itemNew = itemName?.toLowerCase();
      itemNew = itemNew.replaceAll(' ', '_');
    }
    AnalyticsService.trackAmplitudeEvent(SUBSCRIPTION_AMPLITUDE_EVENT_TITLE.COMMUNITY_LEFT_NAVBAR_ITEM_TAPPED, {
      ...analyticsDefaults(path),
      item: itemNew,
    });
  };
  const conditionalClicking = (e) => {
    if (replacementId === SUBSCRIPTION_LOCKED_UI_ID.GROUP_LOCK && isOwner) {
      dispatch(setModalOpen());
      dispatch(setBookADemoClicked(false))
    } else {
      if (typeof handleClose === 'function') {
        handleClose();
      }
      handleAnalytics()
      history.push(route);
    }
    
  };

  return (
    <>
      <div
        className="relative cursor-pointer"
        data-tooltip-html={$translatei18n('AskOwnerToUpgrade')}
        data-tooltip-id={toolTipId}
        data-tooltip-position-strategy="fixed"
        data-tooltip-offset={offset}
        onClick={(e) => {
          conditionalClicking(e);
        }}
      >
        {children}
        <CommonToolTip
          isOwner={isOwner}
          toolTipId={toolTipId}
          communityConfig={communityConfig}
          toRenderSubscriptionUI={toRenderSubscriptionUI}
          place={place}
        />
      </div>
    </>
  );
};

const areEqual = (prev, next) => _.isEqual(prev, next);

export default memo(LockedUIHandler, areEqual);

export const CommonToolTip = ({ isOwner, toolTipId, communityConfig, toRenderSubscriptionUI, lockWrapper = null, place = "top" }) => {
  if ((!isOwner && communityConfig && !toRenderSubscriptionUI?.available) || lockWrapper)
    return <ReactTooltip id={toolTipId} place={place} effect="solid" />;
};
