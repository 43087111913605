import React, { Suspense, useCallback, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { CommunityFavicon } from './components/community/CommunityFavicon';
import CommunityNotFound from './components/shared/tailwind/CommunityNotFound';
import LoadingSpinner from './components/shared/tailwind/LoadingSpinner';
import { getAuthToken } from './constants/authUtils';
import { history, isNullOrUndefined } from './constants/utils';
import { getScreenNameForPath } from './constants/analyticsUtils';
import AnalyticService from './services/AnalyticsService';
import {
  checkCommunityMember,
  checkForMaintenance,
  fetchPublicCommunityDetails,
  onboardingEntriesPost,
  setUserRoleOnInvite,
  verifyOnboardingEntry,
} from './services/communityService';
import {
  FETCH_PUBLIC_COMMUNITY_DETAILS_SUCCESS,
  IS_APP_IN_APP,
  ONBOARDING_VERIFY_COMMUNITY_STEP_SUCCESS,
  ONBOARDING_VERIFY_COMMUNITY_STEP_UPDATE,
  SET_COMMUNITY_MEMBER_FLAG,
  SET_REDIRECT_URL,
  // UPDATE_GRAPHY_SSO_EXTERNAL_NAME,
  VERIFY_COMMUNITY_MEMBER_ROLES_SUCCESS,
  // VERIFY_COMMUNITY_MEMBER_FAILED,
  VERIFY_COMMUNITY_MEMBER_SUCCESS,
} from './store/actions/actionTypes';
import {
  setBranchInitError,
  setIsCommunityOwner,
  setVerifyCommunityMemberLoading,
  updateCommunityData,
  setSubscriptionError,
} from './store/actions/communityActions';
import { persistor, store } from './store/configureStore';
import { initialiseSocket } from './store/actions/textActions';
import ErrorBoundary from './components/errors';
import { ERROR_CODES } from './constants/errorCodes';
import { setSubscriptionDataInStore } from './components/subscription/subscriptionUtils';
import { getcurrentPlanRequest, setActiveProductPlan } from './store/actions/planAction';
import { setMixpanelAPIAttributes } from './store/actions/mixpanelActions';
import CustomRoutesContainer from './components/CustomRoutesContainer';
import { setQueryString, setRedirectUrl } from './store/actions/authActions';
import { removeJwtToken } from './views/community/Utils';
import { getIsAllowedPath } from './utils/Utils';

function App({ setPage }) {
  const [isLoading, setIsLoading] = useState(true);
  const [isDomainVerified, setIsDomainVerified] = useState(false);
  const authToken = getAuthToken();
  const { user } = store.getState();
  const redirectUrl = store.getState().auth?.redirectUrl;
  const publicCommunityDetails = store.getState().community?.publicCommunityDetails;
  const onboardingVerifyStepStatus = store.getState()?.onboarding?.onboardingVerifyStepStatus;
  const isCommunityMemberVerified = store.getState()?.community?.isCommunityMemberVerified || false;
  const isCustomOnboarding = publicCommunityDetails?.custom_onboarding || false;
  const entryApproval = publicCommunityDetails?.entry_approval;

  let previousPath = '';

  const trackRouteChange = useCallback((pathname) => {
    if (pathname === previousPath) return;

    const publicCommunityDetails = store.getState().community?.publicCommunityDetails;

    const community_id = publicCommunityDetails?.id;

    AnalyticService.logMixpanelEvent('Screen Navigation', {
      'Screen Name': getScreenNameForPath(pathname),
      'Community ID': community_id,
    });

    previousPath = pathname;
  }, []);

  const initializeBranch = (branchKey) => {
    const key = branchKey || process.env.REACT_APP_BRANCH_KEY;
    window.branch?.init(key, (err, data) => {
      if (err) {
        store.dispatch(setBranchInitError(true));
        console.log('\n\nBranch Init Err: ', err);
        return;
      }
      store.dispatch(setBranchInitError(false));
      const params = data.data_parsed;
      const customData = params?.custom_data ? JSON.parse(params?.custom_data) : {};
      const referralCode = customData?.referral_code;
      const inviteCode = customData?.invite_code;

      if (referralCode) {
        localStorage.setItem('ReferredBy', referralCode);
      }

      if (inviteCode) {
        localStorage.setItem('InviteCode', inviteCode);
      }
    });
  };

  const fetchPublicDetails = useCallback(async () => {
    try {
      const response = await fetchPublicCommunityDetails();
      const {
        data: { id, settings, has_app, branch_key },
      } = response;
      if (id) {
        store.dispatch(updateCommunityData({ id: id }));
        store.dispatch(setMixpanelAPIAttributes(settings.mixpanel_project_id, id));
      }
      if (has_app || branch_key) {
        initializeBranch(branch_key);
      }
      store.dispatch({
        type: FETCH_PUBLIC_COMMUNITY_DETAILS_SUCCESS,
        payload: response,
      });

      const pathname = window.location.pathname;
      const filterPathList = ['forumposts', 'forum', 'resourceposts'];
      const isAllowedPath = getIsAllowedPath(filterPathList, pathname);

      // if (
      //   window.location.hostname !== response?.data?.domain_address &&
      //   response?.data?.domain_status &&
      //   response?.data?.domain_address &&
      //   process.env.REACT_APP_ENV === 'staging'
      // ) {
      //   const check =
      //     /((https?:\/\/)?(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g.test(
      //       response?.data?.domain_address,
      //     );
      //   if (check) {
      //     window.location.href = `https://${response?.data?.domain_address}`;
      //   }
      // }

      if (authToken) {
        const onboardingStepVerify = await verifyOnboardingEntry();
        store.dispatch({
          type: ONBOARDING_VERIFY_COMMUNITY_STEP_SUCCESS,
          payload: onboardingStepVerify?.data,
        });

        await checkCommunityMember()
          .then(async (res) => {
            if (
              res?.data?.community_user?.community_id == 4375 &&
              localStorage.getItem('currentLocal') !== res?.data?.community_user?.language
            ) {
              localStorage.setItem('currentLocal', res?.data?.community_user?.language);
              window?.$setLocale(res?.data?.community_user?.language);
              window.location.href = window.location.href;
            }

            const socketConn = store.getState().socket.socket;

            if (!socketConn) {
              const { dispatch } = store;
              const { getState } = store;
              await initialiseSocket({
                auth: getState()?.auth,
                dispatch,
                channelId: null,
                getState,
              });
            }

            // store.dispatch({
            //   type: UPDATE_GRAPHY_SSO_EXTERNAL_NAME,
            //   payload: res?.data?.graphy_external_name || null,
            // });

            store.dispatch({
              type: VERIFY_COMMUNITY_MEMBER_SUCCESS,
              payload: res?.data?.community_user,
            });

            store.dispatch({
              type: VERIFY_COMMUNITY_MEMBER_ROLES_SUCCESS,
              payload: res?.data?.community_roles,
            });

            // Handle Entire Subscritption Data Management
            setSubscriptionDataInStore(res?.data);
            let isOwner = false;

            res?.data?.community_roles?.forEach((item) => {
              if (item.role === 'owner') {
                isOwner = true;
              }
            });
            store.dispatch(setIsCommunityOwner(isOwner));

            if (res?.data?.subscription_active) {
              store.dispatch(setSubscriptionError(null));
            }
            const isOldUser = isNullOrUndefined(res?.data?.subscription_active);
            if (isOwner && !isOldUser) {
              store.dispatch(getcurrentPlanRequest(res?.data?.community_user?.community_id));
            }
            store.dispatch(setActiveProductPlan({ planName: res?.data?.current_plan_name, priceId: null }));
          })
          .catch(async (error) => {
            if (
              user?.onboarding_status == 'voice_onboarding_completed' ||
              user?.onboarding_status == 'voice_contacts_synced'
            ) {
              if (!onboardingStepVerify?.data?.status || (entryApproval == false && isCustomOnboarding == false)) {
                if (entryApproval == true && isCustomOnboarding == false) {
                  // API
                  const referralCode = localStorage.getItem('ReferredBy');
                  const inviteCode = localStorage.getItem('InviteCode');
                  const onboardingPayload = {
                    metadata: {},
                  };

                  if (referralCode) onboardingPayload.metadata.referral_code = referralCode;
                  if (inviteCode) onboardingPayload.metadata.invite_code = inviteCode;

                  const response = await onboardingEntriesPost(onboardingPayload);
                  store.dispatch({
                    type: ONBOARDING_VERIFY_COMMUNITY_STEP_UPDATE,
                    payload: response?.data?.status,
                  });
                  window.location.href = window.location.origin;
                }
                return;
              }
            }

            if (onboardingStepVerify?.data?.status) {
              history.replace('/enter-community');
            }

            if (error.response.status >= 500 && error.response.status <= 510) {
              store.dispatch({
                type: SET_COMMUNITY_MEMBER_FLAG,
                payload: null,
              });
              history.replace('/public/internal-server-error');
            } else {
              store.dispatch({
                type: SET_COMMUNITY_MEMBER_FLAG,
                payload: false,
              });
            }
          })
          .finally(() => {
            store.dispatch(setVerifyCommunityMemberLoading(false));
          });
      } else if (
        !authToken &&
        (window.location.pathname === '/' || isAllowedPath) &&
        response?.data?.settings?.guest_view
      ) {
        const search = `${pathname}${window.location.search}`;
        if (!pathname.includes('/guest')) {
          store.dispatch(setQueryString(search === '/' ? '' : search));
          const path = pathname === '/' ? '/guest' : '/guest' + pathname;
          history.push(path);
        } else if (search.includes('/guest')) {
          const newPath = search.replace('/guest', '');
          store.dispatch(setQueryString(newPath));
        }
      } else if (!authToken && (pathname === '/' || isAllowedPath) && !response?.data?.settings?.guest_view) {
        const search = `${pathname}${window.location.search}`;
        if (search.includes('/guest')) {
          const newPath = search.replace('/guest', '');
          store.dispatch(setQueryString(newPath === '/' ? '' : newPath));
        } else {
          store.dispatch(setQueryString(search === '/' ? '' : search));
        }
      } else if (!authToken && pathname === '/oauth2/callback') {
        const path = window.location.href.split('redirect_uri=')[1];
        if (path) {
          store.dispatch(setQueryString(`/${path}`));
        } else {
          const q = window.location.href.split('?')[1];
          const query = q ? `/?${q}` : '/';
          if (q && q !== '/') {
            store.dispatch(setQueryString(query));
          }
        }
      }

      setIsDomainVerified(true);
      setIsLoading(false);
      new AnalyticService();
    } catch (error) {
      if (error.response && error.response.status && error.response.status == 503) {
        setPage('maintenance');
      }
      setIsDomainVerified(false);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (user && (process.env.REACT_APP_ENV === 'ggnation' || process.env.REACT_APP_ENV === 'production')) {
      LogRocket.init(process.env.REACT_APP_LOGROCKET_KEY);
      setupLogRocketReact(LogRocket);
    }

    const queryParameters = new URLSearchParams(window.location.search);
    const isAppInApp = queryParameters.get('app_in_app');
    if (isAppInApp) {
      store.dispatch({
        type: IS_APP_IN_APP,
        payload: isAppInApp,
      });
    }

    fetchPublicDetails();

    function beforeExitReset() {
      AnalyticService.clearUser();
    }

    window.addEventListener('beforeunload', beforeExitReset, { capture: true });

    return window.removeEventListener('beforeunload', beforeExitReset, { capture: true });
  }, []);

  useEffect(() => {
    const unlisten = history.listen((listener) => {
      const { pathname } = listener;
      trackRouteChange(pathname);
    });
    const currentLocal = localStorage.getItem('currentLocal');
    if (currentLocal === 'he') {
      const body = document.getElementsByTagName('body')[0];
      body.setAttribute('dir', 'rtl');
    } else {
      const body = document.getElementsByTagName('body')[0];
      body.setAttribute('dir', 'ltr');
    }

    return unlisten;
  }, []);

  useEffect(() => {
    if (!publicCommunityDetails?.id) return;
    trackRouteChange(history.location.pathname);
  }, [publicCommunityDetails]);

  useEffect(() => {
    if (!user?.username) return;
    if (user && user?.id) {
      const community_id = publicCommunityDetails?.id;
      const community_name = publicCommunityDetails?.name;
      LogRocket.identify(user?.id, {
        name: user?.username,
        email: user?.email,
        community_id,
        community_name,
      });
    }
  }, [user]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (authToken) {
    const queryParams = store.getState()?.auth?.queryString;
    const _query = queryParams.split('?')[1];
    const query = removeJwtToken(_query);
    const pathname = queryParams.split('?')[0];

    if (window.location.pathname === '/oauth2/callback') {
      const path = window.location.href.split('redirect_uri=')[1];
      if (path) {
        store.dispatch(setQueryString(`/${path}`));
      } else {
        const q = window.location.href.split('?')[1];
        const query = q ? `/?${q}` : '/';
        if (q && q !== '/') {
          store.dispatch(setQueryString(query));
        }
      }
    } else if (
      user?.onboarding_status == 'voice_onboarding_completed' ||
      user?.onboarding_status == 'voice_contacts_synced'
    ) {
      if (isCommunityMemberVerified === false && (isCustomOnboarding || entryApproval)) {
        if ((isCustomOnboarding && !onboardingVerifyStepStatus) || onboardingVerifyStepStatus == 'in_process') {
          history.replace('/onboarding/application-form');
        } else if (
          (isCustomOnboarding && onboardingVerifyStepStatus) ||
          (entryApproval && onboardingVerifyStepStatus)
        ) {
          history.replace('/onboarding/application-status');
        } else {
          history.replace('/onboarding/application-error');
        }
      } else if (
        /* isCustomOnboarding && */
        isCommunityMemberVerified === true &&
        !redirectUrl &&
        (window.location.pathname === '/onboarding/application-form' ||
          window.location.pathname === '/onboarding/application-status' ||
          window.location.pathname === '/onboarding/application-error' ||
          window.location.pathname === '/onboarding/mobile' ||
          window.location.pathname === '/register/email/verify-otp' ||
          window.location.pathname === '/enter-community' ||
          window.location.pathname === '/onboarding/create-account')
      ) {
        if (entryApproval || !onboardingVerifyStepStatus) {
          history.replace('/');
        }
      } else if (redirectUrl) {
        history.replace(redirectUrl);
        store.dispatch({
          type: SET_REDIRECT_URL,
          payload: null,
        });
      } else if (window.location.pathname.includes('/guest')) {
        const newPath = window.location.pathname.replace('/guest', '');
        history.push(newPath);
      } else if (pathname) {
        history.replace({
          pathname: pathname || window.location.pathname,
          search: query || window.location.search,
        });
      }
    } else if (user?.onboarding_status == 'voice_onboard_start') {
      history.replace('/register/email/verify-otp');
    } else if (user?.onboarding_status === 'voice_email_confirmed' || user?.onboarding_status == 'voice_otp_sent') {
      history.replace('/onboarding/mobile');
    } else if (user?.onboarding_status === 'voice_otp_verified') {
      history.replace('/onboarding/create-account');
    }
  }

  if (isDomainVerified) {
    return (
      <Router history={history}>
        <Suspense fallback={<LoadingSpinner />}>
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              <ErrorBoundary errorCode={ERROR_CODES.COMMUNITY_LEVEL}>
                <CustomRoutesContainer />
              </ErrorBoundary>
              <ToastContainer
                className="px-4 md:px-6 lg:px-4 w-full max-w-7xl"
                autoClose={3000}
                position="bottom-center"
                hideProgressBar
                pauseOnFocusLoss={false}
                closeButton
                closeOnClick={false}
                draggable={false}
                limit={3}
              />
              <CommunityFavicon />
            </PersistGate>
          </Provider>
        </Suspense>
      </Router>
    );
  }

  return <CommunityNotFound />;
}

export default App;
