const MixpanelIcon = ({ fill, classNames }) => {
  return (
    <svg width={14} height={12} viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg" className={classNames}>
      <path
        d="M4.36236 4.95101H5.96118C5.56084 4.70017 5.41135 4.35051 5.21118 3.70186L4.61067 1.4772C4.33703 0.476351 4.11152 0 3.01186 0H0.713715V0.600507H1.04057C1.71456 0.600507 1.79057 0.851351 1.99074 1.60135L2.51524 3.55236C2.78888 4.5 3.21456 4.95101 4.36236 4.95101ZM8.06169 4.95101H9.66051C10.8108 4.95101 11.2086 4.5 11.4848 3.55236L12.0093 1.60135C12.2095 0.851351 12.3083 0.600507 12.9595 0.600507H13.2864V0H11.011C9.88601 0 9.66051 0.451014 9.40966 1.47466L8.80915 3.69932C8.61405 4.37331 8.46203 4.70017 8.06169 4.95101ZM5.96118 7.04899H8.06169V4.94848H5.96118V7.04899ZM0.713715 12H3.01186C4.11152 12 4.33703 11.5236 4.61067 10.5253L5.21118 8.30067C5.41135 7.65203 5.56084 7.29983 5.96118 7.05152H4.36236C3.21203 7.05152 2.78635 7.50253 2.5127 8.45017L1.98821 10.4012C1.78551 11.1486 1.71203 11.3995 1.03804 11.3995H0.711182L0.713715 12ZM11.011 12H13.2864V11.3995H12.9595C12.3108 11.3995 12.2095 11.1486 12.0093 10.3986L11.4848 8.44763C11.2112 7.49747 10.8108 7.04899 9.66051 7.04899H8.06422C8.46456 7.29983 8.60898 7.62416 8.80915 8.29814L9.40966 10.5228C9.65797 11.549 9.88348 12 11.011 12Z"
        fill={fill}
      />
    </svg>
  );
};

export default MixpanelIcon;
