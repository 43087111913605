import serverV3 from '../api/server';

export const updateCoinDetails = (communityId, coin_detail) => {
  return serverV3.put(`/communities/${communityId}/update_coin`, {
    coin_detail,
  });
};

export const deleteCoinSystem = (communityId) => {
  return serverV3.delete(`/communities/${communityId}/remove_coin`);
};

export const sendCoinTip = (communityId, tip_coin) => {
  return serverV3.put(`/communities/${communityId}/tip_coin`, {
    tip_coin,
  });
};

export const allocateCoin = (communityId, allocate_coin) => {
  return serverV3.put(`/communities/${communityId}/allocate_coin`, {
    allocate_coin,
  });
};

export const getWalletWithID = (communityId, page) => {
  if (page === null || page === undefined) {
    return serverV3.get(`/communities/${communityId}/get_wallet_log`);
  }
  return serverV3.get(`/communities/${communityId}/get_wallet_log?page=${page}`);
};

export const getGobleWallet = (page) => {
  if (page === null || page === undefined) {
    return serverV3.get(`/users/wallet`);
  }
  return serverV3.get(`/users/wallet?page=${page}`);
};
