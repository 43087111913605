import { Dialog, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { BellIcon, ExclamationIcon, PencilAltIcon, PencilIcon, TrashIcon, XIcon } from '@heroicons/react/outline';
import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import { classNames } from '../../constants/utils';
import {
  getActiveCommunityAccentColor,
  getActiveCommunityFontsClass,
  getActiveCommunityId,
  getActiveGroupId,
} from '../../selectors/CommunitySelectors';
import { deleteChannelCategory } from '../../services/channelService';
import NotificationService from '../../services/notificationService';

export default function Sidebar({ open , closeMenu }) {
  const history = useHistory();
  const location = useLocation();
  const activeCommunityId = useSelector(getActiveCommunityId);
  const categories = useSelector((state) => state?.channel?.channels);
  const communityConfig = useSelector((state) => state?.community?.communityConfig);
  const groupConfig = useSelector((state) => state?.channel?.configs?.groupConfigs);
  const { primaryFont } = useSelector(getActiveCommunityFontsClass);
  const accentColor = useSelector(getActiveCommunityAccentColor);
  const [categoryData, setCategoryData] = useState({});
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const activeGroupId = useSelector(getActiveGroupId);

  let channelCategorySettingsPath = matchPath(location.pathname, {
    path: '/channel-category-settings/:channel_category_id',
  });

  let channel_category_id = channelCategorySettingsPath?.params?.channel_category_id;

  useEffect(() => {
    if (!groupConfig?.canManageGroup) {
      handleBackNavigation();
    }
  }, [groupConfig]);

  useEffect(() => {
    if (channel_category_id && categories?.length) {
      let categoryDataObj = {};
      categories.forEach((item) => {
        if (item.id == channel_category_id) {
          categoryDataObj = {
            id: item.id,
            name: item.name,
          };
        }
      });
      if (_.isEmpty(categoryDataObj)) {
        NotificationService.error('Invalid Category');
        handleBackNavigation();
      } else {
        setCategoryData(categoryDataObj);
      }
    } else {
      handleBackNavigation();
    }
  }, [channel_category_id, categories]);

  const handleBackNavigation = () => {
    history.push(`/`);
  };

  const handleDeleteCategory = async () => {
    try {
      setOpenConfirmationModal(false);
      await deleteChannelCategory(activeCommunityId, channel_category_id, activeGroupId);
      NotificationService.success(`Successfully Deleted Category`);
      handleBackNavigation();
    } catch (err) {
      NotificationService.error('Unable to Delete Category');
    }
  };

  return (
    <div className={`${open ? 'fixed top-0 right-0 left-0 bottom-0 main--background overflow-scroll' : ''}`} style={{ zIndex: 99999 }}>
      <nav className="flex-1 px-2 space-y-1">
        <div className="mb-5 pb-5 border-b divider">
          <div
            className={classNames(
              'flex items-start justify-between flex-shrink-0 px-2 pt-5 pb-2 text-base font-medium text__title break-all',
              primaryFont,
            )}
          >
            {/* <h2>{categoryData?.name} {$translatei18n('Settings')}</h2> */}
            <h2>Category Settings</h2>
              {open && (
                <button onClick={() => closeMenu()}>
                  <XIcon className="w-6 h-6" />
                </button>
              )}
          </div>
          <Link
            to={`/channel-category-settings/${channel_category_id}`}
            className={classNames(
              `/channel-category-settings/${channel_category_id}` === location?.pathname
                ? `btn__primary ${primaryFont}`
                : 'text__body',
              'group flex items-center px-2 py-2 text-sm rounded-md mb-2',
            )}
            style={{
              backgroundColor:
                `/channel-category-settings/${channel_category_id}` === location?.pathname ? accentColor : '',
            }}
            onClick={closeMenu}
          >
            <PencilAltIcon className={classNames('mr-3 flex-shrink-0 h-5')} aria-hidden="true" />
            Edit Category
          </Link>
        </div>
        <button
          className="group flex items-center px-2 py-2 text-sm rounded-md text__danger w-full"
          onClick={() => setOpenConfirmationModal(true)}
        >
          <TrashIcon className="mr-3 flex-shrink-0 h-5" aria-hidden="true" />
          Delete Category
        </button>
      </nav>

      <Transition show={openConfirmationModal} as={Fragment}>
        <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" onClose={setOpenConfirmationModal}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center md:block md:p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={setOpenConfirmationModal} />
            </TransitionChild>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden md:inline-block md:align-middle md:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
              enterTo="opacity-100 translate-y-0 md:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 md:scale-100"
              leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            >
              <div className="inline-block align-bottom card rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all md:my-8 md:align-middle md:max-w-lg md:w-full md:p-6">
                <div className="md:flex md:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 md:mx-0 md:h-10 md:w-10">
                    <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center md:mt-0 md:ml-4 md:text-left">
                    <DialogTitle as="h3" className="text-lg leading-6 font-medium text__title">
                      Delete Category
                    </DialogTitle>
                    <div className="mt-2">
                      <p className="text-sm text__description">
                        Are you sure you want to delete this channel category? All of your data will be permanently
                        removed from our servers forever. This action cannot be undone.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 md:mt-4 md:flex md:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 btn__danger text-base font-medium focus:outline-none focus:ring-0 focus:ring-offset-0 md:ml-3 md:w-auto md:text-sm"
                    onClick={handleDeleteCategory}
                  >
                    Delete Category
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md btn__light shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-0 focus:ring-offset-0 md:mt-0 md:w-auto md:text-sm"
                    onClick={() => setOpenConfirmationModal(false)}
                  >
                    {$translatei18n('Cancel')}
                  </button>
                </div>
              </div>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
