import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SUBSCRIPTION_AMPLITUDE_EVENT_TITLE } from '../../constants';
import { analyticsDefaults } from '../../constants/utils';
import { getActiveCommunityId } from '../../selectors/CommunitySelectors';
import AnalyticsService from '../../services/AnalyticsService';
import { useSelector } from 'react-redux';

function ZohoChatbot() {
  const widgetcode = process.env.REACT_APP_ZOHO_WIDGET_CODE;
  const zohoChatUrl = process.env.REACT_APP_ZOHO_CHAT_URL;
  const location = useLocation();
  const pathName = location?.pathname;
  const communityId = useSelector(getActiveCommunityId);
  const firstName = useSelector((state) => state?.user?.first_name);
  const lastName = useSelector((state) => state?.user?.last_name);
  const email = useSelector((state) => state?.user?.email);

  const handleClick = () => {
    if (firstName || lastName || email || communityId) {
      $zoho.salesiq.visitor.info({ firstName: firstName, email: email, communityId: communityId });
    }
    if (firstName) {
      $zoho.salesiq.visitor?.name(firstName);
    }
    if (email) {
      $zoho.salesiq.visitor?.email(email);
    }
    AnalyticsService.trackAmplitudeEvent(SUBSCRIPTION_AMPLITUDE_EVENT_TITLE.CHATBOT_INSIDE_COMMUNITY_CLICKED, {
      ...analyticsDefaults(true),
      community_id: communityId,
    });
  };

  const addClickListener = () => {
    const checkDom = setInterval(() => {
      const elem = document.querySelector('[data-id="zsalesiq"]');
      if (elem) {
        clearInterval(checkDom);
        elem.addEventListener('click', handleClick);
        elem.classList.add('zoho');
        elem.classList.add('z');
      }
    }, 1000);
  };

  useEffect(() => {
    if (pathName.includes('community-settings') || pathName === '/') {
      $zoho.salesiq = $zoho.salesiq || { widgetcode: widgetcode, values: {}, ready: function () {} };
      var d = document;
      var s = d.createElement('script');
      s.type = 'text/javascript';
      s.id = 'zsiqscript';
      s.defer = true;
      s.src = zohoChatUrl;
      s.onload = () => {
        addClickListener();
      };
      var t = d.getElementsByTagName('script')[0];
      t.parentNode.insertBefore(s, t);

      document.head.appendChild(s);
      if (typeof $zoho !== 'undefined') {
        $zoho.salesiq.floatbutton?.visible('show');
      }

      return () => {
        document.head.removeChild(s);
        if (typeof $zoho !== 'undefined') {
          const elem = document.querySelector('[data-id="zsalesiq"]');
          $zoho?.salesiq?.floatbutton?.visible('hide');
          $zoho?.salesiq?.floatwindow?.visible('hide');
          if (elem) {
            elem.removeEventListener('click', handleClick);
          }
        }
      };
    }
  }, [pathName]);

  return null;
}

export default ZohoChatbot;
