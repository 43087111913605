import { XIcon } from '@heroicons/react/outline';
import { COMMUNITY_ROLE_COLOR, PLACE_TYPE } from '../../../constants';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

function UserRolesDropdown({
  userRoles,
  rolesArray = [],
  deleteUserRole,
  managePermission = true,
  position = '',
  placeType = '',
}) {
  const restRoles = _.cloneDeep(userRoles);
  restRoles.shift();

  const renderView = () => {
    return restRoles.map((role, index) => {
      const roleObj = !managePermission
        ? { ...role, role: role?.title || role?.label || role?.role, id: `${role}_${index}` }
        : rolesArray.length > 0
        ? rolesArray.find((_role) => _role.id === role)
        : { ...role, role: role?.title || role?.label || role?.role } || {};
      return (
        <div
          key={roleObj?.id}
          className={`inline-flex items-center text-xs rounded-3xl py-1 px-3 capitalize text-gray-800`}
          data-tooltip-content={roleObj?.role}
          data-tooltip-id={roleObj?.role?.split(`'`).join()}
          style={{
            backgroundColor: roleObj?.color ?? COMMUNITY_ROLE_COLOR[index % userRoles.length],
          }}
        >
          <span className={`inline-block ${roleObj?.role?.length > 10 ? 'truncate max-w-[60px]' : ''}`}>
            {roleObj?.role}
          </span>
          {managePermission && roleObj?.role?.toLowerCase() !== 'owner' && (
            <XIcon
              className="ml-1 inline h-4 w-4 rounded-full cursor-pointer hover:bg-gray-300"
              onClick={() => {
                if (deleteUserRole && typeof deleteUserRole === 'function') {
                  deleteUserRole(placeType === PLACE_TYPE.MANAGE_ROLE ? roleObj : roleObj?.id, userRoles.length === 1);
                }
              }}
            />
          )}
          {roleObj?.role?.length > 10 && (
            <ReactTooltip
              id={roleObj?.role?.split(`'`).join()}
              effect="solid"
              style={{ whiteSpace: 'break-spaces', maxWidth: '200px', overflowWrap: 'break-word' }}
            />
          )}
        </div>
      );
    });
  };

  return (
    <>
      {userRoles.length > 1 && (
        <>
          <Popover as="div" className={`${!['left'].includes(position) ? 'relative' : ''} inline-block text-left`}>
            <PopoverButton className="inline-flex items-center text-xs py-1 px-3 rounded-3xl text-gray-800 bg-gray-200">
              +{restRoles.length}
            </PopoverButton>

            <PopoverPanel
              className={`absolute z-10 ${
                position === 'left' ? 'left-0 translate-x-0' : 'right-1/2 translate-x-1/2'
              } lg:translate-x-0 lg:right-0 mt-2 w-64 flex flex-wrap gap-2 px-3 py-2 rounded-md shadow-xl menu__options__container border focus:outline-none`}
            >
              {renderView()}
            </PopoverPanel>
          </Popover>
        </>
      )}
    </>
  );
}

export default UserRolesDropdown;
