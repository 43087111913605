import {
  UPDATE_CREATE_EVENT_MODAL,
  SET_EVENT_TEMP_DATA,
  SET_EVENT_SOURCE,
  SET_EVENT_ID,
  RESET_CREATE_EVENT_STATE,
  SET_ZOOM_CALENDAR_EVENT,
  SET_CALENDAR_CHANNELS,
} from '../actions/actionTypes';

const initialState = {
  eventSource: '',
  enableCreateEventModal: false,
  eventTempData: null,
  eventId: null,
  currentZoomCalendarEvent: null,
  calendarChannels: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CREATE_EVENT_MODAL:
      return {
        ...state,
        enableCreateEventModal: action?.payload,
      };

    case SET_EVENT_TEMP_DATA:
      return {
        ...state,
        eventTempData: action?.payload,
      };

    case SET_EVENT_SOURCE:
      return {
        ...state,
        eventSource: action?.payload?.eventSource,
      };

    case SET_EVENT_ID:
      return {
        ...state,
        eventId: action?.payload?.eventId,
      };

    case RESET_CREATE_EVENT_STATE:
      return {
        ...state,
        ...initialState,
      };

    case SET_ZOOM_CALENDAR_EVENT:
      return {
        ...state,
        currentZoomCalendarEvent: action?.payload,
      };

    case SET_CALENDAR_CHANNELS:
      return {
        ...state,
        calendarChannels: action?.payload,
      };

    default:
      return state;
  }
};

export default reducer;
