import { BOOK_DEMO_CLICKED, CHARGEBEE_PAYMENT_REQUEST, CLEAR_LOCAL_STORAGE, CURRENT_SUBSCRIPTION_REQUEST, PLAN_DETAILS_REQUEST, PLAN_PAYMENT_STATUS_REQUEST, RESET_PAYMENT_URL, SET_ACTIVE_LOCK_NAVIGATION, SET_ACTIVE_LOCK_NAVIGATION_IDENTIFIER, SET_ACTIVE_PRODUCT_PLAN, SET_COMPONENT_LOCK_STATUS, SET_CURRENT_ACTIVE_FEATURE_IDENTIFIER, SET_CURRENT_ACTIVE_NAVIGATION, SET_MODAL_CLOSE, SET_MODAL_OPEN, TOGGLE_MODAL } from './actionTypes';


export const fetchPlans = (payload) => ({
  type: PLAN_DETAILS_REQUEST,
  payload,
});

export const makePayment = (payload) => ({
  type: CHARGEBEE_PAYMENT_REQUEST,
  payload
})

export const setActiveProductPlan = (payload) => ({
  type: SET_ACTIVE_PRODUCT_PLAN,
  payload
})

export const resetPaymentUrl = () => ({
  type: RESET_PAYMENT_URL
})

export const getPlanPaymentStatus = (payload) => ({
  type: PLAN_PAYMENT_STATUS_REQUEST,
  payload
})


export const getcurrentPlanRequest = (payload) => ({
  type: CURRENT_SUBSCRIPTION_REQUEST,
  payload
})

export const resetLocalStorage = () => ({
  type: CLEAR_LOCAL_STORAGE
})

export const setModalClose = () => ({
  type: SET_MODAL_CLOSE
})

export const setModalOpen = () => ({
  type: SET_MODAL_OPEN
})

export const saveComponentLockStatus = (payload) => ({
  type: SET_COMPONENT_LOCK_STATUS,
  payload
})

export const setCurrentActiveNavigation = (payload) => ({
  type: SET_CURRENT_ACTIVE_NAVIGATION,
  payload
})

export const setCurrentActiveFeatureIdentifier = (payload) => ({
  type: SET_CURRENT_ACTIVE_FEATURE_IDENTIFIER,
  payload
})

export const setActiveLockNavigationIdentifier = (payload) => ({
  type: SET_ACTIVE_LOCK_NAVIGATION_IDENTIFIER,
  payload
})

export const setActiveLockNavigation = (payload) => ({
  type: SET_ACTIVE_LOCK_NAVIGATION,
  payload
})

export const setBookADemoClicked = (payload) => ({
  type: BOOK_DEMO_CLICKED,
  payload
})
