/* eslint-disable no-underscore-dangle */
import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroll-component';
import Loader, { ThreeDots } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { Input } from 'react-chat-elements';
import 'react-chat-elements/dist/main.css';
import {
  ChevronLeftIcon,
  DotsVerticalIcon,
  ClockIcon,
  InformationCircleIcon,
  PaperAirplaneIcon,
} from '@heroicons/react/outline';
import { Fragment, useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';
import { Initial } from 'react-initial';
import UUID from 'uuid-js';
import { XIcon } from '@heroicons/react/solid';
import {
  getActiveCommunity,
  getActiveCommunityAccentColor,
  getActiveCommunityFontsClass,
  getOfflinePrivateMessages,
} from '../../selectors/CommunitySelectors';
import { getActivePrivateRoom } from '../../selectors/PrivateChatSelectors';
import {
  appendMessagePrivateChat,
  closePrivateChatChannelConnection,
  generatePrivateMessageFromResponse,
  initializePrivateChatChannel,
  setEarlierMessagesPrivateChat,
  setLastMessagePrivateChat,
  setLoadMorePrivateChat,
  updateChatMessagesPrivateChat,
  listPrivateRoomsForInbox,
  setPrivateInboxType,
  requestJoinTextRoom,
  deletedPrivateChatMessage,
  deleteMessagePrivateChat,
  updatePrivateChatUnreadStatus,
  updatePrivateChatState,
  setActivePrivateRoom,
  setPrivateRooms,
  privateChatChannelConnectionSuccess,
  renderPrivateChatChannelMessage,
  currentActiveRoomDeleted,
  editMessagePrivateChat,
} from '../../store/actions/privateChatActions';
import { getUserIdFromAuthCookie } from '../../constants/authUtils';
import { imageFetch } from '../../services/preSignedAws';
import { isGiphyUrl } from '../../helpers';
import NotificationService from '../../services/notificationService';
import { getCurrentLoggedInUser } from '../../selectors/ProfileSelectors';
import { INBOX_TYPES } from '../../constants';
import { history, parseAxiosErrorMessage, prepareUserImageName, randomString, useQuery } from '../../constants/utils';
import CommonNameAvatar, {
  COMMON_NAME_AVATAR_SIZE,
  COMMON_NAME_AVATAR_TYPE,
} from '../../components/shared/tailwind/NameAvatar';
import SendImage from '../channel/textchannel/Picker/SendImage';
import { updateOfflineChatState } from '../../store/actions/offlineChatAction';
import usePrevious from '../../hooks/usePrevious';
import { ReactComponent as DoubleTick } from '../../assets/images/icons/charm_tick-double.svg';
import { internetState } from '../../store/models/constants';
import { fetchUserProfile } from '../../services/UserService';
import { createPrivateTextRoomService } from '../../services/privateChatService';
import { setNewChatRequest } from '../../store/actions/chatActions';
import { store } from '../../store/configureStore';
import { initialiseSocket } from '../../store/actions/textActions';
import Picker from '../channel/textchannel/Picker';
import MentionsParser from '../../helpers/MentionsParser';
import { getFullName } from '../../utils/Utils';
import LightBoxWrapper from '../../components/shared/light-box/LightBoxWrapper';

const TIMEOUT = 10000;

function RequestActionButton({ accent_color, textRoom, requestsCount }) {
  const dispatch = useDispatch();

  const handleSubmit = async (accept) => {
    try {
      dispatch(requestJoinTextRoom({ accept, textRoom, requestsCount }));
    } catch (error) {
      NotificationService.error(parseAxiosErrorMessage(error));
    }
  };

  return (
    <div className="bg-gray-50 flex flex-col items-center justify-center p-4 card__footer">
      <p className="text-gray-900 text-16 text__header">
        <span className="font-bold">{textRoom?.user?.username}</span> wants to send you a message
      </p>
      <p className="text-gray-500 text-14 mt-2 text__description">
        <span className="font-bold text-gray-900 text__title">{textRoom?.user?.followers_count}</span>{' '}
        {$translatei18n('Followers')}
        <span className="font-bold text-gray-900 ltr:ml-2 rtl:mr-2 text__title">
          {textRoom?.user?.following_count}
        </span>{' '}
        {$translatei18n('Following')}
      </p>
      <p className="text-gray-900 text-16 mt-2 text-center text__title">
        Do you want to allow them to send you messages from now on? They’ll only know that you’ve seen their request if
        you accept it.
      </p>
      <div className="flex mt-2">
        <button
          type="button"
          className={classNames(
            'mt-2 ltr:ml-4 rtl:mr-4 md:mt-0 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium focus:outline-none focus:ring-0 focus:ring-offset-0 btn__light',
          )}
          style={{ backgroundColor: accent_color }}
          onClick={() => handleSubmit(true)}
        >
          Accept
        </button>
        {/* <button
        type="button"
        className={classNames(
          'mt-2 ltr:ml-4 rtl:mr-4 md:mt-0 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium focus:outline-none focus:ring-0 focus:ring-offset-0 btn__primary',
        )}
        style={{ backgroundColor: accent_color }}
        // onClick={handleSubmit}
      >
        Block
      </button> */}
        <button
          type="button"
          className={classNames(
            'text-red-500 mt-2 ltr:ml-4 rtl:mr-4 md:mt-0 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium border border-gray-300 hover:border-gray-400 btn__danger',
          )}
          onClick={() => handleSubmit(false)}
        >
          Delete
        </button>
      </div>
    </div>
  );
}

function UserImage({ image, imgClasses, name, radius, height, width, onClick, online }) {
  const [isImageLoadingError, setIsImageLoadingError] = useState(false);

  useEffect(() => {
    setIsImageLoadingError(false);
  }, [image]);

  return image && !isImageLoadingError ? (
    <div className="relative">
      <img
        className={imgClasses || ''}
        src={imageFetch(image)}
        onError={() => setIsImageLoadingError(true)}
        alt="profile"
        onClick={onClick}
      />
      {Boolean(online) && (
        <div>
          <div className="dot h-2 w-2 bg-green-500 absolute rounded-full right-0.5 bottom-0 sm:hidden xs:hidden" />
          <p className="text-xs text__body absolute -right-10 -bottom-1 sm:-bottom-5 xs:-bottom-5">online</p>
        </div>
      )}
    </div>
  ) : (
    <div className="relative">
      <Initial
        radius={radius || 30}
        name={name}
        charCount={2}
        height={height || 32}
        width={width || 32}
        fontSize={14}
        useWords
        className="rounded-full cursor-pointer font-Geomanist h-8 w-8"
        color="rgb(107 114 128)"
        onClick={onClick}
      />
      {Boolean(online) && (
        <div>
          <div className="dot h-2 w-2 bg-green-500 absolute rounded-full right-0.5 bottom-0 sm:hidden xs:hidden" />
          <p className="text-xs text__body absolute -right-10 -bottom-1 sm:-bottom-5 xs:-bottom-5">online</p>
        </div>
      )}
    </div>
  );
}

export default function PrivateChatSection({ onInfoClicked }) {
  const privateChatReducer = useSelector((state) => state?.privateChat);
  const { primaryFont } = useSelector(getActiveCommunityFontsClass);
  const activePrivateRoom = useSelector(getActivePrivateRoom);
  const privateRooms = useSelector((state) => state?.privateChat?.privateTextRooms);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 768px)' });
  const activeCommunityAccentColor = useSelector(getActiveCommunityAccentColor);
  const scroll = useRef(null);
  const dispatch = useDispatch();
  const query = useQuery();
  const currentUser = useSelector(getCurrentLoggedInUser);
  const input = useRef(null);
  const [replyToMessage, setReplyToMessage] = useState(null);
  const [editMessage, setEditMessage] = useState(null);
  const [inputValue, setInputValue] = useState(null);
  const [imageVal, setImageVal] = useState(false);
  const [focus, setFocus] = useState(false);
  const activeCommunity = useSelector(getActiveCommunity);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [isImageLoadingError, setIsImageLoadingError] = useState(false);
  const inboxType = useSelector((state) => state?.privateChat?.inboxType);
  const requestsCount = useSelector((state) => state?.privateChat?.requestsCount);
  const [openImageZoomModal, setopenImageZoomModal] = useState(false);
  const [openImage, setopenImage] = useState('');
  const offlineMessages = useSelector(getOfflinePrivateMessages);
  const [arrSentMessages, setSentMessage] = useState([]);
  const socketConnectionStatus = useSelector((state) => state?.socket?.socketConnectionState);
  const newChatRequest = useSelector((state) => state?.chat?.newChatRequest);
  const isOnline = socketConnectionStatus === internetState.CONNECTED;
  const prevOnline = usePrevious({ isOnline });
  const [isSendingMsg, setSendingMsg] = useState(false);
  const prevConnected = usePrevious({ isConnected: privateChatReducer.isConnected });
  const [fetchedUserdata, setFetchedUserdata] = useState({});

  useEffect(() => {
    dispatch(closePrivateChatChannelConnection());
    setTimeout(() => {
      dispatch(initializePrivateChatChannel(activePrivateRoom?.id));
    }, 500);
    return () => {
      dispatch(closePrivateChatChannelConnection());
    };
  }, [activePrivateRoom?.id]);

  useEffect(() => {
    if (newChatRequest) {
      getUserDetails();
    }
  }, [newChatRequest]);

  const getUserDetails = async () => {
    const userId = query.get('user_id');
    const { data } = await fetchUserProfile(userId, activeCommunity?.id);
    setFetchedUserdata(data);
  };

  useEffect(() => {
    if (prevConnected === undefined) {
      if (privateChatReducer.isConnected === true) {
        checkOfflineMessages();
      }
    } else if (prevConnected.isConnected !== privateChatReducer.isConnected) {
      if (privateChatReducer.isConnected === true) {
        checkOfflineMessages();
      }
    }
  }, [privateChatReducer.isConnected]);

  const sendLocalMessages = (arr, index) => {
    if (arr.length > 0) {
      if (arr[index]?.parent_message !== null && arr[index]?.parent_message !== undefined) {
        appendMessage(arr[index], true, true);
        onSendReply(arr[index], arr[index]?.parent_message?._id);
      } else {
        appendMessage(arr[index], false, true);
        onSend(arr[index]);
      }
    }

    if (arr.length - 1 === index) {
      const cloneOfflineMessage = _.cloneDeep(offlineMessages).filter(
        (element) => parseInt(element.channel_id) !== parseInt(activePrivateRoom?.id),
      );
      if (cloneOfflineMessage.length > 0) {
        dispatch(updateOfflineChatState({ privateMessages: cloneOfflineMessage }));
      } else {
        dispatch(updateOfflineChatState({ privateMessages: [] }));
      }
      setSendingMsg(false);
    } else {
      sendLocalMessages(arr, index + 1);
    }
  };
  useEffect(() => {
    if (prevOnline === undefined || prevOnline !== isOnline) {
      checkOfflineMessages();
    }
  }, [isOnline]);

  const checkOfflineMessages = () => {
    if (offlineMessages?.length > 0 && isOnline === true) {
      const cloneOfflineMessage = _.cloneDeep(offlineMessages).filter(
        (element) => parseInt(element.channel_id) === parseInt(activePrivateRoom?.id),
      );
      if (cloneOfflineMessage.length > 0) {
        setSendingMsg(true);
        sendLocalMessages(cloneOfflineMessage, 0);
      }
    }
  };

  useEffect(() => {
    if (arrSentMessages?.length > 0 && isSendingMsg === false) {
      const cloneMessage = _.cloneDeep(privateChatReducer.messages);
      const arrLocalMessage = _.cloneDeep(arrSentMessages);
      arrLocalMessage.forEach(async (element, index) => {
        const indexItem = cloneMessage.findIndex((msg) => msg.message_id === element.message_id);
        let updatedMessage = await generatePrivateMessageFromResponse(element);
        if (indexItem !== -1) {
          updatedMessage = { ...updatedMessage, isSent: true };
          cloneMessage[indexItem] = updatedMessage;
          dispatch(updatePrivateChatState({ messages: cloneMessage }));
          if (index > -1) {
            arrLocalMessage.splice(index, 1);
          }
        }
      });
      if (arrLocalMessage.length === 0) {
        setSentMessage([]);
      }
    }
  }, [arrSentMessages, isSendingMsg]);

  useEffect(() => {
    if (activePrivateRoom?.unread && privateChatReducer?.chatChannel) {
      leaveChannelForMarkReadMessage();
    }
  }, [privateChatReducer?.chatChannel]);

  const renderEarlierMessages = (messages, metadata) => {
    if (metadata && metadata.after) {
      dispatch(setLastMessagePrivateChat(metadata.after));
    }
    // const user = currentUser?.attributes;
    const userID = getUserIdFromAuthCookie();
    const newMessages = _.map(messages, (value) => ({
      _id: value?.id,
      // position: value?.user.id == user.id ? 'right' : '',
      isSent: value.isSent !== undefined && value.isSent !== null ? value.isSent : true,
      position: value?.user?.id === Number(userID) ? 'right' : '',
      text: !isGiphyUrl(value?.body || '') ? value?.body : '',
      image_url: value.image_url,
      avatar: value?.user?.image ? value?.user?.image : undefined,
      'is_cool_human?': value?.user['is_cool_human?'],
      cool_human_badge_url: value?.user?.cool_human_badge_url,
      type: isGiphyUrl(value?.body || '') ? 'photo' : 'text',
      image: isGiphyUrl(value?.body || '') ? value?.body : '',
      createdAt: value?.inserted_at,

      user: {
        _id: value?.user.id,
        avatar: value?.user?.image ? value?.user?.image : undefined,
        'is_cool_human?': value?.user['is_cool_human?'],
        cool_human_badge_url: value?.user?.cool_human_badge_url,
        first_name: value?.user?.first_name,
        last_name: value?.user?.last_name,
        username: value?.user?.username,
      },
    }));

    if (newMessages?.length < 15) {
      dispatch(setLoadMorePrivateChat(false));
    } else {
      dispatch(setLoadMorePrivateChat(true));
    }

    dispatch(setEarlierMessagesPrivateChat(newMessages));
  };

  const loadEarlier = () => {
    const { chatChannel, lastMessageId } = privateChatReducer;

    chatChannel
      .push('message:load_more', {
        next_page_id: lastMessageId,
      })
      .receive('ok', (response) => {
        renderEarlierMessages(response.messages.data, response.messages.metadata);
      });
  };

  const getTime = (date) => {
    const stillUtc = moment.utc(date).toDate();
    const local = moment(stillUtc).local().format('hh:mm a');
    return local;
  };
  const appendMessage = (message, isReply, checkMessage) => {
    if (checkMessage === false) {
      const updatedMessage = generatePrivateMessageFromResponse(message);
      dispatch(appendMessagePrivateChat([updatedMessage]));
      if (isReply === false) {
        dispatch(updateChatMessagesPrivateChat(1, 10));
      }
    } else {
      const cloneMessage = _.cloneDeep(privateChatReducer.messages);
      const indexItem = cloneMessage.findIndex((msg) => msg.message_id === message.message_id);
      if (indexItem === -1) {
        const updatedMessage = generatePrivateMessageFromResponse(message);
        dispatch(appendMessagePrivateChat([updatedMessage]));
        if (isReply === false) {
          dispatch(updateChatMessagesPrivateChat(1, 10));
        }
      }
    }
  };
  const onSendReply = async (message, parentMessageId) => {
    if (isOnline === false) {
      const cloneOfflineMessage = _.cloneDeep(offlineMessages);
      cloneOfflineMessage.push(message);
      dispatch(updateOfflineChatState({ privateMessages: cloneOfflineMessage }));
      setReplyToMessage(null);
    } else {
      const newMessage = message;
      privateChatReducer.chatChannel
        .push('message:reply', {
          message: newMessage.text,
          image_url: newMessage.image_url,
          message_id: newMessage.message_id,
          parent_message_id: parentMessageId,
        })
        .receive('ok', (response) => {
          input.current.value = '';
          setReplyToMessage(null);
          setSentMessage((oldMessages) => [...oldMessages, response]);
          // const messages = generatePrivateMessageFromResponse(response);
          // dispatch(appendMessagePrivateChat([messages]));
        })
        .receive('error', (reasons) => {
          // console.log({ reasons });
        });
    }
  };
  const onSendEdit = async (message, editMessageId) => {
    const newMessage = message;
    privateChatReducer.chatChannel
      .push('message:edit', {
        message: newMessage.text,
        image_url: newMessage.image_url && imageVal === false ? newMessage.image_url : null,
        message_id: editMessageId,
      })
      .receive('ok', (response) => {
        input.current.value = '';
        setEditMessage(null);
        setImageVal(false);
        let updatedMessage = generatePrivateMessageFromResponse(response);
        dispatch(editMessagePrivateChat(updatedMessage));
        dispatch(updateChatMessagesPrivateChat(1, 10));
      })
      .receive('error', (reasons) => {
        console.log({ reasons });
      });
  };

  const onSend = async (messages) => {
    if (newChatRequest && !activePrivateRoom?.id) {
      await initializeChannelConnection(messages);
    }
    if (isOnline === false) {
      const cloneOfflineMessage = _.cloneDeep(offlineMessages);
      cloneOfflineMessage.push(messages);
      dispatch(updateOfflineChatState({ privateMessages: cloneOfflineMessage }));
    } else {
      const newMessage = messages;

      privateChatReducer.chatChannel
        .push(
          'message:add',
          { message: newMessage.text, message_id: newMessage.message_id, image_url: newMessage.image_url },
          TIMEOUT,
        )
        .receive('ok', (response) => {
          input.current.value = '';
          // input.current.input.style = 'margin-right: 10px; background: rgba(8, 8, 8, 0.05); border-radius: 4px; height: 40px;';
          setSentMessage((oldMessages) => [...oldMessages, response]);

          // const updatedMessage = generatePrivateMessageFromResponse(response);
          // AnalyticsService.logMixpanelEvent('Chat Message Sent', {
          //   'Community Id': activeCommunity?.id,
          //   'Community Name': activeCommunity?.name,
          //   'Channel Id': activeChannelId,
          //   'Channel Name': activeChannelName,
          //   'Message Id': selectedMessage?._id,
          // });
          // dispatch(appendMessagePrivateChat([updatedMessage]));
          // dispatch(updateChatMessagesPrivateChat(1, 10));
        })
        .receive('error', (reasons) => {
          // console.error(reasons);
        })
        .receive('timeout', (e) => {
          // console.error('timeout', e);
        });
    }
  };

  const initializeChannelConnection = async (messages) => {
    try {
      const response = await createPrivateTextRoomService({
        user_id: query.get('user_id'),
      });
      dispatch(setActivePrivateRoom(response?.data?.private_text_room?.id));
      const privateChatItem = {
        id: response?.data?.private_text_room?.id,
        community_id: activeCommunity?.id,
        unread: false,
        user: fetchedUserdata?.user,
        last_message_at: '2022-12-08T13:09:07',
      };
      dispatch(setPrivateRooms(privateChatItem));
      await initializeConnection(response?.data?.private_text_room?.id, messages);
      dispatch(setNewChatRequest(false));
      return;
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
      history.push('/inbox');
    }
  };

  const initializeConnection = async (roomId, messages) => {
    try {
      // console.log('initializeConnection 1');
      const { auth, privateChat } = store.getState();
      let isProcessedError = false;
      // console.log('initializeConnection 2');
      if (roomId === privateChat?.roomId) {
        return;
      }
      // console.log('initializeConnection 3');
      if (!roomId) {
        throw new Error('ActiveroomId not present');
      }
      // console.log('initializeConnection 4');
      dispatch(updatePrivateChatState({ channelLoading: true }));
      dispatch(closePrivateChatChannelConnection());
      // console.log('initializeConnection 5');
      let socketConn = store.getState().socket.socket;
      // console.log('initializeConnection 6');
      if (!socketConn) {
        const { getState } = store;
        // console.log('initializeConnection 7');
        await initialiseSocket({ auth, dispatch, getState });
        // console.log('initializeConnection 8');
        socketConn = store.getState().socket.socket;
        // console.log('initializeConnection 9');
      }
      // console.log('initializeConnection 10');
      const chatChannel = socketConn.channel(`private_text:${roomId}`, {});

      chatChannel
        .join()
        .receive('ok', async (data) => {
          dispatch(renderPrivateChatChannelMessage(data.messages.data, data.messages.metadata));
          dispatch(
            privateChatChannelConnectionSuccess(
              chatChannel,
              data,
              // getPrivateChatChannelConfigs(data.channel_permissions),
              // generatePrivateMessageFromResponse(data.pinned_message),
              // data.notifs_muted,
            ),
          );
        })
        .receive('error', (error) => {
          if (error.code === 404 && isProcessedError === false) {
            isProcessedError = true;
            dispatch(currentActiveRoomDeleted());
            dispatch(closePrivateChatChannelConnection());
            dispatch(updatePrivateChatState({ redirect: true }));
          }
        })
        .receive('timeout', () => {
          NotificationService.error('Network Issue!');
          dispatch(updatePrivateChatState({ redirect: true }));
        });

      chatChannel
        .push(
          'message:add',
          { message: messages.text, message_id: messages.message_id, image_url: messages.image_url },
          TIMEOUT,
        )
        .receive('ok', (response) => {
          input.current.value = '';
          // input.current.input.style = 'margin-right: 10px; background: rgba(8, 8, 8, 0.05); border-radius: 4px; height: 40px;';
          setSentMessage((oldMessages) => [...oldMessages, response]);

          // const updatedMessage = generatePrivateMessageFromResponse(response);
          // AnalyticsService.logMixpanelEvent('Chat Message Sent', {
          //   'Community Id': activeCommunity?.id,
          //   'Community Name': activeCommunity?.name,
          //   'Channel Id': activeChannelId,
          //   'Channel Name': activeChannelName,
          //   'Message Id': selectedMessage?._id,
          // });
          // dispatch(appendMessagePrivateChat([updatedMessage]));
          // dispatch(updateChatMessagesPrivateChat(1, 10));
        })
        .receive('error', (reasons) => {
          console.error(reasons);
        })
        .receive('timeout', (e) => {
          console.error('timeout', e);
        });
    } catch (error) {
      // console.log({ error });
      captureException(error, LOGGER_JAVASCRIPT);
    }
  };

  const onSendMessage = async () => {
    const { name, is_cool_human, first_name, last_name } = currentUser;
    const userUUIDs = Number(currentUser.id);
    const msg = input.current.value;
    if (!is_cool_human && isGiphyUrl(msg)) {
      NotificationService.error('You need to have Cool Human access');
      return;
    }
    if (msg?.trim().length === 0) {
      return;
    }
    const userName = name;
    const msgs = {
      text: msg,
      image_url: editMessage ? editMessage.image_url : null,
      body: msg,
      channel_id: parseInt(activePrivateRoom?.id),
      user: {
        id: userUUIDs,
        name: userName,
        first_name,
        last_name,
      },
      _id: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      inserted_at: new Date().toISOString(),
      title: userName,
      message_id: `${randomString()}_${moment().valueOf()}`,
      position: 'right',
      isSent: false,
    };
    if (replyToMessage !== null && replyToMessage !== undefined) {
      msgs.parent_message = { ...replyToMessage, body: replyToMessage?.text, title: replyToMessage?.title };
    }
    if (replyToMessage) {
      appendMessage(msgs, true, false);
      onSendReply(msgs, replyToMessage._id);
    } else if (editMessage) {
      onSendEdit(msgs, editMessage._id);
    } else {
      appendMessage(msgs, false, false);
      onSend(msgs);
    }
    input.current.value = '';
  };
  const handleImageMessage = (img, text) => {
    const { name, userUUID, is_cool_human, first_name, last_name } = currentUser;
    const userName = name;
    const userUUIDs = Number(currentUser.id);
    const msgs = {
      text: text == undefined ? '' : text,
      image_url: img,
      body: text,
      channel_id: parseInt(activePrivateRoom?.id),
      user: {
        id: userUUIDs,
        name,
        first_name,
        last_name,
      },
      _id: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      inserted_at: new Date().toISOString(),
      title: userName,
      message_id: `${randomString()}_${moment().valueOf()}`,
      position: 'right',
      isSent: false,
    };

    if (replyToMessage !== null && replyToMessage !== undefined) {
      msgs.parent_message = { ...replyToMessage, body: replyToMessage?.text, title: replyToMessage?.title };
    }
    if (replyToMessage) {
      appendMessage(msgs, true, false);
      onSendReply(msgs, replyToMessage._id);
    } else if (editMessage) {
      onSendEdit(msgs, editMessage._id);
    } else {
      appendMessage(msgs, false, false);
      onSend(msgs);
    }
  };

  const renderChatHeader = (data, index) => {
    const { messages } = privateChatReducer;
    const msgDate = moment.utc(data.createdAt).startOf('date');
    const stillUtc = moment.utc(data.createdAt).toDate();
    const local = moment(stillUtc).local().format('MMM DD, YYYY');
    if (messages?.length - 1 > index) {
      if (!msgDate.isSame(moment.utc(messages[index + 1].createdAt).startOf('date'))) {
        return (
          <div
            style={{
              display: 'flex',
              flex: 0,
              marginTop: 10,
              marginBottom: 10,
              justifyContent: 'center',
            }}
          >
            <div className="relative grid grid-cols-1 p-2 bg-gray-100 text-xs font-semibold rounded-xl chat__header__date">
              {local}
            </div>
          </div>
        );
      }
    } else {
      return (
        <div
          style={{
            display: 'flex',
            flex: 0,
            marginTop: 10,
            marginBottom: 10,
            justifyContent: 'center',
          }}
        >
          <div className="relative grid grid-cols-1 p-2 bg-gray-100 text-xs font-semibold rounded-xl chat__header__date">
            {local}
          </div>
        </div>
      );
    }
    return null;
  };

  const handleChatCopy = (message) => {
    navigator.clipboard.writeText(message.text);
  };

  const handleChatReply = (message) => {
    setReplyToMessage(message);
  };

  const handleChatEdit = (message) => {
    input.current.value = '';
    setEditMessage(message);
    setInputValue(message.text);
    input.current.value = message.text;
    setTimeout(() => {
      input.current.focus();
    }, 500);
  };

  const deleteChatMessage = () => {
    if (!selectedMessage) return;
    privateChatReducer.chatChannel
      .push('message:delete', {
        message_id: selectedMessage._id,
      })
      .receive('ok', (response) => {
        const updatedMessage = generatePrivateMessageFromResponse(response);
        dispatch(deleteMessagePrivateChat(updatedMessage));
        dispatch(updateChatMessagesPrivateChat(1, 10));
        NotificationService.success('Message deleted successfully');
      })
      .receive('error', (error) => {
        // console.log({ error });

        NotificationService.error("Couldn't delete the selected message");
      });
  };

  const leaveChannelForMarkReadMessage = () => {
    privateChatReducer.chatChannel
      .push('leave')
      .receive('ok', () => {
        dispatch(updatePrivateChatUnreadStatus(activePrivateRoom?.id));
      })
      .receive('error', (error) => {
        // console.log({ error });

        NotificationService.error("Couldn't read the message");
      });
  };

  const handleChatDelete = () => {
    deleteChatMessage();
  };

  const handleClickOnInfo = () => {
    onInfoClicked();
  };

  const handleChat = (e) => {
    setInputValue(e.target.value);
  };

  const renderChatData = () =>
    privateChatReducer.messages.map((chat, i) => (
      <div key={`main-${i}`} className="exp-container px-3 mt-4 mb-0">
        <div className="description">
          {renderChatHeader(chat, i)}
          <div className={chat.position === 'right' ? 'flex flex-row-reverse items-start' : 'flex items-start'}>
            {chat.position === 'right' ? null : (
              <div className="img rounded-md overflow-hidden">
                <UserImage
                  name={prepareUserImageName(chat?.user?.first_name, chat?.user?.last_name)}
                  imgClasses="h-8 w-8 rounded-full cursor-pointer"
                  image={chat?.avatar}
                  size={COMMON_NAME_AVATAR_SIZE.XS}
                  radius={30}
                  height={32}
                  width={32}
                  onClick={() => {
                    history.push(`/profile?user_id=${chat?.user?._id}`);
                  }}
                />
              </div>
            )}

            <div
              className="ml-3 ltr:mr-3 rtl:ml-3 px-0 py-0 group relative"
              style={{ minWidth: '200px', maxWidth: '70%' }}
            >
              <div className="flex h-full absolute left-full">
                <div className="ltr:ml-auto rtl:mr-auto">
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <MenuButton
                        className="rounded-md items-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-0 focus:ring-offset-0 hidden xs:block group-hover:block"
                        onClickCapture={() => setSelectedMessage(chat)}
                      >
                        <span className="sr-only">Open options</span>
                        <DotsVerticalIcon className="h-3 w-3 chat__sender__name" aria-hidden="true" />
                      </MenuButton>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <MenuItems className="z-100 origin-top-left absolute right-0 top-2 mt-2 w-56 rounded-md shadow-lg border menu__options__container focus:outline-none">
                        <div className="py-1 z-100">
                          <MenuItem onClick={() => handleChatCopy(chat)}>
                            {({ active }) => (
                              <a
                                href="#"
                                className={classNames(
                                  active && 'menu__option--active',
                                  'block px-4 py-2 text-sm menu__option',
                                )}
                              >
                                Copy
                              </a>
                            )}
                          </MenuItem>
                          <MenuItem onClick={() => handleChatReply(chat)}>
                            {({ active }) => (
                              <a
                                href="#"
                                className={classNames(
                                  active && 'menu__option--active',
                                  'block px-4 py-2 text-sm menu__option',
                                )}
                              >
                                Reply
                              </a>
                            )}
                          </MenuItem>
                          {chat.position === 'right' && (
                            <MenuItem onClick={() => handleChatEdit(chat)}>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={classNames(
                                    active && 'menu__option--active',
                                    'block px-4 py-2 text-sm menu__option',
                                  )}
                                >
                                  Edit
                                </a>
                              )}
                            </MenuItem>
                          )}

                          {chat.position === 'right' && (
                            <MenuItem onClick={() => handleChatDelete(chat)}>
                              {({ active }) => (
                                <a
                                  href="#"
                                  className={classNames(
                                    active && 'menu__option--active',
                                    'block px-4 py-2 text-sm menu__option',
                                  )}
                                >
                                  Delete
                                </a>
                              )}
                            </MenuItem>
                          )}
                        </div>
                      </MenuItems>
                    </Transition>
                  </Menu>
                </div>
              </div>
              <div
                className={classNames(
                  'bg-gray-100 border-gray-100 border rounded-md px-2 py-2',
                  chat.position === 'right' ? 'chat__sent__container' : 'chat__received__container',
                )}
                style={{
                  backgroundColor: chat.position === 'right' && activeCommunity?.accent_color,
                }}
              >
                {chat.parent !== null && chat.parent !== undefined ? (
                  <div className="chat__reply__container rounded-md px-2 py-1 mb-2">
                    {chat.parent.image_url ? (
                      <div
                        className="max-w-md"
                        // onClick={() => {
                        //   setopenImageZoomModal(true);
                        //   setopenImage(imageFetch(chat.parent?.image_url));
                        // }}
                      >
                        <img
                          className="h-40 w-full cursor-pointer rounded-md mb-1"
                          src={imageFetch(chat.parent.image_url)}
                          alt=""
                        />
                      </div>
                    ) : null}
                    <p className="text-sm font-normal font-Geomanist break-words chat__reply__message">
                      {chat.parent?.type === 'photo' ? (
                        <img
                          className="h-60 w-60 rounded-md mb-1 cursor-pointer"
                          src={chat.parent?.image}
                          alt=""
                          onClick={() => {
                            setopenImageZoomModal(true);
                            setopenImage(chat.parent?.image);
                          }}
                        />
                      ) : (
                        <MentionsParser
                          renderMention={(mention) => {
                            const linkText = mention.match(
                              /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@%_\+.~#?&//=]*)|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
                            ); // GET Text of link - AKA clickable link
                            if (linkText && linkText[0]) {
                              if (linkText[0].startsWith('http://') || linkText[0].startsWith('https://')) {
                                return (
                                  <a
                                    target="_blank"
                                    href={linkText[0]}
                                    className="underline break-words"
                                    rel="noreferrer"
                                  >
                                    {linkText[0]}
                                  </a>
                                );
                              }
                              return (
                                <a
                                  target="_blank"
                                  href={`https://${linkText[0]}`}
                                  className="underline break-words"
                                  rel="noreferrer"
                                >
                                  {linkText[0]}
                                </a>
                              );
                            }
                            return null;
                          }}
                        >
                          {chat?.parent?.text || ''}
                        </MentionsParser>
                      )}
                    </p>
                  </div>
                ) : null}
                {chat.image_url ? (
                  <div
                    className="max-w-md"
                    onClick={() => {
                      setopenImageZoomModal(true);
                      setopenImage(imageFetch(chat.image_url));
                    }}
                  >
                    <img
                      className="h-60 w-auto rounded-md mb-1 cursor-pointer"
                      src={imageFetch(chat.image_url)}
                      alt=""
                    />
                  </div>
                ) : null}
                {chat.type === 'photo' ? (
                  <img
                    className="h-60 w-60 rounded-md mb-1 cursor-pointer"
                    src={chat.image}
                    alt=""
                    onClick={() => {
                      setopenImageZoomModal(true);
                      setopenImage(chat.image);
                    }}
                  />
                ) : (
                  <p
                    className={
                      chat.position === 'right'
                        ? 'text-sm font-Geomanist break-words chat__sent__message whitespace-pre-line'
                        : 'text-sm font-Geomanist break-words chat__received__message whitespace-pre-line'
                    }
                  >
                    <MentionsParser
                      renderMention={(mention) => {
                        const linkText = mention.match(
                          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@%_\+.~#?&//=]*)|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
                        ); // GET Text of link - AKA clickable link
                        if (linkText && linkText[0]) {
                          if (linkText[0].startsWith('http://') || linkText[0].startsWith('https://')) {
                            return (
                              <a target="_blank" href={linkText[0]} className="underline break-words" rel="noreferrer">
                                {linkText[0]}
                              </a>
                            );
                          }
                          return (
                            <a
                              target="_blank"
                              href={`https://${linkText[0]}`}
                              className="underline break-words"
                              rel="noreferrer"
                            >
                              {linkText[0]}
                            </a>
                          );
                        }
                        return null;
                      }}
                    >
                      {chat?.text || ''}
                    </MentionsParser>
                  </p>
                )}

                <div className="flex items-center w-full">
                  {chat.lastEditedAt ? (
                    <div className="flex flex-row items-center ltr:ml-auto rtl:ml-auto">
                      <small
                        className={
                          chat.position === 'right'
                            ? 'text-xs chat__sent__time font-Geomanist'
                            : 'text-xs chat__received__time font-Geomanist'
                        }
                      >
                        Edited
                      </small>
                      <small
                        className={
                          chat.position === 'right'
                            ? 'text-xs chat__sent__time font-Geomanist w-1 h-1 rounded bg-white ml-1'
                            : 'text-xs chat__received__time font-Geomanist w-1 h-1 rounded bg-white ml-1'
                        }
                      />
                    </div>
                  ) : null}
                  <small
                    className={
                      chat.position === 'right'
                        ? `text-xs chat__sent__time font-Geomanist  rtl:mr-auto ${
                            chat.lastEditedAt ? 'ltr:ml-1' : 'ltr:ml-auto'
                          }`
                        : `text-xs chat__received__time font-Geomanist rtl:mr-auto ${
                            chat.lastEditedAt ? 'ltr:ml-1' : 'ltr:ml-auto'
                          }`
                    }
                  >
                    {getTime(chat.createdAt)}
                  </small>
                  {chat.position === 'right' && (
                    <span className="text-xs chat__sent__time ltr:ml-2 rtl:mr-2">
                      {chat.isSent === true ? (
                        <DoubleTick className="chat__sent__time w-4 h-4 transform" />
                      ) : (
                        <ClockIcon className="chat__sent__time w-4 h-4 transform" />
                      )}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));
  const handleSelectedGif = (gifUrl) => {
    const { name, first_name, last_name } = currentUser;

    const userUUIDs = Number(currentUser.id);
    const userName = name;
    const msgs = {
      text: gifUrl,
      image_url: null,
      body: gifUrl,
      channel_id: parseInt(activePrivateRoom?.id),
      user: {
        id: userUUIDs,
        name,
        first_name,
        last_name,
      },
      _id: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      inserted_at: new Date().toISOString(),
      title: userName,
      message_id: `${randomString()}_${moment().valueOf()}`,
      position: 'right',
      isSent: false,
    };

    if (replyToMessage !== null && replyToMessage !== undefined) {
      msgs.parent_message = { ...replyToMessage, body: replyToMessage?.text, title: replyToMessage?.title };
    }

    if (replyToMessage) {
      appendMessage(msgs, true, false);
      onSendReply(msgs, replyToMessage._id);
    } else if (editMessage) {
      onSendEdit(msgs, editMessage._id);
    } else {
      appendMessage(msgs, false, false);
      onSend(msgs);
    }
  };

  const handleBack = () => {
    if (!isTabletOrMobile) {
      return;
    }

    dispatch(setActivePrivateRoom(null));
    dispatch(setNewChatRequest(false));
    history.replace('/inbox');
  };
  const handleClickClose = () => {
    setEditMessage(null);
    setInputValue('');
    setImageVal(false);
    input.current.value = '';
  };
  const handleRemoveImage = () => {
    setImageVal(true);
  };

  return (
    <>
      <div
        ref={scroll}
        id="scrollableDiv"
        className="relative grid grid-cols-1 overflow-auto"
        style={{
          maxHeight: 'calc(100vh - 115px)',
          height: 'auto',
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column-reverse',
        }}
      >
        <InfiniteScroll
          dataLength={privateChatReducer.messages.length}
          next={() => loadEarlier()}
          className="flex flex-col-reverse pb-32 pt-12"
          inverse //
          hasMore={privateChatReducer.canLoadMore}
          loader={
            <div
              style={{
                display: 'flex',
                flex: 1,
                marginTop: 10,
                marginBottom: 10,
                justifyContent: 'center',
              }}
            >
              <ThreeDots color="#00BFFF" height={30} width={30} />
            </div>
          }
          scrollableTarget="scrollableDiv"
        >
          {renderChatData()}
        </InfiniteScroll>
      </div>
      <div className="absolute inset-0 border-b h-16 inbox__chat__header">
        <div className="flex items-center px-5 pt-4 justify-between">
          <div
            className="flex items-center sm:cursor-pointer xs:cursor-pointer"
            onClick={handleBack}
            onKeyPress={handleBack}
            role="button"
            tabIndex="-1"
          >
            <ChevronLeftIcon className="h-6 w-6 md:hidden" />
            {(activePrivateRoom?.user?.image || fetchedUserdata?.user?.image) && (
              <UserImage
                name={getFullName(
                  activePrivateRoom?.user?.first_name || fetchedUserdata?.user?.first_name,
                  activePrivateRoom?.user?.last_name || fetchedUserdata?.user?.last_name,
                )}
                imgClasses="inline-block h-8 w-8 rounded-full sm:hidden xs:hidden"
                image={newChatRequest ? fetchedUserdata?.user?.image : activePrivateRoom?.user?.image}
                size={COMMON_NAME_AVATAR_SIZE.XS}
                radius={30}
                online={privateChatReducer?.dmUsers?.length >= 2}
              />
            )}
            <p className="ml-2 text-sm font-bold text-gray-700 group-hover:text-gray-900 text__title">
              {getFullName(
                activePrivateRoom?.user?.first_name || fetchedUserdata?.user?.first_name,
                activePrivateRoom?.user?.last_name || fetchedUserdata?.user?.last_name,
              )}
            </p>
          </div>
          <div className="">
            <button
              className="text-sm font-bold text-gray-700 group-hover:text-gray-900 text__title"
              type="button"
              onClick={handleClickOnInfo}
            >
              <InformationCircleIcon className="h-5 w-5" />
            </button>
          </div>
        </div>
      </div>
      <section>
        <div
          className={`${
            isTabletOrMobile ? 'fixed' : 'absolute'
          }  bottom-0 left-0 right-0 bg-white chat__footer__container shadow p-2`}
        >
          <div>
            <div className="flex flex-row px-1">
              {/* <XIcon className="cursor-pointer w-4 h-4" onClick={() => {}} /> */}
            </div>
          </div>
          {inboxType === INBOX_TYPES.INBOX ? (
            <>
              <div>
                {replyToMessage !== null && replyToMessage !== undefined && (
                  <div className="flex flex-row px-1">
                    <p className="text-sm font-Geomanist w-full">
                      Reply to {getFullName(activePrivateRoom?.user?.first_name, activePrivateRoom?.user?.last_name)}
                    </p>
                    <XIcon className="cursor-pointer w-4 h-4" onClick={() => setReplyToMessage(null)} />
                  </div>
                )}
              </div>
              <div>
                {editMessage !== null && editMessage !== undefined && (
                  <div className="flex flex-row px-1">
                    <div className="w-full">
                      <p className="text-sm font-Geomanist w-full">Editing Message </p>
                      {editMessage.image_url && imageVal === false ? (
                        <div
                          className="relative"
                          style={{
                            width: '180px',
                            height: '90px',
                          }}
                        >
                          <img
                            className="w-full rounded-xl"
                            src={imageFetch(editMessage.image_url)}
                            style={{
                              width: '180px',
                              height: '90px',
                            }}
                            alt="profile"
                          />
                          <div
                            className="flex align-items justify-center absolute right-0 top-0 h-6 w-6 rounded-2xl text-white"
                            style={{ backgroundColor: '#242424' }}
                          >
                            <XIcon className="cursor-pointer w-4 h-4" onClick={handleRemoveImage} />
                          </div>
                        </div>
                      ) : null}
                    </div>
                    <XIcon className="cursor-pointer w-4 h-4" onClick={handleClickClose} />
                  </div>
                )}
              </div>
              <Input
                inputStyle={{
                  marginRight: '10px',
                  borderRadius: '4px',
                  height: '42px',
                  boxShadow: 'none',
                  paddingTop: '5px',
                  paddingBottom: '5px',
                  fontSize: '14px',
                  borderColor: 'rgba(209, 213, 219, 1)',
                  borderStyle: 'solid',
                }}
                // style={{ outlineColor: activeCommunity?.accent_color }}
                className="chat__footer__container"
                placeholder={$translatei18n('WriteYourMessageHere')}
                defaultValue=""
                value={inputValue}
                referance={input}
                // autofocus={focus}
                maxlength={500}
                multiline
                autoHeight
                onChange={handleChat}
                onKeyPress={(e) => {
                  if (e.shiftKey && e.charCode === 13) {
                    return true;
                  }
                  if (e.charCode === 13) {
                    onSendMessage();
                    e.preventDefault();
                    setInputValue(e.target.value);
                    return false;
                  }
                }}
                leftButtons={
                  <div>
                    <Picker
                      handleSelectedGif={(url) => handleSelectedGif(url)}
                      onEmojiSelect={(emojiObject) => {
                        const newText = `${input.current.value}${emojiObject.emoji}`;
                        input.current.value = newText;
                      }}
                    />
                    <SendImage
                      handleImageMessage={(img, text) => handleImageMessage(img, text)}
                      placeholder={$translatei18n('WriteYourMessageHere')}
                      inputText={inputValue}
                    />
                  </div>
                }
                rightButtons={
                  <button
                    type="button"
                    className={classNames(
                      'inline-flex items-center justify-center h-8 w-8 text-sm leading-4 font-medium rounded shadow-sm focus:outline-none focus:ring-0 focus:ring-offset-0',
                      primaryFont,
                    )}
                    onClick={() => onSendMessage()}
                    style={{
                      backgroundColor: activeCommunityAccentColor,
                    }}
                    // buttonsFloat='left'
                    onKeyPress={(e) => {
                      if (e.shiftKey && e.charCode === 13) {
                        return true;
                      }
                      if (e.charCode === 13) {
                        onSendMessage();
                        e.preventDefault();
                        return false;
                      }
                    }}
                  >
                    <PaperAirplaneIcon className="w-5 h-5 transform rotate-90 text-white" />
                  </button>
                }
              />
            </>
          ) : (
            <RequestActionButton
              accent_color={activeCommunityAccentColor}
              textRoom={activePrivateRoom}
              requestsCount={requestsCount}
            />
          )}
        </div>
      </section>

      {openImageZoomModal && (
        <LightBoxWrapper mainSrc={openImage} onCloseRequest={() => setopenImageZoomModal(false)} />
      )}
    </>
  );
}
