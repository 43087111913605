import CommonLoader from './CommonLoader';

export default function LoadingSpinner({ isSkekeleton }) {
  return (
    <div className="fixed z-10 inset-0 overflow-y-auto loader__background">
      {isSkekeleton ? (
        <div className="container mx-auto flex animate-pulse "></div>
      ) : (
        <div className="flex items-center justify-center">
          <CommonLoader isVisible />
        </div>
      )}
    </div>
  );
}
