import {
  ADD_SELECTED_COMMUNITY_USER_ID,
  FETCH_LEADERBOARD,
  REMOVE_USER_FROM_LEADERBOARD,
  SET_MOBILE_LEADERBOARD_MODAL,
} from './actionTypes';

export const fetchLeaderboard = (payload) => ({
  type: FETCH_LEADERBOARD,
  payload,
});

export const addLeaderboardUserId = (payload) => ({
  type: ADD_SELECTED_COMMUNITY_USER_ID,
  payload,
});

export const removeUserFromLeaderboard = (payload) => ({
  type: REMOVE_USER_FROM_LEADERBOARD,
  payload,
});

export const mobileLeaderboardModal = (payload) => ({
  type: SET_MOBILE_LEADERBOARD_MODAL,
  payload,
});
