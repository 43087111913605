import {
  AUTH_LOG_OUT_SUCCESS,
  ONBOARDING_ADD_PHONE_NUMBER_FAILED,
  ONBOARDING_ADD_PHONE_NUMBER_SUCCESS,
  ONBOARDING_COMPLETE_PROCESS_FAILED,
  ONBOARDING_COMPLETE_PROCESS_SUCCESS,
  ONBOARDING_DELETE_PHONE_NUMBER,
  ONBOARDING_VERIFY_COMMUNITY_STEP_RESET,
  ONBOARDING_VERIFY_COMMUNITY_STEP_SUCCESS,
  ONBOARDING_VERIFY_COMMUNITY_STEP_UPDATE,
  ONBOARDING_VERIFY_EMAIL_FAILED,
  ONBOARDING_VERIFY_EMAIL_SUCCESS,
  ONBOARDING_VERIFY_PHONE_NUMBER_FAILED,
  ONBOARDING_VERIFY_PHONE_NUMBER_SUCCESS,
} from '../actions/actionTypes';

const initialState = {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ONBOARDING_VERIFY_EMAIL_SUCCESS:
      return { ...state, emailVerified: true };
    case ONBOARDING_VERIFY_EMAIL_FAILED:
      return { ...state, emailVerified: false };
    case ONBOARDING_ADD_PHONE_NUMBER_SUCCESS:
      return { ...state, phoneAdded: true };
    case ONBOARDING_ADD_PHONE_NUMBER_FAILED:
      return { ...state, phoneAdded: false };
    case ONBOARDING_DELETE_PHONE_NUMBER:
      return { ...state, phoneAdded: null };
    case ONBOARDING_VERIFY_PHONE_NUMBER_SUCCESS:
      return { ...state, phoneOtpVerified: true };
    case ONBOARDING_VERIFY_PHONE_NUMBER_FAILED:
      return { ...state, phoneOtpVerified: true };
    case ONBOARDING_COMPLETE_PROCESS_SUCCESS:
      return { ...state, onBoardingProcessComplete: true };
    case ONBOARDING_COMPLETE_PROCESS_FAILED:
      return { ...state, onBoardingProcessComplete: false };

    case ONBOARDING_VERIFY_COMMUNITY_STEP_SUCCESS:
      return { ...state, onboardingVerifyStepStatus: action?.payload?.status };
    case ONBOARDING_VERIFY_COMMUNITY_STEP_UPDATE:
      return { ...state, onboardingVerifyStepStatus: action?.payload };
    case ONBOARDING_VERIFY_COMMUNITY_STEP_RESET:
      return { ...state, onboardingVerifyStepStatus: null };

    case AUTH_LOG_OUT_SUCCESS:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
