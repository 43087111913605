import { createBrowserHistory } from 'history';
import { isPlainObject } from 'lodash';
import { store } from '../store/configureStore';
import { useLocation } from 'react-router-dom';
import React from 'react';
import { getCommonAvtarName } from '../utils/Utils';
import rgbHex from 'rgb-hex';

export const history = createBrowserHistory();

export const copyToClipboard = (containerId) => {
  if (window.getSelection) {
    if (window.getSelection().empty) {
      // Chrome
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) {
      // Firefox
      window.getSelection().removeAllRanges();
    }
  } else if (document.selection) {
    // IE?
    document.selection.empty();
  }

  if (document.selection) {
    const range = document.body.createTextRange();
    range.moveToElementText(document.getElementById(containerId));
    range.select().createTextRange();
    document.execCommand('copy');
  } else if (window.getSelection) {
    const range = document.createRange();
    range.selectNode(document.getElementById(containerId));
    window.getSelection().addRange(range);
    document.execCommand('copy');
  }
};

export const parseAxiosErrorMessage = (error) => {
  let message = 'Something went wrong.';
  let errorObject = error;
  if (error && error.response && error.response.data) {
    errorObject = error.response.data;
  } else if (error && error.data) {
    errorObject = error.data;
  }

  if (errorObject) {
    if (errorObject.message) {
      message = errorObject.message;
    } else if (errorObject.data && typeof errorObject.data === 'string') {
      message = errorObject.data;
    } else if (errorObject.error && typeof errorObject.error === 'string') {
      message = errorObject.error;
    } else if (Array.isArray(errorObject?.errors) && typeof errorObject?.errors[0] === 'string') {
      message = errorObject?.errors[0];
    } else if (isPlainObject(errorObject?.errors)) {
      Object.keys(errorObject?.errors).forEach((key) => {
        message = `${key} ${errorObject?.errors?.[key]?.[0]}`;
      });
    } else if (errorObject?.errors?.description?.[0]) {
      message = errorObject?.errors?.description?.[0];
    } else if (errorObject?.reason) {
      message = errorObject.reason;
    } else if (errorObject?.errors?.length) {
      message = errorObject.errors;
    } else if (errorObject && error?.response?.status === 429 && typeof errorObject === 'string') {
      message = $translatei18n('error_messege_429_generic')
    } else if (errorObject?.[0]?.length) {
      message = errorObject?.[0];
    } else if (errorObject?.status) {
      message = errorObject.status;
    }
  }
  return message;
};

export const parseAxiosErrorMessageStage = (error) => {
  let message = 'Something went wrong.';
  let errorObject = error;
  if (error && error.response && error.response.data) {
    errorObject = error.response.data;
  } else if (error && error.data) {
    errorObject = error.data;
  }

  if (errorObject) {
    if (errorObject?.message) {
      message = errorObject?.message;
    } else if (Array.isArray(errorObject) && typeof errorObject[0] === 'string') {
      message = errorObject[0];
    } else if (errorObject?.errors && typeof errorObject?.errors === 'string') {
      message = errorObject?.errors;
    } else if (errorObject?.data && typeof errorObject?.data === 'string') {
      message = errorObject?.data;
    } else if (errorObject?.error && typeof errorObject?.error === 'string') {
      message = errorObject.error;
    } else if (Array.isArray(errorObject?.errors) && typeof errorObject?.errors[0] === 'string') {
      message = errorObject?.errors[0];
    } else if (errorObject?.errors?.description?.[0]) {
      message = errorObject?.errors?.description?.[0];
    }
  }
  return message;
};

export const parseAxiosErrorMessageRole = (error) => {
  if (error?.response?.data?.errors?.role?.length) {
    return `Role name ${error.response.data.errors.role[0]}`;
  }
  return '';
};

export const numberDiff = (num1, num2) => Number(num1) - Number(num2);

export const getButtonCSS = (accentColor) => ({
  backgroundColor: accentColor,
  // borderColor: accentColor,
  // color: '#ffffff',
});

export const classNames = (...classes) => classes.filter(Boolean).join(' ');

export const capitalizeFirstLetter = (string) => {
  if (string) {
    return string.charAt(0).toUpperCase() + (string?.slice(1)?.toLowerCase() || '');
  }
  return '';
};

// REF: https://github.com/component/humanize-number/blob/master/index.js
export const humanizeNumber = (n, options) => {
  if (!n) {
    return 0;
  }
  options = options || {};
  const d = options.delimiter || ',';
  const s = options.separator || '.';
  n = n.toString().split('.');
  n[0] = n[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, `$1${d}`);
  return n.join(s);
};

export const prepareUserImageName = (first_name, last_name) => {
  if (!first_name && !last_name) {
    return '';
  }
  return `${first_name?.charAt(0)?.toUpperCase() || ''}${last_name?.charAt(0)?.toUpperCase() || ''}`;
};

export const redirectToLoginDomain = (redirectUrl = '') => {
  history.replace('/signin');
  // let redirect_url = window.location.href;
  // if (redirectUrl) {
  //   redirect_url = redirectUrl;
  // }
  // window.location.href = `${window.location.origin}/community-login&redirect_url=${redirect_url}`;
};

export const redirectToGuestView = () => {
  window.location.href = `${window.location.origin}/guest`;
};

export const redirectToLoginApp = () => {
  history.replace('/community-login');
};

export const swapArrayElements = (swapFromIndex, swapToIndex, arr = []) => {
  if (!arr?.length) return arr;
  const newArr = [...arr];
  [newArr[swapFromIndex], newArr[swapToIndex]] = [newArr[swapToIndex], newArr[swapFromIndex]];
  return newArr;
};

export const getCurrentTimeZone = (time = new Date()) => {
  const date = new Date(time).toTimeString();
  const timeZoneFull = date?.split('(')[1]?.split(')')[0];
  if (timeZoneFull?.toUpperCase() === timeZoneFull) {
    return timeZoneFull;
  }
  const timeZoneArr = timeZoneFull?.split(' ');
  let timeZone = '';
  timeZoneArr?.forEach((item) => {
    timeZone += item[0];
  });
  return timeZone;
};

export const randomString = (length = 8, chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ') => {
  let result = '';
  for (let i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
  return result;
};

export const isPastDate = (date) => new Date(date).setHours(0, 0, 0, 0) <= new Date('10/11/2022').setHours(0, 0, 0, 0);

export const getGreetings = () => {
  const myDate = new Date();
  const hrs = myDate.getHours();

  let greet = $translatei18n('GoodMorning');

  if (hrs < 12) greet = $translatei18n('GoodMorning');
  else if (hrs >= 12 && hrs <= 17) greet = $translatei18n('GoodAfternoon');
  else if (hrs >= 17 && hrs <= 24) greet = $translatei18n('GoodEvening');

  return greet;
};

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const hex2rgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map((x) => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`;
};

export const formatDate = (dateTimeString) => {
  const dateTime = new Date(dateTimeString);
  const month = dateTime.toLocaleString('en-US', { month: 'long' });
  const date = dateTime.getDate();
  const year = dateTime.getFullYear();
  const dateStr = month?.slice(0, 3) + ' ' + String(date) + ', ' + String(year);
  return dateStr;
};

export const getTranslation = (keyInJson, replacableWord, value) => {
  const textTranslation = $translatei18n(keyInJson);
  if (textTranslation) {
    return textTranslation.replaceAll(`{${replacableWord}}`, value);
  }
};

export const getPercent = (minNumber, MaxNumber) => {
  let maxnum = typeof MaxNumber === 'string' ? 20000 : MaxNumber;
  return ((minNumber / maxnum) * 100)?.toFixed(1);
};

export const convertToGbOrTb = (num) => {
  if (num) {
    let text = '';
    if (num < 1000) {
      text = `${num} ${$translatei18n('GB')}`;
    } else {
      text = `${parseInt(num / 1000)} ${$translatei18n('TB')}`;
    }
    return text;
  }
};

export const isNullOrUndefined = (val) => {
  return val === null || val === undefined;
};

export const getNumbersFromText = (text) => {
  const number = parseFloat(text);
  if (typeof text === 'string') {
    if (text.includes('MB')) {
      return megabytesToGigabytes(number);
    } else if (text.includes('GB')) return number;
  }
};

export const gigabytesToMegabytes = (number) => {
  return gigabytes * 1024;
};

export const megabytesToGigabytes = (megabytes) => {
  return megabytes / 1024;
};

export const openInNewTab = (url) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

export const analyticsDefaults = (url) => {
  const obj = {
    screen_width: window.innerWidth,
    screen_height: window.innerHeight,
  };
  if (typeof url === 'string') {
    obj['current_url'] = url;
  } else if (typeof url === 'boolean') {
    if (url) {
      obj['current_url'] = window.location.href;
    }
  }

  return obj;
};

export const renderRolesAsString = (roles_ids, roles_list) => {
  return roles_ids?.map((role_id) => roles_list[role_id]?.role).join(', ');
};

export const isHexColor = (value) => {
  const hexRegex = /^#([0-9a-fA-F]{3}|[0-9a-fA-F]{6})$/;
  return hexRegex.test(value);
};

export const darkenColor = (hex, percent) => {
  hex = isHexColor(hex) ? hex : `#${rgbHex(hex)}`;

  // Ensure the percent is within the valid range
  percent = Math.min(100, Math.max(0, percent));

  // Convert hex to RGB
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);

  // Darken each RGB component by the percentage
  r = Math.round(r * (1 - percent / 100));
  g = Math.round(g * (1 - percent / 100));
  b = Math.round(b * (1 - percent / 100));

  // Convert the updated RGB values back to hex
  let darkenedHex = `#${((r << 16) | (g << 8) | b).toString(16).padStart(6, '0')}`;

  return darkenedHex;
};

export const getKeyValueFromQueryParams = (query) => {
  if (query) {
    const queryString = query.split('?')[1];
    const params = new URLSearchParams(queryString);

    const filterValues = [];
    for (const [key, value] of params.entries()) {
      if (key === 'filter') {
        if (Array.isArray(value)) {
          filterValues.push(...value);
        } else {
          filterValues.push(value);
        }
      }
    }
    return filterValues;
  }
};
