import {
  FETCH_LEADERBOARD_SUCCESS,
  ADD_SELECTED_COMMUNITY_USER_ID,
  UPDATE_LEADERBOARD_DATA,
  SET_MOBILE_LEADERBOARD_MODAL,
} from '../actions/actionTypes';

const initialState = {
  leaderboard: {
    coins_count: {},
    userIds: [],
    community_users: {},
    current_user_rank: null,
    current_user_score: null,
    pagy: null,
    lastUpdatedAt: null,
  },
  selectedUserCommunityId: null,
  enableMobileLeaderboard: false,
};

const leaderboard = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LEADERBOARD_SUCCESS:
      if (action?.payload?.new) {
        return {
          ...state,
          leaderboard: {
            fetchSuccess: true,
            coins_count: {
              ...action.payload?.coins_count,
            },
            community_users: {
              ...action?.payload?.community_users,
            },
            userIds: [...new Set([...(action.payload.userIds || [])])],
            current_user_rank: action?.payload?.current_user_rank,
            current_user_score: action?.payload?.current_user_score,
            pagy: action?.payload?.pagy,
            lastUpdatedAt: action?.payload?.last_updated_at,
          },
        };
      }
      return {
        ...state,
        leaderboard: {
          fetchSuccess: true,
          coins_count: {
            ...state?.leaderboard?.coins_count,
            ...action.payload.coins_count,
          },
          community_users: {
            ...state?.leaderboard?.community_users,
            ...action?.payload?.community_users,
          },
          userIds: [...new Set([...(state?.leaderboard?.userIds || []), ...action.payload.userIds])],
          current_user_rank: action?.payload?.current_user_rank,
          current_user_score: action?.payload?.current_user_score,
          pagy: action?.payload?.pagy,
          lastUpdatedAt: action?.payload?.last_updated_at,
        },
      };

    case ADD_SELECTED_COMMUNITY_USER_ID:
      return {
        ...state,
        selectedUserCommunityId: action?.payload?.communityUserId,
      };

    case UPDATE_LEADERBOARD_DATA:
      return {
        ...state,
        leaderboard: {
          ...state.leaderboard,
          ...action?.payload,
        },
      };

    case SET_MOBILE_LEADERBOARD_MODAL:
      return {
        ...state,
        enableMobileLeaderboard: action?.payload,
      };

    default:
      return state;
  }
};

export default leaderboard;
