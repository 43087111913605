import {
  all, fork, put, takeLatest,
} from 'redux-saga/effects';
import { parseAxiosErrorMessage, parseAxiosErrorMessageRole } from '../constants/utils';
import NotificationService from '../services/notificationService';
import {
  FETCH_ROLES_REQUEST,
  FETCH_ROLES_SUCCESS,
  UPDATE_ROLES_REQUEST,
  UPDATE_ROLES_SUCCESS,
  CREATE_ROLES_REQUEST,
  CREATE_ROLES_SUCCESS,
  DELETE_ROLES_REQUEST,
  FETCH_COMMUNITY_USER_ROLE_REQUEST,
  FETCH_COMMUNITY_USER_ROLE_SUCCESS,
  SEARCH_COMMUNITY_MEMBER_REQUEST,
  SEARCH_COMMUNITY_MEMBER_SUCCESS,
  FETCH_COMMUNITY_ROLES_REQUEST,
  FETCH_COMMUNITY_ROLES_SUCCESS,
} from '../store/actions/actionTypes';
import {
  createRole,
  deleteRole,
  fetchCommunityRoles,
  fetchRoles,
  getUserCommunityRoleListService,
  searchCommunityMember,
  updateRoles,
} from '../services/roleServices';
import { communityRolesModel, rolesModel } from '../services/models/roles/rolesModel';
import { SUCCESS_MESSAGES } from '../constants/notificationMessages';

function* fetchRolesSaga() {
  yield takeLatest(FETCH_ROLES_REQUEST, function* ({ payload }) {
    try {
      const response = yield fetchRoles(payload);
      yield put({
        type: FETCH_ROLES_SUCCESS,
        payload: { ...rolesModel(response) },
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      // if (error.response.data.code === 401)
      // dispatch redirect to overview
      // yield put({
      //   type: REDIRECT_TO_OVERVIEW,
      //   payload: true,
      // });
      // { NotificationService.error(message); }

      // To check if unauthorized then redirect to overview to fetch permissions
      if (error?.response?.status === 401) {
        if (payload?.history) {
          payload.history.push('/');
        } else {
          NotificationService.error(message);
        }
      }
    }
  });
}

function* fetchCommunityRolesSaga() {
  yield takeLatest(FETCH_COMMUNITY_ROLES_REQUEST, function* () {
    try {
      const response = yield fetchCommunityRoles();
      yield put({
        type: FETCH_COMMUNITY_ROLES_SUCCESS,
        payload: { ...communityRolesModel(response) },
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      if (error.response.data.code === 401) NotificationService.error(message);
    }
  });
}

function* createRoleSaga() {
  yield takeLatest(CREATE_ROLES_REQUEST, function* ({ payload }) {
    try {
      const response = yield createRole(payload);
      yield put({
        type: CREATE_ROLES_SUCCESS,
        payload: response,
      });

      const { data } = response;

      NotificationService.success(SUCCESS_MESSAGES.ROLE_CREATE);
    } catch (error) {
      let message = '';
      message = parseAxiosErrorMessageRole(error);
      if (!message) {
        message = parseAxiosErrorMessage(error);
      }
      NotificationService.error(message);
    }
  });
}

function* updateRolesSaga() {
  yield takeLatest(UPDATE_ROLES_REQUEST, function* ({ payload }) {
    try {
      const response = yield updateRoles(payload);
      yield put({
        type: UPDATE_ROLES_SUCCESS,
        payload: response,
      });
      NotificationService.success(SUCCESS_MESSAGES.ROLE_UPDATE);
    } catch (error) {
      let message = '';
      message = parseAxiosErrorMessageRole(error);
      if (!message) {
        message = parseAxiosErrorMessage(error);
      }
      NotificationService.error(message);
    }
  });
}

function* deleteRoleSaga() {
  yield takeLatest(DELETE_ROLES_REQUEST, function* ({ payload }) {
    try {
      const response = yield deleteRole(payload);
      NotificationService.success(SUCCESS_MESSAGES.ROLE_DELETE);
      window.location.reload();
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* getUserCommunityRoleListSaga() {
  yield takeLatest(FETCH_COMMUNITY_USER_ROLE_REQUEST, function* ({ payload }) {
    try {
      const response = yield getUserCommunityRoleListService(payload);
      yield put({
        type: FETCH_COMMUNITY_USER_ROLE_SUCCESS,
        payload: { response, user_id: payload.user_id, is_reset: payload.is_reset },
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* searchSelectedCommunityMember() {
  yield takeLatest(SEARCH_COMMUNITY_MEMBER_REQUEST, function* ({ payload }) {
    try {
      const response = yield searchCommunityMember(payload);
      yield put({
        type: SEARCH_COMMUNITY_MEMBER_SUCCESS,
        payload: { response, is_reset: payload.is_reset },
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      if (error.response.data.code === 401) NotificationService.error(message);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(fetchRolesSaga),
    fork(updateRolesSaga),
    fork(createRoleSaga),
    fork(deleteRoleSaga),
    fork(getUserCommunityRoleListSaga),
    fork(searchSelectedCommunityMember),
    fork(fetchCommunityRolesSaga),
  ]);
}
