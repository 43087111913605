import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { XIcon, PlusIcon } from '@heroicons/react/solid';
import _ from 'lodash';
import NotificationService from '../../../services/notificationService';
import {
  getSelectedUserRoles,
  addSelectedUserRole,
  deleteSelectedUserRole,
  getUserRoles,
} from '../../../services/roleServices';
import { classNames, parseAxiosErrorMessage } from '../../../constants/utils';
import { getActiveCommunityAccentColor } from '../../../selectors/CommunitySelectors';
import { removeUserFromLeaderboard } from '../../../store/actions/leaderboardActions';
import styles from './styles.module.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import UserRolesDropdown from '../../settings/peopleManagement/UserRolesDropdown';
import { PLACE_TYPE } from '../../../constants';
import RenderRoles from '../../../components/renderView/RenderRoles';

function RoleListPanel({ userCommunityRoles, addUserRole, deleteUserRole }) {
  const accentColor = useSelector(getActiveCommunityAccentColor);

  const [query, setQuery] = useState('');
  const [rolesArr, setRolesArr] = useState([]);

  useEffect(() => {
    if (query) {
      filterRolesArr(query);
    } else {
      setRolesArr(userCommunityRoles);
    }
  }, [userCommunityRoles]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      filterRolesArr(query);
    }, 400);

    return () => clearTimeout(timeout);
  }, [query]);

  const filterRolesArr = (str) => {
    if (str === '') {
      setRolesArr(userCommunityRoles);
    } else {
      setRolesArr(userCommunityRoles.filter((ele) => ele.role.toLowerCase().includes(str.toLowerCase())));
    }
  };

  const disableRole = (index) => {
    const newRolesArr = _.cloneDeep(rolesArr);
    newRolesArr[index].disabled = true;

    setRolesArr(newRolesArr);
  };

  const handleChange = (role, index, allRoles) => () => {
    const isLastRole = allRoles?.filter((item) => item?.checked)?.length === 1;
    disableRole(index);
    if (role.checked) {
      deleteUserRole(
        {
          id: role.userRoleId,
          community_role_id: role.id,
        },
        isLastRole,
      );
    } else {
      addUserRole(role.id);
    }
  };

  return (
    <div className={`px-3 py-2 max-h-96 overflow-y-scroll ${styles.scrollbar}`}>
      <input
        type="text"
        value={query}
        onChange={(event) => setQuery(event.target.value)}
        placeholder={$translatei18n('EnterRoleToSearch')}
        className="shadow-sm block w-full text-sm rounded-md input card mb-2"
      />
      {rolesArr.map((item, index) => (
        <div
          key={item.id}
          className={classNames('flex flex-row py-2', item.disabled && 'cursor-not-allowed opacity-50')}
        >
          <input
            id={`roleOption-${item.id}`}
            name={`roleOption-${item.id}`}
            checked={item.checked}
            type="checkbox"
            className="h-4 w-4 text__link border-gray-300 rounded focus:outline-none focus:ring-0 focus:ring-offset-0"
            onChange={handleChange(item, index, rolesArr)}
            style={{ color: accentColor }}
            disabled={item.disabled}
          />
          <label
            htmlFor={`roleOption-${item.id}`}
            className="ltr:pl-2 rtl:pr-2 text-sm w-11/12 break-words text-left menu__option"
          >
            {item.role}
          </label>
        </div>
      ))}
    </div>
  );
}

function ManageRoles({ activeCommunityId, activeUserId, userData, self }) {
  const communityConfig = useSelector((state) => state?.community?.communityConfig);
  const [roleList, setRoleList] = useState([]);
  const [selectedRole, setSelectedRoles] = useState([]);
  const [userCommunityRoles, setUserCommunityRoles] = useState([]);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();
  const leaderboardState = useSelector((state) => state?.leaderboard);
  const isNotOwnerProfile = !userData?.owner;

  useEffect(() => {
    if (activeCommunityId && activeUserId) {
      if (communityConfig.canManageRoles) {
        if(isNotOwnerProfile) {
          fetchSelectedUserRoles();
        } else {
          const ownerRolesMap = userData?.data?.community_roles.map(role => ({
            community_role: {...role}
          }))
          setSelectedRoles(ownerRolesMap);
        }
      } else {
        fetchUserRoles();
      }
    }
  }, [activeCommunityId, activeUserId]);

  const makeUserCommunityRolesArr = (roles, selectedRoles) => {
    const newUserCommunityRoles = [];

    roles.forEach((ele) => {
      newUserCommunityRoles.push({
        id: ele.id,
        role: ele.role,
        community_id: ele.community_id,
        color: ele.color,
        checked: false,
        userRoleId: null,
      });
    });

    selectedRoles.forEach((ele) => {
      newUserCommunityRoles.push({
        id: ele.community_role.id,
        role: ele.community_role.role,
        community_id: ele.community_role.community_id,
        color: ele.community_role.color,
        checked: true,
        userRoleId: ele.id,
      });
    });

    // sorting to keep order same in role list panel
    newUserCommunityRoles.sort((ele1, ele2) => parseInt(ele1.id, 10) - parseInt(ele2.id, 10));

    setUserCommunityRoles(newUserCommunityRoles);
  };

  const fetchSelectedUserRoles = async () => {
    // To Show and Manage Role
    try {
      const userRolesResponse = await getSelectedUserRoles(activeCommunityId, activeUserId);

      const { data } = userRolesResponse;
      const roles = data?.data?.community_roles;
      const userRoles = data?.data?.user_community_roles;

      makeUserCommunityRolesArr(roles, userRoles);

      if (roles && userRoles) {
        setRoleList(roles);
        setSelectedRoles(userRoles);
      } else {
        setIsError(true);
      }
    } catch (error) {
      setIsError(true);
    }
  };

  const fetchUserRoles = async () => {
    // To Show Roles To Members
    try {
      const userRolesResponse = await getUserRoles(activeCommunityId, activeUserId);
      const { data } = userRolesResponse;
      const userRoles = data?.data?.community_roles;

      if (userRoles) {
        setSelectedRoles(userRoles);
      } else {
        setIsError(true);
      }
    } catch (error) {
      setIsError(true);
    }
  };

  const removeFromLeaderboard = () => {
    try {
      dispatch(
        removeUserFromLeaderboard({
          leaderboardData: leaderboardState,
          userId: activeUserId,
        }),
      );
    } catch (error) {
      NotificationService.error(error);
    }
  };

  const deleteUserRole = async (role, isLastRole) => {
    try {
      await deleteSelectedUserRole(activeCommunityId?.id, role.community_role_id, role.id);
      NotificationService.success('Role removed from the user');
      fetchSelectedUserRoles();
      if (isLastRole) removeFromLeaderboard();
    } catch (error) {
      NotificationService.error(parseAxiosErrorMessage(error));
    }
  };

  const addUserRole = async (roleId) => {
    try {
      await addSelectedUserRole(activeCommunityId, roleId, activeUserId);
      fetchSelectedUserRoles();
    } catch (error) {
      NotificationService.error(parseAxiosErrorMessage(error));
    }
  };

  if (isError) {

    return null;
  }

  if (!communityConfig.canManageRoles) {
    // Just Displays Roles
    return (
      <div className="block">
        {selectedRole?.length > 0 && (
          <div className={`mt-7 mb-3 text__title profile__sidepanel--roles font-bold ${self ? "uppercase" : "capitalize text-sm"}`}>
            {self ? $translatei18n('Roles') : $translatei18n('InCommunityRoles')}
          </div>
        )}
        <div className="flex items-center flex-wrap relative">
          <RenderRoles
            userRoles={selectedRole}
            deleteUserRole={deleteUserRole}
            managePermission={false}
            position="left"
            placeType={PLACE_TYPE.MANAGE_ROLE_WIEW_ONLY}
          />
        </div>
      </div>
    );
  }

  return (
    // To Manage Roles
    <div className="block profile__sidepanel--roles">
      <div className={`mt-7 mb-3 text__title font-bold ${self ? "uppercase" : "capitalize text-sm"}`}>
      {self ? $translatei18n('Roles') : $translatei18n('InCommunityRoles')}
      </div>
      <div className="flex items-center flex-wrap relative">
        {isNotOwnerProfile && (
          <Popover as="div" className="inline-block text-left ltr:mr-2.5 rtl:ml-2.5">
            <PopoverButton className="inline-flex justify-center text-sm items-center w-full rounded-full p-1 btn__light focus:outline-none focus:ring-0 focus:ring-offset-0">
              <PlusIcon className="w-3 h-3" />
              {selectedRole?.length === 0 && <span className="ltr:ml-2 rtl:mr-2">Add New Role</span>}
            </PopoverButton>

            <PopoverPanel className="absolute z-10 left-0 mt-2 w-56 rounded-md shadow-lg menu__options__container border focus:outline-none">
              <RoleListPanel
                userCommunityRoles={userCommunityRoles}
                addUserRole={addUserRole}
                deleteUserRole={deleteUserRole}
              />
            </PopoverPanel>
          </Popover>
        )}
        <RenderRoles
          userRoles={selectedRole}
          deleteUserRole={deleteUserRole}
          position="left"
          placeType={PLACE_TYPE.MANAGE_ROLE}
        />
      </div>
    </div>
  );
}

export default ManageRoles;
