import React, {
  Fragment, useCallback, useEffect, useRef, useState,
} from 'react';
import {
  DocumentSearchIcon, ExclamationIcon, SearchIcon, XIcon,
} from '@heroicons/react/outline';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { Dialog, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { throttle } from 'lodash';
import { getActiveCommunity, getActiveCommunityFontsClass, getActiveGroupId } from '../../selectors/CommunitySelectors';
import SearchTypePills from './SearchTypePills';
import ForumPosts from './ForumPosts';
import ResourcePosts from './ResourcePosts';
import PeopleList from './PeopleList';
import EventsList from './EventsList';
import ChannelList from './ChannelList';
import { GLOBAL_SEARCH_TYPES } from '../../constants';
import { fetchAllSearchResults } from '../../services/SearchService';
import GroupList from './GroupsList';

function GlobalSearch({page}) {
  const [open, setOpen] = useState(false);
  const [searchResult, setSearchResult] = useState(null);
  const [activeType, setActiveType] = useState(GLOBAL_SEARCH_TYPES[0].key);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const scrollContainerRef = useRef(null);
  const [groupId, setGroupId] = useState(null);

  const activeCommunity = useSelector(getActiveCommunity);
  const { primaryFont } = useSelector(getActiveCommunityFontsClass);
  const accentColor = activeCommunity?.accent_color;
  const activeGroupId = useSelector(getActiveGroupId);

  const throttledSearch = useCallback(
    throttle(handleSearch, 1000),
    [activeCommunity, activeGroupId],
  );

  function handleTypeClick(type) {
    setActiveType(type);
    handleScrollTop();
  }

  function closeSearchDrawer() {
    setOpen(false);
  }

  function handleScrollTop() {
    if (scrollContainerRef?.current) {
      scrollContainerRef.current.scrollTop = 0;
    }
  }

  const getEnabledTypes = useCallback((data) => {
    const enabledTypes = [];
    for (const key in data) {
      const searchTypeData = data[key];
      if (searchTypeData?.total_count > 0) {
        enabledTypes.push(key);
      }
    }
    return enabledTypes;
  }, []);

  async function handleSearch(query, groupId) {
    if (query.trim().length === 0) {
      setSearchResult(null);
      setActiveType(GLOBAL_SEARCH_TYPES[0].key);
      return;
    }

    if (!searchResult) setIsLoading(true);

    const { data } = await fetchAllSearchResults(query, activeCommunity?.id, groupId);

    setSearchResult(data);
    setActiveType(GLOBAL_SEARCH_TYPES[0].key);
    setIsLoading(false);
  }

  useEffect(() => {
    if (activeGroupId !== groupId) {
      setGroupId(activeGroupId);
      setSearchQuery('');
      setSearchResult(null);
      setActiveType(GLOBAL_SEARCH_TYPES[0].key);
      setIsLoading(false);
    }
  }, [activeGroupId]);

  function renderSearchResult() {
    const {
      forum_posts, resource_posts, calendar_events, community_users, channels, groups,
    } = searchResult;
    const onCommonPage = activeType === 'all';

    return (
      <>
        {(community_users?.total_count !== 0 && onCommonPage) || activeType === 'user' ? (
          <PeopleList
            searchQuery={searchQuery}
            peopleData={community_users}
            activeCommunity={activeCommunity}
            activeGroupId={groupId}
            closeDrawer={closeSearchDrawer}
            onCommonPage={onCommonPage}
            handleViewDetails={community_users?.total_count > 1 ? () => setActiveType('user') : null}
            handleScrollTop={handleScrollTop}
          />
        ) : null}
        {(forum_posts?.total_count !== 0 && onCommonPage) || activeType === 'forum' ? (
          <ForumPosts
            searchQuery={searchQuery}
            forumData={forum_posts}
            activeCommunity={activeCommunity}
            activeGroupId={groupId}
            closeDrawer={closeSearchDrawer}
            onCommonPage={onCommonPage}
            handleViewDetails={forum_posts?.total_count > 1 ? () => setActiveType('forum') : null}
            handleScrollTop={handleScrollTop}
          />
        ) : null}
        {(resource_posts?.total_count !== 0 && onCommonPage) || activeType === 'resource' ? (
          <ResourcePosts
            searchQuery={searchQuery}
            resourceData={resource_posts}
            activeCommunity={activeCommunity}
            activeGroupId={groupId}
            closeDrawer={closeSearchDrawer}
            onCommonPage={onCommonPage}
            handleViewDetails={resource_posts?.total_count > 2 ? () => setActiveType('resource') : null}
            handleScrollTop={handleScrollTop}
          />
        ) : null}
        {(calendar_events?.total_count !== 0 && onCommonPage) || activeType === 'calendar_event' ? (
          <EventsList
            searchQuery={searchQuery}
            eventData={calendar_events}
            activeCommunity={activeCommunity}
            activeGroupId={groupId}
            closeDrawer={closeSearchDrawer}
            onCommonPage={onCommonPage}
            handleViewDetails={calendar_events?.total_count > 1 ? () => setActiveType('calendar_event') : null}
            handleScrollTop={handleScrollTop}
          />
        ) : null}
        {(channels?.total_count !== 0 && onCommonPage) || activeType === 'channel' ? (
          <ChannelList
            searchQuery={searchQuery}
            channelData={channels}
            activeCommunity={activeCommunity}
            activeGroupId={groupId}
            closeDrawer={closeSearchDrawer}
            onCommonPage={onCommonPage}
            handleViewDetails={channels?.total_count > 3 ? () => setActiveType('channel') : null}
            handleScrollTop={handleScrollTop}
          />
        ) : null}
        {(groups?.total_count !== 0 && onCommonPage) || activeType === 'group' ? (
          <GroupList
            searchQuery={searchQuery}
            groupData={groups}
            activeCommunity={activeCommunity}
            activeGroupId={groupId}
            closeDrawer={closeSearchDrawer}
            onCommonPage={onCommonPage}
            handleViewDetails={groups?.total_count > 3 ? () => setActiveType('group') : null}
            handleScrollTop={handleScrollTop}
          />
        ) : null}
      </>
    );
  }

  const viewTypeOne = () => {
    return(
      <>
        <div className="p-1">
          <SearchIcon
            className="h-6 w-6 text__header cursor-pointer"
            aria-label="Search for People, Posts or Events"
            onClick={() => setOpen(true)}
          />
        </div>
        <Transition show={open} as={Fragment}>
          <Dialog as="div" className="fixed inset-0 z-50 overflow-hidden" onClose={closeSearchDrawer}>
            <div className="absolute inset-0 overflow-hidden">
              <TransitionChild
                as={Fragment}
                enter="ease-in-out duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-500"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={closeSearchDrawer} />
              </TransitionChild>

              <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
                <TransitionChild
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <div className="w-screen max-w-md xs:max-w-xs">
                    <div
                      className="h-full flex flex-col px-6 shadow-xl overflow-y-scroll main--background sidepanel__container"
                      ref={scrollContainerRef}
                    >
                      <div
                        className="sticky top-0 z-10 main--background"
                        style={{
                          marginLeft: '-4px', marginRight: '-4px', paddingLeft: '4px', paddingRight: '4px',
                        }}
                      >
                        <div className="flex items-start justify-between pt-6">
                          <DialogTitle className={classNames('text-lg font-medium text__title', primaryFont)}>
                            {$translatei18n('Search')}
                          </DialogTitle>
                          <div className="ml-3 h-7 flex items-center">
                            <button
                              type="button"
                              className="rounded-md focus:outline-none focus:ring-0 focus:ring-offset-0"
                              onClick={closeSearchDrawer}
                              id="closeBtn"
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6 main__icon" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                        {/* search input */}
                        <div className="relative mt-6 mb-1">
                          <span className="absolute inset-y-0 left-0 flex items-center ltr:pl-2 rtl:pr-2">
                            <SearchIcon className="w-5 h-5 text__description main__icon" />
                          </span>
                          <input
                            type="text"
                            placeholder={$translatei18n('Search')}
                            className="block ltr:pl-9 rtl:pr-9 ltr:pr-4 rtl:pl-4  py-2 w-full sm:text-sm rounded-md card text__body input"
                            value={searchQuery}
                            onChange={(e) => {
                              const { value } = e.target;
                              setSearchQuery(value);
                              throttledSearch(value, groupId);
                            }}
                          />
                        </div>
                        {searchResult && (
                          <SearchTypePills
                            activeType={activeType}
                            accentColor={accentColor}
                            handleTypeClick={handleTypeClick}
                            enabledTypes={getEnabledTypes(searchResult)}
                          />
                        )}
                      </div>
                      {searchResult ? (
                        getEnabledTypes(searchResult).length ? (
                          <div className="flex flex-col flex-1 relative">{renderSearchResult()}</div>
                        ) : (
                          <div className="flex flex-col h-full align-center justify-center">
                            <ExclamationIcon className="w-16 text__description" />
                            <p className="mt-2 text-center text__description">
                              No search results found! <br />
                              Please try typing other query
                            </p>
                          </div>
                        )
                      ) : isLoading ? (
                        <SearchLoadingSpinner autoHeight />
                      ) : (
                        <div className="flex flex-col h-full align-center justify-center">
                          <DocumentSearchIcon className="w-16 text__description" />
                          <p className="mt-2 text-center text__description">{$translatei18n('search_people_text')}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </TransitionChild>
              </div>
            </div>
          </Dialog>
        </Transition>
      </>
    )
  }

  const viewTypeTwo = () => {
    return(
      <div className="w-screen xs:max-w-xs w-full h-[calc(100vh-52px)]">
        <div
          className="h-full flex flex-col px-6 overflow-y-scroll w-7/12 mx-auto xs:w-full"
          ref={scrollContainerRef}
        >
          <div
            className="z-10"
            style={{
              marginLeft: '-4px', marginRight: '-4px', paddingLeft: '4px', paddingRight: '4px',
            }}
          >
            <div className="flex items-start justify-between pt-6">
              <h3 className={classNames('text-lg font-medium text__title', primaryFont)}>
                {$translatei18n('Search')}
              </h3>
              <div className="ml-3 h-7 flex items-center">
                <button
                  type="button"
                  className="rounded-md focus:outline-none focus:ring-0 focus:ring-offset-0"
                  onClick={closeSearchDrawer}
                  id="closeBtn"
                >
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-6 w-6 main__icon" aria-hidden="true" />
                </button>
              </div>
            </div>
            {/* search input */}
            <div className="relative mt-6 mb-1">
              <span className="absolute inset-y-0 left-0 flex items-center ltr:pl-2 rtl:pr-2">
                <SearchIcon className="w-5 h-5 text__description main__icon" />
              </span>
              <input
                type="text"
                placeholder={$translatei18n('Search')}
                className="block ltr:pl-9 rtl:pr-9 ltr:pr-4 rtl:pl-4  py-2 w-full sm:text-sm rounded-md text__body input"
                value={searchQuery}
                onChange={(e) => {
                  const { value } = e.target;
                  setSearchQuery(value);
                  throttledSearch(value, groupId);
                }}
              />
            </div>
            {searchResult && (
              <SearchTypePills
                activeType={activeType}
                accentColor={accentColor}
                handleTypeClick={handleTypeClick}
                enabledTypes={getEnabledTypes(searchResult)}
              />
            )}
          </div>
          {searchResult ? (
            getEnabledTypes(searchResult).length ? (
              <div className="flex flex-col flex-1 relative">{renderSearchResult()}</div>
            ) : (
              <div className="flex flex-col h-full align-center justify-center">
                <ExclamationIcon className="w-16 text__description" />
                <p className="mt-2 text-center text__description">
                  No search results found! <br />
                  Please try typing other query
                </p>
              </div>
            )
          ) : isLoading ? (
            <SearchLoadingSpinner autoHeight />
          ) : (
            <div className="flex flex-col h-full align-center justify-center">
              <DocumentSearchIcon className="w-16 text__description" />
              <p className="mt-2 text-center text__description">{$translatei18n('search_people_text')}</p>
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <>
      {page ? viewTypeTwo() : viewTypeOne()}
    </>
  );
}

export function SearchLoadingSpinner({ autoHeight }) {
  return (
    <div className={`p-6 flex align-center justify-center ${autoHeight ? 'flex-1' : ''}`}>
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-700" />
    </div>
  );
}

export default GlobalSearch;
