import React from 'react'

 const LockIcon = ({classNames, strokeStyle, id}) => {
  return (
<svg id="{id}" xmlns="http://www.w3.org/2000/svg" classname="{classNames}" width={12} height={12} viewBox="0 0 12 12" fill="none">
  <path classname="{strokeStyle}" d="M3.40381 4.26556V3C3.40381 1.89543 4.29924 1 5.40381 1H6.59628C7.70085 1 8.59628 1.89543 8.59628 3V4.26556" stroke="#475569" strokeWidth="1.2" />
  <rect classname="{strokeStyle}" x="1.5" y="4.26556" width={9} height="6.73444" rx="1.81219" stroke="#475569" strokeWidth="1.2" />
  <circle classname="{strokeStyle}" cx="5.86133" cy="8.41199" r="0.75" fill="#475569" />
</svg>

  )
}

export default LockIcon
