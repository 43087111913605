import { all } from 'redux-saga/effects';
import authSaga from './authSaga';
import communitySaga from './communitySaga';
import onboardingSaga from './onboardingSaga';
import channelSaga from './channelSaga';
import forumSaga from './forumSaga';
import textSaga from './textSaga';
import rolesSaga from './rolesSaga';
import userSaga from './bookmarkSaga';
import calendarEventSaga from './calendarEventSaga';
import userProfileSaga from './userSaga';
import ssoSaga from './ssoSaga';
import privateChatSaga from './privateChatSaga';
import leaderboardSaga from './leaderboardSaga';
import shopSaga from './shopSaga';
import publicSaga from './publicSaga';
import plansSaga from './plansSaga';
import mixpanelSaga from './mixpanelSaga';

export default function* rootSaga() {
  yield all([
    authSaga(),
    onboardingSaga(),
    communitySaga(),
    channelSaga(),
    leaderboardSaga(),
    forumSaga(),
    textSaga(),
    rolesSaga(),
    userSaga(),
    calendarEventSaga(),
    userProfileSaga(),
    ssoSaga(),
    privateChatSaga(),
    shopSaga(),
    publicSaga(),
    plansSaga(),
    mixpanelSaga(),
  ]);
}
