import server from '../api/server';

export const userForumBookmarks = (data) => server.get('/users/bookmarks/forums', data);

export const hashtagForumService = async (community_id, group_id, hashtag, page) => await new Promise((resolve, reject) => {
  server
    .get(`/communities/${community_id}/groups/${group_id}/hashtag_posts?query=${encodeURIComponent('#' + hashtag?.toLowerCase())}&page=${page}`)
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch(reject);
});

export const userResourceBookmarks = (data) => server.get('/users/bookmarks/resources', data);

export const userBookmarkForumUpVoteService = async ({
  community_id, channel_id, post_id, group_id,
}) => await new Promise((resolve, reject) => {
  server
    .put(`/communities/${community_id}/groups/${group_id}/channels/${channel_id}/forum_posts/${post_id}/upvote`, {
      forum_post_comment: {
        vote_flag: true,
      },
    })
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch(reject);
});

export const userBookmarkForumDownVoteService = async ({
  community_id, channel_id, post_id, group_id,
}) => await new Promise((resolve, reject) => {
  server
    .put(`/communities/${community_id}/groups/${group_id}/channels/${channel_id}/forum_posts/${post_id}/downvote`, {
      forum_post_comment: {
        vote_flag: true,
      },
    })
    .then((response) => {
      const { data } = response;
      resolve(data);
    })
    .catch(reject);
});
