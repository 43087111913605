import { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { SEND_INVITE_TRIGGERED_FROM } from '../../../../constants';

const useInviteManagement = () => {
  // create invite specific vars
  const [createInviteOpen, setCreateInviteOpen] = useState(false);

  const handleCreateInviteDrawer = (value) => {
    if(document.querySelector('[data-id="zsalesiq"]')) {
      if (value) {
        document.querySelector('[data-id="zsalesiq"]').style.display = 'none';
      } else {
        document.querySelector('[data-id="zsalesiq"]').style.display = 'block';
      }
    }

    if(isMobile) {
      const overviewEle = document.getElementById("overview-wrapper");
      const settingsEle = document.getElementById("settings-wrapper");
      const overviewRect = overviewEle?.getBoundingClientRect();
      const settingsRect = settingsEle?.getBoundingClientRect();
      if(overviewEle && overviewRect?.y !== 0) {
        overviewEle.style.transform = "translateY(56px)";
      }
      if(settingsEle && settingsRect?.y !== 56) {
        settingsEle.style.transform = "translateY(56px)";
      }
    }

    setCreateInviteOpen(value);
  };

  // send invite specific vars
  const [sendInviteOpen, setSendInviteOpen] = useState(false);
  const [createdInviteDetails, setCreatedInviteDetails] = useState(null);

  const handleSendInviteDrawer = (value, inviteDetails) => {
    if(document.querySelector('[data-id="zsalesiq"]')) {
      if (value) {
        document.querySelector('[data-id="zsalesiq"]').style.display = 'none';
      } else {
        document.querySelector('[data-id="zsalesiq"]').style.display = 'block';
      }
    }
    if(value === false) {
      sessionStorage.removeItem(SEND_INVITE_TRIGGERED_FROM);
    }
    setSendInviteOpen(value);
    setCreatedInviteDetails(inviteDetails);
  };

  return {
    createInviteOpen,
    handleCreateInviteDrawer,
    sendInviteOpen,
    createdInviteDetails,
    handleSendInviteDrawer,
  };
};

export default useInviteManagement;
