// /* eslint-disable import/no-cycle */
// /* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
let AgoraRTC = null;
(async () => {
  AgoraRTC = (await import('agora-rtc-sdk-ng')).default;
})();

import server from '../api/server';
import { store } from '../store/configureStore';
import { captureException, LOGGER_JAVASCRIPT } from '../services/SentryService';
import _, { set } from 'lodash';
import { setAgoraUID, setAgoraUIDShare, setMutedIds } from '../store/actions/authActions';
import { closeChannelConnection, initialiseTextChannel } from '../store/actions/textActions';
import { updateAudioState } from '../store/actions/audioActions';
const AGORA_APPID = process.env.REACT_APP_AGORA_APPID;
import { MediaPermissionsErrorType } from 'mic-check';
// import { setStageAudioPermissionAccepted, setStageVideoPermissionAccepted } from '../store/actions/userActions';
import { requestAudioPermissions, requestVideoPermissions } from 'mic-check/lib/requestMediaPermissions';
import AnalyticsService from './AnalyticsService';

class AudioChannelService {
  screenClient = null;
  audioTrack = null;
  videoTrack = null;
  screenVideoTrack = null;
  screenAudioTrack = null;
  engine = null;

  token = null;
  screenShareToken = null;

  users = [];

  speakers = [];

  localId = '';

  mutedIds = [];

  user = '';

  channelId = '';

  textChannel = null;

  host = true;

  isVideoEnabled = false;

  constructor(props) {
    this.audioTrack = props.audioTrack;

    this.engine = props.engine;
    this.token = props.token;
    this.user = props.user;
    this.channelId = props.channelId;
    this.host = props.host;
    this.textChannel = props.textChannel;
  }

  initialization = async (callback) => {
    this.engine.on('user-published', async (user, mediaType) => {
      await this.engine.subscribe(user, mediaType);
      const audio = store.getState().audio;
      const remoteUsers = this.engine.remoteUsers;
      if (audio.deafen === true) {
        if (remoteUsers.length > 0) {
          remoteUsers.map((user) => {
            if (user.audioTrack !== undefined && user.audioTrack !== null) {
              const { trackMediaType, _volume } = user.audioTrack;
              if (trackMediaType === 'audio') {
                if (_volume > 0) {
                  user.audioTrack.setVolume(0);
                } else {
                  user.audioTrack.setVolume(100);
                }
              }
            }
          });
        }
      }

      if (mediaType === 'video') {
        const newUser = _.cloneDeep(user);
        const localRemote = _.cloneDeep(audio.remoteUsers);
        const index = localRemote.findIndex((x) => x.uid === user.uid);

        if (index !== -1) {
          localRemote[index] = newUser;
        }
        store.dispatch(updateAudioState({ remoteUsers: localRemote }));
      }
      if (mediaType === 'audio') {
        user.audioTrack?.play();
        callback({ mutedId: user.uid, mutedState: false });
      }
    });

    this.engine.on('user-unpublished', (user, type) => {
      const audio = store.getState().audio;
      if (type === 'audio') {
        user.audioTrack?.stop();
        callback({ mutedId: user.uid, mutedState: true });
      }
      if (type === 'video') {
        const newUser = _.cloneDeep(user);
        const localRemote = _.cloneDeep(audio.remoteUsers);
        const index = localRemote.findIndex((x) => x.uid === user.uid);

        if (index !== -1) {
          localRemote[index] = newUser;
        }
        store.dispatch(updateAudioState({ remoteUsers: localRemote }));
      }
    });

    this.engine.on('user-left', (user) => {
      const audio = store.getState().audio;
      const localRemote = _.cloneDeep(audio.remoteUsers);
      const index = localRemote.findIndex((x) => x.uid === user.uid);
      if (index !== -1) {
        localRemote.splice(index, 1);
      }
      store.dispatch(updateAudioState({ remoteUsers: localRemote }));
    });

    this.engine.on('user-joined', (user) => {
      const audio = store.getState().audio;
      const localRemote = _.cloneDeep(audio.remoteUsers);
      const newUser = _.cloneDeep(user);
      localRemote.push(newUser);
      store.dispatch(updateAudioState({ remoteUsers: localRemote }));
      setTimeout(function () {
        callback({ mutedId: user.uid, mutedState: user._audio_muted_ });
      }, 500);
    });

    this._addListeners(callback);
  };

  _addListeners = (callback) => {
    this.engine.addListener('token-privilege-will-expire', async (error) => {
      try {
        const { channelData } = await store.getState().text;
        const channelRoomToken = channelData?.room_token || null;
        if (channelRoomToken) {
          const data = await server.get(`/channels/${this.channelId}/join`, {
            params: {
              room_token: channelRoomToken,
            },
          });
          if (data.status == 200) {
            this.token = data.data.token;
            await this.engine.renewToken(this.token);
          } else {
            console.log('Something went wrong !');
          }
        }
      } catch (err) {
        captureException(`TokenPrivilegeWillExpire Error: ${err}`, LOGGER_JAVASCRIPT);
        console.error('Agora side token-privilege-will-expire =>', err);
      }
    });

    this.engine.addListener('RemoteAudioStateChanged', async (uid, state) => {
      try {
        callback({ mutedId: uid, mutedState: state });
      } catch (error) {
        console.error('RemoteAudioStateChanged =>', error);
        captureException(`RemoteAudioStateChanged Error: ${error}`, LOGGER_JAVASCRIPT);
      }
    });

    this.engine.addListener('UserJoined', (uid, elapsed) => {});

    this.engine.addListener('user-info-updated', async (uid, msg) => {
      try {
        // const data = await this.engine.getUserInfoByUid(uid);
        // if (data.userAccount === null) {
        //   console.log('Connection has problem please reconnect');
        // } else {
        //   const exist = this.users.find((x) => x.uid == uid);
        //   if (!exist) {
        //     this.users = [...this.users, data];
        //   }
        // }
      } catch (error) {
        console.error('UserInfoUpdated =>', error);
        captureException(`UserInfoUpdated Error: ${error}`, LOGGER_JAVASCRIPT);
      }
    });

    this.engine.addListener('JoinChannelSuccess', async (channel, uid, elapsed) => {
      try {
        const data = await this.engine.getUserInfoByUid(uid);
        if (this.host) {
          this.textChannel.push('voice:start', { uid });
        }
        if (store.store.getState().auth.agoraUID === null) {
          store.store.dispatch(setAgoraUID(uid));
        }
        const exist = this.users.find((x) => x.uid == uid);
        this.localId = uid;
        if (!exist) {
          this.users = [...this.users, data];
        }
        callback({ loader: false });
      } catch (error) {
        console.error('JoinChannelSuccessError', { error });
        captureException(`JoinChannelSuccess Error: ${error}`, LOGGER_JAVASCRIPT);
      }
    });

    this.engine.addListener('LeaveChannel', (uid, stats) => {
      this.users = [];
    });

    this.engine.addListener('ClientRoleChanged', (old, newrole) => {
      if (newrole === 2) {
        store.store.dispatch(updateAudioState({ granted: false, isAskToSpeakBtnDisabled: false }));
      }
    });

    this.engine.addListener('AudioVolumeIndication', async (speakers, totalVolume) => {
      if (speakers && speakers.length > 0) {
        this.speakers = speakers;
      }
    });

    this.engine.addListener('Error', async (error) => {
      if (error === 109 || error === 110) {
        const { channelData } = await store.getState().text;
        const channelRoomToken = channelData?.room_token || null;
        if (channelRoomToken) {
          const data = await server.get(`/channels/${this.channelId}/join`, {
            params: {
              room_token: channelRoomToken,
            },
          });
          if (data.status === 200) {
            this.token = data.data.token;
            await this.engine.renewToken(this.token);
          } else {
            console.log('Something went wrong !');
          }
        }
      } else {
        console.log({ error });
      }
      captureException(`JoinChannelSuccess Error: ${error}`, LOGGER_JAVASCRIPT);
    });
    this.engine.addListener('crypt-error', async (error) => {
      console.error('Agora crypt-error =>', error);
    });
    this.engine.addListener('crypt-error', async (error) => {
      console.error('Agora exception =>', error);
    });
    this.engine.addListener('connection-state-change', async (curState, revState, reason) => {
      const mixpanelProperties = sessionStorage.getItem('MixpanelSessionProperties') || {};
      switch (curState) {
        case 'DISCONNECTED':
          if (reason === 'SERVER_ERROR' || reason === 'NETWORK_ERROR') {
            const audioReducer = await store.getState().audio;
            store.dispatch(
              closeChannelConnection(
                () => {
                  if (audioReducer.connected === true) {
                    console.error('From Audio Service initialiseTextChannel calling');
                    store.dispatch(
                      initialiseTextChannel(this.channelId, { type: 'stage', shouldInitialiseVoice: true }),
                    );
                  }
                },
                true,
                audioReducer.connected,
              ),
            );
          }
          console.error(`Agora = > ConnectionStateChanged > ${curState} revState > ${revState} reason > ${reason}`);
          console.log(`Agora = > ConnectionStateChanged > ${curState} revState > ${revState} reason > ${reason}`);
          console.log('ConnectionStateChanged > Disconnected');
          sessionStorage.setItem('StageIsConnected', JSON.stringify(false));
          AnalyticsService.logMixpanelEvent('Stage Connected Duration', JSON.parse(mixpanelProperties));
          break;
        case 'CONNECTING':
          console.log('ConnectionStateChanged > Connecting...');
          break;
        case 'CONNECTED':
          console.log('ConnectionStateChanged > Connected');
          sessionStorage.setItem('StageIsConnected', JSON.stringify(true));
          AnalyticsService.logMixpanelEvent('Stage Connected', JSON.parse(mixpanelProperties));
          AnalyticsService.logMixpanelTimingEvent('Stage Connected Duration');
          break;
        case 'RECONNECTING':
          console.log('ConnectionStateChanged > Reconnecting...');
          break;
        case 'DISCONNECTING':
          console.log('ConnectionStateChanged > DISCONNECTING...');
          break;

        default:
          console.log(`ConnectionStateChanged > ${curState} revState > ${revState} reason > ${reason}`);
          return null;
      }
    });

    this.engine.addListener('RejoinChannelSuccess', async (data) => {});

    this.engine.addListener('Warning', (Warning) => {});

    this.engine.addListener('UserOffline', async (uid, reason) => {
      const data = await this.engine.getUserInfoByUid(uid);
      const name = data.userAccount;
      this.users = this.users.filter((user) => user.uid !== uid);
    });

    // TODO HANDLE CALLBACKS VIDEO MODULES LISTENERS
    this.engine.addListener('UserEnableVideo', (data) => {});

    this.engine.addListener('RemoteVideoStateChanged', async (uid, state) => {
      try {
        callback({ videoMutedId: uid, videoMutedState: state });
      } catch (error) {
        console.error('RemoteVideoStateChanged =>', error);
        captureException(`RemoteVideoStateChanged Error: ${error}`, LOGGER_JAVASCRIPT);
      }
    });
  };

  destroyChannel = async () => {
    // await this.engine.destroy();
  };

  registerUser = async () => {
    // await this.engine.registerLocalUserAccount(AGORA_APPID, this.user);
  };

  startEchoTest = async () => {
    await this.engine.startEchoTest(10);
  };

  joinChannel = async (channelId) => {
    try {
      await this.engine.leave();
      if (this.host) {
        await this.engine.setClientRole('host');
      } else {
        await this.engine.setClientRole('audience', { level: 1 });
      }

      const data = await this.engine.join(AGORA_APPID, channelId.toString(), this.token, null).then(async (uid) => {
        try {
          if (this.host) {
            this.textChannel.push('voice:start', { uid });
          }
          const agoraUID = store.getState().auth.agoraUID;

          //if (agoraUID) {
          store.dispatch(setAgoraUID(uid));
          //  }
          const exist = this.users.find((x) => x.uid == uid);
          this.localId = uid;
          if (!exist) {
            this.users = [...this.users, { uid: uid, userAccount: uid }];
          }
          store.dispatch(updateAudioState({ connected: true }));
        } catch (error) {
          captureException(`JoinChannelSuccess Error: ${error}`, LOGGER_JAVASCRIPT);
          console.error('JoinChannelSuccessError', { error });
        }
      });
      this.engine.enableAudioVolumeIndicator();
      // if (this.host && this.audioTrack) {
      if (this.host) {
        try {
          const audioStage = store.getState().audio;
          const isStageAudioPermissionAccepted = store.getState().user.stageAudioPermissionAccepted;
          let audioPermissionPrompt = true;
          // if (!isStageAudioPermissionAccepted) {
          //   store.dispatch(updateAudioState({ microphoneAllowed: false }));
          // }

          setTimeout(() => {
            if (audioPermissionPrompt) {
              store.dispatch(updateAudioState({ microphoneAllowed: false }));
            }
          }, 1000);
          await requestAudioPermissions();
          audioPermissionPrompt = false;
          this.audioTrack = await AgoraRTC.createMicrophoneAudioTrack();
          await this.engine.publish([this.audioTrack]);
          store.dispatch(updateAudioState({ audioTrack: this.audioTrack, microphoneAllowed: true }));
          setTimeout(() => {
            this.muteAudio(!audioStage.mute);
          }, 500);
          // store.dispatch(setStageAudioPermissionAccepted(true));
        } catch (error) {
          console.error('Audio --> ', error);
          const { type } = error;
          if (
            type === MediaPermissionsErrorType.SystemPermissionDenied ||
            type === MediaPermissionsErrorType.UserPermissionDenied
          ) {
            store.dispatch(updateAudioState({ microphoneAllowed: false }));
            // store.dispatch(setStageAudioPermissionAccepted(false));
          }
        }
        await this.engine.publish([this.audioTrack]);
      }
    } catch (error) {
      captureException(`Join Channel error Error: ${error}`, LOGGER_JAVASCRIPT);
      console.error('Join Channel error', error.message);
    }
  };

  leaveChannel = async () => {
    this.localId = '';
    this.users = [];
    this.leaveShareChannel();
    if (this.audioTrack !== null && this.audioTrack !== undefined) {
      await this.audioTrack.setEnabled(false);
      await this.engine.unpublish([this.audioTrack]);
    }
    if (this.videoTrack !== null && this.videoTrack !== undefined) {
      await this.videoTrack.setEnabled(false);
      await this.videoTrack.close();
      await this.engine.unpublish([this.videoTrack]);
    }

    await this.engine.leave();
  };

  switchMicrophone = async (openMicrophone) => {
    await this.engine.enableLocalAudio(openMicrophone);
  };

  switchSpeakerphone = async (enableSpeakerphone) => {
    await this.engine.setEnableSpeakerphone(enableSpeakerphone);
  };

  switchClientRole = async (type, localAudioTrack, localVideoTrack, micMute, cameraMute) => {
    if (type == 'broadcaster') {
      await this.engine.setClientRole('host');
      if (
        localVideoTrack !== null &&
        localVideoTrack !== undefined &&
        localAudioTrack !== null &&
        localAudioTrack !== undefined
      ) {
        this.audioTrack = localAudioTrack;
        this.videoTrack = localVideoTrack;
        await this.audioTrack.setEnabled(!micMute);
        await this.videoTrack.setEnabled(!cameraMute);
        if (this.audioTrack.enabled === true && this.videoTrack.enabled === true) {
          await this.engine.publish([this.audioTrack, this.videoTrack]);
        } else if (this.audioTrack.enabled === true) {
          await this.engine.publish([this.audioTrack]);
        } else if (this.videoTrack.enabled === true) {
          await this.engine.publish([this.videoTrack]);
        }
      }
      if (localVideoTrack !== null && localVideoTrack !== undefined) {
        this.videoTrack = localVideoTrack;
        await this.videoTrack.setEnabled(!cameraMute);
        if (this.videoTrack.enabled === true) {
          await this.engine.publish([this.videoTrack]);
        }
      }
      if (localAudioTrack !== null && localAudioTrack !== undefined) {
        this.audioTrack = localAudioTrack;
        await this.audioTrack.setEnabled(!micMute);
        if (this.audioTrack.enabled === true) {
          await this.engine.publish([this.audioTrack]);
        }
      }
    } else {
      if (this.audioTrack && this.videoTrack) {
        await this.engine.unpublish([this.audioTrack, this.videoTrack]);
      } else {
        await this.engine.unpublish([this.audioTrack]);
      }
      await this.engine.setClientRole('audience', { level: 1 });
    }
  };

  muteAudio = async (mute) => {
    try {
      await this.audioTrack.setEnabled(mute);
      if (mute === true) {
        await this.engine.publish([this.audioTrack]);
      }
    } catch (error) {
      captureException(error, LOGGER_JAVASCRIPT);
    }
  };

  muteOtherAudios = async (muteOthers) => {
    try {
      const remoteUsers = await this.engine.remoteUsers;
      if (remoteUsers.length > 0) {
        remoteUsers.map(async (user) => {
          if (user.audioTrack !== undefined && user.audioTrack !== null) {
            const { trackMediaType, _volume } = user.audioTrack;
            if (trackMediaType === 'audio') {
              if (_volume > 0) {
                await user.audioTrack.setVolume(0);
              } else {
                await user.audioTrack.setVolume(100);
              }
            }
          }
        });
      }
    } catch (error) {
      console.error('muteOtherAudios error =>', error);
      captureException(error, LOGGER_JAVASCRIPT);
    }
  };

  renewAgoraToken = async (token, channelId) => {
    this.token = token;
    try {
      if (channelId) {
        const data = await server.get(`/channels/${channelId}/join`);
        this.token = data.data.token;
      } else {
        await this.engine.renewToken(token);
      }
    } catch (error) {}
  };

  getSpeakingStatus = (uid) => {
    const exist = this.speakers.find((x) => x.uid === uid);
    if (exist) {
      return true;
    }
    const local = this.speakers.find((x) => x.vad === 1);
    if (local !== undefined && this.localId === uid) {
      return true;
    }
    return false;
  };

  getSpeakingBackground = (uid) => {
    if (this.getSpeakingStatus(uid)) return '#5BC388';
    return 'rgba(255, 255, 255, 0.04)';
  };

  // VIDEO MODULES

  enableVideo = async () => {
    try {
      if (this.isVideoEnabled) return;
      this.isVideoEnabled = true;

      await this.engine.setVideoEncoderConfiguration({
        dimensions: { width: 320, height: 240 },
        frameRate: 30,
      });

      await this.engine.enableVideo();
    } catch (error) {
      captureException(error, LOGGER_JAVASCRIPT);
    }
  };

  disableVideo = async () => {
    try {
      if (!this.isVideoEnabled) return;
      this.isVideoEnabled = false;
      await this.engine.disableVideo();
    } catch (error) {
      captureException(error, LOGGER_JAVASCRIPT);
    }
  };

  toggleVideo = async (status) => {
    try {
      const cameras = await AgoraRTC.getCameras();
      if (cameras.length > 0) {
        const state = await store.getState().audio;
        if (state.videoTrack !== null && state.videoTrack !== undefined) {
          this.videoTrack = state.videoTrack;
        } else {
          // const isStageVideoPermissionAccepted = store.getState().user.stageVideoPermissionAccepted;
          // if(!isStageVideoPermissionAccepted){
          //   store.dispatch(updateAudioState({ cameraAllowed: false }));
          // }
          let videoPermissionPrompt = true;
          setTimeout(() => {
            if (videoPermissionPrompt) {
              store.dispatch(updateAudioState({ cameraAllowed: false }));
            }
          }, 1500);
          await requestVideoPermissions();
          videoPermissionPrompt = false;
          store.dispatch(updateAudioState({ cameraAllowed: true }));
          const cameraTrack = await AgoraRTC.createCameraVideoTrack();
          this.videoTrack = cameraTrack;
        }

        if (status === true) {
          if (this.videoTrack !== null && this.videoTrack !== undefined) {
            await this.videoTrack.setEnabled(status);
            await this.engine.publish(this.videoTrack);
            store.dispatch(updateAudioState({ videoTrack: this.videoTrack }));
          }

          // store.dispatch(setStageVideoPermissionAccepted(true));
        } else {
          this.unPublishVideo();

          // await this.videoTrack.setEnabled(false);
          // await this.videoTrack.close();
          // await this.engine.unpublish(this.videoTrack);
          // await store.dispatch(updateAudioState({ videoTrack: null }));
        }
      }
    } catch (error) {
      console.error('cameras =>', error);
      captureException(error, LOGGER_JAVASCRIPT);
      const { type, code } = error;
      if (
        type === MediaPermissionsErrorType.SystemPermissionDenied ||
        type === MediaPermissionsErrorType.UserPermissionDenied ||
        type === MediaPermissionsErrorType.CouldNotStartVideoSource ||
        code === 'PERMISSION_DENIED'
      ) {
        store.dispatch(updateAudioState({ cameraAllowed: false }));
      }
    }
  };

  toggleScreenShare = async (screenTrack, status) => {
    try {
      const state = await store.getState().audio;
      const { channelData } = await store.getState().text;

      let screenTrackLocal = null;
      let uid = null;
      if (state.screenVideoTrack === null || state.screenVideoTrack === null) {
        this.screenClient = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' });
        await this.screenClient.setClientRole('host');

        const data = await server.get(`/channels/${this.channelId}/join`, {
          params: {
            room_token: channelData?.room_token,
          },
        });
        this.screenShareToken = data.data.token;
        uid = await this.screenClient.join(AGORA_APPID, this.channelId, data.data.token);
        screenTrackLocal = screenTrack;
      } else {
        screenTrackLocal = state.screenVideoTrack;
      }

      if (screenTrackLocal instanceof Array) {
        this.screenVideoTrack = screenTrackLocal[0];
        this.screenAudioTrack = screenTrackLocal[1];
      } else {
        this.screenVideoTrack = screenTrackLocal;
      }

      // screenTrackLocal.on('track-ended', async () => {
      //   this.leaveShareChannel();
      // });

      this.screenVideoTrack.on('track-ended', async () => {
        this.leaveShareChannel();
      });
      if (status === true) {
        try {
          if (
            this.screenVideoTrack !== null &&
            this.screenVideoTrack !== undefined &&
            this.screenAudioTrack !== null &&
            this.screenAudioTrack !== undefined
          ) {
            await this.screenClient.publish([this.screenVideoTrack, this.screenAudioTrack]);
          } else {
            await this.screenClient.publish([this.screenVideoTrack]);
          }
        } catch (error) {
          captureException(error, LOGGER_JAVASCRIPT);
          console.log('error publish =>', error.message);
        }

        this.textChannel.push('screen_share:toggle', { ss_uid: uid });
        store.dispatch(setAgoraUIDShare(uid));
        store.dispatch(
          updateAudioState({ screenVideoTrack: this.screenVideoTrack, screenAudioTrack: this.screenAudioTrack }),
        );
      } else {
        this.leaveShareChannel();
      }
      this._addListenersForScreen();
    } catch (error) {
      captureException(error, LOGGER_JAVASCRIPT);
      console.error('screen share error =>', error.message);
    }
  };
  leaveShareChannel = async () => {
    const state = await store.getState().audio;
    if (this.screenVideoTrack !== undefined && this.screenVideoTrack !== null) {
      await this.screenVideoTrack.close();
      if (
        this.screenVideoTrack !== null &&
        this.screenVideoTrack !== undefined &&
        this.screenAudioTrack !== null &&
        this.screenAudioTrack !== undefined
      ) {
        await this.screenClient.unpublish([this.screenVideoTrack, this.screenAudioTrack]);
      } else {
        await this.screenClient.unpublish([this.screenVideoTrack]);
      }

      this.textChannel.push('screen_share:toggle', { ss_uid: null });
      await store.dispatch(updateAudioState({ screenVideoTrack: null, screenAudioTrack: null }));
      await this.screenClient.leave();
    }
  };

  unPublishVideo = async () => {
    const state = await store.getState().audio;
    if (state.videoTrack !== null && state.videoTrack !== undefined) {
      await state.videoTrack.setEnabled(false);
      await state.videoTrack.close();
      await this.engine.unpublish([state.videoTrack]);
      if (this.videoTrack !== null && this.videoTrack !== undefined) {
        await this.videoTrack.setEnabled(false);
        await this.videoTrack.close();
        await this.engine.unpublish([this.videoTrack]);
      }
      await store.dispatch(updateAudioState({ isUserVideoActive: false, videoTrack: null }));
    }
    // if (this.videoTrack !== null && this.videoTrack !== undefined) {
    //   await this.videoTrack.setEnabled(false);
    //   await this.videoTrack.close();
    //   await this.engine.unpublish([this.videoTrack]);
    //   await store.dispatch(updateAudioState({ isUserVideoActive: false, videoTrack: null }));
    // }

    // this.leaveShareChannel();
  };

  unPublishAudio = async () => {
    if (this.audioTrack !== null && this.audioTrack !== undefined) {
      await this.audioTrack.setEnabled(false);
      // await this.videoTrack.close();
      await this.engine.unpublish([this.audioTrack]);
      await store.dispatch(updateAudioState({ audioTrack: null }));
    }
    // this.leaveShareChannel();
  };

  _addListenersForScreen = (callback) => {
    this.screenClient.addListener('token-privilege-will-expire', async (error) => {
      try {
        const { channelData } = await store.getState().text;
        const channelRoomToken = channelData?.room_token || null;
        if (channelRoomToken) {
          const data = await server.get(`/channels/${this.channelId}/join`, {
            params: {
              room_token: channelRoomToken,
            },
          });
          if (data.status == 200) {
            this.screenShareToken = data.data.token;
            await this.screenClient.renewToken(this.screenShareToken);
          } else {
            console.log('Something went wrong !');
          }
        }
      } catch (err) {
        console.error('Screen share token-privilege-will-expire =>', err);
        captureException(`Screenshare token-privilege-will-expire Error: ${error}`, LOGGER_JAVASCRIPT);
      }
    });
  };
}

export default AudioChannelService;
