import _ from 'lodash';
import { INBOX_TYPES } from '../../constants';
import {
  AUTH_LOG_OUT_SUCCESS, LIST_INBOX_USERS_SUCCESS, SET_ACTIVE_PRIVATE_ROOM,
  ADD_BLOCKED_USER_IDS_PRIVATE_CHAT,
  PRIVATE_CHAT_CHANNEL_CONNECTION_SUCCESS,
  REMOVE_BLOCKED_USER_IDS_PRIVATE_CHAT,
  RESET_PRIVATE_CHAT_STATE,
  SET_EARLIER_MESSAGES_PRIVATE_CHAT,
  SET_LAST_MESSAGE_PRIVATE_CHAT,
  SET_LOAD_MORE_PRIVATE_CHAT,
  SET_PINNED_MESSAGE_PRIVATE_CHAT,
  SET_TEXT_MESSAGES_PRIVATE_CHAT,
  UPDATE_PRIVATE_CHAT_STATE,
  SET_NOTIFICATION_STATUS_PRIVATE_CHAT,
  SET_PRIVATE_CHAT_LOADING,
  SET_PRIVATE_INBOX_TYPE,
  CREATE_PRIVATE_TEXT_ROOM_SUCCESS,
  REQUEST_JOIN_TEXT_ROOM_SUCCESS,
  SET_PRIVATE_TEXT_ROOM_REQUEST_COUNT,
  UPDATE_PRIVATE_CHAT_UNREAD_STATUS,
  LIST_INBOX_USERS_REQUEST,
  REMOVE_ACTIVE_PRIVATE_ROOM_AND_RESET,
  RESET_PRIVATE_CHAT_TEXT_ROOM_STATE,
  SET_PRIVATE_ROOMS,
  SET_DM_USERS,
} from '../actions/actionTypes';

const initialState = {
  privateTextRooms: [],
  privateTextRoomNextId: null,
  activePrivateRoom: null,
  inboxType: INBOX_TYPES.INBOX,
  requestsCount: 0,

  channelLoading: true,
  isConnected: false,
  roomId: null,
  channelData: {},
  chatChannel: null,
  channelDetails: {},
  messages: [],
  lastMessageId: null,
  canLoadMore: false,
  isTypingText: '',
  pinnedMessage: null,
  isNotificationMuted: false,
  channelConfigs: {
    canSendMessage: true,
    canStartVoice: true,
    canManageSpeakers: true,
    canManageMessages: true,
    canManageUsers: true,
    canManageSettings: true,
    canViewStage: true,
  },
  redirect: false,
  privateTextRoomLoading: true,
  dmUsers: [],
};

const reducer = (state = initialState, action) => {
  let newInboxUsers;
  let updatedBlockedUserIds;
  let isExistPrivateRoom;
  let privateTextRoomId;
  let newPrivateTextRooms;

  switch (action.type) {
    case AUTH_LOG_OUT_SUCCESS:
      return initialState;

    case LIST_INBOX_USERS_REQUEST:
      if (action?.payload?.nextPageId) {
        return state;
      }

      return { ...state, privateTextRoomLoading: true };

    case LIST_INBOX_USERS_SUCCESS:
      if (action?.payload?.reset) {
        newInboxUsers = action.payload.data;
      } else {
        newInboxUsers = _.uniqBy([...state.privateTextRooms, ...action.payload.data], 'id');
      }

      return {
        ...state,
        privateTextRooms: newInboxUsers,
        privateTextRoomNextId: action.payload.nextPageId,
        requestsCount: action.payload.requestCount,
        privateTextRoomLoading: false,
      };

    case SET_PRIVATE_TEXT_ROOM_REQUEST_COUNT:
      return {
        ...state,
        requestsCount: action.payload,
      };

    case SET_ACTIVE_PRIVATE_ROOM:
      return { ...state, activePrivateRoom: action?.payload };
    case PRIVATE_CHAT_CHANNEL_CONNECTION_SUCCESS:
      return {
        ...state,
        channelData: action.payload.channelData,
        chatChannel: action.payload.chatChannel,
        channelLoading: false,
        isConnected: true,
      };
    case UPDATE_PRIVATE_CHAT_STATE:
      return { ...state, ...action.payload };
    case SET_DM_USERS:
      return { ...state, dmUsers: action.payload };
    case SET_LOAD_MORE_PRIVATE_CHAT:
      return { ...state, canLoadMore: action.payload.value };
    case SET_EARLIER_MESSAGES_PRIVATE_CHAT:
      return {
        ...state,
        messages: [...state.messages, ...action.payload.messages],
        hasEarlierMessages: true,
      };
    case SET_LAST_MESSAGE_PRIVATE_CHAT:
      return { ...state, lastMessageId: action.payload.id };
    case SET_TEXT_MESSAGES_PRIVATE_CHAT:
      return { ...state, messages: action.payload.messages };
    case ADD_BLOCKED_USER_IDS_PRIVATE_CHAT:
      return { ...state, blockedUserIds: [...state.blockedUserIds, ...action.payload.value] };
    case REMOVE_BLOCKED_USER_IDS_PRIVATE_CHAT:
      updatedBlockedUserIds = state.blockedUserIds.filter(
        (id) => id !== action.payload.value,
      );
      return { ...state, blockedUserIds: updatedBlockedUserIds };
    case RESET_PRIVATE_CHAT_STATE:
      return {
        ...initialState,
        privateTextRooms: state.privateTextRooms,
        privateTextRoomNextId: state.privateTextRoomNextId,
        activePrivateRoom: state.activePrivateRoom,
        inboxType: state.inboxType,
        requestsCount: state.requestsCount,
        privateTextRoomLoading: state.privateTextRoomLoading,
      };
    case RESET_PRIVATE_CHAT_TEXT_ROOM_STATE:
      return {
        ...state,
        privateTextRooms: initialState.privateTextRooms,
        privateTextRoomNextId: initialState.privateTextRoomNextId,
        activePrivateRoom: initialState.activePrivateRoom,
        inboxType: initialState.inboxType,
        requestsCount: initialState.requestsCount,
        privateTextRoomLoading: initialState.privateTextRoomLoading,
      };
    case SET_PINNED_MESSAGE_PRIVATE_CHAT:
      return { ...state, pinnedMessage: action.payload.pinnedMessage };
    case SET_NOTIFICATION_STATUS_PRIVATE_CHAT:
      return { ...state, isNotificationMuted: action.payload.isNotificationMuted };
    case SET_PRIVATE_CHAT_LOADING:
      return { ...state, channelLoading: action.payload };
    case SET_PRIVATE_INBOX_TYPE:
      return { ...state, inboxType: action.payload };
    case CREATE_PRIVATE_TEXT_ROOM_SUCCESS:
      // Check if the conversation is exists in the text room
      privateTextRoomId = action?.payload?.response?.data?.private_text_room?.id;
      if (!privateTextRoomId) {
        return state;
      }

      isExistPrivateRoom = state.privateTextRooms.find(
        (d) => d?.id === privateTextRoomId,
      );
      if (isExistPrivateRoom) {
        return {
          ...state,
          activePrivateRoom: privateTextRoomId,
        };
      }
      return {
        ...state,
        activePrivateRoom: privateTextRoomId,
        privateTextRooms: [
          {
            ...action?.payload?.response?.data?.private_text_room,
            user: action?.payload?.response?.data
              ?.recipient[privateTextRoomId],
          },
          ...state.privateTextRooms],
      };

    case REQUEST_JOIN_TEXT_ROOM_SUCCESS:
      return {
        ...state,
        activePrivateRoom: action?.payload?.textRoomId,
        inboxType: INBOX_TYPES.INBOX,
        privateTextRooms: [
          {
            id: action?.payload?.textRoomId,
            user: action?.payload?.user,
          },
          ...state.privateTextRooms],
      };

    case UPDATE_PRIVATE_CHAT_UNREAD_STATUS:
      newInboxUsers = [...state.privateTextRooms];
      isExistPrivateRoom = newInboxUsers.find(
        (d) => d?.id === action?.payload,
      );

      if (isExistPrivateRoom) {
        isExistPrivateRoom.unread = false;
      }

      return {
        ...state,
        privateTextRooms: newInboxUsers,
      };

    case REMOVE_ACTIVE_PRIVATE_ROOM_AND_RESET:
      isExistPrivateRoom = state?.privateTextRooms?.findIndex(
        (d) => d?.id === state?.activePrivateRoom,
      );
      newPrivateTextRooms = [...state.privateTextRooms];

      if (isExistPrivateRoom >= 0) {
        newPrivateTextRooms.splice(isExistPrivateRoom, 1);
      }

      return {
        ...state,
        activePrivateRoom: null,
        privateTextRooms: newPrivateTextRooms,
      };

	  case SET_PRIVATE_ROOMS:
		console.log("action.payload", action.payload);
		return{
			...state,
			privateTextRooms: [action.payload ,...state?.privateTextRooms],
		}

    default:
      return state;
  }
};

export default reducer;
