import { Dialog, Transition } from '@headlessui/react';
import {
  BellIcon,
  ExclamationIcon,
  PencilIcon,
  PlusCircleIcon,
  PlusIcon,
  TagIcon,
  TrashIcon,
  UserIcon,
  XIcon,
} from '@heroicons/react/outline';
import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import { classNames } from '../../constants/utils';
import usePrevious from '../../hooks/usePrevious';
import {
  getActiveCommunityFontsClass,
  getActiveCommunityId,
  getActiveCommunityAccentColor,
  getActiveGroupId,
  isCurrentActiveGroupIsMainGroup,
} from '../../selectors/CommunitySelectors';
import { deleteCommunitychannel } from '../../services/channelService';
import NotificationService from '../../services/notificationService';
import { deleteGroup } from '../../store/actions/communityActions';
import { DeleteModal } from '../settings/Sidebar';

const navigationAdd = [
  {
    name: $translatei18n('NewChannel'),
    href: '/group-settings/create-channel',
    icon: PlusIcon,
    current: false,
  },
  {
    name: $translatei18n('NewCategory'),
    href: '/group-settings/create-category',
    icon: PlusCircleIcon,
    current: false,
  },
  {
    name: $translatei18n('manageTags'),
    href: '/group-settings/manage-tags',
    icon: TagIcon,
    current: false,
  },
];

export default function Sidebar({ open, closeMenu }) {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const activeCommunityId = useSelector(getActiveCommunityId);
  const categories = useSelector((state) => state?.channel?.channels);
  const communityConfig = useSelector((state) => state?.community?.communityConfig);
  const groupConfig = useSelector((state) => state?.channel?.configs?.groupConfigs);
  const { primaryFont } = useSelector(getActiveCommunityFontsClass);
  const accentColor = useSelector(getActiveCommunityAccentColor);
  const isMainGroup = useSelector(isCurrentActiveGroupIsMainGroup);
  const [channelData, setChannelData] = useState({});
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const activeGroupId = useSelector(getActiveGroupId);
  const deletingGroup = useSelector((state) => state?.community?.deletingGroup);
  const prevDeletingGroup = usePrevious(deletingGroup);
  const [openDeleteConfirmationModal, setOpenDeleteConfirmationModal] = useState(false);

  const groupSettingsPath = matchPath(location.pathname, { path: '/group-settings/:group_id' });
  const groupSettingsEditPath = matchPath(location.pathname, { path: '/group-settings/:group_id/edit-permissions' });

  const group_id = groupSettingsPath?.params?.group_id || groupSettingsEditPath?.params?.group_id;

  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    if (!groupConfig?.canManageGroup) {
      handleBackNavigation();
    }
  }, [groupConfig]);

  // useEffect(() => {
  //   if (prevDeletingGroup == true && deletingGroup == false) {
  //     history.push('/');
  //   }
  // }, [prevDeletingGroup, deletingGroup]);

  const handleBackNavigation = () => {
    history.push('/');
  };

  const getGroupLink = (link, groupId) =>
    // To Append groupId to links.
    `${link?.slice(0, 15)}/${groupId}${link?.slice(15)}`;
  const handleDeleteGroup = () => {
    setDeleteLoading(true);
    dispatch(deleteGroup({ groupId: activeGroupId, setDeleteLoading }));
  };

  return (
    <div className={`${open ? 'fixed top-0 right-0 left-0 bottom-0 main--background overflow-scroll' : ''}`} style={{ zIndex: 99999 }}>
      <nav className="mt-0 flex-1 px-2 space-y-1">
        {!isMainGroup && (
          <div className="mb-5">
            <div
              className={classNames(
                'flex items-start justify-between flex-shrink-0 px-2 pt-5 pb-2 text-base font-medium text__title break-all',
                primaryFont,
              )}
            >
              <h2>{$translatei18n('GroupSettings')}</h2>
              {open && (
                <button onClick={() => closeMenu()}>
                  <XIcon className="w-6 h-6" />
                </button>
              )}
            </div>
            <Link
              to={`/group-settings/${group_id}`}
              className={classNames(
                `/group-settings/${group_id}` === location?.pathname ? `btn__primary ${primaryFont}` : 'text__body',
                'group flex items-center px-2 py-2 text-sm rounded-md mb-2',
              )}
              style={{ backgroundColor: `/group-settings/${group_id}` === location?.pathname ? accentColor : '' }}
              onClick={closeMenu}
            >
              <PencilIcon className={classNames('mr-3 flex-shrink-0 h-5')} aria-hidden="true" />
              {$translatei18n('EditGroup')}
            </Link>
            {/* <Link
            to={`/group-settings/${group_id}/edit-permissions`}
            className={classNames(
              `/group-settings/${group_id}/edit-permissions` === location?.pathname
                ? `btn__primary ${primaryFont}`
                : 'text__body',
              'group flex items-center px-2 py-2 text-sm rounded-md mb-2',
            )}
            style={{
              backgroundColor: `/group-settings/${group_id}/edit-permissions` === location?.pathname ? accentColor : '',
            }}
            onClick={closeMenu}
          >
            <UserIcon className={classNames('mr-3 flex-shrink-0 h-5')} aria-hidden="true" />
            Permissions
          </Link> */}
          </div>
        )}
        <div
          className={classNames(
            'flex items-start flex-shrink-0 px-2 pt-5 pb-2 text-base font-medium text__title',
            primaryFont,
            !isMainGroup && 'border-t divider',
          )}
        >
          {$translatei18n('AddNew')}
        </div>
        {navigationAdd.map((item) =>
          item.name === $translatei18n('NewChannel') ? (
            <span
              id={item.name}
              key={item.name}
              // to={getGroupLink(item.href, activeGroupId)}
              className={classNames(
                getGroupLink(item.href, activeGroupId) == location?.pathname
                  ? `btn__primary ${primaryFont}`
                  : ' text__body',
                'group flex items-center px-2 py-2 text-sm rounded-md cursor-pointer',
              )}
              style={{
                backgroundColor: getGroupLink(item.href, activeGroupId) == location?.pathname ? accentColor : '',
              }}
              onClick={() => {
                // closeMenu();
                if (categories && categories.length > 0) {
                  history.push(getGroupLink(item.href, activeGroupId));
                  if (closeMenu) closeMenu();
                } else {
                  NotificationService.error('No channel catogeries found, create catogery first.');
                }
              }}
            >
              <item.icon
                className={classNames(
                  // item.href === location?.pathname ? 'text-gray-500': 'text-gray-500 group-hover:text-gray-500',
                  'mr-3 flex-shrink-0 h-5',
                )}
                aria-hidden="true"
              />
              {item.name}
            </span>
          ) : (
            <Link
              id={item.name}
              key={item.name}
              to={getGroupLink(item.href, activeGroupId)}
              className={classNames(
                getGroupLink(item.href, activeGroupId) == location?.pathname
                  ? `btn__primary ${primaryFont}`
                  : ' text__body',
                'group flex items-center px-2 py-2 text-sm rounded-md',
              )}
              style={{
                backgroundColor: getGroupLink(item.href, activeGroupId) == location?.pathname ? accentColor : '',
              }}
              onClick={closeMenu}
            >
              <item.icon
                className={classNames(
                  // item.href === location?.pathname ? 'text-gray-500' : 'text-gray-500 group-hover:text-gray-500',
                  'mr-3 flex-shrink-0 h-5',
                )}
                aria-hidden="true"
              />
              {item.name}
            </Link>
          ),
        )}

        {!isMainGroup && communityConfig?.canManageCommunity && (
          <div className="px-2 mt-3 pb-1 border-t divider">
            <button
              className="group flex items-center py-2 text-sm rounded-md text__danger w-full"
              onClick={() => setOpenDeleteConfirmationModal(true)}
            >
              <TrashIcon className="mr-3 flex-shrink-0 h-5" aria-hidden="true" />
              Delete Group
            </button>
          </div>
        )}
      </nav>
      <DeleteModal
        title="Delete Group"
        infoText="Deleting this group will remove all its categories and channels. Are you sure you want to delete this group?"
        open={openDeleteConfirmationModal}
        onClose={() => setOpenDeleteConfirmationModal(false)}
        onConfirm={handleDeleteGroup}
        deleteButtonText="Delete Group"
        deleteLoading={deleteLoading}
      />
    </div>
  );
}
