import server from '../api/server';

export const updateCustomDomain = async (custom_domain) => {
  return await new Promise((resolve, reject) => {
    server
      .put(`/update_domain`, {
        custom_domain,
      })
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });
};

export const verifyCustomDomain = async () => {
  return await new Promise((resolve, reject) => {
    server
      .get(`/verify_domain_txt`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });
};

export const getCustomDomainData = async () => {
  return await new Promise((resolve, reject) => {
    server
      .get(`/domain_data`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });
};

export const removeCustomDomain = async () => {
  return await new Promise((resolve, reject) => {
    server
      .delete('/remove_domain')
      .then((response) => {
        resolve(response);
      })
      .catch(reject);
  });
};
