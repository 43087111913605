import { FaFacebook, FaTwitter, FaInstagram, FaLinkedin, FaLink } from 'react-icons/fa';

function RenderSocialLinks({ userData }) {
  if (
    !userData?.facebook_url &&
    !userData?.twitter_url &&
    !userData?.instagram_url &&
    !userData?.linkedin_url &&
    !userData?.website_url
  )
    return null;

  const urlGenerator = (url) => {
    if (/^(http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm.test(url)) {
      return url;
    } else {
      return `https://${url}`;
    }
  };
  return (
    <div className="flex justify-start">
      <div className="flex gap-3 items-center justify-start">
        {userData?.facebook_url && (
          <a className="px-1 flex items-center justify-center gap-1" href={urlGenerator(userData?.facebook_url)} target="_blank" rel="noreferrer">
            <FaFacebook className="h-5 w-5  main__icon" />
          </a>
        )}
        {userData?.twitter_url && (
          <a className="px-1 flex items-center justify-center gap-1" href={urlGenerator(userData?.twitter_url)} target="_blank" rel="noreferrer">
            <FaTwitter className="h-5 w-5 main__icon" />
          </a>
        )}
        {userData?.instagram_url && (
          <a className="px-1 flex items-center justify-center gap-1" href={urlGenerator(userData?.instagram_url)} target="_blank" rel="noreferrer">
            <FaInstagram className="h-5 w-5 main__icon" />
          </a>
        )}
        {userData?.linkedin_url && (
          <a className="px-1 flex items-center justify-center gap-1" href={urlGenerator(userData?.linkedin_url)} target="_blank" rel="noreferrer">
            <FaLinkedin className="h-5 w-5 main__icon" />
          </a>
        )}
        {userData?.website_url && (
          <a className="px-1 flex items-center justify-center gap-1" href={urlGenerator(userData?.website_url)} target="_blank" rel="noreferrer">
            <FaLink className="h-5 w-5 main__icon" />
          </a>
        )}
      </div>
    </div>
  );
}

export default RenderSocialLinks;
