import parse, { domToReact } from 'html-react-parser';
import { matchPath } from 'react-router-dom';
import { ROUTE_PATH } from '../../../constants';
import { history } from '../../../constants/utils';
import { cleanText } from '../../../utils/Utils';

const options = {
  replace: (domNode) => renderNode(domNode),
};

const renderNode = (node) => {
  if (node.name === 'hashtag') {
    return (
      <span
      onClick={(evt) => {
        evt.stopPropagation();
        history.push(`/hashtag/forum/${node?.children[0]?.data?.substring(1)?.toLowerCase()}`)
        document.getElementById('closeBtn')?.click()
      }}
        className="text__link"
      >
        {node?.children[0]?.data}
      </span>
    );
  }
  if (node.name === 'a') {
    const url = node.attribs.href;
    return (
      <a href={url} target="_blank" rel="noreferrer" className="text__link underline z-20" onClick={(e) => e.stopPropagation()}>
        {node.children?.length ? domToReact(node.children) : url}
      </a>
    );
  }
  if (node.name === 'img') {
    const imageUrl = node.attribs.src;
    return (
      <div className="flex justify-center overflow-hidden rounded aspect-w-724 aspect-h-417">
        <img src={imageUrl} alt="cover" className="block w-full h-full" />
      </div>
    );
  }
  if (node.name === 'video') {
    return <div>Video</div>;
  }
  if (node.name === 'div' && node.attribs?.class?.includes('video') && node.children[0]?.name === 'iframe') {
    const videoUrl = node.children[0].attribs?.src;
    if (videoUrl) {
      return domToReact(node.children, options);
    }
    return <></>;
  }
  if (node.name === 'iframe') {
    const videoUrl = node.attribs?.src;
    if (videoUrl) {
      return (
        <div className="flex justify-center overflow-hidden rounded aspect-w-724 aspect-h-417">
          <iframe
            title="video"
            className="block h-full w-full"
            src={videoUrl}
            frameBorder="0"
            allow="accelerometer; autoplay=false; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        </div>
      );
    }
    return <></>;
  }
  if (node.name === 'div' && node.attribs?.class?.includes('document')) {
    const [name, size] = node.attribs?.info?.split('|') || [null, null];
    const documentUrl = node.attribs?.src;
    return (
      <a
        className="flex w-full p-2 align-center bg-gray-200 rounded-lg"
        href={documentUrl}
        download
        target="_blank"
        rel="noreferrer"
      >
        <div className="pr-4">
          {/* <img src={FileIcon} alt="cover" /> */}
        </div>
        <div className="flex-col">
          <div className="text-base">{name}</div>
          <div className="text-sm">{size}</div>
        </div>
      </a>
    );
  }
  if (node.name === 'blockquote') {
    return (
      <blockquote className="mx-6 my-4 ltr:pl-4 rtl:pr-4 border-l-2 border-gray-300">
        {domToReact(node?.children)}
      </blockquote>
    );
  }
  if (node.name === 'ul') {
    return <ul className="list-disc list-inside ml-4">{domToReact(node?.children, options)}</ul>;
  }
  if (node.name === 'ol') {
    return <ol className="list-decimal list-inside ml-4">{domToReact(node?.children, options)}</ol>;
  }
  if (node.name === 'div' && node?.children?.length === 0) {
    return <br />;
  }
  return domToReact(node);
};

export const parseHtml = (text) => {
  if (!text) {
    return null;
  }
  const regex = /(^|\B)#(?![0-9_]+\b)([a-zA-Z0-9_]{1,30})(\b|\r)/gi;

  return parse(
    text.replace(regex, (match) => `<hashtag>${match}</hashtag>`),
    options,
  );
};

export const getBodyText = (richText) => {
  let text = '';

  const getTextFromNode = (node) => {
    if (
      node.name === 'img' ||
      node.name === 'video' ||
      node.name === 'iframe' ||
      (node.name === 'div' && node.attribs?.class?.includes('document'))
    ) {
      return <></>;
    }
    if (node.type === 'text' && node?.data !== '&nbsp;' && node?.data?.trim() != '') {
      if (text[text.length - 1] && text[text.length - 1] !== ' ') {
        text += ' ';
      }
      text += node.data;
    }
    return domToReact(node);
  };

  try {
    parse(richText, {
      replace: (domNode) => getTextFromNode(domNode),
    });
    return text;
  } catch (error) {
    return '';
  }
};
