import server from '../api/server';
import serverV from '../api/serverVersionless';

export const getShopItems = async ({ communityId, nextPageId = '' }) =>
  new Promise((resolve, reject) => {
    const url = nextPageId
      ? `/shop_items?community_id=${communityId}&next_page_id=${nextPageId}`
      : `/shop_items?community_id=${communityId}&next_page_id=`;
    serverV
      .get(url)
      .then((response) => resolve(response?.data))
      .catch(reject);
  });

export const getPurchasedShopItems = async ({ communityId, nextPageId = '' }) =>
  new Promise((resolve, reject) => {
    const url = nextPageId
      ? `/shop_item_purchases?community_id=${communityId}&next_page_id=${nextPageId}`
      : `/shop_item_purchases?community_id=${communityId}&next_page_id=`;
    serverV
      .get(url)
      .then((response) => resolve(response?.data))
      .catch(reject);
  });

export const getShopItemDetails = async ({ communityId, itemId }) =>
  new Promise((resolve, reject) => {
    serverV
      .get(`/shop_items/${itemId}?community_id=${communityId}`)
      .then((response) => resolve(response?.data))
      .catch(reject);
  });

export const getShop = async ({ communityId }) =>
  new Promise((resolve, reject) => {
    server
      .get(`/community_shops?community_id=${communityId}`)
      .then((response) => resolve(response?.data))
      .catch(reject);
  });

export const createShop = async ({ communityId, name, enabled }) =>
  new Promise((resolve, reject) => {
    server
      .post(`/community_shops?community_id=${communityId}`, {
        community_shop: { name, enabled },
      })
      .then((response) => resolve(response?.data))
      .catch(reject);
  });

export const updateShop = async ({ communityId, name, enabled, go_live, payment_provider }) =>
  new Promise((resolve, reject) => {
    server
      .put(`/community_shops?community_id=${communityId}`, {
        community_shop: {
          name,
          enabled,
          go_live,
          payment_provider,
        },
      })
      .then((response) => resolve(response?.data))
      .catch(reject);
  });

export const switchPaymentProvider = async ({ communityId, payment_provider }) =>
  new Promise((resolve, reject) => {
    server
      .patch(`/community_shops/switch_payment_provider?community_id=${communityId}`, {
        payment_provider,
      })
      .then((response) => resolve(response?.data))
      .catch(reject);
  });

export const connectStripe = async ({ communityId }) =>
  new Promise((resolve, reject) => {
    server
      .get(`community_shops/connect_stripe?community_id=${communityId}`)
      .then((response) => resolve(response?.data))
      .catch(reject);
  });

export const createProduct = async ({ communityId, data }) =>
  new Promise((resolve, reject) => {
    serverV
      .post(`/shop_items?community_id=${communityId}`, data)
      .then((response) => resolve(response?.data))
      .catch(reject);
  });

export const updateProduct = async ({ communityId, data, productId }) =>
  new Promise((resolve, reject) => {
    serverV
      .put(`/shop_items/${productId}?community_id=${communityId}`, data)
      .then((response) => resolve(response?.data))
      .catch(reject);
  });

export const deleteProduct = async ({ communityId, productId }) =>
  new Promise((resolve, reject) => {
    serverV
      .delete(`/shop_items/${productId}?community_id=${communityId}`)
      .then((response) => resolve(response?.data))
      .catch(reject);
  });

export const purchaseProduct = async ({ communityId, productId, data }) =>
  new Promise((resolve, reject) => {
    serverV
      .post(`/shop_items/${productId}/shop_item_purchases?community_id=${communityId}`, data)
      .then((response) => resolve(response?.data))
      .catch(reject);
  });

export const verifyPurchase = async ({ communityId, purchaseId }) =>
  new Promise((resolve, reject) => {
    serverV
      .get(`/shop_item_purchases/${purchaseId}?community_id=${communityId}`)
      .then((response) => resolve(response?.data))
      .catch(reject);
  });

// Roazorpay

export const connectRazorpay = async (communityId, data) =>
  new Promise((resolve, reject) => {
    server
      .post(`razorpay_accounts?community_id=${communityId}`, data)
      .then((response) => resolve(response?.data))
      .catch(reject);
  });

export const getStatusRazorpay = async (communityId) =>
  new Promise((resolve, reject) => {
    server
      .get(`razorpay_accounts/refresh_account?community_id=${communityId}`)
      .then((response) => resolve(response?.data))
      .catch(reject);
  });

export const deleteRazorpayAccount = async (communityId) =>
  new Promise((resolve, reject) => {
    server
      .delete(`razorpay_accounts?community_id=${communityId}`)
      .then((response) => resolve(response?.data))
      .catch(reject);
  });

export const getAllCurrenciesStripe = async (communityId) =>
  new Promise((resolve, reject) => {
    server
      .get(`/community_shops/get_currencies?community_id=${communityId}`)
      .then((response) => resolve(response?.data))
      .catch(reject);
  });

export const setStripeCurrency = async (communityId, currency) =>
  new Promise((resolve, reject) => {
    server
      .patch(`/community_shops/switch_stripe_currency?community_id=${communityId}`, { currency: currency.toLowerCase() })
      .then((response) => resolve(response?.data))
      .catch(reject);
  });

  export const disconnectStripeService = async () =>
  new Promise((resolve, reject) => {
    server
      .delete(`/community_shops/disconnect_stripe`,)
      .then((response) => resolve(response?.data))
      .catch(reject);
  });
