import { Dialog, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { Fragment } from 'react';
import { getActiveCommunityFontsClass } from '../../selectors/CommunitySelectors';
import { useSelector } from 'react-redux';
import { XIcon } from '@heroicons/react/outline';
import { classNames } from '../../constants/utils';

const SideDrawer = ({ children, open, handleClose, drawerTitle }) => {
  const { primaryFont } = useSelector(getActiveCommunityFontsClass);
  const isMobile = window.innerWidth < 1024;

  return (
    <Transition show={open} as={Fragment}>
      <div className="fixed inset-0 overflow-hidden z-50" onClose={() => {}}>
        <div className="absolute inset-0 overflow-hidden">
          <TransitionChild
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              onClick={handleClose}
            />
          </TransitionChild>

          <div className="fixed inset-y-0 top-[40px] lg:top-0 right-0 max-w-full lg:pl-10 flex">
            <TransitionChild
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom={isMobile ? 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95' : 'translate-x-full'}
              enterTo={isMobile ? 'translate-x-0' : 'opacity-100 translate-y-0 sm:scale-100'}
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom={isMobile ? 'translate-x-0' : 'opacity-100 translate-y-0 sm:scale-100'}
              leaveTo={isMobile ? 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95' : 'translate-x-full'}
            >
              <div className="w-screen lg:h-full lg:max-w-md main--background relative">
                <div className="min-h-12 flex justify-center items-center cursor-pointer absolute w-full top-0 lg:hidden">
                  <div className="w-6 h-[2px] rounded-sm bg-[#AAB1B7]"></div>
                </div>
                <div className="flex items-start px-6 justify-between pt-8 lg:pt-6 pb-6 border-b border-gray-200">
                  <div className={classNames('text-lg font-medium text__title', primaryFont)}>
                    {drawerTitle}
                  </div>
                  <div className="ml-3 h-7 lg:flex items-center hidden">
                    <button
                      type="button"
                      className="rounded-md focus:outline-none focus:ring-0 focus:ring-offset-0 usetiful-side-drawer-close"
                      onClick={handleClose}
                    >
                      <span className="sr-only">Close panel</span>
                      <XIcon className="h-6 w-6 main__icon" aria-hidden="true" />
                    </button>
                  </div>
                </div>
                {children}
              </div>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Transition>
  );
};

export default SideDrawer;
