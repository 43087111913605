/* eslint-disable default-param-last */
/* eslint-disable no-case-declarations */
import _ from 'lodash';
import {
  CREATE_CUSTOM_SSO_FAILED,
  CREATE_CUSTOM_SSO_REQUEST,
  CREATE_CUSTOM_SSO_SUCCESS,
  UPDATE_CUSTOM_SSO_REQUEST,
  UPDATE_CUSTOM_SSO_SUCCESS,
  UPDATE_CUSTOM_SSO_FAILED,
  GET_CUSTOM_SSO_LIST,
  SET_CUSTOM_SSO_LIST,
  DELETE_CUSTOM_SSO_REQUEST,
  DELETE_CUSTOM_SSO_SUCCESS,
  DELETE_CUSTOM_SSO_FAILED,
  SET_SCENES_LOGIN_STATE
} from '../actions/actionTypes';

const initialState = {
  ssoList: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CUSTOM_SSO_LIST:
      return { ...state, fetchingSsoList: true };
    case SET_CUSTOM_SSO_LIST:
      return { ...state, ssoList: action.payload, fetchingSsoList: false };
    case CREATE_CUSTOM_SSO_REQUEST:
      return { ...state, creatingSsoList: true };
    case CREATE_CUSTOM_SSO_SUCCESS:
      return { ...state, creatingSsoList: false, ssoList: [...state?.ssoList, action.payload] };
    case CREATE_CUSTOM_SSO_FAILED:
      return { ...state, creatingSsoList: false };
    case UPDATE_CUSTOM_SSO_REQUEST:
      return { ...state, updatingSsoList: true };
    case UPDATE_CUSTOM_SSO_SUCCESS:
      const newSsoList = _.cloneDeep(state?.ssoList);
      const index = newSsoList.findIndex((item) => item.id === action.payload.id);
      newSsoList[index] = action.payload;
      return { ...state, updatingSsoList: false, ssoList: newSsoList };
    case UPDATE_CUSTOM_SSO_FAILED:
      return { ...state, updatingSsoList: false };
    case DELETE_CUSTOM_SSO_REQUEST:
      return { ...state, deletingSso: true };
    case DELETE_CUSTOM_SSO_SUCCESS:
      const clonedSsoList = _.cloneDeep(state?.ssoList);
      const idx = clonedSsoList.findIndex((item) => item.id === action.id);
      if (idx >= 0) clonedSsoList.splice(idx, 1);
      return { ...state, deletingSso: false, ssoList: clonedSsoList };
    case DELETE_CUSTOM_SSO_FAILED:
      return { ...state, deletingSso: false };
    case SET_SCENES_LOGIN_STATE : 
    return {...state , scenesLogin : action.payload}
    default:
      return state;
  }
};

export default reducer;
