import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { history } from '../../constants/utils';
import { imageFetch } from '../../services/preSignedAws';
import { fetchSearchResults } from '../../services/SearchService';
import { setActiveGroup } from '../../store/actions/communityActions';
import Pagination from '../shared/Pagination';
import { SearchLoadingSpinner } from './GlobalSearch';

function GroupsList({
  searchQuery,
  groupData,
  activeCommunity,
  activeGroupId,
  closeDrawer,
  onCommonPage,
  handleViewDetails,
  handleScrollTop,
}) {
  const [intitalData, setIntitalData] = useState();
  const [allGroupsData, setAllGroupsData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  function handleGroupClick(groupId) {
    dispatch(setActiveGroup(activeCommunity?.id, groupId, true));
    closeDrawer();
    // /communities/:community_id/group/:group_id/overview
    // history.push(`/communities/${activeCommunity?.id}/group/${groupId}/overview`);
    // history.push(`/`);
  }

  async function fetchGroupsData(query, page) {
    setIsLoading(true);
    const { data } = await fetchSearchResults(query, 'group', page, activeCommunity?.id, activeGroupId);
    setIsLoading(false);
    if (!data?.entries) return;
    setAllGroupsData(data);
    handleScrollTop();
  }

  useEffect(() => {
    if (groupData?.entries) setIntitalData(groupData);
    if (!allGroupsData?.total_count) setAllGroupsData(groupData);
  }, [groupData]);

  useEffect(() => {
    if (onCommonPage) return;
    fetchGroupsData(searchQuery, 1);
  }, [onCommonPage]);

  return (
    <div className={`mb-8 ${!onCommonPage ? 'flex-1' : ''}`}>
      <div className="flex flex-row align-center justify-between">
        <p className="font-bold text__title">
          {$translatei18n('Groups')} <span className="font-normal text__description">{`(${groupData?.total_count})`}</span>
        </p>
        {onCommonPage && handleViewDetails && (
          <span
            className="underline text-xs cursor-pointer text__link"
            onClick={handleViewDetails}
            style={{ color: activeCommunity?.accent_color }}
          >
            See all
          </span>
        )}
      </div>
      <AllGroups
        listData={onCommonPage ? intitalData?.entries : allGroupsData?.entries}
        handleGroupClick={handleGroupClick}
      />
      {isLoading && <SearchLoadingSpinner />}
      {allGroupsData?.links?.pages && allGroupsData.links.pages > 1 && !onCommonPage && (
        <Pagination
          handlePageClick={(page) => fetchGroupsData(searchQuery, page?.selected + 1)}
          pageCount={allGroupsData?.links?.pages}
          className="mt-6"
        />
      )}
    </div>
  );
}

function AllGroups({ listData, handleGroupClick }) {
  return (
    <div className="p-4 pb-1 shadow rounded-lg mt-2 card">
      {listData?.data?.map((data) => {
        const { id, icon, name } = data?.attributes;

        return (
          <div key={id} className="flex items-center mb-3 cursor-pointer" onClick={() => handleGroupClick(id)}>
            <img src={imageFetch(icon)} className="block h-9 w-9 rounded-md" />
            <div className="ml-3 text__header font-bold">{name}</div>
          </div>
        );
      })}
    </div>
  );
}

export default GroupsList;
