import {
  AUTH_LOG_OUT_SUCCESS,
  SET_ACTIVE_CHANNELID,
  SET_ACTIVE_CHANNEL_DATA,
  SET_CHANNEL,
  SET_LOBBY,
  SET_LOBBY_CATEGORIES,
  SET_SOCKET,
  SET_USER_CHANNEL,
  UPDATE_CONNECTION_STATE,
} from '../actions/actionTypes';
import { internetState } from '../models/constants';

const initialState = {
  channel: null,
  activeChannelId: null,
  userChannel: null,
  error: false,
  socket: '',
  lobby: '',
  lobbyCategories: [],
  socketConnectionState: internetState.IDLE,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHANNEL:
      return { ...state, channel: action.payload.channel };
    case SET_SOCKET:
      return { ...state, socket: action.payload.socket };
    case SET_ACTIVE_CHANNELID:
      return { ...state, activeChannelId: action.payload.channelId };
    case SET_LOBBY:
      return { ...state, lobby: action.payload.lobby };
    case SET_USER_CHANNEL:
      return { ...state, userChannel: action.payload.userChannel };
    case SET_LOBBY_CATEGORIES:
      return { ...state, lobbyCategories: action.payload.lobbyCategories };
    case SET_ACTIVE_CHANNEL_DATA:
      return { ...state, activeChannelData: action.payload.activeChannelData };
    case AUTH_LOG_OUT_SUCCESS:
      return initialState;
    case UPDATE_CONNECTION_STATE:
      return { ...state, socketConnectionState: action?.payload };
    default:
      return state;
  }
};

export default reducer;
