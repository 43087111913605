export const channelCategoryModel = (data) => {
  const {
    group_categories,
    // community,
    // community_permissions: permissions,
    // members_count,
    group_permissions: groupPermissions,
  } = data;
  const channels = {};
  const categories = [];

  group_categories.forEach((category) => {
    channels[category.id] = category.channels;
    categories.push({
      name: category.name,
      id: category.id,
      communityId: category.community_id,
    });
  });

  const drawerSectionListData = categories.map((category) => ({
    title: category.name,
    id: category.id,
    data: channels[category.id],
  }));

  // const permissionsConfig = {};
  // permissions.forEach((permission) => {
  //   permissionsConfig[permission] = true;
  // });

  // const communityConfigs = {
  //   canManageRoles: !!permissionsConfig.MANAGE_ROLES,
  //   canManageCommunity: !!permissionsConfig.MANAGE_COMMUNITY,
  //   canInviteUsers: !!permissionsConfig.INVITE_USERS,
  //   canManageMembers: !!permissionsConfig.MANAGE_MEMBERS,
  // };
  // community.members = members_count;

  const groupPermissionsConfig = {};
  groupPermissions.forEach((permission) => {
    groupPermissionsConfig[permission] = true;
  });
  const groupConfigs = {
    canManageGroup: !!groupPermissionsConfig.MANAGER,
    canViewGroup: !!groupPermissionsConfig.VISIBLE,
  };

  return { categories, channels, drawerSectionListData, /* communityConfigs, community, */ groupConfigs };
};

export const getChannelPermissionsConfig = (permissions) => {
  const permissionsConfig = {};
  permissions.forEach((permission) => {
    permissionsConfig[permission] = true;
  });

  const config = {
    canSendMessage: !!permissionsConfig.SEND_MESSAGES,
    canStartVoice: !!permissionsConfig.START_VOICE,
    canManageSpeakers: !!permissionsConfig.MANAGE_SPEAKERS,
    canManageMessages: !!permissionsConfig.MANAGE_MESSAGES,
    canManageUsers: !!permissionsConfig.MANAGE_USERS,
    canManageSettings: !!permissionsConfig.MANAGE_SETTINGS,
    canViewStage: !!permissionsConfig.VIEW_STAGE,
  };

  return config;
};

export const getResourcePermissionConfig = (permissions) => {
  const permissionsConfig = {};
  permissions.forEach((permission) => {
    permissionsConfig[permission] = true;
  });

  const config = {
    canReadResources: !!permissionsConfig.READ_RESOURCES,
    canCreateResources: !!permissionsConfig.CREATE_RESOURCES,
    canCommentResources: !!permissionsConfig.COMMENT_RESOURCES,
    canManageResources: !!permissionsConfig.MANAGE_RESOURCES,
    canInviteUsers: !!permissionsConfig.INVITE_USERS,
  };

  return config;
};

export const getCalendarPermissionConfig = (permissions) => {
  const permissionsConfig = {};
  permissions.forEach((permission) => {
    permissionsConfig[permission] = true;
  });

  const config = {
    canReadEvents: !!permissionsConfig.READ_EVENTS,
    canManageEvents: !!permissionsConfig.MANAGE_EVENTS,
  };

  return config;
};
