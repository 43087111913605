export const TRIAL_PERIOD = 14;

export const PLAN_NAME = {
  HUSTLER: 'hustler',
  PRO: 'pro',
  ENTERPRISE: 'enterprise',
  TRIAL: 'trial',
};

export const TALK_TO_US = 'Talk to us';

export const CHARGEBEE = {
  STATE: {
    SUCCESS: 'succeeded',
  },
};

export const PLAN_PRICE_ID = {
  HUSTLER: process.env.REACT_APP_ENV === 'production' ? 'Hustler-USD-Yearly' : 'hustler-USD-Yearly',
  PRO: process.env.REACT_APP_ENV === 'production' ? 'Pro-USD-Yearly' : 'pro-USD-Yearly',
  ENTERPRISE: process.env.REACT_APP_ENV === 'production' ? 'Enterprise-USD-Yearly' : 'enterprise-USD-Yearly',
  TRIAL:
    process.env.REACT_APP_ENV === 'production'
      ? 'free-trial-plan-USD-Every-15-days'
      : 'Free-Trial-Plan-USD-Every-14-days',
};

export const PLAN_CARD_FEATURES_NAME = {
  WEBSITE_AND_APP: 'Website & App',
  WEBSITE: 'Website',
  ENABLED: 'Enabled',
  UNAVAILABLE: 'Unavailable',
};

export const CHARGEBEE_UPGRADE = {
  STEPS: {
    STARTED: 'STARTED',
    COMPLETED: 'COMPLETED',
  },
};

export const FEATURES_NAMES = {
  MEMBERS: 'Members',
  MODERATORS: 'Moderators',
  STORAGE: 'Storage',
  SUB_COMMUNITY: 'Sub community',
  CHANNELS: 'Channels',
  LIVE_STREAM_LIMIT: 'Live stream limit',
  SHOP: 'Shop',
  WHITE_LABELED_PLATFORM: 'White-labeled platform',
  ANALYTICS_INTEGRATION: 'Analytics integration',
  MULTI_LIVE_STREAM: 'Multi Live-Stream',
  PUSH_NOTIFICATIONS: 'Push notifications',
};

export const FEATURES = [
  FEATURES_NAMES.MEMBERS,
  FEATURES_NAMES.MODERATORS,
  FEATURES_NAMES.STORAGE,
  FEATURES_NAMES.SUB_COMMUNITY,
  FEATURES_NAMES.CHANNELS,
  FEATURES_NAMES.LIVE_STREAM_LIMIT,
  FEATURES_NAMES.SHOP,
  FEATURES_NAMES.WHITE_LABELED_PLATFORM,
  FEATURES_NAMES.ANALYTICS_INTEGRATION,
  FEATURES_NAMES.MULTI_LIVE_STREAM,
  FEATURES_NAMES.PUSH_NOTIFICATIONS,
];

export const PLAN_PAYMENT_MESSAGE = {
  REQUEST: 'Plan not found in subscription',
  SUCCESS: 'Plan payment successfull',
};

export const MODAL_TYPE = {
  REPORT_MODAL: 'REPORT_MODAL',
};
