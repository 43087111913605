export const SETTINGS_ROUTES_CONSTANTS = {
  COMMUNITY_SETTINGS: '/community-settings',
  APPEARANACE: '/community-settings/appearance',
  CREATE_ROLE: '/community-settings/create-role',
  MANAGE_ROLES: '/community-settings/manage-roles',
  PEOPLE_MANAGEMENT: '/community-settings/manage-people',
  NEW_CATEGORY: '/community-settings/create-category',
  NEW_CHANNEL: '/community-settings/create-channel',
  NEW_CHANNEL_CHOOSE_CATEGORY: '/community-settings/create-channel/select-category',
  MANAGE_TAGS: '/community-settings/manage-tags',
  SETUP_COIN: '/community-settings/manage-coin',
  ALLOCATE_COIN: '/community-settings/allocate-coin',
  MODERATION: '/community-settings/moderation',
  PLAN_DETAILS: '/community-settings/plan-details',
  CUSTOM_DOMAIN: '/community-settings/custom-domain',
  SINGLE_SIGN_ON: '/community-settings/single-signon',
  ONBOARDING_SETTINGS: '/community-settings/onboarding-setting',
  NEW_APPLICATIONS: '/community-settings/new-application',
  CUSTOM_CSS: '/community-settings/custom-css',
  NEW_GROUP: '/community-settings/create-group',
  THEMING: '/community-settings/theming',
  INVITES: '/community-settings/invites',
  EMAIL_WHITELABEL: '/community-settings/white-label-email',
  NOTIFICATION_PREFERENCE: '/community-settings/notification-preferences',
  WEBHOOK: '/community-settings/webhooks',
  ZOOM_SETTINGS: '/community-settings/zoom_setting',
  CUSTOM_PUSH_NOTIFICATION: '/community-settings/push_notification_setting',
  PRIVACY_POLICY: '/community-settings/privacy_policy',
  GRAPHY_INTEGRATION: '/community-settings/graphy-integration',
  SHOP_SETUP: '/community-settings/shop-setup',
  MUX_SETUP: '/community-settings/mux_setting',
  MIXPANEL_SETUP: '/community-settings/mixpanel-integration',
  AUTOMATE_ROLE: '/community-settings/automate-roles',
};
