import mixpanel from 'mixpanel-browser';
import * as amplitude from '@amplitude/analytics-browser';
import { store } from '../store/configureStore';
class AnalyticsService {
  static mixpanelProps;

  constructor() {
    const _store = store.getState();

    AnalyticsService.mixpanelProps = _store.mixpanel;

    const amplitudeConfig =
      process.env.REACT_APP_ENV === 'production'
        ? {}
        : {
            logLevel: amplitude.Types.LogLevel.Debug,
          };
    amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY, amplitudeConfig);

    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN); // Your Mixpanel Project Token

    if (AnalyticsService.mixpanelProps?.mixpanelToken) {
      mixpanel.init(
        AnalyticsService.mixpanelProps.mixpanelToken,
        {},
        `${AnalyticsService.mixpanelProps.internalIdentifier}_internal`,
      );
    }
  }

  static setAmplitudeIdentity = (userId) => {
    if (userId) {
      amplitude.setUserId({ user_id: userId });
    }
  };

  static setAmplitudeUserProperty = (value = '') => {
    if (value) {
      const identifyEvent = new amplitude.Identify();
      identifyEvent?.set('email', value?.email);
      identifyEvent?.set('uuid', value?.uuid);
      identifyEvent?.set('name', value?.first_name || '' + value?.last_name || '');
      amplitude.identify(identifyEvent);
    }
  };

  static trackAmplitudeEvent = (eventName = '', params = {}) => {
    if (eventName) {
      amplitude.track(eventName, params);
    }
  };

  static setMixpanelIdentify = (userId) => {
    try {
      if (userId && mixpanel) {
        mixpanel.identify(userId.toString());
  
        if (AnalyticsService.mixpanelProps?.mixpanelToken) {
          mixpanel[`${AnalyticsService.mixpanelProps.internalIdentifier}_internal`].identify(userId.toString());
        }
      }
    } catch (e) {}
  };

  static logMixpanelEvent = (eventName = '', params = {}) => {
    try {
      if (mixpanel) {
        mixpanel.track(eventName, {
          ...params,
          Source: 'Browser',
        });

        if (AnalyticsService.mixpanelProps?.mixpanelToken) {
          mixpanel[`${AnalyticsService.mixpanelProps.internalIdentifier}_internal`].track(eventName, {
            ...params,
            Source: 'Browser',
          });
        }
      }
    } catch (e) {
      // console.log('error >>>>', e);
    }
  };

  static logMixpanelTimingEvent = (eventName = '') => {
    try {
      if (mixpanel) {
        mixpanel.time_event(eventName);

        if (AnalyticsService.mixpanelProps?.mixpanelToken) {
          mixpanel[`${AnalyticsService.mixpanelProps.internalIdentifier}_internal`].time_event(eventName);
        }
      }
    } catch (e) {}
  };

  static setUserAttributes = (data) => {
    if (data.first_name) {
      try {
        if (mixpanel) {
          mixpanel.people?.set('$first_name', data.first_name);
          mixpanel.people?.set('$name', `${data.name || data.first_name}`);

          if (AnalyticsService.mixpanelProps?.mixpanelToken) {
            mixpanel[`${AnalyticsService.mixpanelProps.internalIdentifier}_internal`]?.people.set(
              '$first_name',
              data.first_name,
            );
            mixpanel[`${AnalyticsService.mixpanelProps.internalIdentifier}_internal`]?.people.set(
              '$name',
              `${data.name || data.first_name}`,
            );
          }
        }
      } catch (e) {}
    }

    if (data.last_name) {
      try {
        if (mixpanel) {
          mixpanel.people?.set('$last_name', data.last_name);

          if (AnalyticsService.mixpanelProps?.mixpanelToken) {
            mixpanel[`${AnalyticsService.mixpanelProps.internalIdentifier}_internal`]?.people.set(
              '$last_name',
              data.last_name,
            );
          }
        }
      } catch (e) {}
    }

    if (data.email) {
      try {
        if (mixpanel) {
          mixpanel.people?.set('$email', data.email);

          if (AnalyticsService.mixpanelProps?.mixpanelToken) {
            mixpanel[`${AnalyticsService.mixpanelProps.internalIdentifier}_internal`]?.people.set('$email', data.email);
          }
        }
      } catch (e) {}
    }

    if (data.phone_number) {
      try {
        if (mixpanel) {
          mixpanel.people?.set('$phone', data.phone_number);

          if (AnalyticsService.mixpanelProps?.mixpanelToken) {
            mixpanel[`${AnalyticsService.mixpanelProps.internalIdentifier}_internal`]?.people.set('$phone', data.phone_number);
          }
        }
      } catch (e) {}
    }

    if (data.gender) {
      try {
        if (mixpanel) {
          mixpanel.people?.set('Gender', data.gender);

          if (AnalyticsService.mixpanelProps?.mixpanelToken) {
            mixpanel[`${AnalyticsService.mixpanelProps.internalIdentifier}_internal`]?.people.set('Gender', data.gender);
          }
        }
      } catch (e) {}
    }

    if (data.dob) {
      try {
        if (mixpanel) {
          mixpanel.people?.set('DOB', data.dob);

          if (AnalyticsService.mixpanelProps?.mixpanelToken) {
            mixpanel[`${AnalyticsService.mixpanelProps.internalIdentifier}_internal`]?.people.set('DOB', data.dob);
          }
        }
      } catch (e) {}
    }

    if (data.age) {
      try {
        if (mixpanel) {
          mixpanel.people?.set('Age', data.age);

          if (AnalyticsService.mixpanelProps?.mixpanelToken) {
            mixpanel[`${AnalyticsService.mixpanelProps.internalIdentifier}_internal`]?.people.set('Age', data.age);
          }
        }
      } catch (e) {}
    }

    if (data.personal_referral_code) {
      try {
        if (mixpanel) {
          mixpanel.people?.set('Personal Referral Code', data.personal_referral_code);

          if (AnalyticsService.mixpanelProps?.mixpanelToken) {
            mixpanel[`${AnalyticsService.mixpanelProps.internalIdentifier}_internal`]?.people.set(
              'Personal Referral Code',
              data.personal_referral_code,
            );
          }
        }
      } catch (e) {}
    }

    if (data.invited_via_referral_code) {
      try {
        if (mixpanel) {
          mixpanel.people?.set('Invited Via Referral Code', data.invited_via_referral_code);

          if (AnalyticsService.mixpanelProps?.mixpanelToken) {
            mixpanel[`${AnalyticsService.mixpanelProps.internalIdentifier}_internal`]?.people.set(
              'Invited Via Referral Code',
              data.invited_via_referral_code,
            );
          }
        }
      } catch (e) {}
    }

    if (data?.roleList) {
      try {
        if (mixpanel) {
          mixpanel.people?.set('Role name', data.roleList);

          if (AnalyticsService.mixpanelProps?.mixpanelToken) {
            mixpanel[`${AnalyticsService.mixpanelProps.internalIdentifier}_internal`]?.people.set(
              'Role name',
              data.roleList,
            );
          }
        }
      } catch (e) {}
    }
  };

  static clearUser = () => {
    if (mixpanel) {
      mixpanel.reset();

      if (AnalyticsService.mixpanelProps?.mixpanelToken) {
        mixpanel[`${AnalyticsService.mixpanelProps.internalIdentifier}_internal`].reset();
      }
    }
    amplitude.reset();
  };
}

export { AnalyticsService as default };
