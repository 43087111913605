import server from '../api/server';
import serverV from '../api/serverVersionless';
import { getAuthToken } from '../constants/authUtils';
import { parseAxiosErrorMessage } from '../constants/utils';
import NotificationService from './notificationService';


export const fetchPublicCommunityDetails = async (data) =>
  await new Promise((resolve, reject) => {
    serverV
      .get('/verify_community', {
        params: {
          request_host: data?.host,
        },
      })
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const checkCommunityMember = async (data) =>
  await new Promise((resolve, reject) => {
    serverV
      .get('/verify_community_member')
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const checkForMaintenance = async () => {
  return new Promise((resolve, reject) => {
    server
      .get('/maintenance')
      .then((response) => {
        if (response && response.data && response.data.maintenance_mode === true) {
          if (getAuthToken()) {
            resolve(true);
          } else {
            window.location.href = `${window.location.origin}/community-login`;
            resolve(true);
          }
        } else {
          resolve(false);
        }
      })
      .catch(function (e) {
        reject(e);
      });
  });
};

export const createCommunityRequest = async (data) =>
  await new Promise((resolve, reject) => {
    serverV
      .post('/communities', {
        community: {
          name: data.name,
          description: data.description,
          category_id: data.category_id,
          icon: data.icon,
          cover_image: data.cover_image,
          accent_color: data.accent_color,
          accent_pattern: data.accent_pattern,
          private: data.private,
        },
      })
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const joinCommunityService = async () =>
  await new Promise((resolve, reject) => {
    const joinOptions = { join: true };

    const referredBy = localStorage.getItem('ReferredBy');
    const inviteCode = localStorage.getItem('InviteCode');
    if (referredBy) joinOptions.referral_code = referredBy;
    if (inviteCode) joinOptions.invite_code = inviteCode;

    serverV
      .post('/join', joinOptions)
      .then((response) => {
        localStorage.removeItem('InviteCode');
        resolve(response?.data);
      })
      .catch(reject);
  });

export const updateCommunityRequest = (communityId, community) =>
  serverV
    .put(`/communities/${communityId}`, {
      community,
    })
    .then((response) => response?.data);

export const myCommunitiesRequest = async (data) =>
  await new Promise((resolve, reject) => {
    serverV
      .get('/my_communities')
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const fetchCommunityFeed = async ({ id, page, groupId, sort, postTags }) =>
  await new Promise((resolve, reject) => {
    server
      .get(`/communities/${id}/feed?page=${page}&group_id=${groupId}&sort=${sort}`, {
        params: { post_tags: JSON.stringify(postTags) },
      })
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const changeCommunityAppearance = async (communityId, appearance_metadata) =>
  await new Promise((resolve, reject) => {
    serverV
      .patch(`/appearance`, {
        appearance_metadata,
      })
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const addCustomCss = async (custom_css) =>
  await new Promise((resolve, reject) => {
    serverV
      .put('/custom_css', {
        custom_css,
      })
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const deleteCustomCss = async () =>
  await new Promise((resolve, reject) => {
    serverV
      .delete('/remove_css')
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const toggleGroups = async (enable) =>
  await new Promise((resolve, reject) => {
    serverV
      .put('/toggle_groups', { enable })
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const fetchGroups = async () =>
  await new Promise((resolve, reject) => {
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    serverV
      .get(`/groups?timezone=${timezone}`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const createGroup = async (group, community_role_ids) =>
  await new Promise((resolve, reject) => {
    serverV
      .post('/groups', { group, community_role_ids })
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const updateGroup = async (id, group) =>
  await new Promise((resolve, reject) => {
    serverV
      .put(`/groups/${id}`, { group })
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const deleteGroup = async (id) =>
  await new Promise((resolve, reject) => {
    serverV
      .delete(`/groups/${id}`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const updateMobileVerficationStatus = async (status) =>
  await new Promise((resolve, reject) => {
    serverV
      .put('/mobile_verification', {
        enable: status,
      })
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const updatGateKeepingStatus = async (status) =>
  await new Promise((resolve, reject) => {
    serverV
      .put('/custom_onboarding', {
        enable: status,
      })
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const updateApplicationFormStatus = async (status) =>
  await new Promise((resolve, reject) => {
    serverV
      .put('/entry_approval', {
        enable: status,
      })
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const addOnboardingStep = async (data) =>
  await new Promise((resolve, reject) => {
    server
      .post('/onboarding_steps', data)
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const getAllOnboardingSteps = async () =>
  await new Promise((resolve, reject) => {
    server
      .get('/onboarding_steps')
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const getOnboardingStepById = async (id) =>
  await new Promise((resolve, reject) => {
    server
      .get(`/onboarding_steps/${id}`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const editOnboardingStepById = async (data, id) =>
  await new Promise((resolve, reject) => {
    server
      .put(`/onboarding_steps/${id}`, data)
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const deleteOnboardingStepById = async (id) =>
  await new Promise((resolve, reject) => {
    server
      .delete(`/onboarding_steps/${id}`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const onboardingEntries = async (data) =>
  await new Promise((resolve, reject) => {
    server
      .get('/onboarding_entries', data)
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const onboardingEntriesPost = async (data) =>
  await new Promise((resolve, reject) => {
    server
      .post('/onboarding_entries', data)
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const onboardingEntryDetails = async (id) =>
  await new Promise((resolve, reject) => {
    server
      .get(`/onboarding_step_responses?onboarding_entry_id=${id}`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const acceptDeclineOnboardingEntry = async (id, community_id, status, reason) => {
  if (status == false) {
    return await new Promise((resolve, reject) => {
      serverV
        .put(`/onboarding_entries/${id}`, {
          community_id,
          accept: status,
          decline_reason: reason || '',
        })
        .then((response) => {
          resolve(response?.data);
        })
        .catch((error) => {
          const message = parseAxiosErrorMessage(error);
          NotificationService.error(message);    
        });
    });
  }
  return await new Promise((resolve, reject) => {
    serverV
      .put(`/onboarding_entries/${id}`, {
        community_id,
        accept: status,
      })
      .then((response) => {
        resolve(response?.data);
      })
      .catch((error) => {
        const message = parseAxiosErrorMessage(error);
        NotificationService.error(message);    
      });
});
};

export const acceptRejectAllEntry = async (accept, communityId) => {
  return await new Promise((resolve, reject) => {
    serverV
      .put(`bulk_entry_approval?community_id=${communityId}`, {
        approval_status: accept,
      })
      .then((response) => resolve(response?.data))
      .catch((error) => {
        const message = parseAxiosErrorMessage(error);
        NotificationService.error(message);
      });
  });
};

export const addOnboardingStepResponse = async (data) =>
  await new Promise((resolve, reject) => {
    server
      .post('/onboarding_step_responses', data)
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const verifyOnboardingEntry = async (data) =>
  await new Promise((resolve, reject) => {
    server
      .get('/onboarding_entries/verify_entry')
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const deleteOnboardingDropdownOption = async (stepId, optionId) =>
  await new Promise((resolve, reject) => {
    server
      .delete(`onboarding_steps/${stepId}/onboarding_step_options/${optionId}`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const searchOnboardingDropdownOption = async (stepId, page, optionSearchTerm) =>
  await new Promise((resolve, reject) => {
    const payload = {};
    if (page) {
      payload.page = page;
    }
    if (optionSearchTerm) {
      payload.option = optionSearchTerm;
    }
    server
      .get(`onboarding_steps/${stepId}/onboarding_step_options`, { params: payload })
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const addMobileTheming = (themingObj) =>
  new Promise((resolve, reject) => {
    serverV
      .patch('/mobile_appearance', {
        mobile_appearance_metadata: themingObj,
      })
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const getAllCommunityInvites = (nextPageId = '', active = 'true') => {
  return serverV.get(`/community_role_invites?active=${active}&next_page_id=${nextPageId}`);
};

export const addCommunityInvite = (payload) => {
  return serverV.post(`/community_role_invites`, {
    community_role_invite: payload,
  });
};

export const toggleCommunityInviteEnable = (inviteId, status) => {
  return serverV.put(`/community_role_invites/${inviteId}/disable`, { enable: status === 'ENABLE' ? true : false });
};

export const deleteCommunityInvite = (inviteId) => {
  return serverV.delete(`/community_role_invites/${inviteId}`);
};

export const addCustomEmail = (customEmailObj) =>
  new Promise((resolve, reject) => {
    serverV
      .post('/custom_email', {
        custom_email: customEmailObj,
      })
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const updateCustomEmail = (id, customEmailObj) =>
  new Promise((resolve, reject) => {
    serverV
      .put(`/custom_email/${id}`, {
        custom_email: customEmailObj,
      })
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const getCustomEmail = () =>
  new Promise((resolve, reject) => {
    serverV
      .get('/custom_email')
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const deleteCustomEmail = (id) =>
  new Promise((resolve, reject) => {
    serverV
      .delete(`/custom_email/${id}`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const getNotificationPreferences = (id) =>
  new Promise((resolve, reject) => {
    server
      .get(`/communities/${id}/get_notification_preference`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const updateNotificationPreferences = (id, data) =>
  new Promise((resolve, reject) => {
    server
      .patch(`/communities/${id}/update_notification_preference`, data)
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const addZoomSettingKeys = (id, data) =>
  new Promise((resolve, reject) => {
    serverV
      .patch(`/communities/${id}/settings`, data)
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const getSettingKeys = (id) =>
  new Promise((resolve, reject) => {
    serverV
      .get(`/communities/${id}/get_settings`)
      .then((response) => {
        resolve(response?.data);
      })
      .catch(reject);
  });

export const setUserRoleOnInvite = (id, inviteCode) =>
  new Promise((resolve, reject) => {
    serverV
      .post(`/communities/${id}/user_community_roles/assign_self?invite_code=${inviteCode}`)
      .then((response) => {
        console.log(response);
        resolve(response?.data);
      })
      .catch(reject);
  });
