import _ from 'lodash';
import { all, fork, put, takeLatest } from 'redux-saga/effects';
import { parseAxiosErrorMessage } from '../constants/utils';
import { fetchWeeklyLeaderBoardData, fetchLeaderBoardData } from '../services/LeaderboardService';
import NotificationService from '../services/notificationService';
import {
  FETCH_LEADERBOARD,
  FETCH_LEADERBOARD_SUCCESS,
  REMOVE_USER_FROM_LEADERBOARD,
  UPDATE_LEADERBOARD_DATA,
} from '../store/actions/actionTypes';

function* fetchLeaderboardSaga() {
  yield takeLatest(FETCH_LEADERBOARD, function* ({ payload }) {
    try {
      const response = payload?.weekly ? yield fetchWeeklyLeaderBoardData(payload) : yield fetchLeaderBoardData(payload)
      const updatedResponse = _.cloneDeep(response);
      const coinsCount = [];
      /**
       * coins_count is an object with key as community user ID
       * and value as number of coins the user has. This is sent sorted from the backend
       * but while parsing in frontend JS sorts it based on key but it should be sorted by value.
       * So we have to manually sort it based on value(number of coins).
       * In case multiple users have same number of coins then sort them by key (community_user_id)
       * Since we have to loop through community user ID,
       * keep it(community_user_id) as a seperate array for convenience.
       */
      const coinsCountArr = Object.entries(updatedResponse.coins_count);
      const sortedCoinsCountArr = coinsCountArr.sort(([key1, a], [key2, b]) => {
        if (+a === +b) {
          return +key2 - +key1;
        }
        return +b - +a;
      });
      sortedCoinsCountArr.forEach((item) => {
        /**
         * if the user is removed from membership. user coins count is in the api response
         * but user data wouldn't be present in community_users object.
         * To avoid this issue check if user data associated to communityUserId
         * is present before adding to sorted array list
         */
        const communityUserId = item[0];
        coinsCount.push(communityUserId);
        if (!updatedResponse?.community_users[communityUserId] && updatedResponse) {
          updatedResponse.community_users[communityUserId] = {
            first_name: 'user',
            last_name: 'removed',
            user_image_url: null,
          };
        }
      });
      updatedResponse.new = payload.new;
      updatedResponse.userIds = coinsCount;
      updatedResponse.pagy = response.pagy;
      yield put({
        type: FETCH_LEADERBOARD_SUCCESS,
        payload: updatedResponse,
      });
    } catch (error) {
      const message = parseAxiosErrorMessage(error);
      NotificationService.error(message);
    }
  });
}

function* removeUserFromLeaderboard() {
  yield takeLatest(REMOVE_USER_FROM_LEADERBOARD, function* ({ payload }) {
    try {
      const getUserCommunityId = (leaderboardData, currentUserId) => {
        let id;
        leaderboardData?.userIds?.forEach((item) => {
          if (leaderboardData?.community_users[item]?.user_id === currentUserId) {
            id = leaderboardData?.community_users[item]?.id;
          }
        });
        return id;
      };

      const leaderboardData = {
        ...payload?.leaderboardData?.leaderboard,
      };
      const communityUserId = payload?.leaderboardData?.selectedUserCommunityId;
      const userId = communityUserId || getUserCommunityId(leaderboardData, payload?.userId);

      if (userId && leaderboardData) {
        leaderboardData.community_users[userId] = {
          first_name: 'user',
          last_name: 'removed',
        };
      }

      yield put({
        type: UPDATE_LEADERBOARD_DATA,
        payload: leaderboardData,
      });
    } catch (error) {
      NotificationService.error('Could not update leaderboard');
    }
  });
}

export default function* rootSaga() {
  yield all([fork(fetchLeaderboardSaga), fork(removeUserFromLeaderboard)]);
}
