export default function CommonTextArea({
  label,
  name,
  id,
  placeholder,
  onChange,
  onBlur,
  value = '',
  disabled,
  rows = 4,
}) {
  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text__body">
        {label}
      </label>
      <div className="mt-1">
        <textarea
          rows={rows}
          name={name}
          id={id}
          className="shadow-sm block w-full sm:text-sm border input card rounded-md"
          value={value}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
}
