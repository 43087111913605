import { createSelector } from 'reselect';
import { capitalizeFirstLetter } from '../constants/utils';

const getProfile = (state) => state.auth;

const getUser = (state) => state?.user;

export const getAccessToken = createSelector([getProfile], (profile) =>
  profile && profile.token ? profile.token : null,
);

export const getRefreshToken = createSelector([getProfile], (profile) =>
  profile && profile.refreshToken ? profile.refreshToken : null,
);

export const isUserLoggedIn = createSelector([getProfile], (profile) => profile && profile.token);

export const getUserProfile = createSelector([getProfile], (profile) => profile);

export const getUserData = createSelector([getUser], (user) => user);

export const getRoomToken = createSelector([getProfile], (profile) =>
  profile && profile.roomToken ? profile.roomToken : null,
);

export const getResolution = createSelector([getProfile], (profile) =>
  profile && profile.resolution ? profile.resolution : '360p',
);

export const getUserName = createSelector([getUser], (user) => user?.username);

export const getCurrentLoggedInUserName = createSelector(
  [getUser],
  (user) => `${capitalizeFirstLetter(user?.first_name)} ${capitalizeFirstLetter(user?.last_name)}`,
);

export const getCurrentLoggedInUserId = createSelector([getUser], (user) => user?.id);

export const getCurrentLoggedInUserImage = createSelector([getUser], (user) => user?.image);

export const getCurrentLoggedInUser = createSelector([getUser], (user) => user);

export const getLoggedInUserEmail = createSelector([getUser], (user) => user?.email);

export const getCurrentLoggedInUserRefCode = createSelector([getUser], (user) => user?.referral_code);

export const getRedirectUrl = createSelector([getProfile], (profile) => profile?.redirectUrl);
