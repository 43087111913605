import serverV3 from '../api/server';
import serverLess from '../api/serverVersionless';

export const getBannedUsers = (communityId, page = 1) =>
  serverV3.get(`/communities/${communityId}/community_banned_users`, {
    params: {
      page,
    },
  });

export const getModerationList = (communityId, page = 1) =>
  serverV3.get(`/communities/${communityId}/moderation`, {
    params: {
      page,
    },
  });

export const getUserReportedPosts = (communityId, moderationId, page = 1) =>
  serverV3.get(`/communities/${communityId}/moderation/${moderationId}`, {
    params: {
      page,
    },
  });

export const getUsersReported = (communityId, moderationId, page = 1) =>
  serverV3.get(`/communities/${communityId}/moderation/${moderationId}/user_reports`, {
    params: {
      page,
    },
  });

export const dismissReports = (communityId, moderationId) =>
  serverV3.delete(`/communities/${communityId}/moderation/${moderationId}/dismiss`);

export const banUser = (communityId, userId) =>
  serverV3.post(`/communities/${communityId}/community_banned_users`, {
    community_banned_user: {
      user_id: userId,
    },
  });

export const unbanUser = (banId) =>
  serverV3.delete(`/community_banned_users/${banId}`);

export const reportForumPost = (communityId, channelId, postId, body, groupId) =>
  serverV3.post(`/communities/${communityId}/groups/${groupId}/channels/${channelId}/forum_posts/${postId}/report`, {
    forum_post_report: {
      ...body,
    },
  });

export const reportForumComment = (communityId, channelId, postId, commentId, body, groupId) =>
  serverV3.post(
    `/communities/${communityId}/groups/${groupId}/channels/${channelId}/forum_posts/${postId}/forum_post_comments/${commentId}/report`,
    {
      forum_comment_report: {
        ...body,
      },
    },
  );

export const reportForumReply = (communityId, channelId, postId, commentId, replyId, body, groupId) =>
  serverV3.post(
    `/communities/${communityId}/groups/${groupId}/channels/${channelId}/forum_posts/${postId}/forum_post_comments/${commentId}/forum_comment_replies/${replyId}/report`,
    {
      forum_reply_report: {
        ...body,
      },
    },
  );

export const reportResourceComment = (communityId, channelId, postId, commentId, body, groupId) =>
  serverV3.post(
    `/communities/${communityId}/groups/${groupId}/channels/${channelId}/resource_posts/${postId}/resource_comments/${commentId}/report`,
    {
      resource_comment_report: {
        ...body,
      },
    },
  );

export const reportUser = (data) =>
  serverV3.post(
    `/users/profiles/report_user`, data
  );

export const dismissSingleUserReport = (communityId, moderationId, userReportId) => {
  return serverV3.delete(`/communities/${communityId}/moderation/${moderationId}/remove_user_report?user_report_id=${userReportId}`)
}