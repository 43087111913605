import React from 'react';
import { Tooltip } from 'react-tooltip';
import { XIcon } from '@heroicons/react/outline';
import UserRolesDropdown from '../../views/settings/peopleManagement/UserRolesDropdown';
import { PLACE_TYPE } from '../../constants';

function RenderRoles({ userRoles, rolesArray = [], deleteUserRole, position='', placeType='', managePermission=true }) {
  let firstRoleInList = [];
  let roleArray = []
  if (rolesArray?.length === 0) {
    const firstRoleInList_ = userRoles[0] || {};
    if (placeType === PLACE_TYPE.MANAGE_ROLE) {
        roleArray = userRoles?.map((item) => ({
        color: item?.community_role?.color,
        role: item?.community_role?.role,
        community_id: item?.community_role?.community_id,
        community_role_id: item?.community_role_id,
        id: item?.id,
      }));
      const firstRoleInList_ = roleArray[0] || {};
      firstRoleInList = { ...firstRoleInList_ };
    } else if (firstRoleInList?.id) {
      firstRoleInList = { ...firstRoleInList_, role: firstRoleInList_?.label };
    } else {
      firstRoleInList = {
        ...firstRoleInList_,
        role: firstRoleInList_?.label || firstRoleInList_?.title || firstRoleInList_?.role,
        id: `${firstRoleInList_.title}`,
      };
    }
  } else {
    firstRoleInList = rolesArray.find((role) => role.id === userRoles[0]) || {};
  }

  return (
    <div className={`flex flex-row items-center justify-${position==='left' ? "center" : "end"} gap-1 flex-wrap`}>
      <div
        key={firstRoleInList.id}
        className="flex items-center text-xs rounded-3xl py-1 px-3 capitalize text-gray-800"
        style={{ backgroundColor: firstRoleInList.color }}
      >
        <div
          className={`inline-block ${firstRoleInList?.role?.length > 10 ? 'truncate max-w-[72px]' : ''}`}
          data-tooltip-content={firstRoleInList?.role}
          data-tooltip-id={firstRoleInList?.role?.split(`'`).join()}
        >
          {firstRoleInList?.role}
          {firstRoleInList?.role?.length > 10 && (
            <Tooltip
              id={firstRoleInList?.role?.split(`'`).join()}
              effect="solid"
              style={{ whiteSpace: 'break-spaces', maxWidth: '200px', overflowWrap: 'break-word' }}
            />
          )}
        </div>
        {firstRoleInList?.id && managePermission && firstRoleInList?.role?.toLowerCase() !== 'owner' && (
          <XIcon
            className="ml-1 inline h-4 w-4 rounded-full cursor-pointer hover:bg-gray-300"
            onClick={() => {
              if(deleteUserRole && typeof deleteUserRole === 'function') {
                deleteUserRole(placeType === PLACE_TYPE.MANAGE_ROLE ? firstRoleInList : firstRoleInList?.id, userRoles.length === 1);
              }              
            }}
          />
        )}
      </div>
      <UserRolesDropdown
        userRoles={placeType === PLACE_TYPE.MANAGE_ROLE ? roleArray : userRoles}
        rolesArray={rolesArray}
        deleteUserRole={deleteUserRole}
        position={position}
        managePermission={managePermission}
        placeType={placeType}
      />
    </div>
  );
}

export default RenderRoles;
