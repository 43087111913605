import {
  VolumeUpIcon,
  ChatIcon,
  CubeIcon,
  CalendarIcon,
  DocumentTextIcon,
  BookmarkIcon,
  PresentationChartLineIcon,
  CodeIcon,
  VideoCameraIcon,
} from '@heroicons/react/outline';
import { CHANNEL_TYPES } from '../../constants';

const commonClassName = 'h-5 w-5 ltr:mr-2 rtl:ml-2 flex-none channel__icon';

export const CHANNEL_ACCENT_TYPE = {
  DEFAULT: '#1F2937',
  PHILIPPINE_SILVER: '#b8b8b8',
  GRAY_400: '#9CA3AF',
  WHITE: "#ffffff"
};

export default function ChannelIcon({
  channelType,
  iconClassName = commonClassName,
  accent = CHANNEL_ACCENT_TYPE.DEFAULT,
}) {
  switch (channelType) {
    case CHANNEL_TYPES.FORUM:
      return <DocumentTextIcon color={accent ? CHANNEL_ACCENT_TYPE.WHITE : CHANNEL_ACCENT_TYPE.DEFAULT} className={iconClassName} />;

    case CHANNEL_TYPES.STAGE:
      return <VolumeUpIcon color={accent} className={iconClassName} />;

    case CHANNEL_TYPES.STREAMING:
      return <VideoCameraIcon color={accent} className={iconClassName} />;

    case CHANNEL_TYPES.RESOURCE:
      return <CubeIcon color={accent} className={iconClassName} />;

    case CHANNEL_TYPES.CHAT:
      return <ChatIcon color={accent} className={iconClassName} />;

    case CHANNEL_TYPES.CALENDER:
      return <CalendarIcon color={accent} className={iconClassName} />;

    case CHANNEL_TYPES.BOOKMARKS:
      return <BookmarkIcon color={accent} className={iconClassName} />;

    case CHANNEL_TYPES.ZOOM:
      return <PresentationChartLineIcon color={accent} className={iconClassName} />;

    case CHANNEL_TYPES.EMBED:
      return <CodeIcon color={accent} className={iconClassName} />;

    default:
      return null;
  }
}
