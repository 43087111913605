import { Fragment } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react';

export default function CommonBasicDropdown({
  dropdownButton,
  menuItems = [],
  onChange = () => {},
  toRenderMenuItem = () => true,
}) {
  return (
    <Menu as="div" className="relative inline-block text-left z-20 w-8">
      <div>
        <MenuButton className="rounded flex items-center text-gray-400 hover:text-gray-600 focus:outline-none usetiful-profile-icon">
          {dropdownButton}
        </MenuButton>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <MenuItems className="ltr:origin-top-right rtl:origin-top-left absolute ltr:right-0 rtl:left-0 mt-2 w-56 rounded-md shadow-lg menu__options__container border focus:outline-none">
          <div className="py-1">
            {menuItems.map(
              (menu, key) =>
                toRenderMenuItem(menu) && (
                  <MenuItem key={key} onClick={() => onChange(menu.value)}>
                    <div
                      aria-label={menu.id}
                      className={
                        'group flex items-center cursor-pointer px-4 py-2 text-sm menu__option ' +
                        (menu.className || '')
                      }
                    >
                      {menu.label}
                    </div>
                  </MenuItem>
                ),
            )}
          </div>
        </MenuItems>
      </Transition>
    </Menu>
  );
}
