import { all, fork, put, takeLatest } from 'redux-saga/effects';
import { getSettingKeys } from '../services/communityService';
import { FETCH_MIXPANEL_ATTRIBUTES, SET_MIXPANEL_ATTRIBUTES } from '../store/actions/actionTypes';

function* setMixpanelAttributes({ payload }) {
  try {
    const response = yield getSettingKeys(payload);
    yield put({
      type: SET_MIXPANEL_ATTRIBUTES,
      payload: {
        mixpanelToken: response?.data?.settings?.mixpanel_project_id || null,
        internalIdentifier: payload || null,
      },
    });
  } catch (error) {
    console.log('Error in mixpanel saga', error);
  }
}

function* fetchMixpanelAttributes() {
  yield takeLatest(FETCH_MIXPANEL_ATTRIBUTES, setMixpanelAttributes);
}

export default function* mixpanelSaga() {
  yield all([fork(fetchMixpanelAttributes)]);
}
