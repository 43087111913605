import { useState, Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Dialog, Transition, TransitionChild } from '@headlessui/react';
import * as UpChunk from '@mux/upchunk';
import { VideoCameraIcon } from '@heroicons/react/solid';
import { XIcon } from '@heroicons/react/outline';
import ReactPlayer from 'react-player';
import NotificationService from '../../services/notificationService';
import { getStorageUrlMux } from '../../services/VideoUploadService';
import { getActiveCommunity, getActiveCommunityAccentColor } from '../../selectors/CommunitySelectors';
import FileInputPicker from '../shared/FileInputPicker';
import { parseAxiosErrorMessage } from '../../constants/utils';

function AttachVideo({
  muxPassthrough,
  setFieldValue,
  currentVideoUrl,
  media_types,
  viewText,
  isOverview,
  addedVideo = null,
  setAddedVideo = { file: null, url: '', mediaTypes: {} },
  hideText,
  videoUrl,
  setVideoUrl,
}) {
  const communityId = useSelector(getActiveCommunity);
  const mux_token = useSelector((state) => state?.community?.publicCommunityDetails?.settings?.mux_token);
  const [showVidaoDetailsModal, setShowVideoDetailsModal] = useState(false);
  const [fileTitle, setFileTitle] = useState('');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploadUpchunk, setUploadUpchunk] = useState(null);
  // const [videoUrl, setVideoUrl] = useState('');
  const [mediaType, setMediaType] = useState(null);

  const convertToFileObj = (data) => {
    const videoData = data;
    const blobData = new Blob([videoData], { type: 'video/mp4' });
    const fileData = new File([blobData], 'video.mp4', { type: 'video/mp4' });
    return fileData;
  };

  useEffect(() => {
    if (addedVideo?.file) {
      const newFileObj = convertToFileObj(addedVideo?.file);
      processVideoFile(newFileObj);
    }
  }, [addedVideo?.file]);

  const processVideoFile = async (file) => {
    let fileNameList = file.type.split('/');
    let fileType = '';
    if (fileNameList && fileNameList.length > 0) fileType = fileNameList[0];
    try {
      setFileTitle(file.name);
      setShowVideoDetailsModal(true);
      setLoading(true);
      const response = await getStorageUrlMux(communityId?.id, muxPassthrough);
      const url = response?.data?.data?.url;

      const upload = UpChunk.createUpload({
        endpoint: url, // Authenticated url
        file: file, // File object
        chunkSize: 5120, // Uploads the file in ~5mb chunks
      });

      setUploadUpchunk(upload);

      // Subscribe to events
      upload.on('error', (error) => {
        console.log(error);
        NotificationService.error(parseAxiosErrorMessage(error));
      });

      upload.on('progress', (progress) => {
        setProgress(progress.detail);
      });

      upload.on('success', () => {
        if (isOverview) {
          setFieldValue({ video_upload_ids: [response?.data?.data?.id], videoChange: 'added' });
          setProgress(0);
          setShowVideoDetailsModal(false);
          setLoading(false);
        } else {
          setFieldValue('video_upload_ids', [response?.data?.data?.id]);
          setFieldValue('videoChange', 'added');
          setProgress(0);
          setShowVideoDetailsModal(false);
          setLoading(false);
        }

        setVideoUrl(URL.createObjectURL(file));
        setAddedVideo((fileObj) => ({ ...fileObj, url: URL.createObjectURL(file) }));
        setMediaType(fileType);
      });
    } catch (error) {
      if (error?.response?.data?.error === 'Internal Server Error') {
        NotificationService.error('Invalid Mux Token');
      } else {
        NotificationService.error(parseAxiosErrorMessage(error));
      }
      setShowVideoDetailsModal(false);
      setLoading(false);
    }
  };

  const handleVideoAdd = (e) => {
    let file = e.target.files[0];
    processVideoFile(file);
    e.target.value = null;
  };

  return (
    <>
      {Object.keys(media_types)?.length !== 0 ? (
        Object.entries(media_types)?.map((playbackId) => (
          <div className="mt-2" key={playbackId[0]}>
            <XIcon
              className="ml-auto w-5 h-5 mb-2 cursor-pointer"
              onClick={() => {
                if (isOverview) {
                  setFieldValue({ video_upload_ids: [], videoChange: 'removed' });
                } else {
                  setFieldValue('videoChange', 'removed');
                }
                setFieldValue('video_upload_ids', []);
                setFieldValue('media_types', {});
                setAddedVideo({ file: null, url: '', mediaTypes: {} });
              }}
            />
            <ReactPlayer
              url={`https://stream.mux.com/${playbackId[0]}.m3u8`}
              controls
              width={playbackId[1]?.toLowerCase() === 'video' ? '100%' : '280px'}
              height={playbackId[1]?.toLowerCase() === 'video' ? '400px' : '60px'}
              style={{}}
            />
          </div>
        ))
      ) : videoUrl ? (
        <div className="mt-2">
          <XIcon
            className="ml-auto w-5 h-5 mb-2 cursor-pointer"
            onClick={() => {
              if (isOverview) {
                setFieldValue({ video_upload_ids: [], videoChange: 'removed' });
              } else {
                setFieldValue('video_upload_ids', []);
              }
              setVideoUrl('');
              setAddedVideo({ file: null, url: '', mediaTypes: {} });
            }}
          />
          <ReactPlayer
            url={videoUrl}
            controls
            width={mediaType?.toLowerCase() === 'video' ? '100%' : videoUrl ? '100%' : '280px'}
            style={{ maxHeight: mediaType?.toLowerCase() === 'video' ? '400px' : videoUrl ? '400px' : '60px' }}
          />
        </div>
      ) : (
        mux_token && (
          <button className="btn__light rounded-md" type="button">
            <FileInputPicker
              onClick={(e) => (e.target.value = null)}
              onChange={handleVideoAdd}
              id="attach_video"
              accept="video/mp4,video/x-m4v,video/*,audio/*"
            >
              <div className="flex justify-center px-4 py-2 cursor-pointer">
                <VideoCameraIcon className="h-5 w-5" />
                {!hideText && <span className="pl-2">Add Multimedia</span>}
              </div>
            </FileInputPicker>
          </button>
        )
      )}
      <VideoModal
        open={showVidaoDetailsModal}
        onClose={() => {
          setShowVideoDetailsModal(false);
          setProgress(0);
        }}
        progress={progress}
        uploadUpchunk={uploadUpchunk}
        setProgress={setProgress}
        fileTitle={fileTitle}
        setAddedVideo={setAddedVideo}
      />
    </>
  );
}

export default AttachVideo;

function VideoModal({ open, progress, setProgress, onClose, fileTitle, uploadUpchunk, setAddedVideo }) {
  const accentColor = useSelector(getActiveCommunityAccentColor);
  return (
    <Transition show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-50 inset-0 overflow-y-auto" onClose={() => {}}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center md:block md:p-0">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </TransitionChild>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden md:inline-block md:align-middle md:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
            enterTo="opacity-100 translate-y-0 md:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 md:scale-100"
            leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
          >
            <div className="inline-block align-bottom card justify-center rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all md:my-8 md:align-middle md:max-w-lg md:w-full md:p-6">
              <div className="my-2">
                <div className="flex items-center justify-center gap-4">
                  <div className="bg-gray-200 p-3 rounded-lg ltr:mr-2 rtl:ml-2">
                    <VideoCameraIcon className="w-6 h-6" />
                  </div>
                  <div className="w-full">
                    <div>{fileTitle}</div>
                    <div className="w-full bg-gray-200 h-1 mt-1">
                      <div className="h-1" style={{ width: `${progress}%`, background: accentColor }} />
                    </div>
                    <p className="mt-0.5 text__title text-right text-xs">{Math.round(progress)}%</p>
                  </div>
                  <XIcon
                    className="w-7 h-7 main__icon cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      onClose();
                      uploadUpchunk.abort();
                      setAddedVideo({ file: null, url: '', mediaTypes: {} });
                    }}
                  />
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
}
