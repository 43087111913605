/* eslint-disable no-case-declarations */
import {
  UPDATE_OFF_LINE_CHAT_STATE,
} from '../actions/actionTypes';

const initialState = {
  stageMessages: [],
  textMessages: [],
  privateMessages: [],
 
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_OFF_LINE_CHAT_STATE:
      return { ...state, ...payload };
    default:
      return state;
  }
};

export default reducer;
