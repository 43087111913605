import { useSelector } from 'react-redux';
import { handleDeleteChannel } from './utils';
import { getActiveCommunityId, getActiveGroupId } from '../../selectors/CommunitySelectors';
import { matchPath } from 'react-router-dom';
import { useState } from 'react';

const DeleteConfirmationModal = ({ setOpenDeleteConfirmation }) => {
  const activeCommunityId = useSelector(getActiveCommunityId);
  const activeGroupId = useSelector(getActiveGroupId);
  const [loading, setLoading] = useState(false);

  let channelSettingsPath = matchPath(location.pathname, { path: '/channel-settings/:channel_id' });
  let channel_id = channelSettingsPath?.params?.channel_id;

  return (
    <div className="fixed inset-0 z-50 min-h-screen">
      <div className="relative w-full h-full flex justify-center items-center pt-4 px-4 pb-20 text-center">
        <div
          className="absolute inset-0 bg-gray-500 bg-opacity-75"
          onClick={(e) => {
            e.stopPropagation();
            setOpenDeleteConfirmation(false);
          }}
        ></div>
        <div className="inline-block align-bottom card rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all md:my-8 md:align-middle md:max-w-lg md:w-full md:p-6">
          <div className="md:flex md:items-start">
            <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 md:mx-0 md:h-10 md:w-10">
              {/* <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" /> */}
            </div>
            <div className="mt-3 text-center md:mt-0 md:ml-4 md:text-left">
              <h3 className="text-lg leading-6 font-medium text__title">Delete Channel</h3>
              <div className="mt-2">
                <p className="text-sm text__description">
                  Are you sure you want to delete this channel? All of your data will be permanently removed from our
                  servers forever. This action cannot be undone.
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 md:mt-4 md:flex md:flex-row-reverse">
            <button
              type="button"
              className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-0 focus:ring-offset-0 md:ml-3 md:w-auto md:text-sm btn__danger disabled:opacity-50"
              onClick={(e) => {
                e.stopPropagation();
                setLoading(true);
                handleDeleteChannel(
                  setOpenDeleteConfirmation,
                  activeCommunityId,
                  channel_id,
                  activeGroupId,
                  setLoading,
                );
              }}
              disabled={loading}
            >
              Delete Channel
            </button>
            <button
              type="button"
              className="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 text-base font-medium focus:outline-none focus:ring-0 focus:ring-offset-0 md:mt-0 md:w-auto md:text-sm btn__light"
              onClick={(e) => {
                e.stopPropagation();
                setOpenDeleteConfirmation(false);
              }}
            >
              {$translatei18n('Cancel')}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;
