import React from 'react';

export default function FileInputPicker({
  children, parentClassName = '', id, accept = 'image/*,image/heif,image/heic', ...rest
}) {
  if (!children) {
    console.error('Please provide children for FileInputPicker to view file input');
  }
  if (!id) {
    console.error('Please provide unique id for FileInputPicker');
  }
  return (
    <label className={parentClassName} htmlFor={id}>
      {children}
      <input id={id} type="file" accept={accept} className="hidden" {...rest} />
    </label>
  );
}
