/** AUTH ACTIONS * */
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';

export const CHECK_EMAIL_REQUEST = 'CHECK_EMAIL_REQUEST';
export const CHECK_EMAIL_SUCCESS = 'CHECK_EMAIL_SUCCESS';
export const CHECK_EMAIL_FAILED = 'CHECK_EMAIL_FAILED';
export const CHECK_EMAIL_DELETE = 'CHECK_EMAIL_DELETE';

export const SIGN_UP_WITH_EMAIL_REQUEST = 'SIGN_UP_WITH_EMAIL_REQUEST';
export const SIGN_UP_WITH_EMAIL_SUCCESS = 'SIGN_UP_WITH_EMAIL_SUCCESS';
export const SIGN_UP_WITH_EMAIL_FAILED = 'SIGN_UP_WITH_EMAIL_FAILED';

export const EMAIL_RESEND_OTP_REQUEST = 'EMAIL_RESEND_OTP_REQUEST';
export const EMAIL_RESEND_OTP_SUCCESS = 'EMAIL_RESEND_OTP_SUCCESS';
export const EMAIL_RESEND_OTP_FAILED = 'EMAIL_RESEND_OTP_FAILED';

export const AUTH_SET_TOKEN = 'AUTH_SET_TOKEN';
export const AUTH_SET_REFRESH_TOKEN = 'AUTH_SET_REFRESH_TOKEN';
export const AUTH_LOG_OUT = 'AUTH_LOG_OUT';
export const AUTH_LOG_OUT_BACKGROUD = 'AUTH_LOG_OUT_BACKGROUD';
export const AUTH_LOG_OUT_SUCCESS = 'AUTH_LOG_OUT_SUCCESS';
export const SET_IS_MANUAL_LOGOUT = 'SET_IS_MANUAL_LOGOUT';
export const AUTH_SET_ROOM_TOKEN = 'AUTH_SET_ROOM_TOKEN';
export const AUTH_DELETE_ROOM_TOKEN = 'AUTH_DELETE_ROOM_TOKEN';
export const FETCHING_PROFILE_DATA = 'FETCHING_PROFILE_DATA';
export const AUTH_SET_ONBOARDED = 'AUTH_SET_ONBOARDED';
export const AUTH_DELETE_ONBOARDED = 'AUTH_DELETE_ONBOARDED';
export const LOGIN_USER_ID = 'LOGIN_USER_ID';
export const LOGINED_USER_EMAIL = 'LOGINED_USER_EMAIL';
export const SET_REDIRECT_URL = 'SET_REDIRECT_URL';
export const UPDATE_CONNECTION_STATE = 'UPDATE_CONNECTION_STATE';
export const SET_QUERY_STRING = 'SET_QUERY_STRING';

export const SET_USER = 'SET_USER';
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';

export const FETCH_FOLLOWERS_REQUEST = 'FETCH_FOLLOWERS_REQUEST';
export const FETCH_FOLLOWERS_SUCCESS = 'FETCH_FOLLOWERS_SUCCESS';
export const FETCH_FOLLOWEE_REQUEST = 'FETCH_FOLLOWEE_REQUEST';
export const FETCH_FOLLOWEE_SUCCESS = 'FETCH_FOLLOWEE_SUCCESS';

export const GOOGLE_SIGN_IN_REQUEST = 'GOOGLE_SIGN_IN_REQUEST';
export const GOOGLE_SIGN_IN_SUCCESS = 'GOOGLE_SIGN_IN_SUCCESS';
export const GOOGLE_SIGN_IN_FAILED = 'GOOGLE_SIGN_IN_FAILED';

export const BUBBLE_SIGN_IN_REQUEST = 'BUBBLE_SIGN_IN_REQUEST';
export const BUBBLE_SIGN_IN_SUCCESS = 'BUBBLE_SIGN_IN_SUCCESS';
export const BUBBLE_SIGN_IN_FAILED = 'BUBBLE_SIGN_IN_FAILED';

export const CUSTOM_LOGIN_REQUEST = 'CUSTOM_LOGIN_REQUEST';
export const CUSTOM_LOGIN_SUCCESS = 'CUSTOM_LOGIN_SUCCESS';
export const CUSTOM_LOGIN_FAILED = 'CUSTOM_LOGIN_FAILED';

export const SET_STAGE_AUDIO_PERMISSION_ACCEPTED = 'SET_STAGE_AUDIO_PERMISSION_ACCEPTED';

export const SET_STAGE_VIDEO_PERMISSION_ACCEPTED = 'SET_STAGE_VIDEO_PERMISSION_ACCEPTED';
export const APPLE_SIGN_IN_REQUEST = 'APPLE_SIGN_IN_REQUEST';

/** ONBOARDING ACTIONS * */

export const ONBOARDING_VERIFY_EMAIL_REQUEST = 'ONBOARDING_VERIFY_EMAIL_REQUEST';
export const ONBOARDING_VERIFY_EMAIL_SUCCESS = 'ONBOARDING_VERIFY_EMAIL_SUCCESS';
export const ONBOARDING_VERIFY_EMAIL_FAILED = 'ONBOARDING_VERIFY_EMAIL_FAILED';

export const ONBOARDING_ADD_PHONE_NUMBER_REQUEST = 'ONBOARDING_ADD_PHONE_NUMBER_REQUEST';
export const ONBOARDING_ADD_PHONE_NUMBER_SUCCESS = 'ONBOARDING_ADD_PHONE_NUMBER_SUCCESS';
export const ONBOARDING_ADD_PHONE_NUMBER_FAILED = 'ONBOARDING_ADD_PHONE_NUMBER_FAILED';
export const ONBOARDING_DELETE_PHONE_NUMBER = 'ONBOARDING_DELETE_PHONE_NUMBER';

export const ONBOARDING_VERIFY_PHONE_NUMBER_REQUEST = 'ONBOARDING_VERIFY_PHONE_NUMBER_REQUEST';
export const ONBOARDING_VERIFY_PHONE_NUMBER_SUCCESS = 'ONBOARDING_VERIFY_PHONE_NUMBER_SUCCESS';
export const ONBOARDING_VERIFY_PHONE_NUMBER_FAILED = 'ONBOARDING_VERIFY_PHONE_NUMBER_FAILED';

export const ONBOARDING_RESEND_PHONE_OTP_REQUEST = 'ONBOARDING_RESEND_PHONE_OTP_REQUEST';
export const ONBOARDING_RESEND_PHONE_OTP_SUCCESS = 'ONBOARDING_RESEND_PHONE_OTP_SUCCESS';
export const ONBOARDING_RESEND_PHONE_OTP_FAILED = 'ONBOARDING_RESEND_PHONE_OTP_FAILED';

export const ONBOARDING_COMPLETE_PROCESS_REQUEST = 'ONBOARDING_COMPLETE_PROCESS_REQUEST';
export const ONBOARDING_COMPLETE_PROCESS_SUCCESS = 'ONBOARDING_COMPLETE_PROCESS_SUCCESS';
export const ONBOARDING_COMPLETE_PROCESS_FAILED = 'ONBOARDING_COMPLETE_PROCESS_FAILED';

export const UPDATE_VOICE_ONBOARDING_STATUS_REQUEST = 'UPDATE_VOICE_ONBOARDING_STATUS_REQUEST';
export const UPDATE_VOICE_ONBOARDING_STATUS_SUCCESS = 'UPDATE_VOICE_ONBOARDING_STATUS_SUCCESS';
export const UPDATE_VOICE_ONBOARDING_STATUS_FAILED = 'UPDATE_VOICE_ONBOARDING_STATUS_FAILED';

export const ONBOARDING_VERIFY_COMMUNITY_STEP_SUCCESS = 'ONBOARDING_VERIFY_COMMUNITY_STEP_SUCCESS';
export const ONBOARDING_VERIFY_COMMUNITY_STEP_UPDATE = 'ONBOARDING_VERIFY_COMMUNITY_STEP_UPDATE';
export const ONBOARDING_VERIFY_COMMUNITY_STEP_RESET = 'ONBOARDING_VERIFY_COMMUNITY_STEP_RESET';

export const FETCH_PUBLIC_COMMUNITY_DETAILS_REQUEST = 'FETCH_PUBLIC_COMMUNITY_DETAILS_REQUEST';
export const FETCH_PUBLIC_COMMUNITY_DETAILS_SUCCESS = 'FETCH_PUBLIC_COMMUNITY_DETAILS_SUCCESS';

export const UPDATE_PUBLIC_COMMUNITY_SETTINGS_REQUEST = 'UPDATE_PUBLIC_COMMUNITY_SETTINGS_REQUEST';
export const UPDATE_PUBLIC_COMMUNITY_SETTINGS = 'UPDATE_PUBLIC_COMMUNITY_SETTINGS';

export const CREATE_COMMUNITY_REQUEST = 'CREATE_COMMUNITY_REQUEST';
export const CREATE_COMMUNITY_SUCCESS = 'CREATE_COMMUNITY_SUCCESS';

export const UPDATE_COMMUNITY_REQUEST = 'UPDATE_COMMUNITY_REQUEST';
export const UPDATE_COMMUNITY_SUCCESS = 'UPDATE_COMMUNITY_SUCCESS';
export const UPDATE_COMMUNITY_FAILED = 'UPDATE_COMMUNITY_FAILED';

export const SET_ACTIVE_COMMUNITY_REQUEST = 'SET_ACTIVE_COMMUNITY_REQUEST';
export const SET_ACTIVE_COMMUNITY = 'SET_ACTIVE_COMMUNITY';
export const SET_COMMUNITY_LOADING = 'SET_COMMUNITY_LOADING';

export const FETCH_ALL_COMMUNITY_REQUEST = 'FETCH_ALL_COMMUNITY_REQUEST';
export const FETCH_ALL_COMMUNITY_SUCCESS = 'FETCH_ALL_COMMUNITY_SUCCESS';

export const SET_NEW_ACTIVE_COMMUNITY_WITH_CHANNEL_REQUEST = 'SET_NEW_ACTIVE_COMMUNITY_WITH_CHANNEL_REQUEST';

export const FETCH_COMMUNITY_FEED = 'FETCH_COMMUNITY_FEED';
export const FETCH_COMMUNITY_FEED_SUCCESS = 'FETCH_COMMUNITY_FEED_SUCCESS';
export const FETCH_COMMUNITY_FEED_SET_PAGE = 'FETCH_COMMUNITY_FEED_SET_PAGE';
export const COMMUNITY_FEED_RESET = 'COMMUNITY_FEED_RESET';

export const COMMUNITY_RESOURCE_FEED_SET_LIKE = 'COMMUNITY_RESOURCE_FEED_SET_LIKE';

export const UPDATE_COMMUNITY_DATA = 'UPDATE_COMMUNITY_DATA';

export const SET_INITIAL_COMMUNITY_DATA = 'SET_INITIAL_COMMUNITY_DATA';

export const CHANGE_COMMUNITY_APPEARANCE_REQUEST = 'CHANGE_COMMUNITY_APPEARANCE_REQUEST';
export const CHANGE_COMMUNITY_APPEARANCE_SUCCESS = 'CHANGE_COMMUNITY_APPEARANCE_SUCCESS';
export const CHANGE_COMMUNITY_APPEARANCE_FAILED = 'CHANGE_COMMUNITY_APPEARANCE_FAILED';

export const ADD_CUSTOM_DOMAIN_REQUEST = 'ADD_CUSTOM_DOMAIN_REQUEST';
export const ADD_CUSTOM_DOMAIN_SUCCESS = 'ADD_CUSTOM_DOMAIN_SUCCESS';
export const ADD_CUSTOM_DOMAIN_FAILED = 'ADD_CUSTOM_DOMAIN_FAILED';

export const VERIFY_CUSTOM_DOMAIN_REQUEST = 'VERIFY_CUSTOM_DOMAIN_REQUEST';
export const VERIFY_CUSTOM_DOMAIN_SUCCESS = 'VERIFY_CUSTOM_DOMAIN_SUCCESS';
export const VERIFY_CUSTOM_DOMAIN_FAILED = 'VERIFY_CUSTOM_DOMAIN_FAILED';

export const FETCH_CUSTOM_DOMAIN_DATA_REQUEST = 'FETCH_CUSTOM_DOMAIN_DATA_REQUEST';

export const REMOVE_CUSTOM_DOMAIN_REQUEST = 'REMOVE_CUSTOM_DOMAIN_REQUEST';
export const REMOVE_CUSTOM_DOMAIN_SUCCESS = 'REMOVE_CUSTOM_DOMAIN_SUCCESS';
export const REMOVE_CUSTOM_DOMAIN_FAILED = 'REMOVE_CUSTOM_DOMAIN_FAILED';

export const ADD_CUSTOM_CSS_REQUEST = 'ADD_CUSTOM_CSS_REQUEST';
export const ADD_CUSTOM_CSS_SUCCESS = 'ADD_CUSTOM_CSS_SUCCESS';
export const ADD_CUSTOM_CSS_FAILED = 'ADD_CUSTOM_CSS_FAILED';
export const REMOVE_CUSTOM_CSS_REQUEST = 'REMOVE__CUSTOM_CSS_REQUEST';
export const REMOVE_CUSTOM_CSS_SUCCESS = 'REMOVE__CUSTOM_CSS_SUCCESS';
export const REMOVE_CUSTOM_CSS_FAILED = 'REMOVE__CUSTOM_CSS_FAILED';
export const SET_HAS_CHANGED_CSS = 'SET_HAS_CHANGED_CSS';

export const ADD_MOBILE_THEMING_REQUEST = 'ADD_MOBILE_THEMING_REQUEST';

export const VERIFY_COMMUNITY_MEMBER_REQUEST = 'VERIFY_COMMUNITY_MEMBER_REQUEST';
export const VERIFY_COMMUNITY_MEMBER_SUCCESS = 'VERIFY_COMMUNITY_MEMBER_SUCCESS';
export const VERIFY_COMMUNITY_MEMBER_ROLES_SUCCESS = 'VERIFY_COMMUNITY_MEMBER_ROLES_SUCCESS';
export const SET_COMMUNITY_MEMBER_FLAG = 'SET_COMMUNITY_MEMBER_FLAG';
export const VERIFY_COMMUNITY_MEMBER_FAILED = 'VERIFY_COMMUNITY_MEMBER_FAILED';
export const SET_VERIFY_COMMUNITY_MEMBER_LOADING = 'SET_VERIFY_COMMUNITY_MEMBER_LOADING';
export const SET_PENDING_MEMBERS_COUNT = 'SET_PENDING_MEMBERS_COUNT';
export const SET_MEMBERS_COUNT = 'SET_MEMBERS_COUNT';

export const SET_COMMUNITY_PERMISSIONS = 'SET_COMMUNITY_PERMISSIONS';

export const UPDATE_CUSTOM_SSO_PUBLIC_COMMUNITY_DETAILS = 'UPDATE_CUSTOM_SSO_PUBLIC_COMMUNITY_DETAILS';
export const REMOVE_CUSTOM_SSO_PUBLIC_COMMUNITY_DETAILS = 'REMOVE_CUSTOM_SSO_PUBLIC_COMMUNITY_DETAILS';

/** Group */
export const TOGGLE_GROUPS_REQUEST = 'TOGGLE_GROUPS_REQUEST';
export const TOGGLE_GROUPS_SUCCESS = 'TOGGLE_GROUPS_SUCCESS';

export const CREATE_GROUP_REQUEST = 'CREATE_GROUP_REQUEST';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_FAILED = 'CREATE_GROUP_FAILED';

export const UPDATE_GROUP_REQUEST = 'UPDATE_GROUP_REQUEST';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAILED = 'UPDATE_GROUP_FAILED';

export const DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAILED = 'DELETE_GROUP_FAILED';

export const FETCH_GROUPS_REQUEST = 'FETCH_GROUPS_REQUEST';
export const FETCH_GROUPS_SUCCESS = 'FETCH_GROUPS_SUCCESS';
export const FETCH_GROUPS_FAILED = 'FETCH_GROUPS_FAILED';

export const SET_ACTIVE_GROUP_REQUEST = 'SET_ACTIVE_GROUP_REQUEST';
export const SET_ACTIVE_GROUP = 'SET_ACTIVE_GROUP';
export const SET_GROUP_LOADING = 'SET_GROUP_LOADING';

/** CHANNELS ACTIONS * */
export const FETCH_ALL_COMMUNITY_CHANNELS_REQUEST = 'FETCH_ALL_COMMUNITY_CHANNELS_REQUEST';
export const FETCH_ALL_COMMUNITY_CHANNELS_SUCCESS = 'FETCH_ALL_COMMUNITY_CHANNELS_SUCCESS';

export const SET_ACTIVE_CHANNEL = 'SET_ACTIVE_CHANNEL';

export const SET_CURRENT_CHANNELS = 'SET_CURRENT_CHANNELS';

export const SET_ACTIVE_CHANNEL_ID = 'SET_ACTIVE_CHANNEL_ID';
export const SET_ACTIVE_CHANNEL_TYPE = 'SET_ACTIVE_CHANNEL_TYPE';

export const DELETE_ACTIVE_CHANNEL = 'DELETE_ACTIVE_CHANNEL';

export const CREATE_CHANNEL_REQUEST = 'CREATE_CHANNEL_REQUEST';
export const CREATE_CHANNEL_SUCCESS = 'CREATE_CHANNEL_SUCCESS';

export const UPDATE_CHANNEL_REQUEST = 'UPDATE_CHANNEL_REQUEST';
export const UPDATE_CHANNEL_SUCCESS = 'UPDATE_CHANNEL_SUCCESS';

export const CREATE_CHANNEL_CATEGORY_REQUEST = 'CREATE_CHANNEL_CATEGORY_REQUEST';
export const CREATE_CHANNEL_CATEGORY_SUCCESS = 'CREATE_CHANNEL_CATEGORY_SUCCESS';

export const UPDATE_CHANNEL_CATEGORY_REQUEST = 'UPDATE_CHANNEL_CATEGORY_REQUEST';
export const UPDATE_CHANNEL_CATEGORY_SUCCESS = 'UPDATE_CHANNEL_CATEGORY_SUCCESS';

export const UPDATE_COIN_COUNT = 'UPDATE_COIN_COUNT';
export const UPDATE_CHANNEL_COMMUNITY = 'UPDATE_CHANNEL_COMMUNITY';

export const UPDATE_CATEGORY_POSITION = 'UPDATE_CATEGORY_POSITION';

export const UPDATE_CHANNEL_POSITION = 'UPDATE_CHANNEL_POSITION';
export const UPDATE_CHANNEL_LIST = 'UPDATE_CHANNEL_LIST';
export const FETCH_CHANNELS_REQUEST = 'FETCH_CHANNELS_REQUEST';
export const FETCH_CHANNELS_SUCCESS = 'FETCH_CHANNELS_SUCCESS';
export const RESET_POST_DATA = 'RESET_POST_DATA';
export const FETCH_TAG_DATA = 'FETCH_TAG_DATA';
export const FETCH_TAG_SUCCESS = 'FETCH_TAG_SUCCESS';
export const RESET_TAG = 'RESET_TAG';
export const UPDATE_SCENES_LOGIN_PUBLIC = 'UPDATE_SCENES_LOGIN_PUBLIC';

// TAG ACTIONS
export const GET_GROUP_TAG = 'GET_GROUP_TAG';

// SUBSCRIPTION ACTION
export const UPDATE_SUBSCRIPTION_DAYS = 'UPDATE_SUBSCRIPTION_DAYS';
export const UPDATE_SUBSCRIPTION_ACTIVE = 'UPDATE_SUBSCRIPTION_ACTIVE';
export const UPDATE_COMMUNITY_SUBSCRIPTION_DATA = 'UPDATE_COMMUNITY_SUBSCRIPTION_DATA';

export const SET_IS_COMMUNITY_OWNER = 'SET_IS_COMMUNITY_OWNER';
export const SET_IS_SUBSCRIPTION_ERROR = 'SET_IS_SUBSCRIPTION_ERROR';
export const SET_LOGIN_TYPE = 'SET_LOGIN_TYPE';
export const SET_SELECTED_CHANNEL = 'SET_SELECTED_CHANNEL'

// LEADERBOARD ACTIONS

export const FETCH_LEADERBOARD = 'FETCH_LEADERBOARD';
export const FETCH_LEADERBOARD_SUCCESS = 'FETCH_LEADERBOARD_SUCCESS';
export const ADD_SELECTED_COMMUNITY_USER_ID = 'ADD_SELECTED_COMMUNITY_USER_ID';
export const UPDATE_LEADERBOARD_DATA = 'UPDATE_LEADERBOARD_DATA';
export const REMOVE_USER_FROM_LEADERBOARD = 'REMOVE_USER_FROM_LEADERBOARD';
export const SET_MOBILE_LEADERBOARD_MODAL = 'SET_MOBILE_LEADERBOARD_MODAL';

/** FORUM ACTIONS * */

export const CREATE_FORUM_POST_REQUEST = 'CREATE_FORUM_POST_REQUEST';
export const CREATE_FORUM_POST_COMPLETED = 'CREATE_FORUM_POST_COMPLETED';

export const CREATE_FORUM_POLL_POST_REQUEST = 'CREATE_FORUM_POLL_POST_REQUEST';
export const CREATE_FORUM__POLL_POST_COMPLETED = 'CREATE_FORUM__POLL_POST_COMPLETED';

export const UPDATE_FORUM_POST_REQUEST = 'UPDATE_FORUM_POST_REQUEST';
export const UPDATE_FORUM_POST_COMPLETED = 'UPDATE_FORUM_POST_COMPLETED';

export const DELETE_FORUM_POST_REQUEST = 'DELETE_FORUM_POST_REQUEST';
export const DELETE_FORUM_POST_SUCCESS = 'DELETE_FORUM_POST_SUCCESS';

export const FETCH_FORUM_POST_REQUEST = 'FETCH_FORUM_POST_REQUEST';
export const FETCH_FORUM_POST_SUCCESS = 'FETCH_FORUM_POST_SUCCESS';
export const FETCH_FORUM_POST_ERROR = 'FETCH_FORUM_POST_ERROR';
export const FETCH_FORUM_POST_SET_PAGE = 'FETCH_FORUM_POST_SET_PAGE';

export const FETCH_FORUM_POST_COMMENT_REQUEST = 'FETCH_FORUM_POST_COMMENT_REQUEST';
export const FETCH_FORUM_POST_COMMENT_SUCCESS = 'FETCH_FORUM_POST_COMMENT_SUCCESS';

export const PIN_FORUM_POST_REQUEST = 'PIN_FORUM_POST_REQUEST';
export const PIN_FORUM_POST_SUCCESS = 'PIN_FORUM_POST_SUCCESS';
export const PIN_FORUM_POST_FAILED = 'PIN_FORUM_POST_FAILED';
/**  STAGE  * */

export const ADD_BLOCKED_USER_IDS = 'ADD_BLOCKED_USER_IDS';
export const REMOVE_BLOCKED_USER_IDS = 'REMOVE_BLOCKED_USER_IDS';
export const SET_EARLIER_MESSAGES = 'SET_EARLIER_MESSAGES';
export const SET_LAST_MESSAGE = 'SET_LAST_MESSAGE';
export const SET_LOAD_MORE = 'SET_LOAD_MORE';

// TEXT ACTIONS
export const RESET_TEXT_STATE = 'RESET_TEXT_STATE';
export const SET_TEXT_MESSAGES = 'SET_TEXT_MESSAGES';
export const TEXT_CHANNEL_CONNECTION_SUCCESS = 'TEXT_CHANNEL_CONNECTION_SUCCESS';
export const UPDATE_TEXT_STATE = 'UPDATE_TEXT_STATE';

export const SET_SOCKET = 'SET_SOCKET';
export const SET_ACTIVE_CHANNELID = 'SET_ACTIVE_CHANNELID';
export const SET_CHANNEL = 'SET_CHANNEL';
export const SET_MESSAGES = 'SET_MESSAGES';
export const APPEND_MESSAGE = 'APPEND_MESSAGE';
export const SET_ACTIVE_CHANNEL_DATA = 'SET_ACTIVE_CHANNEL_DATA';
export const SET_CHANNEL_FAILED = 'SET_CHANNEL_FAILED';
export const SET_LOBBY = 'SET_LOBBY';
export const SET_USER_CHANNEL = 'SET_USER_CHANNEL';
export const SET_LOBBY_CATEGORIES = 'SET_LOBBY_CATEGORIES';
export const MUTE_USER = 'MUTE_USER';
export const UNMUTE_USER = 'UNMUTE_USER';
export const SET_HAS_EARLIER_MESSAGES = 'SET_HAS_EARLIER_MESSAGES';
export const REFRESH_MESSAGES = 'REFRESH_MESSAGES';

export const TEST_REQUEST = 'TEST_REQUEST';
export const TEST_REQUEST_SUCCESS = 'TEST_REQUEST_SUCCESS';

export const INITIALIZE_TEXT_REQUEST = 'INITIALIZE_TEXT_REQUEST';
export const INITIALIZE_TEXT_SUCCESS = 'INITIALIZE_TEXT_SUCCESS';

export const INITIALIZE_SOCKET_REQUEST = 'INITIALIZE_SOCKET_REQUEST';
export const INITIALIZE_SOCKET_SUCCESS = 'INITIALIZE_SOCKET_SUCCESS';

// AUDIO ACTIONS
export const AUDIO_CONNECTION_SUCCESS = 'AUDIO_CONNECTION_SUCCESS';
export const UPDATE_MUTED_IDS = 'UPDATE_MUTED_IDS';
export const SET_IS_LEAVE_MODAL_OPEN = 'SET_IS_LEAVE_MODAL_OPEN';
export const SET_IS_ACCEPT_MODAL_OPEN = 'SET_IS_ACCEPT_MODAL_OPEN';
export const VOICE_GRANTED_SUCCESSFULLY = 'VOICE_GRANTED_SUCCESSFULLY';
export const RESET_AUDIO_STATE = 'RESET_AUDIO_STATE';
export const RESET_AUDIO_STATE_WITHOUT_MUTE = 'RESET_AUDIO_STATE_WITHOUT_MUTE';
export const UPDATE_USER_MUTE_STATUS = 'UPDATE_USER_MUTE_STATUS';
export const UPDATE_ONLINE_USER = 'UPDATE_ONLINE_USER';
export const SET_IS_USER_HOST = 'SET_IS_USER_HOST';
export const SET_SHOW_SHARE_TOOLTIP = 'SET_SHOW_SHARE_TOOLTIP';
export const SET_IS_GRANTED_STATUS = 'SET_IS_GRANTED_STATUS';
export const DEMOTED_TO_LISTENER = 'DEMOTED_TO_LISTENER';
export const UPDATE_AUDIO_STATE = 'UPDATE_AUDIO_STATE';
export const UPDATE_SPEAKER_REQUESTS = 'UPDATE_SPEAKER_REQUESTS';
export const DELETE_SPEAKER_REQUESTS = 'DELETE_SPEAKER_REQUESTS';

export const SET_AGORA_UID = 'SET_AGORA_UID';
export const SET_AGORA_UID_SHARE = 'SET_AGORA_UID_SHARE';
export const MUTED_IDS = 'MUTED_IDS';
export const SET_VOICE_ROOM_TOKEN = 'SET_VOICE_ROOM_TOKEN';
export const FETCH_FORUM_POST_COMMENT_SET_PAGE = 'FETCH_FORUM_POST_COMMENT_SET_PAGE';

export const ADD_FORUM_POST_COMMENT_REQUEST = 'ADD_FORUM_POST_COMMENT_REQUEST';

export const UPDATE_FORUM_POST_COMMENT_REQUEST = 'UPDATE_FORUM_POST_COMMENT_REQUEST';

export const FORUM_POST_UP_VOTE_REQUEST = 'FORUM_POST_UP_VOTE_REQUEST';
export const FORUM_POST_UP_VOTE_SUCCESS = 'FORUM_POST_UP_VOTE_SUCCESS';
export const FORUM_POST_POLL_VOTE_SUCCESS = 'FORUM_POST_POLL_VOTE_SUCCESS';

export const FORUM_POST_DOWN_VOTE_REQUEST = 'FORUM_POST_DOWN_VOTE_REQUEST';
export const FORUM_POST_DOWN_VOTE_SUCCESS = 'FORUM_POST_DOWN_VOTE_SUCCESS';

export const FORUM_POST_UP_VOTE_FROM_LIST_REQUEST = 'FORUM_POST_UP_VOTE_FROM_LIST_REQUEST';
export const FORUM_POST_UP_VOTE_FROM_LIST_SUCCESS = 'FORUM_POST_UP_VOTE_FROM_LIST_SUCCESS';

export const FORUM_POST_POLL_VOTE_FROM_LIST_SUCCESS = 'FORUM_POST_POLL_VOTE_FROM_LIST_SUCCESS';

export const FORUM_POST_DOWN_VOTE_FROM_LIST_REQUEST = 'FORUM_POST_DOWN_VOTE_FROM_LIST_REQUEST';
export const FORUM_POST_DOWN_VOTE_FROM_LIST_SUCCESS = 'FORUM_POST_DOWN_VOTE_FROM_LIST_SUCCESS';

export const FORUM_POSTS_RESET = 'FORUM_POSTS_RESET';
export const FORUM_POST_COMMENTS_RESET = 'FORUM_POST_COMMENTS_RESET';

export const FETCH_FORUM_POST_COMMENT_REPLIES_REQUEST = 'FETCH_FORUM_POST_COMMENT_REPLIES_REQUEST';
export const FETCH_FORUM_POST_COMMENT_REPLIES_SUCCESS = 'FETCH_FORUM_POST_COMMENT_REPLIES_SUCCESS';
export const FETCH_FORUM_POST_COMMENT_REPLIES_SET_PAGE = 'FETCH_FORUM_POST_COMMENT_REPLIES_SET_PAGE';

export const ADD_FORUM_POST_COMMENT_REPLY_REQUEST = 'ADD_FORUM_POST_COMMENT_REPLY_REQUEST';
export const ADD_FORUM_POST_COMMENT_REPLY_SUCCESS = 'ADD_FORUM_POST_COMMENT_REPLY_SUCCESS';

export const UPDATE_FORUM_POST_COMMENT_REPLY_REQUEST = 'UPDATE_FORUM_POST_COMMENT_REPLY_REQUEST';

export const DELETE_FORUM_POST_COMMENT_REPLY_REQUEST = 'DELETE_FORUM_POST_COMMENT_REPLY_REQUEST';
export const DELETE_FORUM_POST_COMMENT_REPLY_SUCCESS = 'DELETE_FORUM_POST_COMMENT_REPLY_SUCCESS';

export const FORUM_POST_COMMENT_UP_VOTE_REQUEST = 'FORUM_POST_COMMENT_UP_VOTE_REQUEST';
export const FORUM_POST_COMMENT_UP_VOTE_SUCCESS = 'FORUM_POST_COMMENT_UP_VOTE_SUCCESS';

export const FORUM_POST_COMMENT_DOWN_VOTE_REQUEST = 'FORUM_POST_COMMENT_DOWN_VOTE_REQUEST';
export const FORUM_POST_COMMENT_DOWN_VOTE_SUCCESS = 'FORUM_POST_COMMENT_DOWN_VOTE_SUCCESS';

export const FORUM_POST_COMMENT_REPLY_UP_VOTE_REQUEST = 'FORUM_POST_COMMENT_REPLY_UP_VOTE_REQUEST';
export const FORUM_POST_COMMENT_REPLY_UP_VOTE_SUCCESS = 'FORUM_POST_COMMENT_REPLY_UP_VOTE_SUCCESS';

export const FORUM_POST_COMMENT_REPLY_DOWN_VOTE_REQUEST = 'FORUM_POST_COMMENT_REPLY_DOWN_VOTE_REQUEST';
export const FORUM_POST_COMMENT_REPLY_DOWN_VOTE_SUCCESS = 'FORUM_POST_COMMENT_REPLY_DOWN_VOTE_SUCCESS';

export const FORUM_BOOKMARK_POST_REQUEST = 'FORUM_BOOKMARK_POST_REQUEST';

export const DELETE_FORUM_POST_COMMENT_REQUEST = 'DELETE_FORUM_POST_COMMENT_REQUEST';

export const MARK_TOP_ANSWER_REQUEST = 'MARK_TOP_ANSWER_REQUEST';
export const MARK_TOP_ANSWER = 'MARK_TOP_ANSWER';

// CHAT ACTION TYPES

export const RESET_CHAT_STATE = 'RESET_CHAT_STATE';
export const CHAT_CHANNEL_CONNECTION_SUCCESS = 'CHAT_CHANNEL_CONNECTION_SUCCESS';
export const NEW_CHAT_REQUEST = 'NEW_CHAT_REQUEST';
export const UPDATE_CHAT_STATE = 'UPDATE_CHAT_STATE';
export const ADD_BLOCKED_USER_IDS_CHAT = 'ADD_BLOCKED_USER_IDS_CHAT';
export const REMOVE_BLOCKED_USER_IDS_CHAT = 'REMOVE_BLOCKED_USER_IDS_CHAT';
export const SET_LAST_MESSAGE_CHAT = 'SET_LAST_MESSAGE_CHAT';
export const SET_TEXT_MESSAGES_CHAT = 'SET_TEXT_MESSAGES_CHAT';
export const SET_LOAD_MORE_CHAT = 'SET_LOAD_MORE_CHAT';
export const SET_EARLIER_MESSAGES_CHAT = 'SET_EARLIER_MESSAGES_CHAT';
export const SET_PINNED_MESSAGE = 'SET_PINNED_MESSAGE';
export const SET_NOTIFICATION_STATUS = 'SET_NOTIFICATION_STATUS';
export const SET_CHAT_LOADING = 'SET_CHAT_LOADING';
/**  ROLES & PERMISSIONS  * */

export const FETCH_ROLES_REQUEST = 'FETCH_ROLES_REQUEST';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';

export const FETCH_COMMUNITY_ROLES_REQUEST = 'FETCH_COMMUNITY_ROLES_REQUEST';
export const FETCH_COMMUNITY_ROLES_SUCCESS = 'FETCH_COMMUNITY_ROLES_SUCCESS';

export const DELETE_ROLES_REQUEST = 'DELETE_ROLES_REQUEST';

export const FETCH_COMMUNITY_USER_ROLE_REQUEST = 'FETCH_COMMUNITY_USER_ROLE_REQUEST';
export const FETCH_COMMUNITY_USER_ROLE_SUCCESS = 'FETCH_COMMUNITY_USER_ROLE_SUCCESS';

export const CREATE_ROLES_REQUEST = 'CREATE_ROLES_REQUEST';
export const CREATE_ROLES_SUCCESS = 'CREATE_ROLES_SUCCESS';

export const UPDATE_ROLES_REQUEST = 'UPDATE_ROLES_REQUEST';
export const UPDATE_ROLES_SUCCESS = 'UPDATE_ROLES_SUCCESS';

export const ADD_RESOURCE_PERMISSIONS = 'ADD_RESOURCE_PERMISSIONS';
export const REFETCH_RESORUCES = 'REFETCH_RESORUCES';

export const ADD_CALENDAR_PERMISSIONS = 'ADD_CALENDAR_PERMISSIONS';
export const SET_CALENDER_EVENT_LOADING = 'SET_CALENDER_EVENT_LOADING';
export const GET_CALENDER_EVENT_REQUEST = 'GET_CALENDER_EVENT_REQUEST';
export const GET_CALENDER_EVENT_SUCCESS = 'GET_CALENDER_EVENT_SUCCESS';
export const GET_ZOOM_SIGNATURE_REQUEST = 'GET_ZOOM_SIGNATURE_REQUEST';
export const GET_ZOOM_SIGNATURE_SUCCESS = 'GET_ZOOM_SIGNATURE_SUCCESS';
export const GET_ZOOM_EVENT = 'GET_ZOOM_EVENT';
export const GET_ZOOM_EVENT_SUCCESS = 'GET_ZOOM_EVENT_SUCCESS';
export const GET_STREAMING_EVENT = 'GET_STREAMING_EVENT';
export const GET_STREAMING_EVENT_SUCCESS = 'GET_STREAMING_EVENT_SUCCESS';

// event reducer
export const UPDATE_CREATE_EVENT_MODAL = 'UPDATE_CREATE_EVENT_MODAL';
export const SET_EVENT_TEMP_DATA = 'SET_EVENT_TEMP_DATA';
export const SET_EVENT_SOURCE = 'SET_EVENT_SOURCE';
export const SET_ZOOM_CALENDAR_EVENT = 'SET_ZOOM_CALENDAR_EVENT';
export const SET_CALENDAR_CHANNELS = 'SET_CALENDAR_CHANNELS';
export const SET_EVENT_ID = 'SET_EVENT_ID';
export const RESET_CREATE_EVENT_STATE = 'RESET_CREATE_EVENT_STATE';
export const UPDATE_CALENDAR_EVENT_LIST = 'UPDATE_CALENDAR_EVENT_LIST';
export const GET_CALENDER_EVENT_REMINDER = 'GET_CALENDER_EVENT_REMINDER';

export const SET_USER_PROFILE_DATA = 'SET_USER_PROFILE_DATA';
export const RESET_USER_PROFILE_DATA = 'RESET_USER_PROFILE_DATA';

export const SEARCH_COMMUNITY_MEMBER_REQUEST = 'SEARCH_COMMUNITY_MEMBER_REQUEST';
export const SEARCH_COMMUNITY_MEMBER_SUCCESS = 'SEARCH_COMMUNITY_MEMBER_SUCCESS';
export const RESET_SEARCH_COMMUNITY_MEMBER = 'RESET_SEARCH_COMMUNITY_MEMBER';
export const SET_FETCH_NEXT_PAGE = 'SET_FETCH_NEXT_PAGE';
// User Bookmarks

export const SET_USER_RESOURCE_BOOKMARKS_LOADING = 'SET_USER_RESOURCE_BOOKMARKS_LOADING';
export const SET_USER_FORUM_BOOKMARKS = 'SET_USER_FORUM_BOOKMARKS';
export const SET_USER_RESOURCE_BOOKMARKS = 'SET_USER_RESOURCE_BOOKMARKS';
export const SET_USER_FORUM_BOOKMARKS_PAGE_MANAGERS = 'SET_USER_FORUM_BOOKMARKS_PAGE_MANAGERS';
export const SET_USER_RESOURCE_BOOKMARKS_PAGE_MANAGERS = 'SET_USER_RESOURCE_BOOKMARKS_PAGE_MANAGERS';
export const FETCH_USER_FORUM_BOOKMARKS_REQUEST = 'FETCH_USER_FORUM_BOOKMARKS_REQUEST';
export const FETCH_USER_RESOURCE_BOOKMARKS_REQUEST = 'FETCH_USER_RESOURCE_BOOKMARKS_REQUEST';
export const UPVOTE_BOOKMARKED_POST_REQUEST = 'UPVOTE_BOOKMARKED_POST_REQUEST';
export const UPVOTE_BOOKMARKED_POST_SUCCESS = 'UPVOTE_BOOKMARKED_POST_SUCCESS';
export const DOWNVOTE_BOOKMARKED_POST_REQUEST = 'DOWNVOTE_BOOKMARKED_POST_REQUEST';
export const DOWNVOTE_BOOKMARKED_POST_SUCCESS = 'DOWNVOTE_BOOKMARKED_POST_SUCCESS';
export const RESET_BOOKMARKS_SUCCESS = 'RESET_BOOKMARKS_SUCCESS';
export const SET_USER_BOOKMARKS_COUNT = 'SET_USER_BOOKMARKS_COUNT';

// Single Signon
export const CREATE_CUSTOM_SSO_REQUEST = 'CREATE_CUSTOM_SSO_REQUEST';
export const CREATE_CUSTOM_SSO_SUCCESS = 'CREATE_CUSTOM_SSO_SUCCESS';
export const CREATE_CUSTOM_SSO_FAILED = 'CREATE_CUSTOM_SSO_FAILED';

export const UPDATE_CUSTOM_SSO_REQUEST = 'UPDATE_CUSTOM_SSO_REQUEST';
export const UPDATE_CUSTOM_SSO_SUCCESS = 'UPDATE_CUSTOM_SSO_SUCCESS';
export const UPDATE_CUSTOM_SSO_FAILED = 'UPDATE_CUSTOM_SSO_FAILED';

export const GET_CUSTOM_SSO_LIST = 'GET_CUSTOM_SSO_LIST';
export const SET_CUSTOM_SSO_LIST = 'SET_CUSTOM_SSO_LIST';

export const DELETE_CUSTOM_SSO_REQUEST = 'DELETE_CUSTOM_SSO_REQUEST';
export const DELETE_CUSTOM_SSO_SUCCESS = 'DELETE_CUSTOM_SSO_SUCCESS';
export const DELETE_CUSTOM_SSO_FAILED = 'DELETE_CUSTOM_SSO_FAILED';

export const SET_SCENES_LOGIN_STATE = 'SET_SCENES_LOGIN_STATE';

// PRIVATE CHAT ACTION TYPES
export const LIST_INBOX_USERS_REQUEST = 'LIST_INBOX_USERS_REQUEST';
export const LIST_INBOX_USERS_SUCCESS = 'LIST_INBOX_USERS_SUCCESS';
export const SET_LIST_INBOX_USERS_LOADING = 'SET_LIST_INBOX_USERS_LOADING';

export const SET_ACTIVE_PRIVATE_ROOM = 'SET_ACTIVE_PRIVATE_ROOM';
export const SET_PRIVATE_ROOMS = 'SET_PRIVATE_ROOMS';

export const SET_PRIVATE_TEXT_ROOM_REQUEST_COUNT = 'SET_PRIVATE_TEXT_ROOM_REQUEST_COUNT';

export const RESET_PRIVATE_CHAT_STATE = 'RESET_PRIVATE_CHAT_STATE';
export const RESET_PRIVATE_CHAT_TEXT_ROOM_STATE = 'RESET_PRIVATE_CHAT_TEXT_ROOM_STATE';
export const PRIVATE_CHAT_CHANNEL_CONNECTION_SUCCESS = 'PRIVATE_CHAT_CHANNEL_CONNECTION_SUCCESS';
export const UPDATE_PRIVATE_CHAT_STATE = 'UPDATE_PRIVATE_CHAT_STATE';
export const ADD_BLOCKED_USER_IDS_PRIVATE_CHAT = 'ADD_BLOCKED_USER_IDS_PRIVATE_CHAT';
export const REMOVE_BLOCKED_USER_IDS_PRIVATE_CHAT = 'REMOVE_BLOCKED_USER_IDS_PRIVATE_CHAT';
export const SET_LAST_MESSAGE_PRIVATE_CHAT = 'SET_LAST_MESSAGE_PRIVATE_CHAT';
export const SET_TEXT_MESSAGES_PRIVATE_CHAT = 'SET_TEXT_MESSAGES_PRIVATE_CHAT';
export const SET_LOAD_MORE_PRIVATE_CHAT = 'SET_LOAD_MORE_PRIVATE_CHAT';
export const SET_EARLIER_MESSAGES_PRIVATE_CHAT = 'SET_EARLIER_MESSAGES_PRIVATE_CHAT';
export const SET_PINNED_MESSAGE_PRIVATE_CHAT = 'SET_PINNED_MESSAGE_PRIVATE_CHAT';
export const SET_NOTIFICATION_STATUS_PRIVATE_CHAT = 'SET_NOTIFICATION_STATUS_PRIVATE_CHAT';
export const SET_PRIVATE_CHAT_LOADING = 'SET_PRIVATE_CHAT_LOADING';
export const SET_DM_USERS = 'SET_DM_USERS';

export const SET_PRIVATE_INBOX_TYPE = 'SET_PRIVATE_INBOX_TYPE';

export const UPDATE_PRIVATE_CHAT_UNREAD_STATUS = 'UPDATE_PRIVATE_CHAT_UNREAD_STATUS';

export const REQUEST_JOIN_TEXT_ROOM = 'REQUEST_JOIN_TEXT_ROOM';
export const REQUEST_JOIN_TEXT_ROOM_SUCCESS = 'REQUEST_JOIN_TEXT_ROOM_SUCCESS';

export const CREATE_PRIVATE_TEXT_ROOM_REQUEST = 'CREATE_PRIVATE_TEXT_ROOM_REQUEST';
export const CREATE_PRIVATE_TEXT_ROOM_SUCCESS = 'CREATE_PRIVATE_TEXT_ROOM_SUCCESS';

export const SET_ACTIVE_PRIVATE_ROOM_FROM_USER_ID = 'SET_ACTIVE_PRIVATE_ROOM_FROM_USER_ID';

export const REMOVE_ACTIVE_PRIVATE_ROOM_AND_RESET = 'REMOVE_ACTIVE_PRIVATE_ROOM_AND_RESET';

export const UPDATE_OFF_LINE_CHAT_STATE = 'UPDATE_OFF_LINE_CHAT_STATE';
export const DELETE_USER_DM_CHAT = 'DELETE_USER_DM_CHAT';
// SHOP
export const GET_SHOP_ITEMS = 'GET_SHOP_ITEMS';
export const GET_SHOP_ITEMS_SUCCESS = 'GET_SHOP_ITEMS_SUCCESS';
export const GET_SHOP_ITEMS_FAILED = 'GET_SHOP_ITEMS_FAILED';
export const GET_PURCHASED_SHOP_ITEMS = 'GET_PURCHASED_SHOP_ITEMS';
export const GET_PURCHASED_SHOP_ITEMS_SUCCESS = 'GET_PURCHASED_SHOP_ITEMS_SUCCESS';
export const GET_PURCHASED_SHOP_ITEMS_FAILED = 'GET_PURCHASED_SHOP_ITEMS_FAILED';
export const SET_COMMUNITY_SHOP = 'SET_COMMUNITY_SHOP';
export const SET_COMMUNITY_SHOP_SUCCESS = 'SET_COMMUNITY_SHOP_SUCCESS';
export const SET_COMMUNITY_SHOP_FAILED = 'SET_COMMUNITY_SHOP_FAILED';
export const SET_CREATE_SHOP_LOADING = 'SET_CREATE_SHOP_LOADING';
export const GET_COMMUNITY_SHOP = 'GET_COMMUNITY_SHOP';
export const UPDATE_COMMUNITY_SHOP = 'UPDATE_COMMUNITY_SHOP';
export const UPDATE_COMMUNITY_SHOP_SUCCESS = 'UPDATE_COMMUNITY_SHOP_SUCCESS';
export const UPDATE_STRIPE_CONNECTION_STATE = 'UPDATE_STRIPE_CONNECTION_STATE';
export const UPDATE_RAZORPAY_STATE = 'UPDATE_RAZORPAY_STATE';
export const UPDATE_SELECT_PRODUCT_MODAL = 'UPDATE_SELECT_PRODUCT_MODAL';
export const UPDATE_CREATE_PRODUCT_MODAL = 'UPDATE_CREATE_PRODUCT_MODAL';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS';
export const SET_UPDATE_PRODUCT_ID = 'SET_UPDATE_PRODUCT_ID';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESSFUL';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const SET_TEMP_PRODUCT_DATA = 'SET_TEMP_PRODUCT_DATA';
export const PURCHSE_PRODUCT = 'PURCHSE_PRODUCT';
export const PURCHSE_PRODUCT_SUCCESS = 'PURCHSE_PRODUCT_SUCCESS';
export const SET_PURCHASE_SUCCESS_DATA = 'SET_PURCHASE_SUCCESS_DATA';
export const INITIALIZE_STRIPE_PURCHASE = 'INITIALIZE_STRIPE_PURCHASE';
export const VERIFY_STRIPE_PURCHASE = 'VERIFY_STRIPE_PURCHASE';
export const VERIFY_RAZORPAY_PURCHASE = 'VERIFY_RAZORPAY_PURCHASE';
export const SET_FORM_SUBMITTINIG = 'SET_FORM_SUBMITTINIG';
export const SET_PRODUCT_LIST_VIEW = 'SET_PRODUCT_LIST_VIEW';
export const UPDATE_CURRENCY = 'UPDATE_CURRENCY';
export const DISCONNECT_ACCOUNT_STRIPE = 'DISCONNECT_ACCOUNT_STRIPE';

// Bracnh Set Up
export const SET_BRANCH_SETUP_FAILED = 'SET_BRANCH_SETUP_FAILED';

// Unread DM's
export const SET_UNREAD_DM_COUNT = 'SET_UNREAD_DM_COUNT';

//
export const SET_IS_PREMIUM = 'SET_IS_PREMIUM';

// Public communities
export const FETCH_PUBLIC_COMMUNITY_OVERVIEW_REQUEST = 'FETCH_PUBLIC_COMMUNITY_OVERVIEW_REQUEST';
export const FETCH_PUBLIC_COMMUNITY_OVERVIEW_SUCCESS = 'FETCH_PUBLIC_COMMUNITY_OVERVIEW_SUCCESS';
export const FETCH_PUBLIC_COMMUNITY_OVERVIEW_SET_PAGE = 'FETCH_PUBLIC_COMMUNITY_OVERVIEW_SET_PAGE';
export const FETCH_PUBLIC_COMMUNITY_OVERVIEW_RESET = 'FETCH_PUBLIC_COMMUNITY_OVERVIEW_RESET';
export const FETCH_PUBLIC_COMMUNITY_CHANNELS_REQUEST = 'FETCH_PUBLIC_COMMUNITY_CHANNELS_REQUEST';
export const FETCH_PUBLIC_COMMUNITY_CHANNELS_SUCCESS = 'FETCH_PUBLIC_COMMUNITY_CHANNELS_SUCCESS';
export const UPDATE_PUBLIC_ACTIVE_CHANNEL = 'UPDATE_PUBLIC_ACTIVE_CHANNEL';
export const UPDATE_PUBLIC_COMMUNITY_CHANNELS = 'UPDATE_PUBLIC_COMMUNITY_CHANNELS';
export const FETCH_PUBLIC_GROUPS_REQUEST = 'FETCH_PUBLIC_GROUPS_REQUEST';
export const FETCH_PUBLIC_GROUPS_SUCCESS = 'FETCH_PUBLIC_GROUPS_SUCCESS';
export const FETCH_PUBLIC_FORUM_POST_REQUEST = 'FETCH_PUBLIC_FORUM_POST_REQUEST';
export const FETCH_PUBLIC_FORUM_POST_SUCCESS = 'FETCH_PUBLIC_FORUM_POST_SUCCESS';
export const FETCH_PUBLIC_FORUM_POST_SET_PAGE = 'FETCH_PUBLIC_FORUM_POST_SET_PAGE';
export const FETCH_PUBLIC_FORUM_POST_RESET = 'FETCH_PUBLIC_FORUM_POST_RESET';
export const FETCH_PUBLIC_FORUM_POST_COMMENT_REQUEST = 'FETCH_PUBLIC_FORUM_POST_COMMENT_REQUEST';
export const FETCH_PUBLIC_FORUM_POST_COMMENT_SUCCESS = 'FETCH_PUBLIC_FORUM_POST_COMMENT_SUCCESS';
export const FETCH_PUBLIC_FORUM_POST_COMMENT_REPLIES_REQUEST = 'FETCH_PUBLIC_FORUM_POST_COMMENT_REPLIES_REQUEST';
export const FETCH_PUBLIC_FORUM_POST_COMMENT_REPLIES_SUCCESS = 'FETCH_PUBLIC_FORUM_POST_COMMENT_REPLIES_SUCCESS';
export const SET_PREMIUM_MODAL = 'SET_PREMIUM_MODAL';
export const SET_USER_MODAL = 'SET_USER_MODAL';
export const GET_PUBLIC_ZOOM_EVENT = 'GET_PUBLIC_ZOOM_EVENT';
export const GET_PUBLIC_ZOOM_EVENT_SUCCESS = 'GET_PUBLIC_ZOOM_EVENT_SUCCESS';

// App in App
export const IS_APP_IN_APP = 'IS_APP_IN_APP';

// Hashtag
export const SET_FORUM_HASHTAG = 'SET_FORUM_HASHTAG';
export const SET_FORUM_HASHTAG_PAGE_MANAGERS = 'SET_FORUM_HASHTAG_PAGE_MANAGERS';
export const RESET_HASHTAG_SUCCESS = 'RESET_HASHTAG_SUCCESS';
export const FETCH_FORUM_HASHTAG_REQUEST = 'FETCH_FORUM_HASHTAG_REQUEST';

// Plans
export const PLAN_DETAILS_REQUEST = 'PLAN_DETAILS_REQUEST';
export const PLAN_DETAILS_SUCCESS = 'PLAN_DETAILS_SUCCESS';
export const CHARGEBEE_PAYMENT_REQUEST = 'CHARGEBEE_PAYMENT_REQUEST';
export const CHARGEBEE_PAYMENT_SUCCESS = 'CHARGEBEE_PAYMENT_SUCCESS';
export const RESET_PAYMENT_URL = 'RESET_PAYMENT_URL';
export const SET_ACTIVE_PRODUCT_PLAN = 'SET_ACTIVE_PRODUCT_PLAN';
export const PLAN_PAYMENT_STATUS_REQUEST = 'PLAN_PAYMENT_STATUS_REQUEST';
export const PLAN_PAYMENT_SUCCESS = 'PLAN_PAYMENT_SUCCESS';
export const CURRENT_SUBSCRIPTION_REQUEST = 'CURRENT_SUBSCRIPTION_REQUEST';
export const CURRENT_SUBSCRIPTION_SUCCESS = 'CURRENT_SUBSCRIPTION_SUCCESS';
export const CLEAR_LOCAL_STORAGE = 'CLEAR_LOCAL_STORAGE';
export const SET_MODAL_CLOSE = 'SET_MODAL_CLOSE';
export const SET_MODAL_OPEN = 'SET_MODAL_OPEN';
export const SET_COMPONENT_LOCK_STATUS = 'SET_COMPONENT_LOCK_STATUS';
export const SET_CURRENT_ACTIVE_NAVIGATION = 'SET_CURRENT_ACTIVE_NAVIGATION';
export const SET_CURRENT_ACTIVE_FEATURE_IDENTIFIER = 'SET_CURRENT_ACTIVE_FEATURE_IDENTIFIER';
export const SET_ACTIVE_LOCK_NAVIGATION_IDENTIFIER = 'SET_ACTIVE_LOCK_NAVIGATION_IDENTIFIER';
export const SET_ACTIVE_LOCK_NAVIGATION = 'SET_ACTIVE_LOCK_NAVIGATION';
export const BOOK_DEMO_CLICKED = 'BOOK_DEMO_CLICKED';

// Mixpanel
export const MIXPANEL_TOKEN = 'MIXPANEL_TOKEN';
export const MIXPANEL_INTERNAL_IDENTIFIER = 'MIXPANEL_INTERNAL_IDENTIFIER';
export const SET_MIXPANEL_ATTRIBUTES = 'SET_MIXPANEL_ATTRIBUTE';
export const FETCH_MIXPANEL_ATTRIBUTES = 'FETCH_MIXPANEL_ATTRIBUTES';

// Graphy SSO
// export const UPDATE_GRAPHY_SSO_EXTERNAL_NAME = 'UPDATE_GRAPHY_SSO_EXTERNAL_NAME';

// scheduled and drafts
export const SET_SCHEDULED_POSTS = 'SET_SCHEDULED_POSTS';
export const SET_DRAFT_POSTS = 'SET_DRAFT_POSTS';
export const SET_SELECTED_SCHEDULED_AND_DRAFTS_DATA = 'SET_SELECTED_SCHEDULED_AND_DRAFTS_DATA';
export const SET_SCHEDULED_DRAFTS_COUNT = 'SET_SCHEDULED_DRAFTS_COUNT';
export const SET_POLL_OPTIONS = 'SET_POLL_OPTIONS';
export const SET_VIEW_ALL = 'SET_VIEW_ALL';
export const SET_DRAFTS_PAGE_MANAGER = 'SET_DRAFTS_PAGE_MANAGER';
export const SET_SCHEDULE_PAGE_MANAGER = 'SET_SCHEDULE_PAGE_MANAGER';
