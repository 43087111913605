import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { REPORT_TYPE } from '../../constants';
import usePrevious from '../../hooks/usePrevious';
import { getCurrentLoggedInUserId } from '../../selectors/ProfileSelectors';
import { resetUserProfileData } from '../../store/actions/userProfileActions';
import UserProfile from '../../views/channel/textchannel/UserProfile';
import ReportModal from '../moderation/ReportModal';

function UserProfileSidebar() {
  const dispatch = useDispatch();
  const currentUserId = useSelector(getCurrentLoggedInUserId);
  const userProfileData = useSelector((state) => state?.userProfile);
  const prevUserProfileData = usePrevious(userProfileData);

  const [openReportModal, setOpenReportModal] = useState(false);

  const closeUserProfileSidebar = () => {
    dispatch(resetUserProfileData());
  };

  return (
    <div>
      <UserProfile
        openUserProfile={userProfileData.openUserProfile}
        onClose={() => {
          closeUserProfileSidebar();
        }}
        self={currentUserId == userProfileData.selectedUserId}
        activeUserId={userProfileData.selectedUserId}
        channelId={userProfileData.channelId}
        openReportModal={() => {
          setOpenReportModal(true);
          closeUserProfileSidebar();
        }}
      />

      {openReportModal && (
        <ReportModal
          heading={'Report User'}
          subheading={''}
          onClose={() => setOpenReportModal(false)}
          open={openReportModal}
          reportType={REPORT_TYPE.USER_REPORT}
          userId={userProfileData.selectedUserId || prevUserProfileData.selectedUserId}
        />
      )}
    </div>
  );
}

export default UserProfileSidebar;
