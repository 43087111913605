/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  Dialog,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react';
import {
  ChevronLeftIcon,
  CogIcon,
  HomeIcon,
  MailIcon,
  MenuIcon,
  ShoppingBagIcon,
  UserIcon,
  XIcon,
} from '@heroicons/react/outline';
import WhatsapIcon from '../../components/icons/WhatsapIcon';
import { CheckIcon, PlusIcon, SelectorIcon } from '@heroicons/react/solid';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useHistory, useLocation, useParams } from 'react-router-dom';
import ChannelIcon from '../../components/layout/ChannelIcon';
import NotificationList from '../../components/notification/NotificationList';
import GlobalSearch from '../../components/search/GlobalSearch';
import ShareModal from '../../components/share/ShareModal';
import CommonBasicDropdown from '../../components/shared/tailwind/Dropdown';
import CommonNameAvatar, {
  COMMON_NAME_AVATAR_SIZE,
  COMMON_NAME_AVATAR_TYPE,
} from '../../components/shared/tailwind/NameAvatar';
import {
  INVITE_USERS_AMPLITUDE_EVENT_TITLE,
  ROUTE_PATH,
  SEND_INVITE_TRIGGERED_FROM,
  USER_IMAGE_TYPE_2,
  USER_IMAGE_TYPE_6,
} from '../../constants';
import { analyticsDefaults, classNames, getButtonCSS, isNullOrUndefined, openInNewTab } from '../../constants/utils';
import {
  getActiveChannelData,
  getActiveChannelName,
  getActiveChannelType,
  isForumChannelType,
} from '../../selectors/ChannelSelectors';
import {
  getActiveCommunity,
  getActiveCommunityAccentColor,
  getActiveCommunityFontsClass,
  getActiveCommunityId,
  getActiveCommunityViaPublicCommunity,
  getActiveGroup,
  getActiveGroupId,
  getUnreadDmCount,
  isCurrentActiveGroupIsMainGroup,
} from '../../selectors/CommunitySelectors';
import {
  getCurrentLoggedInUser,
  getCurrentLoggedInUserId,
  getCurrentLoggedInUserImage,
} from '../../selectors/ProfileSelectors';
import { imageFetch } from '../../services/preSignedAws';
import { authLogout } from '../../store/actions/authActions';
import { setEventSource, updateCreateCalenderModal } from '../../store/actions/eventActions';
import { closeChatChannelConnection } from '../../store/actions/chatActions';
import { mobileLeaderboardModal } from '../../store/actions/leaderboardActions';
import { setActivePrivateRoom } from '../../store/actions/privateChatActions';
import { closeChannelConnection } from '../../store/actions/textActions';
import ForumCreatePost from '../channel/forum/forumCreatePost';
import ChannelCategorySettingSidebar from '../channelCategorySettings/Sidebar';
import ChannelSettingSidebar from '../channelSettings/Sidebar';
import GroupSettingSidebar from '../groupSettings/Sidebar';
import CommunitySettingSidebar from '../settings/Sidebar';
import NarrowSidebar from './NarrowSidebar';
import Sidebar from './Sidebar';
import CreateShopButton from '../shopListing/CreateShopButton';
import { getIsStripeConnected, getShopData } from '../../selectors/ShopSelectors';
import { SET_PRODUCT_LIST_VIEW } from '../../store/actions/actionTypes';
import { getIsAppInApp } from '../../selectors/AppInAppSelectors';
import NotificationService from '../../services/notificationService';
import { updateUserLanguagePreference } from '../../services/UserService';
import HideContentWrapper from '../hideContent/HideContentWrapper';
import { PLAN_PRICE_ID } from '../../constants/pricingConstants';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { SETTINGS_ROUTES_CONSTANTS } from '../../constants/routesConstants';
import useInviteManagement from '../channel/stream/hooks/useInviteManagement';
import InviteDrawer from '../../components/drawer/Drawer';
import AnalyticService from '../../services/AnalyticsService';
import DeleteConfirmationModal from '../channelSettings/DeleteConfirmationModal';

function HeaderTitle({ headerTitle, open }) {
  const location = useLocation();
  const history = useHistory();
  const getActiveCommunityPublic = useSelector(getActiveCommunityViaPublicCommunity);
  const activeChannel = useSelector(getActiveChannelData);
  const activeChannelName = activeChannel?.name;
  const activeChannelType = useSelector(getActiveChannelType);
  const { primaryFont } = useSelector(getActiveCommunityFontsClass);
  const currentActiveCommunity = useSelector(getActiveCommunity);
  const currentActiveGroupIsMainGroup = useSelector(isCurrentActiveGroupIsMainGroup);
  const activeGroup = useSelector(getActiveGroup);
  const shopData = useSelector(getShopData);
  const dispatch = useDispatch();

  const forumPostDetailsPath = matchPath(location.pathname, {
    path: ROUTE_PATH.FORUM_POST_DETAILS,
  });
  const resourcePostDetailsPath = matchPath(location.pathname, {
    path: ROUTE_PATH.RESOURCE_POST_DETAILS,
  });
  const isCommunityOverviewPath = matchPath(location.pathname, {
    path: ROUTE_PATH.COMMUNITY_OVERVIEW_DETAILS || '/',
  });

  const isBookmarksPath = matchPath(location.pathname, {
    path: ROUTE_PATH.BOOKMARKS,
  });

  const isCalendatEventDetailsPath = matchPath(location.pathname, {
    path: ROUTE_PATH.CALENDAR_POST_DETAILS,
  });

  const isShopListingPath = matchPath(location.pathname, {
    path: ROUTE_PATH.SHOPLISTING,
  });

  if (headerTitle) {
    return <>{headerTitle}</>;
  }

  const handleBackNavigation = () => {
    if (window.location.pathname.includes('/zoomposts')) {
      history.push('/');
      return;
    }

    if (window.location.pathname.includes('/profile')) {
      history.goBack();
      return;
    }

    if (!forumPostDetailsPath?.isExact && !resourcePostDetailsPath?.isExact && !isCalendatEventDetailsPath?.isExact) {
      return;
    }

    if (forumPostDetailsPath?.isExact) {
      const { channel_id } = forumPostDetailsPath.params;
      const { community_id } = forumPostDetailsPath.params;
      const { group_id } = forumPostDetailsPath.params;

      history.replace(`/communities/${community_id}/group/${group_id}/channels/${channel_id}/forumposts`);
    }
    if (resourcePostDetailsPath?.isExact) {
      const { channel_id } = resourcePostDetailsPath.params;
      const { community_id } = resourcePostDetailsPath.params;
      const { group_id } = resourcePostDetailsPath.params;

      history.replace(`/communities/${community_id}/group/${group_id}/channels/${channel_id}/resourceposts`);
    }

    if (isCalendatEventDetailsPath?.isExact) {
      const { community_id } = isCalendatEventDetailsPath.params;
      const { channel_id } = isCalendatEventDetailsPath.params;
      const { group_id } = isCalendatEventDetailsPath.params;
      if (activeChannelType === 'zoom') {
        history.push(`/communities/${community_id}/group/${group_id}/channels/${channel_id}/zoomposts`);
      } else {
        history.push(`/communities/${community_id}/group/${group_id}/channels/${channel_id}/calendarposts`);
      }
    }
  };

  if (isBookmarksPath?.isExact) {
    return (
      <>
        <div className="hidden lg:block">
          <ChannelIcon iconClassName="h-6 w-6 ltr:mr-2 rtl:ml-2 text__header" channelType="bookmarks" />
        </div>
        <p className={classNames('text-16 xs:text-14 text__header', primaryFont)}>{$translatei18n('MyBookmarks')}</p>
      </>
    );
  }

  if (open) {
    return null;
  }

  // Back to Community Calendar
  return activeChannelName || location?.state?.isFromInbox ? (
    <div className="flex text-lg items-start md:align-middle text__header">
      {forumPostDetailsPath?.isExact ||
      resourcePostDetailsPath?.isExact ||
      isCalendatEventDetailsPath?.isExact ||
      window.location.pathname === '/profile' ? (
        <div className="flex cursor-pointer overflow-hidden" onClick={handleBackNavigation}>
          <ChevronLeftIcon className="h-6 w-6 flex-none" />
          <p
            className={classNames('text-16 leading-4 flex-none pt-1', primaryFont)}
            style={{ color: getActiveCommunityPublic == 3764 ? '#fff' : '' }}
          >
            {$translatei18n('BackTo')} {activeChannelName}
          </p>
        </div>
      ) : (
        <div className="flex overflow-hidden items-center">
          <div className="hidden lg:block">
            <ChannelIcon iconClassName="h-6 w-6 ltr:mr-2 rtl:ml-2 text__header" channelType={activeChannelType} />
          </div>
          <p
            className={classNames('text-16 break-all leading-4 flex-none pt-1', primaryFont)}
            style={{ color: getActiveCommunityPublic == 3764 ? '#fff' : '' }}
          >
            {activeChannelName}
          </p>
        </div>
      )}
    </div>
  ) : isCommunityOverviewPath?.isExact ? (
    <>
      <div className="hidden lg:block">
        <HomeIcon className="h-6 w-6 text__header" />
      </div>
      <p
        className={classNames(
          'ltr:ml-2 rtl:mr-2 ltr:xs:ml-0 rtl:xs:mr-0 text-16 leading-4 text__header pt-1',
          primaryFont,
        )}
        style={{ color: getActiveCommunityPublic == 3764 ? '#fff' : '' }}
      >
        {currentActiveGroupIsMainGroup
          ? activeGroup?.home_screen_metadata?.name || $translatei18n('Overview')
          : 'Welcome'}
      </p>
    </>
  ) : isShopListingPath?.isExact ? (
    <div
      className={`flex overflow-hidden items-center
        ${shopData?.productListView === 'PURCHASED' ? 'cursor-pointer' : 'cursor-default'}
      `}
      role="none"
      onClick={() => {
        if (shopData?.productListView === 'SHOP') return;
        dispatch({
          type: SET_PRODUCT_LIST_VIEW,
          payload: 'SHOP',
        });
      }}
    >
      <div className="hidden lg:block">
        {shopData?.productListView === 'SHOP' ? (
          <ShoppingBagIcon className="h-6 w-6 ltr:mr-2 rtl:ml-2 text__header" />
        ) : (
          <ChevronLeftIcon className="h-6 w-6 flex-none" />
        )}
      </div>
      <p className={classNames('text-16 break-all leading-4 flex-none pt-1 capitalize', primaryFont)}>
        {shopData?.productListView === 'SHOP'
          ? `${shopData?.shopName}`
          : `${$translatei18n('BackTo')} ${shopData?.shopName}`}
      </p>
    </div>
  ) : (
    <div className="flex cursor-pointer overflow-hidden" onClick={() => history.push('/')}>
      <ChevronLeftIcon className="h-6 w-6 flex-none" />
      <p
        className={classNames('ml text-16 leading-4 flex-none pt-1.5', primaryFont)}
        style={{ color: getActiveCommunityPublic == 3764 ? '#fff' : '' }}
      >
        {matchPath(location.pathname, {
          path: ROUTE_PATH.HASHTAGS,
        })?.isExact
          ? `Posts with #${window?.location?.pathname?.split('/')?.pop()?.toLowerCase()}`
          : `${$translatei18n('BackTo')} ${$translatei18n('Overview')}`}
      </p>
    </div>
  );
  // <div>{location.state?.breadcrumb_data}</div>
  // return activeChannelName ? (
  //   <div className="flex text-gray-800 text-lg align-middle">
  //     <div className="hidden pt-1 lg:block">
  //       <ChannelIcon channelType={activeChannelType} />
  //     </div>
  //     <p className="ml-2 font-Geomanist-book">{activeChannelName}</p>
  //   </div>
  // ) : (
  //   <div>{location.state?.breadcrumb_data}</div>
  // );
}

function HeaderCommunityInfo({ closeMenu }) {
  const history = useHistory();
  const activeGroup = useSelector(getActiveGroup);
  const getActiveCommunityPublic = useSelector(getActiveCommunityViaPublicCommunity);
  const { primaryFont } = useSelector(getActiveCommunityFontsClass);

  const redirectToOverview = () => {
    if (closeMenu) closeMenu();
    history.push('/');
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      redirectToOverview();
    }
  };

  return (
    <div className="items-center justify-between my-2 flex overflow-hidden ltr:mr-3 rtl:ml-3">
      <div className="flex items-center">
        {activeGroup?.icon && (
          <img
            src={imageFetch(activeGroup?.icon, {
              resize: {
                height: USER_IMAGE_TYPE_6.HEIGHT,
                width: USER_IMAGE_TYPE_6.WIDTH,
                fit: 'inside',
              },
            })}
            className="flex-none block h-9 w-9 rounded-md"
            alt="community icon"
            loading="lazy"
          />
        )}
        <div className="flex flex-col overflow-hidden ltr:ml-3 rtl:mr-3 flex-none">
          <div
            className={classNames(
              'text-16 cursor-pointer text-left',
              primaryFont,
              closeMenu ? 'text__title' : 'text__header',
            )}
            onClick={redirectToOverview}
            role="button"
            tabIndex={0}
            onKeyDown={handleKeyDown}
          >
            {activeGroup?.name}
          </div>
          {/* <small>{currentActiveCommunity?.members_count} members</small> */}
        </div>
      </div>
      <button onClick={() => closeMenu()}>
        <XIcon className="lg:hidden block h-6 w-6 text__header" aria-hidden="true" />
      </button>
    </div>
  );
}

const LanguageChangeHandler = ({ selected, setSelected }) => {
  const communityId = useSelector(getActiveCommunityId);

  const LANGUAGE_FIELDS =
    communityId == 4375
      ? [
          { label: 'en', value: 'en' },
          { label: 'he', value: 'he' },
        ]
      : [
          { label: 'en', value: 'en' },
          { label: 'es', value: 'es' },
        ];

  const handleLanguageChangeHebrew = async (language) => {
    try {
      const response = await updateUserLanguagePreference(communityId, language);
      const { status } = response;
      if (status >= 200 && status < 300) {
        setSelected(language);
        localStorage.setItem('currentLocal', language?.value);
        window?.$setLocale(language?.value);
        window.location.href = window.location.href;
      }
    } catch (err) {
      console.log('Error :', err);
      NotificationService.error(err);
    }
  };

  const handleLanguageChangeSpanish = (language) => {
    setSelected(language);
    localStorage.setItem('currentLocal', language?.value);
    window?.$setLocale(language?.value);
    window.location.href = window.location.href;
  };

  return (
    <Listbox
      value={selected}
      onChange={(val) => {
        if (communityId == 4375) {
          handleLanguageChangeHebrew(val);
        } else {
          handleLanguageChangeSpanish(val);
        }
      }}
    >
      {({ open }) => (
        <div className="relative w-full flex items-center justify-between">
          <h1>{$translatei18n('Language')}</h1>
          <ListboxButton
            className={`relative border select__button rounded-md shadow-sm ltr:pl-2 ltr:pr-10 rtl:pr-2 rtl:pl-10 py-1 text-left cursor-default focus:outline-none focus:ring-0 sm:text-sm flex items-center justify-between ${
              true && 'cursor-not-allowed opacity-50'
            }`}
          >
            <div className="block truncate">{selected?.label}</div>
            <span className="absolute inset-y-0 ltr:right-0 rtl:left-0 flex items-center ltr:pr-2 rtl:pl-2 pointer-events-none">
              <SelectorIcon className="h-5 w-5" aria-hidden="true" />
            </span>
          </ListboxButton>

          <Transition
            show={open}
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <ListboxOptions className="absolute top-7 z-10 mt-1 w-full shadow max-h-60 rounded-md py-1 text-base border select__options__container overflow-auto focus:outline-none sm:text-sm">
              {LANGUAGE_FIELDS.map((option) => (
                <ListboxOption
                  key={option.value}
                  className={({ active }) =>
                    classNames(
                      active && 'select__option--active',
                      'cursor-default select-none relative py-2 ltr:pl-3 ltr:pr-9 rtl:pl-3 rtl:pr-9 select__option',
                    )
                  }
                  value={option}
                >
                  {({ selected }) => (
                    <>
                      <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                        {option.label}
                      </span>

                      {selected ? (
                        <span className={classNames('absolute inset-y-0 right-0 flex items-center ltr:pr-4 rtl:pl-4')}>
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </ListboxOption>
              ))}
            </ListboxOptions>
          </Transition>
        </div>
      )}
    </Listbox>
  );
};

function ActionButtons({ open, isCommunityOwner }) {
  const currentLoggedInUserImage = useSelector(getCurrentLoggedInUserImage);
  const [isImageLoadingError, setIsImageLoadingError] = useState(false);
  const currentActiveCommunity = useSelector(getActiveCommunity);
  const getActiveCommunityPublic = useSelector(getActiveCommunityViaPublicCommunity);
  const currentActiveGroupIsMainGroup = useSelector(isCurrentActiveGroupIsMainGroup);
  const unreadDm = useSelector(getUnreadDmCount);
  const isAppInApp = useSelector(getIsAppInApp);
  const subscriptionActive = useSelector((state) => state?.community?.subscriptionActive);
  const isSubscriptionActiveNull = subscriptionActive ?? true;
  const isOwner = useSelector((state) => state?.community?.isOwner);
  const isOldUser = isNullOrUndefined(subscriptionActive);

  const dispatch = useDispatch();
  const history = useHistory();
  const [link, setLink] = useState();
  const [openShareSheet, setOpenShareSheet] = useState(false);
  const communityConfig = useSelector((state) => state?.community?.communityConfig);
  const groupConfig = useSelector((state) => state?.channel?.configs?.groupConfigs);
  const activeUserId = useSelector(getCurrentLoggedInUserId);
  const activeGroup = useSelector(getActiveGroup);
  const publicCommunityDetails = useSelector((state) => state?.community?.publicCommunityDetails);
  const logout_url = publicCommunityDetails?.settings?.logout_url;
  const { planPriceId } = useSelector((state) => state?.plans?.currentActiveProduct);
  const isCustomSSOEnabled = publicCommunityDetails?.custom_sso.length > 0;

  const inviteManagementHelpers = useInviteManagement();

  const [selected, setSelected] = useState(
    localStorage.getItem('currentLocal')
      ? { label: localStorage.getItem('currentLocal'), value: localStorage.getItem('currentLocal') }
      : { label: 'en', value: 'en' },
  );

  const userMenuItems = [
    // { label: 'My communities', value: 'myCommunities' },
    { label: $translatei18n('MyBookmarks'), value: 'myBookmarks' },
    {
      label: <LanguageChangeHandler selected={selected} setSelected={setSelected} />,
      value: 'language',
      className: 'w-full py-1',
    },
    {
      label: $translatei18n('CommunitySettings'),
      value: 'communitySetting',
      className: 'sm:hidden md:hidden lg:hidden usetiful-community-settings',
    },
    {
      label: $translatei18n('JoinWhatsapp'),
      value: 'joinWhatsapp',
      className: `${!isOwner ? 'hidden' : ''} sm:hidden md:hidden lg:hidden`,
    },
    {
      label: 'Send Invites',
      value: 'shareCommunity',
      className: 'header__user__menu--share--community',
    },
    {
      label: $translatei18n('Leaderboard'),
      value: 'leaderboard',
      className: `${activeGroup?.leaderboard_enable ? 'visible lg:hidden' : 'hidden'}`,
    },
    { label: $translatei18n('ViewProfile'), value: 'showProfile' },
    {
      label: $translatei18n('ManageAccount'),
      value: 'manageAccount',
      className: isCustomSSOEnabled ? 'hidden' : '',
    },
    {
      label: $translatei18n('ContactSupport'),
      value: 'contactSupport',
      className: currentActiveCommunity?.support_email ? '' : ' hidden',
    },
    {
      label: $translatei18n('Logout'),
      value: 'logout',
      className: ' text__danger border-t divider header__user__menu--logout',
      id: isAppInApp ? 'logout' : '',
    },
  ];

  const userMenuItemsTwo = [
    {
      label: <LanguageChangeHandler selected={selected} setSelected={setSelected} />,
      value: 'language',
      className: 'w-full py-1',
    },
    {
      label: $translatei18n('CommunitySettings'),
      value: 'communitySetting',
      className: 'sm:hidden md:hidden lg:hidden usetiful-community-settings',
    },
    {
      label: 'Join Whatsapp',
      value: 'joinWhatsapp',
      className: `${!isOwner ? 'hidden' : ''} sm:hidden md:hidden lg:hidden`,
    },
    {
      label: 'Send Invites',
      value: 'shareCommunity',
      className: 'header__user__menu--share--community',
    },
    {
      label: $translatei18n('ManageAccount'),
      value: 'manageAccount',
      className: isCustomSSOEnabled ? 'hidden' : '',
    },
    {
      label: $translatei18n('ContactSupport'),
      value: 'contactSupport',
      className: currentActiveCommunity?.support_email ? '' : ' hidden',
    },
    {
      label: $translatei18n('Logout'),
      value: 'logout',
      className: ' text__danger border-t divider header__user__menu--logout',
      id: isAppInApp ? 'logout' : '',
    },
  ];

  const handleUserProfileActionChange = (value) => {
    switch (value) {
      case 'logout':
        try {
          dispatch(closeChannelConnection(() => {}, true));
          dispatch(closeChatChannelConnection());
          sessionStorage.removeItem('invite_message');
          if (logout_url) {
            window.location.href = logout_url;
          } else {
            dispatch(authLogout(true));
          }
        } catch (error) {
          console.error('Logout Error =>', error);
        }
        break;
      case 'myCommunities':
        history.push('/community-switcher');
        break;
      case 'myBookmarks':
        history.push('/profile/bookmarks');
        break;
      case 'shareCommunity':
        handleShareClick();
        break;
      case 'showProfile':
        history.push(`/profile?user_id=${activeUserId}`, { isFromOverview: window.location.pathname === '/' });
        break;
      case 'manageAccount':
        history.push('/manage-account');
        break;
      case 'leaderboard':
        dispatch(mobileLeaderboardModal(true));
        break;
      case 'communitySetting':
        handleSettingsRedirect();
        break;
      case 'joinWhatsapp':
        handleWhatsappRedirect();
        break;
      case 'contactSupport':
        window.location.assign(`mailto:${currentActiveCommunity?.support_email}`);
        break;
      default:
        break;
    }
  };

  const handleShareClick = () => {
    sessionStorage.setItem(SEND_INVITE_TRIGGERED_FROM, 'profile_send_invites');
    AnalyticService.trackAmplitudeEvent(INVITE_USERS_AMPLITUDE_EVENT_TITLE.SEND_INVITE_PROFILE_SECTION, {
      ...analyticsDefaults(true),
    });
    inviteManagementHelpers?.handleSendInviteDrawer(true);
  };

  const handleSettingsRedirect = (e) => {
    if (!activeGroup?.main_group && groupConfig?.canManageGroup) {
      history.push(`/group-settings/${activeGroup?.id}`);
    } else if (communityConfig?.canManageCommunity) {
      history.push(SETTINGS_ROUTES_CONSTANTS.COMMUNITY_SETTINGS);
    } else if (communityConfig?.canManageRoles) {
      history.push(SETTINGS_ROUTES_CONSTANTS.PEOPLE_MANAGEMENT);
    } else if (communityConfig?.canManageMembers) {
      history.push(SETTINGS_ROUTES_CONSTANTS.MODERATION);
    } else if (activeGroup?.main_group && groupConfig?.canManageGroup) {
      history.push(SETTINGS_ROUTES_CONSTANTS.NEW_CHANNEL);
    }
  };

  // to determine whether the user is in free and paid plan
  const isActivePlan = [PLAN_PRICE_ID.HUSTLER, PLAN_PRICE_ID.PRO, PLAN_PRICE_ID.ENTERPRISE].includes(planPriceId);

  const handleWhatsappRedirect = () => {
    if (isOldUser || isActivePlan) {
      openInNewTab(process.env.REACT_APP_GRAPHY_WHATSAPP_PAID);
    } else {
      openInNewTab(process.env.REACT_APP_GRAPHY_WHATSAPP_FREE);
    }
  };

  const toRenderMenuItem = (menuItem) => {
    if (
      menuItem?.value === 'communitySetting' &&
      !communityConfig?.canManageCommunity &&
      !communityConfig?.canManageRoles &&
      !groupConfig?.canManageGroup &&
      !communityConfig?.canManageMembers
    ) {
      return false;
    }
    if (menuItem?.value === 'myBookmarks' && !currentActiveGroupIsMainGroup) {
      return false;
    }

    return true;
  };

  const toRenderSettingsIcon = useMemo(() => {
    if (activeGroup?.main_group) {
      return (
        communityConfig?.canManageCommunity ||
        groupConfig?.canManageGroup ||
        communityConfig?.canManageRoles ||
        communityConfig?.canManageMembers
      );
    }
    return groupConfig?.canManageGroup;
  }, [activeGroup?.main_group, communityConfig?.canManageCommunity, groupConfig?.canManageGroup]);

  const handleMessageInboxClick = () => {
    if (isSubscriptionActiveNull || subscriptionActive) {
      dispatch(setActivePrivateRoom(null));
      history.push('/inbox');
    } else {
      return;
    }
  };

  return (
    <div
      className={open ? 'hidden' : 'flex justify-center items-center gap-3 lg:gap-4 xs:gap-1.5'}
      id="foundershipNotification"
    >
      {isSubscriptionActiveNull || subscriptionActive ? (
        <GlobalSearch />
      ) : (
        <HideContentWrapper circle={{ width: '1.5rem', height: '1.5rem', bgColor: 'E5E7EB' }} />
      )}

      <div type="button" className="relative" onClick={handleMessageInboxClick} id="direct__message">
        {isSubscriptionActiveNull || subscriptionActive ? (
          <button className="p-1.5 text-gray-500 hover:text-gray-700 focus:text-black cursor-pointer">
            <MailIcon className="h-6 w-6 text__header" />
          </button>
        ) : (
          <HideContentWrapper circle={{ width: '1.5rem', height: '1.5rem', bgColor: 'E5E7EB' }} />
        )}

        {isSubscriptionActiveNull || subscriptionActive
          ? unreadDm !== 0 && (
              <span
                style={{
                  fontSize: '12px',
                }}
                className="rounded-full absolute text-white ltr:left-5 rtl:right-3"
              >
                <div
                  className="w-4 h-4 rounded-full bg-red-500 flex items-center justify-center"
                  style={{ fontSize: '10px' }}
                >
                  {unreadDm}
                </div>
              </span>
            )
          : null}
      </div>
      <ShareModal link={link} open={openShareSheet} onClose={() => setOpenShareSheet(false)} />

      <InviteDrawer {...inviteManagementHelpers} />

      {isSubscriptionActiveNull || subscriptionActive ? (
        <NotificationList communityId={currentActiveCommunity?.id} />
      ) : (
        <HideContentWrapper circle={{ width: '1.5rem', height: '1.5rem', bgColor: 'E5E7EB' }} />
      )}

      {/* <button className="mr-5 rounded flex items-center focus:outline-none xs:hidden"
      onClick={handleShareClick}>
        <ShareIcon className="h-6 w-6 text__header" aria-hidden="true" />
      </button> */}

      {isOwner && (
        <div
          data-tooltip-content={$translatei18n('JoinWhatsappGroup')}
          data-tooltip-id={'whatsappSupport'}
          className="z-[21]"
        >
          <button
            type="button"
            className="p-1 rounded flex items-center focus:outline-none xs:hidden usetiful-whatsapp-icon"
            onClick={handleWhatsappRedirect}
          >
            <WhatsapIcon />
          </button>
          <ReactTooltip id={'whatsappSupport'} place="bottom-end" effect="solid" />
        </div>
      )}

      {toRenderSettingsIcon &&
        (isSubscriptionActiveNull || subscriptionActive ? (
          <button
            type="button"
            className="p-1 rounded flex items-center focus:outline-none xs:hidden usetiful-settings-icon"
            onClick={handleSettingsRedirect}
          >
            <CogIcon id="settings" className="h-6 w-6 text__header" aria-hidden="true" />
          </button>
        ) : (
          <HideContentWrapper circle={{ width: '1.5rem', height: '1.5rem', bgColor: 'E5E7EB' }} />
        ))}

      <CommonBasicDropdown
        dropdownButton={
          currentLoggedInUserImage && !isImageLoadingError ? (
            <img
              className="inline-block h-8 w-8 rounded-full"
              src={imageFetch(currentLoggedInUserImage, {
                resize: {
                  height: USER_IMAGE_TYPE_2.HEIGHT,
                  width: USER_IMAGE_TYPE_2.WIDTH,
                  fit: 'inside',
                },
              })}
              alt="profile"
              onError={() => setIsImageLoadingError(true)}
            />
          ) : (
            <CommonNameAvatar type={COMMON_NAME_AVATAR_TYPE.CIRCULAR} size={COMMON_NAME_AVATAR_SIZE.SM} alt="profile" />
          )
        }
        // eslint-disable-next-line eqeqeq
        // Hide lang switcher other than 4375(7rings)
        menuItems={
          getActiveCommunityPublic == 4375 || getActiveCommunityPublic == 4667
            ? userMenuItems
            : isSubscriptionActiveNull || subscriptionActive
            ? userMenuItems.filter((itm) => itm?.value !== 'language')
            : userMenuItemsTwo.filter((itm) => itm?.value !== 'language')
        }
        onChange={handleUserProfileActionChange}
        toRenderMenuItem={toRenderMenuItem}
      />
    </div>
  );
}

function SpecialActions({ channel, activeCommunityAccentColor, activeGroupId, open }) {
  if (!channel?.id || !channel?.community_id || !channel?.name) {
    return null;
  }
  const channelType = channel.channel_type;
  const history = useHistory();
  const dispatch = useDispatch();
  const resourcePermissions = useSelector((state) => state?.resource?.channelPermissionsConfig);
  const calendarPermissions = useSelector((state) => state?.calendar?.channelPermissionsConfig);
  const { primaryFont } = useSelector(getActiveCommunityFontsClass);
  const getActiveCommunityPublic = useSelector(getActiveCommunityViaPublicCommunity);

  const onResourceCreatePressed = () => {
    history.push(
      `/communities/${channel?.community_id}/group/${activeGroupId}/channels/${channel?.id}/resource/create`,
    );
  };

  const enableCreateEventModal = () => {
    dispatch(updateCreateCalenderModal(true));
    dispatch(setEventSource({ eventSource: '' }));
  };

  if (open) return null;

  switch (channelType) {
    // case 'resources':
    //   return resourcePermissions?.canCreateResources ? (
    //     <button
    //       style={getButtonCSS(activeCommunityAccentColor)}
    //       className={classNames(
    //         'border border-transparent p-2 md:py-2 md:px-4 md:w-24 rounded-full md:rounded inline-flex justify-center items-center btn__primary',
    //         getActiveCommunityPublic == 3764 ? '' : 'ltr:mr-2 ltr:md:mr-8 rtl:ml-2 rtl:md:ml-8',
    //         primaryFont,
    //       )}
    //       onClick={onResourceCreatePressed}
    //       type="button"
    //     >
    //       <PlusIcon className="w-4 h-4 flex-none" />
    //       <span className="ltr:ml-1 rtl:mr-1 text-sm hidden md:block font-semibold flex-none">
    //         {$translatei18n('Post')}
    //       </span>
    //     </button>
    //   ) : null;
    case 'calendar':
      return calendarPermissions?.canManageEvents ? (
        <button
          style={getButtonCSS(activeCommunityAccentColor)}
          className={classNames(
            'border border-transparent p-2 md:py-2 md:px-4 md:w-24 rounded-full md:rounded inline-flex justify-center items-center btn__primary',
            getActiveCommunityPublic == 3764 ? '' : 'ltr:mr-2 ltr:md:mr-8 rtl:ml-2 rtl:md:ml-8',
            primaryFont,
          )}
          onClick={() => enableCreateEventModal()}
          type="button"
        >
          <PlusIcon className="w-4 h-4 flex-none" />
          <span className="ml-1 text-sm hidden md:block font-semibold flex-none">{$translatei18n('Event')}</span>
        </button>
      ) : null;
    default:
      return null;
  }
}

export const TOP_HEADER_TYPE = {
  MAIN_LAYOUT: 'MAIN_LAYOUT',
  COMMUNITY_SETTINGS: 'COMMUNITY_SETTINGS',
  CHANNEL_SETTINGS: 'CHANNEL_SETTINGS',
  CHANNEL_CATEGORY_SETTINGS: 'CHANNEL_CATEGORY_SETTINGS',
  GROUP_SETTINGS: 'GROUP_SETTINGS',
  INBOX: 'INBOX',
};

function DisplaySidebar({ open, headerType, closeMenu, openDeleteConfirmation, setOpenDeleteConfirmation }) {
  switch (headerType) {
    case TOP_HEADER_TYPE.MAIN_LAYOUT:
      return (
        <Sidebar open={open} communityInfo={<HeaderCommunityInfo closeMenu={closeMenu} />} closeMenu={closeMenu} />
      );
    case TOP_HEADER_TYPE.COMMUNITY_SETTINGS:
      return <CommunitySettingSidebar open={open} closeMenu={closeMenu} />;
    case TOP_HEADER_TYPE.CHANNEL_SETTINGS:
      return (
        <ChannelSettingSidebar
          open={open}
          closeMenu={closeMenu}
          openDeleteConfirmation={openDeleteConfirmation}
          setOpenDeleteConfirmation={setOpenDeleteConfirmation}
        />
      );
    case TOP_HEADER_TYPE.CHANNEL_CATEGORY_SETTINGS:
      return <ChannelCategorySettingSidebar open={open} closeMenu={closeMenu} />;
    case TOP_HEADER_TYPE.GROUP_SETTINGS:
      return <GroupSettingSidebar open={open} closeMenu={closeMenu} />;
    default:
      return null;
  }
}

export default function TopHeader({ headerType = TOP_HEADER_TYPE.MAIN_LAYOUT, headerTitle }) {
  const isForumChannel = useSelector(isForumChannelType);
  const isForumCreatePostPermissionChannel = useSelector((state) => state?.forum?.permissions?.canCreatePosts);
  const activeChannel = useSelector((state) => state?.channel?.activeChannelData);
  const activeCommunityAccentColor = useSelector(getActiveCommunityAccentColor);
  const getActiveCommunityPublic = useSelector(getActiveCommunityViaPublicCommunity);
  const activeCommunity = useSelector(getActiveCommunity);
  const activeGroupId = useSelector(getActiveGroupId);
  const location = useLocation();
  const isShopListingPath = matchPath(location.pathname, {
    path: ROUTE_PATH.SHOPLISTING,
  });
  const shopData = useSelector(getShopData);
  const isCommunityOwner = useSelector((state) => state?.channel?.communityOwner);
  const isOwner = useSelector((state) => state?.community?.isOwner);
  const displayCreateProduct = isShopListingPath && isCommunityOwner && shopData?.payment_provider;
  const subscriptionActive = useSelector((state) => state?.community?.subscriptionActive);

  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  const [open, setOpen] = useState(false);
  const close = () => setOpen(false);

  return (
    <>
      <header
        className={classNames(
          open ? 'fixed inset-0 z-30' : '',
          'header fixed top-0 left-0 right-0 z-30 shadow-sm lg:overflow-y-visible flex flex-col',
        )}
      >
        <div className="flex h-full relative">
          {/* Placeholder for narrowsidebar */}
          {headerType === TOP_HEADER_TYPE.MAIN_LAYOUT && activeCommunity?.enable_groups && (
            <div className={open ? 'flex lg:hidden' : 'hidden'}>
              <div className="w-16 h-full bg-white" />
            </div>
          )}

          {activeCommunity?.enable_groups && headerType === TOP_HEADER_TYPE.MAIN_LAYOUT && (
            <div className="w-16 h-full bg-white hidden lg:block" />
          )}

          <div className={classNames('flex flex-col w-full h-full', open ? 'overflow-y-auto' : '')}>
            <div
              className={classNames(
                'max-w-7xl mx-auto px-4 md:px-6 lg:px-6 w-full',
                open ? 'main--background xs:pt-2' : 'py-1 xs:py-2',
              )}
            >
              <div className="relative flex justify-between items-center xl:grid xl:grid-cols-12">
                <div className="flex items-center md:absolute md:left-0 md:inset-y-0 lg:hidden">
                  {/* Mobile menu button */}
                  {headerType !== TOP_HEADER_TYPE.INBOX && !open && (
                    <button
                      onClick={() => setOpen(true)}
                      className="-mx-2 rounded-md p-2 inline-flex items-center justify-center focus:outline-none focus:ring-0 focus:ring-inset foundership-menu usetiful-hamburger-icon"
                    >
                      <span className="sr-only">Open menu</span>
                      <MenuIcon className="block h-6 w-6 text__header" aria-hidden="true" />
                    </button>
                  )}
                </div>
                <div className="md:absolute md:left-0 md:inset-y-0 lg:static xl:col-span-2 hidden lg:flex lg:w-sidebar">
                  <HeaderCommunityInfo />
                </div>
                <div className="flex-1 px-2 md:pt-0 md:px-8 xl:col-span-6 lg:my-2 ltr:lg:pr-8 ltr:lg:pl-0 ltr:xl:pl-8 rtl:lg:pl-8 rtl:lg:pr-0 rtl:xl:pr-8 flex align-center">
                  <HeaderTitle headerTitle={headerTitle} open={open} />
                </div>
                <div className="flex-none flex items-center justify-end pt-2 xs:pt-1 sm:pt-1 md:pt-0 xl:col-span-4">
                  <div>
                    {/* {!open && isForumChannel && isForumCreatePostPermissionChannel && <ForumCreatePost />} */}
                    <SpecialActions
                      open={open}
                      channel={activeChannel}
                      activeCommunityAccentColor={activeCommunityAccentColor}
                      activeGroupId={activeGroupId}
                    />
                    {displayCreateProduct && <CreateShopButton />}
                  </div>
                  {
                    // eslint-disable-next-line eqeqeq
                    !open && getActiveCommunityPublic == 3764 ? (
                      <div className="flex xs:hidden sm:hidden ltr:md:mr-0 ltr:sm:mr-0 ltr:lg:mr-16 rtl:md:ml-0 rtl:sm:ml-0 rtl:lg:ml-16 ltr:pl-4 rtl:pl-4 text-white">
                        <a
                          href="https://www.foundershiphq.com/programs-type/cohort-based-programs"
                          className="border w-20 text-center border-transparent rounded-md text-base font-semibold"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Programs
                        </a>
                        <a
                          href="https://www.foundershiphq.com/funding"
                          className="border w-20 text-center border-transparent rounded-md text-base font-semibold"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Funding
                        </a>
                        <a
                          href="https://www.foundershiphq.com/success-stories"
                          className="border w-28 text-center border-transparent rounded-md text-base font-semibold"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Success Stories
                        </a>
                        <a
                          href="https://www.foundershiphq.com/blog"
                          className="border w-20 text-center border-transparent rounded-md text-base font-semibold"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Blog
                        </a>
                      </div>
                    ) : null
                  }

                  <ActionButtons open={open} isCommunityOwner={isCommunityOwner} />
                </div>
              </div>
            </div>

            {open && (
              <nav className="lg:hidden main--background flex-1" aria-label="Global">
                <div className="max-w-3xl mx-auto px-2 pt-2 pb-3 space-y-1 sm:px-4">
                  <DisplaySidebar
                    open={open}
                    headerType={headerType}
                    closeMenu={close}
                    openDeleteConfirmation={openDeleteConfirmation}
                    setOpenDeleteConfirmation={setOpenDeleteConfirmation}
                  />
                </div>

                {/* Group Switcher */}
                {headerType === TOP_HEADER_TYPE.MAIN_LAYOUT && activeCommunity?.enable_groups && (
                  <div className="flex absolute inset-0 w-16 lg:hidden">
                    {subscriptionActive === false ? null : <NarrowSidebar />}
                  </div>
                )}
              </nav>
            )}
          </div>
        </div>
      </header>

      {openDeleteConfirmation && <DeleteConfirmationModal setOpenDeleteConfirmation={setOpenDeleteConfirmation} />}
    </>
  );
}
