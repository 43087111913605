import { Fragment, useEffect } from 'react';
import { Dialog, DialogTitle, Transition, TransitionChild } from '@headlessui/react';
import { FaTimes, FaWhatsapp, FaFacebookF, FaRedditAlien, FaTwitter, FaEnvelope, FaLinkedinIn, FaTelegram } from 'react-icons/fa';
import { FaXTwitter } from 'react-icons/fa6';
import { ShareIcon } from "@heroicons/react/outline"
import NotificationService from '../../services/notificationService';
import { useSelector } from 'react-redux';
import { getActiveCommunity, getBranchInitError } from '../../selectors/CommunitySelectors';
import { ExclamationIcon } from '@heroicons/react/outline';
import { getIsAppInApp } from '../../selectors/AppInAppSelectors';

const ShareModal = ({ open, link, onClose }) => {
  const branchInitError = useSelector(getBranchInitError);
  const isAppInApp = useSelector(getIsAppInApp)
  const community = useSelector(getActiveCommunity)

  const shareOnFacebook = () => {
    const url = `https://www.facebook.com/sharer/sharer.php?u=${link}`;
    window.open(url, '');
  };

  const shareOnTwitter = () => {
    const url = `https://twitter.com/intent/tweet?url=${link}`;
    window.open(url, '');
  };

  const shareOnWhatsapp = () => {
    const url = `https://api.whatsapp.com/send/?phone&text=${link}`;
    window.open(url, '');
  };

  const shareOnReddit = () => {
    const url = `https://www.reddit.com/submit?url=${link}`;
    window.open(url, '');
  };

  const shareOnLinkedIn = () => {
    const url = `https://www.linkedin.com/shareArticle?url=${link}`;
    window.open(url, '');
  };

  const shareOnMail = () => {
    const url = `mailto:?body=${link}`;
    window.open(url, '');
  };

  const shareOnCopy = () => {
    navigator.clipboard.writeText(link);
    NotificationService.success('Link Copied');
    onClose();
  };

  const shareOnTelegran = () => {
    const url = `https://t.me/share/url?url=${link}`;
    window.open(url, '');
  };

  return (
    <Transition show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={() => {
          onClose(false);
        }}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" onClick={() => {
          onClose(false);
        }} />
          </TransitionChild>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom card rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md md:max-w-lg sm:w-full xs:w-full sm:p-6">
              {branchInitError && (
                <div className="p-2 rounded bg-red-500 mb-2 flex gap-1 md:gap-4">
                  <div className="mr-4 flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-white sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon className="h-6 w-6 text-red-500" aria-hidden="true" />
                  </div>
                  <h1 className="text-white text-semibold">
                    It appears you are using an Ad-blocker.{' '}
                    {/* {window.navigator.brave.isBrave.name == 'isBrave' && 'or Brave Shield'} please disable it or the */}
                    Shareable links might not function properly.
                  </h1>
                </div>
              )}
              {isAppInApp ?
                <div className='p-4 flex items-center justify-center'>
                  <div
                    style={{ backgroundColor: '#eee' }}
                    onClick={(e) => {
                      e.stopPropagation()
                      navigator.share({
                        title: community?.name,
                        url: link,
                      })
                        .then(() => { })
                        .catch((error) => { NotificationService.error("Sharing failed or was aborted."); console.log('Error sharing', error) });
                    }}
                    className='flex sm:h-12 sm:w-12 h-16 w-16 justify-center items-center ltr:ml-2 rtl:mr-2 md:ml-5 cursor-pointer rounded-full'>
                    <ShareIcon
                      className="w-7 h-7 cursor-pointer main__icon text-white"
                    />
                  </div>
                </div>
                :
                <>
                  <div className="flex flex-row justify-between items-center">
                    <DialogTitle as="h3" className="text-lg leading-6 font-medium text__title">
                      {$translatei18n('shareTo')}
                    </DialogTitle>
                    <FaTimes
                      onClick={(e) => {
                        e.stopPropagation();
                        onClose();
                      }}
                      className="cursor-pointer main__icon"
                    />
                  </div>

                  <div className="flex flex-row justify-between items-center mt-5" id="share">
                    <div
                      style={{ backgroundColor: '#000' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        shareOnTwitter();
                      }}
                      className="flex xs:h-10 xs:w-10 sm:h-12 sm:w-12 h-16 w-16 justify-center items-center cursor-pointer rounded-full"
                    >
                      <FaXTwitter className="xs:h-5 xs:w-5 sm:h-6 sm:w-6 h-9 w-9" color="#fff" />
                    </div>
                    <div
                      style={{ backgroundColor: '#25D366' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        shareOnWhatsapp();
                      }}
                      className="flex xs:h-10 xs:w-10 sm:h-12 sm:w-12 h-16 w-16 justify-center items-center ltr:ml-2 rtl:mr-2 md:ml-5 cursor-pointer rounded-full"
                    >
                      <FaWhatsapp className="xs:h-5 xs:w-5 sm:h-6 sm:w-6 h-9 w-9" color="#fff" />
                    </div>
                    <div
                      style={{ backgroundColor: '#0088cc' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        shareOnTelegran();
                      }}
                      className="flex xs:h-10 xs:w-10 sm:h-12 sm:w-12 h-16 w-16 justify-center items-center ltr:ml-2 rtl:mr-2 md:ml-5 cursor-pointer rounded-full"
                    >
                      <FaTelegram className="xs:h-5 xs:w-5 sm:h-6 sm:w-6 h-9 w-9" color="#fff" />
                    </div>
                    <div
                      style={{ backgroundColor: '#FF4501' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        shareOnReddit();
                      }}
                      className="flex xs:h-10 xs:w-10 sm:h-12 sm:w-12 h-16 w-16 justify-center items-center ltr:ml-2 rtl:mr-2 md:ml-5 cursor-pointer rounded-full"
                    >
                      <FaRedditAlien className="xs:h-5 xs:w-5 sm:h-6 sm:w-6 h-9 w-9" color="#fff" />
                    </div>
                    <div
                      style={{ backgroundColor: '#3B5998' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        shareOnFacebook();
                      }}
                      className="flex xs:h-10 xs:w-10 sm:h-12 sm:w-12 h-16 w-16 justify-center items-center ltr:ml-2 rtl:mr-2 md:ml-5 cursor-pointer rounded-full"
                    >
                      <FaFacebookF className="xs:h-5 xs:w-5 sm:h-6 sm:w-6 h-9 w-9" color="#fff" />
                    </div>
                    <div
                      style={{ backgroundColor: '#EA4335' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        shareOnMail();
                      }}
                      className="flex xs:h-10 xs:w-10 sm:h-12 sm:w-12 h-16 w-16 justify-center items-center ltr:ml-2 rtl:mr-2 md:ml-5 cursor-pointer rounded-full"
                    >
                      <FaEnvelope className="xs:h-5 xs:w-5 sm:h-6 sm:w-6 h-9 w-9" color="#fff" />
                    </div>
                    <div
                      style={{ backgroundColor: '#006097' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        shareOnLinkedIn();
                      }}
                      className="flex xs:h-10 xs:w-10 sm:h-12 sm:w-12 h-16 w-16 justify-center items-center ltr:ml-2 rtl:mr-2 md:ml-5 cursor-pointer rounded-full"
                    >
                      <FaLinkedinIn className="xs:h-5 xs:w-5 sm:h-6 sm:w-6 h-9 w-9" color="#fff" />
                    </div>
                  </div>
                </>
              }
              <div className="mt-6 mb-2 p-2 flex flex-row flex-wrap justify-center items-center gap-4 highlighted">
                <p className="text__body break-all">{link}</p>
                <p
                  className="cursor-pointer text__link font-semibold"
                  onClick={(e) => {
                    e.stopPropagation();
                    shareOnCopy();
                  }}
                >
                  {$translatei18n('copy')}
                </p>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ShareModal;
