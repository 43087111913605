import { RESET_USER_PROFILE_DATA, SET_USER_PROFILE_DATA } from './actionTypes';

export const setUserProfileData = (userProfileData) => ({
  type: SET_USER_PROFILE_DATA,
  userProfileData,
});

export const resetUserProfileData = () => ({
  type: RESET_USER_PROFILE_DATA,
});
