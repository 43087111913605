import { RESET_USER_PROFILE_DATA, SET_USER_PROFILE_DATA } from '../actions/actionTypes';

const initialState = {
  openUserProfile: false,
  selectedUserId: null,
  currentChannelId: null,
};

const reducer = (state = initialState, action) => {
  let newStateData = {
    openUserProfile: action?.userProfileData?.userId ? true : false,
    selectedUserId: action?.userProfileData?.userId ? action.userProfileData.userId : null,
    currentChannelId: action?.userProfileData?.channelId ? action.userProfileData.channelId : null,
  };

  switch (action.type) {
    case SET_USER_PROFILE_DATA:
      return newStateData;

    case RESET_USER_PROFILE_DATA:
      return initialState;

    default:
      return state;
  }
};

export default reducer;
