import { CogIcon, HomeIcon, ShoppingBagIcon } from '@heroicons/react/outline';
import { LockClosedIcon, PlusIcon } from '@heroicons/react/solid';
import classNames from 'classnames';
import isAfter from 'date-fns/isAfter';
import moment from 'moment';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { sortBy, cloneDeep } from 'lodash';
import { Collapse } from 'react-collapse';
import { BsCaretRightFill, BsCaretDownFill } from 'react-icons/bs';
import ChannelIcon from '../../components/layout/ChannelIcon';
import MinimizedTray from '../../components/minimizedTray/minimizedTray';
import ShareModal from '../../components/share/ShareModal';
import { OnlineUserIndicator } from '../../components/shared/OnlineUserIndicator';
import ConfirmationModal, { CONFIRMATION_TYPE } from '../../components/shared/tailwind/ConfirmationModal';
import ExitPromptLiveEvent from '../../components/shared/tailwind/ExitPromptLiveEvent';
import { CHANNEL_TYPES, ROUTE_PATH } from '../../constants';
import { isNullOrUndefined, classNames as joinClassNames } from '../../constants/utils';
import { getUtcTime } from '../../helpers';
import { getActiveChannelId, getActiveChannelType } from '../../selectors/ChannelSelectors';
import {
  getActiveCommunity,
  getActiveCommunityAccentColor,
  getActiveCommunityFontsClass,
  getActiveGroup,
  getActiveGroupId,
  isCurrentActiveGroupIsMainGroup,
} from '../../selectors/CommunitySelectors';
import { getAudioReducer, getTextReducer } from '../../selectors/StageTextSelectors';
import { endCalendarEvent } from '../../services/CalendarService';
import { imageFetch } from '../../services/preSignedAws';
import { updateAudioState } from '../../store/actions/audioActions';
import { authLogout } from '../../store/actions/authActions';
import {
  deleteActiveChannel,
  fetchAllCommunityChannels,
  setActiveChannel,
  setCurrentChannels,
  updateCategoryPosition,
  updateChannelPosition,
} from '../../store/actions/channelActions';
import { setActiveCommunity, setPremiumModal } from '../../store/actions/communityActions';
import { closeChannelConnection, updateTextState } from '../../store/actions/textActions';
import NotificationService from '../../services/notificationService';
import { getCurrentLoggedInUserId } from '../../selectors/ProfileSelectors';
import { getShopData } from '../../selectors/ShopSelectors';
import { SET_PRODUCT_LIST_VIEW } from '../../store/actions/actionTypes';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import SidebarHideContent from './SidebarHideContent';
import LockIcon from '../../components/icons/LockIcon';
import { setModalOpen } from '../../store/actions/planAction';
import { PLAN_PRICE_ID } from '../../constants/pricingConstants';

function LeftDrawer({ communityInfo, closeMenu }) {
  const [localIndicators, setLocalIndicators] = useState([]);
  const currentActiveCommunity = useSelector(getActiveCommunity);
  const { primaryFont } = useSelector(getActiveCommunityFontsClass);
  const activeCommunityAccentColor = useSelector(getActiveCommunityAccentColor);
  const currentCommunityChannels = useSelector((state) => state?.channel?.channels);
  const communityConfig = useSelector((state) => state?.community?.communityConfig);
  const groupConfig = useSelector((state) => state?.channel?.configs?.groupConfigs);
  const activeChannelId = useSelector(getActiveChannelId);
  const activeChannelType = useSelector(getActiveChannelType);
  const [link, setLink] = useState();
  const [openShareSheet, setOpenShareSheet] = useState(false);
  const audioReducer = useSelector(getAudioReducer);
  const textReducer = useSelector(getTextReducer);
  const [activeChannelData, setActiveChannelData] = useState({});
  const [openExitPrompt, setOpenExitPrompt] = useState(false);
  const location = useLocation();
  const isCommunityOverviewPath = matchPath(location.pathname, {
    path: ROUTE_PATH.COMMUNITY_OVERVIEW_DETAILS || '/',
  });
  const isShopPath = matchPath(location.pathname, {
    path: ROUTE_PATH.SHOPLISTING,
  });
  const activeGroupId = useSelector(getActiveGroupId);
  const activeGroup = useSelector(getActiveGroup);
  const currentActiveGroupIsMainGroup = useSelector(isCurrentActiveGroupIsMainGroup);
  const currentUserId = useSelector(getCurrentLoggedInUserId);
  const shopData = useSelector(getShopData);
  const isCommunityOwner = useSelector((state) => state?.channel?.communityOwner);
  const subscriptionActive = useSelector((state) => state?.community?.subscriptionActive);
  const isSubscriptionActiveNull = subscriptionActive ?? true;
  const { planPriceId } = useSelector((state) => state?.plans?.currentActiveProduct);
  const activeCommunityId = useSelector((state) => state?.community?.activeCommunity);
  const isOldUser = isNullOrUndefined(subscriptionActive);
  const isActivePlan = [PLAN_PRICE_ID.HUSTLER, PLAN_PRICE_ID.PRO, PLAN_PRICE_ID.ENTERPRISE].includes(planPriceId);

  const dispatch = useDispatch();
  const history = useHistory();

  const isCurrentUserHost = useCallback(
    (function getCurrentHostBoolean() {
      let isCurrentUserHost = false;
      currentActiveCommunity?.hosts?.forEach((host) => {
        if (host?.id?.toString() === currentUserId?.toString()) {
          isCurrentUserHost = true;
        }
      });
      return isCurrentUserHost;
    })(),
    [],
  );

  useEffect(() => {
    if (isSubscriptionActiveNull || subscriptionActive) {
      dispatch(fetchAllCommunityChannels({ activeCommunityId: activeCommunityId, groupId: activeGroupId }));
    }
  }, [activeGroupId]);

  const handleOverviewClick = (e) => {
    history.push('/');
    closeMenu && closeMenu();
  };

  const navigateToShopListing = () => {
    if (!isOldUser && !isActivePlan) {
      dispatch(setModalOpen());
    } else {
      dispatch(deleteActiveChannel());
      history.push(ROUTE_PATH.SHOPLISTING);
      dispatch({
        type: SET_PRODUCT_LIST_VIEW,
        payload: 'SHOP',
      });
      if (closeMenu) closeMenu();
    }
  };

  const handleChannelClick = (data, channelData) => {
    if (channelData?.locked) {
      dispatch(setPremiumModal(true));
      return;
    }
    if (data) {
      if (data?.unread_channel === true && data?.channel_type !== 'stage') {
        setLocalIndicators([...localIndicators, data?.id]);
      }
    }

    const { connected, isMinimized } = audioReducer;

    if (connected === true && isMinimized === false && data?.channel_type !== CHANNEL_TYPES.STAGE) {
      dispatch(updateAudioState({ isMinimized: true }));
    }
    if (data?.channel_type === CHANNEL_TYPES.FORUM) {
      dispatch(setActiveChannel(data));
      history.push(
        `/communities/${currentActiveCommunity?.id}/group/${activeGroupId}/channels/${data?.id}/forumposts`,
        {
          breadcrumb_data: `${currentActiveCommunity?.name} > ${data?.name}`,
        },
      );
    }
    if (data?.channel_type === CHANNEL_TYPES.RESOURCE) {
      dispatch(setActiveChannel(data));
      history.push(
        `/communities/${currentActiveCommunity?.id}/group/${activeGroupId}/channels/${data?.id}/resourceposts`,
        {
          breadcrumb_data: `${currentActiveCommunity?.name} > ${data?.name}`,
        },
      );
    }
    if (data?.channel_type === CHANNEL_TYPES.STAGE) {
      if (activeChannelType === 'stage') {
        const { channelData } = textReducer;
        if (channelData?.channel?.id === data?.id) {
          dispatch(updateTextState({ redirect: false }));
          dispatch(setActiveChannel(data));
          history.push(`/communities/${currentActiveCommunity?.id}/group/${activeGroupId}/channels/${data.id}/stage`, {
            breadcrumb_data: `${currentActiveCommunity?.name} > ${data?.name}`,
          });
        } else {
          // setOpenExitPrompt(true);
          dispatch(updateAudioState({ isChangedStage: true, stageData: data }));
          setActiveChannelData(data);
        }
      } else {
        const { connected } = audioReducer;
        if (connected === true) {
          const { channelData } = textReducer;
          if (channelData?.channel?.id === data?.id) {
            dispatch(updateTextState({ redirect: false }));
            dispatch(setActiveChannel(data));
            history.push(
              `/communities/${currentActiveCommunity?.id}/group/${activeGroupId}/channels/${data.id}/stage`,
              {
                breadcrumb_data: `${currentActiveCommunity?.name} > ${data?.name}`,
              },
            );
          } else {
            setActiveChannelData(data);
            dispatch(updateAudioState({ isChangedStage: true, stageData: data }));
            // setOpenExitPrompt(true);
          }
        } else {
          dispatch(updateTextState({ redirect: false }));
          dispatch(setActiveChannel(data));
          history.push(`/communities/${currentActiveCommunity?.id}/group/${activeGroupId}/channels/${data.id}/stage`, {
            breadcrumb_data: `${currentActiveCommunity?.name} > ${data?.name}`,
          });
        }
      }
    }
    if (data?.channel_type === CHANNEL_TYPES.CALENDER) {
      dispatch(setActiveChannel(data));
      history.push(
        `/communities/${currentActiveCommunity?.id}/group/${activeGroupId}/channels/${data?.id}/calendarposts`,
        {
          breadcrumb_data: `${currentActiveCommunity?.name} > ${data?.name}`,
        },
      );
    }
    if (data?.channel_type === CHANNEL_TYPES.ZOOM) {
      dispatch(setActiveChannel(data));
      history.push(`/communities/${currentActiveCommunity?.id}/group/${activeGroupId}/channels/${data?.id}/zoomposts`, {
        breadcrumb_data: `${currentActiveCommunity?.name} > ${data?.name}`,
      });
    }
    if (data?.channel_type === CHANNEL_TYPES.TEXT) {
      dispatch(setActiveChannel(data));
      history.push(`/communities/${currentActiveCommunity?.id}/group/${activeGroupId}/channels/${data.id}/text`, {
        breadcrumb_data: `${currentActiveCommunity?.name} > ${data?.name}`,
      });
    }
    if (data?.channel_type === CHANNEL_TYPES.EMBED) {
      dispatch(setActiveChannel(data));
      history.push(`/communities/${currentActiveCommunity?.id}/group/${activeGroupId}/channels/${data.id}/iframe`, {
        breadcrumb_data: `${currentActiveCommunity?.name} > ${data?.name}`,
      });
    }
    if (data?.channel_type === CHANNEL_TYPES.STREAMING) {
      dispatch(setActiveChannel(data));
      history.push(`/communities/${currentActiveCommunity?.id}/group/${activeGroupId}/channels/${data.id}/streaming`, {
        breadcrumb_data: `${currentActiveCommunity?.name} > ${data?.name}`,
      });
    }

    closeMenu && closeMenu();
  };

  const handleLogout = () => {
    dispatch(authLogout());
  };

  const redirectToChannelSettings = (event, channelId) => {
    event.stopPropagation();
    history.push(`/channel-settings/${channelId}`);
  };

  const redirectToChannelCategorySettings = (channelCategoryId) => {
    history.push(`/channel-category-settings/${channelCategoryId}`);
  };

  const disconnect = () => {
    if (audioReducer.connected) {
      dispatch(
        closeChannelConnection(() => {
          // dispatch(updateTextState({ redirect: false }));
          // dispatch(setActiveChannel(activeChannelData));
          dispatch(setActiveChannel(audioReducer.stageData));
          // setOpenExitPrompt(false);
          dispatch(updateAudioState({ isChangedStage: false }));
          history.push(
            `/communities/${currentActiveCommunity?.id}/group/${activeGroupId}/channels/${audioReducer.stageData?.id}/stage`,
            {
              breadcrumb_data: `${currentActiveCommunity?.name} > ${audioReducer.stageData?.name}`,
            },
          );
        }, true),
      );
    }
  };

  const minimizedTray = () => {
    const { connected, isMinimized } = audioReducer;

    if (connected === true && isMinimized === true) {
      return (
        <MinimizedTray
          closeMenu={closeMenu}
          audioReducer={audioReducer}
          textReducer={textReducer}
          activeCommunity={currentActiveCommunity}
        />
      );
    }
    return null;
  };

  const getDayStart = (time) => {
    const dt = getUtcTime(time);
    return moment(dt, 'YYYY-MM-DD HH:mm:ss');
  };

  const isEventRunning = (start, end) => {
    const nowTime = moment(Date.now());
    const isBwt = nowTime.isBetween(getDayStart(start), getDayStart(end));
    return isBwt;
  };

  const endEvent = async (isCurrentEvent, liveEventId) => {
    if (isCurrentEvent === true) {
      try {
        const { community } = textReducer?.channelData;
        const calendarResponse = await endCalendarEvent(community.id, liveEventId, activeGroupId);
        const { status } = calendarResponse;
        if (status >= 200 && status < 300) {
          disconnect();
        }
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  const renderExitPromptModal = () => {
    const { isChangedStage } = audioReducer;
    const { channelData } = textReducer;
    const isHost = channelData.host;
    let liveEventName = '';
    let liveEventId = '';
    let isCurrentEvent;
    let selectedEvent = {};

    const { events } = channelData;

    if (events !== undefined && events !== null) {
      if (events.length > 0) {
        selectedEvent = events[0];
        liveEventName = events[0].name;
        liveEventId = events[0].id;
        isCurrentEvent = isAfter(new Date(), new Date(getUtcTime(events[0].start_time)));
      }
    }

    return (
      <div>
        {isChangedStage === true ? (
          isHost === true && isEventRunning(selectedEvent.start_time, selectedEvent.stop_time) ? (
            <ExitPromptLiveEvent
              infoText={'Event will automatically end if\nno speaker is detected on stage for 30mins'}
              open={isChangedStage}
              onClose={() => dispatch(updateAudioState({ isChangedStage: false }))}
              disConnectStage={() => disconnect()}
              endEvent={() => endEvent(isCurrentEvent, liveEventId)}
            />
          ) : (
            <ConfirmationModal
              infoText={$translatei18n('areYouSureYouWantToLeaveThisConversation')}
              open={isChangedStage}
              onClose={() => dispatch(updateAudioState({ isChangedStage: false }))}
              onConfirm={() => disconnect()}
              confirmType={CONFIRMATION_TYPE.LEAVE}
            />
          )
        ) : null}
      </div>
    );
  };

  const redirectToAddChannel = (categoryId) => {
    if (!activeGroup?.main_group) history.push(`/group-settings/${activeGroup.id}/create-channel`, { categoryId });
    else if (communityConfig?.canManageCommunity) history.push('/community-settings/create-channel', { categoryId });
    else history.push(`/group-settings/${activeGroup.id}/create-channel`, { categoryId });
  };

  const redirectToAddCategory = () => {
    if (!activeGroup?.main_group) history.push(`/group-settings/${activeGroup.id}/create-category`);
    else if (communityConfig?.canManageCommunity) history.push('/community-settings/create-category');
    else history.push(`/group-settings/${activeGroup.id}/create-category`);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const reorderForNewDest = (sourceList, destList, startIndex, endIndex) => {
    const sourceResult = Array.from(sourceList);
    const destResult = Array.from(destList);
    const [removed] = sourceResult.splice(startIndex, 1);
    destResult.splice(endIndex, 0, removed);

    return {
      sourceData: sourceResult,
      destData: destResult,
    };
  };

  const sortByOrder = (items) => {
    const sortedCategories = sortBy(items, 'position');
    sortedCategories.forEach((category) => {
      category.channels = sortBy(category.channels, 'position');
    });
    return sortedCategories;
  };

  const onDragEnd = (result) => {
    const currentChannels = cloneDeep(currentCommunityChannels);

    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destIndex = result.destination.index;
    if (result.type === 'droppableCategory') {
      const reorderedCategories = reorder(currentChannels, sourceIndex, destIndex);

      const indexReorderedCategories = reorderedCategories.map((item, index) => ({ ...item, position: index + 1 }));

      if (destIndex === sourceIndex) {
        return;
      }

      let finalPosition = null;
      if (destIndex === 0) {
        finalPosition = 1;
      } else if (destIndex < sourceIndex) {
        // Drop above
        finalPosition = reorderedCategories[destIndex + 1].position;
      } else if (indexReorderedCategories.length - 1 === destIndex) {
        finalPosition = reorderedCategories[destIndex - 1].position;
      } else {
        finalPosition = reorderedCategories[destIndex - 1].position;
      }

      dispatch(setCurrentChannels(sortByOrder(indexReorderedCategories)));
      dispatch(
        updateCategoryPosition({
          groupId: activeGroupId,
          orderedChannel: sortByOrder(indexReorderedCategories),
          currentChannel: currentChannels,
          categoryId: result.draggableId,
          position: finalPosition,
          activeCommunityId: activeCommunityId
        }),
      );
    } else if (result.type === 'droppableChannel') {
      const channelMap = currentChannels.reduce((acc, item) => {
        acc[item.id] = item.channels;
        return acc;
      }, {});
      const sourceParentId = result.source.droppableId;
      const destParentId = result.destination.droppableId;

      let updatedCategories;

      if (sourceParentId !== destParentId) {
        // const { sourceData, destData } = reorderForNewDest(
        //   channelMap[sourceParentId],
        //   channelMap[destParentId],
        //   sourceIndex,
        //   destIndex,
        // );

        // const indexReorderedSourceChannels = sourceData.map(
        //   (item, index) => ({ ...item, position: index + 1 }),
        // );
        // const indexReorderedDestChannels = destData.map(
        //   (item, index) => ({ ...item, position: index + 1 }),
        // );

        // updatedCategories = currentChannels.map((item) => {
        //   if (String(item.id) === String(sourceParentId)) {
        //     item.channels = indexReorderedSourceChannels;
        //   }
        //   if (String(item.id) === String(destParentId)) {
        //     item.channels = indexReorderedDestChannels;
        //   }
        //   return item;
        // });

        NotificationService.error("You can't move channel to different category");
        return;
      }
      const reorderedChannels = reorder(channelMap[sourceParentId], sourceIndex, destIndex);

      const indexReorderedChannels = reorderedChannels.map((item, index) => ({ ...item, position: index + 1 }));

      updatedCategories = currentChannels.map((item) => {
        if (String(item.id) === String(sourceParentId)) {
          item.channels = indexReorderedChannels;
        }
        return item;
      });

      if (destIndex === sourceIndex) {
        return;
      }

      let finalPosition = null;
      if (destIndex === 0) {
        finalPosition = 1;
      } else if (destIndex < sourceIndex) {
        // Drop above
        finalPosition = reorderedChannels[destIndex + 1].position;
      } else if (indexReorderedChannels.length - 1 === destIndex) {
        finalPosition = reorderedChannels[destIndex - 1].position;
      } else {
        finalPosition = reorderedChannels[destIndex - 1].position;
      }

      dispatch(
        updateChannelPosition({
          groupId: activeGroupId,
          orderedChannel: sortByOrder(updatedCategories),
          currentChannel: currentChannels,
          channelId: result.draggableId,
          position: finalPosition,
          activeCommunityId: activeCommunityId,
        }),
      );
    }
  };

  return (
    <div>
      <ShareModal link={link} open={openShareSheet} onClose={() => setOpenShareSheet(false)} />
      <div className="ltr:ml-4 rtl:mr-4">{communityInfo}</div>
      <div className={classNames('relative ltr:ml-4 rtl:mr-4', !communityInfo ? 'hidden' : '')}>
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t divider" />
        </div>
      </div>
      <div className="mt-4 px-2">
        <div
          id="sidebar__action--overview"
          onClick={handleOverviewClick}
          className={classNames({
            'group w-full group flex items-stretch ltr:mr-12 rtl:ml-12 px-2 py-2 text-14 rounded-md cursor-pointer': true,
            btn__primary: isCommunityOverviewPath?.isExact,
            text__body: !isCommunityOverviewPath?.isExact,
            [primaryFont]: isCommunityOverviewPath?.isExact,
          })}
          style={{
            backgroundColor: isCommunityOverviewPath?.isExact ? activeCommunityAccentColor : '',
          }}
          onKeyPress={handleOverviewClick}
          tabIndex="-1"
          role="button"
        >
          <HomeIcon className="h-5 w-5 ltr:mr-2 rtl:ml-2" />
          <span className="leading-6">{activeGroup?.home_screen_metadata?.name || $translatei18n('Overview')} </span>
          {isCurrentUserHost && (
            <div className="ltr:ml-auto rtl:mr-auto ltr:pl-2 rtl:pr-2 flex-none">
              {isSubscriptionActiveNull || subscriptionActive ? (
                <CogIcon
                  id="overviewEettings"
                  className="w-5 h-5 hidden group-hover:block cursor-pointer rounded"
                  onClick={(event) => {
                    event.stopPropagation();
                    history.push('/overview-settings');
                  }}
                />
              ) : null}
              <div className="w-5 h-5 block group-hover:hidden" />
            </div>
          )}
        </div>

        {currentActiveGroupIsMainGroup &&
          (isSubscriptionActiveNull || subscriptionActive) &&
          shopData?.isShopEnabled /* && (shopData?.stripe?.isStripeConnected || shopData?.razorpay?.status === 'active') */ &&
          (shopData?.go_live || isCommunityOwner ? (
            <div
              onClick={navigateToShopListing}
              className={classNames({
                'w-full flex items-stretch ltr:mr-12 rtl:ml-12 my-3 px-2 py-2 text-14 rounded-md cursor-pointer relative': true,
                btn__primary: isShopPath?.isExact,
                text__body: !isShopPath?.isExact,
                [primaryFont]: isShopPath?.isExact,
              })}
              style={{
                backgroundColor: isShopPath?.isExact ? activeCommunityAccentColor : '',
              }}
              onKeyPress={navigateToShopListing}
              tabIndex="-1"
              role="button"
            >
              <ShoppingBagIcon className="h-5 w-5 ltr:mr-2 rtl:ml-2" />
              <span className="leading-6">{shopData?.shopName || 'shop'}</span>
              {!isOldUser && !isActivePlan && (
                <div
                  className="flex flex-1 items-end justify-center absolute right-2 top-1/2 -translate-y-1/2 group"
                  style={{ zIndex: 99999 }}
                >
                  <LockIcon
                    id="lockIcon"
                    strokeStyle="group-hover:stroke-[#CBD5E1]"
                    classNames="w-5 h-5 p-1 rounded-full text-black bg-[#CBD5E1] hover:bg-[#475569]"
                  />
                </div>
              )}
            </div>
          ) : null)}
      </div>
      {minimizedTray()}

      {isSubscriptionActiveNull || subscriptionActive ? (
        <>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable" type="droppableCategory">
              {(provided, parentSnapshot) => (
                <div
                  ref={provided.innerRef}
                  className={parentSnapshot.isDraggingOver ? 'text-gray-400 text__info' : 'text-gray-900 text__title'}
                >
                  {currentCommunityChannels?.map((data, index) => (
                    <Draggable
                      key={data.id}
                      draggableId={String(data.id)}
                      index={index}
                      isDragDisabled={!groupConfig?.canManageGroup}
                    >
                      {(provided, categoryChildSnapshot) => (
                        <div
                          className={joinClassNames(
                            'ltr:pl-2 rtl:pr-2 pt-6',
                            !groupConfig?.canManageGroup && !data?.channels?.length && 'hidden',
                            categoryChildSnapshot.isDragging && 'text-gray-900 text__title',
                          )}
                          ref={provided.innerRef}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...provided.draggableProps}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...provided.dragHandleProps}
                        >
                          <div
                            id={data?.id}
                            onClick={() => {
                              const newCurrentChannels = cloneDeep(currentCommunityChannels);

                              newCurrentChannels.forEach((el) => {
                                if (el?.id == data?.id) {
                                  el.isCollapsed = !el.isCollapsed;
                                }
                              });
                              dispatch(setCurrentChannels(newCurrentChannels));
                            }}
                            className={joinClassNames(
                              'px-2 mb-1 text-base font-semibold group break-all rounded',
                              primaryFont,
                            )}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                          >
                            <div className="flex justify-between items-center w-full">
                              <div
                                className="flex items-center w-4/5"
                                data-tooltip-content={$translatei18n('PremiumCategory')}
                                data-tooltip-id={'premiumChannel'}
                              >
                                <div
                                  className="w-full truncate"
                                  data-tooltip-content={data?.name}
                                  data-tooltip-id={data?.id}
                                >
                                  {data?.name}
                                  {data?.name?.length > 25 && (
                                    <ReactTooltip
                                      id={data?.id}
                                      effect="solid"
                                      style={{ maxWidth: '200px', whiteSpace: 'break-spaces' }}
                                    />
                                  )}
                                </div>
                                {data?.locked && (
                                  <>
                                    <LockClosedIcon
                                      className="ltr:ml-1 rtl:mr-1 w-5 h-5 cursor-pointer rounded-full p-0.5 flex-none btn__primary"
                                      style={{ backgroundColor: activeCommunityAccentColor }}
                                    />
                                    <ReactTooltip id={'premiumChannel'} place="bottom-start" effect="solid" />
                                  </>
                                )}
                              </div>
                              <div className="flex justify-center items-center">
                                {data?.isCollapsed === false ? (
                                  <BsCaretRightFill
                                    className="ltr:ml-2 rtl:mr-2 main__icon cursor-pointer rounded flex-none hidden group-hover:block"
                                    fontSize={12}
                                  />
                                ) : (
                                  <BsCaretDownFill
                                    className="ltr:ml-2 rtl:mr-2 main__icon cursor-pointer rounded flex-none hidden group-hover:block"
                                    fontSize={12}
                                  />
                                )}
                                {groupConfig?.canManageGroup && (
                                  <div className="flex justify-center items-center ltr:ml-auto rtl:mr-auto">
                                    <CogIcon
                                      id={`manageChannelCategory${data?.id}`}
                                      className="ltr:ml-2 rtl:mr-2 w-5 h-5 hidden group-hover:block cursor-pointer rounded flex-none"
                                      onClick={() => redirectToChannelCategorySettings(data?.id)}
                                    />
                                    <div className="ltr:ml-2 rtl:mr-2 w-5 h-5 block group-hover:hidden flex-none" />
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          <Collapse isOpened={data?.isCollapsed}>
                            {/* CHANNELS LISTING */}
                            <Droppable droppableId={String(data?.id)} type="droppableChannel">
                              {(provided, snapshot) => (
                                <div ref={provided.innerRef}>
                                  {data?.channels.map((nameData, index) => {
                                    return (
                                      <>
                                        {nameData.channel_type !== 'stage' && (
                                          <Draggable
                                            key={nameData.id}
                                            draggableId={String(nameData.id)}
                                            index={index}
                                            isDragDisabled={!groupConfig?.canManageGroup}
                                          >
                                            {(provided, childSnapshot) => (
                                              <>
                                                <div
                                                  ref={provided.innerRef}
                                                  id={nameData?.id}
                                                  className={classNames(
                                                    {
                                                      'group w-full mb-2 flex items-start px-2 py-2 text-14 rounded-md cursor-pointer': true,
                                                      btn__primary: activeChannelId === nameData?.id,
                                                      text__body:
                                                        activeChannelId !== nameData?.id && !snapshot.isDraggingOver,
                                                      text__info:
                                                        activeChannelId !== nameData?.id &&
                                                        (snapshot.isDraggingOver || parentSnapshot.isDraggingOver) &&
                                                        !childSnapshot.isDragging &&
                                                        !categoryChildSnapshot.isDragging,
                                                      [primaryFont]: activeChannelId == nameData?.id,
                                                    },
                                                    data?.locked && 'opacity-50',
                                                  )}
                                                  key={nameData?.id}
                                                  onClick={(event) => handleChannelClick(nameData, data)}
                                                  role="button"
                                                  tabIndex="-1"
                                                  onKeyPress={handleChannelClick.bind(this, nameData)}
                                                  // eslint-disable-next-line react/jsx-props-no-spreading
                                                  {...provided.draggableProps}
                                                  // eslint-disable-next-line react/jsx-props-no-spreading
                                                  {...provided.dragHandleProps}
                                                  style={{
                                                    ...provided.draggableProps.style,
                                                    ...{
                                                      backgroundColor:
                                                        activeChannelId === nameData?.id
                                                          ? activeCommunityAccentColor
                                                          : '',
                                                    },
                                                  }}
                                                >
                                                  <ChannelIcon
                                                    accent={activeChannelId === nameData?.id}
                                                    channelType={nameData?.channel_type}
                                                  />
                                                  <div
                                                    className="break-all w-full truncate"
                                                    data-tooltip-content={nameData?.name}
                                                    data-tooltip-id={nameData?.id}
                                                  >
                                                    {nameData?.name}

                                                    {nameData?.name?.length >= 20 && (
                                                      <ReactTooltip
                                                        id={nameData?.id}
                                                        effect="solid"
                                                        style={{ maxWidth: '200px', whiteSpace: 'break-spaces' }}
                                                      />
                                                    )}
                                                  </div>
                                                  {groupConfig?.canManageGroup && (
                                                    <div className="ltr:ml-auto rtl:mr-auto ltr:pl-2 rtl:pr-2 flex-none">
                                                      <CogIcon
                                                        id={`manageChannel${nameData?.id}`}
                                                        className="w-5 h-5 hidden group-hover:block cursor-pointer rounded"
                                                        onClick={(event) =>
                                                          redirectToChannelSettings(event, nameData?.id)
                                                        }
                                                      />
                                                      <div className="w-5 h-5 block group-hover:hidden" />
                                                    </div>
                                                  )}
                                                  {nameData?.channel_type !== 'stage' &&
                                                    activeChannelId !== nameData?.id &&
                                                    nameData?.unread_channel &&
                                                    !localIndicators.includes(nameData?.id) && (
                                                      <div className="my-auto group-hover:hidden ltr:ml-auto rtl:mr-auto ltr:pl-2 rtl:pr-2 flex-none justify-center items-center">
                                                        <div className="w-2 h-2 bg-red-600 border-2 border-solid border-red-200 rounded-full" />
                                                      </div>
                                                    )}
                                                  {/* Live event indicator */}
                                                  {nameData?.channel_type === 'stage' && nameData?.live_event && (
                                                    <div className="group-hover:hidden ltr:ml-auto rtl:mr-auto ltr:pl-2 rtl:pr-2 flex-none justify-center items-center">
                                                      <span className="inline-flex items-center px-1 py-0.5 rounded text-xs font-bold bg-red-500 text-white btn__danger">
                                                        LIVE
                                                      </span>
                                                    </div>
                                                  )}
                                                </div>
                                                {/* stage indicator */}
                                                {nameData?.channel_type === 'stage' &&
                                                  nameData?.online_count > 0 &&
                                                  activeChannelId !== nameData?.id && (
                                                    <OnlineUserIndicator
                                                      onlineCount={nameData?.online_count}
                                                      onlineUsers={nameData?.online_users}
                                                    />
                                                  )}
                                                {provided.placeholder}
                                              </>
                                            )}
                                          </Draggable>
                                        )}
                                      </>
                                    );
                                  })}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                            {groupConfig?.canManageGroup && (
                              <div className="w-full px-2">
                                <button
                                  type="button"
                                  className={joinClassNames(
                                    'inline-flex items-center justify-center px-4 py-2 border-transparent text-sm font-medium rounded-md shadow-sm focus:outline-none focus:ring-0 focus:ring-offset-0 border-2 border-dashed w-full max-w-xs btn__outline__dashed',
                                    primaryFont,
                                  )}
                                  onClick={() => redirectToAddChannel(data?.id)}
                                >
                                  <PlusIcon className="w-4 h-4 ltr:mr-2 rtl:ml-2" />
                                  {$translatei18n('AddANewChannel')}
                                </button>
                              </div>
                            )}
                            {provided.placeholder}
                          </Collapse>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {groupConfig?.canManageGroup && (
            <div className="w-full px-2 ltr:pl-4 rtl:pr-4 pt-4">
              <div className="w-full border-t divider pb-4" />
              <button
                type="button"
                className={joinClassNames(
                  'inline-flex items-center justify-center px-4 py-2 border-transparent text-sm font-medium rounded-md shadow-sm focus:outline-none focus:ring-0 focus:ring-offset-0 border-2 border-dashed w-full max-w-xs btn__outline__dashed',
                  primaryFont,
                )}
                onClick={redirectToAddCategory}
              >
                <PlusIcon className="w-4 h-4 ltr:mr-2 rtl:ml-2" />
                {$translatei18n('AddANewCategory')}
              </button>
            </div>
          )}
        </>
      ) : (
        <SidebarHideContent />
      )}

      {renderExitPromptModal()}
    </div>
  );
}

export default function Sidebar({ communities, activeCommunity, communityInfo, closeMenu }) {
  const [mappedCommunities, setMappedCommunities] = useState([]);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    if (Array.isArray(communities)) {
      const mapCommunities = communities.map((data) => ({
        id: data.id,
        icon: imageFetch(data.icon),
        isActive: data.id === activeCommunity,
      }));
      setMappedCommunities(mapCommunities);
    }
  }, [communities, activeCommunity]);

  const handleCommunitySwitch = (data) => {
    dispatch(setActiveCommunity({ id: data?.id }));
    history.replace(`/communities/${data?.id}`);
  };

  return <LeftDrawer communityInfo={communityInfo} closeMenu={closeMenu} />;
}
