import {
  FETCH_FOLLOWEE_REQUEST, FETCH_FOLLOWERS_REQUEST, SET_STAGE_AUDIO_PERMISSION_ACCEPTED, SET_STAGE_VIDEO_PERMISSION_ACCEPTED, SET_USER, UPDATE_USER_REQUEST,
} from './actionTypes';

export const setUser = (user = {}) => ({
  type: SET_USER,
  payload: {
    user,
  },
});

export const updateUser = (payload) => ({
  type: UPDATE_USER_REQUEST,
  payload,
});

export const fetchFollowers = (payload) => ({
  type: FETCH_FOLLOWERS_REQUEST,
  payload,
});

export const fetchFollowee = (payload) => ({
  type: FETCH_FOLLOWEE_REQUEST,
  payload,
});

export const setStageAudioPermissionAccepted = (status) => ({
  type: SET_STAGE_AUDIO_PERMISSION_ACCEPTED,
  payload: status,
});

export const setStageVideoPermissionAccepted = (status) => ({
  type: SET_STAGE_VIDEO_PERMISSION_ACCEPTED,
  payload: status,
});
