import {
  IS_APP_IN_APP
} from '../actions/actionTypes';

const initialState = {
  isAppInApp: false
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case IS_APP_IN_APP:
      return { ...state, isAppInApp: payload };
    default:
      return state;
  }
}

export default reducer